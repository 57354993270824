import { FeedbackMessages, FeedbackMessageType } from './feedback';

const roundHalf = (num: number) => Math.round(num * 2) / 2;

export const calculateGrade = (
  percentageScore: number,
  scoreWeight: number,
  feedbackMessageType: FeedbackMessageType | null
) => {
  if (feedbackMessageType === FeedbackMessages.NoAnswer) {
    return undefined;
  }

  return roundHalf((percentageScore * scoreWeight) / 100);
};
