import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { lazy } from 'react';

import { genRandId } from 'shared/utils/genRandId';

const TooltipNode = lazy(() =>
  import('@/utils/tiptap/extensions/Tooltip/components/TooltipNode').then(module => ({ default: module.TooltipNode }))
);

export const BaseTooltipMark = Node.create<NonNullable<unknown>, NonNullable<unknown>>({
  name: 'tooltip',
  group: 'inline',
  inline: true,
  content: 'inline*',
  marks: '_',

  addAttributes() {
    return {
      tooltipContent: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('tooltipContent');
        },
        renderHTML: attributes => {
          if (!attributes.tooltipContent) {
            return {};
          }

          return { tooltipContent: attributes.tooltipContent };
        }
      },
      isEditMode: {
        default: null,
        parseHTML: element => {
          return element.getAttribute('isEditMode') === 'true';
        },
        renderHTML: attributes => {
          if (!attributes.isEditMode) {
            return {};
          }

          return { isEditMode: `${attributes.isEditMode}` };
        }
      },
      tooltipId: {
        default: genRandId(),
        parseHTML: element => {
          return element.getAttribute('tooltipId');
        },
        renderHTML: attributes => {
          if (!attributes.tooltipId) {
            return {};
          }

          return { tooltipId: attributes.tooltipId };
        }
      },
      tooltipStyle: {
        default: {},
        parseHTML: element => {
          try {
            return JSON.parse(element.getAttribute('tooltipStyle') ?? '{}');
          } catch (err) {
            return {};
          }
        },
        renderHTML: attributes => {
          if (!attributes.tooltipStyle) {
            return {};
          }

          return { tooltipStyle: JSON.stringify(attributes.tooltipStyle) };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'tooltip-component'
      }
    ];
  },

  addCommands() {
    return {
      addTooltip:
        () =>
        ({ chain, editor }) => {
          if (editor.isActive(this.type.name)) {
            return chain().updateAttributes(this.type.name, { isEditMode: true }).run();
          }

          const { from, to, empty } = editor.state.selection;
          const selectedTextFragment = editor.state.doc.slice(from, to).content;

          if (empty) {
            return false;
          }

          let isSelectionAllowed = true;

          selectedTextFragment.forEach(node => {
            if (!node.isInline) {
              isSelectionAllowed = false;
            }
          });

          if (!isSelectionAllowed) {
            return false;
          }

          const tooltipNode = editor.schema.nodes.tooltip
            .create(
              {
                isEditMode: true,
                tooltipId: genRandId(),
                style: { color: '#000000' }
              },
              selectedTextFragment
            )
            .toJSON();

          return chain().deleteRange({ from, to }).insertContent(tooltipNode).focus().run();
        },
      removeTooltip:
        (tooltipId: string) =>
        ({ state, dispatch }) => {
          const { selection } = state;
          let { tr } = state;
          const { from, to } = selection;
          let replaced = false;

          state.doc.descendants((node, pos) => {
            if (
              !replaced &&
              node.type.name === 'tooltip' &&
              node.attrs.tooltipId === tooltipId &&
              pos < to &&
              pos + node.nodeSize >= from
            ) {
              const start = Math.min(pos, from);
              const end = Math.max(pos + node.nodeSize, to);
              // Calculate the position to insert the content
              const targetPos = tr.mapping.map(start);
              // Replace the node with its content
              tr = tr.delete(start, end).insert(targetPos, node.content);
              // Skip checking the rest of the nodes
              replaced = true;
            }
          });

          if (replaced && dispatch) {
            dispatch(tr);
            return true;
          }
          return false;
        }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['tooltip-component', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TooltipNode);
  }
});
