import { useCallback } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const useParentQuestionId = () => {
  const { id, document } = useContentObject();
  const rootMap = getRootMap(document);

  const selectParentQuestionId = useCallback(
    (contentObjects: Y.Map<Y.Map<unknown>>): string | undefined => {
      const item = Array.from(contentObjects.values()).find(value => value.get('questionAnswerId') === id);
      return item?.get('id');
    },
    [id]
  );

  const [parentQuestionId] = useObservedProperty<string | undefined>(rootMap, 'contentObjects', {
    deepObserve: false,
    selector: selectParentQuestionId
  });

  return parentQuestionId;
};
