import { Text } from '@mantine/core';
import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';
import { useDrag } from 'react-dnd';

import { useStyles } from './FillInTheBlanksOption.styles';
import { FillInTheBlanksOptionValue } from './FillInTheBlanksOptionValue';
import { Check } from '@/icons/Check';
import { Cross } from '@/icons/Cross';
import { DndLayer } from '@/widgets/FillInTheBlanksQuestion/player/DndLayer';
import { useTiptapFillInTheBlanksOptionsStore } from '@/widgets/FillInTheBlanksQuestion/player/hooks/useTiptapFillInTheBlanksOptionsStore';
import { MovedWordOption, WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function FillInTheBlanksOption(props: NodeViewRendererProps) {
  const {
    node: {
      attrs: { fillInTheBlanksId, fontFamily, fontSize }
    }
  } = props;

  const [questionId] = useContentObjectProperty<string>('id');

  const selectedAnswers = useTiptapFillInTheBlanksOptionsStore(state => state.selectedAnswers);
  const disabled = useTiptapFillInTheBlanksOptionsStore(state => state.disabled);
  const selectedAnswersStatuses = useTiptapFillInTheBlanksOptionsStore(state => state.selectedAnswersStatuses);
  const handleSelectAnswers = useTiptapFillInTheBlanksOptionsStore(state => state.handleSelectAnswers);

  const optionInput = selectedAnswers[fillInTheBlanksId];

  const textToShow = optionInput?.value;
  const isCorrect = selectedAnswersStatuses[fillInTheBlanksId];

  const handleDropFromAnotherSlot = useCallback(
    (newValue: MovedWordOption) => {
      const { [newValue.fillInTheBlanksId]: toMove, ...remainingAnswers } = selectedAnswers;
      const isSwappingOptions = !!optionInput;
      const updatedSelectedAnswers = {
        ...remainingAnswers,
        [fillInTheBlanksId]: toMove,
        ...(isSwappingOptions && { [newValue.fillInTheBlanksId]: optionInput })
      };

      handleSelectAnswers(updatedSelectedAnswers);
    },
    [fillInTheBlanksId, selectedAnswers, optionInput, handleSelectAnswers]
  );

  const handleDropFromWordBank = useCallback(
    (newValue: WordOption) => {
      const updatedAnswers = { ...selectedAnswers, [fillInTheBlanksId]: newValue };
      handleSelectAnswers(updatedAnswers);
    },
    [fillInTheBlanksId, handleSelectAnswers, selectedAnswers]
  );

  const onOptionChange = useCallback(
    (newValue: WordOption | MovedWordOption) => {
      const isDroppedFromAnotherSlot = 'fillInTheBlanksId' in newValue;
      if (isDroppedFromAnotherSlot) {
        handleDropFromAnotherSlot(newValue);
      } else {
        handleDropFromWordBank(newValue);
      }
    },
    [handleDropFromAnotherSlot, handleDropFromWordBank]
  );

  const [{ isDragging }, dragRef] = useDrag({
    type: `fitb-option-moved-${questionId}`,
    item: { wordOption: optionInput, fillInTheBlanksId },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !disabled && !!optionInput
  });

  const { classes, cx } = useStyles({ fontFamily, fontSize, isCorrect, hasContent: !!textToShow, isDragging });

  return (
    <NodeViewWrapper ref={dragRef} className={classes.componentWithContent}>
      {isCorrect !== undefined ? (
        <>
          {isCorrect ? (
            <Check className={cx(classes.icon, classes.checkIcon)} />
          ) : (
            <Cross className={cx(classes.icon, classes.crossIcon)} />
          )}
        </>
      ) : null}
      <FillInTheBlanksOptionValue
        disabled={disabled}
        fontSize={fontSize}
        isCorrect={isCorrect}
        onChange={onOptionChange}
        textToShow={textToShow}
        questionId={questionId}
      />
      {isDragging && optionInput ? (
        <DndLayer>
          <Text className={classes.wordBankOptionText}>{optionInput.value}</Text>
        </DndLayer>
      ) : null}
    </NodeViewWrapper>
  );
}
