export enum LearningMethodologies {
  DiscussionBasedLearning = 'discussionBasedLearning',
  ProblemBasedLearning = 'problemBasedLearning',
  InquiryBasedLearning = 'inquiryBasedLearning',
  LearningThroughExperimentation = 'learningThroughExperimentation',
  StorytellingLearning = 'storytellingLearning',
  CollaborativeLearning = 'collaborativeLearning',
  FlippedClassroom = 'flippedClassroom'
}

export const learningMethodologiesMap = {
  [LearningMethodologies.DiscussionBasedLearning]: {
    learningObjectStructure: '',
    title: 'Discussion-Based Learning',
    description:
      'Learners engage in structured discussions to explore ideas, share perspectives, and deepen their understanding of a topic through dialogue and interaction.',
    deepDescription: ''
  },
  [LearningMethodologies.ProblemBasedLearning]: {
    learningObjectStructure: '',
    title: 'Problem-Based Learning',
    description:
      'Students learn about a subject by working in groups to solve complex, real-world problems, promoting critical thinking, collaboration, and self-directed learning.',
    deepDescription: ''
  },
  [LearningMethodologies.InquiryBasedLearning]: {
    learningObjectStructure: '',
    title: 'Inquiry-Based Learning',
    description:
      'Students actively engage in exploring questions, problems, or scenarios, conducting investigations, and constructing new understandings through critical thinking and research.',
    deepDescription: `
      Inquiry-Based Learning (IBL) This method emphasizes the development of inquiry skills, such as:
      formulating questions, conducting investigations, analyzing data, and drawing conclusions. The ultimate goal of IBL is to cultivate a deeper understanding of the subject matter, foster critical thinking, and enhance problem-solving abilities by placing students at the center of their own learning journey.
      The process often involves the following steps:
      1. Question Formulation: Students begin by asking open-ended questions about a topic of interest.
      2. Research and Investigation: Students gather information from various sources, and collect data to answer their questions.
      3. Analysis and Interpretation: Students analyze the data they have collected, looking for patterns, relationships, and insights.
      4. Presentation of Findings: Students present their findings to their peers, teachers, or other audiences, often in the form of posters, pictures, drawing, presentations, infographics etc.
      5. Reflection: Students reflect on their learning process, evaluating what they have learned, how they learned it, and what they could improve in future inquiries.

      Requirements for Content:
      1. Engaging and Relevant: The content should be interesting and relevant to the students' lives, encouraging them to invest time and effort into their inquiries.
      2. Open-Ended: The content should allow for multiple perspectives and interpretations, providing opportunities for students to explore various pathways and solutions.
      3. Challenging: The content should present an appropriate level of challenge, pushing students to think critically and stretch their abilities without being overwhelming.
      4. Resource-Rich: The content should be supported by a variety of resources, such as articles, videos, experiments, and data sets, to facilitate thorough investigation and research.
      5. Scaffolded: The content should be structured in a way that provides support and guidance while allowing students to take ownership of their learning. This might include guiding questions, checklists, or frameworks for conducting investigations.
      6. Reflective: The content should include opportunities for reflection, allowing students to think about what they have learned, how they have learned it, and how they can apply their new knowledge in different contexts.
      7. Interdisciplinary: The content should integrate knowledge and skills from multiple disciplines, reflecting the interconnected nature of real-world problems and inquiries.
      8. Iterative: The content should allow for an iterative process of inquiry, where students can revise their questions, methods, and conclusions based on new information and insights.
      9. Authentic Assessment: The content should be assessed through authentic means, such as projects, presentations, and portfolios, which reflect the depth and breadth of students' inquiries.
    `
  },
  [LearningMethodologies.LearningThroughExperimentation]: {
    title: 'Learning through experimentation',
    description:
      'Students learn by conducting experiments, testing hypotheses, and observing outcomes, fostering critical thinking, problem-solving, and hands-on understanding of concepts.',
    deepDescription: `
      Learning through Experimentation is an educational approach where students gain knowledge through hands-on experiences. This method emphasizes the scientific process, encouraging students to conduct experiments aligned with the given topic, test hypotheses generated on the topic, and observe outcomes and fostering critical thinking and problem-solving skills.
      Key Elements:
      1. Formulating Hypotheses: Students start with a question related to the topic and create a testable hypothesis.
      2. Designing Experiments: Students design experiments, selecting variables and controls a to test hypothesis.
      3. Conducting Experiments: Students perform experiments, engaging directly with materials.
      4. Collecting and Analyzing Data: Students gather and analyze data to identify patterns.
      5. Drawing Conclusions: Students assess their hypotheses and explain findings.
      6. Reflecting on the Process: Students evaluate their experiment and learning.
    `,
    learningObjectStructure: `
      Each chapter should have block title at the beginning. First chapter should have 2-4 elements of text with 10-15 sentences. It should have ended with image.
      Next chapters should have 2-4 elements of text with 10-15 sentences and should have ended with learning item.
    `
  },
  [LearningMethodologies.StorytellingLearning]: {
    learningObjectStructure: '',
    title: 'Storytelling Learning',
    description:
      'Uses narrative structures to engage learners, making complex concepts more relatable and easier to understand, thereby enhancing engagement, retention, and critical thinking.',
    deepDescription: `
      Teaching through Storytelling is an educational approach that emphasizes the use of narrative structures to enhance learning by making complex concepts more relatable and memorable. This method involves the creation and analysis of stories to engage students emotionally and cognitively, fostering a deeper understanding and enhancing critical thinking and problem-solving skills. The ultimate goal of storytelling in teaching is to encourage engagement and capture flagging attention, link fiction (stories) with non-fiction (learning topic and goals), making the material meaningful and memorable.

      Effective storytelling should consist of the following key elements:
      1. Character(s): Characters that play specific roles in the narration, embodying different aspects of the learning content.
      2. A Plot: A storyline that describes the learning content in a structured and engaging manner.
      3. A Challenge: Situations or problems that the characters face, representing the core learning objectives.
      4. A Choice: Critical decisions that the characters must make, with students helping to explore these choices.
      5. A Resolution: The outcomes of the characters' choices, which help students to learn, explore findings, and draw conclusions.

      By incorporating these elements, storytelling can effectively engage students, making the learning process interactive and impactful.
      Students receives interactive widgets that are a part of bigger plot. All widgets should be related to the plot and help characters to overcome challenges they are facing.
    `
  },
  [LearningMethodologies.CollaborativeLearning]: {
    learningObjectStructure: '',
    title: 'Collaborative learning',
    description:
      'Involves students working together on activities in a group to ensure that everyone participates, leveraging each other’s skills and foster teamwork.',
    deepDescription: ''
  },
  [LearningMethodologies.FlippedClassroom]: {
    learningObjectStructure: '',
    title: 'Flipped Classroom',
    description:
      'Students watch pre-recorded lessons at home and complete in-class assignments, as opposed to hearing lectures in class and doing homework at home.',
    deepDescription: ''
  }
} as const;
