import { Box } from '@mantine/core';

import { useStyles } from './QuestionBank.styles';
import { DeleteQuestion } from '@/widgets/QuestionGroupList/config/design/QuestionBank/DeleteQuestion';
import { QuestionDuplicate } from '@/widgets/QuestionGroupList/config/design/QuestionBank/QuestionDuplicate';
import { QuestionRequireAction } from '@/widgets/QuestionGroupList/config/design/QuestionBank/QuestionRequireAction';

type QuestionCardActionsProps = {
  itemId: string;
  onDeleteQuestion: () => void;
  onDuplicateQuestion: () => void;
};

export function QuestionCardActions(props: QuestionCardActionsProps) {
  const { itemId, onDeleteQuestion, onDuplicateQuestion } = props;
  const { classes } = useStyles({});

  return (
    <Box className={classes.menuIcons} data-testid="question-bank-item-actions">
      <DeleteQuestion onDelete={onDeleteQuestion} />
      <QuestionDuplicate onDuplicate={onDuplicateQuestion} />
      <QuestionRequireAction questionId={itemId} />
    </Box>
  );
}
