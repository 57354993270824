import { Assets } from './assets';
import { Components, CustomLoadingOverlayProps } from './components';
import { FontType } from './fonts';
import { Metadata } from './metadata';
import {
  ActivityThemeStyles,
  AnswerAreaThemeProps,
  BasicThemeProps,
  BlockTitleThemeProps,
  BorderRadiusThemeProps,
  BorderThemeProps,
  CreateThemeDto,
  GlobalColorThemeProps,
  InteractiveButtonThemeProps,
  LayoutSectionStyles,
  OverrideTypographyStylesKeys,
  QuestionNumberingPosition,
  QuestionNumberingStyle,
  ThemeType,
  TopImageFit,
  TypographyThemeProps,
  UpdateThemeDto
} from './theme';

type ComponentsModuleClientType = {
  assets: Assets;
  defaultActivityThemeStyles: ActivityThemeStyles;
  fonts: FontType[];
  metadata: Metadata;
  components: Components;
};

export { QuestionNumberingPosition, QuestionNumberingStyle };

export type {
  ActivityThemeStyles,
  AnswerAreaThemeProps,
  Assets,
  BasicThemeProps,
  BlockTitleThemeProps,
  BorderRadiusThemeProps,
  BorderThemeProps,
  ComponentsModuleClientType,
  CreateThemeDto,
  CustomLoadingOverlayProps,
  FontType,
  GlobalColorThemeProps,
  InteractiveButtonThemeProps,
  LayoutSectionStyles,
  Metadata,
  OverrideTypographyStylesKeys,
  ThemeType,
  TopImageFit,
  TypographyThemeProps,
  UpdateThemeDto
};
