export const manifestLocales = {
  interactivePoll: {
    previewImageAlt: {
      en: 'Interactive poll',
      he: 'סקר'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    },
    defaultAnswer: {
      en: 'Answer',
      he: 'תשובה'
    }
  }
};
