import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  const primaryButtonStyle = theme.other.button.primary;
  const standardStyle = primaryButtonStyle.standard;
  const hoveredStyle = primaryButtonStyle.hovered;
  const globalSystemStyle = theme.other.global.system;

  const borderStyle = (buttonState: 'standard' | 'hovered') => {
    const borderStyle = primaryButtonStyle[buttonState];
    return getThemeBorderStyle(borderStyle.border, globalSystemStyle.colors.decoration.main);
  };
  const borderRadiusStyle = getThemeBorderRadiusStyle(standardStyle.borderRadius);

  return {
    button: {
      fontWeight: standardStyle.fontWeight,
      fontSize: standardStyle.fontSize,
      fontFamily: standardStyle.fontFamily ?? globalSystemStyle.fontFamily,
      color: standardStyle.color ?? globalSystemStyle.colors.primary.contrast,
      backgroundColor: standardStyle.backgroundColor ?? globalSystemStyle.colors.primary.main,
      ...borderStyle('standard'),
      ...borderRadiusStyle,

      '&:hover': {
        fontWeight: hoveredStyle.fontWeight,
        color: hoveredStyle.color ?? globalSystemStyle.colors.primary.contrast,
        backgroundColor: hoveredStyle.backgroundColor ?? globalSystemStyle.colors.primary.main,
        ...borderStyle('hovered')
      }
    }
  };
});
