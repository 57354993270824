import { asFunction, createContainer } from 'awilix';

import { AIModuleClientType, AIModuleServerType } from './lib/ai-module';
import { AuthenticationModuleClientType, AuthenticationModuleServerType } from './lib/authentication';
import { AuthorizationModuleClientType } from './lib/authorization';
import { FileStorageModuleClientType, FileStorageModuleServerType } from './lib/file-storage';
import { LmsModuleClientType, LmsModuleServerType } from './lib/lms';
import { LoggerModuleServerType } from './lib/logger';
import { ComponentsModuleClientType } from './lib/ui';
import { XApiEventsModuleClientType, XApiEventsModuleServerType } from './lib/xapi-events';

type ClientModules = {
  aiModule: AIModuleClientType;
  authentication: AuthenticationModuleClientType;
  authorization: AuthorizationModuleClientType;
  ui: ComponentsModuleClientType;
  fileStorage: FileStorageModuleClientType;
  lms: LmsModuleClientType;
  xAPIEvents: XApiEventsModuleClientType;
};

type ServerModules = {
  aiModule: AIModuleServerType;
  authentication: AuthenticationModuleServerType;
  fileStorage: FileStorageModuleServerType;
  lms: LmsModuleServerType;
  logger: LoggerModuleServerType;
  xAPIEvents: XApiEventsModuleServerType;
};

type ClientModuleCreatorFunction<T = unknown> = (clientModules: ClientModules) => T;
type ServerModuleCreatorFunction<T = unknown> = (serverModules: ServerModules) => T;

type ClientModulesCreators = {
  aiModule: ClientModuleCreatorFunction<AIModuleClientType>;
  authentication: ClientModuleCreatorFunction<AuthenticationModuleClientType>;
  authorization: ClientModuleCreatorFunction<AuthorizationModuleClientType>;
  fileStorage: ClientModuleCreatorFunction<FileStorageModuleClientType>;
  lms: ClientModuleCreatorFunction<LmsModuleClientType>;
  ui: ClientModuleCreatorFunction<ComponentsModuleClientType>;
  xAPIEvents: ClientModuleCreatorFunction<XApiEventsModuleClientType>;
};

type ServerModulesCreators = {
  aiModule: ServerModuleCreatorFunction<AIModuleServerType>;
  authentication: ServerModuleCreatorFunction<AuthenticationModuleServerType>;
  fileStorage: ServerModuleCreatorFunction<FileStorageModuleServerType>;
  lms: ServerModuleCreatorFunction<LmsModuleServerType>;
  logger: ServerModuleCreatorFunction<LoggerModuleServerType>;
  xAPIEvents: ServerModuleCreatorFunction<XApiEventsModuleServerType>;
};

const clientModulesContainer = createContainer<ClientModules>({
  strict: true,
  injectionMode: 'PROXY'
});
const clientModules = clientModulesContainer.cradle;

const serverModulesContainer = createContainer<ServerModules>({
  strict: true,
  injectionMode: 'PROXY'
});
const serverModules = serverModulesContainer.cradle;

function createClientModules(clientModulesCreators: Partial<ClientModulesCreators>): typeof clientModulesContainer {
  Object.entries(clientModulesCreators).forEach(([key, value]) => {
    if (clientModulesContainer.hasRegistration(key)) {
      throw new Error(`Client module ${key} was already registered.`);
    }
    // @ts-expect-error Type is too broad
    clientModulesContainer.register(key, asFunction(value).singleton());
  });

  return clientModulesContainer;
}

function createServerModules(serverModulesCreators: Partial<ServerModulesCreators>): typeof serverModulesContainer {
  Object.entries(serverModulesCreators).forEach(([key, value]) => {
    if (serverModulesContainer.hasRegistration(key)) {
      throw new Error(`Server module ${key} was already registered.`);
    }
    // @ts-expect-error Type is too broad
    serverModulesContainer.register(key, asFunction(value).singleton());
  });

  return serverModulesContainer;
}

export { clientModules, serverModules, createClientModules, createServerModules };
export type { ClientModulesCreators, ServerModulesCreators, ClientModules, ServerModules };
