import { ActionIcon, Tooltip } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { getParentQuestionIdForSubQuestion } from 'shared/widgetsSDK/questionGroup';
import { getContentObjectsMap } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';
import { CheckSquare } from '@/icons/CheckSquare';

type QuestionRequireActionProps = {
  questionId: string;
};

export function QuestionRequireAction(props: QuestionRequireActionProps) {
  const { questionId } = props;
  const t = useTranslations('widgets');
  const document = useCurrentDocument();
  const contentObjectsMap = getContentObjectsMap(document);

  const handleQuestionRequired = () => {
    const parentQuestionId = getParentQuestionIdForSubQuestion(questionId, document);
    if (!parentQuestionId) return;

    const parentQuestionMap = contentObjectsMap.get(parentQuestionId);
    const requiredSubQuestionsId = (parentQuestionMap?.get('requiredSubQuestionsId') ?? []) as string[];
    const isRequired = requiredSubQuestionsId.includes(questionId);

    const newRequiredIds = isRequired
      ? requiredSubQuestionsId.filter(id => id !== questionId)
      : [...requiredSubQuestionsId, questionId];

    parentQuestionMap?.set('requiredSubQuestionsId', newRequiredIds);
  };

  return (
    <Tooltip label={`${t('questionGroupListSettings.mustAnswerTooltip')}`}>
      <ActionIcon
        variant="transparent"
        data-testid="is-required-question-bank-item-button"
        onClick={handleQuestionRequired}
      >
        <CheckSquare />
      </ActionIcon>
    </Tooltip>
  );
}
