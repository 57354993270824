import { AnswerConfig } from '../types/AnswerConfig';
import { AnswerElementType } from '../types/AnswerElement';

export const DEFAULT_ANSWERS_AMOUNT = 3;
export const SELECTION_MAX_COLUMN_AMOUNT = 4;

export const allowedElementTypesCombinations: AnswerElementType[][] = [
  ['Text', 'Image', 'Audio'],
  ['Text' /*, 'Video'*/]
];

export const defaultAnswerConfig: AnswerConfig = {
  hasText: true,
  hasImage: true,
  hasAudio: true,
  hasVideo: true
};
