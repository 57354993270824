import { useMantineTheme } from '@mantine/core';
import * as Y from 'yjs';

import { ObjectFit } from 'shared/types/Image';

import { AnswerElementComponentProps } from '@/components/AnswerOption';
import { AnswerElement } from '@/components/AnswerOption/AnswerElement';
import { SelectionQuestionSchema } from '@/widgets/SelectionQuestion/Schema';
import {
  useContentObjectErrors,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';

export function SelectionQuestionAnswerElement(props: AnswerElementComponentProps) {
  const { answerIndex, answerElementType, dataTestIdPrefix, hasImage } = props;

  const fragment = useContentObjectStaticProperty<Y.XmlFragment>(`answers.${answerIndex}.text.fragment`);
  const [imageSrc, setImageSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.image.src`);
  const [alt, setAlt] = useContentObjectProperty<string>(`answers.${answerIndex}.image.alt`);
  const [mobileSrc, setMobileSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.image.mobileSrc`);
  const [title, setTitle] = useContentObjectProperty<string>(`answers.${answerIndex}.audio.title`);
  const [audioSrc, setAudioSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.audio.src`);
  const [imageObjectFit, setImageObjectFit] = useContentObjectProperty<ObjectFit>(
    `answers.${answerIndex}.image.objectFit`
  );
  const [imageMobileObjectFit, setImageMobileObjectFit] = useContentObjectProperty<ObjectFit>(
    `answers.${answerIndex}.image.mobileObjectFit`
  );
  const [errors] = useContentObjectErrors<SelectionQuestionSchema>();
  const audioError = errors.answers?.[answerIndex]?.audio;
  const imageError = errors.answers?.[answerIndex]?.image;

  const theme = useMantineTheme();

  return (
    <AnswerElement
      answerElementType={answerElementType}
      audioError={audioError}
      audioSrc={audioSrc}
      audioTitle={title}
      dataTestIdPrefix={dataTestIdPrefix}
      fragment={fragment}
      hasImage={hasImage}
      imageAlt={alt}
      imageError={imageError}
      imageMobileObjectFit={imageMobileObjectFit}
      imageMobileSrc={mobileSrc}
      imageObjectFit={imageObjectFit}
      imageSrc={imageSrc}
      interactiveButtonThemeProps={theme.other.learningItems.selectionQuestion.interactiveButton}
      setAudioSrc={setAudioSrc}
      setAudioTitle={setTitle}
      setImageAlt={setAlt}
      setImageMobileObjectFit={setImageMobileObjectFit}
      setImageMobileSrc={setMobileSrc}
      setImageObjectFit={setImageObjectFit}
      setImageSrc={setImageSrc}
    />
  );
}
