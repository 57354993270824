import { useRouter } from 'next/router';
import { useCallback } from 'react';
import * as Y from 'yjs';

import { CollaborativeState, getCollaborativeState, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';
import { usePages } from '@/contentObjects/Root/hooks/usePages';

export function useNavigation() {
  const { replace, query, isReady, asPath } = useRouter();
  const document = useCurrentDocument();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCollaborativePageIndex] = useObservedProperty<CollaborativeState['collaborativePageIndex']>(
    getCollaborativeState(document),
    'collaborativePageIndex'
  );

  const navigateToPage = useCallback(
    (page: number | string) => {
      if (!isReady) return;
      const newQuery = { ...query, page: page };

      void replace({ query: newQuery }, undefined, {
        shallow: true
      });

      setCollaborativePageIndex(String(page));
    },
    [setCollaborativePageIndex, isReady, query, replace]
  );

  const navigateToPageId = useCallback(
    (pageId: number | string) => {
      void replace({ query: { ...query, pageId } }, undefined, {
        shallow: true
      });
    },
    [query, replace]
  );

  const getUpdatedRoute = useCallback(
    (newParams: Record<string, string>) => {
      const [path, queryParams = ''] = asPath.split('?');

      const updatedParams = Object.fromEntries(new URLSearchParams(queryParams));
      Object.entries(newParams).forEach(([key, value]) => {
        updatedParams[key] = value;
      });

      const updatedQueryParams = new URLSearchParams(updatedParams).toString();
      return path + (updatedQueryParams ? `?${updatedQueryParams}` : '');
    },
    [asPath]
  );

  return { navigateToPage, navigateToPageId, getUpdatedRoute };
}

export function useNavigateToFirstPossiblePage(document: Y.Doc) {
  const { navigateToPageId } = useNavigation();
  const { pagesData } = usePages(document);
  const firstPageId = pagesData[0]?.id;

  return useCallback(() => {
    void navigateToPageId(firstPageId);
  }, [firstPageId, navigateToPageId]);
}
