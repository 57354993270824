import { Box, Popover } from '@mantine/core';
import cx from 'clsx';
import sortBy from 'lodash/sortBy';
import { ReactElement, ReactNode } from 'react';

import { AdditionalAction } from './AdditionalAction';
import { BaseAction } from './BaseAction';
import { widgetContent, menusWrapper, menuContainer, additionalActionsMenu } from './WidgetActionsBar.css';
import { Copy } from '@/icons/Copy';
import { Trashcan } from '@/icons/Trashcan';
import { WidgetAction, WidgetAdditionalAction } from '@/types/Stage';

type Action = {
  name: WidgetAction;
  handler: (id?: string) => void;
};
type WidgetActionsBarProps = {
  children: ReactNode;
  actions?: Action[];
  additionalActions?: WidgetAdditionalAction[];
  id?: string;
  className?: string;
  isOpened?: boolean;
};

const iconsMap: Record<WidgetAction, ReactElement> = {
  Delete: <Trashcan />,
  Duplicate: <Copy />
};
export function WidgetActionsBar(props: WidgetActionsBarProps) {
  const { children, actions = [], className, additionalActions = [], id, isOpened = false } = props;

  const hasActions = actions.length > 0;
  const hasAdditionalActions = additionalActions.length > 0;

  return hasActions || hasAdditionalActions ? (
    <Popover
      transitionProps={{ duration: 0 }}
      middlewares={{ flip: false, shift: false }}
      position="top-start"
      opened={isOpened}
      zIndex={4}
      withinPortal={false}
    >
      <Popover.Target>
        <Box className={cx(widgetContent, className)}>{children}</Box>
      </Popover.Target>
      <Popover.Dropdown id="widget-actions" className={menusWrapper}>
        {hasActions && (
          <Box className={menuContainer} data-testid="widget-actions">
            {sortBy(actions, action => action.name).map(action => (
              <BaseAction
                dataId={`widget-action-${action.name.toLowerCase()}`}
                key={action.name}
                onClick={() => action.handler(id)}
                icon={iconsMap[action.name]}
              />
            ))}
          </Box>
        )}
        {hasAdditionalActions && (
          <Box className={additionalActionsMenu}>
            {additionalActions.map((actionProps, i) => (
              <AdditionalAction key={`action-${i}`} {...actionProps} />
            ))}
          </Box>
        )}
      </Popover.Dropdown>
    </Popover>
  ) : (
    <Box className={className}>{children}</Box>
  );
}
