import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import * as Y from 'yjs';

import { CanvasObjectRepresentation } from '../types/Canvas';
import { OpenDiscussionAnswer } from '../types/OpenDiscussion';

type InteractivePollAnswer = string[];
type InteractiveQuizAnswer = string[];
type UserId = string;
export type InteractiveQuestionAnswers = Record<UserId, InteractivePollAnswer | InteractiveQuizAnswer>;
type QuestionId = string;
export type SlideId = string;
export type AnnotationData = CanvasObjectRepresentation;

export type ContentObjectType = Y.Map<unknown>;
export type CollaborativeState = {
  collaborativePageIndex: string;
  questionsAnswers: Record<QuestionId, InteractiveQuestionAnswers | OpenDiscussionAnswer[]>;
  questionsStates: Record<QuestionId, { isRevealed?: boolean }>;
  annotations: Record<SlideId, AnnotationData>;
};

export function getRootMap<T = unknown>(document: Y.Doc): Y.Map<T> {
  return document.getMap('root');
}

export function validateYMap<T = unknown>(toCheck?: unknown): Y.Map<T> {
  if (!(toCheck instanceof Y.Map)) {
    throw new Error('Object is not a Y.Map.');
  }
  return toCheck;
}

export function validateYArray<T = unknown>(toCheck?: unknown): Y.Array<T> {
  if (!(toCheck instanceof Y.Array)) {
    throw new Error('Object is not a Y.Array.');
  }
  return toCheck;
}

export function getCollaborativeState(document: Y.Doc): Y.Map<CollaborativeState> {
  // collaborativeState isn't persisted to the DB, so this function throws on the server if we don't supply a fallback
  return validateYMap(document.getMap('root').get('collaborativeState') ?? new Y.Map());
}

export function getRootChildrenArray(document: Y.Doc): Y.Array<string> {
  return validateYArray<string>(document.getMap('root').get('children'));
}

export function getContentObjectsMap(document: Y.Doc): Y.Map<ContentObjectType> {
  return validateYMap(document.getMap('root').get('contentObjects'));
}

export function getFormDataMap(document: Y.Doc): Y.Map<ContentObjectType> {
  return validateYMap(document.getMap('root').get('widgetsFormData'));
}

export function getSensitiveDataMap(document: Y.Doc): Y.Map<ContentObjectType> {
  return validateYMap(document.getMap('root').get('sensitiveData'));
}

export function getWidgetCustomCSSClassesMap(document: Y.Doc): Y.Map<string[]> {
  return validateYMap(document.getMap('root').get('widgetsCustomCSSClasses'));
}

export function findPositionInArray<T = unknown>(array: Y.Array<T>, item: T) {
  return array.toArray().findIndex(element => element === item);
}

export function useObservedProperty<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapToObserve: Y.Map<any>,
  propertyName?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: { deepObserve: boolean; selector: (newData: any) => T }
): [T, Dispatch<SetStateAction<T>>];

export function useObservedProperty<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapToObserve: Y.Map<any>,
  propertyName?: string,
  options?: { deepObserve: boolean }
): [T, Dispatch<SetStateAction<T>>];

export function useObservedProperty<T>(
  mapToObserve: Y.Map<T>,
  propertyName?: string,
  options: {
    deepObserve: boolean;
    selector?: (newData: unknown) => T;
  } = { deepObserve: false }
): [T, Dispatch<SetStateAction<T>>] {
  const { deepObserve, selector: selectorFn } = options;
  const selector = useCallback(
    (map: Y.Map<T>) => {
      if (!selectorFn && propertyName) {
        return map.get(propertyName) as T;
      }
      if (selectorFn && !propertyName) {
        return selectorFn(map);
      }
      if (selectorFn && propertyName) {
        return selectorFn(map.get(propertyName));
      }
      return map as T;
    },
    [selectorFn, propertyName]
  );

  const [value, setValue] = useState<T>(() => selector(mapToObserve));
  useEffect(() => {
    // Reinitialize when remounted
    const newValue = selector(mapToObserve);
    setValue(currentValue => {
      if (!isEqual(currentValue, newValue)) {
        return newValue;
      }
      return currentValue;
    });

    const handler = () => {
      const newValue = selector(mapToObserve);
      setValue(currentValue => {
        if (!isEqual(currentValue, newValue)) {
          return newValue;
        }
        return currentValue;
      });
    };

    if (deepObserve) {
      mapToObserve.observeDeep(handler);
      return () => mapToObserve.unobserveDeep(handler);
    } else {
      mapToObserve.observe(handler);
      return () => mapToObserve.unobserve(handler);
    }
  }, [mapToObserve, propertyName, deepObserve, selector]);

  const handleSetValue = useCallback(
    (newValue: T | ((prevState: T) => T)) => {
      if (!propertyName) {
        throw new Error('Cannot set value without property name');
      }
      if (newValue instanceof Function) {
        setValue(prevState => {
          const newState = newValue(prevState);
          const currentValue = mapToObserve.get(propertyName);
          if (!isEqual(currentValue, newState)) {
            mapToObserve.set(propertyName, newState);
          }
          return newState;
        });
      } else {
        setValue(newValue);
        const currentValue = mapToObserve.get(propertyName);
        if (!isEqual(currentValue, newValue)) {
          mapToObserve.set(propertyName, newValue);
        }
      }
    },
    [mapToObserve, propertyName]
  );

  return [value, handleSetValue];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useObservedArray<T>(mapToObserve: Y.Map<any>, propertyName: string) {
  const observedArray = mapToObserve.get(propertyName) as Y.Array<T>;
  const [value, setValue] = useState<T[]>(() => observedArray.toArray());

  useEffect(() => {
    // Reinitialize when remounted
    const newValue = observedArray.toArray();
    setValue(currentValue => (!isEqual(currentValue, newValue) ? newValue : currentValue));

    const handler = () => {
      const newValue = observedArray.toArray();
      setValue(currentValue => (!isEqual(currentValue, newValue) ? newValue : currentValue));
    };

    observedArray.observe(handler);
    return () => observedArray.unobserve(handler);
  }, [observedArray, propertyName]);

  return [value, observedArray] as const;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useObservedText(mapToObserve: Y.Map<any>, propertyName: string) {
  const observedText = mapToObserve.get(propertyName) as Y.Text;
  const [value, setValue] = useState<string>(() => observedText.toString());

  useEffect(() => {
    // Reinitialize when remounted
    setValue(observedText.toString());

    const handler = () => {
      setValue(observedText.toString());
    };

    observedText.observe(handler);
    return () => observedText.unobserve(handler);
  }, [observedText, propertyName]);

  return [value, observedText] as const;
}

type SensitiveDataMap = Y.Map<Y.Map<unknown>>;
type ContentObjectsMap = Y.Map<Y.Map<unknown>>;
type FormDataMap = Y.Map<Y.Map<unknown>>;

export function extractYjsMaps(document: Y.Doc, id: string) {
  const sensitiveDataMap = getSensitiveDataMap(document)?.get(id) as SensitiveDataMap;
  const contentObjectsMap = getContentObjectsMap(document)?.get(id) as ContentObjectsMap;
  const formDataMap = getFormDataMap(document)?.get(id) as FormDataMap;

  return { sensitiveDataMap, contentObjectsMap, formDataMap };
}

type useConvertObservedYXmlFragmentProps<T> = {
  fragment?: Y.XmlFragment;
  convertFunction: (v: Y.XmlFragment) => T;
};
export function useConvertObservedYXmlFragment<T>(props: useConvertObservedYXmlFragmentProps<T>) {
  const { fragment, convertFunction } = props;
  const [observeChange, setObserveChange] = useState(0);

  const result = useMemo(
    () => (fragment !== undefined ? convertFunction(fragment) : null),
    // observeChange is needed in dep array, but it's not being used in useMemo callback
    [fragment, convertFunction, observeChange]
  );

  useEffect(() => {
    const triggerObserveChange = debounce(() => {
      setObserveChange(prevState => prevState + 1);
    }, 300);

    fragment?.observeDeep(triggerObserveChange);
    return () => fragment?.unobserveDeep(triggerObserveChange);
  }, [fragment]);

  return result;
}
