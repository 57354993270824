import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    dndPreview: {
      transformOrigin: 'left top',
      opacity: 1,
      zIndex: 1000
    }
  };
});
