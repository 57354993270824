import { useEffect } from 'react';

import { Feedbacks } from 'shared/types/Feedbacks';

import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useAlignFeedbackWithParent() {
  const questionGroupSettings = useParentQuestionGroupSettings();

  const [, setFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');

  useEffect(() => {
    if (questionGroupSettings?.feedback && questionGroupSettings.feedback !== Feedbacks.None) {
      setFeedback(questionGroupSettings.feedback);
    }
  }, [questionGroupSettings?.feedback, setFeedback]);
}
