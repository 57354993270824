import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type ToolbarSelectStylesProps = {
  maxWidth?: number;
  direction?: string;
};

export const useStyles = createStyles((theme, props: ToolbarSelectStylesProps = {}) => {
  const { maxWidth = 95, direction = 'ltr' } = props;

  return {
    selectRoot: {
      maxWidth: rem(maxWidth),
      height: rem(36),
      '& [role=combobox]': {
        '&:hover': {
          borderRadius: rem(8),
          background: theme.colors.gray[0]
        },
        '&[aria-expanded=true]': {
          background: theme.colors.gray[2],
          borderRadius: rem(8)
        }
      },
      position: 'relative'
    },
    selectItem: {
      textAlign: direction === 'ltr' ? 'left' : 'right',
      display: 'flex',
      justifyContent: 'center',
      '&[data-hovered]': {
        '&, &:hover': {
          background: theme.colors.gray[2],
          borderRadius: rem(8),
          color: 'unset',
          textAlign: direction === 'ltr' ? 'left' : 'right'
        }
      },
      '&[aria-selected=true]': {
        '&, &:hover': {
          background: theme.colors.gray[2],
          borderRadius: rem(8),
          color: 'unset'
        }
      }
    },
    selectRightSection: {
      pointerEvents: 'none'
    },
    selectIcon: {
      paddingInlineStart: rem(4)
    },
    selectDropdown: {
      minWidth: rem(maxWidth * 2)
    },
    selectInput: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&[data-mantine-stop-propagation=true]': {
        pointerEvents: 'none' // prevent placeholder truncate bug
      },
      '&[data-with-icon]': {
        paddingInlineStart: rem(20)
      },
      color: theme.colors.gray[7],
      userSelect: 'none'
    },
    selectInputHidden: {
      opacity: 0
    },
    selectLabel: {
      '[data-on]': {
        '&[data-on=true], &[data-on=true]:hover': {
          background: 'rgba(245, 245, 255, 1)',
          borderRadius: rem(8),
          color: theme.colors.accent[6]
        },
        width: '100%',
        position: 'absolute',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingInlineStart: rem(10)
      },
      width: '100%',
      position: 'absolute',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    selectLabelEnd: {
      justifyContent: 'end',
      paddingInlineEnd: rem(10)
    }
  };
});
