import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewEnclosureWithCombinedTitleData } from 'shared/widgetsSDK/EnclosureWithCombinedTitle/create';

import { manifestLocales } from './manifest.locales';
import EnclosureWithCombinedTitleEn from './resources/en/enclosure-with-combined-title.svg';
import EnclosureWithCombinedTitleHe from './resources/he/enclosure-with-combined-title.svg';

export const Manifest: ManifestType = {
  name: WidgetType.EnclosureWithCombinedTitle,
  previewGroups: [
    {
      id: 'enclosureWithCombinedTitle',
      divider: true,
      previews: [
        {
          name: 'enclosureWithCombinedTitlePreview',
          previewImage: {
            paths: {
              en: EnclosureWithCombinedTitleEn,
              he: EnclosureWithCombinedTitleHe
            },
            alt: { ...manifestLocales.enclosureWithCombinedTitle.previewImageAlt },
            size: {
              width: 285,
              height: 200
            }
          },
          initializeProps: options =>
            createNewEnclosureWithCombinedTitleData(options, {
              content: `<h1><span>${
                manifestLocales.enclosureWithCombinedTitle.initialText[options?.locale ?? 'he']
              }</span></h1>`
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
