import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  clearButton: {
    width: rem(36),
    height: rem(36),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.accent[5],
    '&: hover': {
      backgroundColor: theme.colors.accent[5]
    }
  },
  clearIcon: {
    width: rem(24),
    height: rem(24),
    color: theme.white
  }
}));
