import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewInteractivePollData } from 'shared/widgetsSDK/InteractivePoll/create';
import { manifestLocales } from 'shared/widgetsSDK/InteractivePoll/manifest.locales';

import IconEn from './resources/en/icon.svg';
import IconHe from './resources/he/icon.svg';
import { Poll } from '@/icons/Poll';

export const Manifest: ManifestType = {
  name: WidgetType.InteractivePoll,
  previewGroups: [
    {
      id: 'interactivePoll',
      divider: {
        title: 'interactivePoll',
        icon: Poll
      },
      previews: [
        {
          name: 'imagePreview',
          previewImage: {
            paths: {
              en: IconEn,
              he: IconHe
            },
            size: {
              width: 140,
              height: 108
            },
            alt: { ...manifestLocales.interactivePoll.previewImageAlt }
          },
          initializeProps: createNewInteractivePollData,
          dimensions: {
            width: 800,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minWidth: 760,
      minHeight: 260
    }
  }
};
