import { ReactNode } from 'react';

import { ColorInput } from '@/components/ColorInput';
import { ThemeControl } from '@/components/Theme/ThemeControl';

type ColorInputPanelProps = {
  setColor: (newValue: string) => void;
  color?: string;
  globalPath?: string;
  label?: string;
  path?: string;
  ResetButton?: ReactNode;
};

export const ColorInputPanel = (props: ColorInputPanelProps) => {
  const { setColor, color, globalPath, label, path, ResetButton } = props;
  return (
    <ThemeControl path={path} label={label} globalPath={globalPath}>
      <ColorInput value={color ?? ''} onChange={setColor} />
      {ResetButton}
    </ThemeControl>
  );
};
