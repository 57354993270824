import { Box } from '@mantine/core';
import * as Y from 'yjs';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { getContentObjectsMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useCombinedTitleStyleProperties } from '@/widgets/CombinedTitle/hooks/useCombinedTitleStyleProperties';
import { Config } from '@/widgets/EnclosureWithCombinedTitle/config';
import { Studio as FlexSection } from '@/widgets/FlexSection/studio';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

const acceptedItemTypes: ItemDragType[] = [];
export function EnclosureWithCombinedTitle(props: StudioProps) {
  const { id, document } = useContentObject();

  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');
  const { alignItems, gap, itemsOrder } = useCombinedTitleStyleProperties(id, document);
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSectionId] = useContentObjectProperty<string>('children.1');
  const [verticalSection] = useObservedProperty<Y.Map<unknown>>(contentObjectsMap, verticalSectionId);
  const [verticalSectionChildren] = useObservedArray<string>(verticalSection, 'children');
  const isEmpty = verticalSectionChildren.length === 0;

  const [direction] = useWidgetStyle<FlexSectionDirection>(document, id, 'dropzoneDirection');
  const [dropzoneGap] = useWidgetStyle<number>(document, id, 'dropzoneGap');
  const [marginEnd] = useWidgetStyle<number>(document, id, 'dropzoneMarginEnd');
  const [marginStart] = useWidgetStyle<number>(document, id, 'dropzoneMarginStart');

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>

      <div className={customClassNames}>
        <ContentObjectProvider id={horizontalSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            <FlexSection
              alignItems={alignItems}
              gap={gap}
              itemsOrder={itemsOrder}
              shouldApplyNestStyles={false}
              withConfig={false}
            />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
        <ContentObjectProvider id={verticalSectionId} document={document} type={WidgetType.FlexSection}>
          <Box sx={{ marginInlineStart: marginStart, marginInlineEnd: marginEnd }}>
            {isEmpty ? (
              <DropzonePlaceholder type="learningItems" />
            ) : (
              <FlexSection direction={direction} gap={dropzoneGap} withConfig={false} />
            )}
          </Box>
        </ContentObjectProvider>
      </div>
    </>
  );
}
