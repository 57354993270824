import { Box } from '@mantine/core';
import { useDrop } from 'react-dnd';
import * as Y from 'yjs';

import { findPositionInArray } from 'shared/widgetsSDK/yjs';

import { questionListDndType } from './QuestionBank';
import { useStyles } from './QuestionBank.styles';
import { useCurrentDocument } from '@/components/YjsProvider';
import { calculateNewListPosition } from '@/utils/calculateNewListPosition';

type QuestionDropIndicatorProps = {
  verticalSectionChildren: Y.Array<string>;
  positionIndex: number;
};

export function QuestionDropIndicator(props: QuestionDropIndicatorProps) {
  const { positionIndex, verticalSectionChildren } = props;
  const document = useCurrentDocument();
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: questionListDndType,
      drop(item: { id: string }) {
        moveQuestionBankItem(document, item.id, positionIndex, verticalSectionChildren);
      },
      collect(monitor) {
        return { isOver: monitor.isOver() };
      }
    }),
    [document, positionIndex]
  );

  const { classes } = useStyles({ isOver });

  return (
    <Box ref={drop} className={classes.dndItemIndicator} data-test-id="indicator">
      <Box className={classes.dndItemIndicatorBar} />
    </Box>
  );
}

function moveQuestionBankItem(
  document: Y.Doc,
  questionId: string,
  newIndex: number,
  verticalSectionChildren: Y.Array<string>
) {
  document.transact(() => {
    const currentIndex = findPositionInArray(verticalSectionChildren, questionId);
    if (currentIndex === -1) {
      throw new Error(`Item ${questionId} not found in root children.`);
    }
    const calculatedNewIndex = calculateNewListPosition(currentIndex, newIndex);
    if (calculatedNewIndex === -1) {
      return;
    }

    verticalSectionChildren.delete(currentIndex, 1);
    verticalSectionChildren.insert(calculatedNewIndex, [questionId]);
  });
}
