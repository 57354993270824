import { Box } from '@mantine/core';
import { ComponentType } from 'react';
import { z } from 'zod';

import { useStyles } from './EmbedIframe.styles';
import { sanitizeAndValidateIframe } from './utils/iframe';
import { EmbedPlaceholder } from '@/icons/EmbedPlaceholder';
import { WorkMode } from '@/types/Stage';
import { Placeholder } from '@/widgets/_components/widget/Placeholder';

type GeneralEmbedProps = {
  workMode: WorkMode;
  placeholderText?: string;
  placeholderIcon?: ComponentType;
  src: string;
  title: string;
  tabIndex?: number;
};

export const EmbedIframe = (props: GeneralEmbedProps) => {
  const { src, title = '', workMode, placeholderText, placeholderIcon, tabIndex } = props;

  const isEditMode = workMode === 'Edit';
  const { classes } = useStyles({ isEditMode });

  const isValidIframe = (input: string): boolean => {
    const iframeSchema = z.string().regex(/^<iframe\s[^>]*><\/iframe>$/i);
    return iframeSchema.safeParse(input).success;
  };

  const isUrl = (value: string) => {
    const urlSchema = z.string().url();
    return urlSchema.safeParse(value).success;
  };

  const isValidSrc = (value?: string): value is string => {
    return !!value && (isValidIframe(value) || isUrl(value));
  };

  if (!isValidSrc(src)) {
    return isEditMode && placeholderText ? (
      <Placeholder Icon={EmbedPlaceholder} text={placeholderText} />
    ) : (
      <Placeholder Icon={placeholderIcon ?? EmbedPlaceholder} />
    );
  }

  if (isValidIframe(src)) {
    const sanitizedIframeMarkup = sanitizeAndValidateIframe(src);

    return (
      <Box className={classes.iframeContainer}>
        <Box className={classes.injectedIframe} dangerouslySetInnerHTML={{ __html: sanitizedIframeMarkup }} />
      </Box>
    );
  }

  return (
    <Box className={classes.iframeContainer}>
      <iframe tabIndex={tabIndex} src={src} title={title} className={classes.iframe} />
    </Box>
  );
};
