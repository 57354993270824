import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { BarChart, Bar, Cell, XAxis, LabelList, ResponsiveContainer } from 'recharts';

import { BarChartAxisLabel } from './BarChartAxisLabel';
import { ASPECT_RATIO, ChartVariant, Data, FONT_SIZE, IMG_HEIGHT, VERTICAL_MARGIN } from './Chart';
import { LabelListContent } from './LabelListContent';
import { Direction } from '@/utils/locale.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';

const applyVerticalMargins = (item: number) => item + VERTICAL_MARGIN * 2;

const barRadius: [number, number, number, number] = [6, 6, 0, 0];

type HorizontalBarChartProps = {
  data: Data;
  directionStyle: { direction: Direction };
  getBarColor: (index: number, isCorrect?: boolean) => string;
  variant: ChartVariant;
  dataHasImage?: boolean;
  dataHasLabel?: boolean;
};

export const HorizontalBarChart = (props: HorizontalBarChartProps) => {
  const { data, dataHasImage, dataHasLabel, directionStyle, getBarColor, variant } = props;

  const textHeight = FONT_SIZE * 6; // support a sane value of 6 lines
  const dataWithImageAndLabel = dataHasImage && dataHasLabel;

  const xAxisHeight = dataWithImageAndLabel
    ? applyVerticalMargins(IMG_HEIGHT + VERTICAL_MARGIN + textHeight)
    : dataHasImage
    ? applyVerticalMargins(IMG_HEIGHT)
    : applyVerticalMargins(textHeight);

  const theme = useMantineTheme();
  const { uiDirection } = useUiLanguage();

  const margin = useMemo(() => ({ top: variant === 'validated' ? 100 : 40, right: 0, left: 0, bottom: 0 }), [variant]);

  return (
    <ResponsiveContainer aspect={ASPECT_RATIO} style={directionStyle}>
      <BarChart data={data} margin={margin} barSize={144}>
        <Bar dataKey="value" radius={barRadius}>
          <LabelList
            dataKey="value"
            position="top"
            fill={theme.colors.systemDark[8]}
            fontSize={FONT_SIZE}
            dy={-10}
            content={
              variant === 'validated' ? (
                <LabelListContent data={data} uiDirection={uiDirection} direction="horizontal" />
              ) : undefined
            }
          />
          {data.map((dataItem, index) => (
            <Cell key={index} fill={getBarColor(index, dataItem.isCorrect)} />
          ))}
        </Bar>
        <XAxis
          dataKey="key"
          interval={0}
          height={xAxisHeight}
          tick={props => <BarChartAxisLabel {...props} data={data} direction="horizontal" />}
          tickLine={false}
          stroke="#495057"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
