import { StateCreator } from 'zustand';

import { DeviceType } from 'shared/types/DeviceType';

import { UnitedStore } from '.';
import { ToolboxCategories, ToolboxSubCategories } from '@/components/Toolbox/ToolboxData';

export type Toolbox = {
  selectedCategory: ToolboxCategories | null;
  selectedSubCategory: ToolboxSubCategories | null;
  toolboxSelectedMenuSubItem: string;
  opened: boolean;
};

export type UiSlice = {
  deviceView: DeviceType;
  stageScale: number;
  isFullPageLoadingOverlayVisible: boolean;
  toolbox: Toolbox;
  setDeviceView: (device: DeviceType) => void;
  setStageScale: (scale: number) => void;
  updateStageScaleBy: (scale: number) => void;
  setIsFullPageLoadingOverlayVisible: (isVisible: boolean) => void;
  updateToolbox: (data: Partial<Toolbox>) => void;
};

export const createUiSlice: StateCreator<UnitedStore, [['zustand/immer', never]], [], UiSlice> = (set, get, api) => ({
  deviceView: 'pc',
  stageScale: 1,
  isFullPageLoadingOverlayVisible: false,
  toolbox: {
    selectedCategory: null,
    selectedSubCategory: null,
    toolboxSelectedMenuSubItem: '',
    opened: false
  },
  setDeviceView: device =>
    set(state => {
      state.deviceView = device;
    }),
  setStageScale: scale =>
    set(state => {
      state.stageScale = scale;
    }),
  updateStageScaleBy: value =>
    set(state => {
      //Needs to be rounded since javaScript floating point math is a little off sometimes
      const roundedScale = Math.round((state.stageScale + value) * 20) / 20;
      state.stageScale = roundedScale;
    }),
  setIsFullPageLoadingOverlayVisible: isVisible =>
    set(state => {
      state.isFullPageLoadingOverlayVisible = isVisible;
    }),
  updateToolbox: data => {
    set(state => {
      const toolbox = { ...state.toolbox, ...data };
      return { ...state, toolbox };
    });
  }
});
