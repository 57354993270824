import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';

import { Manifest as AudioManifest } from '@/widgets/Audio/Manifest';
import { Manifest as BlockTitleManifest } from '@/widgets/BlockTitle/Manifest';
import { Manifest as ClozeQuestionManifest } from '@/widgets/ClozeQuestion/Manifest';
import { Manifest as CombinedTitleManifest } from '@/widgets/CombinedTitle/Manifest';
import { Manifest as EmbedManifest } from '@/widgets/Embed/Manifest';
import { Manifest as EnclosureWithBlockTitleManifest } from '@/widgets/EnclosureWithBlockTitle/Manifest';
import { Manifest as EnclosureWithCombinedTitleManifest } from '@/widgets/EnclosureWithCombinedTitle/Manifest';
import { Manifest as FillInTheBlanksQuestionManifest } from '@/widgets/FillInTheBlanksQuestion/Manifest';
import { Manifest as FlexSectionManifest } from '@/widgets/FlexSection/Manifest';
import { Manifest as ImageManifest } from '@/widgets/Image/Manifest';
import { Manifest as InteractivePollManifest } from '@/widgets/InteractivePoll/Manifest';
import { Manifest as InteractiveQuizManifest } from '@/widgets/InteractiveQuiz/Manifest';
import { Manifest as MicrosoftEmbedManifest } from '@/widgets/MicrosoftEmbed/Manifest';
import { Manifest as OpenDiscussionManifest } from '@/widgets/OpenDiscussion/Manifest';
import { Manifest as OpenQuestionManifest } from '@/widgets/OpenQuestion/Manifest';
import { Manifest as PdfDocumentManifest } from '@/widgets/PdfDocument/Manifest';
import { Manifest as QuestionGroupListManifest } from '@/widgets/QuestionGroupList/Manifest';
import { Manifest as SelectionQuestionManifest } from '@/widgets/SelectionQuestion/Manifest';
import { Manifest as SpacerManifest } from '@/widgets/Spacer/Manifest';
import { Manifest as SubmitPageCommentManifest } from '@/widgets/SubmitPageComment/Manifest';
import { Manifest as SubmitPageReturnToFixSwitchManifest } from '@/widgets/SubmitPageReturnToFixSwitch/Manifest';
import { Manifest as SubmitPageSatisfactionSurveyManifest } from '@/widgets/SubmitPageSatisfactionSurvey/Manifest';
import { Manifest as SubmitPageTitleManifest } from '@/widgets/SubmitPageTitle/Manifest';
import { Manifest as SubmitPageUnansweredQuestionsManifest } from '@/widgets/SubmitPageUnansweredQuestions/Manifest';
import { Manifest as SummaryPageSubmissionHistoryManifest } from '@/widgets/SummaryPageSubmissionHistory/Manifest';
import { Manifest as SummaryPageTaskSubmittedNotificationManifest } from '@/widgets/SummaryPageTaskSubmittedNotification/Manifest';
import { Manifest as TableManifest } from '@/widgets/Table/Manifest';
import { Manifest as TextManifest } from '@/widgets/Text/Manifest';
import { Manifest as VideoManifest } from '@/widgets/Video/Manifest';
import { Manifest as AvatarWidgetManifest } from '@/widgets/AvatarWidget/Manifest';

export type WidgetWithManifests =
  | WidgetType.AvatarWidget
  | WidgetType.Audio
  | WidgetType.BlockTitle
  | WidgetType.ClozeQuestion
  | WidgetType.CombinedTitle
  | WidgetType.Embed
  | WidgetType.EnclosureWithBlockTitle
  | WidgetType.EnclosureWithCombinedTitle
  | WidgetType.FillInTheBlanksQuestion
  | WidgetType.FlexSection
  | WidgetType.Image
  | WidgetType.InteractivePoll
  | WidgetType.MicrosoftEmbed
  | WidgetType.OpenDiscussion
  | WidgetType.OpenQuestion
  | WidgetType.PdfDocument
  | WidgetType.QuestionGroupList
  | WidgetType.InteractiveQuiz
  | WidgetType.SelectionQuestion
  | WidgetType.Spacer
  | WidgetType.SubmitPageComment
  | WidgetType.SubmitPageReturnToFixSwitch
  | WidgetType.SubmitPageSatisfactionSurvey
  | WidgetType.SubmitPageTitle
  | WidgetType.SubmitPageUnansweredQuestions
  | WidgetType.SummaryPageSubmissionHistory
  | WidgetType.SummaryPageTaskSubmittedNotification
  | WidgetType.Table
  | WidgetType.Text
  | WidgetType.Video;

export const widgetManifests: Record<WidgetWithManifests, ManifestType> = {
  AvatarWidget: AvatarWidgetManifest,
  Audio: AudioManifest,
  BlockTitle: BlockTitleManifest,
  ClozeQuestion: ClozeQuestionManifest,
  CombinedTitle: CombinedTitleManifest,
  Embed: EmbedManifest,
  EnclosureWithBlockTitle: EnclosureWithBlockTitleManifest,
  EnclosureWithCombinedTitle: EnclosureWithCombinedTitleManifest,
  FillInTheBlanksQuestion: FillInTheBlanksQuestionManifest,
  FlexSection: FlexSectionManifest,
  Image: ImageManifest,
  InteractivePoll: InteractivePollManifest,
  InteractiveQuiz: InteractiveQuizManifest,
  MicrosoftEmbed: MicrosoftEmbedManifest,
  OpenDiscussion: OpenDiscussionManifest,
  OpenQuestion: OpenQuestionManifest,
  PdfDocument: PdfDocumentManifest,
  QuestionGroupList: QuestionGroupListManifest,
  SelectionQuestion: SelectionQuestionManifest,
  Spacer: SpacerManifest,
  SubmitPageComment: SubmitPageCommentManifest,
  SubmitPageReturnToFixSwitch: SubmitPageReturnToFixSwitchManifest,
  SubmitPageSatisfactionSurvey: SubmitPageSatisfactionSurveyManifest,
  SubmitPageTitle: SubmitPageTitleManifest,
  SubmitPageUnansweredQuestions: SubmitPageUnansweredQuestionsManifest,
  SummaryPageSubmissionHistory: SummaryPageSubmissionHistoryManifest,
  SummaryPageTaskSubmittedNotification: SummaryPageTaskSubmittedNotificationManifest,
  Table: TableManifest,
  Text: TextManifest,
  Video: VideoManifest
};
