import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    unansweredQuestionsText: {
      fontSize: rem(24),
      textAlign: 'center'
    },
    unansweredQuestionsCount: {
      color: `${theme.colors.warning[0]} !important`,
      fontWeight: 'bold'
    }
  };
});
