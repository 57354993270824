import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { TaskSubmittedNotification } from '@/components/TaskSubmittedNotification';
import { useGetAssignmentStatus } from '@/queries/assignment';
import { StateStatus } from '@/queries/assignmentApi';
import { useSavedSummaryPageSeen } from '@/queries/state';
import { useUser } from '@/queries/user';

export function SummaryPageTaskSubmittedNotification() {
  const { query } = useRouter();
  const { markSummaryPageAsSeen, summaryPageSeen, isLoading } = useSavedSummaryPageSeen();
  const { status, isLoading: isStatusLoading } = useGetAssignmentStatus({ enabled: true });
  const { user } = useUser();

  const [summaryPageSeenLocal, setSummaryPageSeenLocal] = useState<boolean | null>(true);

  const isLoaded = !isLoading && !isStatusLoading;
  const isSubmitted = status === StateStatus.Submitted;
  const isStudent = user?.role === 'student';

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (summaryPageSeenLocal) {
      setSummaryPageSeenLocal(!!summaryPageSeen);
    }

    if (isSubmitted && isStudent) {
      markSummaryPageAsSeen(true);
    }
  }, [isLoading, summaryPageSeen, summaryPageSeenLocal, markSummaryPageAsSeen, isSubmitted, isStudent]);

  return isLoaded ? (
    <TaskSubmittedNotification withAnimation={isSubmitted && !query.studentId && !summaryPageSeenLocal} />
  ) : null;
}
