import { Flex, Group, rem } from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';

import { useStyles } from './MultipleFilesButton.styles';
import { StyledActionIcon } from '@/components/StyledActionIcon';
import { GeneralDocument } from '@/icons/GeneralDocument';
import { Picture } from '@/icons/Picture';
import { Plus } from '@/icons/Plus';
import { useWorkMode } from '@/utils/useWorkMode';
import { UploadButton } from '@/widgets/_components/questions/AnswerProvider/UploadButton';
import { QuestionActionButton } from '@/widgets/_components/questions/QuestionActionButton';

type MultipleFilesButtonProps = {
  handleUploadFile: (file: File) => Promise<void>;
  handleUploadImage: (file: File) => Promise<void>;
  isFileButtonDisabled?: boolean;
  isImageButtonDisabled?: boolean;
  showFileButton?: boolean;
  showImageButton?: boolean;
};

export function MultipleFilesButton(props: MultipleFilesButtonProps) {
  const {
    handleUploadFile,
    handleUploadImage,
    isFileButtonDisabled,
    isImageButtonDisabled,
    showFileButton,
    showImageButton
  } = props;

  const [isMultipleButtonOpen, { toggle: toggleMultipleButton }] = useDisclosure(false);
  const { classes } = useStyles({ isMultipleButtonOpen });
  const t = useTranslations('widgets');
  const { isStudioEdit } = useWorkMode();

  return (
    <Group gap="xs">
      <Flex className={classes.multipleButtonContainer} gap={rem(15)} direction="row" align="center">
        <StyledActionIcon
          onClick={toggleMultipleButton}
          className={classes.openMultipleFilesButton}
          icon={<Plus width={20} height={20} />}
          label={`${t('addFiles')}`}
        />
        <Flex className={classes.multipleFilesButtons}>
          {isMultipleButtonOpen && (
            <Flex gap={rem(15)} direction="row">
              {showFileButton && (
                <UploadButton
                  addButton={({ onClick }) => (
                    <QuestionActionButton
                      disabled={isFileButtonDisabled}
                      label={t('fileAnswer.addFile')}
                      icon={<GeneralDocument width={30} height={30} />}
                      onClick={isStudioEdit ? () => console.log('studio edit') : onClick}
                    />
                  )}
                  disabled={isFileButtonDisabled}
                  handleUpload={handleUploadFile}
                />
              )}

              {showImageButton && (
                <UploadButton
                  accept={IMAGE_MIME_TYPE.join(',')}
                  addButton={({ onClick }) => (
                    <QuestionActionButton
                      disabled={isImageButtonDisabled}
                      label={t('imageAnswer.addImage')}
                      icon={<Picture width={30} height={30} />}
                      onClick={isStudioEdit ? () => console.log('studio edit') : onClick}
                    />
                  )}
                  disabled={isImageButtonDisabled}
                  handleUpload={handleUploadImage}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Group>
  );
}
