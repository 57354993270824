import { Tabs } from '@mantine/core';

import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  return (
    <WidgetConfig tabs={['behaviour']}>
      <Tabs.Panel key="behaviour" value="behaviour">
        <EvaluationSettings />
        <Feedback />
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
