import { Group } from '@mantine/core';
import { ConnectDragSource } from 'react-dnd';

import { AddActionButton } from './AddActionButton';
import { DeleteActionButton } from './DeleteActionButton';
import { DragActionButton } from './DragActionButton';
import { useStyles } from './TableTooltip.styles';
import { ColumnId, RowId } from '@/widgets/Table/utils';

export type TableMenuProps = (
  | {
      type: 'column';
      columnId: ColumnId;
      rowId?: undefined;
    }
  | {
      type: 'row';
      columnId?: undefined;
      rowId: RowId;
    }
) & {
  dragRef: ConnectDragSource | null;
};

export function TableMenu(props: TableMenuProps) {
  const { type } = props;
  const { classes } = useStyles({ type });

  return (
    <Group className={classes.group}>
      <DeleteActionButton {...props} />
      <AddActionButton {...props} />
      <DragActionButton {...props} />
    </Group>
  );
}
