import { Box } from '@mantine/core';
import cx from 'clsx';
import * as Y from 'yjs';

import { ClozeQuestionType } from 'shared/widgetsSDK/ClozeQuestion/create';

import { ClozeExtension } from './ClozeOption/ClozeExtension';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { playerAnswerStyles } from '@/widgets/ClozeQuestion/ClozeQuestion.css';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

type ClozeTextProps = {
  fragment: Y.XmlFragment | undefined;
};

const extraExtensions = [ClozeExtension];

export function ClozeText(props: ClozeTextProps) {
  const { fragment: answerFragment } = props;

  const clozeQuestionType = useContentObjectStaticProperty<string>('clozeQuestionType');
  const isTable = clozeQuestionType === ClozeQuestionType.Table;

  return (
    <Box className={cx({ [playerAnswerStyles]: !isTable, [ThemeClassNames.widgets.clozeQuestion.answer]: !isTable })}>
      <RichText
        dataTestIdPrefix="preview-cloze-question-answer"
        fragment={answerFragment}
        extraExtensions={extraExtensions}
        contentEditable={false}
        disableTypographyInheritance
      />
    </Box>
  );
}
