import { ActionIcon, Flex, rem, Text } from '@mantine/core';

import { useStyles } from './ConfirmationPaper.styles';
import { AcceptTick } from '@/icons/AcceptTick';
import { RejectCross } from '@/icons/RejectCross';
import { useUiLanguage } from '@/utils/useUiLanguage';

type ConfirmationPaperProps = {
  onAccept: () => void;
  onReject: () => void;
  text: string;
  dataTestId?: string;
};
export function ConfirmationPaper(props: ConfirmationPaperProps) {
  const { onAccept, onReject, text } = props;
  const { classes } = useStyles();
  const { uiDirection } = useUiLanguage();

  return (
    <Flex dir={uiDirection} direction="row" align="center" gap={rem(5)} data-testid="confirmation-paper">
      <Text className={classes.deleteText}>{text}</Text>
      <ActionIcon onClick={onAccept} variant="transparent" data-testid="confirmation-accept-button">
        <AcceptTick />
      </ActionIcon>
      <ActionIcon onClick={onReject} variant="transparent" data-testid="confirmation-cancel-button">
        <RejectCross />
      </ActionIcon>
    </Flex>
  );
}
