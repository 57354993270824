export const manifestLocales = {
  enclosureWithCombinedTitle: {
    previewImageAlt: {
      en: 'Enclosure With Combined Title',
      he: 'מקטע עם כותרת משולבת'
    },
    initialText: {
      en: 'Enclosure With Combined Title',
      he: 'מקטע עם כותרת משולבת'
    }
  }
};
