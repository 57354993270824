import { PlayerQuestionStage } from './PlayerQuestionStage';
import { useGetPluginById } from '@/queries/plugin';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function EvaluatablePlayer() {
  const [id] = useContentObjectProperty<string>('id');
  const [pluginId] = useContentObjectProperty<string>('pluginId');
  const { data } = useGetPluginById(pluginId);

  if (!data) {
    return null;
  }

  return <PlayerQuestionStage id={id} script={data.script} />;
}
