import { Flex, Radio } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { ChartType } from 'shared/types/ChartType';

import * as classes from './AnswersVisualizationPicker.css';
import { ChartHorizontal } from '@/icons/ChartHorizontal';
import { ChartPie } from '@/icons/ChartPie';
import { ChartVertical } from '@/icons/ChartVertical';
import { FormControl } from '@/widgets/_components/FormControl';

const options = [
  { value: ChartType.HorizontalBarChart, Icon: ChartHorizontal },
  { value: ChartType.VerticalBarChart, Icon: ChartVertical },
  { value: ChartType.PieChart, Icon: ChartPie }
];

type AnswersVisualizationPickerProps = {
  onChange: (value: ChartType) => void;
  supportedChartTypes: ChartType[];
  value: ChartType;
};

export const AnswersVisualizationPicker = (props: AnswersVisualizationPickerProps) => {
  const t = useTranslations('components.answersVisualization');
  const { onChange, value, supportedChartTypes } = props;

  return (
    <FormControl title={t('title')}>
      <Flex>
        <Radio.Group value={value} onChange={onChange as (value: string) => void}>
          <Flex gap={16}>
            {options
              .filter(option => supportedChartTypes.includes(option.value))
              .map(option => (
                <Radio
                  key={option.value}
                  classNames={{ inner: classes.inner, label: classes.label }}
                  label={
                    <Flex className={option.value === value ? classes.icon.selected : classes.icon.base}>
                      <option.Icon />
                    </Flex>
                  }
                  value={option.value}
                />
              ))}
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
};
