import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

type AnswersGridStylesProps = {
  isTextOnly?: boolean;
  columns: number;
  answersStyle: AnswerStyleType;
  deviceView: string;
};

const width: Record<AnswerStyleType, number> = {
  Button: 230,
  Default: 280,
  CheckboxWithoutBorder: 280
};

export const useStyles = createStyles((theme, props: AnswersGridStylesProps) => {
  const { columns, answersStyle, isTextOnly, deviceView } = props;

  const columnWidth = (isTextOnly || deviceView === 'mobile') && columns === 1 ? '1fr' : rem(width[answersStyle]);

  return {
    grid: {
      gap: deviceView === 'mobile' ? rem(16) : `${rem(32)} ${rem(16)}`,
      gridTemplateColumns: `repeat(${columns}, minmax(0, ${columnWidth}))`,
      gridAutoRows: 'max-content'
    }
  };
});
