import * as Y from 'yjs';

import { isQuestionOrGroupQuestion } from 'shared/questions/utils';
import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';
import { WidgetType } from 'shared/utils/widgets';
import { validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { yXmlFragmentToText } from '@/utils/tiptap/convert';

export type Layout = Record<string, { span: number; column: number; height: string | number }>;

export type ContentObject = {
  id: string;
  children: string[];
  type: WidgetType;
  label?: string;
  layout?: Layout;
  questionScoreMode?: QuestionScoreMode;
  relativeScoreMode?: RelativeScoreMode;
  visibleQuestionsCount?: number;
  isHidden?: boolean;
};

export type ContentObjectsMap = Record<string, ContentObject>;

export const selectContentObjects = (
  contentObjects: Y.Map<Y.Map<unknown>>,
  { includeLabel, questionsOnly }: { includeLabel: boolean; questionsOnly?: boolean }
) => {
  const processedContentObjects: ContentObjectsMap = {};

  contentObjects.forEach(contentObject => {
    const type = contentObject.get('type') as WidgetType;

    if (questionsOnly && !isQuestionOrGroupQuestion(type)) {
      return;
    }

    const id = contentObject.get('id') as string;
    const children = contentObject.get('children')
      ? validateYArray<string>(contentObject.get('children')).toArray()
      : [];
    const isHidden = contentObject.get('isHidden') as boolean; // for pages
    const layout = contentObject.get('layout')
      ? validateYMap<Y.Map<unknown>>(contentObject.get('layout')).toJSON()
      : undefined;

    let label = '';
    if (includeLabel) {
      const labelFragment = contentObject.get('labelFragment') as Y.XmlFragment | undefined;
      label = labelFragment ? yXmlFragmentToText(labelFragment) : '';
    }
    const questionScoreMode = contentObject.get('questionScoreMode') as QuestionScoreMode;
    const relativeScoreMode = contentObject.get('relativeScoreMode') as RelativeScoreMode;
    const visibleQuestionsCount = contentObject.get('relativeScoreMode') as number;

    processedContentObjects[id] = {
      id,
      children,
      layout,
      type,
      label,
      questionScoreMode,
      relativeScoreMode,
      isHidden,
      visibleQuestionsCount
    };
  });

  return processedContentObjects;
};
