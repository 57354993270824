import { Flex, Radio } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { ParentHeadline } from 'shared/types/QuestionGroupList';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function ParentHeadlineSettings() {
  const t = useTranslations('widgets');
  const [parentHeadline, setParentHeadline] = useContentObjectProperty<`${ParentHeadline}`>('parentHeadline');

  return (
    <FormControl title={t('questionGroupListSettings.parentHeadlineSettings.title')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Radio.Group value={parentHeadline} onChange={value => setParentHeadline(value as `${ParentHeadline}`)}>
          <Flex direction="column" gap={12}>
            {Object.values(ParentHeadline).map(headline => (
              <Radio
                key={headline}
                value={headline}
                disabled={headline === ParentHeadline.Automatic}
                label={t(`questionGroupListSettings.parentHeadlineSettings.${headline}`)}
              />
            ))}
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
}
