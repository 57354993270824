import { RandomizeSubQuestions } from './RandomizeSubQuestions';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';

export function Settings() {
  return (
    <>
      <RandomizeSubQuestions />
      <EvaluationSettings withShowGrade withRelativeScoreWeight />
      <Feedback withGroupCheckButtonSettings />
    </>
  );
}
