import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  extendedOptions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: rem(8),
    marginInlineEnd: rem(8)
  }
}));
