import { useDirection } from '@mantine/core';
import { useEffect } from 'react';

import { SupportedLocale } from 'shared/types/SupportedLocale';

import { useLearningObjectSnapshotAndDraftQuery } from './useLearningObjectSnapshotAndDraftQuery';
import { useWorkMode } from './useWorkMode';
import { getDirectionFromLanguage } from '@/utils/locale.utils';

export function useContentLanguage() {
  const { learningObjectDraft, learningObjectSnapshot, isLoading, isError } = useLearningObjectSnapshotAndDraftQuery();

  const { isPlayer } = useWorkMode();

  const contentLanguage = (
    isPlayer ? learningObjectSnapshot?.language : learningObjectDraft?.language
  ) as SupportedLocale;
  const contentDirection = getDirectionFromLanguage(contentLanguage);

  const { setDirection, dir } = useDirection();

  useEffect(() => {
    if (dir !== contentDirection) {
      setDirection(contentDirection);
    }
  }, [contentDirection, setDirection, dir]);

  return {
    contentDirection,
    contentLanguage,
    isError,
    isLoading
  };
}
