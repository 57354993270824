import * as Y from 'yjs';

import { LearningObjectPages, LearningObjectTypes } from '../../types/LearningObjectType';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';

export function createNewLearningObjectCommonPageData(learningObjectType: LearningObjectTypes) {
  const id = genRandId();

  const pageMap = new Y.Map([
    ['id', id],
    ['type', LearningObjectPages[learningObjectType]],
    ['title', ''],
    ['isHidden', false],
    ['style', new Y.Map()],
    ['stageStyle', new Y.Map()],
    ['children', new Y.Array()]
  ]);

  if (learningObjectType === LearningObjectTypes.PlenaryLesson) {
    const speakerNotesFragment = htmlToXmlFragment('');
    pageMap.set('speakerNotesFragment', speakerNotesFragment);
  }

  return [pageMap, id] as const;
}
