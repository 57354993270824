import { Accordion as MantineAccordion, AccordionProps } from '@mantine/core';

import * as classes from './Accordion.css';
import { ChevronAccordion } from '@/icons/ChevronAccordion';

export const Accordion = (props: AccordionProps & { dense?: boolean; secondary?: boolean }) => {
  return (
    <MantineAccordion
      classNames={{
        chevron: classes.chevron,
        control: classes.control,
        content: classes.content,
        item: classes.item,
        ...(props.secondary && {
          control: classes.subItemControl,
          content: classes.subItemContent
        }),
        ...(props.dense && {
          control: classes.controlDense,
          label: classes.labelDense
        })
      }}
      chevron={<ChevronAccordion />}
      {...props}
    />
  );
};

Accordion.Item = MantineAccordion.Item;
Accordion.Panel = MantineAccordion.Panel;
Accordion.Control = MantineAccordion.Control;
