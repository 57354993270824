import { AuthenticationModuleClientType } from '@hendrx/modules/authentication';
import { Button } from '@mantine/core';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import { useStyles } from './UnauthorizedPage.styles';

export const UnauthorizedPage: AuthenticationModuleClientType['UnauthorizedPage'] = ({
  onCallLogoutEndpoint,
  paths
}) => {
  const { login } = paths;
  const router = useRouter();

  const loginUrl = `${login}?redirectTo=${router.query.redirectTo}`;

  const { classes } = useStyles();

  const t = useTranslations('external-modules');

  const handleLogOut = async () => {
    await onCallLogoutEndpoint();
    await router.push(loginUrl);
  };

  return (
    <div className={classes.container}>
      <h1>{t('authenticationGoogle.unauthorizedPage.title')}</h1>
      <Button onClick={handleLogOut}>{t('authenticationGoogle.unauthorizedPage.button')}</Button>
    </div>
  );
};
