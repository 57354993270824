import { ClientModulesCreators } from '@hendrx/modules';

import { LoginPage } from './components/LoginPage';
import { RedirectPage } from './components/RedirectPage';
import { UnauthorizedPage } from './components/UnauthorizedPage';

export const createClientAuthenticationModule: ClientModulesCreators['authentication'] = () => ({
  LoginPage,
  RedirectPage,
  UnauthorizedPage
});
