import { Box } from '@mantine/core';
import { ReactNode } from 'react';

import { useStyles } from './WidgetNotification.styles';
import { Info } from '@/icons/Info';

type WidgetNotificationProps = {
  text: string;
  Icon?: ReactNode;
};

export function WidgetNotification(props: WidgetNotificationProps) {
  const { text, Icon = Info } = props;
  const { classes } = useStyles();
  return (
    <Box className={classes.notificationWrapper}>
      <Icon className={classes.notificationIcon} />
      {text}
    </Box>
  );
}
