import { useCallback, useEffect } from 'react';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';

import { InteractiveQuizAnswerOption } from './InteractiveQuizAnswerOption';
import { QuestionAnswersStudio } from '@/components/QuestionAnswersStudio';
import { useSelectableStore } from '@/components/Selectable';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { WidgetAction } from '@/types/Stage';
import { useDeleteAnswer } from '@/widgets/InteractiveQuiz/hooks/useDeleteAnswer';
import { useDuplicateAnswer } from '@/widgets/InteractiveQuiz/hooks/useDuplicateAnswer';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';
import { useReorderAnswer } from '@/widgets/hooks/useReorderAnswer';
import { StudioProps } from '@/widgets/types/studioProps';

export function InteractiveQuizAnswerStudio(props: StudioProps) {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.InteractiveQuiz}
      withOrchestrationWrapper={false}
    >
      <InteractiveQuizAnswerStudioImplementation {...props} />
    </ContentObjectProvider>
  );
}

function InteractiveQuizAnswerStudioImplementation(props: StudioProps) {
  const { initialize } = props;

  const { id } = useContentObject();
  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const [correctAnswers, setCorrectAnswers] = useContentObjectProperty<string[]>('correctAnswers', 'sensitiveData');
  const [amountOfColumns] = useContentObjectProperty<number>('amountOfColumns') ?? 1;

  const selectedId = useSelectableStore(state => state.selectedId);

  const reorderAnswer = useReorderAnswer();
  const duplicateAnswer = useDuplicateAnswer();
  const deleteAnswer = useDeleteAnswer();

  const { isTextOnly } = useAnswerElements();

  useEffect(() => {
    initialize?.({
      actions: [],
      isResizableVertically: false
    });
  }, [initialize]);

  const handleCheckboxChange = (newAnswers: string[]) => {
    setCorrectAnswers(newAnswers);
  };

  const getAnswerActions = useCallback(
    (answerIndex: number) => {
      const arr = [{ name: 'Duplicate' as WidgetAction, handler: () => duplicateAnswer(answerIndex) }];

      if (answers.length > 2) {
        arr.unshift({ name: 'Delete' as WidgetAction, handler: () => deleteAnswer(answerIndex) });
      }
      return arr;
    },
    [answers.length, deleteAnswer, duplicateAnswer]
  );

  const renderAnswerOption = useCallback(
    (answerIndex: number) => <InteractiveQuizAnswerOption answerIndex={answerIndex} />,
    []
  );

  return (
    <QuestionAnswersStudio
      amountOfColumns={amountOfColumns}
      answerClassName={ThemeClassNames.widgets.interactiveQuiz.answer}
      answers={answers}
      contextId={`InteractiveQuiz-${id}`}
      getAnswerActions={getAnswerActions}
      id={id}
      isTextOnly={isTextOnly}
      onChange={handleCheckboxChange}
      onDrop={reorderAnswer}
      renderAnswerOption={renderAnswerOption}
      selectedId={selectedId}
      value={correctAnswers}
    />
  );
}
