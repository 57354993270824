import { useState } from 'react';

import { RichTextExtraTools, RichTextTools, TextTypes } from '../types/RichTextToolbar';

type UseTextToolsProps = {
  textType?: TextTypes;
  withParagraphOption?: boolean;
  withCssTool?: boolean;
};

export const useTextTools = (props: UseTextToolsProps) => {
  const { textType, withParagraphOption = true, withCssTool = false } = props;
  return useState<(RichTextTools | RichTextExtraTools)[]>(() => {
    const tools: (RichTextTools | RichTextExtraTools)[] = Object.values(RichTextTools);
    if (withCssTool) {
      tools.push(RichTextExtraTools.Css);
    }
    if (textType === 'header') {
      tools.push(withParagraphOption ? RichTextExtraTools.TextLevelWithParagraph : RichTextExtraTools.TextLevelOnly);
      const index = tools.indexOf(RichTextTools.Lists);
      if (index > -1) {
        tools.splice(index, 1);
      }
    }
    return tools;
  });
};
