import { Metadata } from '@hendrx/modules/ui';

import { ReactComponent as RescueWheel } from './svgs/rescue-wheel.svg';

export const metadata: Metadata = {
  toolbarExternalLinks: [
    {
      Icon: RescueWheel,
      label: {
        en: 'Help center',
        he: 'מרכז התמיכה'
      },
      url: '/404',
      isPopup: true
    }
  ]
};
