import { SelectProps } from '@mantine/core';
import { ReactNode } from 'react';

import { Select } from '@/components/Select';
import { ThemeControl } from '@/components/Theme/ThemeControl';

const verticalAlignToData = [
  { label: 'start', value: 'flex-start' },
  { label: 'end', value: 'flex-end' },
  { label: 'center', value: 'center' },
  { label: 'baseline', value: 'baseline' },
  { label: 'stretch', value: 'stretch' }
];

const horizontalAlignToData = [
  { label: 'start', value: 'flex-start' },
  { label: 'end', value: 'flex-end' },
  { label: 'center', value: 'center' },
  { label: 'space between', value: 'space-between' },
  { label: 'space around', value: 'space-around' },
  { label: 'space evenly', value: 'space-evenly' }
];

type AlignToSelectPanelProps = {
  alignTo: string;
  label?: string;
  setAlignTo: (newValue: string) => void;
  type: 'vertical' | 'horizontal';
  ResetButton?: ReactNode;
  path?: string;
};

export const AlignToSelectPanel = (props: AlignToSelectPanelProps) => {
  const { alignTo, setAlignTo, type, label, ResetButton, path } = props;

  const data = type === 'horizontal' ? horizontalAlignToData : verticalAlignToData;

  return (
    <ThemeControl label={label} path={path}>
      <Select
        data={data}
        value={data.find(d => d.value === alignTo)?.value}
        onChange={setAlignTo as SelectProps['onChange']}
      />
      {ResetButton}
    </ThemeControl>
  );
};
