import { Stack, Tabs } from '@mantine/core';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { Select } from '@/components/Select';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import * as themeClasses from '@/components/Theme/Theme.css';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { VerticalAlignment } from '@/widgets/CombinedTitle/types';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

const alignToData = ['top', 'center', 'bottom'];
const imagePositionData = ['start', 'end'];

export function Config() {
  const { id, document } = useContentObject();
  const [verticalAlignment, setVerticalAlignment] = useWidgetStyle<VerticalAlignment | null>(
    document,
    id,
    'verticalAlignment'
  );
  const [imagePosition, setImagePosition] = useWidgetStyle<string | null>(document, id, 'imagePosition');
  const [gap, setGap] = useWidgetStyle<number | null>(document, id, 'gap');

  const [themeVerticalAlignment] = useActivityThemeStylesProperty<VerticalAlignment>(
    document,
    'combinedTitle.verticalAlignment'
  );
  const [themeImagePosition] = useActivityThemeStylesProperty<string>(document, 'combinedTitle.imagePosition');
  const [themeGap] = useActivityThemeStylesProperty<number>(document, 'combinedTitle.gap');

  const t = useTranslations('components.theme.settings');

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Stack className={themeClasses.contentStack}>
          <ThemeItemSection label={t('alignTo')}>
            <Select
              data={alignToData}
              value={verticalAlignment ?? themeVerticalAlignment}
              onChange={value => value && setVerticalAlignment(value as VerticalAlignment)}
            />
            {verticalAlignment && <ResetButton onClick={() => setVerticalAlignment(null)} />}
          </ThemeItemSection>
          <ThemeItemSection label={t('imagePosition')}>
            <Select
              data={imagePositionData}
              value={imagePosition ?? themeImagePosition}
              onChange={value => value && setImagePosition(value)}
            />
            {imagePosition && <ResetButton onClick={() => setImagePosition(null)} />}
          </ThemeItemSection>
          <ThemeItemSection label={t('spaceFromImage')}>
            <NumberInputPanel
              value={gap ?? themeGap}
              onChange={setGap}
              ResetButton={!isNil(gap) && <ResetButton onClick={() => setGap(null)} />}
            />
          </ThemeItemSection>
        </Stack>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
