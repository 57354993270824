import { useClickOutside } from '@mantine/hooks';
import { CSSProperties, FocusEvent, MouseEvent, ReactNode, useCallback, useId } from 'react';

import { useStyles } from './Selectable.styles';
import { useSelectableStore } from './hooks/useSelectableStore';

type SelectableProps = {
  children: ReactNode;
  id?: string;
  onDeselect?: () => void;
  onSelect?: () => void;
  style?: CSSProperties;
  unstyled?: boolean;

  // Props for widget / nested items with config
  itemConfigId?: string | null;
};

export function Selectable(props: SelectableProps) {
  const { children, id, itemConfigId, onSelect, onDeselect, style, unstyled } = props;

  const uniqueId = useId();
  const selectableId = itemConfigId || id || uniqueId;
  const isSelected = useSelectableStore(state => state.selectedId === selectableId);
  const select = useSelectableStore(state => state.select);
  const selectItemConfigId = useSelectableStore(state => state.selectItemConfigId);
  const setIsSelected = useSelectableStore(state => state.setIsSelected);
  const { classes } = useStyles({ isSelected });

  const handleSelectableTrigger = useCallback(
    (event: MouseEvent<HTMLDivElement> | FocusEvent) => {
      const target = event.target as HTMLElement;
      const selectableId = getClosestId(target, 'data-selectable-id');
      const targetedItemConfigIdValue = getClosestId(target, 'data-item-config-id');

      if (!selectableId) return;
      select(selectableId);

      if (selectableId === targetedItemConfigIdValue) {
        selectItemConfigId(null);
      } else {
        selectItemConfigId(targetedItemConfigIdValue);
      }

      if (typeof targetedItemConfigIdValue === 'undefined') {
        setIsSelected(false);
      } else setIsSelected(true);

      if (onSelect) {
        onSelect();
      }
    },
    [onSelect, select, selectItemConfigId, setIsSelected]
  );

  const clickOutsideRef = useClickOutside(() => {
    if (onDeselect) {
      onDeselect();
    }
  });

  return (
    <div
      ref={clickOutsideRef}
      onMouseDown={handleSelectableTrigger}
      onFocusCapture={handleSelectableTrigger}
      className={unstyled ? undefined : classes.selectable}
      data-selectable-id={selectableId}
      data-item-config-id={itemConfigId}
      style={style}
      aria-current={isSelected}
    >
      {children}
    </div>
  );
}

const getClosestId = (target: HTMLElement, attribute: string) =>
  target.closest(`[${attribute}]`)?.getAttribute(attribute);
