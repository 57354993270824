import { useEffect } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { StageElement } from '@/contentObjects/Plugin';
import { usePluginScriptContext } from '@/contentObjects/Plugin/utils/pluginScriptContext';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { useWorkMode } from '@/utils/useWorkMode';
import { ContentObjectProvider, useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function EvaluatablePluginAnswerStudio(props: StudioProps) {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.EvaluatablePlugin}
      withOrchestrationWrapper={false}
    >
      <EvaluatablePluginAnswerStudioImplementation {...props} />
    </ContentObjectProvider>
  );
}

function EvaluatablePluginAnswerStudioImplementation(props: StudioProps) {
  const { initialize } = props;
  const { id } = useContentObject();
  const script = usePluginScriptContext();

  const { contentLanguage } = useContentLanguage();
  const { uiLanguage } = useUiLanguage();
  const { isStudioEdit } = useWorkMode();

  useEffect(() => {
    initialize?.({
      actions: [],
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <StageElement
      id={id}
      script={script}
      isStudioEdit={isStudioEdit}
      contentLanguage={contentLanguage}
      uiLanguage={uiLanguage}
      isQuestion={true}
    />
  );
}
