import { ActionIcon, Box, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ImageNext from 'next/image';

import { ImageThumbnail } from './ImageAnswerThumbnails';
import { useStyles } from './Thumbnails.styles';
import { Trashcan } from '@/icons/Trashcan';

type ImageAnswerThumbnailProps = {
  isDeleteDisabled?: boolean;
  onDeleteImage: () => void;
  image: ImageThumbnail;
};

export function ImageAnswerThumbnail(props: ImageAnswerThumbnailProps) {
  const { image, onDeleteImage, isDeleteDisabled } = props;
  const { src, name } = image;
  const { classes } = useStyles();
  const [isModalOpen, { close: closeModal, open: openModal }] = useDisclosure(false);

  return (
    <Flex className={classes.answerThumbnailContainer} title={name}>
      <Box className={classes.answerThumbnail}>
        <ImageNext onClick={openModal} width={104} height={80} alt={name} src={src} />
        <ActionIcon
          color="dark"
          variant="light"
          className={classes.deleteIcon}
          onClick={onDeleteImage}
          disabled={isDeleteDisabled}
        >
          <Trashcan />
        </ActionIcon>
      </Box>
      <Text size="xs" className={classes.fileName} lineClamp={2}>
        {name}
      </Text>
      <Modal
        opened={isModalOpen}
        onClose={closeModal}
        size="100%"
        closeButtonProps={{
          color: 'accent'
        }}
      >
        <Modal.Body className={classes.modalAnswerPreview}>
          <ImageNext className={classes.thumbnailImage} fill alt={name} src={src} />
        </Modal.Body>
      </Modal>
    </Flex>
  );
}
