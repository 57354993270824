import { ClientModules } from '@hendrx/modules';
import { AuthenticationModuleClientType } from '@hendrx/modules/authentication';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect } from 'react';

import { onShowSuccessNotification } from '../notifications';

export function createRedirectPage(clientModules: ClientModules) {
  const RedirectPage: AuthenticationModuleClientType['RedirectPage'] = ({ onCallLoginEndpoint, paths }) => {
    const { login, home } = paths;

    const router = useRouter();
    const {
      query: { code: authCode, state: queryState },
      isReady: routerIsReady
    } = router;

    const t = useTranslations('external-modules.authenticationGoogle.notifications');

    const { redirectTo } = JSON.parse((queryState as string | undefined) ?? '{}');
    const loginUrl = `${login}?redirectTo=${redirectTo}`;

    const showErrorNotification = useCallback(
      () => onShowSuccessNotification(t('loginFail.title'), t('loginFail.message')),
      [t]
    );

    const showSuccessNotification = useCallback(
      () => onShowSuccessNotification(t('loginSuccess.title'), t('loginSuccess.message')),
      [t]
    );

    useEffect(() => {
      const onResponseHandle = async () => {
        if (!routerIsReady) {
          // Router needs a moment to read query params
          return;
        }

        if (!authCode) {
          return router.push(loginUrl).then(showErrorNotification);
        }

        try {
          await onCallLoginEndpoint({ oAuthCode: authCode });
        } catch (e) {
          return router.push(loginUrl).then(showErrorNotification);
        }

        return router.push(redirectTo ?? home).then(showSuccessNotification);
      };

      void onResponseHandle();
    }, [
      authCode,
      home,
      loginUrl,
      onCallLoginEndpoint,
      redirectTo,
      router,
      routerIsReady,
      showSuccessNotification,
      showErrorNotification
    ]);

    return <clientModules.ui.components.CustomLoadingOverlay visible />;
  };

  return RedirectPage;
}
