import { Box } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { Editor } from '@tiptap/react';
import cx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import * as Y from 'yjs';

import { OpenQuestionElementType, OpenQuestionValue, TextEditorType } from 'shared/types/OpenQuestion';
import { RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';
import { TextEditorTypes } from 'shared/types/TextEditor';
import { WidgetType } from 'shared/utils/widgets';
import { useConvertObservedYXmlFragment } from 'shared/widgetsSDK/yjs';

import { useCanGiveAnswers } from '@/components/CanProvider';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { QuestionState } from '@/queries/state';
import { useUser } from '@/queries/user';
import { yXmlFragmentToText } from '@/utils/tiptap';
import { useViewMode } from '@/utils/useViewMode';
import { answerStyles, richText, richTextControls, textStyles } from '@/widgets/OpenQuestion/OpenQuestion.css';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import * as AnswerProvider from '@/widgets/_components/questions/AnswerProvider';

const tools = [
  RichTextTools.Lists,
  RichTextTools.Alignment,
  RichTextTools.TextColor,
  RichTextTools.Underline,
  RichTextTools.Italic,
  RichTextTools.Bold,
  RichTextTools.TextHighlight,
  RichTextTools.FontSize,
  RichTextTools.Link,
  RichTextTools.InsertImage,
  RichTextTools.Code
];

export function OpenQuestionAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.OpenQuestion}>
      <OpenQuestionAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

function OpenQuestionAnswerPlayerImplementation() {
  const canGiveAnswers = useCanGiveAnswers();

  const { user } = useUser();

  const [editor, setEditor] = useState<Editor | null>(null);

  const [questionId] = useContentObjectProperty<string>('id');

  const placeholderFragment = useContentObjectStaticProperty<Y.XmlFragment>('placeholderFragment');
  const [showElements] = useContentObjectProperty<Record<OpenQuestionElementType, boolean>>('showElements');
  const [textEditorType] = useContentObjectProperty<TextEditorType>('textEditorType');

  const placeholderText =
    useConvertObservedYXmlFragment({
      fragment: placeholderFragment,
      convertFunction: yXmlFragmentToText
    }) ?? '';

  const visibleElements: Record<OpenQuestionElementType, boolean> = showElements;

  const [elementState, setElementState] = usePlayerElementState<QuestionState<OpenQuestionValue> | undefined>(
    questionId
  );
  const savedAnswer = elementState?.answers;

  const [viewMode] = useViewMode();

  const isInitialized = useRef(false);
  useEffect(() => {
    if (!editor || isInitialized.current || !savedAnswer) {
      return;
    }

    // Yjs in tiptap is not reactive, so we need to manually set the content
    // Initialize the editor with the saved answer
    editor.commands.setContent(savedAnswer?.text ?? '', false, { preserveWhitespace: 'full' });
    isInitialized.current = true;
  }, [editor, savedAnswer, setElementState]);

  useDidUpdate(() => {
    if (!editor) {
      return;
    }

    // Yjs in tiptap is not reactive, so we need to manually set the content
    // Hide the text on "solved" mode, and get it back on other modes
    if (viewMode === 'teacher') {
      editor.commands.setContent('', false);
    } else {
      editor.commands.setContent(savedAnswer?.text ?? '', false, { preserveWhitespace: 'full' });
    }
  }, [viewMode]);

  useEffect(() => {
    // Yjs in tiptap is not reactive, so we need to manually set the content
    // Remove the text when a user pressed the `Reset` button
    if (savedAnswer?.text === undefined) {
      editor?.commands.setContent('', false);
    }
  }, [savedAnswer?.text, editor]);

  const onSaveNewTextValue = (newTextValue: string) => {
    if (!user) {
      return;
    }

    setElementState(currentState => ({
      ...currentState,
      answers: { ...elementState?.answers, text: editor?.isEmpty ? '' : newTextValue }
    }));
  };

  const onSaveAnswerProvided = (element: OpenQuestionElementType, answer: unknown[]) => {
    setElementState(currentState => ({
      ...currentState,
      answers: { ...elementState?.answers, [element]: answer.length ? answer : undefined }
    }));
  };

  return (
    <div>
      {visibleElements.text && (
        <Box
          className={cx(
            canGiveAnswers ? answerStyles.base : answerStyles.notEditable,
            ThemeClassNames.widgets.openQuestion.answer
          )}
        >
          <Box className={textStyles}>
            <RichText
              dataTestIdPrefix="open-question"
              contentEditable={canGiveAnswers}
              placeholder={placeholderText}
              onChange={onSaveNewTextValue}
              setEditor={setEditor}
              toolbarType={textEditorType === TextEditorTypes.Rich ? ToolbarType.Inline : ToolbarType.None}
              className={richText}
              toolbarClassName={richTextControls}
              tools={tools}
              preserveWhitespace="full"
              expandHeight
              disableTypographyInheritance
            />
          </Box>
        </Box>
      )}
      <AnswerProvider.AnswerProvider
        value={savedAnswer}
        disabled={!canGiveAnswers}
        onChange={onSaveAnswerProvided}
        visibleElements={visibleElements}
      />
    </div>
  );
}
