import { Box } from '@mantine/core';
import { useDrop } from 'react-dnd';

import { useStyles } from './FillInTheBlanksOption.styles';
import { MovedWordOption, WordOption } from '@/widgets/FillInTheBlanksQuestion/types';

type FillInTheBlanksOptionValueProps = {
  disabled?: boolean;
  fontSize: string;
  isCorrect: boolean | undefined;
  onChange?: (newValue: WordOption | MovedWordOption) => void;
  textToShow?: string;
  questionId: string;
};

export function FillInTheBlanksOptionValue(props: FillInTheBlanksOptionValueProps) {
  const { disabled, fontSize, isCorrect, textToShow, onChange, questionId } = props;

  const [{ isOver }, dropRef] = useDrop<WordOption | MovedWordOption, void, { isOver: boolean }>(
    {
      accept: [`fitb-option-${questionId}`, `fitb-option-moved-${questionId}`],
      collect: monitor => ({
        isOver: monitor.isOver()
      }),
      canDrop: () => {
        return !disabled;
      },
      drop: onChange
    },
    [disabled, textToShow, questionId, onChange]
  );

  const { classes } = useStyles({
    disabled,
    fontSize,
    hasContent: !!textToShow,
    isCorrect,
    isOver
  });

  return (
    <Box ref={dropRef} className={classes.content} data-testid="option-box" data-testid-state={isCorrect}>
      {textToShow}
    </Box>
  );
}
