import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

export function convertTo2DArray(array: OpenDiscussionAnswer[], columns: number) {
  const result: OpenDiscussionAnswer[][] = Array.from({ length: columns }, () => []);
  let columnIndex = 0;

  array.forEach(item => {
    result[columnIndex].push(item);
    columnIndex = columnIndex === columns - 1 ? 0 : columnIndex + 1;
  });

  return result;
}

export function addAnswersToBoard(
  newAnswers: OpenDiscussionAnswer[],
  currentAnswers: OpenDiscussionAnswer[][],
  currentAnswerIds: string[]
) {
  const answersToAdd = newAnswers.filter(({ id }) => !currentAnswerIds.includes(id));

  const smallestColumnIndex = currentAnswers.reduce((currentColumnIndex, currentColumn, currentIndex) => {
    if (currentColumn.length < currentAnswers[currentColumnIndex].length) {
      return currentIndex;
    }

    return currentColumnIndex;
  }, 0);

  const updatedAnswers = structuredClone(currentAnswers);
  updatedAnswers[smallestColumnIndex].push(...answersToAdd);

  return updatedAnswers;
}

export function removeAnswersFromBoard(
  newAnswers: OpenDiscussionAnswer[],
  currentAnswers: OpenDiscussionAnswer[][],
  currentAnswerIds: string[]
) {
  const newAnswerIds = newAnswers.map(({ id }) => id);
  let idsToRemove = currentAnswerIds.filter(id => !newAnswerIds.includes(id));

  const updatedAnswers = structuredClone(currentAnswers);
  updatedAnswers.forEach((column, columnIndex) => {
    let index = 0;
    while (column[index]) {
      if (idsToRemove.includes(column[index].id)) {
        idsToRemove = idsToRemove.filter(id => id !== column[index].id);
        updatedAnswers[columnIndex].splice(index, 1);
      } else {
        index++;
      }
    }
  });

  return updatedAnswers;
}

export function updateAnswersOnBoard(newAnswers: OpenDiscussionAnswer[], currentAnswers: OpenDiscussionAnswer[][]) {
  const answersMap: Record<string, OpenDiscussionAnswer> = {};
  newAnswers.forEach(answer => {
    answersMap[answer.id] = answer;
  });

  const updatedAnswers = structuredClone(currentAnswers);
  updatedAnswers.forEach((column, columnIndex) => {
    let index = 0;
    while (column[index]) {
      const answer = answersMap[column[index].id];
      if (answer.text !== column[index].text) {
        updatedAnswers[columnIndex][index].text = answer.text;
      }

      if (answer.image !== column[index].image) {
        updatedAnswers[columnIndex][index].image = answer.image;
      }

      if (answer.cardColor !== column[index].cardColor) {
        updatedAnswers[columnIndex][index].cardColor = answer.cardColor;
      }

      index++;
    }
  });

  return updatedAnswers;
}
