import { AIModuleClientType } from '@hendrx/modules/ai-module';
import React from 'react';

import { Chat } from './Chat';

export const MainComponent: AIModuleClientType['MainComponent'] = props => {
  console.log('props', props);
  const { feature } = props;

  return feature === 'chat' ? <Chat /> : <div>Hello world from AI!</div>;
};
