import { clientModules } from '@hendrx/modules';
import { SelectProps } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';

import { Level } from 'shared/tiptap/heading';

import { Select } from '@/components/Select';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

export type TextLevelSelectProps = {
  editor: Editor | null;
  textLevels: { value: string; label: string }[];
};

export function TextLevelSelect(props: TextLevelSelectProps) {
  const { editor, textLevels } = props;

  const { classes } = useStyles({ maxWidth: 72 });
  const t = useTranslations('widgets.headingSelect');

  const isActiveParagraph = editor?.isActive('paragraph');
  const isActiveHeader = editor?.isActive('heading');

  let activeHeading = textLevels[0].value;
  if (isActiveParagraph) {
    activeHeading = 'p';
  } else if (isActiveHeader) {
    activeHeading = editor?.getAttributes('heading').level?.toString();
  }
  const handleHeadingChange = (level: string) => {
    const newLevel = level === '0' ? activeHeading : level;

    let levelTag: keyof typeof clientModules.ui.defaultActivityThemeStyles.typography;
    if (newLevel === 'p') {
      levelTag = 'p';
      editor?.chain().focus().setParagraph().run();
    } else {
      levelTag = `h${level}` as keyof typeof clientModules.ui.defaultActivityThemeStyles.typography;

      editor
        ?.chain()
        .focus()
        .toggleHeading({ level: newLevel as Level })
        .setFontSize(`${clientModules.ui.defaultActivityThemeStyles.typography[levelTag].fontSize}`)
        .run();
    }
  };

  return (
    <Select
      title={t('title') ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: classes.selectInput,
        section: classes.selectIcon,
        dropdown: classes.selectDropdown
      }}
      data={textLevels.map(({ label, value }) => ({ value, label: t(label) }))}
      value={activeHeading}
      onChange={handleHeadingChange as SelectProps['onChange']}
      variant="unstyled"
      withCheckIcon={false}
      leftSection={<DropdownArrow />}
      leftSectionWidth={24}
      leftSectionPointerEvents="auto"
      rightSection={<></>}
      rightSectionWidth={'0px'}
    />
  );
}
