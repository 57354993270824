export const manifestLocales = {
  questionGroupList: {
    previewImageAlt: {
      en: 'Question group list',
      he: 'מקבצי שאלות'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    }
  }
};
