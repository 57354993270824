import { Mark } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useEffect } from 'react';
import * as Y from 'yjs';

import { useStyles } from './Option.styles';
import { useTiptapFillInTheBlanksOptionsStore } from '@/widgets/FillInTheBlanksQuestion/studio/hooks/useTiptapFillInTheBlanksOptionsStore';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import { useContentObjectArray, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Option(props: NodeViewProps) {
  const {
    node: { attrs: nodeAttributes },
    deleteNode,
    getPos,
    editor
  } = props;

  const { classes } = useStyles({
    fontFamily: nodeAttributes?.fontFamily,
    fontSize: nodeAttributes?.fontSize
  });

  const setNodesRemovalFunctions = useTiptapFillInTheBlanksOptionsStore(state => state.setNodesRemovalFunctions);

  const [correctOptions] = useContentObjectProperty<Y.Map<unknown>>(`correctOptions`, 'sensitiveData');

  const [wordBankOptions] = useContentObjectArray<WordOption>(`wordBankOptions`);

  const correctOptionId = correctOptions.get(nodeAttributes.fillInTheBlanksId);

  const correctOption = wordBankOptions.find(option => option.id === correctOptionId);

  useEffect(() => {
    if (!correctOption) return;
    setNodesRemovalFunctions(correctOption.id, () => {
      const nodePosition = getPos();
      deleteNode();
      editor.chain().focus().insertContentAt(nodePosition, correctOption.value).run();
    });
  }, [correctOption, deleteNode, setNodesRemovalFunctions, editor, getPos]);

  return (
    <NodeViewWrapper className={classes.componentWithContent} contentEditable={false}>
      {nodeAttributes.fillInTheBlanksId && correctOption ? (
        <Mark contentEditable={false}>{correctOption.value}</Mark>
      ) : null}
    </NodeViewWrapper>
  );
}
