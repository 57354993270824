import { Box, Tabs } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { ComponentType, ReactNode } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { ConfigTypes } from './ConfigTypes';
import { useStyles } from './WidgetConfig.styles';
import { ConfigTitle } from '@/widgets/_components/ConfigTitle';

export type WidgetConfigProps = {
  children: ReactNode;
  tabs: ConfigTypes[];
  customTitle?: ReactNode;
  title?: string;
  Icon?: ComponentType<{ width: number; height: number }> | string;
  type?: WidgetType;
};

export function WidgetConfig(props: WidgetConfigProps) {
  const { children, tabs, type, title, Icon, customTitle } = props;
  const { classes } = useStyles({ tabs });
  const t = useTranslations('widgets');

  return (
    <Box>
      {customTitle ? (
        customTitle
      ) : type === WidgetType.Plugin || type === WidgetType.EvaluatablePlugin ? (
        <ConfigTitle title={title} Icon={Icon} />
      ) : (
        <ConfigTitle />
      )}
      <Tabs
        classNames={{ list: classes.tabsList, tabLabel: classes.tabLabel, tab: classes.tab }}
        variant="pills"
        color="systemDark.0"
        radius="xs"
        defaultValue={tabs[0]}
      >
        {tabs.length > 1 && (
          <Tabs.List>
            {tabs.map(tab => (
              <Tabs.Tab key={tab} value={tab} data-testid={`widget-config-${tab}`}>
                {t(`widgetConfig.${tab}`)}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        )}
        <Box className={classes.tabsPanels}>{children}</Box>
      </Tabs>
    </Box>
  );
}
