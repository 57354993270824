import { FileButton } from '@mantine/core';
import React, { useCallback, useRef } from 'react';

type UploadButtonProps = {
  accept?: string;
  addButton?: (props: { onClick: () => void }) => JSX.Element;
  disabled?: boolean;
  handleUpload: (file: File) => Promise<void>;
};

export function UploadButton(props: UploadButtonProps) {
  const { addButton, disabled, handleUpload, accept } = props;

  const resetRef = useRef<() => void>(null);

  const handleOnChange = useCallback(
    (file: File | null) => {
      if (!file) {
        return;
      }
      void handleUpload(file);
      resetRef.current?.();
    },
    [handleUpload]
  );

  return (
    <FileButton resetRef={resetRef} disabled={disabled} accept={accept} onChange={handleOnChange}>
      {({ onClick }) => addButton && addButton({ onClick })}
    </FileButton>
  );
}
