import { useDebouncedValue } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { InputClozeBase } from './InputClozeBase';

type InputClozeNewProps = {
  onChange: (value: string) => void;
};

export function InputClozePlaceholder(props: InputClozeNewProps) {
  const { onChange } = props;

  const t = useTranslations('widgets.clozeQuestionSettings');

  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 1000);

  useEffect(() => {
    if (debounced) {
      onChange(debounced);
      setValue('');
    }
  }, [debounced, onChange]);

  return <InputClozeBase value={value} onChange={setValue} dashed placeholder={t('addOption')} />;
}
