import Lottie from 'lottie-react';

import confetti from '@/icons/lotties/confetti.json';
import { RandomizedFeedback, FeedbackMessageType } from '@/utils/feedback.utils';
import { useStyles } from '@/widgets/_components/questions/QuestionFooter/QuestionFooter.styles';

type Props = {
  randomizedFeedback: RandomizedFeedback;
  feedbackMessageType: FeedbackMessageType;
};

export function QuestionFooterFeedback(props: Props) {
  const { randomizedFeedback, feedbackMessageType } = props;

  const { RandomizedIcon, randomizedText, withAnimation } = randomizedFeedback;

  const { classes } = useStyles({ feedbackMessageType });

  return (
    <div className={classes.feedback} data-testid="question-footer-feedback">
      {withAnimation && <Lottie className={classes.feedbackAnimation} animationData={confetti} loop={1} />}
      <div className={classes.feedbackIcon}>
        <RandomizedIcon />
      </div>
      <div>{randomizedText}</div>
    </div>
  );
}
