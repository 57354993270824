import { BlockTitleThemeProps, OverrideTypographyStylesKeys, TypographyThemeProps } from '@hendrx/modules/ui';
import { em, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';
import { Direction } from '@/utils/locale.utils';
import { getThemeBorderRadiusStyle, getThemeBorderStyleAsOneString } from '@/utils/theme.utils';
import {
  BlockTitleTypographyStyle,
  BlockTitleWidgetStyles,
  TextWidgetStyles,
  TypedWidgetStyleAndSet,
  TypographyIdType,
  WidgetTypographyStyle
} from '@/utils/typographyStyles.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';

type RichTextStyles = {
  expandHeight?: boolean;
  widgetStyles?: TextWidgetStyles | BlockTitleWidgetStyles;
  disableTypographyInheritance?: boolean;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
};

const getBorderStyles = (
  typographyDefaultStyle: TypographyThemeProps | BlockTitleThemeProps,
  widgetBorder: Partial<WidgetTypographyStyle>,
  contentDirection: Direction = 'ltr'
) => {
  const { borderTop, borderBottom, borderLeft, borderRight } = widgetBorder || {};

  const resultBorderRight = getThemeBorderStyleAsOneString(
    typographyDefaultStyle.borderRight,
    undefined,
    borderRight || {}
  );
  const resultBorderLeft = getThemeBorderStyleAsOneString(
    typographyDefaultStyle.borderLeft,
    undefined,
    borderLeft || {}
  );

  return {
    borderTop: getThemeBorderStyleAsOneString(typographyDefaultStyle.borderTop, undefined, borderTop || {}),
    borderRight: contentDirection === 'ltr' ? resultBorderRight : resultBorderLeft,
    borderBottom: getThemeBorderStyleAsOneString(typographyDefaultStyle.borderBottom, undefined, borderBottom || {}),
    borderLeft: contentDirection === 'ltr' ? resultBorderLeft : resultBorderRight
  };
};

const getSinglePadding = (typographyTheme: TypographyThemeProps, widgetStyles: WidgetTypographyStyle) =>
  widgetStyles?.padding ?? typographyTheme.padding;

const getMultiPadding = (
  blockTitleTheme: BlockTitleThemeProps,
  widgetStyles: BlockTitleTypographyStyle,
  contentDirection: Direction
) => {
  const {
    paddingTop: defaultPaddingTop,
    paddingRight: defaultPaddingRight,
    paddingBottom: defaultPaddingBottom,
    paddingLeft: defaultPaddingLeft
  } = blockTitleTheme;
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = widgetStyles || {};
  const isLTR = contentDirection === 'ltr';

  const top = rem(paddingTop ?? defaultPaddingTop);
  const right = rem(paddingRight ?? defaultPaddingRight);
  const bottom = rem(paddingBottom ?? defaultPaddingBottom);
  const left = rem(paddingLeft ?? defaultPaddingLeft);

  return `${top} ${isLTR ? right : left} ${bottom} ${isLTR ? left : right}`;
};

const getPadding = ({
  blockTitleTheme,
  typographyTheme,
  widgetStyles,
  useBlockTitleStyles,
  contentDirection = 'ltr'
}: {
  blockTitleTheme: BlockTitleThemeProps;
  typographyTheme: TypographyThemeProps;
  widgetStyles?: TypedWidgetStyleAndSet<WidgetTypographyStyle> | TypedWidgetStyleAndSet<BlockTitleTypographyStyle>;
  useBlockTitleStyles: boolean | undefined;
  contentDirection: Direction;
}) => {
  return useBlockTitleStyles
    ? getMultiPadding(blockTitleTheme, widgetStyles?.style as BlockTitleTypographyStyle, contentDirection)
    : getSinglePadding(typographyTheme, widgetStyles?.style as WidgetTypographyStyle);
};

export const useStyles = createStyles((theme, props: RichTextStyles) => {
  const {
    expandHeight,
    widgetStyles,
    disableTypographyInheritance,
    overrideTypographyStylesPath,
    changeContrastColorToMain
  } = props;

  const { contentDirection } = useContentLanguage();
  const document = useCurrentDocument();

  const defaultAlignment = contentDirection === 'ltr' ? 'left' : 'right';
  const [activityThemeStyles] = useActivityThemeStyles(document);
  const globalTheme = activityThemeStyles.global;
  const typographyTheme = activityThemeStyles.typography;
  const blockTitleTheme = activityThemeStyles.blockTitle;

  const resultStyles = overrideTypographyStylesPath
    ? activityThemeStyles[overrideTypographyStylesPath]
    : typographyTheme;

  const getBorderStylesForTypography = (typographyId: TypographyIdType) => {
    if (typographyId !== 'p') {
      return getBorderStyles(resultStyles[typographyId], widgetStyles?.[typographyId]?.style ?? {}, contentDirection);
    }
    return getBorderStyles(typographyTheme.p, widgetStyles?.p?.style ?? {}, contentDirection);
  };

  const getBorderRaduisForTypography = (typographyId: TypographyIdType) => {
    if (typographyId !== 'p') {
      return getThemeBorderRadiusStyle(
        resultStyles[typographyId].borderRadius,
        widgetStyles?.[typographyId]?.style?.borderRadius ?? {},
        contentDirection
      );
    }
    return getThemeBorderRadiusStyle(
      typographyTheme.p.borderRadius,
      widgetStyles?.p?.style?.borderRadius ?? {},
      contentDirection
    );
  };

  const getPaddingForTypography = (typographyId: TypographyIdType) =>
    getPadding({
      blockTitleTheme: blockTitleTheme[typographyId],
      typographyTheme: typographyTheme[typographyId],
      widgetStyles: widgetStyles?.[typographyId],
      useBlockTitleStyles: !!overrideTypographyStylesPath && typographyId !== 'p',
      contentDirection
    });

  const bordersMap = {
    h1: getBorderStylesForTypography('h1'),
    h2: getBorderStylesForTypography('h2'),
    h3: getBorderStylesForTypography('h3'),
    h4: getBorderStylesForTypography('h4'),
    p: getBorderStylesForTypography('p')
  };

  const borderRadiusMap = {
    h1: getBorderRaduisForTypography('h1'),
    h2: getBorderRaduisForTypography('h2'),
    h3: getBorderRaduisForTypography('h3'),
    h4: getBorderRaduisForTypography('h4'),
    p: getBorderRaduisForTypography('p')
  };

  const paddingMap = {
    h1: getPaddingForTypography('h1'),
    h2: getPaddingForTypography('h2'),
    h3: getPaddingForTypography('h3'),
    h4: getPaddingForTypography('h4'),
    p: getPaddingForTypography('p')
  };

  return {
    wrapper: {
      height: '100%',
      textAlign: defaultAlignment,
      mark: {
        display: 'inline-block'
      }
    },
    richTextInline: {
      border: `1px solid ${theme.colors.dark[1]}`,
      borderRadius: rem(8),
      '.ProseMirror': {
        height: '100%',
        padding: `${rem(16)} !important`
      }
    },
    control: {
      width: rem(36),
      height: rem(36),
      marginRight: rem(8),
      marginLeft: rem(8),
      color: theme.colors.systemDark[7],
      fontSize: rem(17),
      border: 'none'
    },
    toolbar: {
      border: 'none',
      padding: `${rem(4)}`,
      display: 'flex',
      justifyContent: 'start'
    },
    root: {
      height: '100%',
      border: 'none'
    },
    controlsGroup: {
      gap: rem(8),
      alignItems: 'center'
    },
    typographyStylesProvider: {
      height: '100%'
    },
    content: {
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      overflow: 'visible',
      'p:empty::after, h1:empty::after, h2:empty::after, h3:empty::after, h4:empty::after': {
        content: '"\\00A0"'
      },
      '.ProseMirror, .previewContent': {
        ...(expandHeight && {
          minHeight: `calc(${theme.other.stage.minQuestionInputHeight} - ${theme.other.stage.questionInputWrapperPaddingSum})`
        }),
        padding: `${em(4)} 0`,
        overflowWrap: 'anywhere',

        '> :last-child': {
          marginBottom: rem(0)
        },

        // this is for the input to match the placeholder height
        [`p.is-editor-empty:first-of-type::before`]: {
          float: 'unset'
        }
      },
      h1: {
        fontFamily: widgetStyles?.h1?.style?.fontFamily ?? resultStyles.h1.fontFamily ?? globalTheme.content.fontFamily,
        fontSize: rem(resultStyles.h1.fontSize),
        fontWeight: resultStyles.h1.fontWeight,
        lineHeight: resultStyles.h1.lineHeight,
        ...(!disableTypographyInheritance && {
          color:
            widgetStyles?.h1?.style?.color ??
            resultStyles.h1.color ??
            globalTheme.content.colors.primary[changeContrastColorToMain ? 'main' : 'contrast'],
          backgroundColor:
            widgetStyles?.h1?.style?.backgroundColor ??
            resultStyles.h1.backgroundColor ??
            (changeContrastColorToMain ? globalTheme.content.colors.primary.contrast : undefined),
          padding: paddingMap.h1,
          ...bordersMap.h1,
          ...borderRadiusMap.h1
        })
      },
      h2: {
        fontFamily: widgetStyles?.h2?.style?.fontFamily ?? resultStyles.h2.fontFamily ?? globalTheme.content.fontFamily,
        fontSize: rem(resultStyles.h2.fontSize),
        fontWeight: resultStyles.h2.fontWeight,
        lineHeight: resultStyles.h2.lineHeight,
        ...(!disableTypographyInheritance && {
          color:
            widgetStyles?.h2?.style?.color ??
            resultStyles.h2.color ??
            globalTheme.content.colors.primary[changeContrastColorToMain ? 'main' : 'contrast'],
          backgroundColor:
            widgetStyles?.h2?.style?.backgroundColor ??
            resultStyles.h2.backgroundColor ??
            (changeContrastColorToMain ? globalTheme.content.colors.primary.contrast : undefined),
          padding: paddingMap.h2,
          ...bordersMap.h2,
          ...borderRadiusMap.h2
        })
      },
      h3: {
        fontFamily: widgetStyles?.h3?.style?.fontFamily ?? resultStyles.h3.fontFamily ?? globalTheme.content.fontFamily,
        fontSize: rem(resultStyles.h3.fontSize),
        fontWeight: resultStyles.h3.fontWeight,
        lineHeight: resultStyles.h3.lineHeight,
        ...(!disableTypographyInheritance && {
          color:
            widgetStyles?.h3?.style?.color ??
            resultStyles.h3.color ??
            globalTheme.content.colors.secondary[changeContrastColorToMain ? 'main' : 'contrast'],
          backgroundColor:
            widgetStyles?.h3?.style?.backgroundColor ??
            resultStyles.h3.backgroundColor ??
            (changeContrastColorToMain ? globalTheme.content.colors.secondary.contrast : undefined),
          padding: paddingMap.h3,
          ...bordersMap.h3,
          ...borderRadiusMap.h3
        })
      },
      h4: {
        fontFamily: widgetStyles?.h4?.style?.fontFamily ?? resultStyles.h4.fontFamily ?? globalTheme.content.fontFamily,
        fontSize: rem(resultStyles.h4.fontSize),
        fontWeight: resultStyles.h4.fontWeight,
        lineHeight: resultStyles.h4.lineHeight,
        ...(!disableTypographyInheritance && {
          color:
            widgetStyles?.h4?.style?.color ??
            resultStyles.h4.color ??
            globalTheme.content.colors.secondary[changeContrastColorToMain ? 'main' : 'contrast'],
          backgroundColor:
            widgetStyles?.h4?.style?.backgroundColor ??
            resultStyles.h4.backgroundColor ??
            (changeContrastColorToMain ? globalTheme.content.colors.secondary.contrast : undefined),
          padding: paddingMap.h4,
          ...bordersMap.h4,
          ...borderRadiusMap.h4
        })
      },
      p: {
        fontFamily:
          widgetStyles?.p?.style?.fontFamily ?? typographyTheme.p.fontFamily ?? globalTheme.content.fontFamily,
        fontSize: rem(typographyTheme.p.fontSize),
        fontWeight: typographyTheme.p.fontWeight,
        lineHeight: typographyTheme.p.lineHeight,
        ...(!disableTypographyInheritance && {
          color:
            widgetStyles?.p?.style?.color ??
            typographyTheme.p.color ??
            globalTheme.content.colors.secondary[changeContrastColorToMain ? 'main' : 'contrast'],
          backgroundColor:
            widgetStyles?.p?.style?.backgroundColor ??
            typographyTheme.p.backgroundColor ??
            (changeContrastColorToMain ? globalTheme.content.colors.secondary.contrast : undefined),
          padding: paddingMap.p,
          ...bordersMap.p,
          ...borderRadiusMap.p
        })
      },
      img: {
        verticalAlign: 'middle'
      }
    }
  };
});
