import { Tabs } from '@mantine/core';

import { OpenQuestionElementType } from 'shared/types/OpenQuestion';

import { Design } from './design';
import { Settings } from './settings';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

type ConfigProps = {
  elements: Record<OpenQuestionElementType, boolean>;
  onElementChange: (element: OpenQuestionElementType, state: boolean) => void;
};

export function Config(props: ConfigProps) {
  const { elements, onElementChange } = props;

  return (
    <WidgetConfig tabs={['appearance', 'behaviour']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Design elements={elements} onElementChange={onElementChange} />
        <CustomCSSClassesInput />
      </Tabs.Panel>
      <Tabs.Panel key="behaviour" value="behaviour">
        <Settings />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
