import { rem } from '@mantine/core';
import { Cell, Header } from '@tanstack/react-table';
import last from 'lodash/last';
import noop from 'lodash/noop';
import { memo, useMemo } from 'react';

import { useStyles } from './Table.styles';
import { TableColumnResizer } from './TableColumnResizer';
import { TableRowResizer } from './TableRowResizer';
import { useAutoResizeRow } from './hooks/useAutoResizeRow';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CELL_RENDERER_CLASSNAME, CLASSNAMES, CSS_VARS, TRow } from '@/widgets/Table/utils';

type TableCellProps = {
  cell: Cell<TRow, unknown>;
};

const getContentId = (cell: Cell<TRow, unknown>) => {
  const cellValue = cell.getValue() as string | undefined;
  if (!cellValue) {
    // TODO: add logging
    // console.warn('Cell without value', cellValue, cell);
  }
  return cellValue ?? '';
};

export const TableCell = memo(function TableCell(props: TableCellProps) {
  const { cell } = props;
  const { classes, cx } = useStyles();

  const TableCellRenderer = useTable(context => context.TableCellRenderer);

  useAutoResizeRow(cell.row.original.id);

  const getColumnResizeHandler = useMemo(() => {
    const headerGroups = cell.getContext().table.getHeaderGroups();
    const headers = last(headerGroups)?.headers;
    return (
      headers?.find(header => header.id === cell.column.id)?.getResizeHandler ??
      (noop as Header<TRow, unknown>['getResizeHandler'])
    );
  }, [cell]);

  return (
    <div
      className={cx(
        classes.td,
        CLASSNAMES.CellColumn(cell.column.id),
        CLASSNAMES.CellRow(cell.row.original.id),
        ThemeClassNames.widgets.table.cell
      )}
      style={{
        width: `calc(var(${CSS_VARS.ColumnSize(cell.column.id)}) * ${rem(1)})`
      }}
    >
      <div className={cx(classes.cellRenderer, CELL_RENDERER_CLASSNAME)}>
        <TableCellRenderer contentId={getContentId(cell)} />
      </div>
      <TableColumnResizer getResizeHandler={getColumnResizeHandler} />
      <TableRowResizer rowId={cell.row.original.id} />
    </div>
  );
});
