import { createContext, ReactNode, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';

const DEFAULT_ALLOWED_ITEMS = [
  ItemDragType.Item,
  ItemDragType.FlexSection,
  ItemDragType.Question,
  ItemDragType.FullPageItem,
  ItemDragType.UniqueItem
];

type DndAllowStoreType = {
  allowedItems: ItemDragType[];
};
const DndAllowedItemsContext = createContext<StoreApi<DndAllowStoreType> | null>(null);

type DndAllowedItemsContextProps = {
  allowedItems?: ItemDragType[];
  children: ReactNode;
};
export function DndAllowedItemsContextProvider(props: DndAllowedItemsContextProps) {
  const { allowedItems, children } = props;
  const dndAllowStore = useMemo(
    () =>
      createStore<DndAllowStoreType>(() => ({
        allowedItems: allowedItems ?? DEFAULT_ALLOWED_ITEMS
      })),
    [allowedItems]
  );
  return <DndAllowedItemsContext.Provider value={dndAllowStore}>{children}</DndAllowedItemsContext.Provider>;
}

export function useDndAllowedItemsContext<T = DndAllowStoreType>(selector: (state: DndAllowStoreType) => T) {
  const store = useContext(DndAllowedItemsContext);
  if (store === null) throw new Error('DndAllowContextProvider not initialized properly.');
  return useStore(store, selector);
}
