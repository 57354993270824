import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import AIIcon from './ai.svg';
import { AI } from '@/icons/AI';

export const Manifest: ManifestType = {
  name: WidgetType.AvatarWidget,
  previewGroups: [
    {
      id: 'avatar',
      divider: {
        icon: AI,
        title: 'avatar'
      },
      previews: [
        {
          name: 'avatarPreview',
          previewImage: {
            paths: {
              en: AIIcon,
              he: AIIcon
            },
            size: {
              width: 285,
              height: 140
            },
            alt: {}
          },
          dimensions: {
            width: 75,
            height: 75
          },
          initializeProps: widgetsCreateFunctions[WidgetType.AvatarWidget] as ManifestPreviews['initializeProps']
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 75,
      minWidth: 75
    }
  }
};
