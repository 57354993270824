import { Button } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import * as Y from 'yjs';

import { ClozeOptionType } from 'shared/types/ClozeQuestion';
import { genRandId } from 'shared/utils/genRandId';
import {
  initializeClozeOption,
  initializeClozeOptionInputData,
  initializeClozeOptionSelectData
} from 'shared/utils/initializeCloze';

import { useStyles } from './AddCloze.styles';
import { Chevron } from '@/icons/Chevron';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type AddClozeProps = {
  type: ClozeOptionType;
};

export function AddCloze(props: AddClozeProps) {
  const { type } = props;

  const t = useTranslations('widgets.clozeToolbarExtension');

  const { editor } = useRichTextEditorContext();

  const [isDisabled, setIsDisabled] = useState(editor?.state.selection.empty ?? true);

  const { classes } = useStyles({ type, isDisabled });

  const { document } = useContentObject();

  const [clozeOptions, setClozeOptions] = useContentObjectProperty<Y.Map<Y.Map<unknown>>>('clozeOptions');

  const [inputCorrectOptions, setInputCorrectOptions] = useContentObjectProperty<Y.Map<unknown>>(
    'inputCorrectOptions',
    'sensitiveData'
  );
  const [selectCorrectOptions, setSelectCorrectOptions] = useContentObjectProperty<Y.Map<unknown>>(
    'selectCorrectOptions',
    'sensitiveData'
  );
  const [selectPossibleOptions, setSelectPossibleOptions] =
    useContentObjectProperty<Y.Map<unknown>>('selectPossibleOptions');

  useEffect(() => {
    const handleSelection = () => {
      setIsDisabled(!!editor?.state.selection.empty);
    };

    editor?.on('selectionUpdate', handleSelection);

    return () => {
      editor?.off('selectionUpdate', handleSelection);
    };
  }, [editor]);

  const handleAdd = () => {
    if (!editor || !clozeOptions) return;
    const { from, to } = editor.state.selection;
    const selectedTextFragment = editor.state.doc.textBetween(from, to);
    const nodeAttrs = editor.getAttributes('textStyle');
    const selectedText = selectedTextFragment;
    const newClozeId = genRandId();
    const newCloze = initializeClozeOption(newClozeId, type);

    document.transact(() => {
      const newClozeOptions = clozeOptions.clone();
      newClozeOptions.set(newClozeId, newCloze);
      setClozeOptions(newClozeOptions);

      if (type === ClozeOptionType.Input) {
        const input = initializeClozeOptionInputData(selectedText);
        const newInputCorrectOptions = inputCorrectOptions.clone();
        newInputCorrectOptions.set(newClozeId, input);
        setInputCorrectOptions(newInputCorrectOptions);
      }

      if (type === ClozeOptionType.Select) {
        const { correctOption, possibleOptions } = initializeClozeOptionSelectData(selectedText, t('secondOptionText'));

        const newSelectPossibleOptions = selectPossibleOptions.clone();
        newSelectPossibleOptions.set(newClozeId, possibleOptions);
        setSelectPossibleOptions(newSelectPossibleOptions);

        const newSelectCorrectOptions = selectCorrectOptions.clone();
        newSelectCorrectOptions.set(newClozeId, correctOption.clone());
        setSelectCorrectOptions(newSelectCorrectOptions);
      }
    }, 'untracked');

    return editor
      .chain()
      .deleteRange({ from, to })
      .insertContentAt(from, {
        type: 'clozeNode',
        attrs: { clozeId: newClozeId, type, ...nodeAttrs }
      })
      .focus()
      .run();
  };

  return (
    <Button
      data-testid={`studio-cloze-question-button-add-${type}`}
      onClick={handleAdd}
      variant="transparent"
      disabled={isDisabled}
      classNames={{ root: classes.buttonRoot, inner: classes.buttonInner, label: classes.buttonLabel }}
    >
      {t(type === ClozeOptionType.Input ? 'input' : 'select')}
      {type === ClozeOptionType.Select && <Chevron style={{ transform: 'scaleY(-100%)', marginBottom: '-3px' }} />}
    </Button>
  );
}
