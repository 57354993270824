import { Box } from '@mantine/core';
import min from 'lodash/min';
import React, { CSSProperties, Dispatch, PropsWithChildren, RefObject, SetStateAction, useRef, useState } from 'react';

import { WidgetType, widgetsWithConfig } from 'shared/utils/widgets';
import { getItemWidth } from 'shared/widgetsSDK/Stage.utils';

import { useStyles } from './Resizable.styles';
import { useHandleMouseDown } from './hooks/useHandleMouseDown';
import { useInitialDimensions } from './hooks/useInitialDimensions';
import { usePreventUserSelectWhileResize } from './hooks/usePreventUserSelectWhileResize';
import { ResizeDirections, UseResizeDotsListProps, useResizeDotsList } from './hooks/useResizeDotsList';
import { useResizeHandler } from './hooks/useResizeHandler';
import { Direction } from './types';
import { Hoverable } from '@/components/Hoverable';
import { Selectable, useSelectableStore } from '@/components/Selectable';
import { useStore } from '@/store';
import { StageDndItem } from '@/types/Stage';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type ResizableProps = StageDndItem &
  PropsWithChildren & {
    onResizeEnd: (
      newSpan: number,
      newHeight: number | 'auto',
      shouldResizeItemInStartDirection: boolean,
      newWidth: number
    ) => void;
    isResizableVertically?: boolean;
    onSelect: () => void;
    sectionRef: RefObject<HTMLDivElement>;
    setResizingItemId?: Dispatch<SetStateAction<string | undefined>>;
    resizingItemId?: string;
    resizeDirections: ResizeDirections;
    width?: number;
    sectionType: WidgetType;
    containerWidth?: number;
    disabled?: boolean;
  };

export function Resizable(props: ResizableProps) {
  const {
    id,
    sectionType,
    width,
    span = -1,
    height,
    constraints,
    onResizeEnd,
    isResizableVertically = false,
    children,
    onSelect,
    sectionRef,
    resizingItemId,
    setResizingItemId,
    type,
    resizeDirections,
    containerWidth,
    disabled
  } = props;

  const [originalWidth, setOriginalWidth] = useState(0);
  const constrainedWidth = min([containerWidth, width]);
  const [dimensions, setDimensions] = useState({
    width: constrainedWidth || getItemWidth(span),
    height: isResizableVertically ? height : ('auto' as const)
  });

  const [isConstraint, setIsConstraint] = useState(false);
  const [resizingDirection, setResizingDirection] = useState<Direction>();
  const [moveStartDir, setMoveStartDir] = useState(0);

  const coordsRef = useRef({ x: 0, y: 0 });
  const dimensionsRef = useRef({
    width: constrainedWidth || span,
    height: isResizableVertically ? height : ('auto' as const)
  });

  const resizableRef = useRef<HTMLDivElement>(null);

  const isSelected = useSelectableStore(state => state.selectedId === id);

  const isResizingCurrentItem = resizingItemId === id;

  const stageScale = useStore(state => state.stageScale);
  const { contentDirection } = useContentLanguage();

  const { classes, cx } = useStyles({
    isResizing: isResizingCurrentItem,
    isConstraint,
    direction: contentDirection
  });

  useInitialDimensions({
    height,
    isResizableVertically,
    isResizingCurrentItem,
    setDimensions,
    span,
    width: constrainedWidth,
    dimensionsRef
  });

  useResizeHandler({
    constraints,
    coordsRef,
    dimensionsRef,
    isResizableVertically,
    noGrid: span === -1,
    isResizingCurrentItem,
    onResizeEnd,
    originalWidth,
    setDimensions,
    setIsConstraint,
    setMoveStartDir,
    setOriginalWidth,
    stageScale,
    resizingDirection,
    contentDirection,
    sectionRef,
    resizableRef,
    setResizingItemId,
    sectionType,
    containerWidth
  });

  usePreventUserSelectWhileResize({ isResizingCurrentItem });

  const handleMouseDown = useHandleMouseDown({
    id,
    setOriginalWidth,
    setResizingDirection,
    setResizingItemId,
    coordsRef,
    span,
    width
  });

  const resizeDotsList = useResizeDotsList({ classes: classes as UseResizeDotsListProps['classes'], resizeDirections });

  const [contentHeight] = useContentObjectProperty<number>(`layout.${id}.height`);
  const itemHeight = isResizingCurrentItem ? dimensions.height : contentHeight || 'auto';

  const style: CSSProperties = {
    ...(isResizingCurrentItem ? { width: `${dimensions.width}px` } : {}),
    height: typeof itemHeight === 'number' ? `${itemHeight}px` : itemHeight,
    [contentDirection === 'rtl' ? 'right' : 'left']: `${moveStartDir}px`
  };

  return (
    <Box className={classes.resizeContainer} style={style} data-selected={isSelected}>
      <Hoverable>
        <Selectable
          itemConfigId={widgetsWithConfig.includes(type) ? id : null}
          id={id}
          onSelect={onSelect}
          style={style}
        >
          <Box className={classes.childrenWrapper} ref={resizableRef}>
            {children}
          </Box>
          {!disabled &&
            isSelected &&
            resizeDotsList.map(dot => (
              <Box
                key={dot.direction}
                className={cx(dot.class, classes.resizeDotCommon)}
                data-child="resize-dot"
                onMouseDown={event => handleMouseDown(event, dot.direction)}
              />
            ))}
        </Selectable>
      </Hoverable>
    </Box>
  );
}
