import { Button, Popover } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { PropsWithChildren } from 'react';

import { useStyles } from './MoreMenu.styles';

type MoreMenuProps = {
  title: string;
  buttonClassNames?: Record<string, string>;
} & PropsWithChildren;

export function MoreMenu(props: MoreMenuProps) {
  const { title, buttonClassNames, children } = props;
  const { classes } = useStyles();

  return (
    <Popover classNames={{ dropdown: classes.toolbarPopover }} shadow="md" position="bottom-end" withinPortal={false}>
      <Popover.Target>
        <Button c="gray.7" variant="subtle" styles={{ label: { overflow: 'initial' } }} classNames={buttonClassNames}>
          {title}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <RichTextEditor.ControlsGroup className={classes.controlsGroup}>{children}</RichTextEditor.ControlsGroup>
      </Popover.Dropdown>
    </Popover>
  );
}
