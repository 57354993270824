import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import { Stack, Tabs } from '@mantine/core';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';

import { useStyles } from './Config.styles';
import { Select } from '@/components/Select';
import { AlignToSelectPanel } from '@/components/Theme/AlignToSelectPanel';
import { BorderPanel } from '@/components/Theme/BorderPanel';
import { BorderRadiusPanel } from '@/components/Theme/BorderRadiusPanel';
import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useGetNestLevel } from '@/contentObjects/Root/hooks/useGetNestLevel';
import { useFlexSectionStyle } from '@/widgets/FlexSection/hooks/useFlexSectionStyle';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export const directionData = [FlexSectionDirection.row, FlexSectionDirection.column];

export function Config() {
  const t = useTranslations('components.theme.settings');

  const document = useCurrentDocument();
  const [id] = useContentObjectProperty<string>('id');

  const [alignTo, setAlignTo] = useFlexSectionStyle<string | null>(document, id, 'alignTo');
  const [backgroundColor, setBackgroundColor] = useFlexSectionStyle<string | null>(document, id, 'backgroundColor');
  const [padding, setPadding] = useFlexSectionStyle<number | null>(document, id, 'padding');
  const [gap, setGap] = useFlexSectionStyle<number | null>(document, id, 'gap');
  const [direction, setDirection] = useContentObjectProperty<string>('direction');
  const [border, setBorder] = useFlexSectionStyle<BorderThemeProps | null>(document, id, 'border');
  const [borderRadius, setBorderRadius] = useFlexSectionStyle<Partial<BorderRadiusThemeProps> | null>(
    document,
    id,
    'borderRadius'
  );

  const { classes } = useStyles();

  const [activityThemeStyles] = useActivityThemeStyles(document);
  const sectionsStyles = activityThemeStyles.sections;
  const nestLevel = useGetNestLevel(document, id, WidgetType.FlexSection);
  const boundedNestLevel = Math.min(nestLevel, 3) as 1 | 2 | 3;
  const type = direction === FlexSectionDirection.row ? 'horizontal' : 'vertical';
  const themePath = `${type}${boundedNestLevel}` as const;
  const themeStyles = sectionsStyles[themePath];
  const [themeBorder] = useActivityThemeStylesProperty<Omit<BorderThemeProps, 'borderColor'> & { borderColor: string }>(
    document,
    `sections.${themePath}.border`,
    undefined,
    'global.content.colors.decoration.main'
  );

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Stack className={classes.contentStack}>
          <ThemeItemSection label={t('direction')}>
            <Select data={directionData} value={direction} onChange={value => value && setDirection(value)} />
          </ThemeItemSection>
          <ThemeItemSection label={t('backgroundColor')}>
            <ColorInputPanel
              color={backgroundColor ?? themeStyles.backgroundColor}
              setColor={setBackgroundColor}
              ResetButton={backgroundColor && <ResetButton onClick={() => setBackgroundColor(null)} />}
            />
          </ThemeItemSection>
          <ThemeItemSection label={t('border')}>
            <BorderPanel
              label={t('border')}
              border={border ?? themeBorder}
              setBorder={setBorder}
              themeControlClassName={classes.themeControl}
              ResetButton={border && <ResetButton onClick={() => setBorder(null)} />}
            />
            <BorderRadiusPanel
              label={t('borderRadius')}
              setBorderRadius={setBorderRadius}
              borderRadius={borderRadius ?? themeStyles.borderRadius}
              ResetButton={borderRadius && <ResetButton onClick={() => setBorderRadius(null)} />}
            />
          </ThemeItemSection>
          <ThemeItemSection label={t('layout')}>
            <AlignToSelectPanel
              label={t('alignTo')}
              alignTo={alignTo ?? themeStyles.alignTo}
              setAlignTo={setAlignTo}
              type={type}
              ResetButton={alignTo && <ResetButton onClick={() => setAlignTo(null)} />}
            />
            <NumberInputPanel
              label={t('padding')}
              value={padding ?? themeStyles.padding}
              onChange={setPadding}
              ResetButton={!isNil(padding) && <ResetButton onClick={() => setPadding(null)} />}
            />
            <NumberInputPanel
              label={t('gap')}
              value={gap ?? themeStyles.gap}
              onChange={setGap}
              ResetButton={!isNil(gap) && <ResetButton onClick={() => setGap(null)} />}
            />
          </ThemeItemSection>
        </Stack>
      </Tabs.Panel>
    </WidgetConfig>
  );
}
