import { Box, Flex } from '@mantine/core';
import { useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { WordBankOption } from './WordBankOption';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';
import { useTiptapFillInTheBlanksOptionsStore } from '@/widgets/FillInTheBlanksQuestion/player/hooks/useTiptapFillInTheBlanksOptionsStore';
import { MovedWordOption, WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import { useContentObjectArray, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

const getRemainingOptions = (selectedAnswers: Record<string, WordOption>, wordBankOptions: WordOption[]) => {
  const selectedAnswersOptions = Object.values(selectedAnswers).map(({ id }) => id);
  return wordBankOptions.filter(option => !selectedAnswersOptions.includes(option.id));
};

export function WordBank() {
  const [wordBankOptions] = useContentObjectArray<WordOption>(`wordBankOptions`);

  const selectedAnswers = useTiptapFillInTheBlanksOptionsStore(state => state.selectedAnswers);
  const disabled = useTiptapFillInTheBlanksOptionsStore(state => state.disabled);
  const handleSelectAnswers = useTiptapFillInTheBlanksOptionsStore(state => state.handleSelectAnswers);

  const [questionId] = useContentObjectProperty<string>('id');

  const [{ isOver }, dropRef] = useDrop<MovedWordOption, void, { isOver: boolean }>(
    {
      accept: `fitb-option-moved-${questionId}`,
      collect: monitor => ({
        isOver: monitor.isOver()
      }),
      canDrop: () => {
        return !disabled;
      },
      drop: item => {
        const { [item.fillInTheBlanksId]: toRemove, ...remainingAnswers } = selectedAnswers;
        handleSelectAnswers(remainingAnswers);
      }
    },
    [disabled, questionId, selectedAnswers, handleSelectAnswers]
  );

  const { classes, cx } = useStyles({ disabled, isOver });

  const remainingOptions = useMemo(
    () => getRemainingOptions(selectedAnswers, wordBankOptions),
    [selectedAnswers, wordBankOptions]
  );

  return (
    <Box
      ref={dropRef}
      className={cx(classes.wordBankWrapper, ThemeClassNames.widgets.fillInTheBlanksQuestion.wordBank)}
    >
      <Flex className={classes.wordBankOptions} wrap="wrap" data-testid="preview-word-bank">
        {remainingOptions.map(item => (
          <WordBankOption key={item.id} disabled={disabled} item={item} questionId={questionId} />
        ))}
      </Flex>
    </Box>
  );
}
