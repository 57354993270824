import { useMantineTheme } from '@mantine/core';
import { useCallback, useMemo } from 'react';

import { ChartType } from 'shared/types/ChartType';

import { HorizontalBarChart } from './HorizontalBarChart';
import { PieChart } from './PieChart';
import { VerticalBarChart } from './VerticalBarChart';
import { useUiLanguage } from '@/utils/useUiLanguage';

export type Data = { key: string; value: number; label?: string; img?: string; isCorrect?: boolean }[];

export type ChartVariant = 'standard' | 'validated';

export const ASPECT_RATIO = 1.76;
export const HORIZONTAL_MARGIN = 24;
export const VERTICAL_MARGIN = 12;
export const FONT_SIZE = 27;
export const IMG_WIDTH = 120;
export const IMG_HEIGHT = 96;
export const TEXT_WIDTH = 337;

type ChartProps = {
  chartType: ChartType;
  data: Data;
  variant: ChartVariant;
};

export const Chart = (props: ChartProps) => {
  const { chartType, data, variant } = props;

  const theme = useMantineTheme();

  const getBarColor = useCallback(
    (index: number, isCorrect?: boolean) => {
      if (variant === 'validated') {
        return isCorrect ? theme.colors.green[3] : theme.colors.pink[2];
      }

      const colors = [
        theme.colors.accent[4],
        theme.colors.pink[4],
        theme.colors.red[4],
        theme.colors.cyan[4],
        theme.colors.blue[4],
        theme.colors.green[4]
      ];
      return colors[index % colors.length];
    },
    [variant, theme]
  );

  const { uiDirection } = useUiLanguage();
  const directionStyle = useMemo(() => ({ direction: uiDirection }), [uiDirection]);

  const dataHasImage = data.some(dataItem => dataItem.img);
  const dataHasLabel = data.some(dataItem => dataItem.label);

  if (chartType === ChartType.HorizontalBarChart) {
    return (
      <HorizontalBarChart
        data={data}
        dataHasImage={dataHasImage}
        dataHasLabel={dataHasLabel}
        directionStyle={directionStyle}
        getBarColor={getBarColor}
        variant={variant}
      />
    );
  }

  if (chartType === ChartType.VerticalBarChart) {
    return (
      <VerticalBarChart
        data={data}
        dataHasImage={dataHasImage}
        dataHasLabel={dataHasLabel}
        directionStyle={directionStyle}
        getBarColor={getBarColor}
        variant={variant}
      />
    );
  }

  if (chartType === ChartType.PieChart) {
    return <PieChart data={data} directionStyle={directionStyle} getBarColor={getBarColor} />;
  }

  return null;
};
