import { WidgetType } from 'shared/utils/widgets';

import { ThemeClassNames } from '@/consts/ThemeClassNames';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const widgetTypeToClassName: Record<keyof typeof WidgetType, string> = {
  Audio: ThemeClassNames.widgets.audio.this,
  BlockTitle: ThemeClassNames.widgets.blockTitle.this,
  ClozeQuestion: ThemeClassNames.widgets.clozeQuestion.this,
  ClozeQuestionAnswer: ThemeClassNames.widgets.clozeQuestion.answer,
  CombinedTitle: ThemeClassNames.widgets.combinedTitle.this,
  Embed: ThemeClassNames.widgets.embed.this,
  EnclosureWithBlockTitle: ThemeClassNames.widgets.enclosureWithBlockTitle.this,
  EnclosureWithCombinedTitle: ThemeClassNames.widgets.enclosureWithCombinedTitle.this,
  EvaluatablePlugin: ThemeClassNames.widgets.evaluatablePlugin.this,
  EvaluatablePluginAnswer: ThemeClassNames.widgets.evaluatablePlugin.answer,
  FillInTheBlanksQuestion: ThemeClassNames.widgets.fillInTheBlanksQuestion.this,
  FillInTheBlanksQuestionAnswer: ThemeClassNames.widgets.fillInTheBlanksQuestion.answer,
  FlexSection: ThemeClassNames.widgets.flexSection.this,
  Image: ThemeClassNames.widgets.image.this,
  InteractivePoll: ThemeClassNames.widgets.interactivePoll.this,
  InteractivePollAnswer: ThemeClassNames.widgets.interactivePoll.answer,
  InteractiveQuiz: ThemeClassNames.widgets.interactiveQuiz.this,
  InteractiveQuizAnswer: ThemeClassNames.widgets.interactiveQuiz.answer,
  MicrosoftEmbed: ThemeClassNames.widgets.microsoftEmbed.this,
  OpenDiscussion: ThemeClassNames.widgets.openDiscussion.this,
  OpenQuestion: ThemeClassNames.widgets.openQuestion.this,
  OpenQuestionAnswer: ThemeClassNames.widgets.openQuestion.answer,
  Page: '',
  PdfDocument: ThemeClassNames.widgets.pdfDocument.this,
  Plugin: '',
  QuestionGroupList: ThemeClassNames.widgets.questionGroupList.this,
  Section: '',
  SelectionQuestion: ThemeClassNames.widgets.selectionQuestion.this,
  SelectionQuestionAnswer: ThemeClassNames.widgets.selectionQuestion.answer,
  Slide: '',
  Spacer: '',
  SubmitPage: ThemeClassNames.widgets.submitPage.this,
  SubmitPageComment: ThemeClassNames.widgets.submitPageComment.this,
  SubmitPageReturnToFixSwitch: ThemeClassNames.widgets.submitPageReturnToFixSwitch.this,
  SubmitPageSatisfactionSurvey: ThemeClassNames.widgets.submitPageSatisfactionSurvey.this,
  SubmitPageTitle: ThemeClassNames.widgets.submitPageTitle.this,
  SubmitPageUnansweredQuestions: ThemeClassNames.widgets.submitPageUnansweredQuestions.this,
  SummaryPage: ThemeClassNames.widgets.summaryPage.this,
  SummaryPageSubmissionHistory: ThemeClassNames.widgets.summaryPageSubmissionHistory.this,
  SummaryPageTaskSubmittedNotification: ThemeClassNames.widgets.summaryPageTaskSubmittedNotification.this,
  Table: ThemeClassNames.widgets.table.this,
  Text: ThemeClassNames.widgets.text.this,
  Video: ThemeClassNames.widgets.video.this
};
