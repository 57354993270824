import { TagsInput } from '@mantine/core';
import uniq from 'lodash/uniq';
import { useTranslations } from 'next-intl';

import { ThemeClassNamesStringList } from '@/consts/ThemeClassNames';
import { useActivityThemeCSS } from '@/contentObjects/Root/hooks/useActivityThemeCSS';
import {
  getUniqueClassesListFromJSON,
  useAllCustomCSSClasses,
  useWidgetCustomCSSClasses
} from '@/utils/customCSSClasses';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

const classNamesRegex = /\.([^\s{}:,]+)(?![^{}]*\})/g;

const getMultiSelectClassesData = (allCustomCSSClassesJSON: Record<string, string[]>, activityThemeCSS: string) => {
  const allEditorClasses = [...activityThemeCSS.matchAll(classNamesRegex)].map(match => match[1]);
  const customEditorClasses = allEditorClasses.filter(name => !ThemeClassNamesStringList.includes(name));
  const customWidgetsClasses = getUniqueClassesListFromJSON(allCustomCSSClassesJSON);

  return uniq([...customWidgetsClasses, ...customEditorClasses]).map(customClass => {
    return { value: customClass, label: customClass };
  });
};

export function CustomCSSClassesInput() {
  const t = useTranslations('widgets.customCSSClasses');
  const { id: widgetId, document } = useContentObject();

  // Two hooks are needed to achieve the proper data reactiveness:
  // first -> data to read
  const widgetAppliedCustomClasses = useWidgetCustomCSSClasses(widgetId, document) ?? [];
  // second -> map to modify
  const allCustomCSSClasses = useAllCustomCSSClasses(document);

  const [{ activityThemeCSS }] = useActivityThemeCSS(document);

  const onChange = (newCustomClasses: string[]) => {
    allCustomCSSClasses.set(widgetId, newCustomClasses);
  };

  const multiSelectClassesData = getMultiSelectClassesData(allCustomCSSClasses.toJSON(), activityThemeCSS);

  return (
    <FormControl>
      <TagsInput
        label={t('label')}
        value={widgetAppliedCustomClasses}
        data={multiSelectClassesData}
        placeholder={t('placeholder')}
        onChange={onChange}
        splitChars={[',', ' ', '|']}
        data-testid="custom-css-classes-input"
      />
    </FormControl>
  );
}
