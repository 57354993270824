export const createLearningObjectSnapshot = async (learningObjectDraftId: string) => {
  const response = await fetch('/api/learning-object-snapshot/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId })
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}, Could not create a new snapshot`, {
      cause: response
    });
  }
  return response;
};

type ListLearningObjectSnapshotsOptions = {
  learningObjectDraftId: string;
  limit?: number;
  publishedOnly?: boolean;
};

export const listLearningObjectSnapshots = async ({
  learningObjectDraftId,
  limit,
  publishedOnly
}: ListLearningObjectSnapshotsOptions) => {
  const params = new URLSearchParams({
    ...(limit && { limit: limit.toString() }),
    ...(publishedOnly && { publishedOnly: 'true' })
  });

  const response = await fetch(`/api/learning-object-snapshot/${learningObjectDraftId}?${params.toString()}`, {
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Fetching learning object snapshots failed (${response.status}): ${response.statusText}.`, {
      cause: response
    });
  }

  return await response.json();
};

type GetLatestLearningObjectSnapshotOptions = {
  learningObjectDraftId: string;
  publishedOnly?: boolean;
};
export const getLatestLearningObjectSnapshot = async ({
  learningObjectDraftId,
  publishedOnly
}: GetLatestLearningObjectSnapshotOptions) => {
  const params = new URLSearchParams({
    ...(publishedOnly && { publishedOnly: 'true' })
  });

  const response = await fetch(
    `/api/learning-object-snapshot/get-latest/${learningObjectDraftId}?${params.toString()}`,
    {
      method: 'GET'
    }
  );

  if (!response.ok) {
    throw new Error(
      `Fetching the latest learning object snapshot failed (${response.status}): ${response.statusText}.`,
      {
        cause: response
      }
    );
  }

  return await response.json();
};

export const getLearningObjectSnapshotById = async ({
  learningObjectSnapshotId
}: {
  learningObjectSnapshotId?: string;
}) => {
  if (!learningObjectSnapshotId) {
    throw new Error('No `learningObjectSnapshotId`');
  }

  const response = await fetch(`/api/learning-object-snapshot/get-one-by-id/${learningObjectSnapshotId}`, {
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Fetching learning object snapshot by id failed (${response.status}): ${response.statusText}.`, {
      cause: response
    });
  }

  return await response.json();
};

export const publishLearningObjectSnapshot = async (learningObjectDraftId: string) => {
  return await fetch('/api/learning-object-snapshot/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId })
  });
};

export const restoreLearningObjectSnapshot = async (args: { learningObjectSnapshotId?: string }) => {
  const { learningObjectSnapshotId } = args;
  if (!learningObjectSnapshotId) {
    throw new Error('No `learningObjectSnapshotId`');
  }

  const response = await fetch(`/api/learning-object-snapshot/restore/${learningObjectSnapshotId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(`Fetching learning object snapshot failed (${response.status}): ${response.statusText}.`, {
      cause: response
    });
  }
};

export const getAllPublishedSnapshotIds = async () => {
  const response = await fetch(`/api/learning-object-snapshot/get-all-published-ids`, {
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(
      `Fetching published learning object snapshot failed (${response.status}): ${response.statusText}.`,
      {
        cause: response
      }
    );
  }

  return await response.json();
};
