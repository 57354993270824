import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { FeedbackMessages, FeedbackMessageType } from '@/utils/feedback.utils';

type QuestionFooterStylesProps = {
  feedbackMessageType?: FeedbackMessageType | null;
  dense?: boolean;
};

type FeedbackColorMap = Record<FeedbackMessageType, string>;

export const useStyles = createStyles((theme, props: QuestionFooterStylesProps) => {
  const { dense, feedbackMessageType } = props ?? {};

  const feedbackColorMap: FeedbackColorMap = {
    [FeedbackMessages.OnlyAllCorrectAnswers]: theme.other.feedback.color.correct,
    [FeedbackMessages.OnlyWrongAnswers]: theme.other.feedback.color.wrong,
    [FeedbackMessages.NoAnswer]: theme.other.feedback.color.wrong,
    [FeedbackMessages.NotAllCorrectAndNoWrongAnswers]: theme.other.feedback.color.partiallyCorrect,
    [FeedbackMessages.SomeCorrectAndSomeWrongAnswers]: theme.other.feedback.color.partiallyCorrect,
    [FeedbackMessages.ShowAnswer]: theme.other.feedback.color.showAnswer
  };
  return {
    container: {
      '&:not(:empty)': {
        marginTop: dense ? rem(8) : rem(32)
      }
    },
    checkButton: {
      height: rem(40),
      padding: `${rem(8)} ${rem(24)}`
    },
    feedbackWrapper: {
      width: '80%'
    },
    feedback: {
      display: 'flex',
      alignItems: 'center',
      color: feedbackMessageType ? feedbackColorMap[feedbackMessageType] : '',
      fontSize: rem(theme.other.feedback.fontSize),
      fontWeight: theme.other.feedback.fontWeight,
      gap: rem(16),
      position: 'relative'
    },
    feedbackAnimation: {
      width: rem(200),
      zIndex: 2,
      verticalAlign: 'middle',
      position: 'absolute',
      insetInlineStart: rem(-75),
      bottom: rem(-50),
      pointerEvents: 'none'
    },
    feedbackIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    separator: {
      insetInlineStart: rem(-36),
      position: 'relative',
      width: `calc(100% + ${rem(36)})`,
      borderTop: `${rem(1)} solid #f7f6fd`,
      marginTop: rem(48),
      marginBottom: rem(40)
    }
  };
});
