import { WidgetType } from 'shared/utils/widgets';

export const getEmptySubQuestionAnswer = (widgetType?: WidgetType) => {
  switch (widgetType) {
    case WidgetType.SelectionQuestion:
      return [];
    case WidgetType.ClozeQuestion:
    case WidgetType.FillInTheBlanksQuestion:
    case WidgetType.OpenQuestion:
      return {};
    default:
      return undefined;
  }
};
