import { Box, Flex, SelectProps } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { forwardRef, useCallback, useMemo } from 'react';

import { Select } from '@/components/Select';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { TextAlignCenter } from '@/icons/TextAlignCenter';
import { TextAlignLeft } from '@/icons/TextAlignLeft';
import { TextAlignRight } from '@/icons/TextAlignRight';
import { Alignment, getAlignmentFromLanguage } from '@/utils/locale.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { BUTTONS_GAP_PX } from '@/widgets/_components/RichTextToolbar/RichTextToolbar.css';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

const alignmentIcon = {
  left: TextAlignLeft,
  center: TextAlignCenter,
  right: TextAlignRight
};

const alignmentComponent = {
  left: RichTextEditor.AlignLeft,
  center: RichTextEditor.AlignCenter,
  right: RichTextEditor.AlignRight
};

export type AlignmentSelectProps = {
  editor: Editor | null;
  isSelectView?: boolean;
};

export function AlignmentSelect(props: AlignmentSelectProps) {
  const { isSelectView } = props;
  const { classes } = useStyles({ maxWidth: 60 });
  const { contentLanguage } = useContentLanguage();
  const defaultAlignment = getAlignmentFromLanguage(contentLanguage);
  const t = useTranslations('widgets.richTextToolbar.alignmentSelect');
  const { editor } = props;

  const getAlignmentDataActiveProp = useCallback(
    (align: Alignment) => {
      if (editor?.isActive({ textAlign: align })) {
        return { 'data-on': true };
      }
      return {};
    },
    [editor]
  );

  const getCurrentAlignment = useCallback(() => {
    if (editor?.isActive({ textAlign: 'left' })) {
      return 'left';
    }
    if (editor?.isActive({ textAlign: 'center' })) {
      return 'center';
    }
    if (editor?.isActive({ textAlign: 'right' })) {
      return 'right';
    }
    return defaultAlignment;
  }, [defaultAlignment, editor]);

  const setAlignment = useCallback(
    (align: Alignment) => {
      editor?.chain().focus().setTextAlign(align).run();
    },
    [editor]
  );

  const alignmentOptions: AlignmentOptionProps[] = useMemo(
    () => [
      {
        value: 'left',
        getAlignmentDataActiveProp,
        setAlignment,
        label: ''
      },
      {
        value: 'center',
        getAlignmentDataActiveProp,
        setAlignment,
        label: ''
      },
      {
        value: 'right',
        getAlignmentDataActiveProp,
        setAlignment,
        label: ''
      }
    ],
    [getAlignmentDataActiveProp, setAlignment]
  );

  const currentAlignment = getCurrentAlignment();
  const Icon = alignmentIcon[currentAlignment];

  return isSelectView ? (
    <Select
      title={t('title') ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: classes.selectInput,
        section: classes.selectIcon,
        label: classes.selectLabel
      }}
      data={alignmentOptions}
      renderOption={renderAlignmentOption}
      label={
        <Box data-on={true}>
          <Icon />
        </Box>
      }
      variant="unstyled"
      leftSection={<DropdownArrow />}
      leftSectionWidth={16}
      rightSection={<></>}
      rightSectionWidth={'0px'}
    />
  ) : (
    <Flex direction="row" gap={BUTTONS_GAP_PX} sx={{ direction: 'ltr' }}>
      {alignmentOptions.map(alignmentData => (
        <AlignmentOption
          key={alignmentData.value}
          value={alignmentData.value}
          getAlignmentDataActiveProp={alignmentData.getAlignmentDataActiveProp}
          setAlignment={alignmentData.setAlignment}
          label={alignmentData.label}
        />
      ))}
    </Flex>
  );
}

type AlignmentOptionProps = {
  value: Alignment;
  getAlignmentDataActiveProp: (align: Alignment) =>
    | {
        'data-on': boolean;
      }
    | {
        'data-on'?: undefined;
      };
  setAlignment: (align: Alignment) => void;
  label: string;
};

const renderAlignmentOption: SelectProps['renderOption'] = props => {
  const { option } = props;

  const { value, getAlignmentDataActiveProp, setAlignment, label } = option as AlignmentOptionProps;

  return (
    <AlignmentOption
      value={value}
      getAlignmentDataActiveProp={getAlignmentDataActiveProp}
      setAlignment={setAlignment}
      label={label}
    />
  );
};

const AlignmentOption = forwardRef<HTMLButtonElement, AlignmentOptionProps>(function AlignmentOption(props, ref) {
  const { getAlignmentDataActiveProp, setAlignment, value } = props;

  const Component = alignmentComponent[value];
  const Icon = alignmentIcon[value];
  const dataOn = getAlignmentDataActiveProp(value);

  return <Component icon={() => <Icon />} onClick={() => setAlignment(value)} ref={ref} m={0} w="100%" {...dataOn} />;
});
