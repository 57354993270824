import { isQuestionOrGroupQuestion } from 'shared/questions/utils';
import { WidgetType } from 'shared/utils/widgets';

import { ContentObjectsMap, Layout } from '@/utils/selectContentObjects';

export const getActivityOrderedQuestionsIds = (pagesIds: string[], contentObjects: ContentObjectsMap) =>
  pagesIds.flatMap(pageId => getPageOrderedQuestionsIds(pageId, contentObjects));

export const getActivityVisibleOrderedQuestionsIds = (pagesIds: string[], contentObjects: ContentObjectsMap) =>
  pagesIds
    .filter(pageId => !contentObjects[pageId]?.isHidden)
    .flatMap(pageId => getPageOrderedQuestionsIds(pageId, contentObjects));

export const getActivityHiddenQuestionsIds = (pagesIds: string[], contentObjects: ContentObjectsMap) =>
  pagesIds
    .filter(pageId => contentObjects[pageId]?.isHidden)
    .flatMap(pageId => getPageOrderedQuestionsIds(pageId, contentObjects));

const getPageOrderedQuestionsIds = (pageId: string, contentObjects: ContentObjectsMap) => {
  const page = contentObjects[pageId];
  return page?.children.flatMap(sectionId => getSectionOrderedQuestionsIds(sectionId, contentObjects));
};

const getSectionOrderedQuestionsIds = (sectionId: string, contentObjects: ContentObjectsMap): string | string[] => {
  const section = contentObjects[sectionId];
  const { children: childrenIds = [], layout, type } = section ?? {};

  if (isQuestionOrGroupQuestion(type)) {
    return sectionId;
  }

  if (type === WidgetType.Section) {
    return childrenIds
      .sort(sortByColumn(layout))
      .flatMap(childId => getSectionOrderedQuestionsIds(childId, contentObjects));
  }

  if (type === WidgetType.FlexSection) {
    return childrenIds.flatMap(childId => getSectionOrderedQuestionsIds(childId, contentObjects));
  }

  return [];
};

const sortByColumn =
  (layout: Layout = {}) =>
  (questionAId: string, questionBId: string) => {
    const questionA = layout[questionAId];
    const questionB = layout[questionBId];

    const columnA = questionA?.column ?? 0;
    const columnB = questionB?.column ?? 0;

    return columnA - columnB;
  };
