import { Node, Slice } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';

export function handlePasteLogic(view: EditorView, slice: Slice, processingLogic: (node: Node) => string | null) {
  const transformedNodes: string[] = [];
  slice.content.descendants(node => {
    const transformedText = processingLogic(node);
    if (transformedText) {
      transformedNodes.push(transformedText);
    } else if (node.type.name === 'text') {
      transformedNodes.push(node.textContent);
    }
  });

  const newNode = view.state.schema.text(transformedNodes.join(''));
  const transaction = view.state.tr.replaceSelectionWith(newNode);
  view.dispatch(transaction);

  return true;
}
