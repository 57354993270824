import * as Y from 'yjs';

import { alignLayoutItems } from 'shared/utils/layoutHelpers';
import { WidgetType } from 'shared/utils/widgets';
import { findPositionInArray, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

export const moveItemBetweenSections = (
  itemId: string,
  currentSection: Y.Map<unknown>,
  newSection: Y.Map<unknown>,
  position: number
) => {
  const currentSectionId = currentSection.get('id') as string;
  const currentSectionLayout = validateYMap(currentSection.get('layout'));
  const currentItemLayout = validateYMap(currentSectionLayout.get(itemId));
  const currentSpan = currentItemLayout.get('span');
  const currentItemLayoutClone = currentItemLayout.clone();
  const currentSectionChildren = validateYArray(currentSection.get('children'));
  const itemCurrentPosition = findPositionInArray(currentSectionChildren, itemId);

  const newSectionId = newSection.get('id') as string;
  const newSectionChildren = validateYArray(newSection.get('children'));
  const newSectionLayout = validateYMap<Y.Map<unknown>>(newSection.get('layout'));
  const newSectionType = newSection?.get('type') as WidgetType;

  const newItemPosition = position === -1 ? 0 : position;

  if (newSectionType === WidgetType.FlexSection) {
    if (currentSectionId === newSectionId) {
      const item = currentSectionChildren.get(itemCurrentPosition);
      currentSectionChildren.delete(itemCurrentPosition);
      const adjustedPosition = itemCurrentPosition < newItemPosition ? newItemPosition - 1 : newItemPosition;
      currentSectionChildren.insert(adjustedPosition, [item]);
    } else {
      currentSectionChildren.delete(itemCurrentPosition);
      currentSectionLayout.delete(itemId);
      newSectionChildren.insert(newItemPosition, [itemId]);
    }
  } else {
    currentSectionChildren.delete(itemCurrentPosition);
    currentSectionLayout.delete(itemId);
    if (position === -1) {
      alignLayoutItems(newSectionLayout);
    }
    newSectionChildren.push([itemId]);
  }

  currentItemLayoutClone.set('column', newItemPosition);
  // Case where an item is moved from a vertical section onto a one with grid
  if (!currentSpan) {
    currentItemLayoutClone.set('span', 1);
  }
  newSectionLayout.set(itemId, currentItemLayoutClone);
};
