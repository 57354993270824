import { ActionIcon, Box, Button, Flex, Popover, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useMemo, useRef } from 'react';
import { z } from 'zod';

import { useStyles } from './InsertImage.styles';
import { UploadFile } from '@/components/UploadFile';
import { Image } from '@/icons/Image';
import { ImageUpload } from '@/icons/ImageUpload';
import { getImageSizeBySrc } from '@/utils/image.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { Manifest } from '@/widgets/Image/Manifest';
import { FormControl } from '@/widgets/_components/FormControl';

export type InsertImageProps = {
  editor: Editor | null;
};

type InsertImageFormProps = {
  alt: string;
  src: string;
  width: number;
  height: number;
};

const initialValues = {
  alt: '',
  src: '',
  width: 0,
  height: 0
};

export function InsertImage(props: InsertImageProps) {
  const { classes } = useStyles();
  const { editor } = props;
  const { uiDirection } = useUiLanguage();
  const t = useTranslations('widgets.insertImage');

  const previousImage = useRef<
    Partial<InsertImageFormProps> & { widthAspectRatio?: number; heightAspectRatio?: number }
  >(initialValues);

  const [opened, { toggle, close }] = useDisclosure(false);
  const schema = useMemo(
    () =>
      z.object({
        alt: z.string().min(2, { message: t('addAlternativeTextError') ?? '' }),
        src: z.string().url({ message: t('uploadImageMissingError') ?? '' }),
        width: z.coerce.number().pipe(z.number().min(1, { message: t('widthError') ?? '' })),
        height: z.coerce.number().pipe(z.number().min(1, { message: t('heightError') ?? '' }))
      }),
    [t]
  );

  const form = useForm<InsertImageFormProps>({
    initialValues,
    validateInputOnChange: false,
    validate: zodResolver(schema),
    transformValues: values => {
      return values;
    }
  });

  const handleImageSelected = async (src: string) => {
    form.setFieldValue('src', src);

    let widthAspectRatio = previousImage.current.widthAspectRatio ?? 0;
    let heightAspectRatio = previousImage.current.heightAspectRatio ?? 0;

    const { width, height } = await getImageSizeBySrc(src);
    widthAspectRatio = width / height;
    heightAspectRatio = height / width;
    form.setValues({
      width,
      height
    });
    previousImage.current = { src, width, height, widthAspectRatio, heightAspectRatio };
  };

  const handleResetForm = () => {
    previousImage.current = initialValues;
    form.reset();
    close();
  };

  const handleWidthBlur = () => {
    const width = Number(form.getInputProps('width').value);
    if (previousImage.current.width !== width) {
      if (isNaN(width)) {
        form.setFieldValue('width', previousImage.current.width ?? 0);
      } else {
        const newHeight = Math.round(width * (previousImage.current.heightAspectRatio ?? 1));
        form.setFieldValue('height', newHeight);
        previousImage.current.height = newHeight;
      }
    }
  };

  const handleHeighthBlur = () => {
    const height = Number(form.getInputProps('height').value);
    if (previousImage.current.height !== height) {
      if (isNaN(height)) {
        form.setFieldValue('height', previousImage.current.height ?? 0);
      } else {
        const newWidth = Math.round(height * (previousImage.current.widthAspectRatio ?? 1));
        form.setFieldValue('width', newWidth);
        previousImage.current.width = newWidth;
      }
    }
  };

  const handleInsertImage = () => {
    if (form.isValid()) {
      editor?.commands.setImage({
        src: form.getInputProps('src').value,
        alt: form.getInputProps('alt').value,
        title: form.getInputProps('alt').value,
        width: Number(form.getInputProps('width').value),
        height: Number(form.getInputProps('height').value),
        style: 'max-width: 100%;'
      });

      handleResetForm();
    }
  };

  return (
    <Popover
      opened={opened}
      position="bottom"
      withArrow
      shadow="md"
      trapFocus
      onClose={handleResetForm}
      withinPortal={false}
    >
      <Popover.Target>
        <ActionIcon variant="subtle" component="div" onClick={toggle} title={t('title')} size="lg">
          <RichTextEditor.Control>
            <Image alt="" />
          </RichTextEditor.Control>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Box
          className={classes.popoverContainer}
          dir={uiDirection}
          component="form"
          onReset={handleResetForm}
          onSubmit={form.onSubmit(handleInsertImage)}
        >
          <Flex>
            <FormControl title={t('uploadImage')} className={classes.uploadContainer}>
              <UploadFile
                previewMaxHeight={130}
                src={form.values.src}
                alt={t('uploadImage') ?? ''}
                Icon={ImageUpload}
                text={t('uploadImage') ?? ''}
                maxSize={Manifest.additionalData?.maxFileSizeBytes}
                maxFiles={Manifest.additionalData?.maxFiles}
                accept={Manifest.additionalData?.acceptFileType}
                onDrop={handleImageSelected}
                onDelete={handleResetForm}
                dropError={t('unableUpload')}
              />
            </FormControl>
            <Box>
              <FormControl title={t('addAlternativeText')} withAsterisk>
                <TextInput
                  {...form.getInputProps('alt')}
                  placeholder={t('addAlternativeTextPlaceholder') ?? ''}
                  data-testid="texteditor-image-alternative-text-field"
                />
              </FormControl>
              <Flex>
                <FormControl title={t('width')} withAsterisk>
                  <TextInput
                    {...form.getInputProps('width')}
                    type="number"
                    className={classes.textInput}
                    onBlur={handleWidthBlur}
                    data-testid="texteditor-image-width-text-field"
                  />
                </FormControl>
                <FormControl title={t('height')} withAsterisk>
                  <TextInput
                    {...form.getInputProps('height')}
                    type="number"
                    className={classes.textInput}
                    onBlur={handleHeighthBlur}
                    data-testid="texteditor-image-height-text-field"
                  />
                </FormControl>
              </Flex>
              <Flex justify="flex-end" className={classes.footerButtons}>
                <Button type="reset" variant="outline">
                  {t('cancel')}
                </Button>
                <Button type="submit" className={classes.submitButton}>
                  {t('ok')}
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
}
