import { ClientModulesCreators } from '@hendrx/modules';

import { AuthProvider } from './components/AuthProvider';
import { LoginPage } from './components/LoginPage';
import { createRedirectPage } from './components/RedirectPage';
import { UnauthorizedPage } from './components/UnauthorizedPage';

export const createClientAuthenticationModule: ClientModulesCreators['authentication'] = clientModules => ({
  AuthProvider,
  LoginPage,
  RedirectPage: createRedirectPage(clientModules),
  UnauthorizedPage
});
