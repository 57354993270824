import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import { createContext, ReactElement, useContext, useMemo } from 'react';
import * as Y from 'yjs';
import { createStore, StoreApi, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { QuestionScoreMode } from 'shared/types/QuestionScoreMode';
import { WidgetType } from 'shared/utils/widgets';
import {
  getContentObjectsMap,
  getRootMap,
  useObservedArray,
  useObservedProperty,
  validateYMap
} from 'shared/widgetsSDK/yjs';

import { OrchestrationWrapper } from '@/widgets/_components/ContentObjectProvider/OrchestrationWrapper';
import { EvaluationProps } from 'shared/types/EvaluationProps';

export type RootMapNames = 'contentObjects' | 'widgetsFormData' | 'sensitiveData';

type ContentObjectStoreType = {
  id: string;
  document: Y.Doc;
  columnSpan?: number;
};

const ContentObjectStoreContext = createContext<StoreApi<ContentObjectStoreType> | null>(null);

type ContentObjectProviderProps = Omit<ContentObjectStoreType, 'elementState' | 'setElementState'> & {
  children: ReactElement;
  type: WidgetType;
  withOrchestrationWrapper?: boolean;
};
export function ContentObjectProvider(props: ContentObjectProviderProps) {
  const { id, document, children, columnSpan, withOrchestrationWrapper = true } = props;
  const contentObjectStore = useMemo(
    () =>
      createStore<ContentObjectStoreType, [['zustand/subscribeWithSelector', unknown]]>(
        subscribeWithSelector((set, get) => ({
          id,
          document,
          columnSpan
        }))
      ),
    [id, document, columnSpan]
  );

  // There is a general issue where components mounting/dismounting is not being synchronized with how the data updates
  // It's especially prevalent, where a content object renders children that access their own data
  // But the parent content object gets deleted (along with the children data).
  // This can cause the children to still be mounted and try to access their data causing crashes.
  // This is one possible workaround to ensure we do not mount anything that doesn't actually have data in the store
  const contentObjectsMap = getContentObjectsMap(document);
  if (!contentObjectsMap.has(id)) {
    return null;
  }

  return (
    <ContentObjectStoreContext.Provider value={contentObjectStore}>
      {withOrchestrationWrapper ? <OrchestrationWrapper>{children}</OrchestrationWrapper> : children}
    </ContentObjectStoreContext.Provider>
  );
}

export function useContentObject(property: RootMapNames = 'contentObjects') {
  const store = useContext(ContentObjectStoreContext);
  if (store === null) throw new Error('ContentObjectStoreContext not initialized properly.');
  const { document, id, ...rest } = useStore(store);
  const data = validateYMap(getRootMap(document).get(property))?.get(id) as Y.Map<unknown> | undefined;
  return { data, document, id, ...rest };
}

function useNestedMap<T>(propertyPath: string, parentMap: RootMapNames) {
  // Utility function to extract deeply nested properties
  // Example: answers.0.image.src for SelectionQuestion would look up root.contentObjects.<id>.answers.0.image.src
  const { data } = useContentObject(parentMap);
  const propertyParts = propertyPath.split('.');
  const observedPropertyName = last(propertyParts) as string;
  const pathParts = dropRight(propertyParts);
  const nestedData: Y.Map<T> | undefined = pathParts.reduce(
    (data, pathPart) => data?.get(pathPart),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data as Y.Map<any> | undefined
  );
  if (!nestedData) {
    throw new Error(`Nested map at property path ${propertyPath} not found.`);
  }
  return [observedPropertyName, nestedData] as const;
}

export function useContentObjectStaticProperty<T = unknown>(
  propertyPath: string,
  parentMap: RootMapNames = 'contentObjects'
) {
  const [observedPropertyName, nestedData] = useNestedMap<T>(propertyPath, parentMap);
  return nestedData?.get(observedPropertyName);
}

export function useContentObjectProperty<T = unknown>(
  propertyPath: string,
  parentMap: RootMapNames = 'contentObjects'
) {
  const [observedPropertyName, nestedData] = useNestedMap<T>(propertyPath, parentMap);
  return useObservedProperty<T>(nestedData, observedPropertyName);
}

export function useContentObjectArray<T = unknown>(propertyPath: string, parentMap: RootMapNames = 'contentObjects') {
  const [observedArrayName, nestedData] = useNestedMap<T>(propertyPath, parentMap);
  return useObservedArray<T>(nestedData, observedArrayName);
}

export function useContentObjectErrors<SchemaType = Record<string, Array<string>>>() {
  return useContentObjectProperty<Partial<SchemaType>>('errors', 'widgetsFormData');
}

export function useContentObjectScoreWeight() {
  return useContentObjectProperty<number>('scoreWeight', 'widgetsFormData');
}

export function useContentObjectEvaluationProps(): EvaluationProps {
  const [questionScoreMode] = useContentObjectProperty<QuestionScoreMode>('questionScoreMode');
  const [showAnswerButton] = useContentObjectProperty<boolean>('showAnswerButton');
  const [showHintButton] = useContentObjectProperty<boolean>('showHintButton');
  const [showIndicatorButton] = useContentObjectProperty<boolean>('showIndicatorButton');

  return {
    showAnswerButton,
    showHintButton,
    showIndicatorButton,
    questionScoreMode
  };
}
