import { useMantineTheme } from '@mantine/core';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { SelectionQuestionAnswerElement } from './SelectionQuestionAnswerElement';
import { AnswerOption } from '@/components/AnswerOption';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function SelectionQuestionAnswerOption(props: { answerIndex: number }) {
  const { answerIndex } = props;
  const [answerId] = useContentObjectProperty<string>(`answers.${answerIndex}.id`);
  const [correctAnswers] = useContentObjectProperty<string[]>(`correctAnswers`, 'sensitiveData');
  const isChecked = correctAnswers.includes(answerId);
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const theme = useMantineTheme();

  return (
    <AnswerOption
      AnswerElementComponent={SelectionQuestionAnswerElement}
      answerAreaThemeProps={theme.other.learningItems.selectionQuestion.answerArea}
      answerId={answerId}
      answerIndex={answerIndex}
      answersStyle={answersStyle}
      checkboxThemeProps={theme.other.learningItems.selectionQuestion.checkbox}
      selectedCheckboxThemeProps={theme.other.learningItems.selectionQuestion.selectedCheckbox}
      dataTestId="studio-selection-question"
      isChecked={isChecked}
    />
  );
}
