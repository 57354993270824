import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSubmitPageSatisfactionSurvey } from 'shared/widgetsSDK/SubmitPageSatisfactionSurvey/create';
import { manifestLocales } from 'shared/widgetsSDK/SubmitPageSatisfactionSurvey/manifest.locales';

import SubmitPageSatisfactionSurveyEn from './resources/en/submitPageSatisfactionSurvey.svg';
import SubmitPageSatisfactionSurveyHe from './resources/he/submitPageSatisfactionSurvey.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SubmitPageSatisfactionSurvey,
  previewGroups: [
    {
      id: 'SubmitPageSatisfactionSurvey',
      divider: false,
      previews: [
        {
          name: 'SubmitPageSatisfactionSurvey',
          previewImage: {
            paths: {
              en: SubmitPageSatisfactionSurveyEn,
              he: SubmitPageSatisfactionSurveyHe
            },
            alt: { ...manifestLocales.submitPageSatisfactionSurvey.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: createNewSubmitPageSatisfactionSurvey,
          dimensions: {
            width: 1180,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
