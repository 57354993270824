import { ActionIcon, Flex, Switch } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import * as Y from 'yjs';

import { BallPen } from '@/icons/BallPen';
import { useContentObjectProperty, useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';
import { HintModal } from '@/widgets/_components/questions/HintModal';
import { HelpersTypes, HelperType } from '@/widgets/types/helperType';

type StudentHelpersProps = {
  disabled?: HelpersTypes[];
};

export function StudentHelpers(props: StudentHelpersProps) {
  const { disabled } = props;

  const t = useTranslations('widgets');
  const [showHintButton, setShowHintButton] = useContentObjectProperty<boolean>('showHintButton');
  const [showAnswerButton, setShowAnswerButton] = useContentObjectProperty<boolean>('showAnswerButton');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');

  const [isHintModalOpened, { open: openHintModal, close: closeHintModal }] = useDisclosure(false);

  return (
    <>
      <FormControl title={t('evaluationSettings.studentHelpers')} withSeparator>
        <Flex gap={12} direction="column">
          <Flex align="center" justify="space-between" data-testid="widget-side-menu-hint-box">
            <Switch
              disabled={disabled?.includes(HelperType.Hint)}
              checked={showHintButton}
              onChange={event => setShowHintButton(event.currentTarget.checked)}
              label={t('evaluationSettings.hintButton')}
              data-testid="widget-side-menu-hint-checkbox"
            />
            {showHintButton && (
              <ActionIcon variant="subtle" size="xs" ml="xs" color="accent.8" onClick={openHintModal}>
                <BallPen />
              </ActionIcon>
            )}
          </Flex>
          <Switch
            disabled={disabled?.includes(HelperType.Answer)}
            checked={showAnswerButton}
            onChange={event => setShowAnswerButton(event.currentTarget.checked)}
            label={t('evaluationSettings.answerButton')}
            data-testid="widget-side-menu-answer-checkbox"
          />
        </Flex>
      </FormControl>
      <HintModal isHintEditable opened={isHintModalOpened} hintFragment={hintFragment} close={closeHintModal} />
    </>
  );
}
