import { Box } from '@mantine/core';
import { useElementSize, usePrevious } from '@mantine/hooks';
import cx from 'clsx';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo } from 'react';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { GroupCheckButtonMode, ParentHeadline, QuestionListBulletTypes } from 'shared/types/QuestionGroupList';
import { ToolbarType } from 'shared/types/RichTextToolbar';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { isChoiceSettingLocked } from 'shared/widgetsSDK/questionGroup';
import { getContentObjectsMap, useObservedArray, useObservedProperty, validateYArray } from 'shared/widgetsSDK/yjs';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { Hoverable } from '@/components/Hoverable';
import { QuestionTitle } from '@/components/QuestionTitle';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useValidation, ValidationHandler } from '@/utils/validation';
import { Studio as FlexSection } from '@/widgets/FlexSection/studio';
import * as classes from '@/widgets/QuestionGroupList/QuestionGroupList.css';
import { Schema } from '@/widgets/QuestionGroupList/Schema';
import { Config } from '@/widgets/QuestionGroupList/config';
import { QuestionGroupListSettingsContext } from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import { WidgetNotification } from '@/widgets/_components/WidgetNotification';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';

const acceptedItemTypes = [ItemDragType.Question];
const getValidator: (verticalSectionId: string) => ValidationHandler = verticalSectionId => document => {
  const contentObjectsMap = getContentObjectsMap(document);
  const verticalSection = contentObjectsMap.get(verticalSectionId);
  const subQuestions = validateYArray<string>(verticalSection?.get('children')).toArray();
  const dataValidationResult = Schema.safeParse({ subQuestions });
  const dataErrors = dataValidationResult.success ? {} : dataValidationResult.error.format();
  return dataErrors;
};

export function QuestionGroupList() {
  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const descriptionFragment = useContentObjectStaticProperty<Y.XmlFragment>('descriptionFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [feedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');
  const [parentHeadline] = useContentObjectProperty<`${ParentHeadline}`>('parentHeadline');
  const [questionListBulletType] = useContentObjectProperty<QuestionListBulletTypes>('questionListBulletType');
  const [requiredSubQuestionsId] = useContentObjectProperty<string[]>('requiredSubQuestionsId');
  const [showGeneralGrade] = useContentObjectProperty<boolean>('showGeneralGrade');
  const [showEachQuestionGrade] = useContentObjectProperty<boolean>('showEachQuestionGrade');
  const [groupCheckButtonMode] = useContentObjectProperty<`${GroupCheckButtonMode}`>('groupCheckButtonMode');

  const [scoreWeight] = useContentObjectScoreWeight();
  const { contentLanguage } = useContentLanguage();

  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<unknown>>(contentObjectsMap, verticalSectionId);
  const [verticalSectionChildren] = useObservedArray<string>(verticalSection, 'children');
  const isEmpty = verticalSectionChildren.length === 0;
  const subQuestionsCount = verticalSectionChildren.length;
  const prevSubQuestionsCount = usePrevious(subQuestionsCount);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const t = useTranslations('widgets');

  const [choiceQuestionsEnabled, setChoiceQuestionsEnabled] =
    useContentObjectProperty<boolean>('choiceQuestionsEnabled');
  const [choiceQuestionsCount, setChoiceQuestionsCount] = useContentObjectProperty<number>('choiceQuestionsCount');
  const [visibleQuestionsCount, setVisibleQuestionsCount] = useContentObjectProperty<number>('visibleQuestionsCount');
  const [requiredQuestions] = useContentObjectProperty<string[]>('requiredSubQuestionsId');
  const requiredQuestionsCount = requiredQuestions.length;
  const prevVisibleQuestionsCount = usePrevious(visibleQuestionsCount);

  const showManualHeadline = labelFragment && parentHeadline === ParentHeadline.Manual;
  const showCheckOrResetButton =
    feedback === Feedbacks.Check && groupCheckButtonMode === GroupCheckButtonMode.GeneralButton;

  useEffect(() => {
    const idsToDelete: string[] = [];

    // check if required subQuestions are still in the QuestionGroup
    requiredSubQuestionsId.forEach(id => {
      if (!verticalSectionChildren.includes(id)) {
        idsToDelete.push(id);
      }
    });

    // If a question was removed from QuestionGroup (for example by dragging to another section on the stage),
    // remove this question id from requiredSubQuestionsId
    if (id && idsToDelete.length) {
      const newRequiredIds = requiredSubQuestionsId.filter(id => !idsToDelete.includes(id));
      const parentQuestionMap = contentObjectsMap.get(id);
      parentQuestionMap?.set('requiredSubQuestionsId', newRequiredIds);
    }
  }, [verticalSectionChildren, requiredSubQuestionsId, id, contentObjectsMap]);

  useEffect(() => {
    const subQuestionsCountChanged = prevSubQuestionsCount !== subQuestionsCount;
    if (subQuestionsCountChanged && visibleQuestionsCount === prevSubQuestionsCount) {
      setVisibleQuestionsCount(subQuestionsCount);
    }
  }, [visibleQuestionsCount, prevSubQuestionsCount, subQuestionsCount, setVisibleQuestionsCount]);

  useEffect(() => {
    if (!choiceQuestionsEnabled) {
      setChoiceQuestionsCount(Math.max(visibleQuestionsCount - 1, 1));
    }
  }, [choiceQuestionsEnabled, visibleQuestionsCount, setChoiceQuestionsCount]);

  useEffect(() => {
    if (isChoiceSettingLocked(visibleQuestionsCount, requiredQuestionsCount)) {
      setChoiceQuestionsEnabled(false);
    }
  }, [visibleQuestionsCount, requiredQuestionsCount, choiceQuestionsCount, setChoiceQuestionsEnabled]);

  useEffect(() => {
    const visibleQuestionsCountChanged = prevVisibleQuestionsCount !== visibleQuestionsCount;

    if (
      visibleQuestionsCountChanged &&
      prevVisibleQuestionsCount !== undefined &&
      choiceQuestionsCount === prevVisibleQuestionsCount - 1
    ) {
      setChoiceQuestionsCount(Math.max(visibleQuestionsCount - 1, 0));
    }

    if (choiceQuestionsCount < requiredQuestions.length) {
      setChoiceQuestionsCount(requiredQuestions.length);
    }
  }, [
    choiceQuestionsCount,
    visibleQuestionsCount,
    prevVisibleQuestionsCount,
    setChoiceQuestionsCount,
    requiredQuestions
  ]);

  const settings = useMemo(
    () => ({
      choiceQuestionsEnabled,
      feedback,
      groupCheckButtonMode,
      showEachQuestionGrade,
      scoreWeight,
      questionListBulletType,
      subQuestionsOrder: verticalSectionChildren
    }),
    [
      choiceQuestionsEnabled,
      feedback,
      groupCheckButtonMode,
      showEachQuestionGrade,
      scoreWeight,
      questionListBulletType,
      verticalSectionChildren
    ]
  );

  const listClassName =
    questionListBulletType === 'Letter'
      ? classes.list[questionListBulletType][contentLanguage]
      : classes.list[questionListBulletType];

  const validator = useMemo(() => getValidator(verticalSectionId), [verticalSectionId]);
  useValidation(id, document, validator, [id, verticalSectionId]);

  const { ref: sectionRef, width: sectionWidth } = useElementSize();

  return (
    <div className={customClassNames}>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      <Box className={cx(classes.label, ThemeClassNames.widgets.questionGroupList.label)}>
        {labelFragment && showManualHeadline && (
          <Selectable>
            <Hoverable>
              <QuestionTitle questionId={id} fragment={labelFragment} />
            </Hoverable>
          </Selectable>
        )}
      </Box>
      <Box className={cx(classes.description, ThemeClassNames.widgets.questionGroupList.description)}>
        <Selectable>
          <Hoverable>
            <RichText
              placeholder={t('questionGroupList.initialDescriptionText')}
              fragment={descriptionFragment}
              toolbarType={ToolbarType.Fixed}
            />
          </Hoverable>
        </Selectable>
        {choiceQuestionsEnabled && (
          <WidgetNotification
            text={t('questionGroupListSettings.automaticDescription', {
              minAmountOfInfluencedQuestions: choiceQuestionsCount,
              maxAmountOfQuestions: visibleQuestionsCount
            })}
          />
        )}
      </Box>
      <Box ref={sectionRef}>
        <ContentObjectProvider id={verticalSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            <Box className={cx('themed-list', listClassName)}>
              {subQuestionsCount ? (
                <QuestionGroupListSettingsContext.Provider value={settings}>
                  <FlexSection
                    className={isEmpty ? classes.verticalSection.empty : classes.verticalSection.base}
                    shouldApplyNestStyles={false}
                  />
                </QuestionGroupListSettingsContext.Provider>
              ) : (
                <DropzonePlaceholder type="learningItems" sectionRef={sectionRef} sectionWidth={sectionWidth} />
              )}
            </Box>
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
      </Box>
      <QuestionFooter
        questionId={id}
        hintFragment={hintFragment}
        questionScoreMode={questionScoreMode}
        scoreWeight={showGeneralGrade ? scoreWeight : 0}
        showAnswerButton={showAnswerButton}
        showCheckButton={showCheckOrResetButton}
        showHintButton={showHintButton}
        showResetButton={showCheckOrResetButton}
        showSeparator={false}
      />
    </div>
  );
}
