import { clientModules } from '@hendrx/modules';
import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import * as Y from 'yjs';

import { QuestionScoreMode } from 'shared/types/QuestionScoreMode';

import { useStyles } from './QuestionFooter.styles';
import { QuestionFooterFeedback } from './QuestionFooterFeedback';
import { RegenerateUsingMethodology } from './RegenerateUsingMethodology';
import {
  useCanSeeFeedback,
  useCanSeeQuestionCheckButton,
  useCanSeeQuestionHintButton,
  useCanSeeQuestionResetButton,
  useCanSeeQuestionScoreWeight,
  useCanSeeQuestionShowAnswerButton
} from '@/components/CanProvider';
import { StyledButton } from '@/components/StyledButton/StyledButton';
import { FeedbackMessageType, useRandomizedFeedback } from '@/utils/feedback.utils';
import { QuestionGrade } from '@/widgets/_components/QuestionGrade';
import { ScoreWeight } from '@/widgets/_components/ScoreWeight';
import { HintModal } from '@/widgets/_components/questions/HintModal';
import { QuestionActionButton } from '@/widgets/_components/questions/QuestionActionButton';

type QuestionFooterProps = {
  questionId: string;
  disabled?: boolean;
  feedbackMessageType?: FeedbackMessageType | null;
  hintFragment?: Y.XmlFragment;
  isHintEditable?: boolean;
  onCheck?: () => void;
  onReset?: () => void;
  onShowAnswer?: () => void;
  questionScoreMode?: QuestionScoreMode;
  scoreWeight?: number;
  parentScoreWeight?: number;
  showAnswerButton?: boolean;
  showCheckButton?: boolean;
  showHintButton?: boolean;
  showResetButton?: boolean;
  grade?: number;
  requiresManualGrade?: boolean;
  showSeparator?: boolean;
};

export function QuestionFooter(props: QuestionFooterProps) {
  const {
    disabled,
    feedbackMessageType = null,
    hintFragment,
    isHintEditable = true,
    onCheck = () => console.log('check'),
    onReset = () => console.log('reset'),
    onShowAnswer = () => console.log('show answer'),
    questionScoreMode,
    scoreWeight,
    parentScoreWeight,
    showAnswerButton = true,
    showCheckButton = true,
    showHintButton,
    showResetButton = true,
    grade,
    requiresManualGrade = false,
    questionId,
    showSeparator = true
  } = props;

  const randomizedFeedback = useRandomizedFeedback(feedbackMessageType);
  const showRandomizedFeedback = feedbackMessageType && randomizedFeedback;

  const { classes, cx } = useStyles({ dense: !showSeparator });
  const t = useTranslations('widgets');
  const canSeeFeedback = useCanSeeFeedback();

  const [isHintModalOpened, { open: openHintModal, close: closeHintModal }] = useDisclosure(false);

  const showButtonsGroup =
    showCheckButton || showResetButton || showHintButton || showAnswerButton || showRandomizedFeedback;

  const canSeeQuestionCheckButton = useCanSeeQuestionCheckButton();
  const canSeeQuestionHintButton = useCanSeeQuestionHintButton();
  const canSeeQuestionResetButton = useCanSeeQuestionResetButton();
  const canSeeQuestionShowAnswerButton = useCanSeeQuestionShowAnswerButton();
  const canSeeQuestionScoreWeight = useCanSeeQuestionScoreWeight();

  return (
    <>
      <Group justify="space-between" className={classes.container} data-testid="question-footer">
        {showButtonsGroup && (
          <>
            <Group
              align="start"
              gap={64}
              className={cx({ [classes.feedbackWrapper]: canSeeFeedback && showRandomizedFeedback })}
            >
              <Group gap={32}>
                {canSeeQuestionCheckButton && !feedbackMessageType && showCheckButton && (
                  <StyledButton
                    className={classes.checkButton}
                    dataTestId="widget-check-button"
                    disabled={disabled}
                    onClick={onCheck}
                  >
                    {t('questionFooter.check')}
                  </StyledButton>
                )}
                {canSeeFeedback && showRandomizedFeedback && (
                  <QuestionFooterFeedback
                    randomizedFeedback={randomizedFeedback}
                    feedbackMessageType={feedbackMessageType}
                  />
                )}
              </Group>
            </Group>
            <Group align="end" gap={64}>
              <Group gap={16}>
                <RegenerateUsingMethodology />
                {canSeeQuestionResetButton && showResetButton && (
                  <QuestionActionButton
                    disabled={disabled}
                    icon={<clientModules.ui.assets.questionFooterIcons.Reset />}
                    label={t('questionFooter.reset')}
                    onClick={onReset}
                    dataTestId="widget-reset-button"
                  />
                )}
                {canSeeQuestionHintButton && showHintButton && (
                  <QuestionActionButton
                    disabled={disabled}
                    icon={<clientModules.ui.assets.questionFooterIcons.Hint />}
                    label={t('questionFooter.hint')}
                    onClick={openHintModal}
                    dataTestId="widget-hint-button"
                  />
                )}
                {canSeeQuestionShowAnswerButton && showAnswerButton && (
                  <QuestionActionButton
                    disabled={disabled}
                    icon={<clientModules.ui.assets.questionFooterIcons.Answer />}
                    label={t('questionFooter.answer')}
                    onClick={onShowAnswer}
                    dataTestId="widget-answer-button"
                  />
                )}
              </Group>
            </Group>
          </>
        )}
      </Group>
      {canSeeQuestionScoreWeight && questionScoreMode === QuestionScoreMode.WithScore && (
        <ScoreWeight scoreWeight={scoreWeight} parentScoreWeight={parentScoreWeight} />
      )}

      <QuestionGrade
        questionId={questionId}
        grade={grade}
        requiresManualGrade={requiresManualGrade}
        scoreWeight={scoreWeight}
      />

      {showSeparator && <hr className={classes.separator} />}

      {showHintButton && (
        <HintModal
          isHintEditable={isHintEditable}
          opened={isHintModalOpened}
          hintFragment={hintFragment}
          close={closeHintModal}
        />
      )}
    </>
  );
}
