import { Box } from '@mantine/core';
import cx from 'clsx';
import { useEffect, useMemo } from 'react';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { ClozeQuestionType } from 'shared/widgetsSDK/ClozeQuestion/create';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';
import { getContentObjectsMap, getSensitiveDataMap } from 'shared/widgetsSDK/yjs';

import { TableClozeCellRenderer } from './ClozeTable';
import { TiptapClozeOptionsProvider } from './hooks/useTiptapClozeOptionsStore';
import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { Hoverable } from '@/components/Hoverable';
import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import {
  getQuestionBulletWrapperData,
  shouldShowCheckButton,
  shouldShowResetButton,
  shouldShowScoreWeight
} from '@/utils/questions.utils';
import { ValidationHandler, useValidation } from '@/utils/validation';
import { Schema } from '@/widgets/ClozeQuestion/Schema';
import { SensitiveDataSchema } from '@/widgets/ClozeQuestion/SensitiveDataSchema';
import { Config } from '@/widgets/ClozeQuestion/config';
import { TableClozeConfig } from '@/widgets/ClozeQuestion/config/TableClozeConfig';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useAlignFeedbackWithParent } from '@/widgets/QuestionGroupList/hooks/useAlignFeedbackWithParent';
import { TableProvider } from '@/widgets/Table/hooks/useTable';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';
import { useIsQuestionEmpty } from '@/widgets/hooks/useIsQuestionEmpty';
import { StudioProps } from '@/widgets/types/studioProps';

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

const getValidator: (id: string) => ValidationHandler = id => document => {
  const contentObjectsMap = getContentObjectsMap(document);
  const sensitiveDataMap = getSensitiveDataMap(document);

  const contentObject = contentObjectsMap.get(id);
  const sensitiveData = sensitiveDataMap.get(id);

  const dataValidationResult = Schema.safeParse(contentObject?.toJSON());
  const dataErrors = dataValidationResult.success ? {} : dataValidationResult.error.format();

  const sensitiveDataValidationResult = SensitiveDataSchema.safeParse(sensitiveData?.toJSON());
  const sensitiveDataErrors = sensitiveDataValidationResult.success ? {} : sensitiveDataValidationResult.error.format();

  return { ...dataErrors, ...sensitiveDataErrors };
};

export function ClozeQuestion(props: StudioProps) {
  return (
    <TiptapClozeOptionsProvider>
      <ClozeQuestionImplementation {...props} />
    </TiptapClozeOptionsProvider>
  );
}

function ClozeQuestionImplementation(props: StudioProps) {
  const { initialize } = props;

  const [flexSectionId] = useContentObjectProperty<string>('children.0');
  const isEmpty = useIsQuestionEmpty();

  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const answerFragment = useContentObjectStaticProperty<Y.XmlFragment>('answerFragment');
  const clozeQuestionType = useContentObjectStaticProperty<ClozeQuestionType>('clozeQuestionType');
  const [feedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const [scoreWeight] = useContentObjectScoreWeight();
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();

  console.log('answerFragment', answerFragment?.toJSON());

  const isRequired = isQuestionRequired(id, document);

  const questionGroupSettings = useParentQuestionGroupSettings();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(feedback, questionGroupSettings);
  const showResetButton = shouldShowResetButton(questionGroupSettings);
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      isResizableVertically: false
    });
  }, [initialize]);

  const validator = useMemo(() => getValidator(id), [id]);
  useValidation(id, document, validator, [id]);

  useAlignFeedbackWithParent();

  return (
    <div className={customClassNames}>
      <SideMenuPortal>
        {clozeQuestionType === ClozeQuestionType.Table ? (
          <TableProvider TableCellRenderer={TableClozeCellRenderer}>
            <TableClozeConfig />
          </TableProvider>
        ) : (
          <Config />
        )}
      </SideMenuPortal>
      <Box className={cx(questionClasses.label, ThemeClassNames.widgets.clozeQuestion.label)}>
        {labelFragment && (
          <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
            <Selectable>
              <Hoverable>
                <QuestionTitle
                  questionId={id}
                  fragment={labelFragment}
                  dataTestIdPrefix="studio-cloze-question-title"
                />
              </Hoverable>
            </Selectable>
          </ListBulletWrapper>
        )}
      </Box>
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            {isEmpty && <DropzonePlaceholder type="contentItems" />}
            <FlexSectionStudio selectable />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
        <QuestionFooter
          questionId={id}
          hintFragment={hintFragment}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton}
          showResetButton={showResetButton}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
