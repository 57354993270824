import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

export function useQuestionNumberingSetting() {
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);

  return useObservedProperty<boolean>(rootMap, 'withAutomaticQuestionNumbering');
}
