import * as Y from 'yjs';

import { manifestLocales } from './manifest.locales';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';

export function createNewSubmitPageSatisfactionSurvey(
  options: Omit<InitializePropsOptions, 'isPlenaryLesson'>
): ManifestInitializedProps {
  const { locale } = options;
  const { howWasActivity } = manifestLocales.submitPageSatisfactionSurvey;
  const id = genRandId();
  const fragment = htmlToXmlFragment(howWasActivity[locale]);
  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.SubmitPageSatisfactionSurvey],
        ['children', new Y.Array()],
        ['fragment', fragment]
      ])
    }
  ];
}
