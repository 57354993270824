import { Text } from '@mantine/core';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type ClozeOptionValueInputProps = { clozeId: string; className: string };

export function ClozeOptionValueInput(props: ClozeOptionValueInputProps) {
  const { clozeId, className } = props;

  const [textToShow] = useContentObjectProperty<string>(`inputCorrectOptions.${clozeId}.0.value`, 'sensitiveData');

  return <Text className={className}>{textToShow}</Text>;
}
