import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    modalInner: {
      paddingTop: rem(60)
    },
    modalContent: {
      overflow: 'visible'
    },
    modalHeader: {
      zIndex: 1
    },
    closeButton: {
      margin: 0
    },
    content: {
      minHeight: '40vh'
    }
  };
});
