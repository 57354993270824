import { Tabs } from '@mantine/core';
import * as Y from 'yjs';

import { ClozeOptionType } from 'shared/types/ClozeQuestion';

import { ClozeAccordionItem } from './ClozeAccordionItem';
import { Accordion } from '@/components/Accordion';
import { useTiptapClozeOptionsStore } from '@/widgets/ClozeQuestion/studio/hooks/useTiptapClozeOptionsStore';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  const [clozeOptions] = useContentObjectProperty<Y.Map<Y.Map<unknown>>>('clozeOptions');

  const selectedClozeId = useTiptapClozeOptionsStore(state => state.selectedClozeId);
  const setSelectedClozeId = useTiptapClozeOptionsStore(state => state.setSelectedClozeId);

  const hasClozes = clozeOptions && clozeOptions?.size > 0;

  return (
    <WidgetConfig tabs={['appearance', 'behaviour']}>
      <Tabs.Panel key="appearance" value="appearance">
        {hasClozes && (
          <Accordion value={selectedClozeId} onChange={clozeId => setSelectedClozeId(clozeId)}>
            {Array.from(clozeOptions?.keys()).map((clozeId, index) => {
              const type = clozeOptions.get(clozeId)?.get('type') as ClozeOptionType;
              return <ClozeAccordionItem clozeId={clozeId} index={index} type={type} key={clozeId} />;
            })}
          </Accordion>
        )}
        <CustomCSSClassesInput />
      </Tabs.Panel>
      <Tabs.Panel key="behaviour" value="behaviour">
        <EvaluationSettings />
        <Feedback />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
