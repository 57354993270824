import without from 'lodash/without';
import { useCallback } from 'react';

import { validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const useDeleteAnswer = () => {
  const { document, data } = useContentObject();
  const [correctAnswers, setCorrectAnswers] = useContentObjectProperty<string[]>('correctAnswers', 'sensitiveData');

  return useCallback(
    (answerIndex: number) => {
      document.transact(() => {
        if (!data) {
          throw new Error('Content object data is not present.');
        }
        const answersData = validateYArray(data.get('answers'));
        const answerId = validateYMap(answersData.get(answerIndex)).get('id');
        const newCorrectAnswers = without(correctAnswers, answerId) as string[];
        setCorrectAnswers(newCorrectAnswers);
        answersData.delete(answerIndex);
      });
    },
    [document, data, correctAnswers, setCorrectAnswers]
  );
};
