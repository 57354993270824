import * as Y from 'yjs';

import { ActivityScoreWeight } from '../types/ActivityScoreWeight';

export function initializeYjsDocumentStructure(document: Y.Doc) {
  const rootMap = document.getMap('root');
  rootMap.get('title') || rootMap.set('title', '');

  rootMap.get('activityThemeStyles') || rootMap.set('activityThemeStyles', {});

  rootMap.get('withAutomaticQuestionNumbering') ?? rootMap.set('withAutomaticQuestionNumbering', true);

  if (!rootMap.has('customCSS')) {
    rootMap.set('customCSS', new Y.Text());
  }
  rootMap.get('widgetsCustomCSSClasses') || rootMap.set('widgetsCustomCSSClasses', new Y.Map());

  rootMap.get('activityScoreWeight') || rootMap.set('activityScoreWeight', ActivityScoreWeight.automatic);

  rootMap.get('widgetsFormData') || rootMap.set('widgetsFormData', new Y.Map());
  rootMap.get('sensitiveData') || rootMap.set('sensitiveData', new Y.Map());

  rootMap.get('contentObjects') || rootMap.set('contentObjects', new Y.Map());

  rootMap.get('collaborativeState') || rootMap.set('collaborativeState', new Y.Map());
}
