import { useTranslations } from 'next-intl';
import { memo } from 'react';
import * as Y from 'yjs';

import { ClozeText } from './ClozeText';
import { TableImplementation } from '@/widgets/Table/base/studio';
import { TableProvider, useTable } from '@/widgets/Table/hooks/useTable';
import { getCellInfoFromContentId } from '@/widgets/Table/utils';

export function ClozeTable() {
  return (
    <TableProvider TableCellRenderer={TableClozeCellRenderer}>
      <TableImplementation />
    </TableProvider>
  );
}

type TableCellRendererProps = {
  contentId: string;
};

export const TableClozeCellRenderer = memo(function TableClozeCellRenderer(props: TableCellRendererProps) {
  const { contentId } = props;

  const content = useTable(context => context.content);
  const rowOrder = useTable(context => context.rowOrder);
  const columnOrder = useTable(context => context.columnOrder);

  const [columnId, rowId] = getCellInfoFromContentId(contentId);

  const t = useTranslations('widgets.tableWidget');

  const rowIndex = rowOrder.indexOf(rowId);
  const columnIndex = columnOrder.indexOf(columnId);
  const fragment = content?.get(contentId) as Y.XmlFragment;

  const isHeader = rowIndex === -1;
  return (
    <ClozeText
      fragment={fragment}
      placeholder={
        isHeader
          ? t('header', { columnIndex: columnIndex + 1 })
          : t('cell', { columnIndex: columnIndex + 1, rowIndex: rowIndex + 1 })
      }
    />
  );
});
