import { z } from 'zod';

const defaultOptions = z.object({
  feedbackType: z.enum(['automatic', 'check', 'none']),
  correctAnswers: z.array(z.string())
});

const feedbackNoneOptions = z.object({
  feedbackType: z.enum(['none']),
  correctAnswers: z.array(z.string())
});

const feedbackOtherOptions = z.object({
  feedbackType: z.enum(['automatic', 'check']),
  correctAnswers: z.array(z.string()).nonempty()
});

const schemaCond = z.discriminatedUnion('feedbackType', [feedbackNoneOptions, feedbackOtherOptions]);

export const SensitiveDataSchema = z.intersection(schemaCond, defaultOptions);
