import * as Y from 'yjs';

import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';
import { EmbedConfig } from '../questionConfigs';

export function createNewEmbedData(
  options: InitializePropsOptions,
  initializationConfig?: EmbedConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { src = '', title = '' } = initializationConfig ?? {};

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.Embed],
        ['children', new Y.Array()],
        ['title', title],
        ['src', src]
      ])
    }
  ];
}
