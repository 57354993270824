import * as Y from 'yjs';

import { QuestionDataType } from './evaluateQuestionByType';

export const extractDataMaps = (document: Y.Doc) => {
  const root = document.getMap('root');
  const contentObjectsMap = root.get('contentObjects') as Y.Map<Y.Map<unknown>>;
  const sensitiveDataMap = root.get('sensitiveData') as Y.Map<unknown>;
  const widgetsFormDataMap = root.get('widgetsFormData') as Y.Map<unknown>;

  return { contentObjectsMap, sensitiveDataMap, widgetsFormDataMap };
};

export const getQuestionData = (
  questionId: string,
  contentObjectsMap: Y.Map<Y.Map<unknown>>,
  sensitiveDataMap: Y.Map<unknown>,
  widgetsFormDataMap: Y.Map<unknown>
): QuestionDataType => {
  const type = contentObjectsMap.get(questionId)?.get('type') as string;
  const contentObjectData = contentObjectsMap?.get(questionId) as Y.Map<unknown>;
  const sensitiveData = sensitiveDataMap?.get(questionId) as Y.Map<unknown>;
  const widgetFormData = widgetsFormDataMap?.get(questionId) as Y.Map<unknown>;

  return { type, contentObjectData, sensitiveData, widgetFormData };
};
