import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { ConfigTypes } from './ConfigTypes';

export const useStyles = createStyles((theme, props: { tabs: ConfigTypes[] }) => {
  const { tabs } = props;
  return {
    tabsList: {
      justifyContent: 'space-evenly',
      flexWrap: 'nowrap',
      gap: 'normal'
    },
    tabLabel: {
      color: theme.colors.systemDark
    },
    tab: {
      justifyContent: tabs?.length > 1 ? 'center' : 'start',
      cursor: tabs?.length > 1 ? 'pointer' : 'default',
      fontSize: rem('16px'),
      width: '100%',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&::after': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        content: '""',
        width: '100%',
        height: rem(2),
        backgroundColor: theme.colors.accent[1]
      },
      '&[data-active]': {
        position: 'relative',
        backgroundColor: theme.white,
        '& > span': {
          color: theme.colors.accent[6]
        },
        '&::after': {
          backgroundColor: theme.colors.accent[6]
        }
      }
    },
    tabsPanels: {
      padding: `${rem(12)} 0`
    }
  };
});
