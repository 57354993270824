import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { GAP_WIDTH, INDICATOR_WIDTH } from '@/widgets/_components/dnd/dnd.utils';

type Props = {
  isDragging: boolean;
  isOver: boolean;
  canDrop: boolean;
  amountOfColumns: number;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { isDragging, isOver, canDrop, amountOfColumns } = props;

  const isSingleColumnLayout = amountOfColumns === 1;

  return {
    dndGap: {
      display: 'grid',
      placeItems: 'center',
      position: 'relative',
      height: isSingleColumnLayout ? rem(GAP_WIDTH) : '100%',
      width: isSingleColumnLayout ? '100%' : rem(GAP_WIDTH),
      marginBlock: isSingleColumnLayout ? rem(-8) : undefined
    },
    dndIndicator: {
      transform: 'scaleY(120%)',
      backgroundColor: isOver && canDrop ? theme.colors.blue[5] : '',
      height: isSingleColumnLayout ? rem(INDICATOR_WIDTH) : '100%',
      width: isSingleColumnLayout ? '100%' : rem(INDICATOR_WIDTH)
    },
    dndIndicatorDropzone: {
      position: 'absolute',
      inset: 0,
      zIndex: isDragging ? 1000 : -1
    }
  };
});
