import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewPdfDocumentData } from 'shared/widgetsSDK/PdfDocument/create';

import { manifestLocales } from './manifest.locales';
import PdfDocumentEn from './resources/en/pdf-document.svg';
import PdfDocumentHe from './resources/he/pdf-document.svg';
import { PdfObject } from '@/icons/PdfObject';

export const Manifest: ManifestType = {
  name: WidgetType.PdfDocument,
  previewGroups: [
    {
      id: 'pdfDocument',
      divider: {
        icon: PdfObject,
        title: 'pdfDocument'
      },
      previews: [
        {
          name: 'pdfDocumentPreview',
          previewImage: {
            paths: {
              en: PdfDocumentEn,
              he: PdfDocumentHe
            },
            size: {
              width: 280,
              height: 140
            },
            alt: { ...manifestLocales.pdfDocument.previewImageAlt }
          },
          dimensions: {
            width: 1180,
            height: 500
          },
          initializeProps: () => createNewPdfDocumentData()
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 450,
      minWidth: 800
    },
    maxFiles: 1,
    maxFileSizeBytes: 10_000_000
  }
};
