import * as Y from 'yjs';

import { ManifestInitializedProps, InitializePropsOptions } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';

export function createNewAvatarWidgetData(
  options: InitializePropsOptions,
  initializationConfig?: {
    prompt?: string;
  }
): ManifestInitializedProps {
  const id = genRandId();

  const { prompt = '' } = initializationConfig ?? {};

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.AvatarWidget],
        ['children', new Y.Array()],
        ['prompt', prompt]
      ])
    }
  ];
}
