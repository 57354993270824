import { ExcelPlaceholder } from '@/icons/ExcelPlaceholder';
import { PowerPointPlaceholder } from '@/icons/PowerPointPlaceholder';
import { WordPlaceholder } from '@/icons/WordPlaceholder';

export const microsoftDocumentIcons = {
  Word: WordPlaceholder,
  Excel: ExcelPlaceholder,
  PowerPoint: PowerPointPlaceholder
};
export const microsoftDocumentPlaceholderIcons = {
  Word: WordPlaceholder,
  Excel: ExcelPlaceholder,
  PowerPoint: PowerPointPlaceholder
};
