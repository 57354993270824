import * as Y from 'yjs';

import { addNewItemToFlexSection } from './addNewItemToFlexSection';
import { createContentObject } from './contentObjectOperations';
import { getTargetSectionData } from './getTargetSectionData';
import { ManifestPreviews } from '../types/ManifestType';
import { SupportedLocale } from '../types/SupportedLocale';

export function addNewItemToSectionFlex({
  document,
  contentObjectData,
  select,
  contentLanguage,
  isPlenaryLesson,
  itemHeight,
  initializeProps,
  position,
  span,
  sectionWidth
}: {
  document: Y.Doc;
  contentObjectData: Y.Map<unknown> | undefined;
  select?: (id: string) => void;
  contentLanguage: SupportedLocale;
  isPlenaryLesson: boolean;
  itemHeight: number | 'auto';
  initializeProps: ManifestPreviews['initializeProps'];
  position: number;
  span: number;
  sectionWidth: number;
}) {
  const sectionData = getTargetSectionData(document, contentObjectData);
  const initializedData = initializeProps({ isPlenaryLesson, locale: contentLanguage, width: sectionWidth });
  const [{ id: newItemId }] = initializedData;
  document.transact(() => {
    initializedData.forEach(({ id, contentObjectData, sensitiveData }) => {
      createContentObject(document, id, contentObjectData, sensitiveData);
    });

    if (sectionData) {
      addNewItemToFlexSection(sectionData, position, { id: newItemId, height: itemHeight, span }, sectionWidth);
    }
  });

  select?.(newItemId);
}
