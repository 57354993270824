import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSubmitPageReturnToFixSwitch } from 'shared/widgetsSDK/SubmitPageReturnToFixSwitch/create';

import { manifestLocales } from './manifest.locales';
import SubmitPageReturnToFixSwitchEn from './resources/en/submitPageReturnToFixSwitch.svg';
import SubmitPageReturnToFixSwitchHe from './resources/he/submitPageReturnToFixSwitch.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SubmitPageReturnToFixSwitch,
  previewGroups: [
    {
      id: 'SubmitPageReturnToFixSwitch',
      divider: false,
      previews: [
        {
          name: 'SubmitPageReturnToFixSwitch',
          previewImage: {
            paths: {
              en: SubmitPageReturnToFixSwitchEn,
              he: SubmitPageReturnToFixSwitchHe
            },
            alt: { ...manifestLocales.submitPageReturnToFixSwitch.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: () => createNewSubmitPageReturnToFixSwitch(),
          dimensions: {
            width: 1180,
            height: 40
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 40,
      minWidth: 100
    }
  }
};
