import { createStyles } from '@mantine/emotion';

type SatisfactionSurveyOptionStyleProps = { disabled?: boolean };

export const useStyles = createStyles((theme, props: SatisfactionSurveyOptionStyleProps) => {
  const { disabled } = props;

  return {
    optionWrapper: {
      position: 'relative',
      ...(disabled && { cursor: 'not-allowed' })
    },
    option: {
      position: 'absolute',
      top: '15%',
      left: '22%'
    },
    selectionVisible: {
      visibility: 'visible'
    },
    selectionHidden: {
      visibility: 'hidden'
    }
  };
});
