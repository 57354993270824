import { createStyles } from '@mantine/emotion';

import { CSS_VARS } from '@/widgets/Table/utils';

type DndStyleProps = {
  isDragging: boolean;
  isOverTop?: boolean;
  isOverBottom?: boolean;
  isOverStart?: boolean;
  isOverEnd?: boolean;
};

const getDndHoverStyles = (props: DndStyleProps) => {
  const { isOverTop, isOverBottom, isOverStart, isOverEnd } = props;
  const selectionColor = CSS_VARS.SelectionColor;
  switch (true) {
    case isOverTop:
      return {
        borderTop: `4px solid ${selectionColor}`
      };
    case isOverBottom:
      return {
        borderBottom: `4px solid ${selectionColor}`
      };
    case isOverStart:
      return {
        borderRight: `4px solid ${selectionColor}`
      };
    case isOverEnd:
      return {
        borderLeft: `4px solid ${selectionColor}`
      };
    default:
      return {};
  }
};

export const useStyles = createStyles((theme, props: DndStyleProps) => {
  const { isDragging } = props;

  return {
    dndWrapper: {
      width: '100%',
      height: '100%',
      position: 'relative',
      '::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        zIndex: 1000,
        pointerEvents: 'none',
        ...getDndHoverStyles(props)
      }
    },
    dndContainer: {
      height: '100%',
      position: 'relative',
      '&:last-of-type': {
        flexGrow: 1
      }
    },
    dndPreview: {
      transformOrigin: 'left top',
      opacity: isDragging ? 0.7 : 0,
      pointerEvents: isDragging ? 'none' : 'auto',
      zIndex: 1000
    }
  };
});
