import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    container: {
      width: '100%',
      height: `100vh`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
  };
});
