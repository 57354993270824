import { Box } from '@mantine/core';
import cx from 'clsx';
import { useMemo } from 'react';
import * as Y from 'yjs';

import { ChartType } from 'shared/types/ChartType';
import { WidgetType } from 'shared/utils/widgets';

import { useCanRevealPlenaryLessonAnswers } from '@/components/CanProvider';
import { Chart } from '@/components/Chart';
import { RevealAnswers } from '@/components/RevealAnswers';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { yXmlFragmentToText } from '@/utils/tiptap';
import { useCollaborativeQuestionAnswers } from '@/utils/useCollaborativeQuestionAnswers';
import { useCollaborativeQuestionState } from '@/utils/useCollaborativeQuestionState';
import { useWorkMode } from '@/utils/useWorkMode';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

const getChartData = (
  answers: Y.Map<unknown>[],
  hasText: boolean,
  hasImage: boolean,
  answerCounts: Record<string, number>
) => {
  return answers.map((answer, index) => {
    const id = answer.get('id') as string;
    const textMap = answer.get('text') as Y.Map<unknown>;
    const label = yXmlFragmentToText(textMap.get('fragment') as Y.XmlFragment);
    const imageMap = answer.get('image') as Y.Map<unknown>;
    const img = imageMap?.get('src') as string;

    return {
      key: id,
      value: answerCounts[index] ?? 0,
      ...(hasText && { label }),
      ...(hasImage && { img })
    };
  });
};

export const InteractivePollTeacher = () => {
  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const [flexSectionId] = useContentObjectProperty<string>('children.0');
  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const { hasImage, hasText } = useAnswerElements();
  const [chartType] = useContentObjectProperty<ChartType>('answersVisualization');

  const { state, toggleRevealAnswers } = useCollaborativeQuestionState(id);
  const { isRevealed } = state;

  const { isPlayer } = useWorkMode();

  const canRevealPlenaryLessonAnswers = useCanRevealPlenaryLessonAnswers();

  const questionAnswers = useCollaborativeQuestionAnswers(id);

  const { chartData, answersCount } = useMemo(() => {
    const answerCounts = Object.values(questionAnswers)
      .flat()
      .reduce<Record<string, number>>((results, answer) => ({ ...results, [answer]: (results[answer] ?? 0) + 1 }), {});
    const chartData = getChartData(answers, hasText, hasImage, answerCounts);

    return { chartData, answersCount: Object.values(questionAnswers).length };
  }, [questionAnswers, answers, hasImage, hasText]);

  return (
    <>
      <div className={customClassNames}>
        {labelFragment && (
          <Box className={cx(questionClasses.label, ThemeClassNames.widgets.interactivePoll.label)}>
            <RichText fragment={labelFragment} dataTestIdPrefix="preview-interactive-poll-title" editable={false} />
          </Box>
        )}
        {isRevealed ? (
          <Box h={566}>
            <Chart chartType={chartType} data={chartData} variant="standard" />
          </Box>
        ) : (
          <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
            <FlexSectionPlayer />
          </ContentObjectProvider>
        )}
      </div>
      {isPlayer && canRevealPlenaryLessonAnswers && (
        <RevealAnswers answersCount={answersCount} isOn={isRevealed} onToggle={toggleRevealAnswers} />
      )}
    </>
  );
};
