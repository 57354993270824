import { ElementData, OpenQuestionElementType } from 'shared/types/OpenQuestion';

import { ElementFile } from '@/icons/ElementFile';
import { ElementImage } from '@/icons/ElementImage';
import { ElementRecording } from '@/icons/ElementRecording';
import { ElementText } from '@/icons/ElementText';

export const elementsOptions: Record<OpenQuestionElementType, ElementData> = {
  text: {
    Icon: ElementText
  },
  recording: {
    Icon: ElementRecording
  },
  image: {
    Icon: ElementImage
  },
  file: {
    Icon: ElementFile
  }
};
