import { clientModules } from '@hendrx/modules';
import { Box, Flex, Paper, Text, Title } from '@mantine/core';
import Lottie from 'lottie-react';
import { useTranslations } from 'next-intl';
import { FC, useMemo } from 'react';

import * as classes from './PlenaryLessonAnswerFeedback.css';
import confetti from '@/icons/lotties/confetti.json';
import loadingDots from '@/icons/lotties/loading-dots.json';

export type PlenaryAnswerStatus = 'waiting' | 'success' | 'partialSuccess' | 'failure' | 'noAnswer';

function getFeedbackIcons(): Record<PlenaryAnswerStatus, FC> {
  return {
    failure: clientModules.ui.assets.interactiveQuizFeedbackIcons.AnswerFailure,
    noAnswer: clientModules.ui.assets.interactiveQuizFeedbackIcons.NoAnswer,
    partialSuccess: clientModules.ui.assets.interactiveQuizFeedbackIcons.AnswerPartialSuccess,
    success: clientModules.ui.assets.interactiveQuizFeedbackIcons.AnswerSuccess,
    waiting: () => <Lottie animationData={loadingDots} />
  };
}

type PlenaryLessonAnswerFeedbackProps = { status: PlenaryAnswerStatus };

export const PlenaryLessonAnswerFeedback = (props: PlenaryLessonAnswerFeedbackProps) => {
  const { status } = props;
  const t = useTranslations('components.plenaryLessonAnswerFeedback');

  const FeedbackIcon = getFeedbackIcons()[status];

  const titles: Record<PlenaryAnswerStatus, string> = useMemo(
    () => ({
      success: t('titleSuccess'),
      waiting: t('titleWaiting'),
      failure: t('titleFailure'),
      noAnswer: t('titleNoAnswer'),
      partialSuccess: t('titlePartialSuccess')
    }),
    [t]
  );

  const subtitles: Record<PlenaryAnswerStatus, string> = useMemo(
    () => ({
      success: t('subtitleSuccess'),
      waiting: t('subtitleWaiting'),
      failure: t('subtitleFailure'),
      noAnswer: t('subtitleNoAnswer'),
      partialSuccess: t('subtitlePartialSuccess')
    }),
    [t]
  );

  const isWaiting = status === 'waiting';
  const isSuccess = status === 'success';

  return (
    <Paper className={classes.paper}>
      <Flex direction="column" align="center" gap={isWaiting ? 48 : 32}>
        <Title order={3} c="systemDark.8" ta="center" size={isWaiting ? 32 : 36} fw={500}>
          {titles[status]}
        </Title>
        <Box pos="relative">
          {isSuccess && <Lottie className={classes.confetti} animationData={confetti} loop={1} />}
          <FeedbackIcon />
        </Box>
        <Text ta="center" c="gray.7" fz={isWaiting ? 18 : 24} fw={400}>
          {subtitles[status]}
        </Text>
      </Flex>
    </Paper>
  );
};
