import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';
import { customNodes } from '@/utils/tiptap/customNodes';

export type FontSizeOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size
       */
      setFontSize: (fontSize: string) => ReturnType;
      /**
       * Unset the font size
       */
      unsetFontSize: () => ReturnType;
    };
  }
}

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions() {
    return {
      types: ['textStyle']
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}`
              };
            }
          }
        }
      }
    ];
  },

  addCommands() {
    return {
      setFontSize:
        fontSize =>
        ({ chain, tr }) => {
          const from = tr.selection.ranges[0].$from.pos;
          const to = tr.selection.ranges[0].$to.pos;
          tr.doc.descendants((node, pos) => {
            if (customNodes.includes(node.type.name) && pos >= from && pos <= to) {
              tr.setNodeAttribute(pos, 'fontSize', fontSize);
            }
          });
          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain, tr }) => {
          const from = tr.selection.ranges[0].$from.pos;
          const to = tr.selection.ranges[0].$to.pos;
          tr.doc.descendants((node, pos) => {
            if (customNodes.includes(node.type.name) && pos >= from && pos <= to) {
              tr.setNodeAttribute(pos, 'fontSize', null);
            }
          });
          return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
        }
    };
  }
});
