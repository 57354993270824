import { Box } from '@mantine/core';
import cx from 'clsx';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useEvaluatablePlayerEvents } from '@/contentObjects/Plugin/hooks/useEvaluatablePlayerEvents';
import { useEvaluatablePluginQuestionAnswers } from '@/contentObjects/Plugin/hooks/useEvaluatablePluginQuestionAnswers';
import { PluginScriptContext } from '@/contentObjects/Plugin/utils/pluginScriptContext';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import {
  getProperFeeback,
  getQuestionBulletWrapperData,
  shouldShowCheckButton,
  shouldShowScoreWeight
} from '@/utils/questions.utils';
import { useViewMode } from '@/utils/useViewMode';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import { useParentQuestionGroupEvaluation, useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';

type PlayerQuestionStageProps = {
  id: string;
  script: string;
};

export function PlayerQuestionStage(props: PlayerQuestionStageProps) {
  const { id, script } = props;

  const [viewMode] = useViewMode();

  const { document: yDoc } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [ownFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();

  const [scoreWeight] = useContentObjectScoreWeight();

  const [flexSectionId] = useContentObjectProperty<string>('children.0');

  const isRequired = isQuestionRequired(id, yDoc);

  const questionGroupSettings = useParentQuestionGroupSettings();
  const questionGroupEvaluation = useParentQuestionGroupEvaluation();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(ownFeedback, questionGroupSettings);
  const feedback = getProperFeeback(ownFeedback, questionGroupSettings?.feedback);

  const {
    feedbackMessageType,
    grade,
    handleEvaluate,
    handleResetAnswers,
    handleShowAnswer,
    handleAnswersStateChange,
    handleAnswersCheckedChange,
    handleAnswersDataChange
  } = useEvaluatablePluginQuestionAnswers(id, viewMode, feedback, scoreWeight);

  useEvaluatablePlayerEvents(
    id,
    viewMode,
    handleAnswersStateChange,
    handleAnswersCheckedChange,
    handleAnswersDataChange
  );

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, yDoc);

  return (
    <div className={customClassNames}>
      {labelFragment && (
        <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
          <Box className={cx(questionClasses.label, ThemeClassNames.widgets.evaluatablePlugin.label)}>
            <QuestionTitle questionId={id} fragment={labelFragment} dataTestIdPrefix="preview-plugin-question-title" />
          </Box>
        </ListBulletWrapper>
      )}
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <PluginScriptContext.Provider value={script}>
          <ContentObjectProvider id={flexSectionId} document={yDoc} type={WidgetType.FlexSection}>
            <FlexSectionPlayer />
          </ContentObjectProvider>
        </PluginScriptContext.Provider>
        <QuestionFooter
          questionId={id}
          feedbackMessageType={feedbackMessageType}
          hintFragment={hintFragment}
          isHintEditable={false}
          onCheck={handleEvaluate}
          onReset={handleResetAnswers}
          onShowAnswer={handleShowAnswer}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton && !feedbackMessageType}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton && !feedbackMessageType}
          grade={grade || questionGroupEvaluation?.[id]?.grade}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
