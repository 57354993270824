import * as Y from 'yjs';

import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';
import { createNewFlexSectionData } from '../FlexSection/create';
import { createNewTextData } from '../Text/create';

export function createNewOpenDiscussionData(content: string): ManifestInitializedProps {
  const id = genRandId();

  const [textData] = createNewTextData({ locale: 'en' }, { content, textType: 'header', withParagraphOption: false });

  const [horizontalSectionData] = createNewFlexSectionData(FlexSectionDirection.row, [
    { id: textData.id, width: 800, height: 'auto', isDeletable: false, isDraggable: false }
  ]);
  const { id: horizontalSectionId } = horizontalSectionData;
  const childrenArray = new Y.Array();
  childrenArray.push([horizontalSectionId]);

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.OpenDiscussion],
        ['children', childrenArray],
        ['enabledPictureUpload', false],
        ['enabledMultiplePosts', false]
      ])
    },
    horizontalSectionData,
    textData
  ];
}
