import { Flex, Paper, Portal, Switch, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';

import * as classes from './RevealAnswers.css';
import { useStageContext } from '@/components/Stage/hooks/useStageContext';
import { Comment } from '@/icons/Comment';
import { useFullscreenContext } from '@/utils/useFullscreenContext';

type RevealAnswersProps = {
  answersCount: number;
  onToggle: () => void;
  isOn?: boolean;
};

function RevealAnswersContent(props: RevealAnswersProps) {
  const { answersCount, isOn, onToggle } = props;
  const t = useTranslations('components.revealAnswers');

  return (
    <Paper className={classes.paper} radius="md">
      <Flex align="center" gap={16}>
        <Text className={classes.answersCount}>{answersCount}</Text>
        <Comment />
        <Text className={classes.title}>{t('revealAnswers')}</Text>
        <Switch checked={isOn} onChange={onToggle} />
      </Flex>
    </Paper>
  );
}

export const RevealAnswers = (props: RevealAnswersProps) => {
  const isStageAsThumbnail = useStageContext(context => context.isStageAsThumbnail);
  const { isFullscreen } = useFullscreenContext();

  if (isStageAsThumbnail) {
    return null;
  }

  return (
    <>
      <Portal target="#plenary-reveal-answers-portal">
        <RevealAnswersContent {...props} />
      </Portal>

      {isFullscreen && (
        <Portal target="#plenary-reveal-answers-portal-fullscreen">
          <RevealAnswersContent {...props} />
        </Portal>
      )}
    </>
  );
};
