import { rem } from '@mantine/core';
import { Cell } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CELL_RENDERER_CLASSNAME, CLASSNAMES, CSS_VARS, TRow } from '@/widgets/Table/utils';

type TableCellProps = {
  cell: Cell<TRow, unknown>;
};

const getContentId = (cell: Cell<TRow, unknown>) => {
  const cellValue = cell.getValue() as string | undefined;
  if (!cellValue) {
    // TODO: add logging
    // console.warn('Cell without value', cellValue, cell);
  }
  return cellValue ?? '';
};

export const TableCell = memo(function TableCell(props: TableCellProps) {
  const { cell } = props;
  const { classes, cx } = useStyles();
  const TableCellRenderer = useTable(context => context.TableCellRenderer);

  return (
    <div
      className={cx(
        classes.td,
        CLASSNAMES.CellColumn(cell.column.id),
        CLASSNAMES.CellRow(cell.row.original.id),
        ThemeClassNames.widgets.table.cell
      )}
      style={{
        width: `calc(var(${CSS_VARS.ColumnSize(cell.column.id)}) * ${rem(1)})`
      }}
    >
      <div className={cx(classes.cellRenderer, CELL_RENDERER_CLASSNAME)}>
        <TableCellRenderer contentId={getContentId(cell)} />
      </div>
    </div>
  );
});
