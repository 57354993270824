export enum ObjectFit {
  Cover = 'cover',
  Fill = 'fill',
  Contain = 'contain',
  None = 'none'
}

export enum GoToType {
  Page = 'page',
  ExternalLink = 'externalLink'
}

export enum LinkTargetType {
  SameWindow = '_self',
  NewTab = '_blank'
}
