import { px, rem, SelectProps } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Select } from '@/components/Select';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { useTextStyles } from '@/utils/tiptap/useTextStyles';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

export type TextFontSizeSelectProps = {
  editor: Editor | null;
};

const textFontSizes: { value: string; label: string }[] = [
  { value: '10', label: '10' },
  { value: '12', label: '12' },
  { value: '14', label: '14' },
  { value: '16', label: '16' },
  { value: '18', label: '18' },
  { value: '20', label: '20' },
  { value: '24', label: '24' },
  { value: '28', label: '28' },
  { value: '32', label: '32' },
  { value: '36', label: '36' },
  { value: '40', label: '40' },
  { value: '48', label: '48' },
  { value: '64', label: '64' },
  { value: '96', label: '96' }
];

export function TextFontSizeSelect(props: TextFontSizeSelectProps) {
  const { editor } = props;
  const { classes } = useStyles({ maxWidth: 56 });
  const t = useTranslations('widgets.fontSize');
  const { getTextStyles } = useTextStyles(editor);
  const [activeFontSize, setActiveFontSize] = useState(`${px(getTextStyles('fontSize'))}`);

  useEffect(() => {
    const setFontSize = () => {
      const activeFontSize = px(getTextStyles('fontSize'));
      setActiveFontSize(`${activeFontSize}`);
    };

    editor?.on('selectionUpdate', setFontSize);
    editor?.on('update', setFontSize);

    return () => {
      editor?.off('selectionUpdate', setFontSize);
      editor?.off('update', setFontSize);
    };
  }, [editor, getTextStyles]);

  const handleFontSizeChange = (fontSize: string) => {
    editor?.commands.setFontSize(rem(fontSize));
  };

  return (
    <Select
      title={t('title') ?? ''}
      contentEditable={true}
      placeholder={t('placeholder') ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: classes.selectInput,
        section: classes.selectIcon,
        dropdown: classes.selectDropdown
      }}
      withCheckIcon={false}
      leftSection={<DropdownArrow />}
      leftSectionWidth={24}
      leftSectionPointerEvents="auto"
      rightSection={<></>}
      rightSectionWidth={'0px'}
      data={textFontSizes}
      value={activeFontSize}
      onChange={handleFontSizeChange as SelectProps['onChange']}
      variant="unstyled"
    />
  );
}
