import { Box } from '@mantine/core';
import { forwardRef } from 'react';

import { useStyles } from './ClozeOption.styles';
import { ClozeOptionValueInput } from './ClozeOptionValueInput';
import { ClozeOptionValueSelect } from './ClozeOptionValueSelect';
import { useTiptapClozeOptionsStore } from '@/widgets/ClozeQuestion/studio/hooks/useTiptapClozeOptionsStore';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';

type ClozeOptionValueProps = {
  clozeId: string;
  fontFamily: string;
  fontSize: string;
  type: ClozeOptionType;
};

export const ClozeOptionValue = forwardRef<HTMLDivElement, ClozeOptionValueProps>(function ClozeOptionValue(
  props,
  ref
) {
  const { clozeId, fontFamily, fontSize, type } = props;

  const selectedClozeId = useTiptapClozeOptionsStore(state => state.selectedClozeId);
  const setSelectedClozeId = useTiptapClozeOptionsStore(state => state.setSelectedClozeId);

  const { classes } = useStyles({
    fontFamily,
    fontSize,
    isSelected: selectedClozeId === clozeId
  });

  return (
    <Box
      ref={ref}
      contentEditable={false}
      className={classes.content}
      onClick={() => setSelectedClozeId(clozeId)}
      data-testid="cloze-question-option-value"
    >
      {type === ClozeOptionType.Input && <ClozeOptionValueInput clozeId={clozeId} className={classes.option} />}
      {type === ClozeOptionType.Select && <ClozeOptionValueSelect clozeId={clozeId} className={classes.option} />}
    </Box>
  );
});
