import { useTranslations } from 'next-intl';

import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { FontFamilySelectPanel } from '@/components/Theme/FontFamilySelectPanel';
import { FontSizeSelectPanel } from '@/components/Theme/FontSizeSelectPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function TextSettings() {
  const { document, id } = useContentObject();
  const t = useTranslations('components.theme.settings');
  const [themeFontFamily] = useActivityThemeStylesProperty<string | null>(document, 'table.fontFamily');
  const [globalFontFamily] = useActivityThemeStylesProperty<string>(document, 'global.content.fontFamily');
  const [fontFamily, setFontFamily] = useWidgetStyle<string | null>(document, id, 'fontFamily');
  const [themeColor] = useActivityThemeStylesProperty<string | null>(document, 'table.color');
  const [globalColor] = useActivityThemeStylesProperty<string>(document, 'global.content.colors.primary.contrast');
  const [color, setColor] = useWidgetStyle<string | null>(document, id, 'color');
  const [themeFontSize] = useActivityThemeStylesProperty<number>(document, 'table.fontSize');
  const [fontSize, setFontSize] = useWidgetStyle<number | null>(document, id, 'fontSize');

  return (
    <ThemeItemSection label={t('textSettings')}>
      <FontFamilySelectPanel
        label={t('family')}
        value={fontFamily ?? themeFontFamily ?? globalFontFamily}
        onChange={setFontFamily}
        ResetButton={fontFamily && <ResetButton onClick={() => setFontFamily(null)} />}
      />
      <FontSizeSelectPanel
        label={t('size')}
        value={fontSize ?? themeFontSize}
        onChange={setFontSize}
        ResetButton={fontSize && <ResetButton onClick={() => setFontSize(null)} />}
      />
      <ColorInputPanel
        label={t('color')}
        color={color ?? themeColor ?? globalColor}
        setColor={setColor}
        ResetButton={color && <ResetButton onClick={() => setColor(null)} />}
      />
    </ThemeItemSection>
  );
}
