import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core';
import { ReactElement } from 'react';

import { useStyles } from './StyledActionIcon.styles';
import { ThemeClassNames } from '@/consts/ThemeClassNames';

type StyledActionIconProps = {
  label?: string;
  icon: ReactElement;
  onClick?: () => void;
} & ActionIconProps;

export function StyledActionIcon(props: StyledActionIconProps) {
  const { label, icon, className, radius = 'xl', size = 'xl', variant = 'subtle', ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Tooltip label={label}>
      <ActionIcon
        className={cx(classes.actionIcon, className, ThemeClassNames.action_icon)}
        size={size}
        radius={radius}
        variant={variant}
        {...rest}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  );
}
