import { ButtonProps, Button } from '@mantine/core';
import { ReactNode } from 'react';

import { useStyles } from './StyledButton.styles';
import { ThemeClassNames } from '@/consts/ThemeClassNames';

type StyledButtonProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  onClick: () => void;
  radius?: ButtonProps['radius'];
  size?: ButtonProps['size'];
};

export function StyledButton(props: StyledButtonProps) {
  const { children, disabled, dataTestId, onClick, className, radius = 'md', size = 'md' } = props;

  const { cx, classes } = useStyles();

  return (
    <Button
      className={cx(classes.button, className, ThemeClassNames.button)}
      disabled={disabled}
      onClick={onClick}
      radius={radius}
      size={size}
      data-testid={`${dataTestId}`}
    >
      {children}
    </Button>
  );
}
