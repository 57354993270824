import { ClientModulesCreators } from '@hendrx/modules';

type CloudinarySecureFileUploadInfo = {
  apiKey: string;
  baseUrl: string;
  signature: string;
  timestamp: string;
  upload_preset?: string;
};

const createClientFileStorageModule: ClientModulesCreators['fileStorage'] = () => {
  return {
    uploadFile: async (file, onCallGetSecureUploadInfoEndpoint, fileName) => {
      const secureInfo = (await onCallGetSecureUploadInfoEndpoint({})) as CloudinarySecureFileUploadInfo;

      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('api_key', secureInfo.apiKey);
      formData.append('timestamp', secureInfo.timestamp);
      formData.append('signature', secureInfo.signature);

      if (secureInfo.upload_preset) {
        formData.append('upload_preset', secureInfo.upload_preset);
      }

      const response = await fetch(secureInfo.baseUrl, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Uploading a file failed (${response.status}): ${response.statusText}`);
      }

      const data = await response.json();
      return data.secure_url;
    }
  };
};

export { createClientFileStorageModule };
