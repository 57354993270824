export const getListByCategory = async (category: string) => {
  const response = await fetch(`/api/plugin/list/${category}`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}. Could not fetch plugins.`, {
      cause: response
    });
  }

  return await response.json();
};

export const getPluginById = async (id: string) => {
  const response = await fetch(`/api/plugin/${id}`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}. Could not fetch plugin.`, {
      cause: response
    });
  }

  return await response.json();
};
