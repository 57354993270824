import min from 'lodash/min';
import { useEffect, useMemo, Dispatch, SetStateAction, MutableRefObject, RefObject } from 'react';

import { ManifestAdditionalData } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { roundHeight, getItemSpan } from 'shared/widgetsSDK/Stage.utils';

import { Direction, ResizableDimensions, isHeightControlled } from '@/components/Resizable/types';
import { calculateConstraints } from '@/components/Resizable/utils/Resizable.utils';
import { resizeHandler } from '@/components/Resizable/utils/resize-handler.utils';
import { getDirectionMultiplier } from '@/utils/locale.utils';
import { Direction as LocaleDirection } from '@/utils/locale.utils';

type UseResizeHandlerProps = {
  isResizingCurrentItem: boolean;
  originalWidth: number;
  dimensionsRef: MutableRefObject<{ width: number; height: number | 'auto' }>;
  coordsRef: MutableRefObject<{ x: number; y: number }>;
  setOriginalWidth: Dispatch<SetStateAction<number>>;
  onResizeEnd: (
    newSpan: number,
    newHeight: number | 'auto',
    shouldResizeItemInStartDirection: boolean,
    newWidth: number
  ) => void;
  constraints: ManifestAdditionalData['constraints'];
  stageScale: number;
  contentDirection: LocaleDirection;
  isResizableVertically: boolean;
  noGrid: boolean;
  setDimensions: Dispatch<SetStateAction<ResizableDimensions>>;
  setIsConstraint: Dispatch<SetStateAction<boolean>>;
  resizingDirection?: Direction;
  setMoveStartDir: Dispatch<SetStateAction<number>>;
  sectionRef: RefObject<HTMLDivElement>;
  sectionType: WidgetType;
  resizableRef: RefObject<HTMLDivElement>;
  setResizingItemId?: Dispatch<SetStateAction<string | undefined>>;
  containerWidth?: number;
};

export function useResizeHandler(props: UseResizeHandlerProps) {
  const {
    constraints,
    coordsRef,
    dimensionsRef,
    isResizableVertically,
    isResizingCurrentItem,
    onResizeEnd,
    originalWidth,
    noGrid,
    setDimensions,
    setIsConstraint,
    setMoveStartDir,
    setOriginalWidth,
    stageScale,
    contentDirection,
    resizingDirection,
    resizableRef,
    sectionRef,
    sectionType,
    setResizingItemId,
    containerWidth
  } = props;

  const { minWidth, minHeight } = useMemo(() => calculateConstraints(constraints), [constraints]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isResizingCurrentItem) {
        const scaledMouseX =
          ((e.clientX - coordsRef.current.x) / stageScale) * getDirectionMultiplier(contentDirection);
        const scaledMouseY = (e.clientY - coordsRef.current.y) / stageScale;

        const handler = resizeHandler(resizingDirection);
        if (!handler) {
          console.error(`Invalid resizing direction: ${resizingDirection}`);
          return;
        }

        const newDimensions = handler({
          minWidth: constraints?.minWidth,
          dimensionsRef,
          scaledMouseX,
          scaledMouseY,
          originalWidth,
          isResizableVertically,
          setMoveStartDir,
          stageScale,
          sectionRef,
          resizableRef,
          contentDirection,
          sectionType,
          coordsRef
        });
        const width = newDimensions?.width ?? dimensionsRef.current.width;
        const height = newDimensions?.height ?? dimensionsRef.current.height;

        setDimensions({
          width: min([containerWidth, width]) || 0,
          height
        });

        dimensionsRef.current.width = width;
        dimensionsRef.current.height = height;

        setIsConstraint((!noGrid && width < minWidth) || (typeof height === 'number' && height < minHeight));

        coordsRef.current.x = e.clientX;
        coordsRef.current.y = e.clientY;
      }
    };

    const handleMouseUp = () => {
      if (isResizingCurrentItem && setResizingItemId) {
        setResizingItemId(undefined);

        let newHeight = isHeightControlled(isResizableVertically, dimensionsRef.current)
          ? roundHeight(dimensionsRef.current.height)
          : ('auto' as const);
        let newSpan = getItemSpan(dimensionsRef.current.width);

        if (typeof newHeight === 'number' && newHeight < minHeight) {
          newHeight = roundHeight(minHeight);
        }
        if (newSpan < getItemSpan(minWidth)) {
          newSpan = getItemSpan(minWidth);
        }

        const shouldResizeItemInStartDirection = !!resizingDirection?.includes('start');

        setIsConstraint(false);
        setOriginalWidth(0);
        setMoveStartDir(0);
        onResizeEnd(newSpan, newHeight, shouldResizeItemInStartDirection, dimensionsRef.current.width);
      }
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [
    contentDirection,
    dimensionsRef,
    originalWidth,
    resizingDirection,
    stageScale,
    constraints?.minHeight,
    constraints?.minWidth,
    isResizableVertically,
    isResizingCurrentItem,
    setMoveStartDir,
    setDimensions,
    setIsConstraint,
    minWidth,
    minHeight,
    noGrid,
    setResizingItemId,
    setOriginalWidth,
    onResizeEnd,
    coordsRef,
    resizableRef,
    sectionRef,
    sectionType,
    containerWidth
  ]);
}
