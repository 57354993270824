import { useCallback, useMemo } from 'react';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';

import { InteractiveQuizStudent } from './InteractiveQuizStudent';
import { InteractiveQuizTeacher } from './InteractiveQuizTeacher';
import { useUser } from '@/queries/user';
import { useContentObjectArray } from '@/widgets/_components/ContentObjectProvider';
import {
  EvaluatableQuestionAnswersContext,
  useEvaluatableQuestionAnswers
} from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';

const defaultState: string[] = [];

export const InteractiveQuizPlayer = () => {
  const { user } = useUser();

  const isStudent = user?.role === 'plenaryStudent' || user?.role === 'student';

  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const allAnswerOptions = useMemo(() => answers.map(answer => answer.get('id') as string), [answers]);

  const getCorrectAnswers = useCallback(
    (result?: { correctAnswers: string[] }): string[] =>
      result?.correctAnswers?.map(correctAnswerId => allAnswerOptions.indexOf(correctAnswerId).toString()) ?? [],
    [allAnswerOptions]
  );

  const getCorrectStatuses = useCallback((correctAnswersOptions: ReturnType<typeof getCorrectAnswers>) => {
    return (
      correctAnswersOptions?.reduce(
        (statuses, answer) => ({
          [answer]: 'correct',
          ...statuses
        }),
        {}
      ) ?? {}
    );
  }, []);

  const evaluatableQuestionAnswers = useEvaluatableQuestionAnswers<WidgetType.InteractiveQuiz>({
    getCorrectAnswers,
    defaultState,
    getCorrectStatuses,
    questionGroupAction: null,
    questionGroupEvaluation: null
  });

  return (
    <EvaluatableQuestionAnswersContext.Provider value={evaluatableQuestionAnswers}>
      {isStudent ? <InteractiveQuizStudent /> : <InteractiveQuizTeacher />}
    </EvaluatableQuestionAnswersContext.Provider>
  );
};
