import { RefObject, useEffect, useRef, useState } from 'react';
import { useDrop, XYCoord } from 'react-dnd';

import { DraggableType } from 'shared/types/DraggableType';

import { useDndAllowedItemsContext } from '@/components/DndAllowContext';
import { useMoveItemBetweenSections } from '@/contentObjects/Page/sectionsOperations/useMoveItemBetweenSections';
import { clearDraggedItem } from '@/utils/draggedItem';
import { Direction } from '@/utils/locale.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useAddNewItemToSection } from '@/widgets/FlexSection/hooks/useAddNewItemToSection';
import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

const useLeave = (callback: () => void, isOver: boolean) => {
  const ref = useRef(isOver);

  useEffect(() => {
    if (ref.current && !isOver) {
      callback();
    }

    ref.current = isOver;
  }, [isOver, callback]);
};

export function useOnDropAction(
  dropRef: RefObject<HTMLDivElement>,
  sectionWidth: number,
  direction: FlexSectionDirection
) {
  const { id } = useContentObject();
  const allowedItems = useDndAllowedItemsContext(store => store.allowedItems);

  const { contentDirection } = useContentLanguage();

  const moveItemBetweenSections = useMoveItemBetweenSections();
  const addNewItemToSection = useAddNewItemToSection();

  const [indicatorPosition, setIndicatorPosition] = useState<number | null>(null);

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: allowedItems,
      drop(item: DraggableType) {
        if (item.isNew) {
          addNewItemToSection(item.height, item.initializeProps, indicatorPosition ?? 0, item.span, sectionWidth);
        } else {
          moveItemBetweenSections(item.id, item.parentId, id, indicatorPosition ?? 0);
        }
      },
      canDrop(item, monitor) {
        const hasEnoughSpace = true;

        clearDraggedItem();
        setIndicatorPosition(null);

        return monitor.isOver({ shallow: true }) && hasEnoughSpace;
      },
      collect(monitor) {
        return { isOver: monitor.isOver({ shallow: true }) };
      },
      hover(item: DraggableType, monitor) {
        const offset = monitor.getClientOffset();
        if (!offset || !dropRef.current) {
          return;
        }

        const isOver = monitor.isOver({ shallow: true });
        if (!isOver) {
          setIndicatorPosition(null);
          return;
        }

        const items = getDraggableChildren(dropRef.current);
        const itemsWithCoordinates = getItemsWithCoordinates(items, offset, contentDirection);
        const itemsByDelta = itemsWithCoordinates
          .sort((a, b) => a.absDeltaX - b.absDeltaX)
          .sort((a, b) => a.absDeltaY - b.absDeltaY);
        const closestItem = itemsByDelta[0];

        if (!closestItem) {
          setIndicatorPosition(0);
          return;
        }

        if (direction === FlexSectionDirection.row) {
          const isNextItem = contentDirection === 'rtl' ? closestItem.deltaX < 0 : closestItem.deltaX > 0;
          const newIndex = isNextItem ? closestItem.index + 1 : closestItem.index;
          setIndicatorPosition(newIndex);
        }
        if (direction === FlexSectionDirection.column) {
          const newIndex = closestItem.deltaY > 0 ? closestItem.index + 1 : closestItem.index;
          setIndicatorPosition(newIndex);
        }
      }
    }),
    [id, indicatorPosition, addNewItemToSection, moveItemBetweenSections, sectionWidth]
  );

  useLeave(() => {
    setIndicatorPosition(null);
  }, collectedProps.isOver);

  return { indicatorPosition, drop, isOver: collectedProps.isOver } as const;
}

const getDraggableChildren = (element: HTMLElement) => {
  return Array.from(element.children).filter(node => {
    const dataTestId = node.getAttribute('data-testid');
    return dataTestId?.includes('stage-item');
  });
};

const getItemsWithCoordinates = (elements: Element[], offset: XYCoord, contentDirection: Direction) => {
  return elements.map((element, index) => {
    const { x, y, width, height } = element.getBoundingClientRect();

    const deltaX = offset.x - (x + width / 2);
    const deltaY = offset.y - (y + height / 2);
    return {
      index,
      absDeltaX: Math.abs(deltaX),
      absDeltaY: Math.abs(deltaY),
      deltaX,
      deltaY
    };
  });
};
