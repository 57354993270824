import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { useStyles } from './Base.styles';
import { VideoPlaceholder } from '@/icons/VideoPlaceholder';
import { useFileUpload } from '@/queries/fileUpload';
import { Manifest } from '@/widgets/Video/Manifest';
import { UploadDropzone } from '@/widgets/_components/widget/UploadDropzone';

type BaseProps = {
  title: string;
  src: string;
  setSrc: (src: string) => void;
};
export const Base = forwardRef<() => void | null, BaseProps>((props, ref) => {
  const { title, src, setSrc } = props;

  const t = useTranslations('widgets');

  const { mutateAsync: uploadFile, isLoading } = useFileUpload();

  const isVideoReady = Boolean(src && !isLoading);

  const { classes } = useStyles();
  return (
    <>
      {isVideoReady && <video src={src} title={title} controls className={classes.video}></video>}
      <UploadDropzone
        Icon={VideoPlaceholder}
        src={src}
        isReady={isVideoReady}
        maxSize={Manifest.additionalData?.maxFileSizeBytes}
        maxFiles={Manifest.additionalData?.maxFiles}
        accept={Manifest.additionalData?.acceptFileType}
        placeholderText={t('videoWidget.idleText')}
        upload={uploadFile}
        dropAction={({ src }) => {
          setSrc(src);
        }}
        dropError={t('videoWidget.unableUpload')}
        ref={ref}
      />
    </>
  );
});

Base.displayName = 'Base';
