import { Box } from '@mantine/core';
import cx from 'clsx';
import { useEffect, useMemo } from 'react';
import * as Y from 'yjs';

import { useTextTools } from 'shared/richTextToolbar/useTextTools';
import { AnswerElement } from 'shared/types/AnswerElement';
import { ToolbarType } from 'shared/types/RichTextToolbar';
import { WidgetType } from 'shared/utils/widgets';
import { getContentObjectsMap, getSensitiveDataMap, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { Hoverable } from '@/components/Hoverable';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { ValidationHandler, useValidation } from '@/utils/validation';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { Manifest } from '@/widgets/InteractiveQuiz/Manifest';
import { Schema } from '@/widgets/InteractiveQuiz/Schema';
import { SensitiveDataSchema } from '@/widgets/InteractiveQuiz/SensitiveDataSchema';
import { Config } from '@/widgets/InteractiveQuiz/config';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { StudioProps } from '@/widgets/types/studioProps';

const getValidator: (selectionQuestionId: string) => ValidationHandler = selectionQuestionId => document => {
  const contentObjectsMap = getContentObjectsMap(document);

  const sensitiveDataMap = getSensitiveDataMap(document);

  const question = contentObjectsMap.get(selectionQuestionId);

  if (!question) {
    throw new Error(`Interactive Quiz ${selectionQuestionId} not found in contentObjects`);
  }

  const answers = validateYArray(question.get('answers')).toJSON();
  const answerElements = question.get('answerElements') as AnswerElement[];

  const dataValidationResult = Schema.safeParse({ answers, answerElements });
  const dataErrors = dataValidationResult.success ? {} : dataValidationResult.error.format();

  const questionSensitiveData = validateYMap(sensitiveDataMap.get(selectionQuestionId));
  const correctAnswers = questionSensitiveData.get('correctAnswers') as string[];

  const sensitiveDataValidationResult = SensitiveDataSchema.safeParse({ correctAnswers });
  const sensitiveDataErrors = sensitiveDataValidationResult.success ? {} : sensitiveDataValidationResult.error.format();

  return { ...dataErrors, ...sensitiveDataErrors };
};

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

export const InteractiveQuizStudio = (props: StudioProps) => {
  const { initialize } = props;

  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const [flexSectionId] = useContentObjectProperty<string>('children.0');

  useEffect(() => {
    initialize?.({
      actions: ['Delete'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  const [tools] = useTextTools({ textType: 'header' });
  const validator = useMemo(() => getValidator(id), [id]);
  useValidation(id, document, validator, [id]);

  return (
    <div className={customClassNames}>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>

      {labelFragment && (
        <Selectable>
          <Hoverable>
            <Box className={cx(questionClasses.label, ThemeClassNames.widgets.interactivePoll.label)}>
              <RichText
                tools={tools}
                fragment={labelFragment}
                dataTestIdPrefix="studio-interactive-quiz-title"
                toolbarType={ToolbarType.Fixed}
              />
            </Box>
          </Hoverable>
        </Selectable>
      )}
      <Box p={8}>
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            <FlexSectionStudio selectable />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
      </Box>
    </div>
  );
};
