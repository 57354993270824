export const manifestLocales = {
  multiChoice: {
    previewMultiChoiceAlt: {
      en: 'multiChoice1 widget',
      he: 'שאלת רב ברירה'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    }
  }
};
