import { ReactElement, cloneElement, memo } from 'react';

import { WidgetData } from '@/types/Stage';

type Props = {
  initializeWidget: (data: WidgetData) => void;
  children: ReactElement;
};

export const WidgetProvider = memo(function WidgetProvider(props: Props) {
  const { children, initializeWidget: initialize } = props;
  return cloneElement(children, { initialize });
});
