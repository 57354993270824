import { rem } from '@mantine/core';
import { Header } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { TableColumnResizer } from './TableColumnResizer';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CLASSNAMES, CSS_VARS, TRow } from '@/widgets/Table/utils';

type TableHeaderCellProps = {
  header: Header<TRow, unknown>;
};

export const TableHeaderCell = memo(function TableHeaderCell(props: TableHeaderCellProps) {
  const { header } = props;

  const { classes, cx } = useStyles();

  const TableCellRenderer = useTable(context => context.TableCellRenderer);

  return (
    <div
      className={cx(classes.th, CLASSNAMES.CellColumn(header.id), ThemeClassNames.widgets.table.headerCell)}
      style={{
        width: `calc(var(${CSS_VARS.ColumnSize(header.id)}) * ${rem(1)})`
      }}
    >
      <TableCellRenderer contentId={header.id} />
      <TableColumnResizer getResizeHandler={header.getResizeHandler} />
    </div>
  );
});
