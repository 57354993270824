import { QuestionGroupsType } from 'shared/types/QuestionGroups';
import { WidgetType } from 'shared/utils/widgets';
import {
  getEvaluatableSubQuestionsFromSavedState,
  getQuestionGroups,
  isSingleEvaluatableQuestion
} from 'shared/widgetsSDK/questionGroup';

import { usePlayerStateStore } from '@/components/PlayerStateProvider';
import { useQuestions } from '@/components/ScoreWeightSummary/hooks/useQuestions';
import { useCurrentDocument } from '@/components/YjsProvider';
import { QuestionStates } from '@/queries/state';
import { isQuestionAnswered } from '@/utils/questions.utils';

export const useUnansweredQuestionsCount = () => {
  const document = useCurrentDocument();
  const questions = useQuestions('all');

  const elementsState = usePlayerStateStore(state => state.elementsState);
  if (!elementsState) return 0;

  const questionGroups = getQuestionGroups(document, elementsState['questionGroups'] as QuestionGroupsType);

  const evaluatableQuestionGroupsInState = getEvaluatableSubQuestionsFromSavedState(questionGroups, document);
  const allUnansweredGroupQuestions = Object.entries(evaluatableQuestionGroupsInState).reduce(
    (acc, [groupId, questionAnswersIds]) => {
      const evaluatableAnswersInGroupCount = questionAnswersIds.filter(answerId =>
        isQuestionAnswered(elementsState[answerId] as QuestionStates)
      ).length;

      const providedAnswersInGroup = Object.values(evaluatableQuestionGroupsInState[groupId]).length;
      const areAllSubQuestionsAnswered = evaluatableAnswersInGroupCount >= providedAnswersInGroup;
      return areAllSubQuestionsAnswered ? acc : acc + 1;
    },
    0
  );

  const singleEvaluableQuestions = Object.values(questions).filter(contentObject => {
    const isSubQuestion = Object.values(questionGroups).flat().includes(contentObject.id);
    return (
      !isSubQuestion &&
      contentObject.type !== WidgetType.QuestionGroupList &&
      isSingleEvaluatableQuestion(contentObject.type)
    );
  });

  const answeredQuestions = singleEvaluableQuestions.reduce((acc, contentObjectQuestion) => {
    const savedStateQuestionAnswers = elementsState[contentObjectQuestion.id] as QuestionStates;
    return isQuestionAnswered(savedStateQuestionAnswers) ? acc + 1 : acc;
  }, 0);

  const singleEvaluableQuestionsCount = singleEvaluableQuestions.length;
  return allUnansweredGroupQuestions + singleEvaluableQuestionsCount - answeredQuestions;
};
