export const manifestLocales = {
  submitPageSatisfactionSurvey: {
    previewImageAlt: {
      en: 'Submit Page Satisfaction Survey',
      he: 'איך הייתה הפעילות?'
    },
    howWasActivity: {
      en: 'How was the activity? Choose the appropriate character',
      he: 'איך הייתה הפעילות? בחרו את הדמות המתאימה'
    }
  }
};
