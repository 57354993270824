import { Group } from '@mantine/core';
import { ReactElement, useCallback } from 'react';

import { useStyles } from './AnswerOptionButton.styles';
import { QuestionBaseButton } from '@/widgets/_components/questions/QuestionBaseButton';

type AnswerOptionButtonProps = {
  disabled?: boolean;
  icon: ReactElement;
  label: string;
  onClick?: () => void;
};

export function AnswerOptionButton(props: AnswerOptionButtonProps) {
  const { disabled, icon, label, onClick } = props;

  const { classes } = useStyles({ disabled });

  const handleOnClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <Group gap="xs" className={classes.group} onClick={handleOnClick}>
      <QuestionBaseButton.Wrapper>
        <QuestionBaseButton className={classes.icon} icon={icon} label={label} disabled={disabled} />
      </QuestionBaseButton.Wrapper>
    </Group>
  );
}
