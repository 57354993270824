import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, props: { withSeparator: boolean }) => {
  const { withSeparator } = props;
  return {
    propContainer: {
      padding: `0 ${rem(12)} ${rem(24)}`,
      borderBottom: withSeparator ? `1px solid ${theme.colors.gray[4]}` : 'none',
      marginBottom: withSeparator ? rem(24) : 0
    }
  };
});
