import { AbilityBuilder, AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { clientModules } from '@hendrx/modules';
import { User } from '@hendrx/modules/authentication';

import { Resource } from './utils';
import { ViewMode } from '@/utils/useViewMode';

type AppAbility = PureAbility<AbilityTuple, MatchConditions>;

type AppContext = {
  hasStudentId: boolean;
  isAssignmentEvaluated: boolean;
  isAssignmentReturnedToFix: boolean;
  isAssignmentSubmitted: boolean;
  isAssignmentUnsubmitted: boolean;
  isLearningActivity: boolean;
  isPlayer: boolean;
  isPlayerActivity: boolean;
  isPlayerAssignment: boolean;
  isPlenaryLesson: boolean;
  isStudioEdit: boolean;
  isStudioPreview: boolean;
  isTest: boolean;
  user: User | null | undefined;
  viewMode: ViewMode | null | undefined;
};

type DefineRule = (
  can: AbilityBuilder<AppAbility>['can'],
  cannot: AbilityBuilder<AppAbility>['cannot'],
  appContext: AppContext
) => void;

export const defineCanRules = (appContext?: AppContext) => {
  const { can, cannot, build } = new AbilityBuilder<AppAbility>(PureAbility);

  if (!appContext) {
    return build();
  }

  defineAssignment(can, cannot, appContext);
  defineViewMode(can, cannot, appContext);
  defineStudentAssignedData(can, cannot, appContext);
  defineStudentLearningMode(can, cannot, appContext);
  defineStudentTestMode(can, cannot, appContext);
  defineTeacherSolvedMode(can, cannot, appContext);
  defineLocalState(can, cannot, appContext);
  defineSavedState(can, cannot, appContext);
  defineCorrectAnswers(can, cannot, appContext);
  defineInitEvaluation(can, cannot, appContext);
  defineFeedback(can, cannot, appContext);
  defineQuestionEvaluation(can, cannot, appContext);
  defineQuestionCheckAnswerButton(can, cannot, appContext);
  defineQuestionHintButton(can, cannot, appContext);
  defineQuestionResetButton(can, cannot, appContext);
  defineQuestionScoreWeight(can, cannot, appContext);
  defineReturnToFixSwitch(can, cannot, appContext);
  defineSatisfactionSurvey(can, cannot, appContext);
  defineUnansweredQuestions(can, cannot, appContext);
  defineQuestionShowAnswerButton(can, cannot, appContext);
  defineQuestionGrade(can, cannot, appContext);
  definePresenterActionBar(can, cannot, appContext);
  defineSlidesNavigation(can, cannot, appContext);
  defineSlideNumber(can, cannot, appContext);
  defineCanvas(can, cannot, appContext);
  definePlenaryLessonAnswers(can, cannot, appContext);
  definePlenaryLessonCode(can, cannot, appContext);
  defineEnlargeOpenDiscussionAnswer(can, cannot, appContext);

  return build();
};

const defineAssignment: DefineRule = (can, cannot, appContext) => {
  const canCreateAssignment = clientModules.authorization.canCreateAssignment(appContext);

  const canSubmitAssignment = clientModules.authorization.canSubmitAssignment(appContext);
  const canSubmitFeedbackForAssignment = clientModules.authorization.canSubmitFeedbackForAssignment(appContext);

  const canReadAssignmentNotes = clientModules.authorization.canReadAssignmentNotes(appContext);

  const canReadDraftNoteToTeacher = clientModules.authorization.canReadDraftNoteToTeacher(appContext);
  const canUpsertDraftNoteToTeacher = clientModules.authorization.canUpsertDraftNoteToTeacher(appContext);

  const canReadDraftFeedbackForAssignment = clientModules.authorization.canReadDraftFeedbackForAssignment(appContext);
  const canUpsertDraftFeedbackForAssignment =
    clientModules.authorization.canUpsertDraftFeedbackForAssignment(appContext);

  const canReadSatisfactionScore = clientModules.authorization.canReadSatisfactionScore(appContext);
  const canUpsertSatisfactionScore = clientModules.authorization.canUpsertSatisfactionScore(appContext);

  const canReadSubmissionHistory = clientModules.authorization.canReadSubmissionHistory(appContext);

  const canAccessAssignmentSummaryPage = clientModules.authorization.canAccessAssignmentSummaryPage(appContext);
  const canAccessAssignmentSubmitPage = clientModules.authorization.canAccessAssignmentSubmitPage(appContext);

  const canReadQuestionStatus = clientModules.authorization.canReadQuestionStatus(appContext);

  if (canCreateAssignment) {
    can('create', Resource.Assignment);
  }

  if (canSubmitAssignment) {
    can('submit', Resource.Assignment);
  }

  if (canSubmitFeedbackForAssignment) {
    can('submitFeedbackFor', Resource.Assignment);
  }

  if (canReadAssignmentNotes) {
    can('read', Resource.AssignmentNotes);
  }

  if (canReadDraftNoteToTeacher) {
    can('read', Resource.AssignmentDraftNoteToTeacher);
  }

  if (canUpsertDraftNoteToTeacher) {
    can('upsert', Resource.AssignmentDraftNoteToTeacher);
  }

  if (canReadSatisfactionScore) {
    can('read', Resource.AssignmentSatisfactionScore);
  }

  if (canUpsertSatisfactionScore) {
    can('upsert', Resource.AssignmentSatisfactionScore);
  }

  if (canReadDraftFeedbackForAssignment) {
    can('read', Resource.AssignmentDraftFeedbackForAssignment);
  }

  if (canUpsertDraftFeedbackForAssignment) {
    can('upsert', Resource.AssignmentDraftFeedbackForAssignment);
  }

  if (canReadSubmissionHistory) {
    can('read', Resource.AssignmentSubmissionHistory);
  }

  if (canAccessAssignmentSummaryPage) {
    can('access', Resource.AssignmentSummaryPage);
  }

  if (canAccessAssignmentSubmitPage) {
    can('access', Resource.AssignmentSubmitPage);
  }

  if (canReadQuestionStatus) {
    can('read', Resource.QuestionStatus);
  }
};

const defineViewMode: DefineRule = (can, cannot, appContext) => {
  const canManageViewMode = clientModules.authorization.canManageViewMode(appContext);

  if (canManageViewMode) {
    can('manage', Resource.ViewMode);
  }
};

const defineStudentAssignedData: DefineRule = (can, cannot, appContext) => {
  const canReadStudentAssignedMode = clientModules.authorization.canReadStudentAssignedMode(appContext);

  if (canReadStudentAssignedMode) {
    can('read', Resource.StudentAssignedMode);
  }
};

const defineStudentLearningMode: DefineRule = (can, cannot, appContext) => {
  const canReadStudentLearningViewMode = clientModules.authorization.canReadStudentLearningViewMode(appContext);

  if (canReadStudentLearningViewMode) {
    can('read', Resource.StudentLearningMode);
  }
};

const defineStudentTestMode: DefineRule = (can, cannot, appContext) => {
  const canReadStudentTestViewMode = clientModules.authorization.canReadStudentTestViewMode(appContext);

  if (canReadStudentTestViewMode) {
    can('read', Resource.StudentTestMode);
  }
};

const defineTeacherSolvedMode: DefineRule = (can, cannot, appContext) => {
  const canReadTeacherSolvedMode = clientModules.authorization.canReadTeacherSolvedMode(appContext);

  if (canReadTeacherSolvedMode) {
    can('read', Resource.TeacherSolvedMode);
  }
};

const defineLocalState: DefineRule = (can, cannot, appContext) => {
  const canUpdateLocalState = clientModules.authorization.canUpdateLocalState(appContext);

  if (canUpdateLocalState) {
    can('update', Resource.LocalState);
  }
};

const defineSavedState: DefineRule = (can, cannot, appContext) => {
  const canReadSavedState = clientModules.authorization.canReadSavedState(appContext);
  const canUpdateSavedState = clientModules.authorization.canUpdateSavedState(appContext);

  if (canReadSavedState) {
    can('read', Resource.SavedState);
  }

  if (canUpdateSavedState) {
    can('update', Resource.SavedState);
  }
};

const defineCorrectAnswers: DefineRule = (can, cannot, appContext) => {
  const canPrefillCorrectAnswers = clientModules.authorization.canPrefillCorrectAnswers(appContext);

  if (canPrefillCorrectAnswers) {
    can('prefill', Resource.CorrectAnswers);
  }
};

const defineInitEvaluation: DefineRule = (can, cannot, appContext) => {
  const canPrefillEvaluation = clientModules.authorization.canPrefillEvaluation(appContext);

  if (canPrefillEvaluation) {
    can('prefill', Resource.QuestionEvaluation);
  }
};

const defineFeedback: DefineRule = (can, cannot, appContext) => {
  const canSeeFeedback = clientModules.authorization.canSeeFeedback(appContext);

  if (canSeeFeedback) {
    can('see', Resource.Feedback);
  }
};

const defineQuestionEvaluation: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionEvaluation = clientModules.authorization.canSeeQuestionEvaluation(appContext);

  if (canSeeQuestionEvaluation) {
    can('see', Resource.QuestionEvaluation);
  }
};

const defineQuestionCheckAnswerButton: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionCheckButton = clientModules.authorization.canSeeQuestionCheckButton(appContext);

  if (canSeeQuestionCheckButton) {
    can('see', Resource.QuestionCheckButton);
  }
};

const defineQuestionHintButton: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionHintButton = clientModules.authorization.canSeeQuestionHintButton(appContext);

  if (canSeeQuestionHintButton) {
    can('see', Resource.QuestionHintButton);
  }
};

const defineQuestionResetButton: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionResetButton = clientModules.authorization.canSeeQuestionResetButton(appContext);

  if (canSeeQuestionResetButton) {
    can('see', Resource.QuestionResetButton);
  }
};

const defineQuestionShowAnswerButton: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionShowAnswerButton = clientModules.authorization.canSeeQuestionShowAnswerButton(appContext);

  if (canSeeQuestionShowAnswerButton) {
    can('see', Resource.QuestionShowAnswerButton);
  }
};

const defineQuestionScoreWeight: DefineRule = (can, cannot, appContext) => {
  const canSeeQuestionScoreWeight = clientModules.authorization.canSeeQuestionScoreWeight(appContext);

  if (canSeeQuestionScoreWeight) {
    can('see', Resource.QuestionScoreWeight);
  }
};

const defineReturnToFixSwitch: DefineRule = (can, cannot, appContext) => {
  const canSeeReturnToFixSwitch = clientModules.authorization.canSeeReturnToFixSwitch(appContext);

  if (canSeeReturnToFixSwitch) {
    can('see', Resource.ReturnToFixSwitch);
  }
};

const defineSatisfactionSurvey: DefineRule = (can, cannot, appContext) => {
  const canSeeSatisfactionSurvey = clientModules.authorization.canSeeSatisfactionSurvey(appContext);

  if (canSeeSatisfactionSurvey) {
    can('see', Resource.SatisfactionSurvey);
  }
};

const defineUnansweredQuestions: DefineRule = (can, cannot, appContext) => {
  const canSeeUnansweredQuestions = clientModules.authorization.canSeeUnansweredQuestions(appContext);

  if (canSeeUnansweredQuestions) {
    can('see', Resource.UnansweredQuestions);
  }
};

const defineQuestionGrade: DefineRule = (can, cannot, appContext) => {
  const canReadQuestionGrade = clientModules.authorization.canReadQuestionGrade(appContext);
  const canUpsertQuestionGrade = clientModules.authorization.canUpsertQuestionGrade(appContext);

  if (canReadQuestionGrade) {
    can('read', Resource.QuestionGrade);
  }

  if (canUpsertQuestionGrade) {
    can('upsert', Resource.QuestionGrade);
  }
};

const definePresenterActionBar: DefineRule = (can, cannot, appContext) => {
  const canSeePresenterActionBar = clientModules.authorization.canSeePresenterActionBar(appContext);

  if (canSeePresenterActionBar) {
    can('see', Resource.PresenterActionBar);
  }
};

const defineSlidesNavigation: DefineRule = (can, cannot, appContext) => {
  const canSeeSlidesNavigation = clientModules.authorization.canSeeSlidesNavigation(appContext);

  if (canSeeSlidesNavigation) {
    can('see', Resource.SlidesNavigation);
  }
};

const defineSlideNumber: DefineRule = (can, cannot, appContext) => {
  const canSyncSlideNumber = clientModules.authorization.canSyncSlideNumber(appContext);

  if (canSyncSlideNumber) {
    can('sync', Resource.SlideNumber);
  }
};

const defineCanvas: DefineRule = (can, cannot, appContext) => {
  const canSeeCanvas = clientModules.authorization.canSeeCanvas(appContext);
  const canSaveCanvas = clientModules.authorization.canSaveCanvas(appContext);

  if (canSeeCanvas) {
    can('see', Resource.Canvas);
  }

  if (canSaveCanvas) {
    can('save', Resource.Canvas);
  }
};

const definePlenaryLessonAnswers: DefineRule = (can, cannot, appContext) => {
  const canRevealAnswers = clientModules.authorization.canRevealAnswers(appContext);

  if (canRevealAnswers) {
    can('reveal', Resource.PlenaryLessonAnswers);
  }
};

const definePlenaryLessonCode: DefineRule = (can, cannot, appContext) => {
  const canSeePlenaryLessonCode = clientModules.authorization.canSeePlenaryLessonCode(appContext);

  if (canSeePlenaryLessonCode) {
    can('see', Resource.PlenaryLessonCode);
  }
};

const defineEnlargeOpenDiscussionAnswer: DefineRule = (can, cannot, appContext) => {
  const canEnlargeOpenDiscussionAnswer = clientModules.authorization.canEnlargeOpenDiscussionAnswer(appContext);

  if (canEnlargeOpenDiscussionAnswer) {
    can('see', Resource.EnlargeOpenDiscussionAnswer);
  }
};
