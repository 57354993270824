import { ActionIcon, ColorInput as MantineColorInput, Flex } from '@mantine/core';
import { useEyeDropper } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { useStyles } from './ColorInput.styles';
import { EyeDropper } from '@/icons/EyeDropper';

type ColorInputProps = {
  onChange: (color: string) => void;
  value: string;
};

export function ColorInput(props: ColorInputProps) {
  const { onChange, value } = props;

  const t = useTranslations('components');
  const { classes } = useStyles();

  const [color, setColor] = useState(value);
  const { open } = useEyeDropper();

  const pickColor = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { sRGBHex } = (await open())!;
      setColor(sRGBHex);
      onChange(sRGBHex);
    } catch (e) {
      console.error(e);
    }
  };

  // initial value might change due to the theme change
  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <Flex gap={4}>
      <MantineColorInput
        className={classes.input}
        format="hexa"
        onBlur={() => onChange(color)}
        onChange={setColor}
        placeholder={t('colorInput.pickColor') ?? ''}
        value={color}
        withEyeDropper={false}
        closeOnColorSwatchClick
        popoverProps={{ withinPortal: false }}
      />
      <ActionIcon variant="default" onClick={pickColor} className={classes.eyeDropper}>
        <EyeDropper />
      </ActionIcon>
    </Flex>
  );
}
