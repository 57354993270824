import { CustomLoadingOverlayProps } from '@hendrx/modules/ui';

import { assets } from '../../assets';
import { LoadingOverlay } from '../LoadingOverlay';

export function CustomLoadingOverlay({ visible = true }: CustomLoadingOverlayProps) {
  return (
    <LoadingOverlay visible={visible}>
      <assets.loader />
    </LoadingOverlay>
  );
}
