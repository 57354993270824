import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from './RequiredQuestionBadge.styles';

type RequiredQuestionBadgeProps = {
  isRequired?: boolean;
};

export function RequiredQuestionBadge(props: RequiredQuestionBadgeProps) {
  const { isRequired } = props;

  const t = useTranslations('widgets');
  const { classes } = useStyles();

  if (!isRequired) return null;

  return <Box className={classes.requiredBadge}>{t('requiredQuestionBadge.required')}</Box>;
}
