import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const MORE_BUTTONS_GAP_PX = 8;

export const useStyles = createStyles(theme => {
  return {
    toolbarPopover: {
      padding: rem(8)
    },
    controlsGroup: {
      alignItems: 'center',
      gap: rem(MORE_BUTTONS_GAP_PX),
      '[data-rich-text-editor-control]': {
        fontSize: rem(42),
        svg: {
          width: '0.5em',
          height: '0.5em'
        },
        margin: 0,
        padding: rem(4)
      }
    }
  };
});
