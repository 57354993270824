import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    popoverTarget: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  };
});
