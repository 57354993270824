import { useCallback } from 'react';

import { removeChild } from 'shared/utils/removeChild';

import { useSelectableStore } from '@/components/Selectable';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useRemoveChild() {
  const { data: sectionData, id: sectionId, document } = useContentObject();
  const deselect = useSelectableStore(state => state.deselect);

  return useCallback(
    (childId: string) => {
      removeChild({ document, sectionData, sectionId, deselect, childId });
    },
    [document, sectionData, deselect, sectionId]
  );
}
