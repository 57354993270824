import { useMantineTheme } from '@mantine/core';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { InteractiveQuizAnswerElement } from './InteractiveQuizAnswerElement';
import { AnswerOption } from '@/components/AnswerOption';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function InteractiveQuizAnswerOption(props: { answerIndex: number }) {
  const { answerIndex } = props;
  const [answerId] = useContentObjectProperty<string>(`answers.${answerIndex}.id`);
  const [correctAnswers] = useContentObjectProperty<string[]>(`correctAnswers`, 'sensitiveData');
  const isChecked = correctAnswers.includes(answerId);
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const theme = useMantineTheme();

  return (
    <AnswerOption
      AnswerElementComponent={InteractiveQuizAnswerElement}
      answerAreaThemeProps={theme.other.collabItems.interactiveQuiz.answerArea}
      answerId={answerId}
      answerIndex={answerIndex}
      answersStyle={answersStyle}
      checkboxThemeProps={theme.other.collabItems.interactiveQuiz.checkbox}
      dataTestId="studio-interactive-quiz"
      isChecked={isChecked}
    />
  );
}
