import { Box, Stack, Tabs } from '@mantine/core';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { sectionStyles } from './Config.css';
import { Accordion } from '@/components/Accordion';
import { BorderRadiusPanel } from '@/components/Theme/BorderRadiusPanel';
import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import * as themeClasses from '@/components/Theme/Theme.css';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';
import { useBlockTitleWidgetStyles } from '@/utils/typographyStyles.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { ConfigSplitBorder } from '@/widgets/Text/config/ConfigSplitBorder';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

type HeadingIdType = 'h1' | 'h2' | 'h3' | 'h4';
const headingsIds: HeadingIdType[] = ['h1', 'h2', 'h3', 'h4'];

type ConfigProps = { children?: ReactNode };

export function Config(props: ConfigProps) {
  const { children } = props;

  const { id } = useContentObject();
  const blockTitleWidgetStyles = useBlockTitleWidgetStyles(id);
  const { contentDirection } = useContentLanguage();

  const setWidgetStyleByKey = (headingId: HeadingIdType, key: string, value: unknown) => {
    const { style, setStyle } = blockTitleWidgetStyles[headingId];
    setStyle({ ...style, [key]: value });
  };

  const document = useCurrentDocument();
  const [activityThemeStyles] = useActivityThemeStyles(document);

  const t = useTranslations('components.theme.settings');

  const globalContrastColor = activityThemeStyles.global.content.colors.primary.contrast;

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Box className={themeClasses.withMarginBottom}>
          <Accordion secondary>
            {headingsIds.map(headingId => {
              const { style: widgetStyle, setStyle: setWidgetStyle } = blockTitleWidgetStyles[headingId];
              const blockTitleStyles = activityThemeStyles.blockTitle[headingId];
              return (
                <Accordion.Item key={headingId} value={headingId}>
                  <Accordion.Control>{t(headingId)}</Accordion.Control>
                  <Accordion.Panel>
                    <Stack className={themeClasses.contentStack}>
                      <ThemeItemSection label={t('backgroundColor')} sectionClassName={sectionStyles}>
                        <ColorInputPanel
                          color={
                            widgetStyle?.backgroundColor ?? blockTitleStyles.backgroundColor ?? globalContrastColor
                          }
                          setColor={newColor => setWidgetStyleByKey(headingId, 'backgroundColor', newColor)}
                          ResetButton={
                            widgetStyle?.backgroundColor && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'backgroundColor', null)} />
                            )
                          }
                        />
                      </ThemeItemSection>
                      <ThemeItemSection label={t('border')} sectionClassName={sectionStyles}>
                        <ConfigSplitBorder
                          activityThemeStyles={activityThemeStyles}
                          typographyId={headingId}
                          widgetTypographyStyle={widgetStyle}
                          setWidgetTypographyStyle={setWidgetStyle}
                        />
                      </ThemeItemSection>
                      <ThemeItemSection sectionClassName={sectionStyles}>
                        <BorderRadiusPanel
                          label={t('borderRadius')}
                          borderRadius={widgetStyle?.borderRadius ?? blockTitleStyles.borderRadius}
                          setBorderRadius={newRadius => setWidgetStyleByKey(headingId, 'borderRadius', newRadius)}
                          ResetButton={
                            widgetStyle?.borderRadius && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'borderRadius', null)} />
                            )
                          }
                        />
                      </ThemeItemSection>
                      <ThemeItemSection label={t('padding')} sectionClassName={sectionStyles}>
                        <NumberInputPanel
                          label={t('top')}
                          value={widgetStyle?.paddingTop ?? blockTitleStyles.paddingTop}
                          onChange={newPadding => setWidgetStyleByKey(headingId, 'paddingTop', newPadding)}
                          ResetButton={
                            !isNil(widgetStyle?.paddingTop) && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'paddingTop', null)} />
                            )
                          }
                        />
                        <NumberInputPanel
                          label={t('bottom')}
                          value={widgetStyle?.paddingBottom ?? blockTitleStyles.paddingBottom}
                          onChange={newPadding => setWidgetStyleByKey(headingId, 'paddingBottom', newPadding)}
                          ResetButton={
                            !isNil(widgetStyle?.paddingBottom) && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'paddingBottom', null)} />
                            )
                          }
                        />
                        <NumberInputPanel
                          label={contentDirection === 'ltr' ? t('left') : t('right')}
                          value={widgetStyle?.paddingLeft ?? blockTitleStyles.paddingLeft}
                          onChange={newPadding => setWidgetStyleByKey(headingId, 'paddingLeft', newPadding)}
                          ResetButton={
                            !isNil(widgetStyle?.paddingLeft) && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'paddingLeft', null)} />
                            )
                          }
                        />
                        <NumberInputPanel
                          label={contentDirection === 'ltr' ? t('right') : t('left')}
                          value={widgetStyle?.paddingRight ?? blockTitleStyles.paddingRight}
                          onChange={newPadding => setWidgetStyleByKey(headingId, 'paddingRight', newPadding)}
                          ResetButton={
                            !isNil(widgetStyle?.paddingRight) && (
                              <ResetButton onClick={() => setWidgetStyleByKey(headingId, 'paddingRight', null)} />
                            )
                          }
                        />
                      </ThemeItemSection>
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Box>
        <Box className={themeClasses.contentStack}>{children}</Box>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
