import { Box } from '@mantine/core';
import { ReactNode } from 'react';
import { useDrag } from 'react-dnd';

import { DraggableType } from 'shared/types/DraggableType';
import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { getContentObjectsMap, useObservedProperty, validateYMap } from 'shared/widgetsSDK/yjs';

import { draggedItem } from '@/utils/draggedItem';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { DragHandle } from '@/widgets/_components/widget/DragHandle';

type DraggableProps = {
  itemType: ItemDragType;
  id: string;
  span: number;
  parentSectionId: string;
  children: ReactNode;
  column: number;
  resizingItemId?: string;
  disabled?: boolean;
};

export function Draggable(props: DraggableProps) {
  const { itemType = ItemDragType.Item, id, span, parentSectionId, children, column, resizingItemId, disabled } = props;
  const isResizing = resizingItemId === id;
  const { document } = useContentObject();
  const contentObjectData = validateYMap(getContentObjectsMap(document).get(id));
  const [childType] = useObservedProperty<WidgetType>(contentObjectData, 'type');
  let orientation: 'center' | 'side' = 'center';
  if (childType === WidgetType.FlexSection && contentObjectData.get('direction') === FlexSectionDirection.column) {
    orientation = 'side';
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: itemType,
      item: () => {
        const item = {
          id,
          parentId: parentSectionId,
          isNew: false,
          span,
          column
        } satisfies DraggableType;
        draggedItem.current = item;
        return item;
      },
      collect(monitor) {
        return { isDragging: monitor.isDragging() };
      }
    }),
    [span, column, itemType]
  );

  return (
    <Box style={{ opacity: isDragging ? '0%' : '100%' }}>
      {children}
      {!disabled && <DragHandle orientation={orientation} strength={6} ref={drag} isResizing={isResizing} />}
    </Box>
  );
}
