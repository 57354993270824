import { Box, Center, Flex } from '@mantine/core';
import cx from 'clsx';
import { useCallback } from 'react';
import * as Y from 'yjs';

import * as classes from './InteractivePollStudent.css';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import { PlenaryLessonAnswerFeedback } from '@/components/PlenaryLessonAnswerFeedback';
import { PlenaryLessonSubmitButton } from '@/components/PlenaryLessonSubmit';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { InteractivePollAnswerPlayerImplementation } from '@/contentObjects/InteractivePollAnswer/InteractivePollAnswerPlayer';
import { useUser } from '@/queries/user';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { isQuestionAnswered } from '@/utils/questions.utils';
import { useCollaborativeQuestionAnswer } from '@/utils/useCollaborativeQuestionAnswer';
import { useContentObject, useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import * as questionClasses from '@/widgets/_components/questions/Question.css';

export const InteractivePollStudent = () => {
  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const { user } = useUser();

  const [selectedAnswers = []] = usePlayerElementState<string[]>(id);

  const [collaborativeAnswer, setCollaborativeAnswer] = useCollaborativeQuestionAnswer(
    id,
    `${user?.id ?? user?.firstName}`
  );
  const isSubmitted = collaborativeAnswer && isQuestionAnswered({ answers: collaborativeAnswer });

  const onSubmit = useCallback(() => {
    setCollaborativeAnswer(selectedAnswers);
  }, [setCollaborativeAnswer, selectedAnswers]);

  return isSubmitted ? (
    <Center>
      <PlenaryLessonAnswerFeedback status="waiting" />
    </Center>
  ) : (
    <Flex direction="column" className={cx(classes.widgetWrapper, customClassNames)}>
      {labelFragment && (
        <Box className={cx(questionClasses.label, classes.labelWrapper, ThemeClassNames.widgets.interactivePoll.label)}>
          <RichText fragment={labelFragment} dataTestIdPrefix="preview-interactive-poll-title" editable={false} />
        </Box>
      )}
      <Box className={classes.answersWrapper}>
        <InteractivePollAnswerPlayerImplementation />
      </Box>
      <PlenaryLessonSubmitButton onSubmit={onSubmit} isDisabled={selectedAnswers.length === 0} />
    </Flex>
  );
};
