import { BorderThemeProps } from '@hendrx/modules/ui';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { BorderPanel } from '@/components/Theme/BorderPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { SwitchPanel } from '@/components/Theme/SwitchPanel';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useStyles } from '@/widgets/Table/config/Config.styles';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function Borders() {
  const t = useTranslations('components.theme.settings');
  const { classes } = useStyles();
  const { document, id } = useContentObject();
  const [themeIsBorderVisible] = useActivityThemeStylesProperty<boolean>(document, 'table.isBorderVisible');
  const [isBorderVisible, setIsBorderVisible] = useWidgetStyle<boolean | null>(document, id, 'isBorderVisible');

  const [themeBorder] = useActivityThemeStylesProperty<Omit<BorderThemeProps, 'borderColor'> & { borderColor: string }>(
    document,
    `table.border`,
    undefined,
    'global.content.colors.decoration.main'
  );

  const [border, setBorder] = useWidgetStyle<BorderThemeProps | null>(document, id, 'border');

  return (
    <ThemeItemSection label={t('borders')}>
      <SwitchPanel
        value={isBorderVisible ?? themeIsBorderVisible}
        onChange={setIsBorderVisible}
        ResetButton={!isNil(isBorderVisible) && <ResetButton onClick={() => setIsBorderVisible(null)} />}
      />
      <BorderPanel
        label={t('borderStyle')}
        border={border ?? themeBorder}
        setBorder={setBorder}
        themeControlClassName={classes.themeControl}
        ResetButton={border && <ResetButton onClick={() => setBorder(null)} />}
      />
    </ThemeItemSection>
  );
}
