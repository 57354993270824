import * as Y from 'yjs';

import { removeSection } from './removeSection';
import { WidgetType } from './widgets';
import { removeContentObject } from '../widgetsSDK/contentObjectOperations';
import { findPositionInArray, getContentObjectsMap, validateYArray, validateYMap } from '../widgetsSDK/yjs';

export const removeChild = ({
  childId,
  deselect,
  document,
  sectionData: sectionDataArg,
  sectionId
}: {
  childId: string;
  deselect?: () => void;
  document: Y.Doc;
  sectionData?: Y.Map<unknown>;
  sectionId: string;
}) => {
  document.transact(() => {
    let sectionData = sectionDataArg;
    if (!sectionData) {
      sectionData = getContentObjectsMap(document).get(sectionId);
    }

    if (!sectionData) {
      throw new Error('Section data not found');
    }

    const sectionLayout = validateYMap(sectionData.get('layout'));
    const sectionChildren = validateYArray<string>(sectionData.get('children'));
    const childIndex = findPositionInArray(sectionChildren, childId);

    removeContentObject(document, childId);

    sectionChildren.delete(childIndex);
    sectionLayout.delete(childId);

    deselect?.();

    if (sectionData.get('type') === WidgetType.Section && sectionChildren.length === 0) {
      // Do not delete the parent section if it's a horizontal/vertical one
      removeSection(document, sectionId);
    }
  });
};
