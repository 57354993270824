import { uniqueItemsPerPage, fullPageItems, questionWidgets, WidgetType } from 'shared/utils/widgets';

import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';

export function getDragTypeForWidget(widgetType: WidgetType) {
  if (fullPageItems.includes(widgetType)) {
    return ItemDragType.FullPageItem;
  }

  if (uniqueItemsPerPage.includes(widgetType)) {
    return ItemDragType.UniqueItem;
  }

  if (questionWidgets.includes(widgetType)) {
    return ItemDragType.Question;
  }

  if (widgetType === WidgetType.FlexSection) {
    return ItemDragType.FlexSection;
  }

  if (widgetType === WidgetType.Section) {
    return ItemDragType.Section;
  }

  return ItemDragType.Item;
}
