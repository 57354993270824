import { useTranslations } from 'next-intl';

import { Select } from '@/components/Select';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function TableStyles() {
  const t = useTranslations('components.theme.settings');
  const { document, id } = useContentObject();
  const [themeTableStyle] = useActivityThemeStylesProperty<string | null>(document, 'table.tableStyle');
  const [tableStyle, setTableStyle] = useWidgetStyle<string | null>(document, id, 'tableStyle');

  const tableStyleData = [
    { value: 'simple', label: t('simple') },
    { value: 'alternate-rows', label: t('alternateRows') },
    { value: 'alternate-columns', label: t('alternateColumns') }
  ];

  return (
    <ThemeItemSection label={t('tableStyle')}>
      <Select
        data={tableStyleData}
        value={tableStyle ?? themeTableStyle}
        onChange={newStyle => setTableStyle(newStyle)}
      />
      {tableStyle && <ResetButton onClick={() => setTableStyle(null)} />}
    </ThemeItemSection>
  );
}
