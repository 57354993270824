import { Box } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

import { RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';

import { useCanUpsertDraftFeedbackForAssignment } from '@/components/CanProvider';
import { useSavedDraftFeedbackForAssignment, useSaveElementState } from '@/queries/state';
import { useStyles } from '@/widgets/SubmitPageComment/player/SubmitPageComment.styles';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

const tools = [
  RichTextTools.TextColor,
  RichTextTools.Underline,
  RichTextTools.Italic,
  RichTextTools.Bold,
  RichTextTools.TextHighlight
];

export function TeacherComment() {
  const t = useTranslations('widgets.submitPageComment');

  const [settingsPlaceholder] = useContentObjectProperty<string>('placeholder');
  const placeholder = settingsPlaceholder || t('feedbackToTheStudent');

  const [currentFeedbackForAssignmentEditor, setCurrentFeedbackForAssignmentEditor] = useState<Editor | null>(null);

  const { classes } = useStyles();

  const canUpsertDraftFeedbackForAssignment = useCanUpsertDraftFeedbackForAssignment();

  const { data: draftFeedbackForAssignment } = useSavedDraftFeedbackForAssignment();
  const currentFeedbackForAssignment = draftFeedbackForAssignment;

  const { mutate: saveElementState } = useSaveElementState();

  useEffect(() => {
    if (currentFeedbackForAssignmentEditor?.isEmpty && currentFeedbackForAssignment) {
      currentFeedbackForAssignmentEditor?.commands.setContent(currentFeedbackForAssignment);
    }
  }, [currentFeedbackForAssignment, currentFeedbackForAssignmentEditor]);

  const onDraftFeedbackForAssignmentChange = useCallback(
    (newDraftFeedbackForAssignment: string) => {
      saveElementState({
        elements: [{ elementId: 'draftFeedbackForAssignment', state: newDraftFeedbackForAssignment }]
      });
    },
    [saveElementState]
  );

  return (
    <Box className={classes.comment}>
      <RichText
        dataTestIdPrefix="teacher-comment"
        placeholder={placeholder}
        editable={canUpsertDraftFeedbackForAssignment}
        onChange={onDraftFeedbackForAssignmentChange}
        setEditor={setCurrentFeedbackForAssignmentEditor}
        withBorder={true}
        tools={tools}
        toolbarType={ToolbarType.Inline}
      />
    </Box>
  );
}
