import { Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function RandomizeSubQuestions() {
  const t = useTranslations('widgets');
  const [withRandomSubQuestions, setWithRandomSubQuestions] =
    useContentObjectProperty<boolean>('withRandomSubQuestions');

  return (
    <FormControl title={t('questionGroupListSettings.subQuestionsOrder')} withSeparator>
      <Switch
        checked={withRandomSubQuestions}
        onChange={event => setWithRandomSubQuestions(event.currentTarget.checked)}
        label={t('questionGroupListSettings.random')}
      />
    </FormControl>
  );
}
