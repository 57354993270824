import { Group, Stack, Switch, Text } from '@mantine/core';
import { ReactElement } from 'react';

import * as classes from './SwitchGroup.css';
import { FormControl } from '@/widgets/_components/FormControl';

type Option = { label: string | ReactElement; value: string; disabled?: boolean };

type SwitchGroupProps = {
  dataTestId: string;
  onChange: (value: string[]) => void;
  options: Option[];
  title: string;
  value: string[];
};

export function SwitchGroup(props: SwitchGroupProps) {
  const { dataTestId, onChange, options, title, value } = props;

  return (
    <FormControl title={title}>
      <Switch.Group value={value} onChange={onChange}>
        <Stack gap={14}>
          {options.map(option => (
            <Switch
              key={option.value}
              value={option.value}
              data-testid={`${dataTestId}-${option.value}-checkbox`}
              classNames={{
                body: classes.elementSwitchBody,
                label: classes.elementSwitchLabel,
                input: classes.elementSwitchInput
              }}
              label={
                <Group gap={14} data-testid={`${dataTestId}-${option.value}-label`}>
                  {typeof option.label === 'string' ? <Text>{option.label}</Text> : option.label}
                </Group>
              }
              disabled={option.disabled}
            />
          ))}
        </Stack>
      </Switch.Group>
    </FormControl>
  );
}
