import { Box } from '@mantine/core';
import cx from 'clsx';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { GroupCheckButtonMode, ParentHeadline } from 'shared/types/QuestionGroupList';
import { WidgetType } from 'shared/utils/widgets';
import {
  ContentObjectType,
  getContentObjectsMap,
  useObservedArray,
  useObservedProperty,
  validateYMap
} from 'shared/widgetsSDK/yjs';

import { usePlayerStateStore } from '@/components/PlayerStateProvider';
import { useQuestionGroupsContext } from '@/components/QuestionGroupsContext';
import { QuestionTitle } from '@/components/QuestionTitle';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { BaseEvaluationResult, useEvaluateQuestion } from '@/queries/evaluation';
import { QuestionStates } from '@/queries/state';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { checkIfYXmlFragmentHasContent } from '@/utils/tiptap';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUnansweredQuestionsInGroupCount } from '@/utils/useUnansweredQuestionsInGroupCount';
import { Player as FlexSection } from '@/widgets/FlexSection/player';
import * as classes from '@/widgets/QuestionGroupList/QuestionGroupList.css';
import {
  QuestionGroupListAction,
  QuestionGroupListActionContext,
  QuestionGroupListEvaluationContext,
  QuestionGroupListSettingsContext
} from '@/widgets/QuestionGroupList/context';
import { useQuestionListBulletType } from '@/widgets/QuestionGroupList/hooks/useQuestionListBulletType';
import { getEmptySubQuestionAnswer } from '@/widgets/QuestionGroupList/player/utils/getEmptySubQuestionAnswer';
import { Base as TextPlayerBase } from '@/widgets/Text/player/Base';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { WidgetNotification } from '@/widgets/_components/WidgetNotification';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';

export function QuestionGroupList() {
  const { document, id } = useContentObject();

  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const descriptionFragment = useContentObjectStaticProperty<Y.XmlFragment>('descriptionFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [feedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');
  const [parentHeadline] = useContentObjectProperty<`${ParentHeadline}`>('parentHeadline');
  const [showGeneralGrade] = useContentObjectProperty<boolean>('showGeneralGrade');
  const [showEachQuestionGrade] = useContentObjectProperty<boolean>('showEachQuestionGrade');
  const [groupCheckButtonMode] = useContentObjectProperty<`${GroupCheckButtonMode}`>('groupCheckButtonMode');
  const [scoreWeight] = useContentObjectScoreWeight();
  const { contentLanguage } = useContentLanguage();

  const questionListBulletType = useQuestionListBulletType();

  const [choiceQuestionsEnabled] = useContentObjectProperty<boolean>('choiceQuestionsEnabled');
  const [choiceQuestionsCount] = useContentObjectProperty<number>('choiceQuestionsCount');
  const [visibleQuestionsCount] = useContentObjectProperty<number>('visibleQuestionsCount');
  const group = useContentObject();
  const { id: groupId } = group;

  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<ContentObjectType>>(contentObjectsMap, verticalSectionId);
  const [verticalChildrenIds] = useObservedArray<string>(verticalSection, 'children');
  const [requiredQuestionIds] = useContentObjectProperty<string[]>('requiredSubQuestionsId');

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const t = useTranslations('widgets');

  console.log('descriptionFragment', descriptionFragment);
  const showManualDescription = checkIfYXmlFragmentHasContent(descriptionFragment);
  const showManualHeadline = parentHeadline === ParentHeadline.Manual && checkIfYXmlFragmentHasContent(labelFragment);

  const questionGroups = useQuestionGroupsContext();

  const questionsToShow = useMemo(
    () => questionGroups[groupId]?.filter(questionToShow => verticalChildrenIds.includes(questionToShow)) ?? [],
    [groupId, questionGroups, verticalChildrenIds]
  );

  const { mutate: evaluate } = useEvaluateQuestion<WidgetType.QuestionGroupList>();

  const elementStates = usePlayerStateStore(state => state.elementsState) as Record<string, QuestionStates>;
  const subQuestionsData: Record<string, QuestionStates> = {};
  Object.entries(elementStates).forEach(([key, val]) => {
    if (questionsToShow.includes(key)) {
      subQuestionsData[key] = val;
    }
  });

  const numberOfQuestionsToEvaluate = choiceQuestionsEnabled ? choiceQuestionsCount : visibleQuestionsCount;

  const atLeastOneCorrectAnswerRevealed =
    subQuestionsData && Object.values(subQuestionsData).some(data => data.showCorrectAnswers);

  const noSubQuestionChecked =
    subQuestionsData &&
    Object.values(subQuestionsData).every(data => {
      return !data.checked;
    });

  const [grade, setGrade] = useState<number | undefined>(undefined);
  const [evaluatedSubQuestions, setEvaluatedSubQuestions] = useState<Record<string, BaseEvaluationResult> | null>(null);
  const [action, setAction] = useState<QuestionGroupListAction | null>(null);

  const showCheckButton =
    feedback === Feedbacks.Check &&
    groupCheckButtonMode === GroupCheckButtonMode.GeneralButton &&
    !atLeastOneCorrectAnswerRevealed &&
    action !== QuestionGroupListAction.Check &&
    action !== QuestionGroupListAction.ShowAnswers;

  const showResetButton = feedback === Feedbacks.Check && groupCheckButtonMode === GroupCheckButtonMode.GeneralButton;

  const shouldShowAnswerButton =
    showAnswerButton &&
    noSubQuestionChecked &&
    action !== QuestionGroupListAction.Check &&
    action !== QuestionGroupListAction.ShowAnswers;

  const evaluateSubQuestions = () => {
    if (!subQuestionsData) return;
    evaluate(
      {
        questionId: id,
        payload: {
          shownQuestions: questionsToShow || [],
          requiredQuestionIds,
          numberOfQuestionsToEvaluate,
          answers: Object.entries(subQuestionsData).reduce((answers, subQuestionState) => {
            const [subQuestionId, state] = subQuestionState;
            return {
              ...answers,
              [subQuestionId]:
                (state as QuestionStates | undefined)?.answers ??
                getEmptySubQuestionAnswer(validateYMap(contentObjectsMap.get(subQuestionId)).get('type') as WidgetType)
            };
          }, {})
        }
      },
      {
        onSuccess(data) {
          const evaluatedSubQuestions = data.evaluatedSubQuestions as Record<string, BaseEvaluationResult>;
          const totalGrade = Object.values(evaluatedSubQuestions).reduce(
            (totalGrade, subQuestion) => totalGrade + (subQuestion.grade ?? 0),
            0
          );
          setGrade(totalGrade);
          setEvaluatedSubQuestions(evaluatedSubQuestions);
        }
      }
    );
  };

  const settings = useMemo(
    () => ({
      choiceQuestionsEnabled,
      feedback,
      groupCheckButtonMode,
      showEachQuestionGrade,
      scoreWeight,
      questionListBulletType,
      subQuestionsOrder: questionsToShow
    }),
    [
      choiceQuestionsEnabled,
      feedback,
      groupCheckButtonMode,
      showEachQuestionGrade,
      scoreWeight,
      questionListBulletType,
      questionsToShow
    ]
  );

  const { subQuestionsCount, unansweredSubQuestionsCount } = useUnansweredQuestionsInGroupCount(groupId);
  const renderUnansweredSubQuestionsCount =
    choiceQuestionsEnabled && unansweredSubQuestionsCount > 0 && action !== QuestionGroupListAction.ShowAnswers;

  const handleCheckAnswers = () => {
    evaluateSubQuestions();
    setAction(QuestionGroupListAction.Check);
  };
  const handleResetAnswers = () => {
    setAction(QuestionGroupListAction.Reset);
  };
  const handleShowAnswers = () => {
    setAction(QuestionGroupListAction.ShowAnswers);
  };

  const listClassName =
    questionListBulletType === 'Letter'
      ? classes.list[questionListBulletType][contentLanguage]
      : classes.list[questionListBulletType];

  return (
    <div className={customClassNames}>
      {labelFragment && showManualHeadline && (
        <Box className={cx(classes.label, ThemeClassNames.widgets.questionGroupList.label)}>
          <QuestionTitle editable={false} questionId={id} fragment={labelFragment} />
        </Box>
      )}
      <Box className={cx(classes.description, ThemeClassNames.widgets.questionGroupList.description)}>
        {showManualDescription && <TextPlayerBase fragment={descriptionFragment} />}
        {choiceQuestionsEnabled && (
          <WidgetNotification
            text={t('questionGroupListSettings.automaticDescription', {
              minAmountOfInfluencedQuestions: choiceQuestionsCount,
              maxAmountOfQuestions: visibleQuestionsCount
            })}
          />
        )}
      </Box>
      <Box>
        <ContentObjectProvider id={verticalSectionId} document={document} type={WidgetType.FlexSection}>
          <Box className={cx('themed-list', listClassName)}>
            <QuestionGroupListEvaluationContext.Provider value={evaluatedSubQuestions}>
              <QuestionGroupListSettingsContext.Provider value={settings}>
                <QuestionGroupListActionContext.Provider value={{ action, setAction }}>
                  <FlexSection
                    questionIds={questionsToShow}
                    className={classes.verticalSection.base}
                    shouldApplyNestStyles={false}
                  />
                </QuestionGroupListActionContext.Provider>
              </QuestionGroupListSettingsContext.Provider>
            </QuestionGroupListEvaluationContext.Provider>
          </Box>
        </ContentObjectProvider>
      </Box>
      <QuestionFooter
        questionId={id}
        onCheck={handleCheckAnswers}
        onReset={handleResetAnswers}
        onShowAnswer={handleShowAnswers}
        hintFragment={hintFragment}
        isHintEditable={false}
        questionScoreMode={questionScoreMode}
        scoreWeight={showGeneralGrade ? scoreWeight : 0}
        showAnswerButton={shouldShowAnswerButton}
        showCheckButton={showCheckButton}
        showHintButton={showHintButton}
        showResetButton={showResetButton}
        grade={grade}
        showSeparator={false}
      />
      {renderUnansweredSubQuestionsCount && (
        <WidgetNotification
          text={t('questionGroupList.noQuestionAnsweredCount', {
            unansweredSubQuestionsCount:
              unansweredSubQuestionsCount === subQuestionsCount ? 0 : unansweredSubQuestionsCount
          })}
        />
      )}
    </div>
  );
}
