import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    comment: {
      lineHeight: 'normal',
      marginBottom: rem(16)
    }
  };
});
