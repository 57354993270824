import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import deepClone from 'lodash/cloneDeep';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { QuestionGroupsType } from 'shared/types/QuestionGroups';
import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { SaveElementRequest } from 'shared/types/dto/State';

import {
  useCanReadDraftFeedbackForAssignment,
  useCanReadDraftNoteToTeacher,
  useCanReadSatisfactionScore,
  useCanReadSavedState,
  useCanReadSubmissionHistory
} from '@/components/CanProvider';
import { SatisfactionScore } from '@/components/SatisfactionSurvey';
import { Submission } from '@/components/SubmissionHistory';
import { getSavedState, saveElementState } from '@/queries/stateApi';
import { useUser } from '@/queries/user';
import { parseQueryParam } from '@/utils/query.utils';
import { useCurrentPageIndex } from '@/utils/useCurrentPageIndex';
import { useLearningObjectIds } from '@/utils/useLearningObjectIds';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useWorkMode } from '@/utils/useWorkMode';

type UseSavedStateOptions<Result> = {
  select?: (data: Record<string, never>) => Result;
};

export const useSaveElementState = (
  baseArgs: Partial<SaveElementRequest> = {},
  options: { invalidate?: boolean } = { invalidate: true }
) => {
  const router = useRouter();
  const { learningObjectDraftId, assignmentId, learningObjectSnapshotId } = useLearningObjectIds();
  const { type } = useLearningObjectType();
  const isPlenaryLesson = type === LearningObjectTypes.PlenaryLesson;
  const plenaryId = isPlenaryLesson ? learningObjectSnapshotId : undefined;

  const pageIndex = useCurrentPageIndex();
  const { user } = useUser();

  const { isPlayer, isPlayerAssignment } = useWorkMode();
  const id = isPlayer && !isPlayerAssignment ? learningObjectDraftId : assignmentId;
  const queryType = isPlayer && !isPlayerAssignment ? 'learning' : 'assignment';

  const studentId = parseQueryParam(router.query.studentId) ?? user?.id;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: Partial<SaveElementRequest> & Pick<SaveElementRequest, 'elements'>) => {
      if (!id) {
        throw new Error('Assignment ID is not defined.');
      }

      return saveElementState({
        ...baseArgs,
        ...args,
        assignmentId: id,
        pageIndex: Number(pageIndex) || 0,
        learningObjectDraftId,
        studentId,
        plenaryId
      });
    },
    onMutate: async data => {
      await queryClient.cancelQueries(['savedState', queryType, { id, userId: studentId, plenaryId }]);

      const previousState =
        queryClient.getQueryData<Record<string, unknown>>([
          'savedState',
          queryType,
          { id, userId: studentId, plenaryId }
        ]) ?? {};

      const newState = deepClone(previousState);

      data.elements.forEach(({ elementId, state }) => {
        newState[elementId] = state;
      });

      queryClient.setQueryData<Record<string, unknown>>(
        ['savedState', queryType, { id, userId: studentId, plenaryId }],
        old => ({
          ...old,
          ...newState
        })
      );

      return { previousState };
    },
    onError: (err, newData, context) => {
      // Rollback to previous value
      queryClient.setQueryData(
        ['savedState', queryType, { id, userId: studentId, plenaryId }],
        () => context?.previousState
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['savedState', queryType, { id, userId: studentId, plenaryId }]);
    },
    retry: 3,
    retryDelay: 1000,
    cacheTime: 60 * 60 * 24 * 1000, // 24 hours
    networkMode: 'offlineFirst'
  });
};

export const useSavedState = <Result = Record<string, unknown>>(options?: UseSavedStateOptions<Result>) => {
  const canReadSavedState = useCanReadSavedState();

  const { user: currentUser } = useUser();
  const router = useRouter();
  const { isPlayer, isPlayerAssignment } = useWorkMode();
  const { learningObjectDraftId, assignmentId, learningObjectSnapshotId } = useLearningObjectIds();
  const { type } = useLearningObjectType();
  const isPlenaryLesson = type === LearningObjectTypes.PlenaryLesson;
  const plenaryId = isPlenaryLesson ? learningObjectSnapshotId : undefined;

  const studentId = parseQueryParam(router.query.studentId);
  const userId = studentId || currentUser?.id;
  const id = isPlayer && !isPlayerAssignment ? learningObjectDraftId : assignmentId;
  const queryType = isPlayer && !isPlayerAssignment ? 'learning' : 'assignment';

  const query = useQuery<Record<string, never>, unknown, Result>({
    queryKey: ['savedState', queryType, { id, userId, plenaryId }],
    queryFn: () => getSavedState({ userId: userId as string, learningObjectDraftId, assignmentId, plenaryId }),
    enabled: !!userId && canReadSavedState,
    select: options?.select,
    // TODO fix persisting common state for teacher and student
    staleTime: 0
  });

  useEffect(() => {
    if (!canReadSavedState && query.data) {
      query.remove();
    }
  }, [canReadSavedState, query]);

  return query;
};

type Answer = string[] | Record<string, unknown>;

export type QuestionState<T extends Answer> = {
  answers: T;
  checked?: boolean;
  showCorrectAnswers?: boolean;
};

export type QuestionStates = QuestionState<Answer>;

export const useSavedStateAnswer = <T>(questionId: string) => {
  return useSavedState<T | undefined>({
    select: data => data?.[questionId]
  });
};

export const useSavedStateAnswers = (questionsIds: string[] | undefined) => {
  return useSavedState<Record<string, QuestionStates>>({
    select: data => questionsIds?.reduce((acc, id) => ({ ...acc, [id]: data?.[id] }), {}) ?? {}
  });
};

export const useSavedDraftFeedbackForAssignment = () => {
  const canReadDraftFeedbackForAssignment = useCanReadDraftFeedbackForAssignment();
  return useSavedState<Record<string, Record<string, never>> | undefined>({
    select: data => {
      const draftFeedbackForAssignment = data?.['draftFeedbackForAssignment'];
      if (canReadDraftFeedbackForAssignment) {
        return draftFeedbackForAssignment;
      }
      return undefined;
    }
  });
};

export const useSavedDraftNoteToTeacher = () => {
  const canReadDraftNoteToTeacher = useCanReadDraftNoteToTeacher();
  return useSavedState<Record<string, Record<string, never>> | undefined>({
    select: data => {
      const draftNoteToTeacher = data?.['draftNoteToTeacher'];
      if (canReadDraftNoteToTeacher) {
        return draftNoteToTeacher;
      }
      return undefined;
    }
  });
};

export const useSavedQuestionGroups = () => {
  return useSavedState<QuestionGroupsType | undefined>({
    select: data => data?.['questionGroups']
  });
};

export const useSavedSatisfactionScore = () => {
  const canReadSatisfactionScore = useCanReadSatisfactionScore();
  return useSavedState<SatisfactionScore | null | undefined>({
    select: data => {
      const satisfactionScore = data?.['satisfactionScore'];
      if (canReadSatisfactionScore) {
        return satisfactionScore;
      }
      return undefined;
    }
  });
};

export const useSavedGrade = (questionId: string) => {
  const { data: manualGrades = {}, isLoading } = useSavedStateAnswer<Record<string, number>>('manualGrades');
  const savedGrade = manualGrades[questionId] ?? undefined;

  const { mutate: saveElementState } = useSaveElementState();

  const saveGrade = useCallback(
    (score: number | undefined) => {
      saveElementState({ elements: [{ elementId: 'manualGrades', state: { ...manualGrades, [questionId]: score } }] });
    },
    [manualGrades, questionId, saveElementState]
  );

  return { savedGrade, isLoading, saveGrade };
};

export const useSavedSubmissionHistory = () => {
  const canReadSubmissionHistory = useCanReadSubmissionHistory();

  const { data: submissionHistory, isLoading } = useSavedStateAnswer<Submission[] | null>('submissionHistory');

  if (canReadSubmissionHistory) {
    return { submissionHistory, isLoading };
  }
  return { submissionHistory: undefined, isLoading: false };
};

export const useSavedSummaryPageSeen = () => {
  const { data: summaryPageSeen, isLoading } = useSavedStateAnswer<boolean>('summaryPageSeen');
  const { mutate: saveElementState } = useSaveElementState({}, { invalidate: false });

  const markSummaryPageAsSeen = useCallback(
    (summaryPageSeen: boolean) => {
      saveElementState({ elements: [{ elementId: 'summaryPageSeen', state: summaryPageSeen }] });
    },
    [saveElementState]
  );

  return { summaryPageSeen, markSummaryPageAsSeen, isLoading };
};
