import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import cx from 'clsx';
import { useState } from 'react';

import { RichTextExtraTools, RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';

import * as classes from './RichTextEditorInline.css';
import { RichTextProps } from '@/widgets/_components/RichText';
import { RichTextToolbar } from '@/widgets/_components/RichTextToolbar';

type RichTextEditorInlineProps = {
  tools?: (RichTextTools | RichTextExtraTools)[];
  toolbarClassName?: string;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
};

export function RichTextEditorInline(props: RichTextProps & RichTextEditorInlineProps) {
  const { tools, toolbarClassName, overrideTypographyStylesPath, changeContrastColorToMain } = props;

  const { editor } = useRichTextEditorContext();
  const [editorContentRef, setEditorContentRef] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <RichTextEditor.Toolbar className={cx(classes.toolbar, toolbarClassName)} sticky>
        <RichTextToolbar
          editor={editor}
          editorContentRef={editorContentRef}
          type={ToolbarType.Inline}
          tools={tools}
          overrideTypographyStylesPath={overrideTypographyStylesPath}
          changeContrastColorToMain={changeContrastColorToMain}
        />
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content ref={setEditorContentRef} />
    </>
  );
}
