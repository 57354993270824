import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { Feedbacks } from 'shared/types/Feedbacks';
import { GroupCheckButtonMode, QuestionListBulletTypes } from 'shared/types/QuestionGroupList';

import { QuestionStates } from '@/queries/state';
import { QuestionGroupListSettings } from '@/widgets/QuestionGroupList/context';

const shouldUseParentFeedback = (parentFeedback: `${Feedbacks}` | undefined) => {
  return parentFeedback && parentFeedback !== Feedbacks.None;
};

export const shouldShowCheckButton = (
  ownFeedback: `${Feedbacks}`,
  questionGroupSettings: QuestionGroupListSettings | null
) => {
  const { feedback: parentFeedback, groupCheckButtonMode: parentFeedbackMode } = questionGroupSettings ?? {};
  const usesParentFeedback = shouldUseParentFeedback(parentFeedback);
  return usesParentFeedback
    ? parentFeedback === Feedbacks.Check && parentFeedbackMode === GroupCheckButtonMode.OnEachQuestion
    : ownFeedback === Feedbacks.Check;
};

export const shouldShowResetButton = (questionGroupSettings: QuestionGroupListSettings | null) => {
  const { feedback: parentFeedback, groupCheckButtonMode: parentFeedbackMode } = questionGroupSettings ?? {};

  if (
    questionGroupSettings &&
    parentFeedback === Feedbacks.Check &&
    parentFeedbackMode === GroupCheckButtonMode.GeneralButton
  ) {
    return false;
  }

  return true;
};

export const getProperFeeback = (ownFeedback: `${Feedbacks}`, parentFeedback: `${Feedbacks}` | undefined) => {
  return shouldUseParentFeedback(parentFeedback) ? parentFeedback : ownFeedback;
};

export const shouldShowScoreWeight = (questionGroupSettings: QuestionGroupListSettings | null) => {
  return !questionGroupSettings || (questionGroupSettings && questionGroupSettings.showEachQuestionGrade);
};

export const isQuestionAnswered = (questionState: QuestionStates | undefined) => {
  const answers = questionState?.answers;
  if (questionState === undefined || isEmpty(answers)) {
    return false;
  }
  return isArray(answers) ? answers.some(Boolean) : Object.values(answers).some(Boolean);
};

export const getQuestionBulletWrapperData = (
  questionGroupSettings: QuestionGroupListSettings | null,
  questionId: string
) => {
  const questionListBulletType = questionGroupSettings?.questionListBulletType ?? QuestionListBulletTypes.None;

  return {
    questionListBulletType,
    withQuestionListBullet: questionListBulletType !== QuestionListBulletTypes.None
  };
};
