import * as Y from 'yjs';

import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { HorizontalLayout } from '../../types/Layout';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';

type SectionChild = {
  id: string;
} & Partial<HorizontalLayout>;

export function createNewFlexSectionData(
  direction: FlexSectionDirection,
  childItems: SectionChild[] = [],
  style: Y.Map<unknown> = new Y.Map()
) {
  const id = genRandId();

  const children = new Y.Array();
  children.push(childItems.map(child => child.id));

  const layout = new Y.Map();
  childItems.forEach(child => {
    layout.set(
      child.id,
      new Y.Map([
        ['span', child.span ?? 4],
        ['column', child.column ?? 1],
        ['height', child.height ?? 200],
        ['width', child.width ?? 200],
        ['isDeletable', child.isDeletable ?? true],
        ['isDraggable', child.isDraggable ?? true]
      ])
    );
  });

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.FlexSection],
        ['children', children],
        ['layout', layout],
        ['direction', direction],
        ['style', style]
      ])
    }
  ];
}
