import has from 'lodash/has';
import { useTranslations } from 'next-intl';

import { InputClozeBase } from './InputClozeBase';
import {
  RootMapNames,
  useContentObjectErrors,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

type InputClozeProps = {
  index: number;
  isDisabled: boolean;
  propertyPath: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onDelete?: () => void;
  parentMap?: RootMapNames;
};

export function InputCloze(props: InputClozeProps) {
  const { autoFocus, onChange, isDisabled, onDelete, propertyPath, parentMap } = props;

  const [value, setValue] = useContentObjectProperty<string>(propertyPath, parentMap);

  const [errors] = useContentObjectErrors();

  const t = useTranslations('widgets.clozeQuestionSettings');

  return (
    <InputClozeBase
      autoFocus={autoFocus}
      value={value}
      onChange={value => {
        setValue(value);
        onChange?.(value);
      }}
      error={has(errors, propertyPath) && t('addAlternativeTextError')}
      isDisabled={isDisabled}
      onDelete={onDelete}
    />
  );
}
