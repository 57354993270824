import { useEffect, useMemo, useRef, useState } from 'react';

import { PRESENTATION_AREA_HEIGHT_PX, PRESENTATION_AREA_WIDTH_PX } from '@/consts/styleConsts';

export const useFullscreenScale = () => {
  const stageRef = useRef(document.getElementById('scalableStage'));
  const [dimensions, setDimensions] = useState({
    stageHeight: stageRef.current?.offsetHeight ?? PRESENTATION_AREA_HEIGHT_PX,
    stageWidth: stageRef.current?.offsetWidth ?? PRESENTATION_AREA_WIDTH_PX,
    containerHeight: window.innerHeight,
    containerWidth: window.innerWidth,
    offsetTop: 0,
    offsetLeft: 0
  });

  useEffect(() => {
    const updateDimensions = () => {
      if (stageRef.current) {
        const rect = stageRef.current.getBoundingClientRect();
        setDimensions({
          stageHeight: stageRef.current.offsetHeight ?? PRESENTATION_AREA_HEIGHT_PX,
          stageWidth: stageRef.current.offsetWidth ?? PRESENTATION_AREA_WIDTH_PX,
          containerHeight: window.innerHeight,
          containerWidth: window.innerWidth,
          offsetTop: rect.top,
          offsetLeft: rect.left
        });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const { stageHeight, stageWidth, containerHeight, containerWidth, offsetTop, offsetLeft } = dimensions;

  const fullscreenScale = useMemo(() => {
    const isScreenShorterThanPresentation = containerWidth / containerHeight < 16 / 9;
    return isScreenShorterThanPresentation ? containerWidth / stageWidth : containerHeight / stageHeight;
  }, [containerWidth, containerHeight, stageWidth, stageHeight]);

  return { fullscreenScale, offsetTop, offsetLeft };
};
