import { Box, Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { ContentObjectType, getContentObjectsMap, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import * as classes from './QuestionHeader.css';
import { Player as FlexSection } from '@/widgets/FlexSection/player';
import { CollapseButton } from '@/widgets/OpenDiscussion/CollapseButton';
import { useQuestionHeaderStyles } from '@/widgets/OpenDiscussion/hooks/useQuestionHeaderStyles';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

const getStudentHeadlineFragment = (contentObjectsMap: Y.Map<ContentObjectType>, sectionId: string) => {
  const horizontalSectionData = validateYMap(contentObjectsMap.get(sectionId));
  const childrenIds = validateYArray<string>(horizontalSectionData.get('children')).toArray();
  const layout = validateYMap(horizontalSectionData.get('layout'));

  const headlineId = childrenIds.find(id => {
    const childLayout = validateYMap(layout.get(id));
    return !childLayout.get('isDeletable');
  });
  const headlineObject = validateYMap(contentObjectsMap.get(headlineId ?? ''));
  return headlineObject.get('fragment') as Y.XmlFragment;
};

type QuestionHeaderProps = {
  isStudentView?: boolean;
};

export function QuestionHeader(props: QuestionHeaderProps) {
  const { isStudentView } = props;
  const [opened, { toggle }] = useDisclosure(true);

  const { document } = useContentObject();
  const contentObjectsMap = getContentObjectsMap(document);
  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');
  const headlineFragment = getStudentHeadlineFragment(contentObjectsMap, horizontalSectionId);

  const { gap, alignTo, direction, collapsibleSectionStyles } = useQuestionHeaderStyles(horizontalSectionId, document);

  return (
    <>
      <Collapse in={opened} style={collapsibleSectionStyles} className={classes.collapsible}>
        {isStudentView ? (
          <Box p={24}>
            <RichText fragment={headlineFragment} editable={false} />
          </Box>
        ) : (
          <ContentObjectProvider id={horizontalSectionId} document={document} type={WidgetType.FlexSection}>
            <FlexSection
              shouldApplyNestStyles={false}
              direction={direction}
              alignItems={alignTo}
              gap={gap}
              className={classes.headerSection}
            />
          </ContentObjectProvider>
        )}
      </Collapse>
      <CollapseButton opened={opened} onToggle={toggle} />
    </>
  );
}
