import * as Y from 'yjs';

import { calculateGrade } from './calculateGrade';
import { Feedbacks, getFeedbackMessageType } from './feedback';
import { calculateClozeQuestionPercentageScore } from './formulas';

enum ClozeOptionType {
  Input = 'Input',
  Select = 'Select'
}

export const evaluateClozeQuestion = (
  contentObjectData: Y.Map<unknown>,
  sensitiveData: Y.Map<unknown>,
  widgetFormData: Y.Map<unknown>,
  answers: Record<string, string>
) => {
  const clozeOptions = contentObjectData.get('clozeOptions') as Y.Map<Y.Map<unknown>>;
  const inputCorrectOptions = sensitiveData.get('inputCorrectOptions') as Y.Map<Y.Array<Y.Map<unknown>>>;
  const selectCorrectOptions = sensitiveData.get('selectCorrectOptions') as Y.Map<Y.Map<unknown>>;
  const feedback = contentObjectData.get('feedback') as Feedbacks;
  const scoreWeight = widgetFormData.get('scoreWeight') as number;

  let rightAnswersCount = 0;
  let wrongAnswersCount = 0;

  const evaluatedAnswers = Object.entries(answers).reduce((result, [clozeId, inputValue]) => {
    const type = clozeOptions?.get(clozeId)?.get('type');

    let isCorrect = false;
    if (type === ClozeOptionType.Input) {
      const correctOptions = inputCorrectOptions.get(clozeId)?.toArray() ?? [];
      isCorrect = correctOptions.some(option => option.get('value') === inputValue);
    }
    if (type === ClozeOptionType.Select) {
      const correctOptionValue = selectCorrectOptions.get(clozeId)?.get('value');
      isCorrect = inputValue === correctOptionValue;
    }

    if (isCorrect) {
      rightAnswersCount += 1;
    } else {
      wrongAnswersCount += 1;
    }

    result[clozeId] = isCorrect;
    return result;
  }, {} as Record<string, boolean>);

  const percentageScore = calculateClozeQuestionPercentageScore({
    allAnswersCount: clozeOptions.size,
    rightAnswersCount,
    wrongAnswersCount
  });

  const feedbackMessageType = getFeedbackMessageType({
    allPossibleRightAnswersCount: clozeOptions.size,
    rightAnswersCount,
    wrongAnswersCount,
    feedback
  });

  const grade = calculateGrade(percentageScore, scoreWeight, feedbackMessageType);

  return { evaluatedAnswers, feedbackMessageType, percentageScore, grade };
};
