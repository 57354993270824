import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { FontFamilySelectPanel } from '@/components/Theme/FontFamilySelectPanel';
import { FontSizeSelectPanel } from '@/components/Theme/FontSizeSelectPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { SwitchPanel } from '@/components/Theme/SwitchPanel';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function CaptionRow() {
  const t = useTranslations('components.theme.settings');
  const { document, id } = useContentObject();
  const [themeIsCaptionVisible] = useActivityThemeStylesProperty<boolean>(document, 'table.isCaptionVisible');
  const [isCaptionVisible, setIsCaptionVisible] = useWidgetStyle<boolean | null>(document, id, 'isCaptionVisible');

  const [themeCaptionBackgroundColor] = useActivityThemeStylesProperty<string | null>(
    document,
    'table.captionBackgroundColor'
  );
  const [globalCaptionBackgroundColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.primary.contrast'
  );
  const [captionBackgroundColor, setCaptionBackgroundColor] = useWidgetStyle<string | null>(
    document,
    id,
    'captionBackgroundColor'
  );

  const [themeCaptionFontFamily] = useActivityThemeStylesProperty<string | null>(document, 'table.captionFontFamily');
  const [globalCaptionFontFamily] = useActivityThemeStylesProperty<string>(document, 'global.content.fontFamily');
  const [captionFontFamily, setCaptionFontFamily] = useWidgetStyle<string | null>(document, id, 'captionFontFamily');
  const [themeCaptionColor] = useActivityThemeStylesProperty<string | null>(document, 'table.captionColor');
  const [globalCaptionColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.primary.contrast'
  );
  const [captionColor, setCaptionColor] = useWidgetStyle<string | null>(document, id, 'captionColor');
  const [themeCaptionFontSize] = useActivityThemeStylesProperty<number>(document, 'table.captionFontSize');
  const [captionFontSize, setCaptionFontSize] = useWidgetStyle<number | null>(document, id, 'captionFontSize');

  return (
    <ThemeItemSection label={t('captionRow')}>
      <SwitchPanel
        value={isCaptionVisible ?? themeIsCaptionVisible}
        onChange={setIsCaptionVisible}
        ResetButton={!isNil(isCaptionVisible) && <ResetButton onClick={() => setIsCaptionVisible(null)} />}
      />
      <ColorInputPanel
        label={t('backgroundColor')}
        color={captionBackgroundColor ?? themeCaptionBackgroundColor ?? globalCaptionBackgroundColor}
        setColor={setCaptionBackgroundColor}
        ResetButton={captionBackgroundColor && <ResetButton onClick={() => setCaptionBackgroundColor(null)} />}
      />
      <FontFamilySelectPanel
        label={t('family')}
        value={captionFontFamily ?? themeCaptionFontFamily ?? globalCaptionFontFamily}
        onChange={setCaptionFontFamily}
        ResetButton={captionFontFamily && <ResetButton onClick={() => setCaptionFontFamily(null)} />}
      />
      <FontSizeSelectPanel
        label={t('size')}
        value={captionFontSize ?? themeCaptionFontSize}
        onChange={setCaptionFontSize}
        ResetButton={captionFontSize && <ResetButton onClick={() => setCaptionFontSize(null)} />}
      />
      <ColorInputPanel
        label={t('color')}
        color={captionColor ?? themeCaptionColor ?? globalCaptionColor}
        setColor={setCaptionColor}
        ResetButton={captionColor && <ResetButton onClick={() => setCaptionColor(null)} />}
      />
    </ThemeItemSection>
  );
}
