import * as Y from 'yjs';

import { getItemWidth } from './Stage.utils';
import { validateYArray, validateYMap } from './yjs';

export const addNewItemToFlexSection = (
  sectionData: Y.Map<unknown>,
  position: number,
  newItem: { id: string; span: number; height: number | 'auto' },
  sectionWidth = 0
) => {
  const currentSectionChildren = validateYArray(sectionData?.get('children'));
  const newItemPosition = position === -1 ? 0 : position;
  currentSectionChildren.insert(newItemPosition, [newItem.id]);

  const currentSectionLayout = validateYMap<Y.Map<unknown>>(sectionData?.get('layout'));
  currentSectionLayout.set(
    newItem.id,
    new Y.Map([
      ['column', newItemPosition],
      ['span', newItem.span],
      ['width', Math.min(sectionWidth, getItemWidth(newItem.span))],
      ['height', newItem.height],
      ['isDraggable', true],
      ['isDeletable', true]
    ])
  );
};
