import { useMutation, useQuery } from '@tanstack/react-query';

import { LearningObjectSnapshot } from 'shared/types/LearningObjects';

import { getSnapshotForAssignment } from './assignmentApi';
import {
  getLatestLearningObjectSnapshot,
  getLearningObjectSnapshotById,
  listLearningObjectSnapshots,
  restoreLearningObjectSnapshot
} from '@/queries/learningObjectSnapshotApi';

export const useFetchLatestSnapshotByDraftId = ({
  enabled,
  learningObjectDraftId,
  publishedOnly
}: {
  enabled?: boolean;
  learningObjectDraftId?: string;
  publishedOnly?: boolean;
}) => {
  return useQuery<LearningObjectSnapshot>({
    queryKey: [
      'snapshot',
      'latest',
      {
        learningObjectDraftId,
        ...(publishedOnly && { publishedOnly })
      }
    ],
    queryFn: () =>
      getLatestLearningObjectSnapshot({
        learningObjectDraftId: learningObjectDraftId as string,
        publishedOnly
      }),
    enabled: !!learningObjectDraftId && enabled !== false
  });
};

export const useFetchSnapshotById = ({
  enabled = true,
  learningObjectSnapshotId
}: {
  enabled?: boolean;
  learningObjectSnapshotId?: string;
}) => {
  return useQuery<LearningObjectSnapshot>({
    queryKey: [
      'snapshot',
      {
        learningObjectSnapshotId
      }
    ],
    queryFn: () => getLearningObjectSnapshotById({ learningObjectSnapshotId }),
    enabled: !!learningObjectSnapshotId && enabled
  });
};

export const useFetchAllSnapshots = (learningObjectDraftId?: string) => {
  return useQuery<LearningObjectSnapshot[]>({
    queryKey: ['snapshots', 'list', { learningObjectDraftId }],
    queryFn: () =>
      listLearningObjectSnapshots({
        learningObjectDraftId: learningObjectDraftId as string
      }),
    enabled: !!learningObjectDraftId
  });
};

export const useRestoreSnapshot = ({ onError, onSuccess }: { onError: () => void; onSuccess: () => void }) => {
  return useMutation({
    mutationFn: (variables: { learningObjectSnapshotId?: string }) => restoreLearningObjectSnapshot(variables),
    onError,
    onSuccess
  });
};

export function useFetchSnapshotForAssignment({ assignmentId, enabled }: { assignmentId?: string; enabled?: boolean }) {
  return useQuery<LearningObjectSnapshot>({
    queryKey: ['snapshot', { assignmentId }],
    queryFn: () => getSnapshotForAssignment({ assignmentId: assignmentId as string }),
    enabled: !!assignmentId || enabled !== false
  });
}
