import * as Y from 'yjs';

import { removeContentObject } from '../widgetsSDK/contentObjectOperations';
import { findPositionInArray, getContentObjectsMap, validateYArray, validateYMap } from '../widgetsSDK/yjs';

export function removeSection(document: Y.Doc, sectionId: string) {
  const contentObjectsMap = getContentObjectsMap(document);
  const sectionData = validateYMap(contentObjectsMap.get(sectionId));

  const parentPageId = sectionData.get('parentId') as string | undefined;
  if (!parentPageId) {
    throw new Error('Section has no parent specified.');
  }

  const pageData = validateYMap(contentObjectsMap.get(parentPageId));
  const pageChildren = validateYArray(pageData.get('children'));
  const currentIndex = findPositionInArray(pageChildren, sectionId);

  removeContentObject(document, sectionId);
  pageChildren.delete(currentIndex);
}
