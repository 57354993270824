import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, props: { isOver?: boolean }) => {
  const { isOver } = props;
  return {
    menuIcons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: rem(16),
      paddingTop: rem(12),
      paddingRight: rem(10),
      marginTop: rem(8),
      borderTop: `${rem(1)} solid ${theme.white}`,
      '& > button[data-disabled]': {
        opacity: '0.4'
      },
      '& svg': {
        color: theme.colors.accent[5],
        cursor: 'pointer'
      }
    },
    dndItemIndicator: {
      height: rem(18),
      width: '100%',
      display: 'grid',
      placeItems: 'center'
    },
    dndItemIndicatorBar: {
      backgroundColor: isOver ? theme.colors.blue[5] : '',
      width: '100%',
      height: rem(8),
      borderRadius: '99px'
    },
    itemCard: {
      minWidth: rem(276),
      transition: 'background-color 250ms ease',
      borderRadius: rem(5),
      padding: rem(8),
      paddingLeft: rem(28),
      border: `1px solid ${theme.colors.gray[3]}`,
      cursor: 'pointer',
      backgroundColor: theme.colors.accent[0],
      '&:hover': {
        boxShadow: `1px 2px 5px 0px ${theme.colors.gray[4]}`,
        backgroundColor: theme.colors.accent[0]
      }
    },
    itemCardTop: {
      position: 'relative'
    },
    menuHandleIcon: {
      position: 'absolute',
      display: 'grid',
      placeItems: 'center',
      color: theme.colors.accent[4],
      right: rem(10),
      cursor: 'pointer'
    },
    numberOrder: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.white,
      borderRadius: '100%',
      backgroundColor: theme.colors.accent[6],
      width: rem(26),
      height: rem(26),
      lineHeight: rem(26),
      marginRight: rem(10)
    },
    questionBankCardTitle: {
      marginRight: rem(5)
    },
    emptyListWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      outline: '1px dashed gray',
      borderRadius: rem(5),
      height: '100%',
      minHeight: rem(50),
      position: 'relative',
      marginTop: rem(10)
    }
  };
});
