import { Flex, Loader } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import React, { ComponentType, forwardRef, useState } from 'react';

import { useStyles } from './UploadDropzone.styles';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { humanFileSize } from '@/utils/core.utils';
import { showErrorNotification } from '@/utils/notifications';
import { Placeholder } from '@/widgets/_components/widget/Placeholder';

type UploadDropzoneProps = {
  Icon: ComponentType;
  isReady: boolean;
  maxSize?: number;
  maxFiles?: number;
  accept?: string[];
  placeholderText: string;
  src: string;
  upload: UseMutateAsyncFunction<string | undefined, unknown, Blob, unknown>;
  dropAction: ({ src, type }: { type: string; src: string }) => void;
  dropError: string;
  className?: string;
};

// eslint-disable-next-line react/display-name
export const UploadDropzone = forwardRef((props: UploadDropzoneProps, uploadRef: React.Ref<() => void>) => {
  const {
    Icon,
    isReady,
    maxSize = 1_000_000,
    maxFiles = 1,
    accept,
    placeholderText,
    upload,
    dropAction,
    dropError,
    className
  } = props;

  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { classes, cx } = useStyles({ isRejected: rejectedFiles.length !== 0 });

  const t = useTranslations();

  const handleDrop = async (droppedFiles: FileWithPath[]) => {
    try {
      setRejectedFiles([]);
      setErrorMessage('');
      setIsLoading(true);
      const droppedFile = droppedFiles[0];
      const src = await upload(droppedFile);
      if (!src) {
        return;
      }

      dropAction({ type: droppedFile.type, src });
    } catch (e) {
      setErrorMessage(dropError);
    }

    setIsLoading(false);
  };

  const handleReject = (files: FileRejection[]) => {
    setRejectedFiles(files);
    let message = '';
    files.forEach(({ errors }) => {
      if (errors[0].code === 'file-too-large') {
        message = t('components.uploadFileActions.fileTooLargeMessage', { maxSize: humanFileSize(maxSize) });
      } else if (errors[0].code === 'file-invalid-type') {
        message = t('components.uploadFileActions.fileInvalidTypeMessage');
      } else if (errors[0].code === 'too-many-files') {
        message = t('components.uploadFileActions.tooManyFilesMessage', { count: maxFiles });
      } else {
        message = dropError;
      }
    });
    setErrorMessage(message);
    if (isReady) showErrorNotification(message, t('utils.notification.error'));
  };

  return (
    <Dropzone
      sx={{ display: isReady ? 'none' : 'auto' }}
      openRef={uploadRef}
      onDrop={handleDrop}
      onReject={handleReject}
      maxSize={maxSize}
      maxFiles={maxFiles}
      accept={accept}
      classNames={{ root: classes.dropzone, inner: classes.dropzoneInner }}
      className={cx(className, ThemeClassNames.dropzone)}
    >
      <Flex justify="center" align="center" direction="column" className={classes.dropzoneContent}>
        {rejectedFiles.length > 0 || errorMessage ? (
          <Placeholder Icon={Icon} text={errorMessage} isContentOnly={true} isError={true} />
        ) : (
          <Placeholder
            Icon={isLoading ? SmallLoader : Icon}
            text={placeholderText}
            subText={`${t('components.uploadFileActions.maxSize', { maxSize: humanFileSize(maxSize) })}`}
            isContentOnly={true}
            dataTestId={isLoading ? 'loader' : 'action-icon'}
          />
        )}
      </Flex>
    </Dropzone>
  );
});

const SmallLoader = (props: Record<string, unknown>) => <Loader size="sm" {...props} />;
