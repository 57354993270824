import { ManifestType, ManifestPreviews } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewTextData } from 'shared/widgetsSDK/Text/create';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import { manifestLocales } from './manifest.locales';
import BulletListEn from './resources/en/bulleted-list.svg';
import Header1En from './resources/en/h1.svg';
import Header2En from './resources/en/h2.svg';
import Header3En from './resources/en/h3.svg';
import Header4En from './resources/en/h4.svg';
import NumberedListEn from './resources/en/numbered-list.svg';
import BulletListHe from './resources/he/bulleted-list.svg';
import Header1He from './resources/he/h1.svg';
import Header2He from './resources/he/h2.svg';
import Header3He from './resources/he/h3.svg';
import Header4He from './resources/he/h4.svg';
import NumberedListHe from './resources/he/numbered-list.svg';

export const Manifest: ManifestType = {
  name: WidgetType.Text,
  previewGroups: [
    {
      id: 'h1',
      divider: false,
      previews: [
        {
          name: 'h1Preview',
          previewImage: {
            paths: {
              en: Header1En,
              he: Header1He
            },
            alt: { ...manifestLocales.h1.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<h1><span>${manifestLocales.h1.initialText[options?.locale ?? 'he']}</span></h1>`,
              textType: 'header',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'h2',
      divider: true,
      previews: [
        {
          name: 'h2Preview',
          previewImage: {
            paths: {
              en: Header2En,
              he: Header2He
            },
            alt: { ...manifestLocales.h2.previewImageAlt },
            size: {
              width: 285,
              height: 28
            }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<h2><span>${manifestLocales.h2.initialText[options?.locale ?? 'he']}</span></h2>`,
              textType: 'header',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'h3',
      divider: true,
      previews: [
        {
          name: 'h3Preview',
          previewImage: {
            paths: {
              en: Header3En,
              he: Header3He
            },
            size: {
              width: 285,
              height: 28
            },
            alt: { ...manifestLocales.h3.previewImageAlt }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<h3><span>${manifestLocales.h3.initialText[options?.locale ?? 'he']}</span></h3>`,
              textType: 'header',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'h4',
      divider: true,
      previews: [
        {
          name: 'h4Preview',
          previewImage: {
            paths: {
              en: Header4En,
              he: Header4He
            },
            size: {
              width: 285,
              height: 26
            },
            alt: { ...manifestLocales.h4.previewImageAlt }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<h4><span>${manifestLocales.h4.initialText[options?.locale ?? 'he']}</span></h4>`,
              textType: 'header',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'paragraph',
      divider: true,
      previews: [
        {
          name: 'paragraphPreview',
          previewImage: {
            paths: {
              en: 'https://res.cloudinary.com/dzfzjsmy8/image/upload/v1683723963/paragraph_dbgohk.svg',
              he: 'https://res.cloudinary.com/drhfy2pxb/image/upload/v1687178855/iuvspklbm19v3jbpshpi.svg'
            },
            size: {
              width: 285,
              height: 75
            },
            alt: { ...manifestLocales.paragraph.previewImageAlt }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<p>${manifestLocales.paragraph.initialText[options?.locale ?? 'he']}</p>`,
              textType: 'paragraph',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'bulletedList',
      divider: true,
      previews: [
        {
          name: 'bulletedListPreview',
          previewImage: {
            paths: {
              en: BulletListEn,
              he: BulletListHe
            },
            size: {
              width: 285,
              height: 50
            },
            alt: { ...manifestLocales.bulletedList.previewImageAlt }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<ul><li><span>${
                manifestLocales.bulletedList.initialText[options?.locale ?? 'he']
              }</span></li><li><span>${
                manifestLocales.bulletedList.initialText[options?.locale ?? 'he']
              }</span></li></ul>`,
              textType: 'paragraph',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    },
    {
      id: 'numberedList',
      divider: true,
      previews: [
        {
          name: 'numberedListPreview',
          previewImage: {
            paths: {
              en: NumberedListEn,
              he: NumberedListHe
            },
            size: {
              width: 285,
              height: 50
            },
            alt: { ...manifestLocales.numberedList.previewImageAlt }
          },
          initializeProps: options =>
            createNewTextData(options, {
              content: `<ol><li><span>${
                manifestLocales.numberedList.initialText[options?.locale ?? 'he']
              }</span></li><li><span>${
                manifestLocales.numberedList.initialText[options?.locale ?? 'he']
              }</span></li></ol>`,
              textType: 'paragraph',
              withParagraphOption: true
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
