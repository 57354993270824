import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useWorkMode } from './useWorkMode';
import { STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH } from '@/consts/paths';
import { parseQueryParam } from '@/utils/query.utils';

const VIEW_MODE_PARAM = 'viewMode';

export type ViewMode = 'teacher' | 'studentLearning' | 'studentTest' | 'studentAssigned';

export const useViewMode = () => {
  const { query, pathname, push } = useRouter();
  const { isPlayerAssignment, isPlayerAssignmentTest } = useWorkMode();
  const isLocked = pathname.includes(STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH);
  const hasStudentId = !!parseQueryParam(query.studentId);
  const queryParamViewMode = parseQueryParam(query.viewMode) as ViewMode | undefined;

  const defaultValue: ViewMode =
    isPlayerAssignment && hasStudentId ? 'studentAssigned' : isPlayerAssignmentTest ? 'studentTest' : 'studentLearning';

  const viewMode = isLocked ? 'teacher' : queryParamViewMode ?? defaultValue;

  const setViewMode = useCallback(
    (newViewMode: ViewMode) => {
      void push({
        pathname,
        query: {
          ...query,
          [VIEW_MODE_PARAM]: newViewMode
        }
      });
    },
    [pathname, push, query]
  );

  return [viewMode, setViewMode] as const;
};
