import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import { manifestLocales } from './manifest.locales';
import AudioEn from './resources/en/audio.svg';
import AudioHe from './resources/he/audio.svg';
import { AudioObject } from '@/icons/AudioObject';

export const Manifest: ManifestType = {
  name: WidgetType.Audio,
  previewGroups: [
    {
      id: 'audio',
      divider: {
        icon: AudioObject,
        title: 'audio'
      },
      previews: [
        {
          name: 'audioPreview',
          previewImage: {
            paths: {
              en: AudioEn,
              he: AudioHe
            },
            size: {
              width: 285,
              height: 140
            },
            alt: { ...manifestLocales.audio.previewImageAlt }
          },
          dimensions: {
            width: 75,
            height: 75
          },
          initializeProps: widgetsCreateFunctions[WidgetType.Audio] as ManifestPreviews['initializeProps']
        }
      ]
    }
  ],
  additionalData: {
    maxFileSizeBytes: 3_072_000,
    acceptFileType: ['audio/x-wav', 'audio/mpeg', 'audio/x-ms-wma'],
    maxFiles: 1,
    constraints: {
      minHeight: 75,
      minWidth: 75
    }
  }
};
