import { useDidUpdate } from '@mantine/hooks';
import { ViewMode } from '@storybook/types';
import isEqual from 'lodash/isEqual';
import { useEffect, useCallback, useMemo, useRef } from 'react';

import { calculateGrade } from 'shared/evaluation/calculateGrade';
import { FeedbackMessages, Feedbacks } from 'shared/evaluation/feedback';

import {
  useCanGiveAnswers,
  useCanPrefillCorrectAnswers,
  useCanPrefillEvaluation,
  useCanSeeQuestionEvaluation
} from '@/components/CanProvider';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';
import { EvaluationPayloadByWidget } from '@/queries/evaluation';
import { QuestionState } from '@/queries/state';

const defaultAnswers = {
  state: '',
  score: 0,
  answered: false
};
export const useEvaluatablePluginQuestionAnswers = (
  id: string,
  viewMode: ViewMode,
  feedback: `${Feedbacks}` | undefined,
  scoreWeight: number
) => {
  const [elementState, setElementState] = usePlayerElementState<
    Partial<QuestionState<EvaluationPayloadByWidget['EvaluatablePlugin']>> | undefined
  >(id);

  useEffect(() => {
    const handleLoad = (e: Event) => {
      const handleLoadEvent = new CustomEvent(`${PluginEvent.handleLoadEvent}-${id}`, {
        detail: { answersData: elementState?.answers }
      });
      document.dispatchEvent(handleLoadEvent);
    };
    document.addEventListener(`${PluginEvent.loadedEvent}-${id}`, handleLoad);
    return () => {
      document.removeEventListener(`${PluginEvent.loadedEvent}-${id}`, handleLoad);
    };
  }, [elementState, id]);

  const getFeedbackMessageType = () => {
    if (typeof elementState?.answers?.score === 'number') {
      if (
        elementState.answers.score === 0 &&
        elementState.answers.answered === false &&
        feedback !== Feedbacks.Automatic
      ) {
        return FeedbackMessages.NoAnswer;
      } else if (elementState.answers.score === 0 && elementState.answers.answered) {
        return FeedbackMessages.OnlyWrongAnswers;
      } else if (elementState.answers.score > 0 && elementState.answers.score < 100) {
        return FeedbackMessages.SomeCorrectAndSomeWrongAnswers;
      } else if (elementState.answers.score === 100) {
        return FeedbackMessages.OnlyAllCorrectAnswers;
      }
    } else {
      if (feedback !== Feedbacks.Automatic) {
        return FeedbackMessages.NoAnswer;
      }
      return null;
    }
  };

  const canPrefillCorrectAnswers = useCanPrefillCorrectAnswers();
  const canGiveAnswers = useCanGiveAnswers();
  const canPrefillEvaluation = useCanPrefillEvaluation();
  const canSeeQuestionEvaluation = useCanSeeQuestionEvaluation();

  const isCorrectAnswerShown = elementState?.showCorrectAnswers;

  const evaluatedQuestionFeedbackMessageType =
    elementState?.checked || (feedback === Feedbacks.Automatic && canSeeQuestionEvaluation)
      ? getFeedbackMessageType()
      : null;

  const feedbackMessageType = isCorrectAnswerShown
    ? canPrefillCorrectAnswers
      ? null
      : FeedbackMessages.ShowAnswer
    : evaluatedQuestionFeedbackMessageType ?? null;

  const grade = useMemo(
    () => calculateGrade(elementState?.answers?.score ?? 0, scoreWeight, feedbackMessageType),
    [elementState?.answers?.score, feedbackMessageType, scoreWeight]
  );

  const disabled = isCorrectAnswerShown || !canGiveAnswers;

  const evaluateAnswers = useCallback(() => {
    const checkQuestionEvent = new CustomEvent(`${PluginEvent.checkQuestionEvent}-${id}`);
    document.dispatchEvent(checkQuestionEvent);
  }, [id]);

  const handleAnswersStateChange = useCallback(
    (changedData: Record<string, unknown>) => {
      if (
        !isEqual(changedData.state, elementState?.answers?.state) &&
        feedback === Feedbacks.Automatic &&
        canSeeQuestionEvaluation
      ) {
        evaluateAnswers();
      }

      setElementState(currentState => ({
        ...currentState,
        answers: { ...(currentState?.answers ?? defaultAnswers), ...changedData },
        checked: false
      }));
    },
    [canSeeQuestionEvaluation, elementState?.answers?.state, evaluateAnswers, feedback, setElementState]
  );

  const handleAnswersCheckedChange = useCallback(
    (changedData: Record<string, unknown>) => {
      setElementState(currentState => ({
        ...currentState,
        answers: { ...(currentState?.answers ?? defaultAnswers), ...changedData },
        checked: true
      }));
    },
    [setElementState]
  );

  const handleAnswersDataChange = useCallback(
    (changedData: Record<string, unknown>) => {
      setElementState(currentState => ({
        ...currentState,
        answers: { ...(currentState?.answers ?? defaultAnswers), ...changedData }
      }));
    },
    [setElementState]
  );

  const shouldEvaluateAnswers = useMemo(
    () =>
      (feedback === Feedbacks.Automatic && canSeeQuestionEvaluation) ||
      (elementState?.checked && canSeeQuestionEvaluation),
    [canSeeQuestionEvaluation, elementState?.checked, feedback]
  );

  const isInitialized = useRef(false);
  useEffect(() => {
    // When the question is initialized, evaluate the answers if appropriate
    if (!elementState || isInitialized.current) {
      return;
    }

    if (canPrefillEvaluation || shouldEvaluateAnswers) {
      setElementState(currentState => ({
        ...currentState,
        checked: true
      }));

      isInitialized.current = true;
    }
  }, [canPrefillEvaluation, elementState, setElementState, shouldEvaluateAnswers]);

  useDidUpdate(() => {
    const viewModeChangeEvent = new CustomEvent(`${PluginEvent.viewModeChangeEvent}-${id}`, {
      detail: { answersData: elementState?.answers }
    });
    document.dispatchEvent(viewModeChangeEvent);
  }, [viewMode]);

  const handleResetAnswers = useCallback(() => {
    const resetQuestionEvent = new CustomEvent(`${PluginEvent.resetQuestionEvent}-${id}`);
    document.dispatchEvent(resetQuestionEvent);
    setElementState(currentState => ({
      answers: defaultAnswers,
      checked: false,
      showCorrectAnswers: false
    }));
  }, [id, setElementState]);

  const handleEvaluate = useCallback(() => {
    evaluateAnswers();
    setElementState(currentState => ({
      ...currentState,
      checked: true
    }));
  }, [evaluateAnswers, setElementState]);

  const handleShowAnswer = useCallback(async () => {
    const showQuestionAnswerEvent = new CustomEvent(`${PluginEvent.showQuestionAnswerEvent}-${id}`);
    document.dispatchEvent(showQuestionAnswerEvent);
    setElementState(currentState => ({
      ...currentState,
      checked: false,
      showCorrectAnswers: true
    }));
  }, [id, setElementState]);

  return {
    disabled,
    feedbackMessageType,
    grade,
    handleEvaluate,
    handleResetAnswers,
    handleShowAnswer,
    handleAnswersStateChange,
    handleAnswersCheckedChange,
    handleAnswersDataChange
  };
};
