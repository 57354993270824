import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    spacer: {
      height: '100%',
      width: '100%',
      outline: '1px dashed gray'
    }
  };
});
