import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSubmitPageComment } from 'shared/widgetsSDK/SubmitPageComment/create';

import { manifestLocales } from './manifest.locales';
import SubmitPageCommentEn from './resources/en/submitPageComment.svg';
import SubmitPageCommentHe from './resources/he/submitPageComment.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SubmitPageComment,
  previewGroups: [
    {
      id: 'SubmitPageComment',
      divider: false,
      previews: [
        {
          name: 'SubmitPageCommentPreview',
          previewImage: {
            paths: {
              en: SubmitPageCommentEn,
              he: SubmitPageCommentHe
            },
            alt: { ...manifestLocales.submitPageComment.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: createNewSubmitPageComment,
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
