import { ViewMode } from '@storybook/types';
import { useEffect } from 'react';

import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';

export function useEvaluatablePlayerEvents(
  id: string,
  viewMode: ViewMode,
  handleAnswersStateChange: (data: Record<string, unknown>) => void,
  handleAnswersCheckedChange: (data: Record<string, unknown>) => void,
  handleAnswersDataChange: (data: Record<string, unknown>) => void
) {
  useEffect(() => {
    const handleStateChanged = (e: Event) => {
      if (viewMode !== 'teacher') handleAnswersStateChange((e as CustomEvent).detail);
    };
    document.addEventListener(`${PluginEvent.answersStateChangeEvent}-${id}`, handleStateChanged);
    return () => {
      document.removeEventListener(`${PluginEvent.answersStateChangeEvent}-${id}`, handleStateChanged);
    };
  }, [handleAnswersStateChange, id, viewMode]);

  useEffect(() => {
    const handleCheckedChange = (e: Event) => {
      if (viewMode !== 'teacher') handleAnswersCheckedChange((e as CustomEvent).detail);
    };
    document.addEventListener(`${PluginEvent.answersCheckedChangeEvent}-${id}`, handleCheckedChange);
    return () => {
      document.removeEventListener(`${PluginEvent.answersCheckedChangeEvent}-${id}`, handleCheckedChange);
    };
  }, [handleAnswersCheckedChange, id, viewMode]);

  useEffect(() => {
    const handleDataChange = (e: Event) => {
      if (viewMode !== 'teacher') handleAnswersDataChange((e as CustomEvent).detail);
    };
    document.addEventListener(`${PluginEvent.answersScoreChangeEvent}-${id}`, handleDataChange);
    document.addEventListener(`${PluginEvent.answersAnsweredChangeEvent}-${id}`, handleDataChange);
    return () => {
      document.removeEventListener(`${PluginEvent.answersScoreChangeEvent}-${id}`, handleDataChange);
      document.removeEventListener(`${PluginEvent.answersAnsweredChangeEvent}-${id}`, handleDataChange);
    };
  }, [handleAnswersDataChange, id, viewMode]);
}
