import { HeaderGroup } from '@tanstack/react-table';

import { useStyles } from './Table.styles';
import { TableHeaderCell } from './TableHeaderCell';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { TRow } from '@/widgets/Table/utils';

type TableRowProps = {
  headerGroup: HeaderGroup<TRow>;
};

export function TableHeaderRow(props: TableRowProps) {
  const { headerGroup } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.tr, ThemeClassNames.widgets.table.headerRow)}>
      {headerGroup.headers.map(header => (
        <TableHeaderCell key={header.id} header={header} />
      ))}
    </div>
  );
}
