import { Button } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from './GoogleClassroomShareButton.styles';

type GoogleClassroomShareButtonProps = {
  assignmentRedirectUrl: string;
};

export const GoogleClassroomShareButton = (props: GoogleClassroomShareButtonProps) => {
  const { assignmentRedirectUrl } = props;

  const t = useTranslations('external-modules');

  const { classes } = useStyles();

  return (
    <Button
      className={classes.assignTaskButton}
      variant="filled"
      onClick={() =>
        window.open(`https://classroom.google.com/share?url=${assignmentRedirectUrl}`, 'popup', 'width=600, height=600')
      }
    >
      {t('lmsGoogle.share.title')}
    </Button>
  );
};
