import { ReactNode } from 'react';

import { WidgetErrorIndicator } from '@/components/WidgetErrorIndicator';
import { useWorkMode } from '@/utils/useWorkMode';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type OrchestrationWrapperProps = {
  children: ReactNode;
};

export function OrchestrationWrapper(props: OrchestrationWrapperProps) {
  const { children } = props;
  const [errors] = useContentObjectProperty<Record<string, Array<string>>>('errors', 'widgetsFormData');
  const { isStudioEdit } = useWorkMode();

  if (isStudioEdit) {
    return <WidgetErrorIndicator errors={errors}>{children}</WidgetErrorIndicator>;
  }

  return <>{children}</>;
}
