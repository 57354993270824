import { useCallback } from 'react';
import * as Y from 'yjs';

import { getSensitiveDataMap, useObservedProperty, validateYMap } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';
import { ClozeOption } from 'shared/types/ClozeQuestion';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useCorrectClozeOption(clozeId: string) {
  const document = useCurrentDocument();
  const [questionId] = useContentObjectProperty<string>('id');
  const sensitiveDataMap = getSensitiveDataMap(document);
  const selector = useCallback(
    (sensitiveData?: Y.Map<Y.Map<unknown>>) => {
      if (sensitiveData) {
        const selectCorrectOptions = validateYMap<Y.Map<ClozeOption>>(sensitiveData.get('selectCorrectOptions'));
        const correctOption = validateYMap<ClozeOption>(selectCorrectOptions.get(clozeId));
        return correctOption.toJSON() as ClozeOption;
      }
    },
    [clozeId]
  );

  const [correctOption] = useObservedProperty<ClozeOption | undefined>(sensitiveDataMap, questionId, {
    deepObserve: true,
    selector
  });

  return correctOption;
}
