import { useTranslations } from 'next-intl';

import { useCanSubmitFeedbackForAssignment } from '@/components/CanProvider';
import { useGetAssignmentStatus } from '@/queries/assignment';
import { useStyles } from '@/widgets/SubmitPageTitle/player/SubmitPageTitle.styles';

export function SubmitPageTitle() {
  const t = useTranslations('widgets');
  const { classes } = useStyles();
  const { isReturnedToFix } = useGetAssignmentStatus({ enabled: true });

  const canSubmitFeedbackForAssignment = useCanSubmitFeedbackForAssignment();

  return (
    <h2 className={classes.title}>
      <div className={classes.titleInner}>
        {t(
          `submitPageTitleWidget.${
            canSubmitFeedbackForAssignment ? 'submitTitleTeacher' : isReturnedToFix ? 'resubmitTitle' : 'submitTitle'
          }`
        )}
      </div>
    </h2>
  );
}
