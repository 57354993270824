const prefix = `hendrx_theme_`;

export const ThemeClassNames = {
  action_icon: `${prefix}action_icon`,
  button: `${prefix}button`,
  dropzone: `${prefix}dropzone`,
  navigationBar: {
    this: `${prefix}navigationBar`,
    buttonNext: `${prefix}navigationBar_buttonNext`,
    buttonPrev: `${prefix}navigationBar_buttonPrev`,
    buttonSubmit: `${prefix}navigationBar_buttonSubmit`,
    pageButton: `${prefix}navigationBar_pageButton`,
    pageNumber: `${prefix}navigationBar_pageNumber`
  },
  page: `${prefix}page`,
  scoreWeight: `${prefix}scoreWeight`,
  stage: `${prefix}stage`,

  widgets: {
    audio: {
      this: `${prefix}audioWidget`
    },

    video: {
      this: `${prefix}videoWidget`
    },

    embed: {
      this: `${prefix}embedWidget`
    },

    microsoftEmbed: {
      this: `${prefix}microsoftEmbedWidget`
    },

    pdfDocument: {
      this: `${prefix}pdfDocumentWidget`
    },

    image: {
      this: `${prefix}imageWidget`
    },

    interactivePoll: {
      this: `${prefix}interactivePollWidget`,
      answer: `${prefix}interactivePollWidget_answer`,
      label: `${prefix}interactivePollWidget_label`
    },

    interactiveQuiz: {
      this: `${prefix}interactiveQuizWidget`,
      answer: `${prefix}interactiveQuizWidget_answer`,
      label: `${prefix}interactiveQuizWidget_label`
    },

    text: {
      this: `${prefix}textWidget`
    },

    blockTitle: {
      this: `${prefix}blockTitle`
    },

    combinedTitle: {
      this: `${prefix}combinedTitle`
    },

    enclosureWithBlockTitle: {
      this: `${prefix}enclosureWithBlockTitle`
    },

    enclosureWithCombinedTitle: {
      this: `${prefix}enclosureWithCombinedTitle`
    },

    table: {
      this: `${prefix}tableWidget`,
      row: `${prefix}tableWidget_row`,
      headerRow: `${prefix}tableWidget_header_row`,
      cell: `${prefix}tableWidget_cell`,
      headerCell: `${prefix}tableWidget_header_cell`
    },

    openDiscussion: {
      this: `${prefix}openDiscussionWidget`
    },

    openQuestion: {
      this: `${prefix}openQuestionWidget`,
      answer: `${prefix}openQuestionWidget_answer`,
      label: `${prefix}openQuestionWidget_label`
    },

    selectionQuestion: {
      this: `${prefix}selectionQuestionWidget`,
      answer: `${prefix}selectionQuestionWidget_answer`,
      label: `${prefix}selectionQuestionWidget_label`
    },

    clozeQuestion: {
      this: `${prefix}clozeQuestionWidget`,
      answer: `${prefix}clozeQuestionWidget_answer`,
      label: `${prefix}clozeQuestionWidget_label`
    },

    fillInTheBlanksQuestion: {
      this: `${prefix}fillInTheBlanksQuestionWidget`,
      answer: `${prefix}fillInTheBlanksQuestionWidget_answer`,
      label: `${prefix}fillInTheBlanksQuestionWidget_label`,
      wordBank: `${prefix}fillInTheBlanksQuestionWidget_wordBank`
    },

    evaluatablePlugin: {
      this: `${prefix}evaluatablePluginWidget`,
      answer: `${prefix}evaluatablePluginWidget_answer`,
      label: `${prefix}evaluatablePluginWidget_label`
    },

    questionGroupList: {
      this: `${prefix}questionGroupListWidget`,
      description: `${prefix}questionGroupListWidget_description`,
      label: `${prefix}questionGroupListWidget_label`
    },

    flexSection: {
      this: `${prefix}flexSectionWidget`
    },

    submitPageUnansweredQuestions: {
      this: `${prefix}submitPageUnansweredQuestions`
    },

    submitPageComment: {
      this: `${prefix}submitPageCommentWidget`
    },

    submitPageReturnToFixSwitch: {
      this: `${prefix}submitPageReturnToFix`
    },

    submitPage: {
      this: `${prefix}submitPage`
    },

    submitPageSatisfactionSurvey: {
      this: `${prefix}submitPageSatisfactionSurvey`
    },

    submitPageTitle: {
      this: `${prefix}submitPageTitle`
    },

    summaryPageTaskSubmittedNotification: {
      this: `${prefix}summaryPageTaskSubmittedNotification`
    },

    summaryPageSubmissionHistory: {
      this: `${prefix}summaryPageSubmissionHistory`
    },

    summaryPage: {
      this: `${prefix}summaryPage`
    }
  }
};

function getObjectValuesDeep(obj: Record<string, unknown>): string[] {
  const strings = [];

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      strings.push(obj[key] as string);
    } else if (typeof obj[key] === 'object') {
      strings.push(...getObjectValuesDeep(obj[key] as Record<string, unknown>));
    }
  }

  return strings;
}

export const ThemeClassNamesStringList = getObjectValuesDeep(ThemeClassNames);
