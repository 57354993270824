import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import merge from 'lodash/merge';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { LearningObjectDraft, LearningObjectSnapshot } from 'shared/types/LearningObjects';
import { SoftDeleteOneRequest, SoftDeleteOneResponse } from 'shared/types/dto/LearningObjectDraft';

import {
  createLearningObjectDraft,
  duplicateLearningObjectDraftById,
  getLearningObjectDraftById,
  listLearningObjectDrafts,
  restoreLearningObjectDraft,
  setLearningObjectDraftTheme,
  softDeleteLearningObjectDraft,
  updateLearningObjectDraft
} from './learningObjectDraftApi';
import { getAllPublishedSnapshotIds, getLearningObjectSnapshotById } from '@/queries/learningObjectSnapshotApi';
import { useWorkMode } from '@/utils/useWorkMode';

export const useFetchLearningObjectDrafts = (deleted: boolean) => {
  return useQuery<LearningObjectDraft[]>({
    queryKey: ['learning-object-drafts', 'list', { deleted }],
    queryFn: () => listLearningObjectDrafts(deleted)
  });
};

export const useFetchPublishedIds = ({ enabled }: { enabled: boolean }) => {
  return useQuery<LearningObjectSnapshot[]>({
    queryKey: ['learning-object-snapshots', 'published'],
    queryFn: () => getAllPublishedSnapshotIds(),
    enabled
  });
};

export const useCreateLearningObjectDraft = ({ onSuccess }: { onSuccess: (data: LearningObjectDraft) => void }) => {
  const queryClient = useQueryClient();

  return useMutation<LearningObjectDraft, unknown, { language: string; type: LearningObjectTypes }, unknown>({
    mutationFn: variables => createLearningObjectDraft(variables),
    onSuccess: data => {
      void queryClient.invalidateQueries({ queryKey: ['learning-object-draft'] });
      onSuccess(data);
    }
  });
};

export const useLearningObjectDraft = (
  learningObjectDraftId?: string,
  { onSuccess }: { onSuccess?: (data: LearningObjectDraft) => void } = {}
) => {
  const { isPlayerAssignment } = useWorkMode();

  return useQuery<LearningObjectDraft>({
    queryKey: ['learning-object-draft', { learningObjectDraftId }],
    queryFn: () => getLearningObjectDraftById(learningObjectDraftId as string, isPlayerAssignment),
    enabled: !!learningObjectDraftId,
    onSuccess
  });
};

export const useLearningObjectSnapshot = (
  learningObjectSnapshotId?: string,
  { onSuccess }: { onSuccess?: (data: LearningObjectSnapshot) => void } = {}
) => {
  return useQuery<LearningObjectSnapshot>({
    queryKey: ['learning-object-snapshot', { learningObjectSnapshotId }],
    queryFn: () => getLearningObjectSnapshotById({ learningObjectSnapshotId }),
    enabled: !!learningObjectSnapshotId,
    onSuccess
  });
};

export const useRestoreLearningObjectDraft = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ learningObjectDraftId }: { learningObjectDraftId: string }) =>
      restoreLearningObjectDraft(learningObjectDraftId),
    onSuccess: (_result, variables) => {
      queryClient.setQueryData(['learning-object-drafts', 'list', { deleted: true }], oldData => {
        return (oldData as LearningObjectDraft[]).filter(
          learningObjectDraft => learningObjectDraft._id !== variables.learningObjectDraftId
        );
      });
    }
  });
};

export const useSoftDeleteLearningObjectDraft = ({
  onSuccess
}: { onSuccess?: (data: SoftDeleteOneResponse) => void } = {}) => {
  const queryClient = useQueryClient();
  return useMutation<SoftDeleteOneResponse, unknown, SoftDeleteOneRequest>({
    mutationFn: ({ learningObjectDraftId }) => softDeleteLearningObjectDraft(learningObjectDraftId),
    onSuccess: (result, variables) => {
      queryClient.setQueryData(['learning-object-drafts', 'list', { deleted: false }], oldData => {
        return (oldData as LearningObjectDraft[])?.filter(
          learningObjectDraft => learningObjectDraft._id !== variables.learningObjectDraftId
        );
      });

      if (onSuccess) {
        onSuccess(result);
      }
    }
  });
};

export const useDuplicateLearningObjectDraft = ({ onSuccess }: { onSuccess: (data: LearningObjectDraft) => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ learningObjectDraftId }: { learningObjectDraftId: string }) =>
      duplicateLearningObjectDraftById(learningObjectDraftId),
    onSuccess: data => {
      void queryClient.invalidateQueries({ queryKey: ['learning-object-draft'] });
      onSuccess(data);
    }
  });
};

export const useUpdateLearningObjectDraft = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      learningObjectDraftId,
      update
    }: {
      learningObjectDraftId: string;
      update: Parameters<typeof updateLearningObjectDraft>[1];
    }) => updateLearningObjectDraft(learningObjectDraftId, update),
    onMutate: async ({ learningObjectDraftId, update }) => {
      await queryClient.cancelQueries(['learning-object-draft', { learningObjectDraftId }]);

      const previousLearningObjectDraft = queryClient.getQueryData<LearningObjectDraft>([
        'learning-object-draft',
        { learningObjectDraftId }
      ]);

      queryClient.setQueryData(['learning-object-draft', { learningObjectDraftId }], currentData => {
        return merge({}, currentData, update);
      });

      queryClient.setQueryData(['tags'], (currentData: unknown) => {
        return merge([], currentData, update.metadata?.tags);
      });

      return { previousLearningObjectDraft };
    },
    onError: (error, { learningObjectDraftId }, context) => {
      queryClient.setQueryData(
        ['learning-object-draft', { learningObjectDraftId }],
        context?.previousLearningObjectDraft
      );
    },
    onSuccess: async (_, { learningObjectDraftId }) => {
      await queryClient.invalidateQueries({
        queryKey: ['learning-object-draft', { learningObjectDraftId }]
      });

      await queryClient.invalidateQueries({ queryKey: ['tags'] });
    }
  });
};

export const useSetLearningObjectDraftParentTheme = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      learningObjectDraftId,
      themeId
    }: {
      learningObjectDraftId: string;
      themeId: string;
      onMutate?: () => Promise<void>;
    }) => setLearningObjectDraftTheme(learningObjectDraftId, themeId),
    onMutate: async ({ learningObjectDraftId, themeId, onMutate }) => {
      // Optimistically update the query data
      queryClient.setQueryData(['learning-object-draft', { learningObjectDraftId }], oldData => {
        if (oldData) {
          return {
            ...oldData,
            themeId: themeId
          };
        }
        return oldData;
      });

      await onMutate?.();
    },
    onError: (error, { learningObjectDraftId }) => {
      // Rollback to the previous query data on error
      void queryClient.invalidateQueries(['learning-object-draft', { learningObjectDraftId }]);
    },
    onSettled: async (_, __, { learningObjectDraftId, themeId }) => {
      // Invalidate the query to fetch the latest data
      await queryClient.invalidateQueries(['learning-object-draft', { learningObjectDraftId }]);
      onSuccess?.();
    }
  });
};
