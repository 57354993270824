import { Stack } from '@mantine/core';
import { useTranslations } from 'next-intl';

import {
  DEFAULT_MARGIN_START,
  DEFAULT_MARGIN_END,
  DEFAULT_DIRECTION,
  DEFAULT_GAPS
} from 'shared/widgetsSDK/EnclosureWithBlockTitle/config';

import { Select } from '@/components/Select';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeControl } from '@/components/Theme/ThemeControl';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

const directionData = ['row', 'column'];

export function Config() {
  const t = useTranslations('components.theme.settings');

  const { id, document } = useContentObject();

  const [direction, setDirection] = useWidgetStyle<string>(document, id, 'dropzoneDirection');
  const [gap, setGap] = useWidgetStyle<number>(document, id, 'dropzoneGap');
  const [marginEnd, setMarginEnd] = useWidgetStyle<number>(document, id, 'dropzoneMarginEnd');
  const [marginStart, setMarginStart] = useWidgetStyle<number>(document, id, 'dropzoneMarginStart');

  return (
    <ThemeItemSection label={t('dropzone')}>
      <Stack>
        <NumberInputPanel
          label={t('marginStart')}
          value={marginStart ?? DEFAULT_MARGIN_START}
          onChange={setMarginStart}
          ResetButton={
            marginStart !== DEFAULT_MARGIN_START && <ResetButton onClick={() => setMarginStart(DEFAULT_MARGIN_START)} />
          }
        />
        <NumberInputPanel
          label={t('marginEnd')}
          value={marginEnd ?? DEFAULT_MARGIN_END}
          onChange={setMarginEnd}
          ResetButton={
            marginEnd !== DEFAULT_MARGIN_END && <ResetButton onClick={() => setMarginEnd(DEFAULT_MARGIN_END)} />
          }
        />
        <ThemeControl label={t('direction')}>
          <Select data={directionData} value={direction} onChange={value => value && setDirection(value)} />
          {direction !== DEFAULT_DIRECTION && <ResetButton onClick={() => setDirection(DEFAULT_DIRECTION)} />}
        </ThemeControl>
        <NumberInputPanel
          label={t('gaps')}
          value={gap ?? DEFAULT_GAPS}
          onChange={value => setGap(value)}
          ResetButton={gap != DEFAULT_GAPS && <ResetButton onClick={() => setGap(DEFAULT_GAPS)} />}
        />
      </Stack>
    </ThemeItemSection>
  );
}
