import TextAlign from '@tiptap/extension-text-align';

/**
 * This extension is used to always provide text-align style.
 * The basic implementation of TextAlign ignores values, which are equal to its
 * defaultAlignment making LTR/RTL unusable and vice versa.
 */
export const CustomTextAlign = TextAlign.extend({
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: element => element.style.textAlign || this.options.defaultAlignment,
            renderHTML: attributes => {
              return { style: `text-align: ${attributes.textAlign}` };
            }
          }
        }
      }
    ];
  }
});
