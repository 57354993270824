import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    wrapper: {
      width: rem(96),
      height: rem(36),
      border: `${rem(1)} solid ${theme.colors.systemDark[5]}`,
      borderRadius: rem(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${rem(6)} ${rem(14)}`,
      svg: {
        width: rem(14),
        height: rem(14)
      }
    },
    button: {
      width: rem(14),
      height: rem(14),
      color: theme.colors.systemDark[5],
      '&:hover': {
        color: 'inherit'
      }
    }
  };
});
