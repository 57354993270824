import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewFillInTheBlanksQuestionData } from 'shared/widgetsSDK/FillInTheBlanksQuestion/create';
import { manifestLocales } from 'shared/widgetsSDK/FillInTheBlanksQuestion/manifest.locales';

import FillInTheBlanksEn from './resources/en/fitb-question.svg';
import FillInTheBlanksHe from './resources/he/fitb-question.svg';
import { QuestionsFill } from '@/icons/QuestionsFill';

export const Manifest: ManifestType = {
  name: WidgetType.FillInTheBlanksQuestion,
  previewGroups: [
    {
      id: 'fillInTheBlanksQuestion',
      divider: {
        icon: QuestionsFill,
        title: 'fillInTheBlanksQuestion'
      },
      previews: [
        {
          name: 'fillInTheBlanksQuestionPreview',
          previewImage: {
            paths: {
              en: FillInTheBlanksEn,
              he: FillInTheBlanksHe
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.fillInTheBlanksQuestion.previewFillInTheBlanksQuestionAlt }
          },
          dimensions: {
            width: 1180,
            height: 'auto'
          },
          initializeProps: createNewFillInTheBlanksQuestionData
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
