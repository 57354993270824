import { ActionIconProps, Stack, StackProps } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { CSSProperties, MouseEventHandler, PropsWithChildren, ReactElement } from 'react';

import { StyledActionIcon } from '@/components/StyledActionIcon';

type DefaultStyles<T> = {
  onClick?: MouseEventHandler<T>;
  style?: CSSProperties;
  sx?: EmotionSx;
  className?: string;
};

type QuestionBaseButtonProps = {
  label: string;
  icon: ReactElement;
} & ActionIconProps;

export function QuestionBaseButton(props: QuestionBaseButtonProps) {
  const { icon, label, className, ...buttonProps } = props;

  return <StyledActionIcon className={className} aria-label={label} label={label} icon={icon} {...buttonProps} />;
}

type QuestionBaseButtonWrapperProps = {
  align?: CSSProperties['alignItems'];
  spacing?: StackProps['gap'];
} & PropsWithChildren &
  DefaultStyles<HTMLDivElement>;

const QuestionBaseButtonWrapper = (props: QuestionBaseButtonWrapperProps) => {
  const { children, align = 'center', spacing = 0, ...stackProps } = props;

  return (
    <Stack align={align} gap={spacing} {...stackProps}>
      {children}
    </Stack>
  );
};

QuestionBaseButton.Wrapper = QuestionBaseButtonWrapper;
