import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { manifestLocales } from 'shared/widgetsSDK/OpenQuestion/manifest.locales';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import OpenQuestionEn from './resources/en/open-question.svg';
import OpenQuestionHe from './resources/he/open-question.svg';
import { QuestionsCircles } from '@/icons/QuestionsCircles';

export const Manifest: ManifestType = {
  name: WidgetType.OpenQuestion,
  previewGroups: [
    {
      id: 'openQuestion',
      divider: {
        icon: QuestionsCircles,
        title: 'openQuestion'
      },
      previews: [
        {
          name: 'openQuestionPreview',
          previewImage: {
            paths: {
              en: OpenQuestionEn,
              he: OpenQuestionHe
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.openQuestion.previewOpenQuestionAlt }
          },
          dimensions: {
            width: 1180,
            height: 'auto'
          },
          initializeProps: widgetsCreateFunctions[WidgetType.OpenQuestion] as ManifestPreviews['initializeProps']
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
