import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type SubmissionHistoryStylesProps = {
  isExpanded?: boolean;
};

export const useStyles = createStyles((theme, props: SubmissionHistoryStylesProps) => {
  const { isExpanded } = props;

  return {
    collapseWrapper: {
      margin: `0 ${rem(52)}`,
      gap: rem(8)
    },
    collapseText: {
      fontFamily: `${theme.other.navigationBar.fontFamily} !important`,
      color: theme.colors.accent[6],
      textAlign: 'center',
      fontSize: rem(20),
      fontWeight: 500,
      lineHeight: '104%'
    },
    chevronIcon: {
      transform: `rotate(${isExpanded ? '0deg' : '180deg'})`
    },
    entryWrapper: {
      gap: rem(8),
      paddingTop: rem(8)
    },
    contentWrapper: {
      borderRadius: rem(8),
      background: theme.colors.accent[0],
      gap: rem(4),
      padding: `${rem(8)} ${rem(12)}`,
      width: '100%'
    },
    username: {
      fontFamily: `${theme.other.navigationBar.fontFamily} !important`,
      fontSize: rem(16),
      fontWeight: 600,
      lineHeight: '104%'
    },
    state: {
      fontFamily: `${theme.other.navigationBar.fontFamily} !important`,
      fontSize: rem(16),
      fontWeight: 500,
      lineHeight: '104%'
    },
    comment: {
      span: {
        fontFamily: `${theme.other.navigationBar.fontFamily} !important`
      },
      fontSize: rem(22),
      lineHeight: '120%'
    }
  };
});
