import { Box, Flex, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import cx from 'clsx';
import { useTranslations } from 'next-intl';
import { MutableRefObject, ReactNode, useMemo } from 'react';

import * as classes from './DropzonePlaceholder.css';
import { DropzonePlaceholderIcon } from '@/icons/DropzonePlaceholder';
import { useStore } from '@/store';
import { useDragInfo } from '@/utils/useDragInfo';
import { useOnDropAction } from '@/widgets/FlexSection/hooks/useOnDropAction';
import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

const toolboxConfigMap = {
  learningItems: {
    selectedCategory: 'learningItems',
    selectedSubCategory: 'questions'
  },
  contentItems: {
    selectedCategory: 'contentItems',
    selectedSubCategory: 'text'
  }
} as const;

type ToolboxItemsType = 'learningItems' | 'contentItems';

type DropzonePlaceholderProps = {
  type: ToolboxItemsType;
  sectionRef?: MutableRefObject<HTMLDivElement | null>;
  sectionWidth?: number;
  color?: 'gray' | 'blue';
  direction?: 'row' | 'column';
  size?: 'normal' | 'large';
  subtitle?: ReactNode | null | undefined;
  title?: ReactNode | null | undefined;
};

export const DropzonePlaceholder = (props: DropzonePlaceholderProps) => {
  const { type, subtitle, title, size = 'normal', color = 'gray', direction } = props;
  const { ref: sectionRef, width: sectionWidth } = useElementSize();
  const { drop, isOver } = useOnDropAction(
    props.sectionRef || sectionRef,
    props.sectionWidth || sectionWidth,
    FlexSectionDirection.column
  );

  const updateToolbox = useStore(state => state.updateToolbox);

  const openLearningItems = () => {
    updateToolbox({ ...toolboxConfigMap[type], opened: true });
  };

  const { isDragging } = useDragInfo();
  const t = useTranslations('components.dropzonePlaceholder');

  const placeholderDirection = direction ?? type === 'learningItems' ? 'column' : 'row';

  const titleTexts: Record<ToolboxItemsType, string> = useMemo(
    () => ({ contentItems: t('contentItemsHeader'), learningItems: t('learningItemsHeader') }),
    [t]
  );

  return (
    <>
      {isDragging && (
        <>
          <Box ref={drop} className={classes.dndWrapper} />
          <Box
            className={cx({
              [classes.dndItemIndicatorContainer.base]: !isOver,
              [classes.dndItemIndicatorContainer.over]: isOver && size === 'normal',
              [classes.dndItemIndicatorContainer.overLarge]: isOver && size === 'large'
            })}
          >
            <Box className={isOver ? classes.dndItemIndicatorBar.over : classes.dndItemIndicatorBar.base} />
          </Box>
        </>
      )}
      {isOver ? null : (
        <Box
          className={cx({
            [classes.placeholder.normal]: size === 'normal',
            [classes.placeholder.large]: size === 'large',
            [classes.placeholder.gray]: color === 'gray',
            [classes.placeholder.blue]: color === 'blue'
          })}
          onClick={openLearningItems}
        >
          <Flex direction={placeholderDirection} align="center" justify="center" gap={8}>
            <DropzonePlaceholderIcon
              className={cx(classes.placeholderIcon[placeholderDirection], classes.placeholderIcon[color])}
            />
            <Box>
              <Text ta="center" fw="bold">
                {title !== undefined ? title : titleTexts[type]}
              </Text>
              {subtitle !== undefined
                ? subtitle
                : type === 'learningItems' && <Text ta="center">{t('learningItemsInstruction')}</Text>}
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};
