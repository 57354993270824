import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { GAP_WIDTH } from '@/widgets/_components/dnd/dnd.utils';

type Props = {
  amountOfColumns: number;
  colMaxWidth: number;
  dense?: boolean;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { amountOfColumns, colMaxWidth, dense } = props;

  const isSingleColumnLayout = amountOfColumns === 1;

  return {
    dndGrid: {
      display: 'grid',
      gridTemplateColumns: isSingleColumnLayout
        ? 'minmax(0,1fr)'
        : `repeat(${amountOfColumns}, ${rem(GAP_WIDTH)} minmax(0,${rem(colMaxWidth)})) ${rem(GAP_WIDTH)}`,
      columnGap: 0,
      rowGap: isSingleColumnLayout ? 0 : rem(24),
      marginInline: isSingleColumnLayout && !dense ? rem(GAP_WIDTH) : undefined,
      gridAutoRows: 'max-content'
    }
  };
});
