import { useRouter } from 'next/router';

import { parseQueryParam } from './query.utils';
import { useWorkMode } from './useWorkMode';
import { useFetchLatestSnapshotByDraftId, useFetchSnapshotForAssignment } from '@/queries/snapshot';

export const useLearningObjectIds = () => {
  const router = useRouter();
  const { isPlayerAssignment } = useWorkMode();

  const assignmentId = parseQueryParam(router.query.assignmentId);
  const learningObjectDraftId = parseQueryParam(router.query.learningObjectDraftId);
  const learningObjectSnapshotIdFromQuery = parseQueryParam(router.query.learningObjectSnapshotId);

  const fetchSnapshotForAssignment = useFetchSnapshotForAssignment({
    assignmentId,
    enabled: isPlayerAssignment
  });

  const fetchLatestSnapshotByDraftId = useFetchLatestSnapshotByDraftId({
    learningObjectDraftId,
    enabled: !learningObjectSnapshotIdFromQuery
  });

  if (isPlayerAssignment) {
    return {
      assignmentId,
      learningObjectSnapshotId: fetchSnapshotForAssignment.data?._id,
      learningObjectDraftId: fetchSnapshotForAssignment.data?.learningObjectDraft,
      isLoading: fetchSnapshotForAssignment.isLoading
    };
  }

  return { learningObjectSnapshotId: fetchLatestSnapshotByDraftId.data?._id, learningObjectDraftId, isLoading: false };
};
