import { createStyles } from '@mantine/emotion';

import { configWidth } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  return {
    wrapper: {
      width: configWidth
    }
  };
});
