import { Node } from '@tiptap/pm/model';
import { Editor, EditorEvents } from '@tiptap/react';
import { useCallback, useEffect, useState } from 'react';
import * as Y from 'yjs';

export type NodeHistoryData<HistoryDataValue = unknown> = { key: string; value: HistoryDataValue };

type UseCustomNodeUpdateProps<HistoryDataValue> = {
  editor: Editor | null;
  nodeName: string;
  attributeKey: string;
  onRemove: (nodeIds: string[]) => NodeHistoryData<HistoryDataValue>[];
  onAdd: (historyData: NodeHistoryData<HistoryDataValue>[]) => void;
};

/**
 * A hook to manage undo/redo updates for a custom node integrated within a document fragment.
 * The hook will manage the history for custom nodes based on the integration with content object data.
 * Whenever a custom node is removed from the document fragment, it's removed from the content object data as well.
 * Whenever a custom node is added back to the document (e.g., through undo/redo operations), it's added back to the content object data.
 *
 * @param {Editor|null} props.editor - The editor instance.
 * @param {string} props.nodeName - The name of the node. (node.type.name)
 * @param {string} props.attributeKey - The key of the node attribute. (node.attrs[attributeKey])
 * @param {Function} props.onRemove - Callback that handles node removal. Should return an array of history data to be stored for each removed node. Ensure to pass cloned values of the nodes before deleting them.
 * @param {Function} props.onAdd - Callback that handles adding nodes back. Should be used to restore the nodes using the history data provided.
 *
 * @template HistoryDataValue The type of value stored within the NodeHistoryData.
 */

export function useCustomNodeHistoryIntegration<HistoryDataValue = unknown>(
  props: UseCustomNodeUpdateProps<HistoryDataValue>
) {
  const { editor, nodeName, attributeKey, onRemove, onAdd } = props;

  const [deletedNodesMap] = useState<Y.Map<HistoryDataValue>>(() => {
    const doc = new Y.Doc();
    return doc.getMap('root');
  });

  const handleRemoveNodes = useCallback(
    (nodeIds: string[]) => {
      const historyData = onRemove(nodeIds);
      historyData.forEach(({ key, value }) => {
        if (!value) return;
        deletedNodesMap.set(key, value);
      });
    },
    [onRemove, deletedNodesMap]
  );

  const handleAddNodes = useCallback(
    (nodeIds: string[]) => {
      const deletedNodeMaps: NodeHistoryData<HistoryDataValue>[] = nodeIds.map(nodeId => ({
        key: nodeId,
        value: cloneIfPossible(deletedNodesMap.get(nodeId)) as HistoryDataValue
      }));
      nodeIds.forEach(nodeId => deletedNodesMap.delete(nodeId));

      onAdd(deletedNodeMaps);
    },
    [onAdd, deletedNodesMap]
  );

  useEffect(() => {
    const onUpdate = ({ editor, transaction }: EditorEvents['update']) => {
      if (!transaction.docChanged) return;

      const affectedNodeIds = getAffectedNodeIds(transaction, nodeName, attributeKey);

      const currentNodeIds = getCurrentNodeIds(editor.state.doc, nodeName, attributeKey);

      // Handle removed nodes
      const removedNodeIds = affectedNodeIds.filter(affectedNodeId => !currentNodeIds.includes(affectedNodeId));
      if (removedNodeIds.length > 0) {
        handleRemoveNodes(removedNodeIds);
      }

      // Handle added nodes from the deleted nodes map (undo/redo)
      const addedNodeIds = Array.from(deletedNodesMap.keys()).filter(nodeId => currentNodeIds.includes(nodeId));
      if (addedNodeIds.length > 0) {
        handleAddNodes(addedNodeIds);
      }
    };

    editor?.on('update', onUpdate);

    return () => {
      editor?.off('update', onUpdate);
    };
  }, [attributeKey, deletedNodesMap, editor, handleAddNodes, handleRemoveNodes, nodeName]);
}

const getAffectedNodeIds = (
  transaction: EditorEvents['update']['transaction'],
  nodeName: string,
  attributeKey: string
) => {
  const affectedNodeIds: string[] = [];
  transaction.steps.forEach(step => {
    const stepMap = step.getMap();
    stepMap.forEach((oldStart, oldEnd) => {
      transaction.before.nodesBetween(oldStart, oldEnd, node => {
        if (node.type.name === nodeName) {
          affectedNodeIds.push(node.attrs[attributeKey]);
        }
      });
    });
  });
  return affectedNodeIds;
};

const getCurrentNodeIds = (doc: Node, nodeName: string, attributeKey: string) => {
  const currentNodeIds: string[] = [];
  doc.descendants(node => {
    if (node.type.name === nodeName) {
      currentNodeIds.push(node.attrs[attributeKey]);
    }
  });
  return currentNodeIds;
};

const cloneIfPossible = (value: unknown) => {
  if (value instanceof Y.Map) {
    return value.clone();
  }
  return value;
};
