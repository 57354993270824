import { ReactElement, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

type TiptapClozeOptionsProviderProps = {
  children: ReactElement;
};

type TiptapClozeOptionsStore = {
  selectedClozeId: string | null;
  setSelectedClozeId: (selectedClozeId: string | null) => void;
};

const TiptapClozeOptionsStoreContext = createContext<StoreApi<TiptapClozeOptionsStore> | null>(null);

export function TiptapClozeOptionsProvider(props: TiptapClozeOptionsProviderProps) {
  const { children } = props;

  const tiptapClozeOptionsStore = useMemo(
    () =>
      createStore<TiptapClozeOptionsStore>(set => ({
        selectedClozeId: null,
        setSelectedClozeId: selectedClozeId => set({ selectedClozeId })
      })),
    []
  );

  return (
    <TiptapClozeOptionsStoreContext.Provider value={tiptapClozeOptionsStore}>
      {children}
    </TiptapClozeOptionsStoreContext.Provider>
  );
}

export function useTiptapClozeOptionsStore<T = TiptapClozeOptionsStore>(
  selector: (state: TiptapClozeOptionsStore) => T
) {
  const store = useContext(TiptapClozeOptionsStoreContext);
  if (store === null) throw new Error('Context setup is incorrect');
  return useStore(store, selector);
}
