import '@mantine/core/styles.layer.css';
import '@mantine/dropzone/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import '@mantine/tiptap/styles.layer.css';
import './global.css';

import '@/clientModules';

import { clientModules } from '@hendrx/modules';
import { Box, DirectionProvider, MantineProvider } from '@mantine/core';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';
import { Notifications } from '@mantine/notifications';
import { Hydrate, QueryClient, QueryClientProvider, QueryKey } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistedClient, Persister, persistQueryClient } from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import App, { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { IntlError, IntlErrorCode, NextIntlClientProvider } from 'next-intl';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { CSSVarsValuesProvider } from '@/components/CSSVarsValuesProvider';
import { CanProvider } from '@/components/CanProvider';
import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { WidgetComponentProvider } from '@/components/WidgetComponentProvider';
import { YjsProvider } from '@/components/YjsProvider';
import { LOGIN_PATH, UNAUTHORIZED_PATH } from '@/consts/paths';
import { emotionCache } from '@/emotion/cache';
import { theme } from '@/utils/theme.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';

const AuthProvider = clientModules.authentication.AuthProvider || Fragment;
const XApiProvider = clientModules.xAPIEvents.XApiProvider || Fragment;

const getGtmScript = (gtmCode: string) =>
  gtmCode
    ? `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmCode}');
`
    : '';

function onTranslationError(error: IntlError) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.debug(error.message);
  } else {
    throw error;
  }
}

export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    }
  } as Persister;
}

const defaultQueryFn = async ({ queryKey }: { queryKey: QueryKey }) => {
  const host = process.env.NODE_ENV === 'development' ? 'http://localhost:4200/api' : '';
  const response = await fetch(`${host}/${queryKey[0]}`);

  if (!response.ok) {
    throw new Error('Error', { cause: response });
  }

  return await response.json();
};

type CustomAppProps = AppProps & {
  gtmCode: string;
  defaultTimeZone: string;
};

function CustomApp({ Component, pageProps, gtmCode, defaultTimeZone }: CustomAppProps) {
  const { replace, locale } = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryFn: defaultQueryFn,
            refetchOnWindowFocus: false,
            onError: error => {
              if (error instanceof Error) {
                if (error.cause instanceof Response) {
                  const redirectTo = new URLSearchParams(location.search).get('redirectTo');
                  const currentPath = location.pathname;
                  if (error.cause?.status === 401) {
                    void replace(`${LOGIN_PATH}?redirectTo=${redirectTo ?? currentPath}`);
                  }
                  if (error.cause?.status === 403) {
                    void replace(`${UNAUTHORIZED_PATH}?redirectTo=${redirectTo ?? currentPath}`);
                  }
                }
              }
            }
          }
        }
      })
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Setup persistence only on the client-side
      const persister = createIDBPersister();
      return void persistQueryClient({
        queryClient,
        persister,
        maxAge: 1000 * 60 * 60 * 24, // 24 hours
        buster: '',
        hydrateOptions: undefined,
        dehydrateOptions: undefined
      });
    }
  }, [queryClient]);

  const { uiLanguage, uiDirection } = useUiLanguage();
  const mantineTheme = useMemo(
    () => ({
      ...theme,
      other: clientModules.ui.defaultActivityThemeStyles
    }),
    []
  );

  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {getGtmScript(gtmCode)}
      </Script>
      <QueryClientProvider client={queryClient}>
        <NextIntlClientProvider
          onError={onTranslationError}
          timeZone={defaultTimeZone}
          locale={locale ?? 'en'}
          messages={pageProps.messages}
        >
          <AuthProvider>
            <XApiProvider>
              <CanProvider>
                <Hydrate state={pageProps.dehydratedState}>
                  <DirectionProvider detectDirection={false}>
                    <MantineEmotionProvider cache={emotionCache}>
                      <MantineProvider theme={mantineTheme} stylesTransform={emotionTransform}>
                        <YjsProvider>
                          <WidgetComponentProvider>
                            <CSSVarsValuesProvider>
                              <DndAllowedItemsContextProvider>
                                <Notifications dir={uiDirection} />
                                {/*
                          We want the main container to take the screen size on first load.
                          Setting it to 100dv... creates a small unwanted scrollbar.
                        */}
                                <Box
                                  style={{ minWidth: '90dvw', minHeight: '90dvh' }}
                                  dir={uiDirection}
                                  sx={{
                                    '*': {
                                      direction: uiDirection
                                    }
                                  }}
                                  data-testid="page"
                                >
                                  <Component {...pageProps} locale={uiLanguage} />
                                </Box>
                              </DndAllowedItemsContextProvider>
                            </CSSVarsValuesProvider>
                          </WidgetComponentProvider>
                        </YjsProvider>
                      </MantineProvider>
                    </MantineEmotionProvider>
                  </DirectionProvider>
                </Hydrate>
              </CanProvider>
            </XApiProvider>
          </AuthProvider>
        </NextIntlClientProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
  const context = await App.getInitialProps(appContext);

  return {
    ...context,
    gtmCode: process.env.GTM_TAG,
    defaultTimeZone: process.env.DEFAULT_TIMEZONE ?? 'Asia/Jerusalem'
  };
};

export default CustomApp;
