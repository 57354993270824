import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSpacerData } from 'shared/widgetsSDK/Spacer/create';

import { manifestLocales } from './manifest.locales';
import SpacerEn from './resources/en/spacer.svg';
import SpacerHe from './resources/he/spacer.svg';
import { SpacerObject } from '@/icons/SpacerObject';

export const Manifest: ManifestType = {
  name: WidgetType.Spacer,
  previewGroups: [
    {
      id: 'spacer',
      divider: {
        icon: SpacerObject,
        title: 'spacer'
      },
      previews: [
        {
          name: 'spacerPreview',
          previewImage: {
            paths: {
              en: SpacerEn,
              he: SpacerHe
            },
            size: {
              width: 285,
              height: 140
            },
            alt: { ...manifestLocales.spacer.previewImageAlt }
          },
          dimensions: {
            width: 1180,
            height: 24
          },
          initializeProps: () => createNewSpacerData()
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 24,
      minWidth: 24
    }
  }
};
