import { clientModules } from '@hendrx/modules';
import { ActivityThemeStyles } from '@hendrx/modules/ui';
import { MantineTheme } from '@mantine/core';
import { useMemo } from 'react';

import { DEFAULT_THEME } from './_mantineClone/defaultTheme';
import { deepMerge, mergeMantineTheme } from './_mantineClone/mergeMantineTheme';
import { themeToStyles } from './themeToStyles';
import { theme } from '@/utils/theme.utils';

export const useThemeStyles = (currentActivityTheme: ActivityThemeStyles) => {
  return useMemo(
    () =>
      themeToStyles(
        mergeMantineTheme(DEFAULT_THEME, {
          ...theme,
          other: deepMerge(clientModules.ui.defaultActivityThemeStyles, currentActivityTheme)
        }) as MantineTheme & {
          other: ActivityThemeStyles;
        }
      ),
    [currentActivityTheme]
  );
};
