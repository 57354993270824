import { useEffect } from 'react';

import { StageElement } from '@/contentObjects/Plugin';
import { useDataAttributes } from '@/contentObjects/Plugin/hooks/useDataAttributes';
import { usePlayerEvents } from '@/contentObjects/Plugin/hooks/usePlayerEvents';
import { useGetPluginById } from '@/queries/plugin';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function Player(props: StudioProps) {
  const { initialize } = props;

  useEffect(() => {
    initialize?.({
      isResizableVertically: true,
      shouldKeepAspectRatio: true
    });
  }, [initialize]);

  const [id] = useContentObjectProperty<string>('id');
  const [pluginId] = useContentObjectProperty<string>('pluginId');
  const { data } = useGetPluginById(pluginId);

  const { contentLanguage, uiLanguage, isStudioEdit, viewMode, workMode, assignmentMode, role } = useDataAttributes();

  usePlayerEvents(id, viewMode);

  if (!data) {
    return null;
  }

  return (
    <>
      <StageElement
        id={id}
        script={data.script}
        isStudioEdit={isStudioEdit}
        viewMode={viewMode}
        contentLanguage={contentLanguage}
        uiLanguage={uiLanguage}
        workMode={workMode}
        assignmentMode={assignmentMode}
        role={role}
        isQuestion={false}
      />
    </>
  );
}
