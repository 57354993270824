import { Box, Flex, Portal } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { Dispatch, RefObject, SetStateAction, useEffect, useRef } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import { useUser } from '@/queries/user';
import { AnswerCard } from '@/widgets/OpenDiscussion/player/AnswerCard';
import { AnswerField } from '@/widgets/OpenDiscussion/player/AnswerField';
import { QuestionHeader } from '@/widgets/OpenDiscussion/player/QuestionHeader';
import * as classes from '@/widgets/OpenDiscussion/player/StudentView/StudentView.css';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type StudentViewProps = {
  answers: OpenDiscussionAnswer[];
  deleteAnswer: (id: string) => void;
  editAnswerId: string | null;
  handleCancelEditMode: () => void;
  handleEdit: () => Promise<void>;
  handleImageDelete: () => void;
  handleOpenEditMode: (answer: OpenDiscussionAnswer) => void;
  handleSend: () => Promise<void>;
  image: File | string | null;
  isLoading: boolean;
  resetRef: RefObject<() => void>;
  setImage: Dispatch<SetStateAction<string | File | null>>;
  setText: Dispatch<SetStateAction<string>>;
  text: string;
};

export function StudentView(props: StudentViewProps) {
  const {
    answers,
    deleteAnswer,
    editAnswerId,
    handleCancelEditMode,
    handleEdit,
    handleImageDelete,
    handleOpenEditMode,
    handleSend,
    image,
    isLoading,
    resetRef,
    setImage,
    setText,
    text
  } = props;

  const { user } = useUser();

  const [enabledPictureUpload] = useContentObjectProperty<boolean>('enabledPictureUpload');
  const [enabledMultiplePosts] = useContentObjectProperty<boolean>('enabledMultiplePosts');

  const isAnswersLimited = !enabledMultiplePosts && answers.filter(({ userId }) => userId === user?.id).length >= 1;

  const previousAnswersLengthRef = useRef(answers.length);
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement, HTMLDivElement>();

  useEffect(() => {
    // Scroll only when the answer is added. Don't scroll when the user edited/deleted an answer.
    if (answers.length > previousAnswersLengthRef.current) {
      scrollIntoView();
    }
    previousAnswersLengthRef.current = answers.length;
  }, [answers.length, scrollIntoView]);

  return (
    <Flex direction="column" className={classes.studentViewContainer}>
      <QuestionHeader isStudentView />

      <Flex ref={scrollableRef} direction="column" align="center" gap={12} mx={12} className={classes.answersContainer}>
        {answers.map((answer, index) => (
          <AnswerCard
            key={answer.id}
            answer={answer}
            onDelete={deleteAnswer}
            onEdit={() => handleOpenEditMode(answer)}
            isStudent
            studentScrollTargetRef={index === answers.length - 1 ? targetRef : undefined}
          />
        ))}
      </Flex>

      <Portal>
        <Box pos="absolute" w="100%" bottom={0} px="sm" py={6} bg="white">
          <AnswerField
            editAnswerId={editAnswerId}
            enabledPictureUpload={enabledPictureUpload}
            handleCancelEditMode={handleCancelEditMode}
            handleEdit={handleEdit}
            handleImageDelete={handleImageDelete}
            handleSend={handleSend}
            image={image}
            isAnswersLimited={isAnswersLimited}
            isLoading={isLoading}
            resetRef={resetRef}
            setImage={setImage}
            setText={setText}
            text={text}
          />
        </Box>
      </Portal>
    </Flex>
  );
}
