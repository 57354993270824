import { CollaborativeState, getCollaborativeState, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

export const useCollaborativeQuestionAnswers = (questionId: string) => {
  const document = useCurrentDocument();
  const [allQuestionsAnswers = {}] = useObservedProperty<CollaborativeState['questionsAnswers']>(
    getCollaborativeState(document),
    'questionsAnswers'
  );

  return allQuestionsAnswers[questionId] ?? {};
};
