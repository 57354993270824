import * as Y from 'yjs';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { ManifestInitializedProps } from 'shared/types/ManifestType';
import { initializeYjsDocumentStructure } from 'shared/utils/initializeYjsDocumentStructure';
import { createNewSectionData } from 'shared/widgetsSDK/Section/create';
import { createContentObject } from 'shared/widgetsSDK/contentObjectOperations';
import { getContentObjectsMap, validateYArray } from 'shared/widgetsSDK/yjs';

import { addPage } from '@/contentObjects/Root/pageUtils';

export const bootstrapYDocument = (
  contentObjectsToCreate: ManifestInitializedProps,
  learningObjectType = LearningObjectTypes.LearningActivity
) => {
  const document = new Y.Doc();
  initializeYjsDocumentStructure(document);

  // create pages array with one page
  const rootMap = document.getMap('root');
  rootMap.set('children', new Y.Array());
  const pageId = addPage(document, learningObjectType);

  // create widget's content objects
  contentObjectsToCreate.forEach(({ id, contentObjectData }) => createContentObject(document, id, contentObjectData));
  const { id: widgetId, layout } = contentObjectsToCreate[0];

  // create section and add it to the page
  const [newSectionData, newSectionId] = createNewSectionData(pageId, [{ id: widgetId, ...layout }]);
  createContentObject(document, newSectionId, newSectionData);
  const pageData = getContentObjectsMap(document).get(pageId);
  validateYArray(pageData?.get('children')).insert(0, [newSectionId]);

  return document;
};
