import * as Y from 'yjs';

import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';
import { BlockTitleConfig } from '../questionConfigs';

export function createNewBlockTitleData(
  options: InitializePropsOptions,
  initializationConfig?: BlockTitleConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { content, style } = initializationConfig ?? {};

  const fragment = htmlToXmlFragment(content ?? '');

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.BlockTitle],
        ['textType', 'header'],
        ['children', new Y.Array()],
        ['fragment', fragment],
        ['placeholder', ''],
        ['style', new Y.Map(Object.entries(style ?? {}))]
      ])
    }
  ];
}
