import { useCallback } from 'react';
import * as Y from 'yjs';

import { HorizontalLayout } from 'shared/types/Layout';
import { checkIfHasEnoughSpace, removeItemFromLayout } from 'shared/utils/layoutHelpers';
import { WidgetType } from 'shared/utils/widgets';
import { validateYMap } from 'shared/widgetsSDK/yjs';

import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useChangeChildLayout() {
  const { data: sectionData } = useContentObject();
  const [sectionLayout] = useContentObjectProperty<Y.Map<unknown>>('layout');

  return useCallback(
    (id: string, parentId: string, newChildLayout: HorizontalLayout) => {
      const { height, column, span, width: newWidth } = newChildLayout;

      const type = sectionData?.get('type') as WidgetType;
      const childLayout = validateYMap<Y.Map<unknown>>(sectionData?.get('layout')).get(id);

      if (!childLayout) {
        throw new Error(`Child layout for ${id} not found.`);
      }
      const previousChildLayout = {
        span: childLayout.get('span') as number,
        column: childLayout.get('column') as number,
        height: childLayout.get('height') as number | 'auto',
        ...(type === WidgetType.FlexSection && { width: childLayout.get('width') as number })
      };

      const currentLayout = Object.values(Object.values(sectionLayout.toJSON()));

      const movedItem = { ...previousChildLayout, isNew: false as const, id, parentId };

      const cleanedLayout = removeItemFromLayout(currentLayout, movedItem);
      const hasEnoughSpace = checkIfHasEnoughSpace(cleanedLayout, newChildLayout, id, movedItem);
      const shouldChangeLayout = type === WidgetType.FlexSection || hasEnoughSpace;

      if (shouldChangeLayout) {
        childLayout.set('height', height);
        childLayout.set('span', span);
        childLayout.set('column', column);

        if (type === WidgetType.FlexSection) {
          childLayout.set('width', newWidth);
        }
      }
    },
    [sectionData, sectionLayout]
  );
}
