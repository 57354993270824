import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import * as Y from 'yjs';

import { useTextTools } from 'shared/richTextToolbar/useTextTools';

import { Hoverable } from '@/components/Hoverable';
import { SatisfactionSurvey, SatisfactionScore } from '@/components/SatisfactionSurvey';
import { Selectable } from '@/components/Selectable';
import { Manifest } from '@/widgets/SubmitPageSatisfactionSurvey/Manifest';
import { Base as TextBase } from '@/widgets/Text/studio/Base';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function SubmitPageSatisfactionSurvey(props: StudioProps) {
  const { initialize } = props;

  const titleFragment = useContentObjectStaticProperty<Y.XmlFragment>('fragment');

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  const [value, setValue] = useState<SatisfactionScore | null>(null);

  const [tools] = useTextTools({ textType: 'header' });

  return (
    <SatisfactionSurvey
      onChange={setValue}
      title={
        <Selectable>
          <Hoverable>
            <Box>
              <TextBase
                tools={tools}
                fragment={titleFragment}
                dataTestIdPrefix="studio-submit-page-satisfaction-survey-title"
              />
            </Box>
          </Hoverable>
        </Selectable>
      }
      value={value}
    />
  );
}
