import { ActivityThemeStyles } from '@hendrx/modules/ui';
import get from 'lodash/get';

export function getThemePropertyValue(
  activityThemeStyles: Partial<ActivityThemeStyles>,
  parentThemeStyles: Partial<ActivityThemeStyles> | undefined,
  defaultActivityThemeStyles: ActivityThemeStyles,
  path: string,
  globalPath?: string,
  partialGlobalPath?: string
) {
  const computedProperty =
    get(activityThemeStyles, path) ??
    (globalPath && get(activityThemeStyles, globalPath)) ??
    get(parentThemeStyles, path) ??
    (globalPath && get(parentThemeStyles, globalPath)) ??
    get(defaultActivityThemeStyles, path) ??
    (globalPath && get(defaultActivityThemeStyles, globalPath));

  let finalProperty = computedProperty;
  if (typeof computedProperty === 'object') {
    const emptyProperties = Object.keys(computedProperty).filter(key => computedProperty[key] === null);
    if (emptyProperties.length > 0) {
      const globalProperty =
        (partialGlobalPath && get(activityThemeStyles, partialGlobalPath)) ??
        (partialGlobalPath && get(parentThemeStyles, partialGlobalPath)) ??
        (partialGlobalPath && get(defaultActivityThemeStyles, partialGlobalPath));
      finalProperty = { ...computedProperty, borderColor: globalProperty };
    }
  }

  return finalProperty;
}

export function getThemeDefaultValue(
  activityThemeStyles: Partial<ActivityThemeStyles>,
  parentThemeStyles: Partial<ActivityThemeStyles> | undefined,
  defaultActivityThemeStyles: ActivityThemeStyles,
  path: string,
  globalPath?: string
) {
  const defaultThemeValue =
    (globalPath && get(activityThemeStyles, globalPath)) ??
    get(parentThemeStyles, path) ??
    (globalPath && get(parentThemeStyles, globalPath)) ??
    get(defaultActivityThemeStyles, path) ??
    (globalPath && get(defaultActivityThemeStyles, globalPath));

  return defaultThemeValue;
}

export function getThemeDefaultObjectValues(
  themeDefaultValue: Record<string, unknown>,
  activityThemeStyles: Partial<ActivityThemeStyles>,
  parentThemeStyles: Partial<ActivityThemeStyles> | undefined,
  defaultActivityThemeStyles: ActivityThemeStyles,
  partialGlobalPath?: string
) {
  if (!themeDefaultValue) {
    return;
  }
  let objectValues = Object.values(themeDefaultValue);
  if (typeof themeDefaultValue === 'object') {
    const emptyProperties = Object.keys(themeDefaultValue).filter(key => themeDefaultValue[key] === null);
    if (emptyProperties.length > 0) {
      const globalProperty =
        (partialGlobalPath && get(activityThemeStyles, partialGlobalPath)) ??
        (partialGlobalPath && get(parentThemeStyles, partialGlobalPath)) ??
        (partialGlobalPath && get(defaultActivityThemeStyles, partialGlobalPath));

      objectValues = Object.values(themeDefaultValue);
      const index = objectValues.indexOf(null);
      if (index !== -1) {
        objectValues[index] = globalProperty;
      }

      return objectValues;
    }
  }
}
