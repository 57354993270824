import { createStyles } from '@mantine/emotion';

type Props = {
  isDragging: boolean;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { isDragging } = props;

  return {
    dndWrapper: {
      width: '100%',
      height: '100%',
      opacity: isDragging ? 0.5 : 1,
      position: 'relative'
    },
    dndContainer: {
      height: '100%',
      position: 'relative'
    }
  };
});
