import { Input, RingProgress, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { ComponentProps, useEffect, useState } from 'react';

import { useStyles } from './Grade.styles';
import { GradeChevron } from '@/components/Grade/GradeChevron';
import { vars } from '@/utils/mantineVanillaIntegration/vars';

type GradeProps = {
  defaultScore?: number;
  maxScore: number;
  onScoreChange?: (currentScore: number | undefined) => void;
  submitted?: boolean;
  readonly?: boolean;
  controls?: boolean;
  label?: string;
  ringProps?: Partial<ComponentProps<typeof RingProgress>>;
};

const getScoreColor = (scorePercentage: number) => {
  return scorePercentage <= 55
    ? vars.colors?.failure?.[0]
    : scorePercentage < 70
    ? vars.colors?.warning?.[0]
    : vars.colors?.success?.[0];
};

const fixRoundingError = (num: number) => {
  return Math.round(num * 100) / 100;
};

export function Grade(props: GradeProps) {
  const { readonly, label, defaultScore, maxScore, submitted, onScoreChange, controls = true, ringProps } = props;

  const { classes } = useStyles();
  const t = useTranslations('components.grade');

  const [currentScore, setCurrentScore] = useState(defaultScore);

  useEffect(() => {
    setCurrentScore(defaultScore);
  }, [defaultScore]);

  const scorePercentage = fixRoundingError(((currentScore ?? 0) / maxScore) * 100);
  const scoreColor = getScoreColor(scorePercentage);

  const updateScore = (score: number | undefined) => {
    setCurrentScore(score);
    onScoreChange?.(score);
  };

  const onScoreUp = () => {
    updateScore((currentScore ?? 0) + 0.5);
  };

  const onScoreDown = () => {
    updateScore((currentScore ?? 0) - 0.5);
  };

  const onScoreCorrection = () => {
    if (currentScore === undefined) {
      return;
    }

    const newValue = Math.round(currentScore * 2) / 2;
    const newScore = Math.min(Math.max(newValue, 0), maxScore);
    updateScore(newScore);
  };

  return (
    <div className={classes.grade} style={label ? { flex: 1 } : {}}>
      {label ? <Text>{label}</Text> : null}
      {submitted ? (
        <Text>{t('grade')}</Text>
      ) : controls ? (
        <GradeChevron
          data-testid="grade-chevron-down"
          disabled={currentScore === undefined || currentScore <= 0}
          onClick={onScoreDown}
          variant="down"
        />
      ) : null}

      <RingProgress
        ml="auto"
        className={classes.ringProgress}
        data-testid="grade-ring-progress"
        label={
          <div className={classes.ringProgressInputContainer}>
            <Input
              className={classes.ringProgressInput}
              data-testid="grade-input"
              disabled={submitted || readonly}
              max={maxScore}
              min={0}
              onBlur={onScoreCorrection}
              onChange={event => updateScore(event.currentTarget.value === '' ? undefined : +event.currentTarget.value)}
              step={0.5}
              type="number"
              value={currentScore}
              variant="unstyled"
            />
            {currentScore === undefined && (
              <span className={classes.visualUnderscore} data-testid="grade-visualUnderscore">
                _
              </span>
            )}
          </div>
        }
        sections={[{ value: scorePercentage, color: scoreColor ?? 'green' }]}
        size={60}
        thickness={3}
        {...ringProps}
      />

      {submitted ? (
        <Text>{t('fromMaxScore', { maxScore })}</Text>
      ) : controls ? (
        <GradeChevron
          data-testid="grade-chevron-up"
          disabled={currentScore === maxScore}
          onClick={onScoreUp}
          variant="up"
        />
      ) : null}
    </div>
  );
}
