import { createContext, ReactElement, useCallback, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { Feedbacks } from 'shared/evaluation/feedback';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { getProperFeeback } from '@/utils/questions.utils';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import {
  useParentQuestionGroupAction,
  useParentQuestionGroupEvaluation,
  useParentQuestionGroupSettings
} from '@/widgets/QuestionGroupList/context';
import {
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import {
  useEvaluatableQuestionAnswers,
  UseEvaluatableQuestionAnswersProps
} from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';

type TiptapFillInTheBlanksOptionsProviderProps = {
  children: ReactElement;
};

type TiptapFillInTheBlanksOptionsStore = ReturnType<
  typeof useEvaluatableQuestionAnswers<WidgetType.FillInTheBlanksQuestion>
>;

const TiptapFillInTheBlanksOptionsStoreContext = createContext<StoreApi<TiptapFillInTheBlanksOptionsStore> | null>(
  null
);

export function TiptapFillInTheBlanksOptionsProvider(props: TiptapFillInTheBlanksOptionsProviderProps) {
  const { children } = props;
  const [ownFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const questionGroupSettings = useParentQuestionGroupSettings();
  const feedback = getProperFeeback(ownFeedback, questionGroupSettings?.feedback);
  const [wordBankOptions] = useContentObjectArray<WordOption>(`wordBankOptions`);
  const allAnswerOptions = useMemo(
    () =>
      wordBankOptions.reduce((ids, wordBankOption) => {
        ids[wordBankOption.id] = wordBankOption;
        return ids;
      }, {} as Record<string, WordOption>),
    [wordBankOptions]
  );
  const questionGroupAction = useParentQuestionGroupAction();
  const { document, id } = useContentObject();
  const isRequired = isQuestionRequired(id, document);
  const questionGroupEvaluation = useParentQuestionGroupEvaluation();

  const getCorrectAnswers: UseEvaluatableQuestionAnswersProps<WidgetType.FillInTheBlanksQuestion>['getCorrectAnswers'] =
    useCallback(
      result => {
        const { correctOptions = {} } = result ?? {};
        return Object.keys(correctOptions).reduce((ids, key) => {
          ids[key] = allAnswerOptions[correctOptions[key]];
          return ids;
        }, {} as Record<string, WordOption>);
      },
      [allAnswerOptions]
    );

  const defaultState = useMemo(() => ({}), []);

  const getCorrectStatuses: UseEvaluatableQuestionAnswersProps<WidgetType.FillInTheBlanksQuestion>['getCorrectStatuses'] =
    useCallback((correctAnswersOptions: ReturnType<typeof getCorrectAnswers>) => {
      return Object.keys(correctAnswersOptions).reduce((statuses, answer) => {
        statuses[answer] = true;
        return statuses;
      }, {} as Record<string, boolean>);
    }, []);

  const { choiceQuestionsEnabled } = questionGroupSettings ?? {};
  const initialValues = useEvaluatableQuestionAnswers<WidgetType.FillInTheBlanksQuestion>({
    feedback,
    getCorrectAnswers,
    defaultState,
    getCorrectStatuses,
    questionGroupAction,
    questionGroupEvaluation,
    isRequired: isRequired || !choiceQuestionsEnabled
  });

  const tiptapFillInTheBlanksOptionsStore = useMemo(
    () => createStore(subscribeWithSelector<TiptapFillInTheBlanksOptionsStore>(set => initialValues)),
    [initialValues]
  );

  return (
    <TiptapFillInTheBlanksOptionsStoreContext.Provider value={tiptapFillInTheBlanksOptionsStore}>
      {children}
    </TiptapFillInTheBlanksOptionsStoreContext.Provider>
  );
}

export function useTiptapFillInTheBlanksOptionsStore<T = TiptapFillInTheBlanksOptionsStore>(
  selector: (state: TiptapFillInTheBlanksOptionsStore) => T
) {
  const store = useContext(TiptapFillInTheBlanksOptionsStoreContext);
  if (store === null) {
    throw new Error('Context setup is incorrect');
  }

  return useStore(store, selector);
}
