import * as Y from 'yjs';

import { ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';

export function createNewSubmitPageTitle(): ManifestInitializedProps {
  const id = genRandId();
  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.SubmitPageTitle],
        ['children', new Y.Array()]
      ])
    }
  ];
}
