import { ReactNode } from 'react';

import { FontFamilyPicker } from '@/components/FontFamilyPicker';
import { ThemeControl } from '@/components/Theme/ThemeControl';

type FontFamilySelectPanelProps = {
  label?: string;
  path?: string;
  globalPath?: string;
  value: string;
  onChange: (newValue: string) => void;
  ResetButton?: ReactNode;
};

export const FontFamilySelectPanel = (props: FontFamilySelectPanelProps) => {
  const { label, path, globalPath, value, onChange, ResetButton } = props;

  return (
    <ThemeControl label={label} path={path} globalPath={globalPath}>
      <FontFamilyPicker value={value} onChange={newFontFamily => onChange(newFontFamily as string)} />
      {ResetButton}
    </ThemeControl>
  );
};
