import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createUiSlice, UiSlice } from './ui.slice';

export type UnitedStore = UiSlice;
type StoreMiddleware = [
  ['zustand/devtools', never],
  ['zustand/subscribeWithSelector', unknown],
  ['zustand/immer', never]
];

export const useStore = create<UnitedStore, StoreMiddleware>(
  devtools(
    subscribeWithSelector(
      immer((...set) => ({
        ...createUiSlice(...set)
      }))
    ),
    { enabled: process.env.NODE_ENV !== 'production', name: 'Main store' }
  )
);
