import { ReactNode } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { WidgetComponentContext } from './WidgetComponentContext';
import { playerPlenaryStudentWidgets } from '@/widgets/playerPlenaryStudentWidgets';
import { playerWidgets } from '@/widgets/playerWidgets';
import { studioWidgets } from '@/widgets/studioWidgets';

const getWidgetComponent = (widgetType: WidgetType, isStudioEdit: boolean, isPlenaryLessonStudent: boolean) => {
  const sourceWidgets = isStudioEdit
    ? studioWidgets
    : isPlenaryLessonStudent
    ? playerPlenaryStudentWidgets
    : playerWidgets;
  return sourceWidgets[widgetType] || (() => null);
};

type WidgetComponentProviderProps = { children: ReactNode };

export const WidgetComponentProvider = (props: WidgetComponentProviderProps) => {
  const { children } = props;
  return <WidgetComponentContext.Provider value={{ getWidgetComponent }}>{children}</WidgetComponentContext.Provider>;
};
