import { ActionIcon, Box, Button, FileButton, Flex, Image, Text, Textarea } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { Dispatch, RefObject, SetStateAction, useMemo } from 'react';

import * as classes from './AnswerField.css';
import { ImageUpload } from '@/icons/ImageUpload';
import { Send } from '@/icons/Send';
import { Trashcan } from '@/icons/Trashcan';
import { getFlipStyle } from '@/utils/locale.utils';
import { theme } from '@/utils/theme.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';

type AnswerFieldProps = {
  editAnswerId: string | null;
  enabledPictureUpload?: boolean;
  handleCancelEditMode: () => void;
  handleEdit: () => Promise<void>;
  handleImageDelete: () => void;
  handleSend: () => Promise<void>;
  image: File | string | null;
  isAnswersLimited?: boolean;
  imagePosition?: 'top' | 'bottom';
  isLoading: boolean;
  resetRef: RefObject<() => void>;
  setImage: Dispatch<SetStateAction<string | File | null>>;
  setText: Dispatch<SetStateAction<string>>;
  text: string;
};

export function AnswerField(props: AnswerFieldProps) {
  const {
    editAnswerId,
    enabledPictureUpload = true,
    handleCancelEditMode,
    handleEdit,
    handleImageDelete,
    handleSend,
    image,
    isAnswersLimited,
    imagePosition = 'top',
    isLoading,
    resetRef,
    setImage,
    setText,
    text
  } = props;

  const t = useTranslations('widgets.openDiscussionPlayer');

  const { contentDirection } = useContentLanguage();

  const flipStyle = getFlipStyle(contentDirection);

  const ImageElement = useMemo(
    () =>
      image ? (
        <Box pos="relative" dir={contentDirection}>
          <Image
            alt=""
            src={image instanceof File ? URL.createObjectURL(image) : image}
            radius="sm"
            fit="contain"
            mah={400}
          />
          <ActionIcon
            aria-label={t('delete')}
            className={classes.imageDeleteIcon}
            variant="filled"
            color="#38374580"
            m={7}
            onClick={handleImageDelete}
          >
            <Trashcan />
          </ActionIcon>
        </Box>
      ) : null,
    [handleImageDelete, image, t, contentDirection]
  );

  if (isAnswersLimited && !editAnswerId) {
    return null;
  }

  return (
    <>
      {editAnswerId && (
        <Flex justify="space-between" align="center" mb="xs">
          <Button size="xs" variant="subtle" onClick={handleCancelEditMode}>
            {t('cancel')}
          </Button>
          <Text size="xs">{t('edit')}</Text>
          <Button size="xs" loading={isLoading} onClick={handleEdit}>
            {t('done')}
          </Button>
        </Flex>
      )}
      {imagePosition === 'top' && enabledPictureUpload && ImageElement}
      <Flex
        align="center"
        gap="xs"
        mb={image && imagePosition === 'bottom' ? 'xs' : undefined}
        mt={image && imagePosition === 'top' ? 'xs' : undefined}
      >
        <Textarea
          minRows={1}
          maxRows={5}
          autosize
          className={classes.textarea}
          rightSection={
            enabledPictureUpload ? (
              <FileButton resetRef={resetRef} onChange={setImage} accept="image/png,image/jpeg,image/webp">
                {props => (
                  <ActionIcon size={26} aria-label={t('uploadImage')} variant="subtle" color="dark" {...props}>
                    <ImageUpload style={flipStyle} />
                  </ActionIcon>
                )}
              </FileButton>
            ) : undefined
          }
          classNames={{ section: classes.section }}
          onChange={event => setText(event.currentTarget.value)}
          value={text}
          styles={{
            input: {
              textAlign: `${contentDirection === 'ltr' ? 'left' : 'right'} !important` as 'left' | 'right'
            }
          }}
        />
        {!editAnswerId && (
          <ActionIcon
            size={42}
            radius="xl"
            aria-label={t('send')}
            color="#dddcfe"
            className={classes.sendButton}
            onClick={handleSend}
            loading={isLoading}
            styles={{
              icon: { color: theme.colors?.accent?.[6] }
            }}
          >
            <Send className={classes.sendIcon} style={flipStyle} />
          </ActionIcon>
        )}
      </Flex>
      {imagePosition === 'bottom' && enabledPictureUpload && ImageElement}
    </>
  );
}
