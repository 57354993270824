import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';
import { WidgetType } from 'shared/utils/widgets';

import { OVERALL_MAX_SCORE_WEIGHT_AMOUNT } from '@/utils/consts';

type ScoreDataType = { basicScore: number; wholePointsToShare: number; pointsLeftToShareAsHalfPoints: number };

type SingleQuestion = {
  id: string;
  type:
    | WidgetType.ClozeQuestion
    | WidgetType.FillInTheBlanksQuestion
    | WidgetType.OpenQuestion
    | WidgetType.SelectionQuestion;
  questionScoreMode: QuestionScoreMode;
  isHidden?: boolean;
};

type GroupQuestion = {
  id: string;
  type: WidgetType.QuestionGroupList;
  questionScoreMode: QuestionScoreMode;
  relativeScoreMode: RelativeScoreMode;
  subQuestions: { id: string }[];
  choiceQuestionsEnabled: boolean;
  choiceQuestionsCount: number;
  visibleQuestionsCount: number;
  isHidden?: boolean;
};

type ScoreWeightDistributionResult = {
  id: string;
  scoreWeight: number;
  subQuestions?: { id: string; scoreWeight: number }[];
}[];

/**
 * Top-level questions. SubQuestions are nested within the group questions
 */
export type ScoreWeightDistributionInput = (SingleQuestion | GroupQuestion)[];

export const calculateQuestionScore = (scoreData: ScoreDataType) => {
  let addition = 0;

  if (scoreData.wholePointsToShare > 0) {
    // add whole points first
    addition = 1;
    scoreData.wholePointsToShare -= 1;
  } else if (scoreData.pointsLeftToShareAsHalfPoints > 0) {
    // later add half a point at a time
    addition = 0.5;
    scoreData.pointsLeftToShareAsHalfPoints -= 0.5;
  }
  const result = scoreData.basicScore + addition;

  return result > 0 ? result : 0;
};

export const getScoreData = (overallScore: number, divider: number): ScoreDataType => {
  if (!overallScore || !divider || overallScore < 0 || divider < 0) {
    return {
      basicScore: 0,
      wholePointsToShare: 0,
      pointsLeftToShareAsHalfPoints: 0
    };
  }
  const basicScore = Math.floor(overallScore / divider);
  const remainder = overallScore % divider;

  const shouldAddWholePoints = remainder * 2 > divider;
  const wholePointsToShare = shouldAddWholePoints ? (remainder * 2) % divider : 0;

  return {
    basicScore,
    wholePointsToShare,
    pointsLeftToShareAsHalfPoints: remainder - wholePointsToShare
  };
};

export const distributeScoreWeights = (input: ScoreWeightDistributionInput): ScoreWeightDistributionResult => {
  const numberOfQuestionsToShareActivityWeight = getNumberOfQuestionsToShareActivityWeight(input);
  const activityScoreData = getScoreData(OVERALL_MAX_SCORE_WEIGHT_AMOUNT, numberOfQuestionsToShareActivityWeight);

  return input.reverse().map(question => {
    if (question.type === WidgetType.QuestionGroupList) {
      return distributeGroupQuestionScoreWeight(question, activityScoreData);
    }
    return distributeSingleQuestionScoreWeight(question, activityScoreData);
  });
};

const getNumberOfQuestionsToShareActivityWeight = (input: ScoreWeightDistributionInput) => {
  return input.reduce((questionCount, question) => {
    if (question.questionScoreMode === QuestionScoreMode.WithScore && !question.isHidden) {
      if (
        question.type === WidgetType.QuestionGroupList &&
        question.relativeScoreMode === RelativeScoreMode.RelativeToActivity
      ) {
        const actualSubQuestionsCount = getActualSubQuestionsCount(question);
        // when the subQuestions are related to the activity, they take part in the `global` distribution pool
        return questionCount + actualSubQuestionsCount;
      }
      return questionCount + 1;
    }
    return questionCount;
  }, 0);
};

const distributeGroupQuestionScoreWeight = (question: GroupQuestion, activityScoreData: ScoreDataType) => {
  if (question.isHidden || question.questionScoreMode !== QuestionScoreMode.WithScore) {
    return {
      id: question.id,
      scoreWeight: 0,
      subQuestions: question.subQuestions.map(subQuestion => ({ id: subQuestion.id, scoreWeight: 0 }))
    };
  }

  if (question.relativeScoreMode === RelativeScoreMode.RelativeToParent) {
    // in this mode the question's score weight comes from the `global` pool of weights,
    // and it's then distributed across the subQuestions
    const actualSubQuestionsCount = getActualSubQuestionsCount(question);
    const scoreWeight = calculateQuestionScore(activityScoreData);
    const scoreDataForSubQuestions = getScoreData(scoreWeight, actualSubQuestionsCount);
    const subQuestionsScoreWeights = getSubQuestionsScoreWeights(
      scoreDataForSubQuestions,
      question.subQuestions,
      actualSubQuestionsCount
    );
    return { id: question.id, subQuestions: subQuestionsScoreWeights, scoreWeight };
  } else {
    // in this mode (RelativeScoreMode.RelativeToActivity) the subQuestions' score weights are part of the `global` pool of weights,
    // so the question's score weight is a sum of its subQuestions' weights
    const actualSubQuestionsCount = getActualSubQuestionsCount(question);
    const subQuestionsScoreWeights = getSubQuestionsScoreWeights(
      activityScoreData,
      question.subQuestions,
      actualSubQuestionsCount
    );
    const actualSubQuestionsScoreWeights = subQuestionsScoreWeights.slice(0, actualSubQuestionsCount);
    const scoreWeight = actualSubQuestionsScoreWeights.reduce(
      (totalWeight, subQuestion) => totalWeight + subQuestion.scoreWeight,
      0
    );
    return { id: question.id, subQuestions: subQuestionsScoreWeights, scoreWeight };
  }
};

const distributeSingleQuestionScoreWeight = (question: SingleQuestion, activityScoreData: ScoreDataType) => {
  if (question.isHidden || question.questionScoreMode !== QuestionScoreMode.WithScore) {
    return { id: question.id, scoreWeight: 0 };
  }
  return { id: question.id, scoreWeight: calculateQuestionScore(activityScoreData) };
};

const getActualSubQuestionsCount = (groupQuestion: {
  choiceQuestionsEnabled: boolean;
  choiceQuestionsCount: number;
  visibleQuestionsCount: number;
}) => {
  return (
    (groupQuestion.choiceQuestionsEnabled ? groupQuestion.choiceQuestionsCount : groupQuestion.visibleQuestionsCount) ??
    0
  );
};

const getSubQuestionsScoreWeights = (
  scoreData: ScoreDataType,
  subQuestions: { id: string }[],
  actualSubQuestionsCount: number
): { id: string; scoreWeight: number }[] => {
  const scoreList: number[] = [];
  for (let i = 0; i < actualSubQuestionsCount; i++) {
    const newSubQuestionScore = calculateQuestionScore(scoreData);
    scoreList.push(newSubQuestionScore);
  }

  // Assign scores to subQuestions. Usually in the group there are more subQuestions than can be included
  // to the final score (the score that the student can get for the group question), so if the scoreList
  // is shorter, we take the score value from the last index and assign it to the rest of the subQuestions.
  return subQuestions.map((subQuestion, index) => {
    const scoreIndex = index < actualSubQuestionsCount ? index : actualSubQuestionsCount - 1;
    return { id: subQuestion.id, scoreWeight: scoreList[scoreIndex] };
  });
};
