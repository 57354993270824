import { WidgetType } from 'shared/utils/widgets';

import { AudioSchemaType } from '@/widgets/Audio/Schema';
import { Base } from '@/widgets/Audio/config/Base';
import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Config() {
  const [title, setTitle] = useContentObjectProperty<string>('title');
  const [errors] = useContentObjectErrors<AudioSchemaType>();
  const [widgetType] = useContentObjectProperty<WidgetType>('type');

  const isStandaloneImageWidget = widgetType === WidgetType.Image;

  return <Base title={title} setTitle={setTitle} errors={errors} withCustomCSSClasses={isStandaloneImageWidget} />;
}
