import { useContext } from 'react';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { WidgetType } from 'shared/utils/widgets';

import { WidgetComponentContext } from './WidgetComponentContext';
import { useUser } from '@/queries/user';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useWorkMode } from '@/utils/useWorkMode';

export const useWidgetComponent = (widgetType: WidgetType) => {
  const widgetComponentContext = useContext(WidgetComponentContext);
  if (widgetComponentContext === null) throw new Error('WidgetComponentContext not initialized properly.');

  const { getWidgetComponent } = widgetComponentContext;

  const { isStudioEdit } = useWorkMode();
  const { user } = useUser();
  const { type } = useLearningObjectType();

  const isPlenaryLesson = type === LearningObjectTypes.PlenaryLesson;
  const isPlenaryLessonStudent = isPlenaryLesson && user?.role === 'plenaryStudent';

  return getWidgetComponent(widgetType, isStudioEdit, isPlenaryLessonStudent);
};
