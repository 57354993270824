import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  switchBody: {
    alignItems: 'center',
    'input[disabled] + label': {
      opacity: '.7',
      backgroundColor: theme.colors.accent[6]
    }
  },
  switchLabel: {
    '&[data-disabled]': {
      color: theme.colors.gray[6],
      pointerEvents: 'none',
      cursor: 'not-allowed'
    }
  },
  textEditors: {
    marginInlineStart: rem(56)
  }
}));
