import { Text } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from '@/widgets/SubmitPageUnansweredQuestions/player/SubmitPageUnansweredQuestions.styles';

type Props = {
  unansweredQuestionsCount: number;
};

export function Base(props: Props) {
  const { unansweredQuestionsCount } = props;
  const t = useTranslations('widgets');
  const { classes } = useStyles();

  if (unansweredQuestionsCount > 0) {
    const summaryText =
      unansweredQuestionsCount > 1 ? (
        <>
          {t('didntAnswer')}
          <span className={classes.unansweredQuestionsCount}>{unansweredQuestionsCount}</span>
          {t('questions')}
        </>
      ) : (
        t('singleQuestion')
      );

    return <Text className={classes.unansweredQuestionsText}>{summaryText}</Text>;
  } else {
    return null;
  }
}
