import { ActionIcon, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';

import { ConfirmationPaper } from '@/components/ConfirmationPaper';
import { Trashcan } from '@/icons/Trashcan';

type QuestionCardDeleteProps = {
  onDelete: () => void;
};

export function DeleteQuestion(props: QuestionCardDeleteProps) {
  const { onDelete } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const t = useTranslations('widgets');

  const handleDeleteQuestion = () => {
    close();
    onDelete();
  };

  return (
    <Popover opened={opened} trapFocus position="left" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon variant="transparent" data-testid="delete-question-bank-item-button" onClick={() => open()}>
          <Trashcan />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ConfirmationPaper
          onAccept={handleDeleteQuestion}
          onReject={close}
          text={t('questionGroupListSettings.deleteQuestionConfirmation')}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
