import * as Y from 'yjs';

import { manifestLocales } from './manifest.locales';
import { Feedbacks } from '../../types/Feedbacks';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { createQuestionWidgetChildren } from '../../utils/createQuestionWidgetChildren';
import { genRandId } from '../../utils/genRandId';
import { getEvaluationProps } from '../../utils/getEvaluationProps';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';
import { createNewFillInTheBlanksQuestionAnswerData } from '../FillInTheBlanksQuestionAnswer/create';
import { FillInTheBlanksQuestionConfig } from '../questionConfigs';

export function createNewFillInTheBlanksQuestionData(
  options: InitializePropsOptions,
  initializationConfig?: FillInTheBlanksQuestionConfig
): ManifestInitializedProps {
  const { isPlenaryLesson = false, locale } = options;
  const { answerPlaceholder, initialHeadlineText } = manifestLocales.fillInTheBlanksQuestion;

  const { answer = '', hint = '', label = initialHeadlineText[locale] } = initializationConfig || {};

  // answer example:
  // const answer = `one two <bank>bank three</bank> four five <bank>bank six</bank> seven eight`;

  const wordIds = [];
  const wordBankOptions = new Y.Array();
  const correctOptions = new Y.Map();

  // Split text into chunks, replace each chunk with a <fitb-option> element
  const content = answer
    .split(/(<bank>.*?<\/bank>)/)
    .map(chunk => {
      if (chunk.startsWith('<bank>')) {
        const optionId = genRandId();
        const wordId = genRandId();

        wordIds.push(wordId);

        wordBankOptions.push([{ id: optionId, value: chunk.replace('<bank>', '').replace('</bank>', '') }]);
        correctOptions.set(wordId, optionId);

        return `<fitb-option data-fitb-id="${wordId}" style="font-size: null; font-family: null"></fitb-option>`;
      }

      return chunk;
    })
    .join('');

  const id = genRandId();
  const labelFragment = htmlToXmlFragment(`<h2>${label}</h2>`);
  const answerFragment = htmlToXmlFragment(content);
  const hintFragment = htmlToXmlFragment(hint);

  const widgetEvaluationProps = Object.entries(getEvaluationProps(isPlenaryLesson));

  const [questionAnswerData] = createNewFillInTheBlanksQuestionAnswerData();
  const { childrenArray, horizontalFlexSectionData, verticalFlexSectionData } = createQuestionWidgetChildren(
    questionAnswerData.id,
    options?.width
  );

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.FillInTheBlanksQuestion],
        ['children', childrenArray],
        ['labelFragment', labelFragment],
        ['answerFragment', answerFragment],
        ['hintFragment', hintFragment],
        ['answerPlaceholder', answerPlaceholder[locale]],
        ['feedback', Feedbacks.Check],
        ['wordBankOptions', wordBankOptions], //[{id: xxx, value: xxx}]
        ['saveAnswers', true],
        ...widgetEvaluationProps,
        ['questionAnswerId', questionAnswerData.id]
      ]),
      sensitiveData: new Y.Map([
        ['correctOptions', correctOptions] //id (fillInTheBlanksId) -> id (optionId)
      ])
    },
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  ];
}

/**
 * in the player:
 *
 * each word in the bank has an optionId and a value
 *
 * each input has an fillInTheBlanksId
 *
 * when a word is dragged into an input we can take the fillInTheBlanksId and check server side (sensitive data) which optionId is connected to it, then check if it fits the one that was dragged.
 *
 *
 * in the studio:
 *
 * when rendering the inputs we can grab the connected value directly from sensitive data here without checking server side
 *
 */
