import { ActivityScoreWeight } from 'shared/types/ActivityScoreWeight';
import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

export function useActivityScoreWeight() {
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);
  return useObservedProperty<ActivityScoreWeight>(rootMap, 'activityScoreWeight');
}
