import { MantineTheme } from '@mantine/core';

import { isVar } from '@/utils/theme.utils';

export const themeToStyles = (theme: MantineTheme) => {
  return themeToStylesInternal(theme, '-');
};

const themeToStylesInternal = (themeNode: unknown, currentVariableName: string): Record<`--${string}`, string> => {
  let nodeStyles;

  if (Array.isArray(themeNode)) {
    nodeStyles = themeNode.reduce((currentThemeStyles, themeNodeElement, index) => {
      const partialNodeStyles = themeToStylesInternal(themeNodeElement, currentVariableName + '-' + index);

      return {
        ...currentThemeStyles,
        ...partialNodeStyles
      };
    }, {});
  } else if (typeof themeNode === 'object') {
    nodeStyles = Object.entries(themeNode ?? {}).reduce((currentThemeStyles, [themeKey, themeValue]) => {
      const partialNodeStyles = themeToStylesInternal(themeValue, currentVariableName + '-' + themeKey);

      return {
        ...currentThemeStyles,
        ...partialNodeStyles
      };
    }, {});
  } else {
    if (isVar(themeNode)) {
      nodeStyles = {};
    } else {
      nodeStyles = {
        [currentVariableName]: themeNode
      };
    }
  }

  return nodeStyles;
};
