import { clientModules } from '@hendrx/modules';
import { ActivityThemeStyles } from '@hendrx/modules/ui';
import set from 'lodash/set';
import { useCallback } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useParentTheme } from './useParentTheme';
import { getThemePropertyValue } from '@/contentObjects/Root/ActivityThemeStylesUtils';

export function useActivityThemeStylesProperty<T = string>(
  document: Y.Doc,
  path: string,
  globalPath?: string,
  partialGlobalPath?: string
) {
  const { data: parentTheme } = useParentTheme();

  const rootMap = getRootMap(document);
  const [activityThemeStyles, setActivityThemeStyles] = useObservedProperty<ActivityThemeStyles>(
    rootMap,
    'activityThemeStyles'
  );
  const handleSet = useCallback(
    (newValue: T) => {
      setActivityThemeStyles(prevTheme => set(structuredClone(prevTheme), path, newValue));
    },
    [setActivityThemeStyles, path]
  );

  const finalProperty = getThemePropertyValue(
    activityThemeStyles,
    parentTheme?.styles,
    clientModules.ui.defaultActivityThemeStyles,
    path,
    globalPath,
    partialGlobalPath
  );

  return [finalProperty as T, handleSet] as const;
}
