import { ActivityThemeStyles, OverrideTypographyStylesKeys, TypographyThemeProps } from '@hendrx/modules/ui';
import { Editor } from '@tiptap/react';
import { useCallback } from 'react';

import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';

type LevelTagTypography = keyof ActivityThemeStyles['typography'];

export const useTextStyles = (editor: Editor | null) => {
  const document = useCurrentDocument();
  const [activityThemeStyles] = useActivityThemeStyles(document);

  const getTextStyleProp = useCallback(
    (
      prop: keyof TypographyThemeProps | 'fontFamily',
      options?: {
        overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
        changeContrastColorToMain?: boolean;
      }
    ) => {
      if (!editor) return null;

      const stylesPath = options?.overrideTypographyStylesPath ?? 'typography';

      const elementStyle = editor.getAttributes('textStyle');

      if (elementStyle[prop]) {
        return elementStyle[prop];
      }

      for (let level = 1; level < 5; level++) {
        if (editor.getAttributes('heading').level === level.toString()) {
          const levelTag = `h${level}` as LevelTagTypography;

          if (prop === 'fontFamily') {
            return (
              activityThemeStyles[stylesPath][levelTag].fontFamily ?? activityThemeStyles.global.content.fontFamily
            );
          }

          if (prop === 'color') {
            const customTagColor = activityThemeStyles[stylesPath][levelTag].color;
            // Header's level tag 1 and 2 are considered as primary typography, other than that is secondary
            // It'd be a good idea to create a map or a constant to hold values like these
            const isPrimaryTypography = level === 1 || level === 2;
            return (
              customTagColor ??
              activityThemeStyles.global.content.colors[isPrimaryTypography ? 'primary' : 'secondary'][
                options?.changeContrastColorToMain ? 'main' : 'contrast'
              ]
            );
          }

          // Padding types can be different: always simple padding in typography vs paddingTop, paddingBottom etc. in overriding styles
          if (prop !== 'padding') {
            return activityThemeStyles[stylesPath][levelTag][prop];
          }

          return activityThemeStyles.typography[levelTag].padding;
        }
      }

      if (prop === 'fontFamily') {
        return activityThemeStyles[stylesPath].p.fontFamily ?? activityThemeStyles.global.content.fontFamily;
      }

      if (prop === 'color') {
        return (
          activityThemeStyles[stylesPath].p.color ??
          activityThemeStyles.global.content.colors.secondary[options?.changeContrastColorToMain ? 'main' : 'contrast']
        );
      }

      if (prop === 'lineHeight') {
        return activityThemeStyles[stylesPath].p.lineHeight;
      }

      return activityThemeStyles.typography.p[prop];
    },
    [activityThemeStyles, editor]
  );

  return {
    getTextStyles: getTextStyleProp
  };
};
