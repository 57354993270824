export const manifestLocales = {
  powerpoint: {
    previewImageAlt: {
      en: 'powerPoint widget',
      he: 'מצגת PowerPoint'
    }
  },
  word: {
    previewImageAlt: {
      en: 'word widget',
      he: 'מסמך Word'
    }
  },
  excel: {
    previewImageAlt: {
      en: 'excel widget',
      he: 'מסמך Excel'
    }
  }
};
