import { Box, UnstyledButton } from '@mantine/core';
import { FC, useCallback } from 'react';

import { useStyles } from './SatisfactionSurveyOption.styles';
import { SatisfactionScore } from '@/components/SatisfactionSurvey';
import { SatisfactionSelection } from '@/icons/SatisfactionSelection';

type SatisfactionSurveyOptionProps = {
  Icon: FC;
  onChange: (value: SatisfactionScore | null) => void;
  optionName: SatisfactionScore;
  disabled?: boolean;
  isSelected?: boolean;
};

export const SatisfactionSurveyOption = (props: SatisfactionSurveyOptionProps) => {
  const { Icon, isSelected, onChange, optionName, disabled } = props;

  const { classes } = useStyles({ disabled });

  const handleOnChange = useCallback(() => {
    onChange(isSelected ? null : optionName);
  }, [optionName, isSelected, onChange]);

  return (
    <UnstyledButton className={classes.optionWrapper} onClick={handleOnChange} disabled={disabled}>
      <Box className={classes.option}>
        <Icon />
      </Box>
      <SatisfactionSelection className={isSelected ? classes.selectionVisible : classes.selectionHidden} />
    </UnstyledButton>
  );
};
