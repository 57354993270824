import { CreateThemeDto, ThemeType, UpdateThemeDto } from '@hendrx/modules/ui';

export const getAllThemeNames = async () => {
  const response = await fetch(`/api/theme/get-all-theme-names`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}. Could not fetch themes.`, {
      cause: response
    });
  }

  return await response.json();
};

export const getThemeById = async (themeId: string) => {
  const response = await fetch(`/api/theme/get-one-by-id/${themeId}`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}. Could not fetch theme.`, {
      cause: response
    });
  }

  return (await response.json()) as ThemeType;
};

export const createTheme = async (createThemeDto: CreateThemeDto) => {
  const response = await fetch('/api/theme/create-theme', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(createThemeDto)
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}, Could not create a theme`, {
      cause: response
    });
  }
  return await response.json();
};

export const updateTheme = async (updateThemeDto: UpdateThemeDto) => {
  const response = await fetch('/api/theme/update-theme', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updateThemeDto)
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}, Could not update a theme`, {
      cause: response
    });
  }
  return await response.json();
};
