import { Box, Flex } from '@mantine/core';

import { WordBankOption } from './WordBankOption';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';
import { useTiptapFillInTheBlanksOptionsStore } from '@/widgets/FillInTheBlanksQuestion/studio/hooks/useTiptapFillInTheBlanksOptionsStore';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import { useContentObjectArray } from '@/widgets/_components/ContentObjectProvider';

export function WordBank() {
  const [wordBankOptions] = useContentObjectArray<WordOption>(`wordBankOptions`);

  const nodesRemovalFunctions = useTiptapFillInTheBlanksOptionsStore(state => state.nodesRemovalFunctions);

  const { classes, cx } = useStyles({});

  return (
    <Box className={cx(classes.wordBankWrapper, ThemeClassNames.widgets.fillInTheBlanksQuestion.wordBank)}>
      <Flex className={classes.wordBankOptions} wrap="wrap" data-testid="studio-word-bank">
        {wordBankOptions.map(({ id, value }) => (
          <WordBankOption key={id} onRemove={nodesRemovalFunctions[id]}>
            {value}
          </WordBankOption>
        ))}
      </Flex>
    </Box>
  );
}
