import { ActionIcon } from '@mantine/core';
import cx from 'clsx';
import { CSSProperties, MouseEventHandler } from 'react';

import * as classes from './NavArrowBase.css';
import * as presentationActionBarClasses from '@/components/PresenterActionBar/PresenterActionBar.css';
import { PresentationNavigationChevron } from '@/icons/PresentationNavigationChevron';
import { getFlipStyle } from '@/utils/locale.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';

type NavArrowBaseProps = {
  isArrowNext?: boolean;
  arrowPositionStyle: CSSProperties;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export function NavArrowBase(props: NavArrowBaseProps) {
  const { isArrowNext, arrowPositionStyle, onClick } = props;

  const { uiDirection } = useUiLanguage();
  const flipStyle = getFlipStyle(uiDirection);

  return (
    <ActionIcon
      onClick={onClick}
      className={isArrowNext ? classes.navArrow.next : classes.navArrow.back}
      style={arrowPositionStyle}
    >
      <PresentationNavigationChevron
        className={cx(presentationActionBarClasses.iconPrimary, {
          [presentationActionBarClasses.rotate.right]: isArrowNext
        })}
        style={flipStyle}
      />
    </ActionIcon>
  );
}
