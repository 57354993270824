import { NotificationData, notifications } from '@mantine/notifications';

type NotificationProps = Omit<NotificationData, 'message' | 'title' | 'color'>;

const showNotification = (
  type: 'error' | 'warning' | 'success',
  message: string,
  title: string,
  options?: NotificationProps
) => {
  const color = type === 'error' ? 'red' : type === 'warning' ? 'yellow' : 'green';

  notifications.show({
    color,
    title,
    message,
    ...options
  });
};

export const showErrorNotification = (message: string, title: string, options?: NotificationProps) => {
  return showNotification('error', message, title, options);
};

export const showWarningNotification = (message: string, title: string, options?: NotificationProps) => {
  return showNotification('warning', message, title, options);
};

export const showSuccessNotification = (message: string, title: string, options?: NotificationProps) => {
  return showNotification('success', message, title, options);
};

export const hideNotification = (id: string) => {
  notifications.hide(id);
};
