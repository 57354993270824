import { Flex } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import * as Y from 'yjs';

import { ContentObjectType, getContentObjectsMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { AnswerCount } from '@/widgets/QuestionGroupList/config/design/AnswerCount';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { calculateWeightScore } from '@/widgets/_components/ContentObjectProvider/OrchestrationWrapper/calculateWeightScore';
import { FormControl } from '@/widgets/_components/FormControl';

export function DisplayPreferenceSettings() {
  const t = useTranslations('widgets');

  const [visibleQuestionsCount, setVisibleQuestionsCount] = useContentObjectProperty<number>('visibleQuestionsCount');

  const { document } = useContentObject();
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');
  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<ContentObjectType>>(contentObjectsMap, verticalSectionId);
  const [verticalChildren] = useObservedArray<string>(verticalSection, 'children');
  const [requiredQuestions] = useContentObjectProperty<string[]>('requiredSubQuestionsId');

  const maxQuestionsCount = verticalChildren.length;

  useEffect(() => {
    if (requiredQuestions.length > visibleQuestionsCount) {
      setVisibleQuestionsCount(requiredQuestions.length);
    }
  }, [requiredQuestions, setVisibleQuestionsCount, visibleQuestionsCount]);

  useEffect(() => {
    calculateWeightScore(document);
  }, [document, visibleQuestionsCount]);

  return (
    <FormControl title={t('questionGroupListSettings.displayPreferences')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <AnswerCount
          value={visibleQuestionsCount}
          setValue={setVisibleQuestionsCount}
          min={maxQuestionsCount ? Math.max(requiredQuestions.length, 1) : 0}
          max={maxQuestionsCount}
        />
      </Flex>
    </FormControl>
  );
}
