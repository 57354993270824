import { Box } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { PropsWithChildren, useRef } from 'react';
import { useDrag } from 'react-dnd';

import { useStyles } from './Dnd.styles';
import { useStore } from '@/store';
import { DndDragLayer } from '@/widgets/_components/dnd/DndDragLayer/DndDragLayer';
import { DndCollect, DndItem } from '@/widgets/_components/dnd/dnd';
import { DragHandle } from '@/widgets/_components/widget/DragHandle';

type Props = {
  id: string;
  index: number;
  offset?: number;
  contextId: string;
} & PropsWithChildren;

export const Dnd = (props: Props) => {
  const { id, index, children, offset = 0, contextId } = props;

  const { ref: detectSizeRef, width: previewWidth, height: previewHeight } = useElementSize();
  const stageScale = useStore(state => state.stageScale);
  const dragRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag<DndItem, void, DndCollect>(
    {
      type: contextId,
      item: { id, index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    },
    [id, index]
  );

  const { classes } = useStyles({ isDragging });

  drag(dragRef);

  return (
    <Box className={classes.dndContainer}>
      <Box className={classes.dndWrapper} ref={detectSizeRef}>
        {children}
        <DragHandle orientation="side" strength={6} ref={dragRef} offset={offset} />
      </Box>
      {isDragging ? (
        <DndDragLayer scale={stageScale}>
          <Box style={{ width: previewWidth, height: previewHeight }}>
            {children}
            <DragHandle orientation="side" strength={6} offset={offset} />
          </Box>
        </DndDragLayer>
      ) : null}
    </Box>
  );
};
