import { Box } from '@mantine/core';

import { WidgetType } from 'shared/utils/widgets';

import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';
import { FillInTheBlanksText } from '@/widgets/FillInTheBlanksQuestion/player/FillInTheBlanksText';
import { WordBank } from '@/widgets/FillInTheBlanksQuestion/player/WordBank';
import { ContentObjectProvider, useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function FillInTheBlanksQuestionAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.FillInTheBlanksQuestion}>
      <FillInTheBlanksQuestionAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

function FillInTheBlanksQuestionAnswerPlayerImplementation() {
  const { classes, cx } = useStyles({});
  return (
    <>
      <Box className={cx(classes.answer, ThemeClassNames.widgets.fillInTheBlanksQuestion.answer)}>
        <FillInTheBlanksText />
      </Box>
      <WordBank />
    </>
  );
}
