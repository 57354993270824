import { BorderThemeProps } from '@hendrx/modules/ui';
import { NumberInput, Box } from '@mantine/core';
import { ReactNode } from 'react';

import { ColorInput } from '@/components/ColorInput';
import { Select } from '@/components/Select';
import * as classes from '@/components/Theme/Theme.css';
import { ThemeControl } from '@/components/Theme/ThemeControl';

const borderStyleData = ['solid', 'dashed', 'dotted'];

type BorderPanelProps = {
  ResetButton?: ReactNode;
  border: BorderThemeProps;
  colorGlobalPath?: string;
  label?: string;
  path?: string;
  fourBordersPaths?: string[];
  setBorder: (newValue: BorderThemeProps) => void;
  themeControlClassName?: string;
};

export const BorderPanel = (props: BorderPanelProps) => {
  const { border, colorGlobalPath, label, path, fourBordersPaths, setBorder, themeControlClassName, ResetButton } =
    props;

  const { borderColor, borderStyle, borderWidth } = border;

  return (
    <Box className={classes.fullWidthContainer}>
      <ThemeControl
        label={label}
        path={path}
        pathsBatch={fourBordersPaths}
        partialGlobalPath={colorGlobalPath}
        className={themeControlClassName}
      >
        <ColorInput value={borderColor ?? ''} onChange={color => setBorder({ ...border, borderColor: color })} />
        <Box className={classes.numberInput}>
          <NumberInput
            value={borderWidth}
            onChange={width => setBorder({ ...border, borderWidth: +width || 0 })}
            min={0}
          />
        </Box>
        <Select
          data={borderStyleData}
          value={borderStyle}
          onChange={style => setBorder({ ...border, borderStyle: style ?? '' })}
        />
        {ResetButton}
      </ThemeControl>
    </Box>
  );
};
