export const getUploadSecureInfo = async (queryParams: Record<string, string>) => {
  const params = new URLSearchParams(queryParams);

  const response = await fetch(`/api/file-upload/get-secure-info?${params.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.status === 404) {
    return response; // GetSecureFileUploadInfo endpoint is optional
  }

  if (!response.ok) {
    throw new Error(`HTTP auth error! Status: ${response.status}`, {
      cause: response
    });
  }

  return response.json();
};
