import { useCallback } from 'react';

import { useRemoveChild } from '@/contentObjects/Section/hooks/useRemoveChild';

export const useDeleteAction = () => {
  const removeChild = useRemoveChild();

  return useCallback(
    (id?: string) => {
      if (id) {
        removeChild(id);
      }
    },
    [removeChild]
  );
};
