import {
  Modal as MantineModal,
  ModalCloseButtonProps,
  ModalContentProps,
  ModalRootProps,
  rem,
  ModalBaseStylesNames,
  ScrollArea
} from '@mantine/core';
import { CSSProperties, ReactNode } from 'react';

import { useStyles } from '@/components/Modal/Modal.styles';
import { HEADER_HEIGHT_PX } from '@/consts/styleConsts';
import { MODAL_PORTAL_ID } from '@/utils/consts';

type Modal = {
  opened: boolean;
  children: ReactNode;
  title?: string;
  dataTestPrefix?: string;
  modalContentProps?: ModalContentProps;
  closeButtonProps?: ModalCloseButtonProps;
  overridingClasses?: Partial<Record<ModalBaseStylesNames, string>>;
  styles?: Partial<Record<ModalBaseStylesNames, CSSProperties>>;
} & ModalRootProps;

export function Modal(props: Modal) {
  const {
    title,
    children,
    dataTestPrefix,
    modalContentProps,
    closeButtonProps,
    overridingClasses,
    styles,
    ...modalRootProps
  } = props;
  const { classes } = useStyles();

  return (
    <MantineModal.Root
      classNames={{
        header: classes.modalHeader,
        inner: classes.modalInner,
        content: classes.content,
        close: classes.closeButton,
        ...overridingClasses
      }}
      styles={styles}
      scrollAreaComponent={ScrollArea.Autosize}
      yOffset={rem(HEADER_HEIGHT_PX)}
      size="xl"
      centered
      {...modalRootProps}
    >
      <MantineModal.Overlay />
      <MantineModal.Content id={MODAL_PORTAL_ID} {...modalContentProps}>
        <MantineModal.Header>
          <MantineModal.Title>{title}</MantineModal.Title>
          <MantineModal.CloseButton
            variant="transparent"
            color="accent"
            data-testid={`${dataTestPrefix}-close`}
            {...closeButtonProps}
          />
        </MantineModal.Header>
        <MantineModal.Body data-testid={`${dataTestPrefix}-content`}>{children}</MantineModal.Body>
      </MantineModal.Content>
    </MantineModal.Root>
  );
}
