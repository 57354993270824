import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  satisfactionSurvey: {
    backgroundColor: theme.colors.accent[0],
    borderRadius: rem(8),
    padding: `${rem(16)} ${rem(16)} ${rem(8)}`,

    '&  p > span': {
      display: 'block',
      textAlign: 'center'
    }
  }
}));
