import { ActionIcon, Flex, TextInput } from '@mantine/core';
import { ReactNode } from 'react';

import * as classes from './InputClozeBase.css';
import { Cross } from '@/icons/Cross';

type InputClozeBaseProps = {
  onChange: (value: string) => void;
  value: string;
  autoFocus?: boolean;
  dashed?: boolean;
  error?: ReactNode;
  isDisabled?: boolean;
  onDelete?: () => void;
  placeholder?: string;
};

export function InputClozeBase(props: InputClozeBaseProps) {
  const { autoFocus, onChange, isDisabled, error, value, onDelete, dashed, placeholder } = props;

  return (
    <Flex align="center" className={classes.wrapper}>
      <TextInput
        autoFocus={autoFocus}
        value={value}
        onChange={event => onChange(event.target.value)}
        className={classes.input}
        classNames={dashed ? { input: classes.dashedBorder } : {}}
        error={error}
        data-testid="studio-cloze-question-appearance-input-field"
        placeholder={placeholder}
      />
      <ActionIcon
        size="xs"
        variant="subtle"
        className={onDelete ? classes.crossIcon.visible : classes.crossIcon.hidden}
        onClick={onDelete}
        disabled={isDisabled}
        data-testid="studio-cloze-question-appearance-input-delete-button"
      >
        <Cross />
      </ActionIcon>
    </Flex>
  );
}
