import * as Y from 'yjs';

import { extractDataMaps, getQuestionData } from './questionDataUtils';

export function getCorrectAnswers({ questionId, document }: { questionId: string; document: Y.Doc }) {
  const { contentObjectsMap, sensitiveDataMap, widgetsFormDataMap } = extractDataMaps(document);
  const { type, sensitiveData } = getQuestionData(questionId, contentObjectsMap, sensitiveDataMap, widgetsFormDataMap);

  if (type === 'SelectionQuestion') {
    const correctAnswers = sensitiveData.get('correctAnswers') as string[];
    return { correctAnswers };
  } else if (type === 'ClozeQuestion') {
    const inputCorrectOptions = sensitiveData.get('inputCorrectOptions') as Y.Map<Y.Array<Y.Map<unknown>>>;
    const selectCorrectOptions = sensitiveData.get('selectCorrectOptions') as Y.Map<Y.Map<unknown>>;
    return { inputCorrectOptions, selectCorrectOptions };
  } else if (type === 'FillInTheBlanksQuestion') {
    const correctOptions = sensitiveData.get('correctOptions') as Y.Map<string>;
    return { correctOptions };
  } else if (type === 'InteractiveQuiz') {
    const correctAnswers = sensitiveData.get('correctAnswers') as string[];
    return { correctAnswers };
  }

  return null;
}
