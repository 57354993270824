import { PdfPlaceholder } from '@/icons/PdfPlaceholder';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';

type BaseProps = {
  src: string;
  title: string;
};

export function Base(props: BaseProps) {
  const { src, title } = props;
  return <EmbedIframe placeholderIcon={PdfPlaceholder} workMode="Preview" title={title} src={src} />;
}
