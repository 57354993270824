import { Button, useMantineTheme } from '@mantine/core';
import { useTranslations } from 'next-intl';

import * as classes from './CollapseButton.css';
import { ChevronDown } from '@/icons/ChevronDown';

type CollapseButtonProps = {
  opened: boolean;
  onToggle: () => void;
};

export function CollapseButton(props: CollapseButtonProps) {
  const { opened, onToggle } = props;
  const theme = useMantineTheme();
  const t = useTranslations('widgets.openDiscussionWidget');

  return (
    <Button
      variant="white"
      color="dark"
      onClick={onToggle}
      rightSection={
        <ChevronDown width="13" color={theme.colors.accent?.[6]} className={opened ? classes.rotatedIcon : undefined} />
      }
      className={classes.button}
    >
      {opened ? t('close') : t('open')}
    </Button>
  );
}
