import { ActionIcon } from '@mantine/core';

import { ChevronDown } from '@/icons/ChevronDown';
import { ChevronUp } from '@/icons/ChevronUp';
import { vars } from '@/utils/mantineVanillaIntegration/vars';

type GradeChevronProps = {
  ['data-testid']?: string;
  disabled: boolean;
  onClick: () => void;
  variant: 'up' | 'down';
};

export const GradeChevron = (props: GradeChevronProps) => {
  const { 'data-testid': dataTestId, variant, disabled, onClick } = props;
  const ChevronIcon = variant === 'up' ? ChevronUp : ChevronDown;

  return (
    <ActionIcon
      data-testId={dataTestId}
      aria-label={`Grade ${variant}`}
      color="systemDark.1"
      disabled={disabled}
      onClick={onClick}
      variant="filled"
    >
      <ChevronIcon color={disabled ? vars.colors?.gray?.[6] : vars.colors?.accent?.[6]} width="16" />
    </ActionIcon>
  );
};
