import * as Y from 'yjs';

import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { ManifestInitializedProps, InitializePropsOptions } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';
import { createNewFlexSectionData } from '../FlexSection/create';
import { createNewImageData } from '../Image/create';
import { createNewTextData } from '../Text/create';
import { CombinedTitleConfig } from '../questionConfigs';

export function createNewCombinedTitleData(
  options: InitializePropsOptions,
  initializationConfig?: CombinedTitleConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { locale, isPlenaryLesson } = options;
  const { content = '', style = {}, imageConfig } = initializationConfig ?? {};

  const [imageData] = createNewImageData({ locale, isPlenaryLesson }, imageConfig);
  const [textData] = createNewTextData(
    { locale, isPlenaryLesson },
    { content, textType: 'header', withParagraphOption: false }
  );

  const [horizontalSectionData] = createNewFlexSectionData(FlexSectionDirection.row, [
    { id: imageData.id, width: 60, height: 60, span: 1, column: 0, isDeletable: false, isDraggable: false },
    { id: textData.id, width: 300, height: 'auto', isDeletable: false, isDraggable: false }
  ]);

  const { id: horizontalSectionId } = horizontalSectionData;
  const childrenArray = new Y.Array();
  childrenArray.push([horizontalSectionId]);

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.CombinedTitle],
        ['children', childrenArray],
        ['style', new Y.Map(Object.entries(style))]
      ])
    },
    horizontalSectionData,
    imageData,
    textData
  ];
}
