import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FPresenterActionBar%2FPresenterActionBar.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VU226jMBB9z1fMPlRKVjUyYXOpI%2FVXVgYPMF2wkXES0lX%2FfWUuKYSkfVgRoRjOzDk%2BPkPwO1Q5z7fE4e8CIDGFsQJO0i4Zaxc1k0mC2rHt6rD4WAQDPnyIz6y8sP0NWuTmhPY7jt20aj2qimXyJ7PmqBX7okG4gh9UVsY6qd2kV9R2UVRXhbwISAtsDguAytTkyGgBKTWo%2FKPYOGdKAdwv3o61o%2FTCEqMdaifA06D1r86kXC4g5PzJL98ZaYWNgDXnXWfySIYn1K4WoI3GG0Wv8LNVdQuVR2cm0F%2F3xX8hrpJKkc6YpSx3AkKL5cSZT0SB6T3AiH3TspfSZqTZ1Z1gY7Hs%2FGpYnUtlzgK4v4Jo598BB5vFcsmfof8F4WZ1o6I3MSU37GJCve386aUO3GEQrjfzHY2FdG7PDik2VqFlVio61l7ufU9IF6SR1U5aJyAKZmQjiS%2B9Ow0buNZXa3x608LryUkp1P6Zs1IPmbtWeTvrSdv4tu12aDsCJS2o7ZgaWwqwxkmHy3DPFWbTYVIPsOxljsUH2DvQtIWec3LI6kom6L0%2FW1lNUFmf84fDFgXb%2FW6wrYtkO0V9ftv%2F809Am6%2F1JnqGzxsP9rOUfXPs%2F5Xg69yH3A%2F%2BaNN5Z013fFG%2Ft2nExJVrUknjSp%2FdR%2BF7676NXj%2B9k84E9FPEYtMcFh%2F%2FALjeC4jfBQAA%22%7D"
export var actionsGroup = {folded:'_1dh0h6i9',unfolded:'_1dh0h6ib _1dh0h6i9'};
export var collapse = '_1dh0h6i2';
export var iconPrimary = '_1dh0h6i0';
export var iconSecondary = '_1dh0h6i1';
export var nonBreakableText = '_1dh0h6if';
export var presenterActionBar = {slidesOpen:'_1dh0h6i4 _1dh0h6i6',slidesClose:'_1dh0h6i4 _1dh0h6i5'};
export var presenterActionBarWrapper = '_1dh0h6i3';
export var rotate = {right:'_1dh0h6ic',bottom:'_1dh0h6id',top:'_1dh0h6ie'};
export var slidesCarouselItem = '_1dh0h6ij';
export var slidesCarouselWrapper = '_1dh0h6ig';
export var slidesCarouselbutton = '_1dh0h6ii';
export var slidesCarouselbuttonWrapper = '_1dh0h6ih';