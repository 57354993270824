import { Box, Collapse, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cx from 'clsx';
import { useTranslations } from 'next-intl';
import { CSSProperties } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import * as classes from './Studio.css';
import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { PRESENTATION_AREA_WIDTH_PX } from '@/consts/styleConsts';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { getThemeBorderRadiusStyle, getThemeBorderStyle } from '@/utils/theme.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { Studio as FlexSection } from '@/widgets/FlexSection/studio';
import { CollapseButton } from '@/widgets/OpenDiscussion/CollapseButton';
import { Config } from '@/widgets/OpenDiscussion/config';
import { useOpenDiscussionStyleProperties } from '@/widgets/OpenDiscussion/hooks/useOpenDiscussionStyleProperties';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

export const OpenDiscussion = () => {
  const t = useTranslations('widgets.openDiscussionWidget');
  const { id, document } = useContentObject();
  const [opened, { toggle }] = useDisclosure(true);
  const { contentDirection } = useContentLanguage();

  const [headlineSectionId] = useContentObjectProperty<string>('children.0');

  const {
    direction,
    backgroundColor,
    border,
    borderRadius,
    alignTo,
    padding,
    gap,
    themeBackgroundColor,
    themeBorder,
    themeBorderRadius,
    themeAlignTo,
    themePadding,
    themeGap
  } = useOpenDiscussionStyleProperties(headlineSectionId, document);

  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const borderStyle = getThemeBorderStyle(themeBorder, undefined, {
    borderColor: border?.borderColor,
    borderWidth: border?.borderWidth,
    borderStyle: border?.borderStyle
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(themeBorderRadius, borderRadius ?? {}, contentDirection);

  const headlineSectionStyles: CSSProperties = {
    backgroundColor: backgroundColor ?? themeBackgroundColor,
    padding: padding ?? themePadding,
    ...borderStyle,
    ...borderRadiusStyle
  };

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>

      <div className={cx(customClassNames)}>
        <ContentObjectProvider id={horizontalSectionId} document={document} type={WidgetType.OpenDiscussion}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            <Box className={classes.wrapper}>
              <Flex gap={0} direction="column" align="flex-start" w={PRESENTATION_AREA_WIDTH_PX}>
                <Collapse in={opened} style={headlineSectionStyles} className={classes.collapsible}>
                  <FlexSection
                    shouldApplyNestStyles={false}
                    withConfig={false}
                    gap={gap ?? themeGap}
                    alignItems={alignTo ?? themeAlignTo}
                    direction={direction}
                    className={classes.headlineSection}
                  />
                </Collapse>
                <CollapseButton onToggle={toggle} opened={opened} />
              </Flex>
              <Flex justify="center" align="center" direction="row" className={classes.answerAreaPlaceholder} h={24}>
                <Text size="24" fw={500} c="systemDark.6">
                  {t('answerAreaPlaceholder')}
                </Text>
              </Flex>
            </Box>
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
      </div>
    </>
  );
};
