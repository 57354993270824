import { RandomizeAnswers } from './RandomizeAnswers';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';

export function Settings() {
  return (
    <>
      <EvaluationSettings />
      <RandomizeAnswers />
      <Feedback />
    </>
  );
}
