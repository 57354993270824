export const isVar = (text: unknown): text is `var(--${string})` =>
  typeof text === 'string' && text.startsWith('var(--');

export type ThemeVars<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? Array<ThemeVars<U>> // Array handling
    : T[K] extends object
    ? ThemeVars<T[K]> // Object handling
    : `var(--${string})`; // Base case for primitive types
};

export const themeToVars = <T>(theme: T) => {
  return themeToVarsInternal<T>(theme, '-');
};

const themeToVarsInternal = <T>(themeNode: T, currentVariableName: string): ThemeVars<T> => {
  let nodeVars;

  if (Array.isArray(themeNode)) {
    nodeVars = themeNode.reduce((currentThemeVars, themeNodeElement, index) => {
      const partialNodeVars = themeToVarsInternal(themeNodeElement, currentVariableName + '-' + index);

      return {
        ...currentThemeVars,
        ...(partialNodeVars && { [index]: partialNodeVars })
      };
    }, {});
  } else if (typeof themeNode === 'object' && themeNode !== null) {
    nodeVars = Object.entries(themeNode ?? {}).reduce(
      (currentThemeVars, [themeNodeElementKey, themeNodeElementValue]) => {
        const partialNodeVars = themeToVarsInternal(
          themeNodeElementValue,
          currentVariableName + '-' + themeNodeElementKey
        );

        return {
          ...currentThemeVars,
          ...(partialNodeVars && { [themeNodeElementKey]: partialNodeVars })
        };
      },
      {}
    );
  } else {
    if (isVar(themeNode)) {
      nodeVars = themeNode;
    } else {
      nodeVars = `var(${currentVariableName})`;
    }
  }

  return nodeVars;
};
