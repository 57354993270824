import { useDebouncedValue, useOs, useViewportSize } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from 'shared/consts/breakpoints';
import { DeviceType } from 'shared/types/DeviceType';

export function useDeviceType(shouldDebounce?: boolean) {
  const [currentDevice, setCurrentDevice] = useState<DeviceType>('pc');
  const os = useOs();

  const { width } = useViewportSize();
  const [debouncedWidth] = useDebouncedValue(width, shouldDebounce ? 200 : 0);

  useEffect(() => {
    if (debouncedWidth) {
      if (debouncedWidth > TABLET_BREAKPOINT) {
        setCurrentDevice('pc');
      } else if (debouncedWidth > MOBILE_BREAKPOINT) {
        setCurrentDevice('tablet');
      } else {
        setCurrentDevice('mobile');
      }
    }
  }, [debouncedWidth]);

  return { os, currentDevice };
}

export const BASE_FONT_SIZE = 16;

export function useDeviceView(widthBasedDeviceType?: DeviceType): { deviceView: DeviceType; htmlFontSize: number } {
  const router = useRouter();
  const routerDeviceType = router.query.device as DeviceType | undefined;
  const deviceType = widthBasedDeviceType ?? routerDeviceType ?? ('pc' as DeviceType);
  const deviceBreakPoint = deviceType === 'mobile' ? MOBILE_BREAKPOINT : TABLET_BREAKPOINT;
  const { width } = useViewportSize();
  const { currentDevice } = useDeviceType();

  if (deviceType === 'pc') {
    if (currentDevice === 'mobile') {
      return {
        deviceView: 'mobile',
        htmlFontSize: (BASE_FONT_SIZE * width) / MOBILE_BREAKPOINT
      };
    } else if (currentDevice === 'tablet') {
      return {
        deviceView: 'tablet',
        htmlFontSize: (BASE_FONT_SIZE * width) / TABLET_BREAKPOINT
      };
    } else {
      const PC_BREAKPOINTS = [920, 1050, 1180, 1310];
      const PC_FONT_SCALES = [0.6, 0.7, 0.8, 0.9];
      const activeIndex = PC_BREAKPOINTS.findIndex((breakpoint: number) => width <= breakpoint);
      return { deviceView: 'pc', htmlFontSize: (activeIndex < 0 ? 1 : PC_FONT_SCALES[activeIndex]) * BASE_FONT_SIZE };
    }
  } else {
    return {
      deviceView: deviceType,
      htmlFontSize: width > deviceBreakPoint ? BASE_FONT_SIZE : (BASE_FONT_SIZE * width) / deviceBreakPoint
    };
  }
}
