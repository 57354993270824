import { Flex } from '@mantine/core';

import { ManualGrade } from './ManualGrade';
import { ReadOnlyGrade } from './ReadOnlyGrade';
import { useCanUpsertQuestionGrade } from '@/components/CanProvider';

type QuestionGradeProps = {
  questionId: string;
  grade?: number;
  requiresManualGrade?: boolean;
  scoreWeight?: number;
};

export const QuestionGrade = (props: QuestionGradeProps) => {
  const { questionId, grade, requiresManualGrade, scoreWeight } = props;

  const canUpsertQuestionGrade = useCanUpsertQuestionGrade();

  if (!scoreWeight) {
    return null;
  }

  return (
    <Flex justify="center" w="100%" mt={10}>
      {requiresManualGrade && canUpsertQuestionGrade ? (
        <ManualGrade questionId={questionId} scoreWeight={scoreWeight} />
      ) : (
        <ReadOnlyGrade grade={grade} scoreWeight={scoreWeight} />
      )}
    </Flex>
  );
};
