import { CustomLoadingOverlayProps } from '@hendrx/modules/ui';

import { useStyles } from './LoadingOverlay.styles';

export function LoadingOverlay({ visible = true, children }: CustomLoadingOverlayProps) {
  const { classes } = useStyles();
  return (
    <>
      {visible && (
        <>
          <div className={classes.overlay} />
          <div className={classes.loader}>{children}</div>
        </>
      )}
    </>
  );
}
