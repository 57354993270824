import { Box, ThemeIcon } from '@mantine/core';
import { ReactElement } from 'react';

import { QuestionListBulletTypes } from 'shared/types/QuestionGroupList';

import * as classes from './ListBulletWrapper.css';
import { ReactComponent as TrianglePoint } from './resources/triangle-point.svg';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useDragInfo } from '@/utils/useDragInfo';

export type ListBulletWrapperProps = {
  children: ReactElement;
  questionListBulletType: QuestionListBulletTypes;
  itemId?: string;
};

export function ListBulletWrapper(props: ListBulletWrapperProps) {
  const { children, questionListBulletType, itemId } = props;
  const { contentDirection } = useContentLanguage();

  const { draggedItemId, isDragging } = useDragInfo();
  const renderBulletPoint = !(isDragging && draggedItemId === itemId);

  return (
    <Box className={classes.bulletListWrapper}>
      {renderBulletPoint && questionListBulletType !== QuestionListBulletTypes.None && (
        <span className={classes.iconContainer}>
          {questionListBulletType === QuestionListBulletTypes.Triangle ? (
            <TrianglePoint className={contentDirection === 'rtl' ? classes.rotated : undefined} />
          ) : (
            <ThemeIcon radius="xl">
              <span className="themed-list-item" />
            </ThemeIcon>
          )}
        </span>
      )}

      <Box className={classes.childWrapper}>{children}</Box>
    </Box>
  );
}
