import { create } from 'zustand';

interface HoverableStore {
  hoveredId: string | null;
  hoverOver: (id?: string | null) => void;
}

export const useHoverableStore = create<HoverableStore>(set => ({
  hoveredId: null,
  hoverOver: id => set(() => ({ hoveredId: id }))
}));
