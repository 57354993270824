import { PDF_MIME_TYPE } from '@mantine/dropzone';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { PdfPlaceholder } from '@/icons/PdfPlaceholder';
import { useFileUpload } from '@/queries/fileUpload';
import { Manifest } from '@/widgets/PdfDocument/Manifest';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';
import { UploadDropzone } from '@/widgets/_components/widget/UploadDropzone';

type BaseProps = {
  src: string;
  title: string;
  setSrc: (src: string) => void;
};

export const Base = forwardRef<() => void | null, BaseProps>((props, ref) => {
  const { src, setSrc, title } = props;

  const t = useTranslations('widgets');
  const { mutateAsync: uploadFile, isLoading } = useFileUpload();
  const isPdfDocumentReady = Boolean(src && !isLoading);

  return (
    <>
      {isPdfDocumentReady && <EmbedIframe workMode="Edit" title={title} src={src} />}

      <UploadDropzone
        Icon={PdfPlaceholder}
        src={src}
        isReady={isPdfDocumentReady}
        maxSize={Manifest.additionalData?.maxFileSizeBytes}
        maxFiles={Manifest.additionalData?.maxFiles}
        accept={PDF_MIME_TYPE}
        placeholderText={t('pdfDocumentWidget.idleText')}
        upload={uploadFile}
        dropAction={({ src }) => setSrc(src)}
        dropError={t('pdfDocumentWidget.unableUpload')}
        ref={ref}
      />
    </>
  );
});

Base.displayName = 'Base';
