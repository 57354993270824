import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { ClozeOptionType } from 'shared/types/ClozeQuestion';

type AddClozeStylesProps = {
  type: ClozeOptionType;
  isDisabled: boolean;
};

export const useStyles = createStyles((theme, props: AddClozeStylesProps) => {
  const { type, isDisabled } = props;
  return {
    buttonRoot: {
      padding: 0
    },
    buttonInner: {
      padding: `${rem(6)} ${rem(8)}`,
      border: `${rem(1.2)} solid ${isDisabled ? theme.colors.gray[4] : theme.colors.accent[6]}`,
      backgroundColor: isDisabled ? theme.colors.gray[0] : theme.colors.accent[0],
      width: type === ClozeOptionType.Input ? rem(56) : 'max-content',
      borderRadius: rem(3),
      justifyContent: 'unset',
      fontSize: rem(16),
      color: isDisabled ? theme.colors.gray[4] : theme.colors.gray[7],
      cursor: isDisabled ? 'not-allowed' : 'pointer'
    },
    buttonLabel: {
      gap: rem(2)
    }
  };
});
