import max from 'lodash/max';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { getRootMap, useObservedArray, useObservedProperty, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

export const useGetNestLevel = (document: Y.Doc, sectionId: string, containerType: WidgetType.FlexSection) => {
  const rootMap = getRootMap(document);
  const [contentObjects] = useObservedProperty<Y.Map<Y.Map<unknown>>>(rootMap, 'contentObjects');
  const [pagesIds] = useObservedArray<string>(rootMap, 'children');

  const getNestLevel = (id: string, currentNestLevel: number): number => {
    const contentObject = validateYMap(contentObjects.get(id));
    const type = contentObject.get('type') as WidgetType;
    const children = contentObject.get('children')
      ? validateYArray<string>(contentObject.get('children')).toArray()
      : [];

    if (id === sectionId) {
      return currentNestLevel;
    }

    if (children.length === 0) {
      return 0;
    }

    const nestLevel = type === containerType ? currentNestLevel + 1 : currentNestLevel;
    return max(children.map(childId => getNestLevel(childId, nestLevel))) ?? 0;
  };

  return max(pagesIds.map(pageId => getNestLevel(pageId, 1))) ?? 0;
};
