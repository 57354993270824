import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

import { UI_LANGUAGE_COOKIE } from '@/utils/consts';
import { defaultLanguage, Direction, getDirectionFromLanguage } from '@/utils/locale.utils';

export function useUiLanguage() {
  const uiLanguage = (getCookie(UI_LANGUAGE_COOKIE) as string | undefined) ?? defaultLanguage;
  const [uiDirection, setUiDirection] = useState<Direction>(getDirectionFromLanguage(defaultLanguage));

  useEffect(() => {
    setUiDirection(getDirectionFromLanguage(uiLanguage));
  }, [uiLanguage]);

  return { uiLanguage, uiDirection };
}
