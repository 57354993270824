import { CSSProperties } from 'react';
import * as Y from 'yjs';

import { useOpenDiscussionStyleProperties } from './useOpenDiscussionStyleProperties';
import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';

export const useQuestionHeaderStyles = (sectionId: string, document: Y.Doc) => {
  const {
    direction,
    backgroundColor,
    border,
    borderRadius,
    alignTo,
    padding,
    gap,
    themeBackgroundColor,
    themeBorder,
    themeBorderRadius,
    themeAlignTo,
    themePadding,
    themeGap
  } = useOpenDiscussionStyleProperties(sectionId, document);

  const { contentDirection } = useContentLanguage();

  const borderStyle = getThemeBorderStyle(themeBorder, undefined, {
    borderColor: border?.borderColor,
    borderWidth: border?.borderWidth,
    borderStyle: border?.borderStyle
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(themeBorderRadius, borderRadius ?? {}, contentDirection);

  const collapsibleSectionStyles: CSSProperties = {
    backgroundColor: backgroundColor ?? themeBackgroundColor,
    padding: padding ?? themePadding,
    ...borderStyle,
    ...borderRadiusStyle
  };

  return {
    gap: gap ?? themeGap,
    alignTo: alignTo ?? themeAlignTo,
    direction,
    collapsibleSectionStyles
  };
};
