import { AnyAbility } from '@casl/ability';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useMemo } from 'react';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';

import { defineCanRules } from './defineCanRules';
import { useGetAssignmentStatus } from '@/queries/assignment';
import { useUser } from '@/queries/user';
import { parseQueryParam } from '@/utils/query.utils';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useViewMode } from '@/utils/useViewMode';
import { useWorkMode } from '@/utils/useWorkMode';

export const CanContext = createContext<AnyAbility>(defineCanRules());

type CanWrapperProps = {
  children: ReactNode;
};

export const CanProvider = (props: CanWrapperProps) => {
  const { children } = props;

  const { isPlayer, isPlayerActivity, isPlayerAssignment, isPlayerAssignmentTest, isStudioEdit, isStudioPreview } =
    useWorkMode();
  const [viewMode] = useViewMode();
  const { user } = useUser();

  const router = useRouter();
  const studentId = parseQueryParam(router.query.studentId);

  const {
    isEvaluated: isAssignmentEvaluated,
    isReturnedToFix: isAssignmentReturnedToFix,
    isSubmitted: isAssignmentSubmitted,
    isUnsubmitted: isAssignmentUnsubmitted
  } = useGetAssignmentStatus({ enabled: isPlayer });

  const isTest = isPlayerAssignmentTest;

  const { type } = useLearningObjectType();
  const isLearningActivity = type === LearningObjectTypes.LearningActivity;
  const isPlenaryLesson = type === LearningObjectTypes.PlenaryLesson;

  const canRules = useMemo(
    () =>
      defineCanRules({
        hasStudentId: !!studentId,
        isAssignmentEvaluated,
        isAssignmentReturnedToFix,
        isAssignmentSubmitted,
        isAssignmentUnsubmitted,
        isLearningActivity,
        isPlayer,
        isPlayerActivity,
        isPlayerAssignment,
        isPlenaryLesson,
        isStudioEdit,
        isStudioPreview,
        isTest,
        user,
        viewMode
      }),
    [
      isAssignmentEvaluated,
      isAssignmentReturnedToFix,
      isAssignmentSubmitted,
      isAssignmentUnsubmitted,
      isLearningActivity,
      isPlayer,
      isPlayerActivity,
      isPlayerAssignment,
      isPlenaryLesson,
      isStudioEdit,
      isStudioPreview,
      isTest,
      studentId,
      user,
      viewMode
    ]
  );

  return <CanContext.Provider value={canRules}>{children}</CanContext.Provider>;
};
