import { Box } from '@mantine/core';
import { useEffect } from 'react';

import { Manifest } from '@/widgets/Spacer/Manifest';
import { useStyles } from '@/widgets/Spacer/studio/Studio.styles';
import { StudioProps } from '@/widgets/types/studioProps';

export function Spacer(props: StudioProps) {
  const { initialize } = props;

  const { classes } = useStyles();

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: true
    });
  }, [initialize]);

  return <Box className={classes.spacer} />;
}
