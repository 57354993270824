import { useCallback } from 'react';

import { removeSection } from 'shared/utils/removeSection';
import { WidgetType } from 'shared/utils/widgets';
import { getTargetSectionData } from 'shared/widgetsSDK/getTargetSectionData';
import { getContentObjectsMap, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { moveItemBetweenSections } from './moveItemBetweenSections';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useMoveItemBetweenSections() {
  const { document } = useContentObject();

  return useCallback(
    (itemId: string, currentSectionId: string, newParentId: string, position: number) => {
      document.transact(() => {
        const contentObjectsMap = getContentObjectsMap(document);
        const currentSectionData = validateYMap(contentObjectsMap.get(currentSectionId));
        const newParentContentObject = contentObjectsMap.get(newParentId);
        const newSectionData = getTargetSectionData(document, newParentContentObject);

        const currentSectionChildren = validateYArray(currentSectionData.get('children'));

        if (newSectionData) {
          moveItemBetweenSections(itemId, currentSectionData, newSectionData, position);
        }

        if (currentSectionChildren.length === 0 && currentSectionData.get('type') === WidgetType.Section) {
          removeSection(document, currentSectionId);
        }
      });
    },
    [document]
  );
}
