import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    comment: {
      borderColor: theme.colors.dark[1],
      borderRadius: rem(8),
      borderStyle: 'solid',
      borderWidth: rem(1),
      lineHeight: 'normal',
      padding: rem(12),
      marginBottom: rem(16),
      '.ProseMirror': {
        minHeight: rem(64)
      }
    }
  };
});
