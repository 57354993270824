import { usePrevious } from '@mantine/hooks';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';
import { selectContentObjects } from '@/utils/selectContentObjects';

export const useContentObjectsChanged = (onChange: () => void, questionsOnly = true) => {
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);

  const memoizedSelectContentObjects = useCallback(
    (contentObjects: Y.Map<Y.Map<unknown>>) =>
      selectContentObjects(contentObjects, { includeLabel: false, questionsOnly }),
    [questionsOnly]
  );
  const [questions] = useObservedProperty(rootMap, 'contentObjects', {
    deepObserve: true,
    selector: memoizedSelectContentObjects
  });
  const previousQuestions = usePrevious(questions);

  const isChanged = !isEqual(questions, previousQuestions);

  useEffect(() => {
    onChange();
  }, [isChanged, onChange]);
};
