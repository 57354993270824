import { Divider, Switch, Tabs, Text, TextInput } from '@mantine/core';
import has from 'lodash/has';
import { useTranslations } from 'next-intl';

import { GoToType, ObjectFit } from 'shared/types/Image';

import { useStyles } from './Config.styles';
import { Select } from '@/components/Select';
import { UploadFile } from '@/components/UploadFile';
import { ImageUpload } from '@/icons/ImageUpload';
import { Manifest } from '@/widgets/Image/Manifest';
import { ImageSchemaType } from '@/widgets/Image/Schema';
import { GoToOption } from '@/widgets/Image/config/GoToOption';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

type BaseProps = {
  alt: string;
  errors: Partial<ImageSchemaType>;
  goToType: GoToType | null;
  isDecorative: boolean;
  mobileObjectFit: ObjectFit;
  mobileSrc: string;
  objectFit: ObjectFit;
  setAlt: (alt: string) => void;
  setGoToType: (goToType: GoToType | null) => void;
  setIsDecorative: (isDecorative: boolean) => void;
  setMobileObjectFit: (mobileObjectFit: ObjectFit) => void;
  setMobileSrc: (mobileSrc: string) => void;
  setObjectFit: (objectFit: ObjectFit) => void;
  withCustomCSSClasses: boolean;
  withGoToSettings: boolean;
};

export function Base(props: BaseProps) {
  const {
    alt,
    errors,
    goToType,
    isDecorative,
    mobileObjectFit,
    mobileSrc,
    objectFit,
    setAlt,
    setGoToType,
    setIsDecorative,
    setMobileObjectFit,
    setMobileSrc,
    setObjectFit,
    withCustomCSSClasses,
    withGoToSettings
  } = props;

  const t = useTranslations('widgets');
  const { classes } = useStyles();
  const objectFitData = Object.values(ObjectFit).map(value => ({ value, label: t(`imageSettings.${value}`) }));
  const showAltText = !isDecorative;

  // 'decorative' settings should be visible only for the stage image widget,
  // but not for images in i.e. multiselect question where isDecorative remains undefined.
  const showDecorativeSettings = isDecorative !== undefined;

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        {showDecorativeSettings && (
          <FormControl>
            <Switch
              checked={isDecorative}
              onChange={event => setIsDecorative(event.currentTarget.checked)}
              label={t('imageSettings.decorativeImage')}
            />
          </FormControl>
        )}

        {showAltText && (
          <FormControl title={t('imageSettings.addAlternativeText')} withAsterisk={!isDecorative}>
            <TextInput
              value={alt}
              onChange={event => setAlt(event.currentTarget.value)}
              placeholder={t('imageSettings.addAlternativeTextPlaceholder') ?? ''}
              error={has(errors, 'alt') && t('imageSettings.addAlternativeTextError')}
              data-testid="alternative-text-field"
            />
          </FormControl>
        )}

        <FormControl title={t('imageSettings.objectFit')}>
          <Select
            data={objectFitData}
            value={objectFit}
            onChange={(value: string | null) => value && setObjectFit(value as ObjectFit)}
          />
        </FormControl>

        <Divider />

        <Text size="lg" component="h3" className={classes.subtitle}>
          {t('imageSettings.mobileVersionOptional')}
        </Text>
        <FormControl title={t('imageSettings.addImage')}>
          <UploadFile
            src={mobileSrc}
            alt={alt}
            Icon={ImageUpload}
            text={t('imageSettings.uploadImage') ?? ''}
            maxSize={Manifest.additionalData?.maxFileSizeBytes}
            maxFiles={Manifest.additionalData?.maxFiles}
            accept={Manifest.additionalData?.acceptFileType}
            onDrop={newSrc => {
              setMobileSrc(newSrc);
            }}
            onDelete={() => {
              setMobileSrc('');
            }}
            dropError={t('imageWidget.unableUpload')}
          />
        </FormControl>
        <FormControl title={t('imageSettings.mobileObjectFit')}>
          <Select
            data={objectFitData}
            value={mobileObjectFit}
            onChange={(value: string | null) => value && setMobileObjectFit(value as ObjectFit)}
          />
        </FormControl>

        {withCustomCSSClasses && <CustomCSSClassesInput />}

        {withGoToSettings && (
          <FormControl>
            <Switch
              checked={!!goToType}
              onChange={() => setGoToType(goToType ? null : GoToType.Page)}
              label={t('imageSettings.goToLabel')}
            />
            {goToType && <GoToOption goToType={goToType} setGoToType={setGoToType} />}
          </FormControl>
        )}
      </Tabs.Panel>
    </WidgetConfig>
  );
}
