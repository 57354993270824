import { PieChart as RechartsPieChart, Pie, Cell, Label, Legend, ResponsiveContainer } from 'recharts';

import { ASPECT_RATIO, Data } from './Chart';
import { PieChartLabel } from './PieChartLabel';
import { PieChartLabelCenter } from './PieChartLabelCenter';
import { PieChartLegend } from './PieChartLegend';
import { Direction } from '@/utils/locale.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';

type PieChartProps = {
  data: Data;
  directionStyle: { direction: Direction };
  getBarColor: (index: number, isCorrect?: boolean) => string;
};

export const PieChart = (props: PieChartProps) => {
  const { data, directionStyle, getBarColor } = props;

  const { uiDirection } = useUiLanguage();

  const total = data.reduce((total, item) => total + item.value, 0);

  return (
    <ResponsiveContainer aspect={ASPECT_RATIO} style={directionStyle}>
      <RechartsPieChart>
        <Pie
          data={data}
          cx="50%"
          cy="45%"
          innerRadius="50%"
          outerRadius="85%"
          paddingAngle={1}
          dataKey="value"
          labelLine={false}
          label={PieChartLabel}
        >
          <Label content={props => <PieChartLabelCenter {...props} total={total} />} />
          {data.map((_, index) => (
            <Cell key={index} fill={getBarColor(index)} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="top"
          align={uiDirection === 'ltr' ? 'left' : 'right'}
          content={props => <PieChartLegend {...props} data={data} />}
        />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};
