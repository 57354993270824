import * as Y from 'yjs';
import { z } from 'zod';

import { evaluateClozeQuestion } from './evaluateClozeQuestion';
import { evaluateFillInTheBlanksQuestion } from './evaluateFillInTheBlanksQuestion';
import { evaluateInteractiveQuiz } from './evaluateInteractiveQuiz';
import { evaluateQuestionGroupList } from './evaluateQuestionGroupList';
import { evaluateSelectionQuestion } from './evaluateSelectionQuestion';
import { getQuestionData } from './questionDataUtils';
import { validatePayload } from './validatePayload';

type EvaluatableWidgets =
  | 'SelectionQuestion'
  | 'ClozeQuestion'
  | 'FillInTheBlanksQuestion'
  | 'QuestionGroupList'
  | 'InteractiveQuiz';

const selectionQuestionAnswersSchema = z.array(z.string());
const clozeQuestionAnswersSchema = z.record(z.string());
const fitbQuestionAnswersSchema = z.record(z.object({ id: z.string(), value: z.string() }));
const openQuestionMockedSchema = z.object({});
const interactiveQuizAnswersSchema = z.array(z.string());

export const payloadSchema = {
  SelectionQuestion: z.object({ answers: selectionQuestionAnswersSchema }),
  ClozeQuestion: z.object({ answers: clozeQuestionAnswersSchema }),
  FillInTheBlanksQuestion: z.object({ answers: fitbQuestionAnswersSchema }),
  QuestionGroupList: z.object({
    shownQuestions: z.array(z.string()),
    requiredQuestionIds: z.array(z.string()),
    numberOfQuestionsToEvaluate: z.number(),
    answers: z.record(
      z.union([
        selectionQuestionAnswersSchema,
        clozeQuestionAnswersSchema,
        fitbQuestionAnswersSchema,
        openQuestionMockedSchema
      ])
    )
  }),
  InteractiveQuiz: z.object({ answers: interactiveQuizAnswersSchema })
} satisfies Record<EvaluatableWidgets, unknown>;

export type QuestionDataType = {
  type: string;
  contentObjectData: Y.Map<unknown>;
  sensitiveData: Y.Map<unknown>;
  widgetFormData: Y.Map<unknown>;
};
type EvaluateQuestionByTypeOptions = QuestionDataType & {
  payload: unknown;
  contentObjectsMap: Y.Map<Y.Map<unknown>>;
  sensitiveDataMap: Y.Map<unknown>;
  widgetsFormDataMap: Y.Map<unknown>;
};
export const evaluateQuestionByType = (options: EvaluateQuestionByTypeOptions) => {
  const {
    type,
    payload,
    contentObjectData,
    sensitiveData,
    widgetFormData,
    contentObjectsMap,
    sensitiveDataMap,
    widgetsFormDataMap
  } = options;

  if (type === 'SelectionQuestion') {
    const safePayload = validatePayload(payload, payloadSchema[type]);
    return evaluateSelectionQuestion(contentObjectData, sensitiveData, widgetFormData, safePayload.answers);
  }
  if (type === 'ClozeQuestion') {
    const safePayload = validatePayload(payload, payloadSchema[type]);
    return evaluateClozeQuestion(contentObjectData, sensitiveData, widgetFormData, safePayload.answers);
  }
  if (type === 'FillInTheBlanksQuestion') {
    const safePayload = validatePayload(payload, payloadSchema[type]);
    return evaluateFillInTheBlanksQuestion(contentObjectData, sensitiveData, widgetFormData, safePayload.answers);
  }
  if (type === 'OpenQuestion') {
    return { feedbackMessageType: null, percentageScore: 0 };
  }
  if (type === 'QuestionGroupList') {
    const safePayload = validatePayload(payload, payloadSchema[type]);
    const { shownQuestions, requiredQuestionIds, numberOfQuestionsToEvaluate, answers } = safePayload;
    const subQuestionsData: Record<string, QuestionDataType> = {};
    shownQuestions.forEach(
      id => (subQuestionsData[id] = getQuestionData(id, contentObjectsMap, sensitiveDataMap, widgetsFormDataMap))
    );
    return evaluateQuestionGroupList({
      subQuestionsData,
      shownQuestions,
      requiredQuestionIds,
      numberOfQuestionsToEvaluate,
      answers,
      contentObjectsMap,
      sensitiveDataMap,
      widgetsFormDataMap
    });
  }
  if (type === 'InteractiveQuiz') {
    const safePayload = validatePayload(payload, payloadSchema[type]);
    return evaluateInteractiveQuiz(contentObjectData, sensitiveData, safePayload.answers);
  }

  throw new Error('Unrecognized question');
};
