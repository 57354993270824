import { mergeAttributes, Node } from '@tiptap/core';

export const BaseFillInTheBlanksExtension = Node.create({
  name: 'fillInTheBlanksNode',
  group: 'inline',
  content: 'block*',
  inline: true,
  // marks: 'textStyle',
  addAttributes() {
    return {
      fillInTheBlanksId: {
        default: null,
        parseHTML: element => element.getAttribute('data-fitb-id'),
        renderHTML: attributes => {
          return {
            'data-fitb-id': attributes['fillInTheBlanksId']
          };
        }
      },
      fontSize: {
        ...this.parent?.(),
        default: null,
        parseHTML: element => element.getAttribute('data-font-size'),
        renderHTML: attributes => {
          return {
            'data-font-size': attributes['fontSize'],
            style: `font-size: ${attributes['fontSize']}`
          };
        }
      },
      fontFamily: {
        ...this.parent?.(),
        default: null,
        parseHTML: element => element.getAttribute('data-font-family'),
        renderHTML: attributes => {
          return {
            'data-font-family': attributes['fontFamily'],
            style: `font-family: ${attributes['fontFamily']}`
          };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: 'fitb-option'
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['fitb-option', mergeAttributes(HTMLAttributes), 0];
  }
});
