import { Box, Flex, Text } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import React, { ComponentType, ReactNode, useDeferredValue } from 'react';

import { useStyles } from './Placeholder.styles';

type PlaceholderTextProps = {
  text?: string | null;
  subText?: string | null;
};

type PlaceholderProps = {
  isContentOnly?: boolean;
  isError?: boolean;
  Icon: ComponentType;
  text?: string | null;
  subText?: string | null;
  className?: string;
  dataTestId?: string;
};

function PlaceholderText(props: PlaceholderTextProps) {
  const { text, subText } = props;

  return (
    <Box ta="center">
      {text}
      {subText && (
        <Text fw="bold" ta="center">
          {subText}
        </Text>
      )}
    </Box>
  );
}

export function PlaceholderContent(props: PlaceholderProps & { children?: ReactNode }) {
  const { isContentOnly = false, isError, Icon, dataTestId, className, children } = props;
  const { classes, cx } = useStyles({ isContentOnly, isError });
  return (
    <Flex justify="center" align="center" direction="column" className={cx(classes.placeholderContent, className)}>
      <Text className={classes.placeholderIcon} c={isError ? 'pink.6' : 'accent.4'}>
        <Icon data-testid={dataTestId} />
      </Text>
      {children}
    </Flex>
  );
}

export function Placeholder(props: PlaceholderProps) {
  const { isContentOnly = false, text, subText, isError } = props;
  const { classes } = useStyles({ isContentOnly });
  const { ref, width = 0, height = 0 } = useElementSize();
  const shouldRenderTooltip = useDeferredValue(text && (width < 300 || height < 150));

  return (
    <Tooltip
      label={<PlaceholderText text={text} subText={subText} />}
      position="bottom"
      withArrow
      disabled={!shouldRenderTooltip}
    >
      <Box className={classes.placeholderContainer} ref={ref}>
        <PlaceholderContent {...props}>
          {text && (
            <Text className={classes.placeholderText} c={isError ? 'pink.6' : 'systemDark.7'} size="xl">
              <PlaceholderText text={text} subText={subText} />
            </Text>
          )}
        </PlaceholderContent>
      </Box>
    </Tooltip>
  );
}
