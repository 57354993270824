import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function Cells() {
  const t = useTranslations('components.theme.settings');
  const { document, id } = useContentObject();
  const [themeCellPrimaryBackgroundColor] = useActivityThemeStylesProperty<string | null>(
    document,
    'table.cellPrimaryBackgroundColor'
  );
  const [globalCellPrimaryBackgroundColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.primary.contrast'
  );
  const [cellPrimaryBackgroundColor, setCellPrimaryBackgroundColor] = useWidgetStyle<string | null>(
    document,
    id,
    'cellPrimaryBackgroundColor'
  );
  const [themeCellSecondaryBackgroundColor] = useActivityThemeStylesProperty<string | null>(
    document,
    'table.cellSecondaryBackgroundColor'
  );
  const [globalCellSecondaryBackgroundColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.secondary.contrast'
  );
  const [cellSecondaryBackgroundColor, setCellSecondaryBackgroundColor] = useWidgetStyle<string | null>(
    document,
    id,
    'cellSecondaryBackgroundColor'
  );
  const [themeCellPadding] = useActivityThemeStylesProperty<number>(document, 'table.cellPadding');
  const [cellPadding, setCellPadding] = useWidgetStyle<number | null>(document, id, 'cellPadding');
  const [themeRowSpacing] = useActivityThemeStylesProperty<number>(document, 'table.rowSpacing');
  const [rowSpacing, setRowSpacing] = useWidgetStyle<number | null>(document, id, 'rowSpacing');
  const [themeColumnSpacing] = useActivityThemeStylesProperty<number>(document, 'table.columnSpacing');
  const [columnSpacing, setColumnSpacing] = useWidgetStyle<number | null>(document, id, 'columnSpacing');

  return (
    <ThemeItemSection label={t('cells')}>
      <ColorInputPanel
        label={t('backgroundColor')}
        color={cellPrimaryBackgroundColor ?? themeCellPrimaryBackgroundColor ?? globalCellPrimaryBackgroundColor}
        setColor={setCellPrimaryBackgroundColor}
        ResetButton={cellPrimaryBackgroundColor && <ResetButton onClick={() => setCellPrimaryBackgroundColor(null)} />}
      />
      <ColorInputPanel
        label={t('alternateBackgroundColor')}
        color={cellSecondaryBackgroundColor ?? themeCellSecondaryBackgroundColor ?? globalCellSecondaryBackgroundColor}
        setColor={setCellSecondaryBackgroundColor}
        ResetButton={
          cellSecondaryBackgroundColor && <ResetButton onClick={() => setCellSecondaryBackgroundColor(null)} />
        }
      />
      <NumberInputPanel
        label={t('cellPadding')}
        value={cellPadding ?? themeCellPadding}
        onChange={setCellPadding}
        ResetButton={!isNil(cellPadding) && <ResetButton onClick={() => setCellPadding(null)} />}
      />
      <NumberInputPanel
        label={t('rowSpacing')}
        value={rowSpacing ?? themeRowSpacing}
        onChange={setRowSpacing}
        ResetButton={!isNil(rowSpacing) && <ResetButton onClick={() => setRowSpacing(null)} />}
      />
      <NumberInputPanel
        label={t('columnSpacing')}
        value={columnSpacing ?? themeColumnSpacing}
        onChange={setColumnSpacing}
        ResetButton={!isNil(columnSpacing) && <ResetButton onClick={() => setColumnSpacing(null)} />}
      />
    </ThemeItemSection>
  );
}
