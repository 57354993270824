import { BorderRadiusThemeProps } from '@hendrx/modules/ui';
import { Box, NumberInput } from '@mantine/core';

import * as classes from '@/components/Theme/Theme.css';

type UnlinkedRadiusInputProps = {
  type: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  borderRadius: Partial<BorderRadiusThemeProps> | undefined;
  setBorderRadiusProperty: (property: keyof BorderRadiusThemeProps, newBorderRadius: number | string | null) => void;
};

export const UnlinkedRadiusInput = (props: UnlinkedRadiusInputProps) => {
  const { type, borderRadius, setBorderRadiusProperty } = props;

  return (
    <Box className={classes.radiusInput}>
      <NumberInput
        value={borderRadius?.[type] ?? 0}
        onChange={newValue => setBorderRadiusProperty(type, newValue)}
        min={0}
      />
    </Box>
  );
};
