import { Button } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import * as Y from 'yjs';

import { genRandId } from 'shared/utils/genRandId';

import { useStyles } from './AddWordToBank.styles';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';
import {
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

export function AddWordToBank() {
  const t = useTranslations('widgets.fillInTheBlanksToolbarExtension');

  const { editor } = useRichTextEditorContext();

  const [isDisabled, setIsDisabled] = useState(editor?.state.selection.empty ?? true);

  const { classes } = useStyles({ isDisabled });

  const { document } = useContentObject();

  const [wordBankOptions, observedWordBankOptions] = useContentObjectArray<WordOption>('wordBankOptions');

  const [correctOptions, setCorrectOptions] = useContentObjectProperty<Y.Map<string>>(
    'correctOptions',
    'sensitiveData'
  );

  const getSelection = useCallback(() => {
    if (!editor) {
      return { content: '', to: 0, from: 0 };
    }
    const { from, to } = editor.state.selection;
    const selectionContent = editor.state.doc.cut(from, to).textContent;
    const content = selectionContent.trim();
    return { content, to, from };
  }, [editor]);

  const updateWordOptions = useCallback(
    (content: string) => {
      const correctOptionId = genRandId();
      const newWordId = genRandId();

      document.transact(() => {
        observedWordBankOptions.push([{ id: correctOptionId, value: content }]);
        const newCorrectOptionsMap = correctOptions.clone();
        newCorrectOptionsMap.set(newWordId, correctOptionId);
        setCorrectOptions(newCorrectOptionsMap);
      }, 'untracked');

      return { newWordId };
    },
    [correctOptions, document, observedWordBankOptions, setCorrectOptions]
  );

  // Check if the current selection contains a cloze
  const selectionContainsCloze = useCallback(() => {
    if (!editor) {
      return false;
    }
    const { from, to } = editor.state.selection;
    let containsCloze = false;
    editor.state.doc.nodesBetween(from, to, node => {
      if (node.type.name === 'fillInTheBlanksNode') {
        containsCloze = true;
        return false;
      }
    });
    return containsCloze;
  }, [editor]);

  const handleAdd = useCallback(
    (content: string, from: number, to: number) => {
      if (!editor || !wordBankOptions) return;

      const nodeAttrs = editor.getAttributes('textStyle');
      const { newWordId } = updateWordOptions(content);

      const result = editor
        .chain()
        .deleteRange({ from, to })
        .insertContentAt(from, {
          type: 'fillInTheBlanksNode',
          attrs: { fillInTheBlanksId: newWordId, ...nodeAttrs }
        })
        .focus()
        .run();

      console.log(editor.getJSON());
      console.log(editor.getHTML());

      return result;
    },
    [editor, updateWordOptions, wordBankOptions]
  );

  const handleDoubleClick = useCallback(
    (event: MouseEvent) => {
      if (!editor) return;

      // Get the position of the double click in the document
      const posAtClick = editor.view.posAtCoords({ left: event.clientX, top: event.clientY });

      if (!posAtClick) return;

      const { pos } = posAtClick;

      // Define boundaries for traversal
      let leftBoundary = pos;
      let rightBoundary = pos;

      // Traverse to the left until space or node boundary
      while (leftBoundary > 0) {
        leftBoundary--; // Move the boundary first before checking
        const charBefore = editor.state.doc.textBetween(leftBoundary, leftBoundary + 1);
        if (charBefore === ' ' || charBefore === '\n' || charBefore === '') {
          leftBoundary++; // Adjust it back to include the space or newline
          break;
        }
        const nodeBefore = editor.state.doc.nodeAt(leftBoundary);
        if (nodeBefore && nodeBefore.type.name === 'fillInTheBlanksNode') {
          leftBoundary++; // Adjust it back to exclude the node
          break;
        }
      }

      // Traverse to the right until space or node boundary
      while (rightBoundary < editor.state.doc.content.size) {
        const charAfter = editor.state.doc.textBetween(rightBoundary, rightBoundary + 1);
        if (charAfter === ' ' || charAfter === '\n' || charAfter === '') {
          break;
        }
        const nodeAfter = editor.state.doc.nodeAt(rightBoundary);
        if (nodeAfter && nodeAfter.type.name === 'fillInTheBlanksNode') {
          break;
        }
        rightBoundary++;
      }

      // Get the content between the new boundaries
      const selectedContent = editor.state.doc.textBetween(leftBoundary, rightBoundary).trim();

      // If the content is empty, return early
      if (!selectedContent) return;

      // Create the new node
      handleAdd(selectedContent, leftBoundary, rightBoundary);
    },
    [editor, handleAdd]
  );

  const handleButtonClick = useCallback(() => {
    const { content, from, to } = getSelection();
    handleAdd(content, from, to);
  }, [getSelection, handleAdd]);

  useEffect(() => {
    const handleSelection = () => {
      setIsDisabled(editor?.state.selection.empty || selectionContainsCloze());
    };

    editor?.on('selectionUpdate', handleSelection);
    addEventListener('dblclick', handleDoubleClick);

    return () => {
      editor?.off('selectionUpdate', handleSelection);
      removeEventListener('dblclick', handleDoubleClick);
    };
  }, [editor, handleDoubleClick, selectionContainsCloze]);

  return (
    <Button
      data-testid="add-word-to-bank-button"
      onClick={handleButtonClick}
      variant="transparent"
      disabled={isDisabled}
      classNames={{ root: classes.buttonRoot, inner: classes.buttonInner }}
    >
      {t('bankButtonLabel')}
    </Button>
  );
}
