import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { PROSE_MIRROR_SELECTOR, getThemeBorderStyle } from '@/utils/theme.utils';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { CLASSNAMES } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const useStyles = createStyles(theme => {
  const tableStyles = theme.other.table;

  const {
    border: themeBorder,
    cellPrimaryBackgroundColor: themeCellPrimaryBackgroundColor,
    cellSecondaryBackgroundColor: themeCellSecondaryBackgroundColor,
    color: themeColor,
    fontFamily: themeFontFamily,
    fontSize: themeFontSize,
    headerBackgroundColor: themeHeaderBackgroundColor,
    headerColor: themeHeaderColor,
    isBorderVisible: themeIsBorderVisible,
    isCaptionVisible: themeIsCaptionVisible,
    isHeaderVisible: themeIsHeaderVisible,
    rowSpacing: themeRowSpacing,
    columnSpacing: themeColumnSpacing,
    cornerRadius: themeCornerRadius,
    cellPadding: themeCellPadding,
    captionBackgroundColor: themeCaptionBackgroundColor,
    captionColor: themeCaptionColor,
    captionFontFamily: themeCaptionFontFamily,
    captionFontSize: themeCaptionFontSize,
    headerFontFamily: themeHeaderFontFamily,
    headerFontSize: themeHeaderFontSize,
    tableStyle: themeTableStyle
  } = tableStyles;

  const globalContentTheme = theme.other.global.content;

  const { document, id } = useContentObject();
  const [tableStyle] = useWidgetStyle<string | null>(document, id, 'tableStyle');
  const [fontFamily] = useWidgetStyle<string | null>(document, id, 'fontFamily');
  const [fontSize] = useWidgetStyle<number | null>(document, id, 'fontSize');
  const [color] = useWidgetStyle<string | null>(document, id, 'color');
  const [cellPrimaryBackgroundColor] = useWidgetStyle<string | null>(document, id, 'cellPrimaryBackgroundColor');
  const [cellSecondaryBackgroundColor] = useWidgetStyle<string | null>(document, id, 'cellSecondaryBackgroundColor');
  const [cellPadding] = useWidgetStyle<number | null>(document, id, 'cellPadding');
  const [rowSpacing] = useWidgetStyle<number | null>(document, id, 'rowSpacing');
  const [columnSpacing] = useWidgetStyle<number | null>(document, id, 'columnSpacing');
  const [cornerRadius] = useWidgetStyle<Partial<BorderRadiusThemeProps> | null>(document, id, 'cornerRadius');
  const [isBorderVisible] = useWidgetStyle<boolean | null>(document, id, 'isBorderVisible');
  const [border] = useWidgetStyle<BorderThemeProps | null>(document, id, 'border');
  const [isCaptionVisible] = useWidgetStyle<boolean | null>(document, id, 'isCaptionVisible');
  const [captionBackgroundColor] = useWidgetStyle<string | null>(document, id, 'captionBackgroundColor');
  const [captionFontFamily] = useWidgetStyle<string | null>(document, id, 'captionFontFamily');
  const [captionColor] = useWidgetStyle<string | null>(document, id, 'captionColor');
  const [captionFontSize] = useWidgetStyle<number | null>(document, id, 'captionFontSize');
  const [isHeaderVisible] = useWidgetStyle<boolean | null>(document, id, 'isHeaderVisible');
  const [headerBackgroundColor] = useWidgetStyle<string | null>(document, id, 'headerBackgroundColor');
  const [headerFontFamily] = useWidgetStyle<string | null>(document, id, 'headerFontFamily');
  const [headerColor] = useWidgetStyle<string | null>(document, id, 'headerColor');
  const [headerFontSize] = useWidgetStyle<number | null>(document, id, 'headerFontSize');

  const mergedBorder = getThemeBorderStyle(border ?? themeBorder, globalContentTheme.colors.decoration.main);
  const borderStyle = {
    ...mergedBorder,
    borderWidth: isBorderVisible ?? themeIsBorderVisible ? mergedBorder.borderWidth : 0
  };

  const captionFont = {
    [PROSE_MIRROR_SELECTOR]: {
      color: captionColor ?? themeCaptionColor ?? globalContentTheme.colors.primary.contrast,
      fontFamily: captionFontFamily ?? themeCaptionFontFamily ?? globalContentTheme.fontFamily,
      fontSize: rem(captionFontSize ?? themeCaptionFontSize)
    }
  };

  const headerFont = {
    [PROSE_MIRROR_SELECTOR]: {
      color: headerColor ?? themeHeaderColor ?? globalContentTheme.colors.primary.contrast,
      fontFamily: headerFontFamily ?? themeHeaderFontFamily ?? globalContentTheme.fontFamily,
      fontSize: rem(headerFontSize ?? themeHeaderFontSize)
    }
  };

  const cellFont = {
    [PROSE_MIRROR_SELECTOR]: {
      color: color ?? themeColor ?? globalContentTheme.colors.primary.contrast,
      fontFamily: fontFamily ?? themeFontFamily ?? globalContentTheme.fontFamily,
      fontSize: rem(fontSize ?? themeFontSize)
    }
  };

  const tbodyAlternateRowStyle = {
    [`.${CLASSNAMES.TableRowWrapper}:nth-of-type(even)`]: {
      backgroundColor: cellPrimaryBackgroundColor ?? themeCellPrimaryBackgroundColor
    },
    [`.${CLASSNAMES.TableRowWrapper}:nth-of-type(odd)`]: {
      backgroundColor: cellSecondaryBackgroundColor ?? themeCellSecondaryBackgroundColor
    }
  };

  const tbodyAlternateColumnStyle = {
    "[class*='cell-col']:nth-of-type(even)": {
      backgroundColor: cellPrimaryBackgroundColor ?? themeCellPrimaryBackgroundColor
    },
    "[class*='cell-col']:nth-of-type(odd)": {
      backgroundColor: cellSecondaryBackgroundColor ?? themeCellSecondaryBackgroundColor
    }
  };

  const tbodySimpleStyle = {
    "[class*='cell-col']": {
      backgroundColor: cellPrimaryBackgroundColor ?? themeCellPrimaryBackgroundColor
    }
  };

  return {
    divTable: {
      display: 'block',
      width: '100%'
    },
    caption: {
      backgroundColor: captionBackgroundColor ?? themeCaptionBackgroundColor,
      ...borderStyle,
      ...(isCaptionVisible ?? themeIsCaptionVisible
        ? {
            borderStartEndRadius: rem((cornerRadius ?? themeCornerRadius).topLeft),
            borderStartStartRadius: rem((cornerRadius ?? themeCornerRadius).topRight)
          }
        : {}),
      ...captionFont
    },
    thead: {
      width: '100%',
      marginTop: isCaptionVisible ?? themeIsCaptionVisible ? rem(rowSpacing ?? themeRowSpacing) : undefined,
      ...(!(isCaptionVisible ?? themeIsCaptionVisible) && (isHeaderVisible ?? themeIsHeaderVisible)
        ? {
            "[class*='cell-col']:last-of-type": {
              borderStartEndRadius: rem((cornerRadius ?? themeCornerRadius).topLeft)
            },
            "[class*='cell-col']:first-of-type": {
              borderStartStartRadius: rem((cornerRadius ?? themeCornerRadius).topRight)
            }
          }
        : {})
    },
    tbody: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: rem(rowSpacing ?? themeRowSpacing),
      marginTop: isHeaderVisible ?? themeIsHeaderVisible ? rem(rowSpacing ?? themeRowSpacing) : undefined,
      ...(!(isCaptionVisible ?? themeIsCaptionVisible) && !(isHeaderVisible ?? themeIsHeaderVisible)
        ? {
            [`.${CLASSNAMES.TableRowWrapper}:first-of-type [class*='cell-row']:last-of-type`]: {
              borderStartEndRadius: rem((cornerRadius ?? themeCornerRadius).topLeft)
            },
            [`.${CLASSNAMES.TableRowWrapper}:first-of-type [class*='cell-row']:first-of-type`]: {
              borderStartStartRadius: rem((cornerRadius ?? themeCornerRadius).topRight)
            }
          }
        : {}),
      [`.${CLASSNAMES.TableRowWrapper}:last-of-type [class*='cell-row']:last-of-type`]: {
        borderEndEndRadius: rem((cornerRadius ?? themeCornerRadius).bottomLeft)
      },
      [`.${CLASSNAMES.TableRowWrapper}:last-of-type [class*='cell-row']:first-of-type`]: {
        borderEndStartRadius: rem((cornerRadius ?? themeCornerRadius).bottomRight)
      },
      ...((tableStyle ?? themeTableStyle) === 'alternate-rows'
        ? tbodyAlternateRowStyle
        : (tableStyle ?? themeTableStyle) === 'alternate-columns'
        ? tbodyAlternateColumnStyle
        : tbodySimpleStyle)
    },

    cellRenderer: {
      width: '100%'
    },
    tr: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      paddingInlineStart: rem(borderStyle.borderWidth),
      columnGap: rem(columnSpacing ?? themeColumnSpacing)
    },

    th: {
      position: 'relative',
      fontWeight: 'bold',
      textAlign: 'center',
      '&:last-of-type': {
        flexGrow: 1
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: `calc(${rem(borderStyle.borderWidth)} * -1)`,
      marginInlineStart: `calc(${rem(borderStyle.borderWidth)} * -1)`,
      ...borderStyle,
      backgroundColor: headerBackgroundColor ?? themeHeaderBackgroundColor,
      padding: rem(cellPadding ?? themeCellPadding),
      ...headerFont
    },
    td: {
      '&:last-of-type': {
        flexGrow: 1
      },
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 0,
      marginTop: `calc(${rem(borderStyle.borderWidth)} * -1)`,
      marginInlineStart: `calc(${rem(borderStyle.borderWidth)} * -1)`,
      ...borderStyle,
      padding: rem(cellPadding ?? themeCellPadding),
      ...cellFont
    }
  };
});
