import { Stack } from '@mantine/core';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

import { Select } from '@/components/Select';
import { AlignToSelectPanel } from '@/components/Theme/AlignToSelectPanel';
import { BorderPanel } from '@/components/Theme/BorderPanel';
import { BorderRadiusPanel } from '@/components/Theme/BorderRadiusPanel';
import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useCurrentDocument } from '@/components/YjsProvider';
import { directionData } from '@/widgets/FlexSection/config';
import { useStyles } from '@/widgets/FlexSection/config/Config.styles';
import { useOpenDiscussionStyleProperties } from '@/widgets/OpenDiscussion/hooks/useOpenDiscussionStyleProperties';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Design() {
  const t = useTranslations('components.theme.settings');
  const { classes } = useStyles();

  const document = useCurrentDocument();
  const [id] = useContentObjectProperty<string>('children.0');

  const {
    direction,
    setDirection,
    backgroundColor,
    setBackgroundColor,
    border,
    setBorder,
    borderRadius,
    setBorderRadius,
    alignTo,
    setAlignTo,
    padding,
    setPadding,
    gap,
    setGap,
    themeBackgroundColor,
    themeBorder,
    themeBorderRadius,
    themeAlignTo,
    themePadding,
    themeGap
  } = useOpenDiscussionStyleProperties(id, document);

  const type = direction === FlexSectionDirection.row ? 'horizontal' : 'vertical';

  return (
    <Stack className={classes.contentStack}>
      <ThemeItemSection label={t('direction')}>
        <Select
          data={directionData}
          value={direction}
          onChange={value => value && setDirection(value as FlexSectionDirection)}
        />
      </ThemeItemSection>
      <ThemeItemSection label={t('backgroundColor')}>
        <ColorInputPanel
          color={backgroundColor ?? themeBackgroundColor}
          setColor={setBackgroundColor}
          ResetButton={backgroundColor && <ResetButton onClick={() => setBackgroundColor(null)} />}
        />
      </ThemeItemSection>
      <ThemeItemSection label={t('border')}>
        <BorderPanel
          label={t('border')}
          border={border ?? themeBorder}
          setBorder={setBorder}
          themeControlClassName={classes.themeControl}
          ResetButton={border && <ResetButton onClick={() => setBorder(null)} />}
        />
        <BorderRadiusPanel
          label={t('borderRadius')}
          setBorderRadius={setBorderRadius}
          borderRadius={borderRadius ?? themeBorderRadius}
          ResetButton={borderRadius && <ResetButton onClick={() => setBorderRadius(null)} />}
        />
      </ThemeItemSection>
      <ThemeItemSection label={t('layout')}>
        <AlignToSelectPanel
          label={t('alignTo')}
          alignTo={alignTo ?? themeAlignTo}
          setAlignTo={setAlignTo}
          type={type}
          ResetButton={alignTo && <ResetButton onClick={() => setAlignTo(null)} />}
        />
        <NumberInputPanel
          label={t('padding')}
          value={padding ?? themePadding}
          onChange={setPadding}
          ResetButton={!!padding && <ResetButton onClick={() => setPadding(null)} />}
        />
        <NumberInputPanel
          label={t('gap')}
          value={gap ?? themeGap}
          onChange={setGap}
          ResetButton={!isNil(gap) && <ResetButton onClick={() => setGap(null)} />}
        />
      </ThemeItemSection>
    </Stack>
  );
}
