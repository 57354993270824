import { Alert, Button, Center, Divider, Flex, Loader, Modal, Select, Title } from '@mantine/core';
import { Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { learningMethodologiesMap } from 'packages/ai-module/src/server/features/learningMethodologies';
import React, { useEffect, useState } from 'react';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { createNewSectionData } from 'shared/widgetsSDK/Section/create';
import { createContentObject } from 'shared/widgetsSDK/contentObjectOperations';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';
import { getContentObjectsMap, getRootMap, getSensitiveDataMap, validateYArray } from 'shared/widgetsSDK/yjs';

import { useWidgetComponent } from '@/components/WidgetComponentProvider/useWidgetComponent';
import { WidgetProvider } from '@/components/WidgetProvider';
import { YjsProvider, useCurrentDocument } from '@/components/YjsProvider';
import { useCurrentPageId } from '@/contentObjects/Root/hooks/useCurrentPageId';
import { bootstrapYDocument } from '@/utils/bootstrapYDocument';
import { useLearningObjectIds } from '@/utils/useLearningObjectIds';
import { useWorkMode } from '@/utils/useWorkMode';
import { ContentObjectProvider, useContentObject } from '@/widgets/_components/ContentObjectProvider';

const fetchAiModuleBackend = async (customData: Record<string, unknown>) => {
  const response = await fetch('/api/ai-module/call', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customData)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  const data = await response.json();
  return { ...customData, ...data };
};

export const useAIModuleBackendData = (customData: Record<string, unknown>) => {
  return useMutation<Record<string, unknown>>({
    mutationFn: () => fetchAiModuleBackend(customData)
  });
};

export const RegenerateUsingMethodology = () => {
  const [opened, { close, open }] = useDisclosure();

  const { isStudioEdit } = useWorkMode();
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);
  const isInWidgetPreview = rootMap.get('isWidgetPreview');

  if (!isStudioEdit) {
    return null;
  }

  return isInWidgetPreview ? null : (
    <>
      <Button onClick={open}>Preview widget in different methodology</Button>
      <Modal opened={opened} onClose={close} size="1280">
        <ErrorBoundary>
          <ModalContent onClose={close} />
        </ErrorBoundary>
      </Modal>
    </>
  );
};

const methodologies = Object.entries(learningMethodologiesMap).map(([methodology, { title }]) => ({
  label: title,
  value: methodology
}));

const ModalContent = ({ onClose }: { onClose: () => void }) => {
  const { learningObjectDraftId } = useLearningObjectIds();
  const [methodology, setMethodology] = useState<string | null>('');

  const [newDocument, setNewDocument] = useState<Y.Doc | null>(null);
  const [newWidgetId, setNewWidgetId] = useState<string | null>(null);

  const { id: currentWidgetId, document: currentDocument } = useContentObject();

  const { data, isLoading, error, mutate } = useAIModuleBackendData({
    feature: 'regenerateContentObjectInMethodology',
    learningObjectDraftId,
    contentObjectId: currentWidgetId,
    methodology
  });

  useEffect(() => {
    if (data) {
      const newWidgetType = data.widgetConfig.type;
      const createWidget = widgetsCreateFunctions[newWidgetType];
      if (!createWidget) {
        throw new Error('No `createWidget` function for ' + newWidgetType);
      }

      const contentObjects = createWidget({ locale: 'en' }, data.widgetConfig);
      const { id: newWidgetId, sensitiveData } = contentObjects[0];
      const newDocument = bootstrapYDocument(contentObjects);
      const rootMap = getRootMap(newDocument);
      rootMap.set('isWidgetPreview', true);

      const sensitiveDataMap = getSensitiveDataMap(newDocument);
      sensitiveDataMap.set(newWidgetId, sensitiveData);

      setNewDocument(newDocument);
      setNewWidgetId(newWidgetId);
    }
  }, [data]);

  const onMethodologyChange = (methodology: string | null) => {
    setNewDocument(null);
    setNewWidgetId(null);
    setMethodology(methodology);
  };

  const pageId = useCurrentPageId(currentDocument);

  const onApply = () => {
    if (!data || !pageId) {
      return;
    }

    const newWidgetType = data.widgetConfig.type;
    const createWidget = widgetsCreateFunctions[newWidgetType];
    if (!createWidget) {
      throw new Error('No `createWidget` function for ' + newWidgetType);
    }

    currentDocument.transact(() => {
      const contentObjectsToCreate = createWidget({ locale: 'en', width: 1180 }, data.widgetConfig);
      contentObjectsToCreate.forEach(({ id, contentObjectData, sensitiveData }) =>
        createContentObject(currentDocument, id, contentObjectData, sensitiveData)
      );
      const { id: widgetId } = contentObjectsToCreate[0];

      const [newSectionData, newSectionId] = createNewSectionData(pageId, [
        { id: widgetId, column: 0, span: 12, height: 'auto', isDeletable: true, isDraggable: true }
      ]);
      createContentObject(currentDocument, newSectionId, newSectionData);

      const contentObjectsMap = getContentObjectsMap(currentDocument);
      const allSections = Array.from(contentObjectsMap.values()).filter(
        ymap => ymap.get('type') === WidgetType.Section
      );
      const currentSection = allSections.find(ymap => ymap.get('children').toArray().includes(currentWidgetId));
      const pageData = getContentObjectsMap(currentDocument).get(pageId);
      const pageChildren = validateYArray<string>(pageData?.get('children'));
      const newSectionPosition = currentSection ? pageChildren.toArray().indexOf(currentSection.get('id')) + 1 ?? 0 : 0;
      pageChildren.insert(newSectionPosition, [newSectionId]);
    });

    onClose();
  };

  return (
    <Flex direction="column" gap={16}>
      <Select
        label="Methodology"
        placeholder="Pick value"
        data={methodologies}
        value={methodology}
        onChange={onMethodologyChange}
        mb={16}
      />
      <Button disabled={!methodology} onClick={() => mutate()}>
        Generate
      </Button>
      <Divider />
      {error && <Alert color="red">{error.toString()}</Alert>}
      {isLoading && (
        <Center>
          <Loader />
        </Center>
      )}
      {data?.widgetConfig && newDocument && newWidgetId && (
        <>
          <Title>New widget:</Title>
          <YjsProvider document={newDocument} withDevTools={false}>
            <NewWidgetPreview document={newDocument} id={newWidgetId} type={data.widgetConfig.type} />
          </YjsProvider>
          <Title>Explanation:</Title>
          {data?.justification && <Text>{data.justification}</Text>}
          <Button onClick={onApply}>Add a new section with widget</Button>
        </>
      )}
    </Flex>
  );
};

const NewWidgetPreview = ({ id, document, type }: { id: string; document: Y.Doc; type: WidgetType }) => {
  const Widget = useWidgetComponent(type);

  return (
    <div style={{ userSelect: 'none', opacity: '0.5', minHeight: 'fit-content' }}>
      <ContentObjectProvider id={id} document={document} type={type}>
        <WidgetProvider initializeWidget={noop}>
          <Widget />
        </WidgetProvider>
      </ContentObjectProvider>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
