import { useTranslations } from 'next-intl';
import * as Y from 'yjs';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { createNewLearningObjectCommonPageData } from 'shared/widgetsSDK/LearningObjectCommonPage/create';
import {
  createContentObject,
  duplicateContentObject,
  removeContentObject
} from 'shared/widgetsSDK/contentObjectOperations';
import { getRootChildrenArray } from 'shared/widgetsSDK/yjs';

import { Page } from '@/contentObjects/Root/hooks/usePages';
import { calculateNewListPosition } from '@/utils/calculateNewListPosition';

export function addPage(document: Y.Doc, type: LearningObjectTypes) {
  const rootChildren = getRootChildrenArray(document);
  const [newPageData, newPageId] = createNewLearningObjectCommonPageData(type);

  document.transact(() => {
    rootChildren.push([newPageId]);
    createContentObject(document, newPageId, newPageData);
  });

  return newPageId;
}

export function removePage(document: Y.Doc, pageId: string, actualIndex: number, type: LearningObjectTypes) {
  const rootChildren = getRootChildrenArray(document);

  return document.transact(() => {
    const amountOfPages = rootChildren.length;
    rootChildren.delete(actualIndex);
    removeContentObject(document, pageId);

    if (amountOfPages === 1) {
      return addPage(document, type);
    }
  });
}

export function duplicatePage(
  document: Y.Doc,
  pageId: string,
  actualIndex: number,
  t: ReturnType<typeof useTranslations>
) {
  return document.transact(() => {
    const rootChildren = getRootChildrenArray(document);

    const duplicatedPage = duplicateContentObject(document, pageId, undefined);
    const previousTitle = duplicatedPage.get('title');
    // @ts-expect-error Using the ReturnType to type this function makes it not recognize translation keys and throw an error.
    duplicatedPage.set('title', previousTitle ? t('pageCard.customTitleCopy', { title: previousTitle }) : '');
    const duplicatedPageId = duplicatedPage.get('id') as string;
    rootChildren.insert(actualIndex + 1, [duplicatedPageId]);
    return duplicatedPageId;
  });
}

export function movePage(document: Y.Doc, pageId: string, newIndex: number, pagesData: Page[]) {
  let pageIds: string[] = [];

  document.transact(() => {
    const rootChildren = getRootChildrenArray(document);
    const currentIndex = pagesData.findIndex(({ id }) => id === pageId);
    if (currentIndex === -1) {
      throw new Error(`Page ${pageId} not found in root children.`);
    }
    const calculatedNewIndex = calculateNewListPosition(currentIndex, newIndex);
    if (calculatedNewIndex === -1) {
      return;
    }

    rootChildren.delete(currentIndex);
    rootChildren.insert(calculatedNewIndex, [pageId]);

    pageIds = rootChildren.map(id => id);
  });

  let hiddenPageIndex = 0;
  let visiblePageIndex = 0;

  const pageIndexes = pageIds.reduce<Record<string, string | number>>((indexes, pageId) => {
    const pageData = pagesData.find(({ id }) => id === pageId);
    if (pageData) {
      const isHidden = pageData.isHidden;

      isHidden ? (hiddenPageIndex += 1) : (visiblePageIndex += 1);

      indexes[pageId] = isHidden ? `h${hiddenPageIndex}` : visiblePageIndex;
    }
    return indexes;
  }, {});

  return pageIndexes[pageId];
}
