import { useMemo } from 'react';

import { Direction } from '@/components/Resizable/types';

type Classes = {
  dotStart: string;
  dotEnd: string;
  dotUpStart: string;
  dotDownStart: string;
  dotDownMiddle: string;
  dotDownEnd: string;
  dotUpEnd: string;
};

export type ResizeDirections = (keyof Classes)[];

type ResizeDot = {
  direction: Direction;
  class: string;
};

export type UseResizeDotsListProps = {
  classes: Classes;
  resizeDirections: ResizeDirections;
};

export const horizontalResizeDots: ResizeDirections = ['dotStart', 'dotEnd'];
export const allResizeDots: ResizeDirections = [
  ...horizontalResizeDots,
  'dotUpStart',
  'dotDownStart',
  'dotDownMiddle',
  'dotDownEnd',
  'dotUpEnd'
];

export function useResizeDotsList(props: UseResizeDotsListProps): ResizeDot[] {
  const { classes, resizeDirections } = props;

  const allDots: Record<keyof Classes, ResizeDot> = useMemo(
    () => ({
      dotStart: { direction: 'start', class: classes.dotStart },
      dotEnd: { direction: 'end', class: classes.dotEnd },
      dotUpStart: { direction: 'up-start', class: classes.dotUpStart },
      dotDownStart: { direction: 'down-start', class: classes.dotDownStart },
      dotDownMiddle: { direction: 'down', class: classes.dotDownMiddle },
      dotDownEnd: { direction: 'down-end', class: classes.dotDownEnd },
      dotUpEnd: { direction: 'up-end', class: classes.dotUpEnd }
    }),
    [
      classes.dotStart,
      classes.dotEnd,
      classes.dotUpStart,
      classes.dotDownStart,
      classes.dotDownMiddle,
      classes.dotDownEnd,
      classes.dotUpEnd
    ]
  );

  return useMemo(() => resizeDirections.map(direction => allDots[direction]), [resizeDirections, allDots]);
}
