import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type FillInTheBlanksOptionStyleProps = {
  disabled?: boolean;
  fontFamily?: string;
  fontSize?: string;
  hasContent?: boolean;
  isCorrect: boolean | undefined;
  isOver?: boolean;
  isDragging?: boolean;
};

export const useStyles = createStyles((theme, props: FillInTheBlanksOptionStyleProps) => {
  const { disabled, fontFamily, fontSize, isCorrect, isOver, hasContent, isDragging } = props;

  const globalThemeContentColors = theme.other.global.content.colors;
  const customBorderColor =
    isCorrect === undefined ? undefined : isCorrect ? theme.colors.success[0] : theme.colors.failure[0];
  const fitbQuestionBlankTheme = theme.other.learningItems.fillInTheBlanks.blank;

  const borderStyle = getThemeBorderStyle(fitbQuestionBlankTheme.border, globalThemeContentColors.primary.dark, {
    ...(customBorderColor && { borderColor: customBorderColor })
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(fitbQuestionBlankTheme.borderRadius);

  const disabledStyles = disabled
    ? {
        cursor: 'not-allowed',
        color: theme.colors.dark[2],
        opacity: '0.6'
      }
    : {};

  const cursorStyle = hasContent && !disabled ? { cursor: 'pointer' } : {};
  const zIndexStyle = isDragging ? { zIndex: 1 } : {};

  const backgroundColor = isOver
    ? fitbQuestionBlankTheme.backgroundColorHovered ?? globalThemeContentColors.primary.light
    : fitbQuestionBlankTheme.backgroundColor ?? globalThemeContentColors.secondary.main;

  return {
    componentWithContent: {
      display: 'inline-block',
      position: 'relative',
      width: hasContent ? 'fit-content' : rem(75),
      height: '100%',
      maxWidth: '100%',
      padding: `0 ${rem(3)}`,
      fontFamily,
      fontSize,
      ...zIndexStyle
    },
    content: {
      ...disabledStyles,
      ...borderStyle,
      ...borderRadiusStyle,
      ...cursorStyle,
      backgroundColor,
      color: fitbQuestionBlankTheme.color ?? globalThemeContentColors.secondary.contrast,
      height: '100%',
      lineHeight: `calc(${fontSize} + 6px)`,
      paddingInline: rem(5),
      ':empty::before': {
        content: '"_"',
        visibility: 'hidden'
      }
    },
    icon: {
      borderRadius: '50%',
      height: rem(16),
      padding: rem(3),
      position: 'absolute',
      right: rem(-4),
      top: rem(-7),
      width: rem(16),
      zIndex: 1
    },
    checkIcon: {
      background: theme.colors.success
    },
    crossIcon: {
      background: theme.colors.failure,
      color: theme.white
    },
    wordBankOptionText: {
      fontSize: theme.fontSizes.lg
    }
  };
});
