import { Flex, Stack, Text } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { useCallback, useRef, useState } from 'react';
import * as Y from 'yjs';

import { generateDefaultOption } from 'shared/utils/initializeCloze';

import { useStyles } from './ClozeAccordionItem.styles';
import { InputCloze } from './InputCloze';
import { InputClozePlaceholder } from './InputClozePlaceholder';
import { Accordion } from '@/components/Accordion';
import { useOverflowText } from '@/utils/useOverflowText';
import {
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

type ClozeAccordionItemInputProps = { clozeId: string; index: number };

export function ClozeAccordionItemInput(props: ClozeAccordionItemInputProps) {
  const { clozeId, index } = props;

  const { classes } = useStyles();

  const { document } = useContentObject();

  console.log('clozeId', clozeId);

  const [inputCorrectOptions, observedInputCorrectOptions] = useContentObjectArray<Y.Map<unknown>>(
    `inputCorrectOptions.${clozeId}`,
    'sensitiveData'
  );
  const [textToShow] = useContentObjectProperty<string>(`inputCorrectOptions.${clozeId}.0.value`, 'sensitiveData');

  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);

  const addNewOption = useCallback(
    (value: string) => {
      observedInputCorrectOptions.push([generateDefaultOption(value)]);
      setFocusedOptionIndex(observedInputCorrectOptions.length - 1);
    },
    [observedInputCorrectOptions]
  );

  const handleRemoveOptions = (indexToRemove: number) => {
    document.transact(() => {
      observedInputCorrectOptions?.delete(indexToRemove);
    });
  };

  const previewTextRef = useRef<HTMLSpanElement>(null);
  const overflowPreviewText = useOverflowText(previewTextRef);

  return (
    <>
      <Accordion.Control>
        {
          <Tooltip label={overflowPreviewText} disabled={!overflowPreviewText} maw={400} multiline>
            <span ref={previewTextRef}>
              <Text truncate className={classes.previewText}>
                {index + 1}. {textToShow}
              </Text>
            </span>
          </Tooltip>
        }
      </Accordion.Control>
      <Accordion.Panel p={16} pt={4}>
        <Stack>
          {inputCorrectOptions?.map((yMap, index) => {
            const optionId = yMap.get('id') as string | undefined;
            if (!optionId) return null;

            return (
              <Flex key={optionId} className={classes.wrapper}>
                <InputCloze
                  autoFocus={focusedOptionIndex === index}
                  index={index}
                  isDisabled={inputCorrectOptions.length < 2}
                  onDelete={() => handleRemoveOptions(index)}
                  propertyPath={`inputCorrectOptions.${clozeId}.${index}.value`}
                  parentMap="sensitiveData"
                />
              </Flex>
            );
          })}
          <Flex className={classes.wrapper}>
            <InputClozePlaceholder onChange={addNewOption} />
          </Flex>
        </Stack>
      </Accordion.Panel>
    </>
  );
}
