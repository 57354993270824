import set from 'lodash/set';
import { useCallback } from 'react';

import { getCollaborativeState, InteractiveQuestionAnswers, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

const defaultAnswer: string[] = [];

export const useCollaborativeQuestionAnswer = (questionId: string, studentId: string) => {
  const document = useCurrentDocument();
  const [allQuestionsAnswers = {}, setAllQuestionsAnswers] = useObservedProperty<
    Record<string, InteractiveQuestionAnswers>
  >(getCollaborativeState(document), 'questionsAnswers');

  const singleQuestionAnswers = allQuestionsAnswers[questionId] ?? {};
  const answer = singleQuestionAnswers[studentId] ?? defaultAnswer;

  const setAnswer = useCallback(
    (answer: string[]) => {
      const clone = structuredClone(allQuestionsAnswers);
      set(clone, `${questionId}.${studentId}`, answer);
      setAllQuestionsAnswers(clone);
    },
    [allQuestionsAnswers, setAllQuestionsAnswers, questionId, studentId]
  );

  return [answer, setAnswer] as const;
};
