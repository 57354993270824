import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { Node } from 'prosemirror-model';
import { useCallback, useMemo, useState } from 'react';
import * as Y from 'yjs';

import { ToolbarType } from 'shared/types/RichTextToolbar';
import { extractYjsMaps } from 'shared/widgetsSDK/yjs';

import { AddWordToBank } from './AddWordToBank';
import { FillInTheBlanksExtension } from './FillInTheBlanksExtension/FillInTheBlanksExtension';
import { NodeHistoryData, handlePasteLogic, useCustomNodeHistoryIntegration } from '@/utils/tiptap';
import {
  useContentObject,
  useContentObjectArray,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

type HistoryDataValue = {
  optionId: string;
  wordBankOption: {
    id: string;
    value: string;
  };
};

export function FillInTheBlanksText() {
  const [editor, setEditor] = useState<Editor | null>(null);

  const answerFragment = useContentObjectStaticProperty<Y.XmlFragment>('answerFragment');
  const [answerPlaceholder] = useContentObjectProperty<string>('answerPlaceholder');

  const [contentObjectOptions, setContentObjectOptions] = useContentObjectProperty<Y.Map<string>>(
    'correctOptions',
    'sensitiveData'
  );

  const [wordBankOptions, observedWordBankOptions] = useContentObjectArray<{ id: string; value: string }>(
    'wordBankOptions'
  );

  const { document, id } = useContentObject();

  const onRemove = (nodeIds: string[]): NodeHistoryData<HistoryDataValue>[] => {
    const deletedNodeMaps = nodeIds.map(nodeId => {
      const optionId = contentObjectOptions.get(nodeId);
      const wordBankOption = wordBankOptions.find(option => option.id === optionId);

      return {
        key: nodeId,
        value: {
          optionId: optionId as string,
          wordBankOption: wordBankOption as { id: string; value: string }
        }
      };
    });

    document.transact(() => {
      const clonedOptions = contentObjectOptions.clone();
      nodeIds.forEach(nodeId => {
        const optionId = contentObjectOptions.get(nodeId);
        const optionIndex = observedWordBankOptions.toArray().findIndex(option => option.id === optionId);

        if (optionIndex < 0) {
          return console.error('Option not found');
        }

        observedWordBankOptions.delete(optionIndex);
        clonedOptions.delete(nodeId);
      });
      setContentObjectOptions(clonedOptions);
    }, 'untracked');

    return deletedNodeMaps;
  };

  const onAdd = (historyData: NodeHistoryData<HistoryDataValue>[]) => {
    document.transact(() => {
      const clonedOptions = contentObjectOptions.clone();
      historyData.forEach(({ key, value }) => {
        const { optionId, wordBankOption } = value;
        if (!optionId || !wordBankOption) return;
        clonedOptions.set(key, optionId);
        observedWordBankOptions.push([wordBankOption]);
      });
      setContentObjectOptions(clonedOptions);
    }, 'untracked');
  };

  useCustomNodeHistoryIntegration<HistoryDataValue>({
    editor,
    nodeName: 'fillInTheBlanksNode',
    attributeKey: 'fillInTheBlanksId',
    onRemove,
    onAdd
  });

  const processFillInTheBlanksNode = useCallback(
    (node: Node) => {
      if (node.type.name !== 'fillInTheBlanksNode') return null;

      const { sensitiveDataMap, contentObjectsMap } = extractYjsMaps(document, id);

      const optionId = sensitiveDataMap.get('correctOptions')?.get(node.attrs.fillInTheBlanksId);
      const wordBankOptions = contentObjectsMap?.get('wordBankOptions')?.toJSON() as
        | HistoryDataValue['wordBankOption'][]
        | undefined;

      if (!wordBankOptions) {
        console.error('Word bank options not found');
        return null;
      }

      const wordBankOption = wordBankOptions.find(option => option.id === optionId);

      if (!wordBankOption) {
        console.error('Word bank option not found');
        return null;
      }

      return wordBankOption.value;
    },
    [document, id]
  );

  const extraExtensions = useMemo(
    () => [
      FillInTheBlanksExtension.extend({
        addProseMirrorPlugins() {
          return [
            new Plugin({
              key: new PluginKey('fillInTheBlanksPastePlugin'),
              props: {
                handlePaste(view, _, slice) {
                  return handlePasteLogic(view, slice, node => processFillInTheBlanksNode(node));
                }
              }
            })
          ];
        }
      })
    ],
    [processFillInTheBlanksNode]
  );

  const customControls = useMemo(() => <AddWordToBank />, []);

  return (
    <RichText
      dataTestIdPrefix="studio-fill-in-the-blanks-answer"
      fragment={answerFragment}
      placeholder={answerPlaceholder}
      extraExtensions={extraExtensions}
      customControls={customControls}
      setEditor={setEditor}
      expandHeight
      disableTypographyInheritance
      toolbarType={ToolbarType.Fixed}
    />
  );
}
