import { useCallback } from 'react';
import * as Y from 'yjs';

import { QuestionGroupsType } from 'shared/types/QuestionGroups';
import { EvaluatableWidgets, WidgetType } from 'shared/utils/widgets';

import { QuestionsShown } from '@/components/ScoreWeightSummary';
import { useCurrentDocument } from '@/components/YjsProvider';
import {
  getActivityHiddenQuestionsIds,
  getActivityOrderedQuestionsIds,
  getActivityVisibleOrderedQuestionsIds
} from '@/utils/getActivityOrderedQuestionsIds';
import { selectContentObjects, ContentObjectsMap } from '@/utils/selectContentObjects';
import { getContentObjectsMap, getFormDataMap, getRootMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';
import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';

export type QuestionWidget = Exclude<EvaluatableWidgets | WidgetType.OpenQuestion, WidgetType.InteractiveQuiz>;

export type QuestionData = {
  id: string;
  type: QuestionWidget;
  label?: string;
  children?: QuestionData[];
  scoreWeight?: number;
  scoreMode?: QuestionScoreMode;
  relativeScoreMode?: RelativeScoreMode;
  isHidden?: boolean;
};

export function useQuestions(questionsShown: QuestionsShown, includeHidden = false) {
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);

  const memoizedSelectContentObjects = useCallback(
    (contentObjects: Y.Map<Y.Map<unknown>>) => selectContentObjects(contentObjects, { includeLabel: true }),
    []
  );

  const [contentObjects] = useObservedProperty<ContentObjectsMap>(getContentObjectsMap(document), undefined, {
    deepObserve: true,
    selector: memoizedSelectContentObjects
  });
  const [widgetsFormData] = useObservedProperty<Y.Map<Y.Map<unknown>>>(getFormDataMap(document), undefined, {
    deepObserve: true
  });
  const [pagesIds] = useObservedArray<string>(rootMap, 'children');

  const activityHiddenQuestionsIds = getActivityHiddenQuestionsIds(pagesIds, contentObjects);

  if (includeHidden) {
    const orderedQuestionsIds = getActivityOrderedQuestionsIds(pagesIds, contentObjects);
    const questions = orderedQuestionsIds
      .map(questionId => getQuestionData(questionId, contentObjects, widgetsFormData, questionsShown))
      .filter(Boolean) as QuestionData[];
    return questions.map(question => ({ ...question, isHidden: activityHiddenQuestionsIds.includes(question?.id) }));
  }

  const orderedQuestionsIds = getActivityVisibleOrderedQuestionsIds(pagesIds, contentObjects);
  return orderedQuestionsIds
    .map(questionId => getQuestionData(questionId, contentObjects, widgetsFormData, questionsShown))
    .filter(Boolean) as QuestionData[];
}

export const getQuestionData = (
  questionId: string,
  contentObjects: ContentObjectsMap,
  widgetsFormData: Y.Map<Y.Map<unknown>>,
  questionsShown: QuestionsShown,
  questionGroups?: QuestionGroupsType
): QuestionData | null => {
  const question = contentObjects[questionId];
  const { type, label, questionScoreMode: scoreMode, relativeScoreMode } = question ?? {};

  const formData = widgetsFormData.get(questionId);
  const scoreWeight = formData?.get('scoreWeight') as number | undefined;

  if (questionsShown === 'withScore' && scoreMode === QuestionScoreMode.WithoutScore) {
    return null;
  }

  if (type === WidgetType.QuestionGroupList) {
    const verticalSectionId = question.children[0];
    const nestedQuestionsIds = contentObjects[verticalSectionId].children;
    const nestedQuestionsData = (questionGroups?.[questionId] ?? nestedQuestionsIds)
      .map(nestedQuestionId =>
        getQuestionData(nestedQuestionId, contentObjects, widgetsFormData, questionsShown, questionGroups)
      )
      .filter(Boolean) as QuestionData[];

    return {
      id: questionId,
      type,
      label,
      scoreWeight,
      scoreMode,
      relativeScoreMode,
      children: nestedQuestionsData
    };
  }

  return { id: questionId, type: type as QuestionWidget, label, scoreWeight, scoreMode };
};
