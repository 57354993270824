import { create } from 'zustand';

interface SelectableStore {
  selectedId: string | null;
  itemConfigId: string | null;
  select: (id?: string | null) => void;
  deselect: () => void;
  selectItemConfigId: (id?: string | null) => void;
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
}

export const useSelectableStore = create<SelectableStore>(set => ({
  // current selected selectable item - [data-selectable-id] attribute
  selectedId: null,

  // current selected item with a config - [data-item-config-id] attribute
  itemConfigId: null,
  isSelected: false,
  select: id => set(() => ({ selectedId: id })),
  deselect: () => set(() => ({ selectedId: null, itemConfigId: null, isSelected: false })),
  selectItemConfigId: id => set(() => ({ itemConfigId: id })),
  setIsSelected: value => set(() => ({ isSelected: value }))
}));
