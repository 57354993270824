import { useSearchParams } from 'next/navigation';

import { CollaborativeState, getCollaborativeState, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCanSyncSlideNumber } from '@/components/CanProvider';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useWorkMode } from '@/utils/useWorkMode';

export function useCurrentPageIndex() {
  const params = useSearchParams();
  const { isPlayer } = useWorkMode();

  const canSyncSlideNumber = useCanSyncSlideNumber();
  const document = useCurrentDocument();
  const [collaborativePageIndex] = useObservedProperty<CollaborativeState['collaborativePageIndex']>(
    getCollaborativeState(document),
    'collaborativePageIndex'
  );

  if (isPlayer && canSyncSlideNumber) {
    return collaborativePageIndex ?? params?.get('page') ?? '1';
  }

  return params?.get('page') ?? '1';
}
