import { clientModules } from '@hendrx/modules';
import { ActivityThemeStyles } from '@hendrx/modules/ui';
import merge from 'lodash/merge';
import { useMemo } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useParentTheme } from './useParentTheme';

export function useActivityThemeStyles(document: Y.Doc) {
  const { data: parentTheme } = useParentTheme();

  const rootMap = getRootMap(document);
  const [activityThemeStyles, setActivityThemeStyles] = useObservedProperty<
    ActivityThemeStyles | Record<string, never>
  >(rootMap, 'activityThemeStyles');

  return useMemo(
    () =>
      [
        merge({}, clientModules.ui.defaultActivityThemeStyles, parentTheme?.styles, activityThemeStyles),
        setActivityThemeStyles
      ] as const,
    [activityThemeStyles, parentTheme?.styles, setActivityThemeStyles]
  );
}
