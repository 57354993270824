import * as Y from 'yjs';

import { manifestLocales } from './manifest.locales';
import { Feedbacks } from '../../types/Feedbacks';
import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { GroupCheckButtonMode, ParentHeadline, QuestionListBulletTypes } from '../../types/QuestionGroupList';
import { RelativeScoreMode } from '../../types/QuestionScoreMode';
import { genRandId } from '../../utils/genRandId';
import { getEvaluationProps } from '../../utils/getEvaluationProps';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';
import { createNewFlexSectionData } from '../FlexSection/create';

export function createNewQuestionGroupList(options: InitializePropsOptions): ManifestInitializedProps {
  const { isPlenaryLesson = false, locale } = options;
  const { initialHeadlineText } = manifestLocales.questionGroupList;
  const id = genRandId();
  const labelFragment = htmlToXmlFragment(`<h2>${initialHeadlineText[locale]}</h2>`);
  const descriptionFragment = new Y.XmlFragment();
  const hintFragment = htmlToXmlFragment('');
  const widgetEvaluationProps = Object.entries(getEvaluationProps(isPlenaryLesson));

  const [verticalSectionData] = createNewFlexSectionData(FlexSectionDirection.column);
  const { id: verticalSectionId } = verticalSectionData;
  const childrenArray = new Y.Array();
  childrenArray.push([verticalSectionId]);

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.QuestionGroupList],
        ['children', childrenArray],
        ['labelFragment', labelFragment],
        ['descriptionFragment', descriptionFragment],
        ['hintFragment', hintFragment],
        ['withRandomSubQuestions', true],
        ['feedback', Feedbacks.Check],
        ['groupCheckButtonMode', GroupCheckButtonMode.GeneralButton],
        ['parentHeadline', ParentHeadline.Manual],
        ['questionListBulletType', QuestionListBulletTypes.Number],
        ['relativeScoreMode', RelativeScoreMode.RelativeToParent],
        ['showGeneralGrade', true],
        ['showEachQuestionGrade', false],
        ['choiceQuestionsEnabled', false],
        ['choiceQuestionsCount', 0],
        ['visibleQuestionsCount', 0],
        ['requiredSubQuestionsId', []],
        ['saveAnswers', true],
        ...widgetEvaluationProps
      ])
    },
    verticalSectionData
  ];
}
