import { clientModules } from '@hendrx/modules';
import { useMutation } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';

import { getUploadSecureInfo } from '@/queries/fileUploadApi';
import { showErrorNotification } from '@/utils/notifications';

type File = Blob;

type UseFileUploadProps = {
  errorMessage?: string;
  fileName?: string;
};

const uploadFileHandler = clientModules.fileStorage.uploadFile;

export const useFileUpload = (props?: UseFileUploadProps) => {
  const t = useTranslations('utils.notification');

  return useMutation(
    async (file: File): Promise<string | undefined> => {
      try {
        return await uploadFileHandler(file, getUploadSecureInfo, props?.fileName);
      } catch {
        return;
      }
    },
    {
      onError() {
        if (!props) return;
        const { errorMessage } = props;

        if (!errorMessage) return;
        showErrorNotification(errorMessage, t('error'));
      }
    }
  );
};
