export function calculateNewListPosition(currentIndex: number, newIndex: number) {
  // Need to account for the fact that there is always one indicator more than items on the list
  const isMovedUp = currentIndex > newIndex;
  const dropDistance = isMovedUp ? Math.abs(1 + currentIndex - newIndex) : Math.abs(currentIndex - newIndex);

  // Case where a page is being moved to an adjacent indicator
  if (dropDistance < 2) {
    return -1;
  }

  return isMovedUp ? newIndex : newIndex - 1;
}
