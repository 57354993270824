import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';

import { SupportedLocale } from 'shared/types/SupportedLocale';

import { StudioQuestionStage } from './StudioQuestionStage';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ConfigElement } from '@/contentObjects/Plugin';
import { useEvaluatableStudioEvents } from '@/contentObjects/Plugin/hooks/useEvaluatableStudioEvents';
import { useStudioEvents } from '@/contentObjects/Plugin/hooks/useStudioEvents';
import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';
import { Edit } from '@/icons/Edit';
import { useGetPluginById } from '@/queries/plugin';
import { WidgetAction, WidgetAdditionalAction } from '@/types/Stage';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { useWorkMode } from '@/utils/useWorkMode';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function EvaluatableStudio(props: StudioProps) {
  const { initialize } = props;
  const { contentLanguage } = useContentLanguage();
  const { uiLanguage } = useUiLanguage();
  const t = useTranslations('widgets');

  const { document: yDoc, id } = useContentObject();

  const [pluginId] = useContentObjectProperty<string>('pluginId');
  const { data } = useGetPluginById(pluginId);

  const { isStudioEdit } = useWorkMode();

  const [allowEdit, setAllowEdit] = useState(false);

  const isQuestion = data?.type === 'question';

  useEffect(() => {
    const actions: WidgetAction[] = ['Duplicate', 'Delete'];
    const editPlugin: WidgetAdditionalAction = {
      actionName: t('plugin.edit'),
      dataTestId: 'plugin-additional-action-edit',
      Icon: Edit,
      onClick: () => {
        const handleEditButtonClickEvent = new CustomEvent(`${PluginEvent.handleEditButtonClickEvent}-${id}`);
        document.dispatchEvent(handleEditButtonClickEvent);
      }
    };
    const baseData = {
      actions,
      constraints: { minHeight: 200, minWidth: 200 },
      isResizableVertically: false
    };
    const initData = allowEdit
      ? {
          ...baseData,
          additionalActions: [editPlugin]
        }
      : baseData;
    initialize?.(initData);
  }, [initialize, isQuestion, t, allowEdit, id]);

  useStudioEvents(id);
  useEvaluatableStudioEvents(id, setAllowEdit);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, yDoc);

  if (!data) {
    return null;
  }

  return (
    <div className={customClassNames} ref={containerRef}>
      <SideMenuPortal>
        <ConfigElement
          id={id}
          script={data.script}
          isStudioEdit={isStudioEdit}
          contentLanguage={contentLanguage}
          uiLanguage={uiLanguage}
          title={data.title[uiLanguage as SupportedLocale]}
          tabs={data.configTabs}
          iconUrl={data.iconUrl}
        />
      </SideMenuPortal>
      <StudioQuestionStage id={id} script={data.script} />
    </div>
  );
}
