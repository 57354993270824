import { Tabs } from '@mantine/core';

import { Design } from './design';
import { Settings } from './settings';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  return (
    <WidgetConfig tabs={['appearance', 'behaviour']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Design />
        <CustomCSSClassesInput />
      </Tabs.Panel>
      <Tabs.Panel key="behaviour" value="behaviour">
        <Settings />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
