import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      zIndex: 1001,
      top: '0',
      right: '0',
      left: '0',
      bottom: '0'
    },
    loader: { position: 'absolute', zIndex: 1001, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
  };
});
