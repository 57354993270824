import { ActionIcon, Box, Collapse, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';

import { SubmissionEntry } from './SubmissionEntry';
import { useStyles } from './SubmissionHistory.styles';
import { SatisfactionScore } from '@/components/SatisfactionSurvey';
import { Chevron } from '@/icons/Chevron';
import { StateStatus } from '@/queries/assignmentApi';

export enum SubmissionActor {
  Student = 'student',
  Teacher = 'teacher'
}

export type EntryOwner = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  role: SubmissionActor;
};

export type Submission = {
  status: StateStatus.Submitted | StateStatus.ReturnedToFix | StateStatus.Evaluated;
  submissionEntryOwner: EntryOwner;
  date: Date;
  comment?: string;
  satisfactionScore?: SatisfactionScore | null;
};

type SubmissionHistoryProps = {
  submissions?: Submission[] | null;
};

export const SubmissionHistory = (props: SubmissionHistoryProps) => {
  const { submissions } = props;

  const t = useTranslations('components.submissionHistory');
  const [isExpanded, { toggle }] = useDisclosure(false);
  const { classes } = useStyles({ isExpanded });

  if (!submissions) {
    return null;
  }

  const isExpandable = submissions.length > 1;

  const CollapseText = () => {
    return (
      <Flex className={classes.collapseWrapper} direction="row" align="center">
        <Text className={classes.collapseText}>{isExpanded ? t('close') : t('showAllFeedbacks')}</Text>
        <ActionIcon variant="subtle" className={classes.chevronIcon} onClick={toggle}>
          <Chevron />
        </ActionIcon>
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      <Box>
        {submissions.length > 0 && <SubmissionEntry submission={submissions[0]} />}
        {isExpandable && <CollapseText />}
      </Box>
      <Box>
        <Collapse in={isExpanded}>
          {submissions.slice(1).map(submission => (
            <SubmissionEntry key={submission.date.toString()} submission={submission} />
          ))}
        </Collapse>
      </Box>
    </Flex>
  );
};
