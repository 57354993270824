export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const REDIRECT_PATH = '/redirect';
export const UNAUTHORIZED_PATH = '/unauthorized';
export const PLAYER_PATH = '/player';
export const STUDIO_PATH = '/studio';

export const PLAYER_ACTIVITY_PATH = PLAYER_PATH;
export const PLAYER_ASSIGNMENT_PATH = `${PLAYER_PATH}/assignment`;

export const STUDIO_EDIT_PATH = `${STUDIO_PATH}/edit`;
export const STUDIO_PREVIEW_PATH = `${STUDIO_PATH}/preview`;
export const STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH = '/versions/';

export const AUTH_MODULE_PATHS = {
  home: STUDIO_PATH,
  login: LOGIN_PATH
};
