import { mergeAttributes, Node } from '@tiptap/core';

export const BaseClozeExtension = Node.create({
  name: 'clozeNode',
  group: 'inline',
  content: 'inline*',
  inline: true,
  // marks: 'textStyle',
  addAttributes() {
    return {
      clozeId: {
        default: null,
        parseHTML: element => element.getAttribute('data-cloze-id'),
        renderHTML: attributes => {
          return {
            'data-cloze-id': attributes['clozeId']
          };
        }
      },
      type: {
        default: null,
        parseHTML: element => element.getAttribute('data-cloze-type'),
        renderHTML: attributes => {
          return {
            'data-cloze-type': attributes['type']
          };
        }
      },
      fontSize: {
        ...this.parent?.(),
        default: null,
        parseHTML: element => element.getAttribute('data-font-size'),
        renderHTML: attributes => {
          return {
            'data-font-size': attributes['fontSize'],
            style: `font-size: ${attributes['fontSize']}`
          };
        }
      },
      fontFamily: {
        ...this.parent?.(),
        default: null,
        parseHTML: element => element.getAttribute('data-font-family'),
        renderHTML: attributes => {
          return {
            'data-font-family': attributes['fontFamily'],
            style: `font-family: ${attributes['fontFamily']}`
          };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: 'cloze-option'
      }
    ];
  },
  renderHTML({ HTMLAttributes, node }) {
    return ['cloze-option', mergeAttributes(HTMLAttributes), 0];
  }
});
