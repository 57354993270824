import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  grade: {
    display: 'inline-flex',

    '& > *': {
      margin: 'auto'
    }
  },
  ringProgress: {
    margin: `0 ${rem(5)}`
  },
  ringProgressInputContainer: {
    position: 'relative'
  },
  ringProgressInput: {
    input: {
      fontSize: rem(18),
      textAlign: 'center',

      '&:disabled': {
        background: 'none',
        color: 'initial',
        cursor: 'text',
        opacity: 1
      }
    }
  },
  visualUnderscore: {
    bottom: 0,
    left: rem(20),
    pointerEvents: 'none',
    position: 'absolute'
  }
}));
