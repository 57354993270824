import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewOpenDiscussionData } from 'shared/widgetsSDK/OpenDiscussion/create';

import { manifestLocales } from './manifest.locales';
import IconEn from './resources/en/icon.svg';
import IconHe from './resources/he/icon.svg';
import { OpenDiscussion } from '@/icons/OpenDiscussion';

export const Manifest: ManifestType = {
  name: WidgetType.OpenDiscussion,
  previewGroups: [
    {
      id: 'openDiscussion',
      divider: {
        title: 'openDiscussion',
        icon: OpenDiscussion
      },
      previews: [
        {
          name: 'imagePreview',
          previewImage: {
            paths: {
              en: IconEn,
              he: IconHe
            },
            size: {
              width: 140,
              height: 108
            },
            alt: { ...manifestLocales.openDiscussion.previewImageAlt }
          },
          initializeProps: options =>
            createNewOpenDiscussionData(
              `<h1><span>${manifestLocales.openDiscussion.initialText[options?.locale ?? 'he']}</span></h1>`
            ),
          dimensions: {
            width: 1280,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minWidth: 1280,
      minHeight: 630
    }
  }
};
