import { Box } from '@mantine/core';
import * as Y from 'yjs';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';
import { getContentObjectsMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { BlockTitle } from '@/widgets/BlockTitle/studio';
import { Config } from '@/widgets/EnclosureWithBlockTitle/config';
import { Studio as FlexSection } from '@/widgets/FlexSection/studio';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function EnclosureWithBlockTitle(props: StudioProps) {
  const { id, document } = useContentObject();
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');

  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<unknown>>(contentObjectsMap, verticalSectionId);
  const [verticalSectionChildren] = useObservedArray<string>(verticalSection, 'children');
  const isEmpty = verticalSectionChildren.length === 0;

  const [direction] = useWidgetStyle<FlexSectionDirection>(document, id, 'dropzoneDirection');
  const [gap] = useWidgetStyle<number>(document, id, 'dropzoneGap');
  const [marginEnd] = useWidgetStyle<number>(document, id, 'dropzoneMarginEnd');
  const [marginStart] = useWidgetStyle<number>(document, id, 'dropzoneMarginStart');

  return (
    <div className={customClassNames}>
      <BlockTitle config={<Config />} />
      <ContentObjectProvider id={verticalSectionId} document={document} type={WidgetType.FlexSection}>
        <Box sx={{ marginInlineStart: marginStart, marginInlineEnd: marginEnd }}>
          {isEmpty ? (
            <DropzonePlaceholder type="learningItems" />
          ) : (
            <FlexSection direction={direction} gap={gap} withConfig={false} />
          )}
        </Box>
      </ContentObjectProvider>
    </div>
  );
}
