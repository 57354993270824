import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewFlexSectionData } from 'shared/widgetsSDK/FlexSection/create';

import { manifestLocales } from './manifest.locales';
import FlexboxEn from './resources/en/flexbox.svg';
import FlexboxHe from './resources/he/flexbox.svg';
import { Flexbox } from '@/icons/Flexbox';

export const Manifest: ManifestType = {
  name: WidgetType.FlexSection,
  previewGroups: [
    {
      id: 'flex',
      divider: {
        icon: Flexbox,
        title: 'flex'
      },
      previews: [
        {
          name: 'flexSectionPreview',
          previewImage: {
            paths: {
              en: FlexboxEn,
              he: FlexboxHe
            },
            alt: { ...manifestLocales.main.previewImageAlt },
            size: {
              width: 285,
              height: 140
            }
          },
          initializeProps: () => createNewFlexSectionData(FlexSectionDirection.row),
          dimensions: {
            width: 600,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
