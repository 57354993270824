import { Box } from '@mantine/core';
import { ReactNode } from 'react';

import { useStyles } from './FormControl.styles';
import { FormLabel } from '@/widgets/_components/FormLabel';

type FormControlProps = {
  children: ReactNode;
  title?: string | null;
  subTitle?: string | null;
  withAsterisk?: boolean;
  withSeparator?: boolean;
  className?: string;
};

export function FormControl(props: FormControlProps) {
  const { children, title, subTitle, withAsterisk = false, withSeparator = false, className } = props;

  const { classes, cx } = useStyles({ withSeparator });

  return (
    <Box className={cx(classes.propContainer, className)}>
      <FormLabel title={title} subTitle={subTitle} withAsterisk={withAsterisk} />
      {children}
    </Box>
  );
}
