import { Box, Checkbox, Flex } from '@mantine/core';
import cx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import * as Y from 'yjs';

import * as classes from './QuestionAnswersStudio.css';
import { Hoverable } from '@/components/Hoverable';
import { Selectable } from '@/components/Selectable';
import { WidgetAction } from '@/types/Stage';
import { DndGrid } from '@/widgets/_components/dnd/DndGrid/DndGrid';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { WidgetActionsBar } from '@/widgets/_components/widget/WidgetActionsBar';

const COL_ANSWER_WIDTH = 280;

const getContainerStyle = (isTextOnly?: boolean): CSSProperties => ({
  height: '100%',
  ...(!isTextOnly && { width: 'fit-content' })
});

type AnswerAction = {
  name: WidgetAction;
  handler: () => void;
};

type QuestionAnswersStudioProps = {
  amountOfColumns: number;
  answers: Y.Map<unknown>[];
  contextId: string;
  getAnswerActions: (answerIndex: number) => AnswerAction[];
  id: string;
  onDrop: (currentIndex: number, newIndex: number) => void;
  selectedId: string | null;
  renderAnswerOption: (answerIndex: number) => ReactNode;
  answerClassName: string;
  value?: string[];
  onChange?: (newAnswers: string[]) => void;
  isTextOnly?: boolean;
  withFixedHeight?: boolean;
};

export const QuestionAnswersStudio = (props: QuestionAnswersStudioProps) => {
  const {
    amountOfColumns,
    answers,
    contextId,
    onChange,
    onDrop,
    value,
    getAnswerActions,
    id,
    selectedId,
    renderAnswerOption,
    answerClassName,
    isTextOnly,
    withFixedHeight = true
  } = props;

  const containerStyle = getContainerStyle(isTextOnly);

  return (
    <Flex className={withFixedHeight ? classes.wrapper : ''}>
      <Checkbox.Group
        value={value}
        onChange={onChange}
        className={classes.answers}
        classNames={{
          label: questionClasses.label,
          description: questionClasses.description
        }}
        size="xl"
      >
        <DndGrid amountOfColumns={amountOfColumns} onDrop={onDrop} contextId={contextId} colMaxWidth={COL_ANSWER_WIDTH}>
          {answers.map((answer, answerIndex) => (
            <Box key={answer.get('id') as string} className={cx(classes.answerItemWrapper, answerClassName)}>
              <Selectable id={id + answer.get('id')} style={containerStyle}>
                <Hoverable style={containerStyle}>
                  <WidgetActionsBar
                    actions={getAnswerActions(answerIndex)}
                    className={classes.actionBar}
                    isOpened={id + answer.get('id') === selectedId}
                  >
                    {renderAnswerOption(answerIndex)}
                  </WidgetActionsBar>
                </Hoverable>
              </Selectable>
            </Box>
          ))}
        </DndGrid>
      </Checkbox.Group>
    </Flex>
  );
};
