export const manifestLocales = {
  openDiscussion: {
    previewImageAlt: {
      en: 'Open discussion',
      he: 'דיון פתוח'
    },
    initialText: {
      en: 'This is the question for the discussion?',
      he: 'זאת השאלה לדיון?'
    }
  }
};
