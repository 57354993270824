import { useEffect } from 'react';

import { useYjsStore } from './useYjs';
import { DocumentType } from '@/components/YjsProvider';
import { useUser } from '@/queries/user';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useWorkMode } from '@/utils/useWorkMode';

type UseInitializeYjsArgs = {
  documentName?: string;
  documentType: DocumentType;
};

export const useInitializeYjs = (args: UseInitializeYjsArgs) => {
  const { documentName, documentType } = args;

  const initializeYjs = useYjsStore(state => state.initializeYjs);
  const { type: learningObjectType, isLoading } = useLearningObjectType();
  const { user } = useUser();
  const { isPlayerAssignment: isAssignment } = useWorkMode();

  useEffect(() => {
    if (typeof window !== 'undefined' && documentName && !isLoading && learningObjectType && user) {
      return initializeYjs({ documentName, documentType, isAssignment, learningObjectType, user });
    }
  }, [documentName, documentType, initializeYjs, isAssignment, isLoading, learningObjectType, user]);
};
