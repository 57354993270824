import { Box, Flex, SelectProps } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { forwardRef, useCallback, useMemo } from 'react';

import { Select } from '@/components/Select';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { TextDirectionLTR } from '@/icons/TextDirectionLTR';
import { TextDirectionRTL } from '@/icons/TextDirectionRTL';
import { Direction } from '@/utils/locale.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { BUTTONS_GAP_PX } from '@/widgets/_components/RichTextToolbar/RichTextToolbar.css';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

const directionIcon = {
  ltr: TextDirectionLTR,
  rtl: TextDirectionRTL
};

export type DirectionSelectProps = {
  editor: Editor | null;
  isSelectView?: boolean;
};

export function DirectionSelect(props: DirectionSelectProps) {
  const { isSelectView } = props;
  const { classes } = useStyles({ maxWidth: 66 });
  const { contentDirection } = useContentLanguage();
  const { uiLanguage } = useUiLanguage();
  const t = useTranslations('widgets.directionSelect');
  const { editor } = props;

  const getDirectionDataActiveProp = useCallback(
    (direction: Direction) => {
      return !!editor?.isActive({ dir: direction });
    },
    [editor]
  );

  const getCurrentDirection = useCallback(() => {
    if (editor?.isActive({ dir: 'ltr' })) {
      return 'ltr';
    }
    if (editor?.isActive({ dir: 'rtl' })) {
      return 'rtl';
    }
    return contentDirection;
  }, [contentDirection, editor]);

  const setDirection = useCallback(
    (direction: Direction) => {
      editor?.commands.setTextDirection(direction);
    },
    [editor]
  );

  const directionOptions: DirectionOptionProps[] = useMemo(
    () => [
      {
        value: 'ltr',
        getDirectionDataActiveProp,
        setDirection,
        label: ''
      },
      {
        value: 'rtl',
        getDirectionDataActiveProp,
        setDirection,
        label: ''
      }
    ],
    [getDirectionDataActiveProp, setDirection]
  );

  const currentDirection = getCurrentDirection();
  const Icon = directionIcon[currentDirection as Direction];

  return isSelectView ? (
    <Select
      // TODO
      title={t(currentDirection, { lng: uiLanguage }) ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: classes.selectInput,
        section: classes.selectIcon,
        label: classes.selectLabel
      }}
      data={directionOptions}
      renderOption={renderDirectionOption}
      label={
        <Box data-on={true}>
          <Icon />
        </Box>
      }
      variant="unstyled"
      leftSection={<DropdownArrow />}
      leftSectionWidth={16}
      rightSection={<></>}
      rightSectionWidth={'0px'}
    />
  ) : (
    <Flex direction="row" dir="ltr" gap={BUTTONS_GAP_PX}>
      {directionOptions.map(directionData => (
        <DirectionOption
          key={directionData.value}
          value={directionData.value}
          getDirectionDataActiveProp={directionData.getDirectionDataActiveProp}
          setDirection={directionData.setDirection}
          label={directionData.label}
        />
      ))}
    </Flex>
  );
}

type DirectionOptionProps = {
  value: Direction;
  getDirectionDataActiveProp: (direction: Direction) => boolean;
  setDirection: (direction: Direction) => void;
  label: string;
};

const renderDirectionOption: SelectProps['renderOption'] = props => {
  const { option } = props;

  const { value, getDirectionDataActiveProp, setDirection, label } = option as DirectionOptionProps;

  return (
    <DirectionOption
      value={value}
      getDirectionDataActiveProp={getDirectionDataActiveProp}
      setDirection={setDirection}
      label={label}
    />
  );
};

const DirectionOption = forwardRef<HTMLButtonElement, DirectionOptionProps>(function DirectionOption(props, ref) {
  const { getDirectionDataActiveProp, setDirection, value } = props;

  const { uiLanguage } = useUiLanguage();
  const t = useTranslations('widgets.directionSelect');

  const Icon = directionIcon[value];

  return (
    <RichTextEditor.Control
      title={t(value, { lng: uiLanguage }) ?? ''}
      data-on={getDirectionDataActiveProp(value)}
      onClick={() => setDirection(value)}
      ref={ref}
      m={0}
      w="100%"
    >
      <Icon />
    </RichTextEditor.Control>
  );
});
