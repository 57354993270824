import { Box } from '@mantine/core';
import { useEffect } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { Hoverable } from '@/components/Hoverable';
import { Selectable } from '@/components/Selectable';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';
import { FillInTheBlanksText } from '@/widgets/FillInTheBlanksQuestion/studio/FillInTheBlanksText';
import { WordBank } from '@/widgets/FillInTheBlanksQuestion/studio/WordBank';
import { ContentObjectProvider, useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function FillInTheBlanksQuestionAnswerStudio(props: StudioProps) {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.FillInTheBlanksQuestion}
      withOrchestrationWrapper={false}
    >
      <FillInTheBlanksQuestionAnswerStudioImplementation {...props} />
    </ContentObjectProvider>
  );
}

function FillInTheBlanksQuestionAnswerStudioImplementation(props: StudioProps) {
  const { initialize } = props;

  const { classes, cx } = useStyles({});

  useEffect(() => {
    initialize?.({
      actions: [],
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <>
      <Box className={cx(classes.answer, ThemeClassNames.widgets.fillInTheBlanksQuestion.answer)}>
        <Hoverable>
          <Selectable>
            <FillInTheBlanksText />
          </Selectable>
        </Hoverable>
      </Box>
      <WordBank />
    </>
  );
}
