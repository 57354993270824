import { mergeAttributes, Node, textblockTypeInputRule } from '@tiptap/core';
import { Level as TiptapLevel } from '@tiptap/extension-heading';

export type Level = '1' | '2' | '3' | '4' | '5' | '6';

export interface HeadingOptions {
  levels: Level[];
  HTMLAttributes: Record<string, unknown>;
}

export const Heading = Node.create<HeadingOptions>({
  name: 'heading',

  addOptions() {
    return {
      levels: ['1', '2', '3', '4', '5', '6'],
      HTMLAttributes: {}
    };
  },

  content: 'inline*',

  group: 'block',

  defining: true,

  addAttributes() {
    return {
      level: {
        default: '1',
        rendered: false
      }
    };
  },

  parseHTML() {
    return this.options.levels.map((level: Level) => ({
      tag: `h${level}`,
      attrs: { level }
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = !!this.options.levels.find(level => level === node.attrs['level']);
    const level = hasLevel ? node.attrs['level'] : this.options.levels[0];

    return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setHeading:
        attributes =>
        ({ commands }) => {
          if (!this.options.levels.includes(attributes.level as unknown as Level)) {
            return false;
          }

          return commands.setNode(this.name, attributes);
        },
      toggleHeading:
        attributes =>
        ({ commands }) => {
          if (!this.options.levels.includes(attributes.level as unknown as Level)) {
            return false;
          }

          return commands.toggleNode(this.name, 'paragraph', attributes);
        }
    };
  },

  addKeyboardShortcuts() {
    return this.options.levels.reduce(
      (items, level) => ({
        ...items,
        ...{
          [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level: level as unknown as TiptapLevel })
        }
      }),
      {}
    );
  },

  addInputRules() {
    return this.options.levels.map(level => {
      return textblockTypeInputRule({
        find: new RegExp(`^(#{1,${level}})\\s$`),
        type: this.type,
        getAttributes: {
          level
        }
      });
    });
  }
});
