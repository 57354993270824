import { TextInput } from '@mantine/core';
import React, { useState } from 'react';

import * as styles from './Chat.css';
import { useAIModuleBackendData } from '../useAIModuleBackendData';

export const Chat = () => {
  const [message, setMessage] = useState('');

  const { data, mutate, isLoading } = useAIModuleBackendData({ feature: 'chat', data: { message } });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(message);

    mutate();
  };

  return null;
  console.log('isLoading', isLoading);
  console.log('data', data);

  return (
    <div className={styles.chat}>
      <div>Chat</div>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Message"
          onChange={event => setMessage(event.target.value)}
          placeholder="Message..."
          value={message}
        />
      </form>
    </div>
  );
};
