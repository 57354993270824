import { FC } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { InteractivePollAnswerPlayer } from '@/contentObjects/InteractivePollAnswer/InteractivePollAnswerPlayer';
import { InteractiveQuizAnswerPlayer } from '@/contentObjects/InteractiveQuizAnswer/InteractiveQuizAnswerPlayer';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import { InteractivePollPlayer } from '@/widgets/InteractivePoll/player';
import { InteractiveQuizPlayer } from '@/widgets/InteractiveQuiz/player';
import { OpenDiscussion as OpenDiscussionPlayer } from '@/widgets/OpenDiscussion/player';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const playerPlenaryStudentWidgets: Record<WidgetType, FC<any>> = {
  Audio: () => null,
  BlockTitle: () => null,
  ClozeQuestion: () => null,
  ClozeQuestionAnswer: () => null,
  CombinedTitle: () => null,
  Embed: () => null,
  EnclosureWithBlockTitle: () => null,
  EnclosureWithCombinedTitle: () => null,
  EvaluatablePlugin: () => null,
  EvaluatablePluginAnswer: () => null,
  FillInTheBlanksQuestion: () => null,
  FillInTheBlanksQuestionAnswer: () => null,
  FlexSection: FlexSectionPlayer,
  Image: () => null,
  InteractivePoll: InteractivePollPlayer,
  InteractivePollAnswer: InteractivePollAnswerPlayer,
  InteractiveQuiz: InteractiveQuizPlayer,
  InteractiveQuizAnswer: InteractiveQuizAnswerPlayer,
  MicrosoftEmbed: () => null,
  OpenDiscussion: OpenDiscussionPlayer,
  OpenQuestion: () => null,
  OpenQuestionAnswer: () => null,
  Page: () => null,
  PdfDocument: () => null,
  Plugin: () => null,
  QuestionGroupList: () => null,
  Section: () => null,
  SelectionQuestion: () => null,
  SelectionQuestionAnswer: () => null,
  Slide: () => null,
  Spacer: () => null,
  SubmitPage: () => null,
  SubmitPageComment: () => null,
  SubmitPageReturnToFixSwitch: () => null,
  SubmitPageSatisfactionSurvey: () => null,
  SubmitPageTitle: () => null,
  SubmitPageUnansweredQuestions: () => null,
  SummaryPage: () => null,
  SummaryPageSubmissionHistory: () => null,
  SummaryPageTaskSubmittedNotification: () => null,
  Table: () => null,
  Text: () => null,
  Video: () => null
};
