import { useTranslations } from 'next-intl';

import { CirclePlus } from '@/icons/CirclePlus';
import { FormControl } from '@/widgets/_components/FormControl';
import { IconButton } from '@/widgets/_components/IconButton';

type AnswersAddNewProps = { onClick: () => void; dataTestId: string };

export const AnswersAddNew = (props: AnswersAddNewProps) => {
  const t = useTranslations('components.answersAddNew');
  const { onClick, dataTestId } = props;

  return (
    <FormControl title={t('title')}>
      <IconButton Icon={<CirclePlus />} dataTestId={dataTestId} onClick={onClick} />
    </FormControl>
  );
};
