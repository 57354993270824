export enum StateStatus {
  Unsubmitted = 'Unsubmitted',
  Submitted = 'Submitted',
  ReturnedToFix = 'ReturnedToFix',
  Evaluated = 'Evaluated'
}

export type SubmitAssignmentOptions = {
  assignmentId: string;
  assignmentURL: string;
  studentId: string;
};

export const submitAssignment = async ({ assignmentId, assignmentURL, studentId }: SubmitAssignmentOptions) => {
  if (!assignmentId || !studentId) {
    return;
  }

  const response = await fetch('/api/assignment/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ assignmentId, studentId, assignmentURL })
  });

  if (!response.ok) {
    throw new Error(`Submitting an assignment failed (${response.status}): ${response.statusText}`, {
      cause: response
    });
  }
};

export type GetAssignmentStatusOptions = {
  assignmentId: string;
  userId: string;
};

export const getAssignmentStatus = async ({ assignmentId, userId }: GetAssignmentStatusOptions) => {
  if (!assignmentId || !userId) {
    return;
  }

  const params = new URLSearchParams({ userId, assignmentId });

  const response = await fetch(`/api/assignment/get-status?${params.toString()}`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`Getting assignment status failed (${response.status}): ${response.statusText}`, {
      cause: response
    });
  }

  return response.json();
};

export type GetActivitySnapshotForAssignmentOptions = { assignmentId: string };

export const getSnapshotForAssignment = async ({ assignmentId }: GetActivitySnapshotForAssignmentOptions) => {
  if (!assignmentId) {
    return;
  }

  const params = new URLSearchParams({ assignmentId });

  const response = await fetch(`/api/assignment/get-snapshot?${params.toString()}`, { method: 'GET' });
  if (!response.ok) {
    return null;
  }

  return response.json();
};
