import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { Direction } from '@/utils/locale.utils';
import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type ClozeOptionStyleProps = {
  direction: Direction;
  fontFamily?: string;
  fontSize?: string;
  disabled?: boolean;
  isCorrect?: boolean;
  width?: number;
};

export const useStyles = createStyles((theme, props: ClozeOptionStyleProps) => {
  const { disabled, width, fontFamily, fontSize, isCorrect, direction } = props;
  const globalThemeContentColors = theme.other.global.content.colors;
  const clozeQuestionTheme = theme.other.learningItems.clozeQuestion;
  const clozeQuestionInputFieldTheme = clozeQuestionTheme.inputField;
  const {
    fontFamily: themeFontFamily,
    fontSize: themeFontSize,
    fontWeight: themeFontWeight
  } = theme.other.typography.p;
  const globalFontFamily = theme.other.global.content.fontFamily;

  const lineHeight = clozeQuestionTheme.answerArea.lineHeight;
  const placeholderColor = clozeQuestionTheme.answerArea.placeholderColor;

  const customBorderColor =
    isCorrect === undefined ? undefined : isCorrect ? theme.colors.success[0] : theme.colors.failure[0];

  const borderStyle = getThemeBorderStyle(clozeQuestionInputFieldTheme.border, globalThemeContentColors.primary.dark, {
    ...(customBorderColor && { borderColor: customBorderColor })
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(clozeQuestionInputFieldTheme.borderRadius);

  const disabledStyles = disabled
    ? {
        cursor: 'not-allowed',
        color: theme.colors.dark[2],
        opacity: '0.6'
      }
    : {};

  const color = clozeQuestionInputFieldTheme.color ?? globalThemeContentColors.secondary.contrast;
  const backgroundColor = disabled
    ? theme.colors.gray[1]
    : clozeQuestionInputFieldTheme.backgroundColor ?? globalThemeContentColors.secondary.main;

  const rightSectionIconPadding = 28;

  return {
    componentWithContent: {
      display: 'inline-block',
      fontFamily: fontFamily || themeFontFamily || globalFontFamily,
      fontSize: rem(fontSize || themeFontSize),
      height: '100%',
      maxWidth: '100%',
      padding: `0 ${rem(2)}`,
      position: 'relative',
      top: rem(-1),
      verticalAlign: 'middle'
    },
    content: {
      ...borderStyle,
      ...borderRadiusStyle,
      ...disabledStyles,
      backgroundColor,
      minHeight: 'auto',
      '&:focus-within': {
        outline: `${rem(1)} solid ${borderStyle.borderColor}`
      },
      '& input, & textarea': {
        backgroundColor,
        color
      }
    },
    input: {
      ...borderRadiusStyle,
      fontFamily: fontFamily || themeFontFamily || globalFontFamily,
      fontSize: rem(fontSize || themeFontSize),
      fontWeight: themeFontWeight,
      border: 'none',
      height: `calc(${rem(fontSize || themeFontSize)} * ${lineHeight})`,
      lineHeight,
      maxWidth: '100%',
      minHeight: 'unset',
      paddingTop: 0,
      paddingInlineEnd: 0,
      paddingBottom: 0,
      paddingInlineStart: rem(6),
      width: width ? rem(width + rightSectionIconPadding) : rem(250),
      textAlign: `${direction === 'ltr' ? 'left' : 'right'} !important` as 'left' | 'right',
      verticalAlign: 'middle',
      '::placeholder': {
        color: placeholderColor
      }
    },
    selectInput: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    selectWrapper: {
      paddingTop: 0,
      paddingInlineStart: 0,
      paddingBottom: 0,
      paddingInlineEnd: 0
    },
    dropdownItem: {
      fontFamily: `${fontFamily || themeFontFamily || globalFontFamily} !important`,
      fontSize: rem(fontSize || themeFontSize),
      padding: 0,
      '> span': {
        color: `${color} !important`,
        lineHeight: `calc(${fontSize || themeFontSize}px + 1px)`
      }
    },
    icon: {
      borderRadius: '50%',
      height: rem(16),
      padding: rem(3),
      position: 'absolute',
      right: rem(-5),
      top: rem(-7),
      width: rem(16),
      zIndex: 1
    },
    checkIcon: {
      background: theme.colors.success
    },
    crossIcon: {
      background: theme.colors.failure,
      color: theme.white
    },
    rightSection: {
      width: rem(24),
      display: 'flex',
      alignItems: 'center',
      color: `${color} !important`
    }
  };
});
