import { Text, TextProps } from 'recharts';

import { Data, FONT_SIZE, IMG_HEIGHT, IMG_WIDTH, HORIZONTAL_MARGIN, TEXT_WIDTH, VERTICAL_MARGIN } from './Chart';

type BarChartAxisLabelProps = TextProps & {
  payload: { value: string };
  data: Data;
  direction: 'horizontal' | 'vertical';
  uiDirection: 'ltr' | 'rtl';
  width: number;
};

export const BarChartAxisLabel = (props: BarChartAxisLabelProps) => {
  const { payload, data, direction, x: xProps = 0, y: yProps = 0, uiDirection, width } = props;

  const dataItem = data.find(item => item.key === payload.value);
  if (!dataItem) {
    return null;
  }

  const x = direction === 'horizontal' ? +xProps : uiDirection === 'ltr' ? 0 : +xProps;
  const y = +yProps;

  const renderText = (x: number, y: number, text: string) => {
    if (direction === 'horizontal') {
      const barSize = width / data.length;
      const maxTextWidth = barSize * 0.5; // barSize also includes margins, so take half

      return (
        <Text x={x} y={y} fontSize={FONT_SIZE} textAnchor={'middle'} width={maxTextWidth} verticalAnchor="start">
          {text}
        </Text>
      );
    }

    return (
      <Text
        x={x}
        y={y}
        fontSize={FONT_SIZE}
        textAnchor={uiDirection === 'ltr' ? 'start' : 'end'}
        width={200}
        verticalAnchor="middle"
      >
        {text}
      </Text>
    );
  };

  const renderImage = (x: number, y: number, href: string) => {
    return (
      <image
        x={direction === 'horizontal' ? x - IMG_WIDTH / 2 : x}
        y={direction === 'horizontal' ? y : y - IMG_HEIGHT / 2}
        href={href}
        width={IMG_WIDTH}
        height={IMG_HEIGHT}
        clip-path="inset(0% round 6px)"
      />
    );
  };

  if (dataItem.img && dataItem.label) {
    let textX = x;
    let textY = y;
    let imgX = x;
    let imgY = y;

    if (direction === 'horizontal') {
      textY = y + VERTICAL_MARGIN + IMG_HEIGHT + VERTICAL_MARGIN;
      imgY = y + VERTICAL_MARGIN;
    } else {
      if (uiDirection === 'ltr') {
        textX = x + IMG_WIDTH + HORIZONTAL_MARGIN;
      } else {
        imgX = x + HORIZONTAL_MARGIN + TEXT_WIDTH + HORIZONTAL_MARGIN;
        textX = x + HORIZONTAL_MARGIN;
      }
    }

    return (
      <>
        {renderImage(imgX, imgY, dataItem.img)}
        {renderText(textX, textY, dataItem.label)}
      </>
    );
  }

  const calculatedX = direction === 'vertical' && uiDirection === 'rtl' ? x + HORIZONTAL_MARGIN : x;
  const calculatedY = direction === 'horizontal' ? y + VERTICAL_MARGIN : y;

  if (dataItem.label) {
    return renderText(calculatedX, calculatedY, dataItem.label);
  }

  if (dataItem.img) {
    return renderImage(calculatedX, calculatedY, dataItem.img);
  }
};
