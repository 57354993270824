import { LearningObjectTypes } from 'shared/types/LearningObjectType';

import { useLearningObjectSnapshotAndDraftQuery } from './useLearningObjectSnapshotAndDraftQuery';

export function useLearningObjectType() {
  const { learningObjectSnapshot, learningObjectDraft, isLoading } = useLearningObjectSnapshotAndDraftQuery();

  const type: LearningObjectTypes | undefined = learningObjectSnapshot?.type ?? learningObjectDraft?.type;
  const isPlenaryLesson = type === LearningObjectTypes.PlenaryLesson;
  const isLearningActivity = type === LearningObjectTypes.LearningActivity;

  return { type, isLearningActivity, isPlenaryLesson, isLoading };
}
