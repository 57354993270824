export type ResizableDimensions = HorizontalResizableDimensions | VerticalResizableDimensions;

export type HorizontalResizableDimensions = {
  width: number;
  height: 'auto';
};

export type VerticalResizableDimensions = {
  width: number;
  height: number;
};

export type Direction = 'up-start' | 'start' | 'down-start' | 'down' | 'down-end' | 'end' | 'up-end';

export function isHeightControlled(
  isResizableVertically: boolean,
  dimensions: ResizableDimensions
): dimensions is VerticalResizableDimensions {
  return isResizableVertically && dimensions.height !== 'auto';
}
