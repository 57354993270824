import { UnstyledButton } from '@mantine/core';
import { useCallback } from 'react';

import { TableMenuProps } from './TableMenu';
import { useStyles } from './TableTooltip.styles';
import { Trashcan } from '@/icons/Trashcan';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { ColumnId, RowId, createContentId, getActualColumnWidth } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
type DeleteActionButtonProps = TableMenuProps;

export function DeleteActionButton(props: DeleteActionButtonProps) {
  const { columnId, rowId, type } = props;
  const columnOrder = useTable(context => context.columnOrder);
  const columnWidths = useTable(context => context.columnWidths);
  const rowOrder = useTable(context => context.rowOrder);
  const setColumnOrder = useTable(context => context.setColumnOrder);
  const setColumnWidths = useTable(context => context.setColumnWidths);
  const setRowHeights = useTable(context => context.setRowHeights);
  const setRowOrder = useTable(context => context.setRowOrder);
  const content = useTable(context => context.content);
  const { classes } = useStyles({ type });
  const { document } = useContentObject();

  const handleDeleteColumn = useCallback(
    (columnId: ColumnId) => {
      const newColumnOrder = columnOrder.filter(id => id !== columnId);

      const cellIdsToRemove = rowOrder.map(rowId => createContentId(columnId, rowId));

      const lastColumnId = columnOrder[columnOrder.length - 1];
      columnWidths[lastColumnId] = getActualColumnWidth(columnId);

      const { [columnId]: removedColumnWidth, ...newColumnWidths } = columnWidths;
      const newColumnWidthsSum = Object.values(newColumnWidths).reduce((acc, width) => acc + width, 0);
      const toAddArray = newColumnOrder.map(
        columnId => (newColumnWidths[columnId] / newColumnWidthsSum) * removedColumnWidth
      );
      newColumnOrder.forEach((columnId, index) => {
        newColumnWidths[columnId] = newColumnWidths[columnId] + toAddArray[index];
      });

      document.transact(() => {
        cellIdsToRemove.forEach(cellId => content?.delete(cellId));
        setColumnWidths(newColumnWidths);
        setColumnOrder(newColumnOrder);
      });
    },
    [columnOrder, columnWidths, document, rowOrder, setColumnOrder, setColumnWidths, content]
  );

  const handleDeleteRow = useCallback(
    (rowId: RowId) => {
      const cellIdsToRemove = columnOrder.map(columnId => createContentId(columnId, rowId));

      document.transact(() => {
        cellIdsToRemove.forEach(cellId => content?.delete(cellId));
        setRowHeights(currentRowHeights => {
          const { [rowId]: _, ...newRowHeights } = currentRowHeights;
          return newRowHeights;
        });
        setRowOrder(rowOrder => rowOrder.filter(id => id !== rowId));
      });
    },
    [columnOrder, document, content, setRowHeights, setRowOrder]
  );

  const handleDelete = useCallback(() => {
    if (type === 'column') {
      handleDeleteColumn(columnId);
    } else {
      handleDeleteRow(rowId);
    }
  }, [columnId, handleDeleteColumn, handleDeleteRow, rowId, type]);

  return (
    <UnstyledButton className={classes.button} onClick={handleDelete}>
      <Trashcan color="white" width={16} height={16} />
    </UnstyledButton>
  );
}
