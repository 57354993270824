/*** This is a combination of the source code of useMouse and of useElementSize, it is done this way instead of using useMergedRef because the result from useMergedRef doesn't work well with react-dnd ref input expectation.
TODO: Research this further and see if it can be improved.
 ***/

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react';
import type { MouseEvent } from 'react';

type ObserverRect = Omit<DOMRectReadOnly, 'toJSON'>;

const defaultState: ObserverRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

export function useMouseAndSize<T extends HTMLElement = any>(
  options: { resetOnExit?: boolean } = { resetOnExit: false }
) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rect, setRect] = useState<ObserverRect>(defaultState);
  const ref = useRef<T>(null);

  // Mouse position logic
  const setMousePosition = (event: MouseEvent<HTMLElement>) => {
    if (ref.current) {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = Math.max(0, Math.round(event.pageX - rect.left - (window.pageXOffset || window.scrollX)));
      const y = Math.max(0, Math.round(event.pageY - rect.top - (window.pageYOffset || window.scrollY)));
      setPosition({ x, y });
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const resetMousePosition = () => setPosition({ x: 0, y: 0 });

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener('mousemove', setMousePosition as any);
    if (options.resetOnExit) {
      element.addEventListener('mouseleave', resetMousePosition as any);
    }

    return () => {
      element.removeEventListener('mousemove', setMousePosition as any);
      if (options.resetOnExit) {
        element.removeEventListener('mouseleave', resetMousePosition as any);
      }
    };
  }, [options.resetOnExit]);

  // Resize observer logic
  const observer = useMemo(
    () =>
      typeof window !== 'undefined'
        ? new ResizeObserver(entries => {
            const entry = entries[0];
            if (entry) {
              setRect(entry.contentRect);
            }
          })
        : null,
    []
  );

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [observer]);

  return { ref, position, size: { width: rect.width, height: rect.height } };
}
