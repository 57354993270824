import { QuestionNumberingPosition, QuestionNumberingStyle } from '@hendrx/modules/ui';
import StarterKit from '@tiptap/starter-kit';
import cx from 'clsx';
import { memo, useMemo } from 'react';
import * as Y from 'yjs';

import { useTextTools } from 'shared/richTextToolbar/useTextTools';
import { ToolbarType } from 'shared/types/RichTextToolbar';

import * as classes from './QuestionTitle.css';
import { useQuestionNumberingContext } from '@/components/QuestionNumberingContext';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useQuestionNumberingSetting } from '@/contentObjects/Root/hooks/useQuestionNumberingSetting';
import { RichText } from '@/widgets/_components/RichText';

const getStyledQuestionNumber = (questionNumber: number | null | undefined, style: QuestionNumberingStyle) => {
  return style === QuestionNumberingStyle.Standalone ? `${questionNumber}` : `${questionNumber}.`;
};

type QuestionTitleProps = {
  questionId: string;
  fragment: Y.XmlFragment;
  dataTestIdPrefix?: string;
  editable?: boolean;
};

export const QuestionTitle = memo(function QuestionTitle(props: QuestionTitleProps) {
  const { questionId, fragment, dataTestIdPrefix, editable = true } = props;

  const [withAutomaticQuestionNumbering] = useQuestionNumberingSetting();

  const document = useCurrentDocument();
  const [questionNumberingStyle] = useActivityThemeStylesProperty<QuestionNumberingStyle>(
    document,
    'questionNumbering.numberingStyle'
  );
  const [questionNumberingPosition] = useActivityThemeStylesProperty<QuestionNumberingPosition>(
    document,
    'questionNumbering.numberingPosition'
  );
  const questionNumbering = useQuestionNumberingContext();
  const questionNumber = questionNumbering[questionId];
  const styledQuestionNumber = getStyledQuestionNumber(questionNumber, questionNumberingStyle);

  const [tools] = useTextTools({ textType: 'header' });

  const extraExtensions = useMemo(() => {
    const htmlAttributesConfig = {
      HTMLAttributes: {
        class: 'question-number-heading',
        'data-question-number': `${styledQuestionNumber}`
      }
    };
    return [StarterKit.configure({ paragraph: htmlAttributesConfig, heading: htmlAttributesConfig })];
  }, [styledQuestionNumber]);

  return withAutomaticQuestionNumbering && questionNumber ? (
    <RichText
      extraExtensions={extraExtensions}
      preserveWhitespace="full"
      tools={tools}
      fragment={fragment}
      dataTestIdPrefix={dataTestIdPrefix}
      className={cx({
        [classes.headingStyleBefore]: questionNumberingPosition === QuestionNumberingPosition.BeforeHeadline,
        [classes.headingStyleAfter]: questionNumberingPosition === QuestionNumberingPosition.AfterHeadline,
        [classes.headingStyleAbove]: questionNumberingPosition === QuestionNumberingPosition.AboveHeadline
      })}
      editable={editable}
      toolbarType={editable ? ToolbarType.Fixed : ToolbarType.None}
      overrideTypographyStylesPath="questionNumbering"
    />
  ) : (
    <RichText
      tools={tools}
      fragment={fragment}
      dataTestIdPrefix={dataTestIdPrefix}
      editable={editable}
      toolbarType={editable ? ToolbarType.Fixed : ToolbarType.None}
    />
  );
});
