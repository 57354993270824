export const manifestLocales = {
  interactiveQuiz: {
    previewImageAlt: {
      en: 'Interactive quiz',
      he: 'חידון אינטראקטיבי'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    },
    defaultAnswer: {
      en: 'Answer',
      he: 'תשובה'
    }
  }
};
