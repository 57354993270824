import { AnswerAreaThemeProps, BasicThemeProps, BorderThemeProps, GlobalColorThemeProps } from '@hendrx/modules/ui';
import { Box, Checkbox, Flex, Group } from '@mantine/core';
import { ReactElement } from 'react';

import { AnswerElementType } from 'shared/types/AnswerElement';
import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import * as answerBodyClasses from './AnswerBody.css';
import { useStyles } from './AnswerOption.styles';
import { useStyles as useAnswerAreaStyles } from '@/widgets/hooks/AnswerArea.styles';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

export type AnswerElementComponentProps = {
  answerIndex: number;
  answerElementType: AnswerElementType;
  dataTestIdPrefix?: string;
  hasImage?: boolean;
};

export function AnswerOption(props: {
  AnswerElementComponent: (props: AnswerElementComponentProps) => ReactElement;
  answerAreaThemeProps: AnswerAreaThemeProps;
  answerIndex: number;
  answersStyle: AnswerStyleType;
  checkboxThemeProps: BasicThemeProps;
  selectedCheckboxThemeProps?: GlobalColorThemeProps & { border: BorderThemeProps };
  dataTestId: string;
  answerId?: string;
  isChecked?: boolean;
  withCheckbox?: boolean;
}) {
  const {
    AnswerElementComponent,
    answerAreaThemeProps,
    answerId,
    answerIndex,
    answersStyle,
    checkboxThemeProps,
    selectedCheckboxThemeProps,
    dataTestId,
    isChecked,
    withCheckbox = true
  } = props;

  const { hasAudio, hasImage, hasText, isTextOnly } = useAnswerElements();

  const { classes: commonClasses, cx } = useAnswerAreaStyles({
    isChecked,
    answersStyleType: answersStyle,
    isTextOnly,
    answerAreaThemeProps: answerAreaThemeProps
  });
  const { classes } = useStyles({
    isChecked,
    answersStyleType: answersStyle,
    isTextOnly,
    checkboxThemeProps,
    selectedCheckboxThemeProps
  });

  return (
    <Group
      wrap="nowrap"
      align="flex-start"
      gap={0}
      style={{ height: '100%', position: 'relative' }}
      data-testid={`${dataTestId}-answer-box`}
    >
      {withCheckbox && (
        <Checkbox
          value={answerId}
          classNames={{
            input: cx(commonClasses.checkboxInput, classes.checkboxInput),
            inner: commonClasses.checkboxInner,
            body: cx(commonClasses.checkboxBody, classes.checkboxBody),
            root: cx(commonClasses.checkboxRoot, classes.checkboxRoot)
          }}
          size="xl"
        />
      )}
      <Box className={cx(commonClasses.checkboxLabel, classes.checkboxLabel)}>
        <Box className={commonClasses.contentArea}>
          <Group grow align="flex-start">
            <Box className={answerBodyClasses.answerStyle}>
              {hasImage && (
                <Box>
                  <AnswerElementComponent
                    answerIndex={answerIndex}
                    answerElementType="Image"
                    dataTestIdPrefix={`${dataTestId}-answer-image`}
                    hasImage={hasImage}
                  />
                </Box>
              )}
              <Flex className={hasImage ? answerBodyClasses.caption.withImage : answerBodyClasses.caption.base}>
                {hasText && (
                  <Box className={answerBodyClasses.textWrapper}>
                    <AnswerElementComponent
                      answerIndex={answerIndex}
                      answerElementType="Text"
                      dataTestIdPrefix={`${dataTestId}-answer-text`}
                      hasImage={hasImage}
                    />
                  </Box>
                )}
                {hasAudio && (
                  <Flex
                    className={
                      hasImage ? answerBodyClasses.audioWrapper.withImage : answerBodyClasses.audioWrapper.base
                    }
                  >
                    <AnswerElementComponent
                      answerIndex={answerIndex}
                      answerElementType="Audio"
                      dataTestIdPrefix={`${dataTestId}-answer-audio`}
                      hasImage={hasImage}
                    />
                  </Flex>
                )}
              </Flex>
            </Box>
          </Group>
        </Box>
      </Box>
    </Group>
  );
}
