import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { Box, Divider, Flex, rem } from '@mantine/core';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { Fragment, ReactNode } from 'react';

import { RichTextExtraTools, RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';

import { AlignmentSelect } from './AlignmentSelect';
import { DirectionSelect } from './DirectionSelect';
import { FontFamilySelect } from './FontFamilySelect';
import { InsertImage } from './InsertImage';
import { LineHeightSelect } from './LineHeightSelect';
import { ListTypeSelect } from './ListTypeSelect';
import { MoreMenu } from './MoreMenu';
import * as classes from './RichTextToolbar.css';
import { BUTTONS_GAP_PX } from './RichTextToolbar.css';
import { TextColorPicker } from './TextColorPicker';
import { TextFontSizeSelect } from './TextFontSizeSelect';
import { TextHighlightPicker } from './TextHighlightPicker';
import { TextLevelOnlySelect } from './TextLevelSelect/TextLevelOnlySelect';
import { TextLevelWithParagraphSelect } from './TextLevelSelect/TextLevelWithParagraphSelect';
import { ClearTextStyle } from '@/icons/ClearTextStyle';
import { Code } from '@/icons/Code';
import { HorizontalLine } from '@/icons/HorizontalLine';
import { Link as LinkIcon } from '@/icons/Link';
import { LinkRemove } from '@/icons/LinkRemove';
import { Quote } from '@/icons/Quote';
import { TextBold } from '@/icons/TextBold';
import { TextItalic } from '@/icons/TextItalic';
import { TextStrikeThrough } from '@/icons/TextStrikeThrough';
import { TextUnderline } from '@/icons/TextUnderline';
import { TooltipEdit } from '@/icons/TooltipEdit';
import { TooltipRemove } from '@/icons/TooltipRemove';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';

const SELECT_VIEW_BREAKPOINT = 1700;
const RESIZE_BREAKPOINT1 = 1600;
const RESIZE_BREAKPOINT2 = 1250;

export enum ResizeOptions {
  None = 'none',
  FoldOnBreakpoint1 = 'foldOnBreakpoint1',
  FoldOnBreakpoint2 = 'foldOnBreakpoint2'
}

type RichTextToolbarProps = {
  editor: Editor | null;
  editorContentRef: HTMLDivElement | null;
  setLinkRef?: (value: HTMLDivElement | null) => void;
  type: ToolbarType;
  tools?: (RichTextTools | RichTextExtraTools)[];
  children?: ReactNode;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
};

export function RichTextToolbar(props: RichTextToolbarProps) {
  const {
    editor,
    editorContentRef,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- found on develop
    setLinkRef,
    type,
    tools = Object.values(RichTextTools),
    children,
    overrideTypographyStylesPath,
    changeContrastColorToMain
  } = props;

  const { width } = useViewportSize();
  const { ref: childrenRef, width: childrenWidth } = useElementSize();

  const isSelectView = type === ToolbarType.Fixed && width < SELECT_VIEW_BREAKPOINT + childrenWidth;
  const resizeOptions =
    type === ToolbarType.Fixed
      ? width < RESIZE_BREAKPOINT2 + childrenWidth
        ? [ResizeOptions.FoldOnBreakpoint1, ResizeOptions.FoldOnBreakpoint2]
        : width < RESIZE_BREAKPOINT1 + childrenWidth
        ? [ResizeOptions.FoldOnBreakpoint1]
        : []
      : [];

  const t = useTranslations('widgets.richTextToolbar');

  const toolsData = [
    {
      name: RichTextTools.Lists,
      categoryIndex: 1,
      component: <ListTypeSelect editor={editor} isSelectView={isSelectView} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.LineHeight,
      categoryIndex: 1,
      component: <LineHeightSelect editor={editor} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.Alignment,
      categoryIndex: 1,
      component: <AlignmentSelect editor={editor} isSelectView={isSelectView} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.Direction,
      categoryIndex: 1,
      component: <DirectionSelect editor={editor} isSelectView={isSelectView} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.TextColor,
      categoryIndex: 2,
      component: (
        <TextColorPicker
          portalTarget={editorContentRef}
          editor={editor}
          overrideTypographyStylesPath={overrideTypographyStylesPath}
          changeContrastColorToMain={changeContrastColorToMain}
        />
      ),
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.Bold,
      categoryIndex: 2,
      component: <RichTextEditor.Bold classNames={{ control: classes.control }} icon={TextBold} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.Italic,
      categoryIndex: 2,
      component: <RichTextEditor.Italic classNames={{ control: classes.control }} icon={TextItalic} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.Underline,
      categoryIndex: 2,
      component: <RichTextEditor.Underline classNames={{ control: classes.control }} icon={TextUnderline} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.StrikeThrough,
      categoryIndex: 2,
      component: <RichTextEditor.Strikethrough classNames={{ control: classes.control }} icon={TextStrikeThrough} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.TextHighlight,
      categoryIndex: 2,
      component: <TextHighlightPicker editor={editor} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint2
    },
    {
      name: RichTextExtraTools.TextLevelOnly,
      categoryIndex: 3,
      component: <TextLevelOnlySelect editor={editor} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextExtraTools.TextLevelWithParagraph,
      categoryIndex: 3,
      component: <TextLevelWithParagraphSelect editor={editor} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.FontFamily,
      categoryIndex: 4,
      component: <FontFamilySelect editor={editor} overrideTypographyStylesPath={overrideTypographyStylesPath} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.FontSize,
      categoryIndex: 4,
      component: <TextFontSizeSelect editor={editor} />,
      resizeOption: ResizeOptions.None
    },
    {
      name: RichTextTools.Link,
      categoryIndex: 5,
      component: (
        <>
          <RichTextEditor.Link
            classNames={{ control: classes.control }}
            icon={LinkIcon}
            popoverProps={{ withinPortal: false }}
          />
          {editor?.isActive('link') && (
            <RichTextEditor.Unlink classNames={{ control: classes.control }} icon={LinkRemove} />
          )}
        </>
      ),
      resizeOption: ResizeOptions.FoldOnBreakpoint2
    },
    {
      name: RichTextTools.InsertImage,
      categoryIndex: 5,
      component: <InsertImage editor={editor} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint2
    },
    {
      name: RichTextExtraTools.Css,
      categoryIndex: 6,
      component: (
        <MoreMenu title="CSS" buttonClassNames={{ root: classes.cssButtonRoot, label: classes.cssButtonLabel }}>
          <CustomCSSClassesInput />
        </MoreMenu>
      ),
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.Code,
      categoryIndex: 6,
      component: <RichTextEditor.Code classNames={{ control: classes.control }} icon={Code} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.Tooltip,
      categoryIndex: 6,
      component: (
        <Flex direction="row" dir="ltr" gap={rem(BUTTONS_GAP_PX)}>
          <RichTextEditor.Control
            bg="transparent"
            title={`${t('tooltip')}`}
            active={editor?.isActive('tooltip')}
            disabled={!editor?.can().addTooltip()}
            onClick={() => {
              editor?.chain().focus().addTooltip().run();
            }}
          >
            <TooltipEdit />
          </RichTextEditor.Control>
          {editor?.isActive('tooltip') && (
            <RichTextEditor.Control
              title={`${t('removeTooltip')}`}
              onClick={() => {
                editor?.chain().focus().removeTooltip(editor?.getAttributes('tooltip').tooltipId).run();
              }}
            >
              <TooltipRemove />
            </RichTextEditor.Control>
          )}
        </Flex>
      ),
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.ClearFormatting,
      categoryIndex: 7,
      component: <RichTextEditor.ClearFormatting classNames={{ control: classes.control }} icon={ClearTextStyle} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.HorizontalLine,
      categoryIndex: 7,
      component: <RichTextEditor.Hr classNames={{ control: classes.control }} icon={HorizontalLine} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    },
    {
      name: RichTextTools.Blockquote,
      categoryIndex: 7,
      component: <RichTextEditor.Blockquote classNames={{ control: classes.control }} icon={Quote} />,
      resizeOption: ResizeOptions.FoldOnBreakpoint1
    }
  ];

  const currentToolsData = toolsData.filter(tool => tools.includes(tool.name));
  const mainBarTools = currentToolsData.filter(tool => !resizeOptions.includes(tool.resizeOption));
  const moreMenuTools = currentToolsData.filter(tool => resizeOptions.includes(tool.resizeOption));

  const controlsGroupClass = type === ToolbarType.Fixed ? classes.controlsGroup.fixed : classes.controlsGroup.inline;

  return (
    <RichTextEditor.ControlsGroup className={controlsGroupClass}>
      {children ? (
        <Box ref={childrenRef} className={controlsGroupClass}>
          {children}
          <Divider className={classes.divider} />
        </Box>
      ) : null}
      {currentToolsData?.length > 0 ? (
        <>
          {mainBarTools.map((tool, index) => {
            return (
              <Fragment key={index}>
                {tool.component}
                {index !== mainBarTools.length - 1 && tool.categoryIndex !== mainBarTools[index + 1].categoryIndex && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            );
          })}
          {resizeOptions.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <MoreMenu title={t('moreButton')}>
                {moreMenuTools.map((tool, index) => {
                  return (
                    <Fragment key={index}>
                      {tool.component}
                      {index !== moreMenuTools.length - 1 &&
                        tool.categoryIndex !== moreMenuTools[index + 1].categoryIndex && (
                          <Divider className={classes.divider} />
                        )}
                    </Fragment>
                  );
                })}
              </MoreMenu>
            </>
          )}
        </>
      ) : null}
    </RichTextEditor.ControlsGroup>
  );
}
