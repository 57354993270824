import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle, PROSE_MIRROR_SELECTOR } from '@/utils/theme.utils';

type FillInTheBlanksStylesProps = {
  disabled?: boolean;
  isOver?: boolean;
};

export const useStyles = createStyles((theme, props: FillInTheBlanksStylesProps) => {
  const { disabled, isOver } = props;
  const globalContentColorsTheme = theme.other.global.content.colors;
  const fitbQuestionAnswerAreaTheme = theme.other.learningItems.fillInTheBlanks.answerArea;
  const fitbQuestionBankTheme = theme.other.learningItems.fillInTheBlanks.bank;
  const fitbQuestionBlankTheme = theme.other.learningItems.fillInTheBlanks.blank;

  const borderStyle = getThemeBorderStyle(fitbQuestionAnswerAreaTheme.border, globalContentColorsTheme.decoration.main);
  const borderRadiusStyle = getThemeBorderRadiusStyle(fitbQuestionAnswerAreaTheme.borderRadius);
  const bankBorderStyle = getThemeBorderStyle(fitbQuestionBankTheme.border);
  const bankBorderRadiusStyle = getThemeBorderRadiusStyle(fitbQuestionBankTheme.borderRadius);
  const blankBorderStyle = getThemeBorderStyle(fitbQuestionBlankTheme.border, globalContentColorsTheme.primary.dark);
  const blankBorderRadiusStyle = getThemeBorderRadiusStyle(fitbQuestionBlankTheme.borderRadius);
  const color = fitbQuestionAnswerAreaTheme.color ?? globalContentColorsTheme.secondary.contrast;
  const lineHeight = fitbQuestionAnswerAreaTheme.lineHeight;

  return {
    answer: {
      backgroundColor: fitbQuestionAnswerAreaTheme.backgroundColor ?? globalContentColorsTheme.secondary.main,
      [PROSE_MIRROR_SELECTOR]: { color, lineHeight },
      ...borderStyle,
      ...borderRadiusStyle,
      padding: theme.other.stage.questionInputWrapperPadding,
      minHeight: theme.other.stage.minQuestionInputHeight,
      marginBottom: rem(10),
      '.ProseMirror p.is-editor-empty:first-of-type::before': {
        color: fitbQuestionAnswerAreaTheme.placeholderColor,
        lineHeight: fitbQuestionAnswerAreaTheme.lineHeight
      }
    },
    wordBankWrapper: {
      color: fitbQuestionBankTheme.color ?? globalContentColorsTheme.secondary.contrast,
      backgroundColor: fitbQuestionBankTheme.backgroundColor ?? globalContentColorsTheme.primary.light,
      ...bankBorderStyle,
      ...bankBorderRadiusStyle,
      padding: rem(12),
      minHeight: rem(55),
      display: 'flex',
      flexDirection: 'column',
      gap: rem(8),
      ...(isOver && {
        backgroundColor: fitbQuestionBlankTheme.backgroundColorHovered ?? globalContentColorsTheme.primary.light
      })
    },
    wordBankOptions: {
      gap: rem(8),
      zIndex: 1
    },
    wordBankOption: {
      color: fitbQuestionBlankTheme.color ?? globalContentColorsTheme.secondary.contrast,
      backgroundColor: fitbQuestionBlankTheme.backgroundColor ?? globalContentColorsTheme.secondary.main,
      ...blankBorderStyle,
      ...blankBorderRadiusStyle,
      cursor: disabled ? 'not-allowed' : 'grab',
      alignItems: 'center',
      padding: `0 ${rem(6)}`,
      position: 'relative',
      userSelect: 'none'
    },
    wordBankOptionText: {
      fontSize: theme.fontSizes.lg
    }
  };
});
