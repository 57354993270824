import { ActionIcon, Box, Flex, Radio, Switch, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect } from 'react';

import { QuestionListBulletTypes } from 'shared/types/QuestionGroupList';
import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';

import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityScoreWeight } from '@/contentObjects/Root/hooks/useActivityScoreWeight';
import { BallPen } from '@/icons/BallPen';
import { useStore } from '@/store';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { calculateWeightScore } from '@/widgets/_components/ContentObjectProvider/OrchestrationWrapper/calculateWeightScore';
import { useStyles } from '@/widgets/_components/EvaluationSettings/EvaluationSettings.styles';
import { FormControl } from '@/widgets/_components/FormControl';

type ScoreWeightSettingsProps = {
  withRelativeScoreWeight?: boolean;
};

export function ScoreWeightSettings(props: ScoreWeightSettingsProps) {
  const { withRelativeScoreWeight } = props;

  const t = useTranslations('widgets.evaluationSettings');
  const { classes } = useStyles();
  const [questionScoreMode, setQuestionScoreMode] = useContentObjectProperty<QuestionScoreMode>('questionScoreMode');
  const [relativeScoreMode, setRelativeScoreMode] = useContentObjectProperty<RelativeScoreMode>('relativeScoreMode');
  const [, setBulletListType] = useContentObjectProperty<QuestionListBulletTypes>('questionListBulletType');
  const [saveAnswers, setSaveAnswers] = useContentObjectProperty<boolean>('saveAnswers');
  const [activityScoreWeight] = useActivityScoreWeight();
  const { isPlenaryLesson } = useLearningObjectType();
  const document = useCurrentDocument();
  const updateToolbox = useStore(state => state.updateToolbox);

  const withScore = questionScoreMode === QuestionScoreMode.WithScore;

  const handleSetRelativeScoreMode = (scoreMode: string) => {
    setRelativeScoreMode(scoreMode as RelativeScoreMode);
    if (scoreMode === RelativeScoreMode.RelativeToActivity) {
      setBulletListType(QuestionListBulletTypes.None);
    }
  };

  useEffect(() => {
    calculateWeightScore(document);
  }, [questionScoreMode, relativeScoreMode, document]);

  const openActivityScoreWeightSummary = useCallback(() => {
    updateToolbox({
      selectedCategory: 'activitySettings',
      selectedSubCategory: 'scoreWeight',
      opened: true
    });
  }, [updateToolbox]);

  return (
    <FormControl title={t('scoreWeight')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Radio.Group value={questionScoreMode} onChange={value => setQuestionScoreMode(value as QuestionScoreMode)}>
          <Flex direction="column" gap={12}>
            <Flex justify="space-between">
              <Radio
                disabled={isPlenaryLesson}
                value={QuestionScoreMode.WithScore}
                label={
                  <>
                    <Text span>{t(`widgetScores.${QuestionScoreMode.WithScore}`)}</Text>
                    <Text className={classes.activityScoreWeight} span>
                      &nbsp;({t(`widgetScores.${activityScoreWeight}`)})
                    </Text>
                    {withScore && withRelativeScoreWeight && (
                      <Box className={classes.extendedOptions}>
                        <Radio.Group value={relativeScoreMode} onChange={handleSetRelativeScoreMode}>
                          <Flex direction="column" gap={12}>
                            <Radio
                              value={RelativeScoreMode.RelativeToParent}
                              label={t(`widgetScores.${RelativeScoreMode.RelativeToParent}`)}
                            />
                            <Radio
                              value={RelativeScoreMode.RelativeToActivity}
                              label={t(`widgetScores.${RelativeScoreMode.RelativeToActivity}`)}
                            />
                          </Flex>
                        </Radio.Group>
                      </Box>
                    )}
                  </>
                }
              />
              <ActionIcon
                variant="subtle"
                color="accent.8"
                disabled={isPlenaryLesson}
                ml="xs"
                onClick={openActivityScoreWeightSummary}
                size="xs"
              >
                <BallPen />
              </ActionIcon>
            </Flex>
            <Radio
              value={QuestionScoreMode.WithoutScore}
              label={
                <>
                  {t(`widgetScores.${QuestionScoreMode.WithoutScore}`)}
                  {!withScore && (
                    <Box className={classes.extendedOptions}>
                      <Switch
                        checked={saveAnswers}
                        onChange={event => setSaveAnswers(event.currentTarget.checked)}
                        label={t('saveAnswers')}
                      />
                    </Box>
                  )}
                </>
              }
            />
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
}
