import { Box } from '@mantine/core';
import ImageNext from 'next/image';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { ObjectFit } from 'shared/types/Image';

import { useStyles } from './Base.styles';
import { ImageUpload } from '@/icons/ImageUpload';
import { useFileUpload } from '@/queries/fileUpload';
import { Manifest } from '@/widgets/Image/Manifest';
import { UploadDropzone } from '@/widgets/_components/widget/UploadDropzone';

type ImageClassNames = {
  image?: string;
  dropzone?: string;
};

type BaseProps = {
  src: string;
  setSrc: (src: string) => void;
  alt: string;
  fill?: boolean;
  width?: number;
  height?: number;
  objectFit: ObjectFit;
  classNames?: ImageClassNames;
};
export const Base = forwardRef<() => void | null, BaseProps>((props, ref) => {
  const { src, setSrc, alt, fill = true, width, height, classNames, objectFit } = props;

  const t = useTranslations('widgets');

  const { mutateAsync: uploadFile, isLoading } = useFileUpload();

  const isImageReady = Boolean(src && !isLoading);

  const { classes, cx } = useStyles({ objectFit });
  return (
    <>
      {isImageReady && (
        <Box className={cx(classes.imageContainer, classNames?.image)}>
          <ImageNext width={width} height={height} alt={alt} src={src} fill={fill} className={classes.image} />
        </Box>
      )}

      <UploadDropzone
        Icon={ImageUpload}
        src={src}
        isReady={isImageReady}
        maxSize={Manifest.additionalData?.maxFileSizeBytes}
        maxFiles={Manifest.additionalData?.maxFiles}
        accept={Manifest.additionalData?.acceptFileType}
        placeholderText={t('imageWidget.idleText')}
        upload={uploadFile}
        dropAction={({ src }) => {
          setSrc(src);
        }}
        dropError={t('imageWidget.unableUpload')}
        ref={ref}
        className={classNames?.dropzone}
      />
    </>
  );
});

Base.displayName = 'Base';
