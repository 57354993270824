import { ClientModulesCreators } from '@hendrx/modules';

import { MainComponent } from './components/MainComponent';

const createClientAIModuleModule: ClientModulesCreators['aiModule'] = () => {
  return {
    MainComponent
  };
};

export { createClientAIModuleModule };
