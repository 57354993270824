import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  propTitle: {
    fontSize: rem('16px'),
    lineHeight: rem('24px'),
    marginBottom: rem('8px')
  },
  propSubTitle: {
    fontWeight: 'normal',
    marginInlineStart: rem('4px')
  },
  asterisk: {
    color: theme.colors.red[8]
  }
}));
