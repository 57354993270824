import { memo } from 'react';
import * as Y from 'yjs';

import { ClozeText } from './ClozeText';
import { TableImplementation } from '@/widgets/Table/base/player';
import { TableProvider, useTable } from '@/widgets/Table/hooks/useTable';

export function ClozeTable() {
  return (
    <TableProvider TableCellRenderer={TableCellRenderer}>
      <TableImplementation />
    </TableProvider>
  );
}

type TableCellRendererProps = {
  contentId: string;
};

const TableCellRenderer = memo(function TableCellRenderer(props: TableCellRendererProps) {
  const { contentId } = props;

  const content = useTable(context => context.content);

  const fragment = content?.get(contentId) as Y.XmlFragment;

  return <ClozeText fragment={fragment} />;
});
