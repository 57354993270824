import { useLearningObjectIds } from './useLearningObjectIds';
import { useLearningObjectSnapshot, useLearningObjectDraft } from '@/queries/learningObject';

export function useLearningObjectSnapshotAndDraftQuery() {
  const { learningObjectDraftId, learningObjectSnapshotId, isLoading } = useLearningObjectIds();

  const learningObjectSnapshotQuery = useLearningObjectSnapshot(learningObjectSnapshotId);
  const learningObjectDraftQuery = useLearningObjectDraft(learningObjectDraftId);

  return {
    learningObjectSnapshot: learningObjectSnapshotQuery.data,
    learningObjectDraft: learningObjectDraftQuery.data,
    isLoading:
      isLoading ||
      (learningObjectSnapshotId && learningObjectSnapshotQuery.isLoading) ||
      (learningObjectDraftId && learningObjectDraftQuery.isLoading),
    isError:
      (learningObjectSnapshotId && learningObjectSnapshotQuery.isError) ||
      (learningObjectDraftId && learningObjectDraftQuery.isError)
  };
}
