import { ActionIcon, Box, Card, Flex, Image, Menu, Overlay, Popover, Text } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import cx from 'clsx';
import { useTranslations } from 'next-intl';
import { RefObject, useCallback } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import * as classes from './AnswerCard.css';
import { useCanEnlargeOpenDiscussionAnswer } from '@/components/CanProvider';
import { ConfirmationPaper } from '@/components/ConfirmationPaper';
import { Edit } from '@/icons/Edit';
import { Enlarge } from '@/icons/Enlarge';
import { MenuHandle } from '@/icons/MenuHandle';
import { Trashcan } from '@/icons/Trashcan';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { ColorSwatches } from '@/widgets/OpenDiscussion/player/ColorSwatches';

type AnswerCardProps = {
  answer: OpenDiscussionAnswer;
  onEdit: (answer: OpenDiscussionAnswer) => void;
  onEditColor?: (newColor: string, answer: OpenDiscussionAnswer) => Promise<void>;
  onDelete: (id: string) => void;
  onClick?: () => void;
  isStudent?: boolean;
  studentScrollTargetRef?: RefObject<HTMLDivElement>;
};

export function AnswerCard(props: AnswerCardProps) {
  const { answer, onEdit, onEditColor, onDelete, onClick, isStudent, studentScrollTargetRef } = props;
  const { id, text, image, cardColor } = answer;

  const [opened, { open, close }] = useDisclosure(false);
  const { hovered, ref: teacherHoverRef } = useHover();
  const t = useTranslations('widgets.openDiscussionPlayer');

  const onEditCardColor = useCallback((newColor: string) => onEditColor?.(newColor, answer), [answer, onEditColor]);

  const canEnlargeOpenDiscussionAnswer = useCanEnlargeOpenDiscussionAnswer();

  const { uiDirection } = useUiLanguage();

  return (
    <Card
      shadow="sm"
      radius="sm"
      pos="relative"
      pe={image ? undefined : 46}
      className={cx(classes.card, isStudent ? classes.studentCard : classes.teacherCard)}
      bg={isStudent ? undefined : cardColor}
      ref={isStudent ? studentScrollTargetRef : teacherHoverRef}
    >
      <Flex>
        <Box>
          {hovered && canEnlargeOpenDiscussionAnswer && (
            <Overlay onClick={onClick} backgroundOpacity={0} center className={classes.enlarge} zIndex={2}>
              <Enlarge className={classes.enlargeIcon} />
            </Overlay>
          )}
          {image && <Image alt="" src={image} radius="sm" mb={text ? 'sm' : undefined} />}
          {text && <Text fz={18}>{text}</Text>}
        </Box>
        <Menu shadow="md" width={130} withinPortal={false}>
          <Menu.Target>
            <ActionIcon
              variant="subtle"
              className={classes.menu}
              size={42}
              aria-label={t('menu')}
              bg={isStudent ? undefined : cardColor}
              color="dark"
            >
              <MenuHandle className={classes.handle} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown dir={uiDirection}>
            <Menu.Item leftSection={<Edit />} onClick={() => onEdit(answer)}>
              {t('edit')}
            </Menu.Item>

            {onEditColor && (
              <>
                <Menu.Divider w="100%" mb={0} />
                <ColorSwatches cardColor={cardColor} editCardColor={onEditCardColor} />
                <Menu.Divider w="100%" mt={0} />
              </>
            )}

            <Popover opened={opened} trapFocus position="right" withArrow shadow="md" onClose={close}>
              <Popover.Target>
                <Menu.Item onClick={open} leftSection={<Trashcan />} closeMenuOnClick={false}>
                  {t('delete')}
                </Menu.Item>
              </Popover.Target>
              <Popover.Dropdown>
                <ConfirmationPaper onAccept={() => onDelete(id)} onReject={close} text={t('delete')} />
              </Popover.Dropdown>
            </Popover>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Card>
  );
}
