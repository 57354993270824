import type {
  ActivityThemeStyles,
  BlockTitleThemeProps,
  BorderRadiusThemeProps,
  LayoutSectionStyles
} from '@hendrx/modules/ui';
import { QuestionNumberingPosition, QuestionNumberingStyle } from '@hendrx/modules/ui';

// TODO: Workaround for vanilla extract plugin bundling bug: https://github.com/vanilla-extract-css/vanilla-extract/issues/1043
function createConverter(units: string) {
  return (px: unknown) => {
    if (typeof px === 'number') {
      return `${px / 16}${units}`;
    }

    if (typeof px === 'string') {
      const replaced = px.replace('px', '');
      if (!Number.isNaN(Number(replaced))) {
        return `${Number(replaced) / 16}${units}`;
      }
    }

    return px as string;
  };
}

export const rem = createConverter('rem');
export const em = createConverter('em');

const systemFont = 'var(--font-openSans)';
const contentFont = 'var(--font-openSans)';

const defaultBorder = {
  borderColor: null,
  borderWidth: 0,
  borderStyle: 'solid'
};

const defaultTextBorders = {
  borderTop: defaultBorder,
  borderRight: defaultBorder,
  borderBottom: defaultBorder,
  borderLeft: defaultBorder
};

const defaultZeroBorderRadius: BorderRadiusThemeProps = {
  topLeft: 0,
  topRight: 0,
  bottomRight: 0,
  bottomLeft: 0,
  measureUnit: '1px'
};

const defaultLayoutSectionStyles: LayoutSectionStyles = {
  backgroundColor: '#ffffff00',
  padding: 16,
  gap: 16,
  alignTo: 'flex-start',
  border: defaultBorder,
  borderRadius: defaultZeroBorderRadius
};

const defaultBlockTitle: Omit<BlockTitleThemeProps, 'fontSize' | 'lineHeight'> = {
  fontFamily: null,
  color: null,
  fontWeight: 'bold',
  backgroundColor: null,
  ...defaultTextBorders,
  borderRadius: {
    topLeft: 0,
    topRight: 20,
    bottomRight: 20,
    bottomLeft: 0,
    measureUnit: '1px'
  },
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 20,
  paddingRight: 0
};

const defaultTypography: ActivityThemeStyles['typography'] = {
  h1: {
    fontFamily: null,
    color: null,
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: '1.15',
    backgroundColor: '#ffffff00',
    ...defaultTextBorders,
    borderRadius: defaultZeroBorderRadius,
    padding: 0
  },
  h2: {
    fontFamily: null,
    color: null,
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: '1.15',
    backgroundColor: '#ffffff00',
    ...defaultTextBorders,
    borderRadius: defaultZeroBorderRadius,
    padding: 0
  },
  h3: {
    fontFamily: null,
    color: null,
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '1.15',
    backgroundColor: '#ffffff00',
    ...defaultTextBorders,
    borderRadius: defaultZeroBorderRadius,
    padding: 0
  },
  h4: {
    fontFamily: null,
    color: null,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '1.5',
    backgroundColor: '#ffffff00',
    ...defaultTextBorders,
    borderRadius: defaultZeroBorderRadius,
    padding: 0
  },
  p: {
    fontFamily: null,
    color: null,
    fontSize: 20,
    fontWeight: 'normal',
    lineHeight: '1.5',
    backgroundColor: '#ffffff00',
    ...defaultTextBorders,
    borderRadius: defaultZeroBorderRadius,
    padding: 0
  }
};

export const defaultActivityThemeStyles: ActivityThemeStyles = {
  global: {
    content: {
      fontFamily: contentFont,
      colors: {
        primary: {
          main: '#ffffff',
          contrast: '#5750fa',
          dark: '#ada1fc',
          light: '#ecebff'
        },
        secondary: {
          main: '#ffffff',
          contrast: '#000000'
        },
        decoration: {
          main: '#a6a7ab'
        }
      }
    },
    system: {
      fontFamily: systemFont,
      colors: {
        primary: {
          main: '#5750fa',
          contrast: '#ffffff',
          dark: '#ada1fc',
          light: '#dddcfe'
        },
        secondary: {
          main: '#ecebff',
          contrast: '#5750fa'
        },
        decoration: {
          main: '#a6a7ab'
        }
      }
    }
  },
  page: {
    backgroundColor: '#f1f3f5',
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    topImageEnabled: false,
    topImageSrc: '',
    topImageMobileSrc: '',
    topImageFit: 'fill',
    topImageOverlap: false,
    topImageSticky: false
  },
  stage: {
    backgroundColor: null,
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    minQuestionInputHeight: rem(96),
    questionInputWrapperPadding: rem(12),
    questionInputWrapperPaddingSum: rem(24),
    border: defaultBorder,
    borderRadius: defaultZeroBorderRadius
  },
  sections: {
    vertical1: defaultLayoutSectionStyles,
    horizontal1: defaultLayoutSectionStyles,
    vertical2: defaultLayoutSectionStyles,
    horizontal2: defaultLayoutSectionStyles,
    vertical3: defaultLayoutSectionStyles,
    horizontal3: defaultLayoutSectionStyles
  },
  typography: defaultTypography,
  blockTitle: {
    h1: { fontSize: 36, lineHeight: '1.15', ...defaultBlockTitle },
    h2: { fontSize: 32, lineHeight: '1.15', ...defaultBlockTitle },
    h3: { fontSize: 28, lineHeight: '1.15', ...defaultBlockTitle },
    h4: { fontSize: 20, lineHeight: '1.5', ...defaultBlockTitle },
    p: { fontSize: 20, lineHeight: '1.5', ...defaultBlockTitle }
  },
  combinedTitle: { gap: 16, verticalAlignment: 'center', imagePosition: 'start' },
  navigationBar: {
    fontFamily: null,
    backgroundColor: '#545460',
    paddingInline: 36,
    paddingBlock: 10,
    shadow: true,
    borderRadius: {
      topLeft: 50,
      topRight: 50,
      bottomRight: 0,
      bottomLeft: 0,
      measureUnit: '1px'
    },
    pageNumber: {
      backgroundColor: '#ffffff00',
      color: null,
      fontWeight: 'bold',
      fontSize: 20,
      size: 30,
      border: defaultBorder,
      borderRadius: {
        topLeft: 99,
        topRight: 99,
        bottomRight: 99,
        bottomLeft: 99,
        measureUnit: '1px'
      }
    },
    pageNumberHovered: {
      color: '#545460',
      backgroundColor: null,
      fontWeight: 'bold',
      border: defaultBorder
    },
    pageNumberSelected: {
      color: '#545460',
      backgroundColor: null,
      fontWeight: 'bold',
      border: defaultBorder
    },
    navigationButton: {
      color: null,
      fontSize: 14,
      fontWeight: 600
    }
  },
  table: {
    tableStyle: 'simple',
    fontFamily: null,
    fontSize: 20,
    color: null,
    cellPrimaryBackgroundColor: '#ffffff',
    cellSecondaryBackgroundColor: '#f8f9fa',
    cellPadding: 8,
    rowSpacing: 0,
    columnSpacing: 0,
    cornerRadius: {
      topLeft: 8,
      topRight: 8,
      bottomRight: 8,
      bottomLeft: 8,
      measureUnit: '1px'
    },
    isBorderVisible: true,
    border: {
      borderColor: null,
      borderWidth: 1,
      borderStyle: 'solid'
    },
    isCaptionVisible: false,
    captionBackgroundColor: '#f8f9fa',
    captionColor: null,
    captionFontSize: 36,
    captionFontFamily: null,
    isHeaderVisible: true,
    headerBackgroundColor: '#e9ecef',
    headerColor: null,
    headerFontSize: 20,
    headerFontFamily: null
  },
  tooltip: {
    backgroundColor: '#ffffff',
    cornerRadius: {
      topLeft: 8,
      topRight: 8,
      bottomRight: 8,
      bottomLeft: 8,
      measureUnit: '1px'
    },
    border: {
      borderColor: null,
      borderWidth: 1,
      borderStyle: 'solid'
    },
    isBorderVisible: true,
    isShadowVisible: true,
    shadow: '0px 0px 8px 0px #00000029',
    padding: 8,
    offset: 8,
    position: 'top'
  },
  media: {
    border: defaultBorder,
    borderRadius: {
      topLeft: 4,
      topRight: 4,
      bottomRight: 4,
      bottomLeft: 4,
      measureUnit: '1px'
    }
  },
  learningItems: {
    openQuestion: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      }
    },
    selectionQuestion: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      },
      checkbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        }
      },
      selectedCheckbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        }
      },
      interactiveButton: {
        standard: {
          color: null
        },
        hovered: {
          color: null
        }
      }
    },
    clozeQuestion: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      },
      inputField: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomRight: 4,
          bottomLeft: 4,
          measureUnit: '1px'
        }
      },
      selectedField: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomRight: 4,
          bottomLeft: 4,
          measureUnit: '1px'
        }
      }
    },
    fillInTheBlanks: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      },
      blank: {
        backgroundColor: null,
        backgroundColorHovered: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 2,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomRight: 4,
          bottomLeft: 4,
          measureUnit: '1px'
        }
      },
      bank: {
        backgroundColor: null,
        color: null,
        border: defaultBorder,
        borderRadius: {
          topLeft: 6,
          topRight: 6,
          bottomRight: 6,
          bottomLeft: 6,
          measureUnit: '1px'
        }
      }
    }
  },
  collabItems: {
    interactivePoll: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      },
      checkbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        }
      },
      selectedCheckbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        }
      },
      interactiveButton: {
        standard: {
          color: null
        },
        hovered: {
          color: null
        }
      }
    },
    openDiscussion: {
      headlineSection: {
        backgroundColor: '#ffffffff',
        padding: 0,
        gap: 16,
        alignTo: 'flex-start',
        border: defaultBorder,
        borderRadius: defaultZeroBorderRadius
      }
    },
    interactiveQuiz: {
      answerArea: {
        backgroundColor: null,
        color: '#000000ff',
        placeholderColor: '#adb5bd',
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        },
        lineHeight: '1.5'
      },
      checkbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        },
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
          measureUnit: '1px'
        }
      },
      selectedCheckbox: {
        backgroundColor: null,
        color: null,
        border: {
          borderColor: null,
          borderWidth: 1,
          borderStyle: 'solid'
        }
      },
      interactiveButton: {
        standard: {
          color: null
        },
        hovered: {
          color: null
        }
      }
    }
  },
  button: {
    primary: {
      standard: {
        color: null,
        backgroundColor: null,
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: null,
        border: defaultBorder,
        borderRadius: {
          topLeft: 25,
          topRight: 25,
          bottomRight: 25,
          bottomLeft: 25,
          measureUnit: '1px'
        }
      },
      hovered: {
        color: null,
        backgroundColor: null,
        fontWeight: 'bold',
        border: defaultBorder
      }
    },
    secondary: {
      standard: {
        color: null,
        backgroundColor: null,
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: null,
        border: defaultBorder,
        borderRadius: {
          topLeft: 99,
          topRight: 99,
          bottomRight: 99,
          bottomLeft: 99,
          measureUnit: '1px'
        }
      },
      hovered: {
        color: null,
        backgroundColor: null,
        fontWeight: 'bold',
        border: defaultBorder
      }
    }
  },

  feedback: {
    fontWeight: 'bold',
    fontSize: 20,
    color: {
      showAnswer: '#ae3ec9',
      wrong: '#e64980',
      correct: '#40c057',
      partiallyCorrect: '#ff922b'
    }
  },
  topbar: {
    backgroundColor: '#343A40',
    color: '#ecebff',

    burger: {
      backgroundColor: '#fdfdfd'
    },
    avatar: {
      backgroundColor: '#8d83fb',
      color: '#ffffff'
    },
    viewModeDropdown: {
      color: '#ffffff'
    },
    actionIcon: {
      standard: {
        color: '#fdfdfd',
        backgroundColor: '#343a40'
      },
      disabled: {
        color: '#adb5bd',
        backgroundColor: '#343a40'
      }
    },
    saveButton: {
      standard: {
        backgroundColor: '#5750FA',
        color: '#FDFDFD'
      },
      hovered: {
        backgroundColor: '#748FFC',
        color: '#FDFDFD'
      },
      disabled: {
        backgroundColor: '#495057',
        color: '#ADB5BD'
      }
    },
    publishButton: {
      standard: {
        backgroundColor: '#343A40',
        color: '#F1F3F5'
      },
      hovered: {
        backgroundColor: '#495057',
        color: '#F1F3F5'
      },
      disabled: {
        backgroundColor: '#343A40',
        color: '#868E96'
      }
    },
    player: {
      backgroundColor: '#343A40',
      color: '#ffffff',

      activeMenu: {
        backgroundColor: '#e9ecef'
      },
      loginButton: {
        standard: {
          color: '#212529',
          backgroundColor: '#ffffff',
          borderRadius: 8,
          borderColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'solid'
        },

        hovered: {
          color: '#212529',
          backgroundColor: '#e9ecef',
          borderRadius: 8,
          borderColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'solid'
        }
      },
      assignTaskButton: {
        standard: {
          color: '#212529',
          backgroundColor: '#e9ecef',
          borderRadius: 8,
          borderColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'solid'
        },
        hovered: {
          color: '#212529',
          backgroundColor: '#e9ecef',
          borderRadius: 8,
          borderColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'solid'
        }
      },
      actionIcon: {
        standard: {
          color: '#ffffff',
          backgroundColor: '#343A40',
          borderRadius: 8,
          borderColor: '#343A40',
          borderWidth: 0,
          borderStyle: 'solid'
        },

        hovered: {
          color: '#e9ecef',
          backgroundColor: '#212529',
          borderRadius: 8,
          borderColor: '#212529',
          borderWidth: 0,
          borderStyle: 'solid'
        }
      }
    }
  },
  questionNumbering: {
    numberingStyle: QuestionNumberingStyle.WithDots,
    numberingPosition: QuestionNumberingPosition.BeforeHeadline,
    ...defaultTypography
  }
};
