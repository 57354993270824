import { Box } from '@mantine/core';
import { ReactNode } from 'react';

import { Select } from '@/components/Select';
import * as classes from '@/components/Theme/Theme.css';
import { ThemeControl } from '@/components/Theme/ThemeControl';

type FontSizeSelectPanelProps = {
  label?: string;
  path?: string;
  value: number;
  onChange: (newValue: number) => void;
  ResetButton?: ReactNode;
};

const fontSizeData = ['10', '12', '14', '16', '18', '20', '24', '28', '32', '36', '40', '48', '64', '96'];

export const FontSizeSelectPanel = (props: FontSizeSelectPanelProps) => {
  const { label, path, value, onChange, ResetButton } = props;

  return (
    <ThemeControl label={label} path={path}>
      <Box className={classes.fontSizeSelect}>
        <Select
          data={fontSizeData}
          value={String(value)}
          onChange={selectedValue => onChange(Number(selectedValue ?? '16'))}
        />
      </Box>
      {ResetButton}
    </ThemeControl>
  );
};
