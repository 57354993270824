export enum Feedbacks {
  Automatic = 'automatic',
  Check = 'check',
  None = 'none'
}

export enum FeedbackMessages {
  NoAnswer = 'noAnswer',
  OnlyWrongAnswers = 'onlyWrongAnswers',
  NotAllCorrectAndNoWrongAnswers = 'notAllCorrectAndNoWrongAnswers',
  SomeCorrectAndSomeWrongAnswers = 'someCorrectAndSomeWrongAnswers',
  OnlyAllCorrectAnswers = 'onlyAllCorrectAnswers',
  ShowAnswer = 'showAnswer'
}

export type FeedbackMessageType = `${FeedbackMessages}`;

export const getFeedbackMessageType = ({
  allPossibleRightAnswersCount,
  rightAnswersCount,
  wrongAnswersCount,
  feedback
}: {
  allPossibleRightAnswersCount: number;
  rightAnswersCount: number;
  wrongAnswersCount: number;
  feedback: `${Feedbacks}` | null;
}): FeedbackMessageType | null => {
  // right
  const noCorrectAnswer = rightAnswersCount === 0;
  const notAllCorrectAnswers = rightAnswersCount > 0 && rightAnswersCount < allPossibleRightAnswersCount;
  const allCorrectAnswers = rightAnswersCount === allPossibleRightAnswersCount;
  const someCorrectAnswers = notAllCorrectAnswers || allCorrectAnswers;
  // wrong
  const noWrongAnswer = wrongAnswersCount === 0;
  const someWrongAnswers = wrongAnswersCount > 0;

  if (noCorrectAnswer && noWrongAnswer && feedback !== Feedbacks.Automatic) {
    return FeedbackMessages.NoAnswer;
  } else if (noCorrectAnswer && someWrongAnswers) {
    return FeedbackMessages.OnlyWrongAnswers;
  } else if (notAllCorrectAnswers && noWrongAnswer) {
    return FeedbackMessages.NotAllCorrectAndNoWrongAnswers;
  } else if (someCorrectAnswers && someWrongAnswers) {
    return FeedbackMessages.SomeCorrectAndSomeWrongAnswers;
  } else if (allCorrectAnswers && noWrongAnswer) {
    return FeedbackMessages.OnlyAllCorrectAnswers;
  }

  return null;
};
