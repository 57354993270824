import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { Direction } from '@/utils/locale.utils';

type Props = {
  isConstraint: boolean;
  isResizing: boolean;
  direction: Direction;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { isConstraint, isResizing, direction } = props;

  const hoverStyle = {
    outlineColor: isConstraint ? theme.colors.red[5] : theme.colors.blue[5],
    '[data-child=resize-dot]': {
      opacity: 1
    }
  };

  const isRtl = direction === 'rtl';

  return {
    resizeDotCommon: {
      position: 'absolute',
      width: rem(16),
      height: rem(16),
      backgroundColor: theme.colors.blue[5],
      pointerEvents: 'all',
      opacity: 0,
      zIndex: 10
    },
    dotDownEnd: {
      bottom: 0,
      insetInlineEnd: 0,
      cursor: isRtl ? 'nesw-resize' : 'nwse-resize',
      transform: `translate(${isRtl ? '-50%' : '50%'}, 50%)`
    },
    dotEnd: {
      bottom: `calc(50% - ${rem(10)})`,
      insetInlineEnd: 0,
      cursor: 'ew-resize',
      transform: `translate(${isRtl ? '-50%' : '50%'}, 0)`
    },
    dotUpEnd: {
      top: 0,
      insetInlineEnd: 0,
      cursor: isRtl ? 'nwse-resize' : 'nesw-resize',
      transform: `translate(${isRtl ? '-50%' : '50%'}, -50%)`
    },
    dotDownStart: {
      bottom: 0,
      insetInlineStart: 0,
      cursor: isRtl ? 'nwse-resize' : 'nesw-resize',
      transform: `translate(${isRtl ? '50%' : '-50%'}, 50%)`
    },
    dotStart: {
      bottom: `calc(50% - ${rem(10)})`,
      insetInlineStart: 0,
      cursor: 'ew-resize',
      transform: `translate(${isRtl ? '50%' : '-50%'}, 0)`
    },
    dotUpStart: {
      top: 0,
      insetInlineStart: 0,
      cursor: isRtl ? 'nesw-resize' : 'nwse-resize',
      transform: `translate(${isRtl ? '50%' : '-50%'}, -50%)`
    },
    dotDownMiddle: {
      bottom: 0,
      insetInlineStart: `calc(50% - ${rem(10)})`,
      cursor: 'ns-resize',
      transform: `translate(0, 50%)`
    },
    resizeContainer: {
      zIndex: isResizing ? 1000 : 'auto',
      position: 'relative',
      inset: 0,
      outline: `transparent 2px solid`,
      '&[data-selected=true]': hoverStyle,
      '&> :first-of-type': {
        height: '100%',
        width: '100%'
      }
    },
    childrenWrapper: {
      width: '100%',
      height: '100%',
      '&> :first-of-type': {
        height: '100%',
        width: '100%'
      }
    }
  };
});
