import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSummaryPageTaskSubmittedNotification } from 'shared/widgetsSDK/SummaryPageTaskSubmittedNotification/create';

import { manifestLocales } from './manifest.locales';
import SummaryPageTaskSubmittedNotificationEn from './resources/en/summaryPageTaskSubmittedNotification.svg';
import SummaryPageTaskSubmittedNotificationHe from './resources/he/summaryPageTaskSubmittedNotification.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SummaryPageTaskSubmittedNotification,
  previewGroups: [
    {
      id: 'SummaryPageTaskSubmittedNotification',
      divider: false,
      previews: [
        {
          name: 'SummaryPageTaskSubmittedNotification',
          previewImage: {
            paths: {
              en: SummaryPageTaskSubmittedNotificationEn,
              he: SummaryPageTaskSubmittedNotificationHe
            },
            alt: { ...manifestLocales.summaryPageTaskSubmittedNotification.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: () => createNewSummaryPageTaskSubmittedNotification(),
          dimensions: {
            width: 1180,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 1180
    }
  }
};
