import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  title: {
    textAlign: 'center',
    height: 'auto'
  },
  titleInner: {
    color: theme.colors.accent[6]
  }
}));
