import { ActionIcon } from '@mantine/core';
import { ReactElement } from 'react';

import { useStyles } from './BaseAction.styles';

type Props = {
  onClick: () => void;
  icon: ReactElement;
  dataId: string;
};

export function BaseAction(props: Props) {
  const { onClick, icon, dataId } = props;
  const { classes } = useStyles();

  return (
    <ActionIcon data-testid={dataId} variant="transparent" onClick={onClick} className={classes.icon}>
      {icon}
    </ActionIcon>
  );
}
