import { Box, Switch } from '@mantine/core';
import { ReactNode } from 'react';

import * as classes from '@/components/Theme/Theme.css';
import { ThemeControl } from '@/components/Theme/ThemeControl';

type SwitchPanelProps = {
  label?: string;
  path?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  ResetButton?: ReactNode;
};

export const SwitchPanel = (props: SwitchPanelProps) => {
  const { label, path, value, onChange, ResetButton } = props;

  return (
    <ThemeControl path={path} label={label}>
      <Box className={classes.shadow}>
        <Switch checked={value} onChange={event => onChange(event.currentTarget.checked)} />
      </Box>
      {ResetButton}
    </ThemeControl>
  );
};
