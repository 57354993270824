import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import * as Y from 'yjs';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { VerticalAlignment } from '@/widgets/CombinedTitle/types';
import { useFlexSectionStyle } from '@/widgets/FlexSection/hooks/useFlexSectionStyle';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const openDiscussionPathStart = 'collabItems.openDiscussion.headlineSection';

export const useOpenDiscussionStyleProperties = (id: string, document: Y.Doc) => {
  const [alignTo, setAlignTo] = useFlexSectionStyle<string | null>(document, id, 'alignTo');
  const [backgroundColor, setBackgroundColor] = useFlexSectionStyle<string | null>(document, id, 'backgroundColor');
  const [padding, setPadding] = useFlexSectionStyle<number | null>(document, id, 'padding');
  const [gap, setGap] = useFlexSectionStyle<number | null>(document, id, 'gap');
  const [direction, setDirection] = useContentObjectProperty<FlexSectionDirection | undefined>('direction');
  const [border, setBorder] = useFlexSectionStyle<BorderThemeProps | null>(document, id, 'border');
  const [borderRadius, setBorderRadius] = useFlexSectionStyle<Partial<BorderRadiusThemeProps> | null>(
    document,
    id,
    'borderRadius'
  );

  const [themeBackgroundColor] = useActivityThemeStylesProperty<string>(
    document,
    `${openDiscussionPathStart}.backgroundColor`
  );
  const [themeBorder] = useActivityThemeStylesProperty<Omit<BorderThemeProps, 'borderColor'> & { borderColor: string }>(
    document,
    `${openDiscussionPathStart}.border`,
    undefined,
    'global.content.colors.decoration.main'
  );
  const [themeBorderRadius] = useActivityThemeStylesProperty<BorderRadiusThemeProps>(
    document,
    `${openDiscussionPathStart}.borderRadius`
  );
  const [themeAlignTo] = useActivityThemeStylesProperty<VerticalAlignment>(
    document,
    `${openDiscussionPathStart}.alignTo`
  );
  const [themePadding] = useActivityThemeStylesProperty<number>(document, `${openDiscussionPathStart}.padding`);
  const [themeGap] = useActivityThemeStylesProperty<number>(document, `${openDiscussionPathStart}.gap`);

  return {
    direction: direction ?? FlexSectionDirection.row,
    setDirection,
    backgroundColor,
    setBackgroundColor,
    border,
    setBorder,
    borderRadius,
    setBorderRadius,
    alignTo,
    setAlignTo,
    padding,
    setPadding,
    gap,
    setGap,
    themeBackgroundColor,
    themeBorder,
    themeBorderRadius,
    themeAlignTo,
    themePadding,
    themeGap
  };
};
