import { MantineSize, Textarea } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useMemo, useRef } from 'react';

import { useStyles } from './ClozeOption.styles';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useDynamicInputWidth } from '@/utils/useDynamicInputWidth';

type ClozeOptionInputProps = {
  className: string;
  disabled?: boolean;
  fontSize?: MantineSize;
  fontFamily?: string;
  onInputChange: (value: string) => void;
  value: string;
};

export function ClozeOptionInput(props: ClozeOptionInputProps) {
  const { value, onInputChange, className, disabled, fontSize = 'lg', fontFamily } = props;

  const t = useTranslations('widgets');

  const placeholder = t('clozeQuestionWidget.inputPlaceholder');

  const ref = useRef<HTMLTextAreaElement>(null);

  const words = useMemo(() => [value], [value]);
  const width = useDynamicInputWidth(words, 30, ref);

  const placeholderWords = useMemo(() => [placeholder], [placeholder]);
  const placeholderWidth = useDynamicInputWidth(placeholderWords, 60, ref);

  const finalWidth = value ? width : placeholderWidth;

  const { contentDirection } = useContentLanguage();

  const { classes } = useStyles({ width: finalWidth, fontSize, fontFamily, direction: contentDirection });

  return (
    <Textarea
      ref={ref}
      data-testid="cloze-question-preview-answer-input"
      placeholder={placeholder}
      value={value}
      autosize
      minRows={1}
      onChange={event => onInputChange(event.target.value)}
      classNames={{ input: classes.input, wrapper: className }}
      disabled={disabled}
    />
  );
}
