import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    popoverContainer: {
      overflowY: 'auto'
    },
    uploadContainer: {
      marginInlineEnd: rem(8),
      width: rem(300),
      height: rem(210),
      wordBreak: 'break-all'
    },
    textInput: {
      width: rem(100)
    },
    footerButtons: {
      paddingBottom: rem(2)
    },
    submitButton: {
      marginInlineStart: rem(8)
    }
  };
});
