import { useEffect } from 'react';

import { TaskSubmittedNotification } from '@/components/TaskSubmittedNotification';
import { Manifest } from '@/widgets/SummaryPageTaskSubmittedNotification/Manifest';
import { StudioProps } from '@/widgets/types/studioProps';

export function SummaryPageTaskSubmittedNotification(props: StudioProps) {
  const { initialize } = props;

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return <TaskSubmittedNotification />;
}
