import { useCallback } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedText } from 'shared/widgetsSDK/yjs';

export function useActivityThemeCSS(document: Y.Doc) {
  const rootMap = getRootMap(document);
  const [activityThemeCSS, observedActivityThemeCSS] = useObservedText(rootMap, 'customCSS');

  const applyThemeCSS = useCallback(
    (css: string) => {
      observedActivityThemeCSS.delete(0, observedActivityThemeCSS.length);
      observedActivityThemeCSS.insert(0, css);
    },
    [observedActivityThemeCSS]
  );

  return [{ activityThemeCSS, applyThemeCSS }, observedActivityThemeCSS] as const;
}
