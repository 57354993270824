import { useRouter } from 'next/router';
import * as Y from 'yjs';

import { usePages } from './usePages';
import { HIDDEN_PAGE_PREFIX } from '@/utils/pages.utils';
import { parseQueryParam } from '@/utils/query.utils';
import { useCurrentPageIndex } from '@/utils/useCurrentPageIndex';
import { useWorkMode } from '@/utils/useWorkMode';

export function useCurrentPageId(document: Y.Doc) {
  const router = useRouter();
  const pageIndex = useCurrentPageIndex();
  const { pagesData, visiblePages, hiddenPages } = usePages(document);
  const { isStudioEdit } = useWorkMode();

  if (isStudioEdit) {
    const pageExists = pagesData.some(({ id }) => id === router.query.pageId);
    if (!pageExists) {
      return null;
    }
    return parseQueryParam(router.query.pageId) ?? pagesData[0]?.id ?? null;
  }
  const page = pageIndex.startsWith(HIDDEN_PAGE_PREFIX)
    ? hiddenPages[Number(pageIndex.split(HIDDEN_PAGE_PREFIX)[1]) - 1]
    : visiblePages[Number(pageIndex) - 1];

  return page?.id ?? null;
}
