import { Box, Stack, Tabs } from '@mantine/core';
import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { sectionStyles } from './Config.css';
import { ConfigSplitBorder } from './ConfigSplitBorder';
import { Accordion } from '@/components/Accordion';
import { BorderRadiusPanel } from '@/components/Theme/BorderRadiusPanel';
import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { NumberInputPanel } from '@/components/Theme/NumberInputPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import * as themeClasses from '@/components/Theme/Theme.css';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';
import { typographyIds, TypographyIdType, useTextWidgetStyles } from '@/utils/typographyStyles.utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  const { id } = useContentObject();
  const textWidgetStyles = useTextWidgetStyles(id);

  const setWidgetStyleByKey = (typographyId: TypographyIdType, key: string, value: unknown) => {
    const { style, setStyle } = textWidgetStyles[typographyId];
    setStyle({ ...style, [key]: value });
  };

  const document = useCurrentDocument();
  const [activityThemeStyles] = useActivityThemeStyles(document);
  const { typography } = activityThemeStyles;

  const t = useTranslations('components.theme.settings');

  const [accordionTabOpen, setAccordionTabOpen] = useState<string | null>(null);

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <Box className={themeClasses.withMarginBottom}>
          <Accordion secondary value={accordionTabOpen} onChange={tab => setAccordionTabOpen(tab)}>
            {typographyIds.map(typographyId => {
              const { style: widgetStyle, setStyle: setWidgetStyle } = textWidgetStyles[typographyId];
              return (
                <Accordion.Item key={typographyId} value={typographyId}>
                  <Accordion.Control>{t(typographyId)}</Accordion.Control>
                  {typographyId === accordionTabOpen && (
                    <Accordion.Panel>
                      <Stack className={themeClasses.contentStack}>
                        <ThemeItemSection label={t('backgroundColor')} sectionClassName={sectionStyles}>
                          <ColorInputPanel
                            color={widgetStyle?.backgroundColor ?? typography[typographyId].backgroundColor}
                            setColor={newColor => setWidgetStyleByKey(typographyId, 'backgroundColor', newColor)}
                            ResetButton={
                              widgetStyle?.backgroundColor && (
                                <ResetButton
                                  onClick={() => setWidgetStyleByKey(typographyId, 'backgroundColor', null)}
                                />
                              )
                            }
                          />
                        </ThemeItemSection>
                        <ThemeItemSection label={t('border')} sectionClassName={sectionStyles}>
                          <ConfigSplitBorder
                            activityThemeStyles={activityThemeStyles}
                            typographyId={typographyId}
                            widgetTypographyStyle={widgetStyle}
                            setWidgetTypographyStyle={setWidgetStyle}
                          />
                        </ThemeItemSection>
                        <ThemeItemSection sectionClassName={sectionStyles}>
                          <BorderRadiusPanel
                            label={t('borderRadius')}
                            borderRadius={widgetStyle?.borderRadius ?? typography[typographyId].borderRadius}
                            setBorderRadius={newRadius => setWidgetStyleByKey(typographyId, 'borderRadius', newRadius)}
                            ResetButton={
                              widgetStyle?.borderRadius && (
                                <ResetButton onClick={() => setWidgetStyleByKey(typographyId, 'borderRadius', null)} />
                              )
                            }
                          />
                        </ThemeItemSection>
                        <ThemeItemSection label={t('layout')} sectionClassName={sectionStyles}>
                          <NumberInputPanel
                            label={t('padding')}
                            value={widgetStyle?.padding ?? typography[typographyId].padding}
                            onChange={newPadding => setWidgetStyleByKey(typographyId, 'padding', newPadding)}
                            ResetButton={
                              !isNil(widgetStyle?.padding) && (
                                <ResetButton onClick={() => setWidgetStyleByKey(typographyId, 'padding', null)} />
                              )
                            }
                          />
                        </ThemeItemSection>
                      </Stack>
                    </Accordion.Panel>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Box>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
