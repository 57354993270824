import { SelectProps } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Select } from '@/components/Select';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { LineHeight } from '@/icons/LineHeight';
import { useTextStyles } from '@/utils/tiptap/useTextStyles';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

export type LineHeightSelectProps = {
  editor: Editor | null;
};

const textLineHeights: { value: string; label: string }[] = [
  { value: '1', label: '1' },
  { value: '1.15', label: '1.15' },
  { value: '1.3', label: '1.3' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' }
];

export function LineHeightSelect(props: LineHeightSelectProps) {
  const { editor } = props;
  const { classes, cx } = useStyles({ maxWidth: 65 });
  const t = useTranslations('widgets.lineHeightSelect');
  const { getTextStyles } = useTextStyles(editor);
  const [activeLineHeight, setActiveLineHeight] = useState(getTextStyles('lineHeight'));

  useEffect(() => {
    const setLineHeight = () => {
      const activeLineHeight = getTextStyles('lineHeight');
      setActiveLineHeight(`${activeLineHeight}`);
    };

    editor?.on('selectionUpdate', setLineHeight);
    editor?.on('update', setLineHeight);

    return () => {
      editor?.off('selectionUpdate', setLineHeight);
      editor?.off('update', setLineHeight);
    };
  }, [editor, getTextStyles]);

  const handleLineHeightChange = (lineHeight: string) => {
    editor?.commands.setLineHeight(lineHeight);
  };

  return (
    <Select
      title={t('title') ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: cx(classes.selectInput, classes.selectInputHidden),
        section: classes.selectIcon,
        label: cx(classes.selectLabel, classes.selectLabelEnd)
      }}
      label={<LineHeight />}
      data={textLineHeights}
      withCheckIcon={false}
      value={activeLineHeight}
      onChange={handleLineHeightChange as SelectProps['onChange']}
      variant="unstyled"
      leftSection={<DropdownArrow />}
      leftSectionWidth={16}
      rightSection={<></>}
      rightSectionWidth={'0px'}
    />
  );
}
