import { Helpers } from './Helpers';
import { ScoreWeightSettings } from './ScoreWeightSettings';
import { ShowGradeSettings } from './ShowGradeSettings';
import { HelpersTypes } from '@/widgets/types/helperType';

type EvaluationSettingsProps = {
  withShowGrade?: boolean;
  helpersDisabled?: HelpersTypes[];
  withRelativeScoreWeight?: boolean;
};

export function EvaluationSettings(props: EvaluationSettingsProps) {
  const { helpersDisabled, withShowGrade, withRelativeScoreWeight } = props;

  return (
    <>
      <ScoreWeightSettings withRelativeScoreWeight={withRelativeScoreWeight} />
      {withShowGrade && <ShowGradeSettings />}
      <Helpers disabled={helpersDisabled} />
    </>
  );
}
