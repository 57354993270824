import { Flex, Radio } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { Feedbacks } from 'shared/types/Feedbacks';

import { GroupCheckButtonSettings } from './GroupCheckButtonSettings';
import { getProperFeeback } from '@/utils/questions.utils';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

type FeedbackProps = {
  withGroupCheckButtonSettings?: boolean;
};

export function Feedback(props: FeedbackProps) {
  const { withGroupCheckButtonSettings } = props;

  const [ownFeedback, setOwnFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const { feedback: parentFeedback } = useParentQuestionGroupSettings() ?? {};

  const feedback = getProperFeeback(ownFeedback, parentFeedback);
  const disabled = parentFeedback === Feedbacks.Automatic || parentFeedback === Feedbacks.Check;

  const showGroupCheckButtonSettings = withGroupCheckButtonSettings && ownFeedback === Feedbacks.Check;

  const t = useTranslations('widgets');

  return (
    <FormControl title={t('feedback.title')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Radio.Group value={feedback} onChange={value => setOwnFeedback(value as `${Feedbacks}`)}>
          <Flex direction="column" gap={12}>
            <Radio
              data-testid={`widget-config-feedback-${Feedbacks.Automatic}-radio`}
              disabled={disabled}
              label={t(`feedback.feedbackTypes.${Feedbacks.Automatic}`)}
              value={Feedbacks.Automatic}
            />
            <Radio
              disabled={disabled}
              value={Feedbacks.Check}
              data-testid={`widget-config-feedback-${Feedbacks.Check}-radio`}
              label={
                <>
                  {t(`feedback.feedbackTypes.${Feedbacks.Check}`)}
                  {showGroupCheckButtonSettings && <GroupCheckButtonSettings />}
                </>
              }
            />
            <Radio
              data-testid={`widget-config-feedback-${Feedbacks.None}-radio`}
              disabled={disabled}
              label={t(`feedback.feedbackTypes.${Feedbacks.None}`)}
              value={Feedbacks.None}
            />
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
}
