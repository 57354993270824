import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import * as Y from 'yjs';

import { TextWidgetStyles } from '@/utils/typographyStyles.utils';
import { RichText } from '@/widgets/_components/RichText';

export type BaseProps = {
  fragment?: Y.XmlFragment;
  dataTestIdPrefix?: string;
  disableTypographyInheritance?: boolean;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
  widgetStyles?: TextWidgetStyles;
};

export function Base(props: BaseProps) {
  return <RichText {...props} contentEditable={false} />;
}
