import { Box } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';
import { getSensitiveDataMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { FeedbackMessages } from '@/utils/feedback.utils';
import {
  getProperFeeback,
  getQuestionBulletWrapperData,
  shouldShowCheckButton,
  shouldShowResetButton,
  shouldShowScoreWeight
} from '@/utils/questions.utils';
import { useWorkMode } from '@/utils/useWorkMode';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import {
  useParentQuestionGroupAction,
  useParentQuestionGroupEvaluation,
  useParentQuestionGroupSettings
} from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectArray,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';
import {
  EvaluatableQuestionAnswersContext,
  useEvaluatableQuestionAnswers
} from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';
import { useOrderedAnswers } from '@/widgets/hooks/useOrderedAnswers';

const defaultState: string[] = [];

export function SelectionQuestion() {
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();
  const [ownFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const [scoreWeight] = useContentObjectScoreWeight();

  const { document, id } = useContentObject();
  const isRequired = isQuestionRequired(id, document);
  const questionGroupSettings = useParentQuestionGroupSettings();
  const questionGroupEvaluation = useParentQuestionGroupEvaluation();
  const questionGroupAction = useParentQuestionGroupAction();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(ownFeedback, questionGroupSettings);
  const showResetButton = shouldShowResetButton(questionGroupSettings);
  const feedback = getProperFeeback(ownFeedback, questionGroupSettings?.feedback);

  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const allAnswerOptions = useMemo(() => answers.map(answer => answer.get('id') as string), [answers]);

  const getCorrectAnswers = useCallback(
    (result?: { correctAnswers: string[] }): string[] =>
      result?.correctAnswers?.map(correctAnswerId => allAnswerOptions.indexOf(correctAnswerId).toString()) ?? [],
    [allAnswerOptions]
  );

  const { resetOrderedAnswers } = useOrderedAnswers();

  const onReset = useCallback(() => {
    resetOrderedAnswers();
  }, [resetOrderedAnswers]);

  const getCorrectStatuses = useCallback((correctAnswersOptions: ReturnType<typeof getCorrectAnswers>) => {
    return (
      correctAnswersOptions?.reduce(
        (statuses, answer) => ({
          [answer]: 'correct',
          ...statuses
        }),
        {}
      ) ?? {}
    );
  }, []);

  const [flexSectionId] = useContentObjectProperty<string>('children.0');

  const { choiceQuestionsEnabled } = questionGroupSettings ?? {};
  const evaluatableQuestionAnswers = useEvaluatableQuestionAnswers<WidgetType.SelectionQuestion>({
    feedback,
    getCorrectAnswers,
    onReset,
    defaultState,
    getCorrectStatuses,
    questionGroupAction,
    questionGroupEvaluation,
    isRequired: isRequired || !choiceQuestionsEnabled
  });
  const { feedbackMessageType, grade, handleEvaluate, handleResetAnswers, handleShowAnswer, selectedAnswers } =
    evaluatableQuestionAnswers;

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);
  const sideSpaceWrapperClass = withQuestionListBullet
    ? questionClasses.sideSpaceWrapper.withQuestionListBullet
    : questionClasses.sideSpaceWrapper.base;

  const sensitiveDataMap = getSensitiveDataMap(document);
  const [sensitiveData] = useObservedProperty<Y.Map<unknown>>(sensitiveDataMap, id);

  const { isStudioPreview } = useWorkMode();
  const previewAnswerCount = isStudioPreview ? (sensitiveData.get('correctAnswers') as string[]).length : undefined;

  // only to react to changes in studio
  const showPreviewNoAnswerFeedback =
    ownFeedback === Feedbacks.None && previewAnswerCount === 0 && !selectedAnswers.length;

  return (
    <div className={customClassNames}>
      {labelFragment && (
        <>
          <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
            <Box className={ThemeClassNames.widgets.selectionQuestion.label}>
              <QuestionTitle
                questionId={id}
                fragment={labelFragment}
                dataTestIdPrefix="preview-selection-question-title"
                editable={false}
              />
            </Box>
          </ListBulletWrapper>
          <RequiredQuestionBadge isRequired={isRequired} />
        </>
      )}
      <Box className={sideSpaceWrapperClass}>
        <EvaluatableQuestionAnswersContext.Provider value={evaluatableQuestionAnswers}>
          <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
            <FlexSectionPlayer />
          </ContentObjectProvider>
        </EvaluatableQuestionAnswersContext.Provider>
        <QuestionFooter
          questionId={id}
          feedbackMessageType={showPreviewNoAnswerFeedback ? FeedbackMessages.NoAnswer : feedbackMessageType}
          hintFragment={hintFragment}
          isHintEditable={false}
          onCheck={() => handleEvaluate(selectedAnswers)}
          onReset={handleResetAnswers}
          onShowAnswer={handleShowAnswer}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton && !feedbackMessageType}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton && !feedbackMessageType}
          showResetButton={showResetButton}
          grade={grade || questionGroupEvaluation?.[id]?.grade}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
