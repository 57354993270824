import { Box } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { Dispatch, Fragment, SetStateAction } from 'react';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';

import { useStyles } from './DndSection.styles';
import { DndItem } from '@/components/Section/DndItem';
import { useCurrentDocument } from '@/components/YjsProvider';
import { useGetNestLevel } from '@/contentObjects/Root/hooks/useGetNestLevel';
import { useDragInfo } from '@/utils/useDragInfo';
import { DndItemIndicator } from '@/widgets/FlexSection/DndItemIndicator';
import { useFlexSectionStyle } from '@/widgets/FlexSection/hooks/useFlexSectionStyle';
import { useOnDropAction } from '@/widgets/FlexSection/hooks/useOnDropAction';
import { useContentObjectArray, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type DndSectionProps = {
  id: string;
  alignItems?: string;
  direction?: FlexSectionDirection;
  gap?: number;
  itemsOrder?: Record<string, number>;
  questionIds?: string[];
  resizingItemId?: string;
  setResizingItemId?: Dispatch<SetStateAction<string | undefined>>;
};

export function DndSection(props: DndSectionProps) {
  const {
    alignItems: propsAlignItems,
    direction: propsDirection,
    gap: propsGap,
    id,
    itemsOrder,
    questionIds,
    resizingItemId,
    setResizingItemId
  } = props;

  const document = useCurrentDocument();
  const nestLevel = useGetNestLevel(document, id, WidgetType.FlexSection);

  const [children] = useContentObjectArray<string>('children');
  const [sectionDirection] = useContentObjectProperty<FlexSectionDirection>('direction');
  const [sectionGap] = useFlexSectionStyle<number>(document, id, 'gap');
  const [alignTo] = useFlexSectionStyle<string>(document, id, 'alignTo');

  const { ref: sectionRef, width: sectionWidth } = useElementSize();

  const direction = propsDirection ?? sectionDirection;

  const { isDragging, draggedItemHeight } = useDragInfo();
  const { drop, indicatorPosition } = useOnDropAction(sectionRef, sectionWidth, direction);
  const alignItems = direction === FlexSectionDirection.column ? alignTo : undefined;
  const justifyContent = direction === FlexSectionDirection.row ? alignTo : undefined;

  const gap = propsGap ?? sectionGap;

  const { classes } = useStyles({
    alignTo,
    direction: direction,
    gap,
    isEmpty: children.length === 0,
    nestLevel,
    justifyContent,
    alignItems: propsAlignItems ?? alignItems
  });

  const childrenToShow = questionIds ?? children;

  if (!childrenToShow) return null;

  return (
    <>
      <Box
        ref={drop(sectionRef) as unknown as React.RefObject<HTMLDivElement>}
        className={classes.dndSection}
        data-testid={`stage-section-${id}`}
      >
        {childrenToShow.map((itemId, index) => (
          <Fragment key={itemId}>
            {isDragging && indicatorPosition === index && (
              <DndItemIndicator height={draggedItemHeight} isHorizontal={direction === FlexSectionDirection.column} />
            )}
            <DndItem
              isFlexItem
              flexOrder={itemsOrder?.[itemId]}
              flexDirection={direction}
              containerWidth={sectionWidth}
              key={`${itemId || index}`}
              id={itemId}
              parentId={id}
              sectionRef={sectionRef}
              setResizingItemId={setResizingItemId}
              resizingItemId={resizingItemId}
            />
          </Fragment>
        ))}
        {isDragging && indicatorPosition === children.length && (
          <DndItemIndicator
            height={draggedItemHeight}
            isHorizontal={direction === FlexSectionDirection.column || !children.length}
          />
        )}
      </Box>
    </>
  );
}
