import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  requiredBadge: {
    backgroundColor: theme.colors.accent[1],
    borderRadius: rem(3),
    color: theme.colors.accent[6],
    fontSize: 12,
    fontWeight: 700,
    height: rem(28),
    marginBottom: rem(12),
    padding: `${rem(6)} ${rem(8)}`,
    textAlign: 'center',
    width: rem(71)
  }
}));
