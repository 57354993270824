import { Box } from '@mantine/core';
import { useDrop } from 'react-dnd';

import { useStyles } from './DndIndicator.styles';
import { DndCollect, DndItem } from '@/widgets/_components/dnd/dnd';

type Props = {
  targetIndex: number;
  onDrop: (startIndex: number, targetIndex: number) => void;
  contextId: string;
  amountOfColumns: number;
};

export function DndIndicator(props: Props) {
  const { targetIndex, onDrop, contextId, amountOfColumns } = props;

  const [{ isOver, canDrop, isDragging }, drop] = useDrop<DndItem, void, DndCollect>(
    () => ({
      accept: contextId,
      drop: item => {
        onDrop(item.index, targetIndex);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: monitor.getItem() !== null
      })
    }),
    [onDrop]
  );

  const { classes } = useStyles({ isDragging, isOver, canDrop, amountOfColumns });

  return (
    <Box className={classes.dndGap}>
      <Box className={classes.dndIndicator} />
      <Box className={classes.dndIndicatorDropzone} ref={drop} />
    </Box>
  );
}
