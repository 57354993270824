import { QuestionListBulletTypes } from 'shared/types/QuestionGroupList';
import { RelativeScoreMode } from 'shared/types/QuestionScoreMode';

import { useQuestionNumberingSetting } from '@/contentObjects/Root/hooks/useQuestionNumberingSetting';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const useQuestionListBulletType = () => {
  const [withAutomaticQuestionNumbering] = useQuestionNumberingSetting();
  const [relativeScoreMode] = useContentObjectProperty<RelativeScoreMode>('relativeScoreMode');
  const [questionListBulletType] = useContentObjectProperty<QuestionListBulletTypes>('questionListBulletType');

  if (withAutomaticQuestionNumbering && relativeScoreMode === RelativeScoreMode.RelativeToActivity) {
    return QuestionListBulletTypes.None;
  }

  return questionListBulletType;
};
