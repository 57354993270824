import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  const globalThemeContentColors = theme.other.global.content.colors;
  const openQuestionaAnswerAreaTheme = theme.other.learningItems.openQuestion.answerArea;
  const borderStyle = getThemeBorderStyle(
    openQuestionaAnswerAreaTheme.border,
    globalThemeContentColors.decoration.main,
    { borderWidth: 2 }
  );

  return {
    answerThumbnailContainer: {
      width: rem(128),
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column'
    },
    answerThumbnail: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: rem(5),
      width: rem(112),
      height: rem(88),
      position: 'relative',
      ...borderStyle,
      borderRadius: rem(2),
      '&:hover': {
        cursor: 'pointer'
      }
    },
    fileName: {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      maxWidth: rem(97)
    },
    deleteIcon: {
      position: 'absolute',
      bottom: rem(5),
      right: rem(5),
      borderRadius: '50%',
      color: theme.white,
      backgroundColor: theme.colors.gray[7]
    },
    modalAnswerPreview: {
      position: 'relative',
      height: '75vh'
    },
    thumbnailImage: {
      objectFit: 'contain'
    },
    extensionIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: rem(5),
      width: rem(112),
      height: rem(88),
      ...borderStyle
    }
  };
});
