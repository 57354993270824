import { Header } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { TRow } from '@/widgets/Table/utils';

type TableColumnResizerProps = {
  getResizeHandler: Header<TRow, unknown>['getResizeHandler'];
};

export const TableColumnResizer = memo(function TableColumnResizer(props: TableColumnResizerProps) {
  const { getResizeHandler } = props;

  const { classes } = useStyles();

  return <div onMouseDown={getResizeHandler()} onTouchStart={getResizeHandler()} className={classes.columnResizer} />;
});
