import { Box } from '@mantine/core';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';

import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { BlockTitle } from '@/widgets/BlockTitle/player';
import { Player as FlexSection } from '@/widgets/FlexSection/player';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

export function EnclosureWithBlockTitle() {
  const { id, document } = useContentObject();
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');

  const [direction] = useWidgetStyle<FlexSectionDirection>(document, id, 'dropzoneDirection');
  const [gap] = useWidgetStyle<number>(document, id, 'dropzoneGap');
  const [marginEnd] = useWidgetStyle<number>(document, id, 'dropzoneMarginEnd');
  const [marginStart] = useWidgetStyle<number>(document, id, 'dropzoneMarginStart');

  return (
    <div className={customClassNames}>
      <BlockTitle />
      <ContentObjectProvider id={verticalSectionId} document={document} type={WidgetType.FlexSection}>
        <Box sx={{ marginInlineStart: marginStart, marginInlineEnd: marginEnd }}>
          <FlexSection direction={direction} gap={gap} />
        </Box>
      </ContentObjectProvider>
    </div>
  );
}
