import { Abilities } from '@casl/ability/dist/types/types';
import { useContext } from 'react';

import { CanContext } from '@/components/CanProvider/CanProvider';

export enum Resource {
  All = 'all',
  Assignment = 'Assignment',
  AssignmentDraftFeedbackForAssignment = 'AssignmentDraftFeedbackForAssignment',
  AssignmentDraftNoteToTeacher = 'AssignmentDraftNoteToTeacher',
  AssignmentNotes = 'AssignmentNotes',
  AssignmentSatisfactionScore = 'AssignmentSatisfactionScore',
  AssignmentSubmissionHistory = 'AssignmentSubmissionHistory',
  AssignmentSubmitPage = 'AssignmentSubmitPage',
  AssignmentSummaryPage = 'AssignmentSummaryPage',
  Canvas = 'Canvas',
  CorrectAnswers = 'CorrectAnswers',
  EnlargeOpenDiscussionAnswer = 'EnlargeOpenDiscussionAnswer',
  Feedback = 'Feedback',
  LocalState = 'LocalState',
  PlenaryLessonAnswers = 'PlenaryLessonAnswers',
  PlenaryLessonCode = 'PlenaryLessonCode',
  PresenterActionBar = 'PresenterActionBar',
  QuestionCheckButton = 'QuestionCheckButton',
  QuestionEvaluation = 'QuestionEvaluation',
  QuestionGrade = 'QuestionGrade',
  QuestionHintButton = 'QuestionHintButton',
  QuestionResetButton = 'QuestionResetButton',
  QuestionScoreWeight = 'QuestionScoreWeight',
  QuestionShowAnswerButton = 'QuestionShowAnswerButton',
  QuestionStatus = 'QuestionStatus',
  ReturnToFixSwitch = 'ReturnToFixSwitch',
  SatisfactionSurvey = 'SatisfactionSurvey',
  SavedState = 'SavedState',
  SlidesNavigation = 'SlidesNavigation',
  SlideNumber = 'SlideNumber',
  StudentAssignedMode = 'StudentAssignedMode',
  StudentLearningMode = 'StudentLearningMode',
  StudentTestMode = 'StudentTestMode',
  TeacherSolvedMode = 'TeacherSolvedMode',
  UnansweredQuestions = 'UnansweredQuestions',
  ViewMode = 'ViewMode'
}

export const useCan = (ability: Abilities, resource: Resource) => {
  const authorization = useContext(CanContext);
  return authorization.can(ability, resource);
};
