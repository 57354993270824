import { Flex, rem, Text as MantineText } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from './ScoreWeight.styles';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { OVERALL_MAX_SCORE_WEIGHT_AMOUNT } from '@/utils/consts';

type WidgetScoreWeightProps = {
  scoreWeight?: number;
  parentScoreWeight?: number;
};

export function ScoreWeight(props: WidgetScoreWeightProps) {
  const { scoreWeight, parentScoreWeight } = props;
  const { classes, cx } = useStyles();
  const t = useTranslations('components');

  const showScoreWeightValue = !!scoreWeight && !isNaN(scoreWeight);

  return (
    <>
      {showScoreWeightValue && (
        <Flex className={cx(classes.scoreContainer, ThemeClassNames.scoreWeight)} gap={rem(5)}>
          <MantineText c="dimmed" span>
            {t('scoreWeight.score')}
          </MantineText>
          <MantineText className={classes.scoreWeight} span>
            {scoreWeight}
          </MantineText>
          <MantineText c="dimmed" span>
            / {parentScoreWeight || OVERALL_MAX_SCORE_WEIGHT_AMOUNT}
          </MantineText>
        </Flex>
      )}
    </>
  );
}
