import { rem } from '@mantine/core';
import { Row } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { TableCell } from './TableCell';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CLASSNAMES, TRow } from '@/widgets/Table/utils';

type TableRowProps = {
  row: Row<TRow>;
};

export const TableRow = memo(function TableRow(props: TableRowProps) {
  const { row } = props;
  const rowId = row.original.id;
  const rowHeights = useTable(context => context.rowHeights);

  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.tr, CLASSNAMES.TableRowWrapper, ThemeClassNames.widgets.table.row)}
      style={{
        minHeight: `${rem(rowHeights[rowId])}`
      }}
    >
      {row.getVisibleCells().map(cell => (
        <TableCell key={cell.id} cell={cell} />
      ))}
    </div>
  );
});
