import { AnswerElementType } from 'shared/types/AnswerElement';
import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import {
  SELECTION_MAX_COLUMN_AMOUNT,
  allowedElementTypesCombinations,
  defaultAnswerConfig
} from 'shared/utils/selectionQuestionConfigs';

import { AnswersAddNew } from '@/components/AnswersAddNew';
import { AnswersColumnCountPicker } from '@/components/AnswersColumnCountPicker';
import { AnswersElementPicker } from '@/components/AnswersElementPicker';
import { AnswersStylePicker } from '@/components/AnswersStylePicker';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { useAddNewAnswer } from '@/widgets/hooks/useAddNewAnswer';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

const ElementTypes: Record<AnswerElementType, AnswerElementType> = {
  Text: 'Text',
  Image: 'Image',
  // Video: 'Video',
  Audio: 'Audio'
};

const styleOptions = [AnswerStyleType.Default, AnswerStyleType.Button];

export function Design() {
  const [amountOfColumns, setAmountOfColumns] = useContentObjectProperty<number>('amountOfColumns');
  const [answerElements, setAnswerElements] = useContentObjectProperty<AnswerElementType[]>('answerElements');
  const [answersStyle, setAnswersStyle] = useContentObjectProperty<string>('answersStyle');

  const { hasAudio, hasImage, hasText } = useAnswerElements();

  const addNewAnswer = useAddNewAnswer(defaultAnswerConfig);

  return (
    <>
      <AnswersColumnCountPicker
        value={amountOfColumns}
        onChange={setAmountOfColumns}
        maxColumnCount={SELECTION_MAX_COLUMN_AMOUNT}
        dataTestId="selection-question-number-of-columns-radio"
      />
      <AnswersAddNew onClick={addNewAnswer} dataTestId="selection-question-add-new-answer" />
      <AnswersElementPicker
        allowedElementTypesCombinations={allowedElementTypesCombinations}
        dataTestId="selection-question-answer-element"
        onChange={setAnswerElements}
        options={Object.keys(ElementTypes) as AnswerElementType[]}
        value={answerElements}
      />
      <AnswersStylePicker
        hasAudio={hasAudio}
        hasImage={hasImage}
        hasText={hasText}
        onChange={setAnswersStyle}
        options={styleOptions}
        value={answersStyle}
      />
    </>
  );
}
