export const manifestLocales = {
  clozeQuestion: {
    previewClozeQuestionAlt: {
      en: 'clozeQuestion widget',
      he: 'שאלת קלוז'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    },
    answerPlaceholder: {
      en: 'Answer placeholder',
      he: 'ממלא מקום לתשובה'
    }
  }
};
