import { User } from '@hendrx/modules/authentication';
import { HocuspocusProvider } from '@hocuspocus/provider';
import sample from 'lodash/sample';
import { useLocale } from 'next-intl';
import { createContext, ReactNode, useMemo, useRef } from 'react';
import * as Y from 'yjs';
import { createStore, StoreApi } from 'zustand';

import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { SupportedLocale } from 'shared/types/SupportedLocale';
import { initializeYjsDocumentStageData } from 'shared/utils/initializeYjsDocumentStageData';
import { getContentObjectsMap, getFormDataMap, getSensitiveDataMap } from 'shared/widgetsSDK/yjs';

import { YjsDevTools } from '@/components/YjsDevTools';
import { mantineDefaultColors } from '@/consts/mantineDefaultColors';

declare global {
  interface Window {
    getContentObjectData(contentObjectId: string): unknown;
    getSensitiveData(contentObjectId: string): unknown;
    getFormData(contentObjectId: string): unknown;
  }
}

export type DocumentType = 'learningObjectDraft' | 'learningObjectSnapshot';

export type ActiveUser = Pick<User, 'avatarUrl' | 'id' | 'firstName' | 'lastName' | 'role'> & {
  color: string;
};

type InitializeYjsParams = {
  documentName: string;
  documentType: DocumentType;
  isAssignment: boolean;
  learningObjectType: LearningObjectTypes;
  user: User;
};

export type YjsStoreType = {
  activeUsers: ActiveUser[];
  initializeYjs: (params: InitializeYjsParams) => () => void;
  isSynced: boolean;
  undoManager: Y.UndoManager | null;
  yjsDocument: Y.Doc;
};

export const YjsStoreContext = createContext<StoreApi<YjsStoreType> | null>(null);

type YjsProviderProps = { children: ReactNode; document?: Y.Doc; withDevTools?: boolean };

export function YjsProvider(props: YjsProviderProps) {
  const { withDevTools = true } = props;

  // We do not want to recreate the store if the locale changes because it's only used to initialize the submit page once
  const localeRef = useRef(useLocale() as SupportedLocale);
  const yjsStore = useMemo(
    () =>
      createStore<YjsStoreType>((set, get) => {
        const initialState = {
          activeUsers: [],
          isSynced: false
        };

        let document = props.document || new Y.Doc();
        const undoManager: Y.UndoManager | null = null;

        return {
          ...initialState,
          yjsDocument: document,
          undoManager,
          isSynced: false,
          initializeYjs: ({ documentName, documentType, isAssignment, learningObjectType, user }) => {
            const provider = new HocuspocusProvider({
              document,
              name: documentName,
              parameters: { documentType, learningObjectType, isAssignment },
              // Hocuspocus requires us to define it because we use onAuthenticate on the backend, but this token is not really being used.
              token: 'no_token',
              url: process.env.NEXT_PUBLIC_WS_URL ?? '',
              onAwarenessUpdate: ({ states: awarenessStates }) => {
                set(state => ({
                  ...state,
                  activeUsers: awarenessStates.map(awarenessState => awarenessState.user)
                }));
              },
              onSynced: () => {
                const rootMap = provider.document.getMap('root');
                initializeYjsDocumentStageData(document, learningObjectType, localeRef.current);

                set(state => ({
                  ...state,
                  undoManager: new Y.UndoManager(rootMap),
                  isSynced: true
                }));

                window.getContentObjectData = function (contentObjectId: string) {
                  return getContentObjectsMap(document).get(contentObjectId);
                };
                window.getSensitiveData = function (contentObjectId: string) {
                  return getSensitiveDataMap(document).get(contentObjectId);
                };
                window.getFormData = function (contentObjectId: string) {
                  return getFormDataMap(document).get(contentObjectId);
                };
              }
            });

            provider.setAwarenessField('user', {
              avatarUrl: user.avatarUrl,
              color: sample(mantineDefaultColors),
              firstName: user.firstName,
              id: user.id,
              lastName: user.lastName,
              role: user.role
            } as ActiveUser);

            return () => {
              if (provider) {
                provider.destroy();
                document = new Y.Doc();

                set(() => ({
                  ...initialState,
                  yjsDocument: document,
                  undoManager: new Y.UndoManager(document.getMap('root'))
                }));
              }
            };
          }
        };
      }),
    [props.document]
  );

  return (
    <YjsStoreContext.Provider value={yjsStore}>
      {props.children}
      {withDevTools && <YjsDevTools />}
    </YjsStoreContext.Provider>
  );
}
