import { Box, Flex, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { usePreview } from 'react-dnd-preview';

import { useStyles } from './DndLayer.styles';
import { useStore } from '@/store';

export function DndLayer(props: PropsWithChildren) {
  const { children } = props;

  const { classes } = useStyles();

  const preview = usePreview<{ id: string; value: string }, HTMLDivElement>();

  const stageScale = useStore(state => state.stageScale);

  if (!preview.display) {
    return null;
  }

  const { ref, monitor, style } = preview;

  const { x: clientX, y: clientY } = monitor.getClientOffset() ?? { x: 0, y: 0 };
  const { x: elementX, y: elementY } = monitor.getInitialSourceClientOffset() ?? { x: 0, y: 0 };

  const deltaX = (clientX - elementX) / stageScale;
  const deltaY = (clientY - elementY) / stageScale;

  const transform = `translate(${deltaX}px, ${deltaY}px)`;

  const previewDndProps = {
    style: {
      ...style,
      position: 'absolute' as const,
      transform,
      WebkitTransform: transform
    },
    ref
  };

  return (
    <Box {...previewDndProps}>
      <Flex className={classes.wordBankOptionDragged}>
        <Text className={classes.wordBankOptionText}>{children}</Text>
      </Flex>
    </Box>
  );
}
