import { Box } from '@mantine/core';
import { Editor } from '@tiptap/react';
import cx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import * as Y from 'yjs';

import { OpenQuestionValue } from 'shared/types/OpenQuestion';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { QuestionState } from '@/queries/state';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { getQuestionBulletWrapperData, shouldShowResetButton, shouldShowScoreWeight } from '@/utils/questions.utils';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import {
  QuestionGroupListAction,
  useParentQuestionGroupAction,
  useParentQuestionGroupSettings
} from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';

export function OpenQuestion() {
  const [flexSectionId] = useContentObjectProperty<string>('children.0');

  const [editor] = useState<Editor | null>(null);

  const { document, id } = useContentObject();
  const [questionId] = useContentObjectProperty<string>('id');

  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const { showHintButton, questionScoreMode } = useContentObjectEvaluationProps();
  const [scoreWeight] = useContentObjectScoreWeight();

  const questionGroupSettings = useParentQuestionGroupSettings();
  const questionGroupAction = useParentQuestionGroupAction();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const isRequired = isQuestionRequired(questionId, document);
  const showResetButton = shouldShowResetButton(questionGroupSettings);
  const [, setElementState] = usePlayerElementState<QuestionState<OpenQuestionValue> | undefined>(questionId);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const [manualGrades] = usePlayerElementState<Record<string, number> | undefined>('manualGrades');
  const grade = manualGrades?.[id];

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  const resetAnswer = useCallback(() => {
    editor?.commands.setContent('', false);
    setElementState(currentState => ({
      ...currentState,
      answers: {}
    }));
  }, [setElementState, editor]);

  useEffect(() => {
    if (questionGroupAction?.action === QuestionGroupListAction.Reset) {
      resetAnswer();
    }
  }, [questionGroupAction?.action, resetAnswer]);

  return (
    <div className={customClassNames}>
      {labelFragment && (
        <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
          <Box className={cx(questionClasses.label, ThemeClassNames.widgets.openQuestion.label)}>
            <QuestionTitle questionId={id} fragment={labelFragment} editable={false} />
          </Box>
        </ListBulletWrapper>
      )}
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <FlexSectionPlayer />
        </ContentObjectProvider>
        <QuestionFooter
          questionId={id}
          hintFragment={hintFragment}
          isHintEditable={false}
          onReset={resetAnswer}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={false}
          showCheckButton={false}
          showHintButton={showHintButton}
          showResetButton={showResetButton}
          requiresManualGrade
          grade={grade}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
