import { Checkbox, Flex, Radio } from '@mantine/core';
import cx from 'clsx';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { WidgetType } from 'shared/utils/widgets';

import { InteractivePollPlayerSingleAnswer } from './InteractivePollPlayerSingleAnswer';
import { AnswersGrid } from '@/components/AnswersGrid';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import * as classes from '@/contentObjects/InteractiveQuizAnswer/InteractiveQuestionAnswerPlayer.css';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { useUser } from '@/queries/user';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';
import { useColumnsForCurrentDevice } from '@/widgets/hooks/useColumnsForCurrentDevice';
import { useOrderedAnswers } from '@/widgets/hooks/useOrderedAnswers';

export function InteractivePollAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.InteractivePoll}>
      <InteractivePollAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

export function InteractivePollAnswerPlayerImplementation() {
  const [isMultiSelect] = useContentObjectProperty<boolean>('isMultiselect');

  const { id } = useContentObject();

  const { orderedAnswers } = useOrderedAnswers();

  const [selectedAnswers = [], handleSelectAnswers] = usePlayerElementState<string[]>(id);

  const columns = useColumnsForCurrentDevice();
  const { isTextOnly } = useAnswerElements();
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const { user } = useUser();
  const isStudentMobile = user?.role === 'student' || user?.role === 'plenaryStudent';

  return (
    <Flex className={isStudentMobile ? classes.wrapper.mobile : classes.wrapper.base}>
      {isMultiSelect ? (
        <Checkbox.Group
          value={selectedAnswers}
          onChange={handleSelectAnswers}
          className={cx(questionClasses.inputGroup, classes.answers)}
          classNames={{ label: questionClasses.label, description: questionClasses.description }}
          size="xl"
        >
          <AnswersGrid columns={columns} isTextOnly={isTextOnly} answersStyle={answersStyle}>
            {orderedAnswers.map(answerIndex => (
              <InteractivePollPlayerSingleAnswer
                key={answerIndex}
                answerIndex={answerIndex}
                isChecked={selectedAnswers.includes(answerIndex.toString())}
                inputType="checkbox"
              />
            ))}
          </AnswersGrid>
        </Checkbox.Group>
      ) : (
        <Radio.Group
          value={selectedAnswers[0] ?? ''}
          onChange={answer => handleSelectAnswers([answer])}
          className={cx(questionClasses.inputGroup, classes.answers)}
          classNames={{ label: questionClasses.label, description: questionClasses.description }}
          size="xl"
        >
          <AnswersGrid columns={columns} isTextOnly={isTextOnly} answersStyle={answersStyle}>
            {orderedAnswers.map(answerIndex => (
              <InteractivePollPlayerSingleAnswer
                key={answerIndex}
                answerIndex={answerIndex}
                isChecked={selectedAnswers.includes(answerIndex.toString())}
                inputType="radio"
                name={`RadioQuestion-${id}`}
              />
            ))}
          </AnswersGrid>
        </Radio.Group>
      )}
    </Flex>
  );
}
