import { Button } from '@mantine/core';

import { useStyles as useResetButtonStyles } from '@/components/Theme/ClearActivityStyleProperty/ClearActivityStyleProperty.styles';
import { Reset } from '@/icons/Reset';

type ButtonResetProps = {
  onClick: () => void;
  disabled?: boolean;
};

// This is a very simple reset button for imitating ClearActivityStyleProperty button
// Upcoming refactor will use a similar solution for resetting content object's theme styling
export function ResetButton(props: ButtonResetProps) {
  const { onClick, disabled } = props;
  const { classes } = useResetButtonStyles();
  return (
    <Button className={classes.clearButton} size="compact-md" onClick={onClick} disabled={disabled}>
      <Reset className={classes.clearIcon} />
    </Button>
  );
}
