import { Box, Flex, Text } from '@mantine/core';
import Image from 'next/image';

import { Data, TEXT_WIDTH } from './Chart';
import * as classes from './PieChartLegendItem.css';

type PieChartLegendProps = {
  payload?: { payload?: { key?: string; value?: number; fill?: string } }[];
  data: Data;
};

export const PieChartLegend = (props: PieChartLegendProps) => {
  const { payload, data } = props;
  return (
    <div>
      {payload?.map(item => {
        const dataItem = data.find(dataItem => dataItem.key === item.payload?.key);
        return dataItem && item.payload ? (
          <PieChartLegendItem
            dataKey={item.payload.key ?? ''}
            fill={item.payload.fill ?? ''}
            img={dataItem.img}
            key={item.payload.key}
            label={dataItem.label}
            value={item.payload.value ?? 0}
          />
        ) : null;
      })}
    </div>
  );
};

type PieChartLegendItemProps = { label?: string; img?: string; value: number; dataKey: string; fill: string };

const PieChartLegendItem = (props: PieChartLegendItemProps) => {
  const { img, label, value, dataKey, fill } = props;
  return (
    <Flex className={classes.legendItem} align="center">
      <Box bg={fill} className={classes.valueBox}>
        {value}
      </Box>
      {img && (
        <Image
          width={label ? 90 : 120}
          height={label ? 72 : 96}
          className={label ? classes.image.withLabel : classes.image.base}
          src={img}
          alt={dataKey}
        />
      )}
      {label && (
        <Text fz={30} c="systemDark.8" w={TEXT_WIDTH}>
          {label}
        </Text>
      )}
    </Flex>
  );
};
