import { AnswerElement } from 'shared/types/AnswerElement';
import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { ChartType } from 'shared/types/ChartType';
import {
  INTERACTIVE_QUIZ_MAX_COLUMN_AMOUNT,
  allowedElementTypesCombinations,
  defaultAnswerConfig
} from 'shared/widgetsSDK/InteractiveQuiz/consts';

import { AnswersAddNew } from '@/components/AnswersAddNew';
import { AnswersColumnCountPicker } from '@/components/AnswersColumnCountPicker';
import { AnswersElementPicker } from '@/components/AnswersElementPicker';
import { AnswersStylePicker } from '@/components/AnswersStylePicker';
import { AnswersVisualizationPicker } from '@/components/AnswersVisualizationPicker';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { useAddNewAnswer } from '@/widgets/hooks/useAddNewAnswer';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

type InteractiveQuizAnswerElementType = `${AnswerElement.Text | AnswerElement.Image}`;

const ElementTypes: Record<InteractiveQuizAnswerElementType, InteractiveQuizAnswerElementType> = {
  Text: 'Text',
  Image: 'Image'
};

const styleOptions = [AnswerStyleType.Default, AnswerStyleType.Button, AnswerStyleType.CheckboxWithoutBorder];

const supportedChartTypes = [ChartType.HorizontalBarChart, ChartType.VerticalBarChart];

export function Design() {
  const [amountOfColumns, setAmountOfColumns] = useContentObjectProperty<number>('amountOfColumns');
  const [answerElements, setAnswerElements] =
    useContentObjectProperty<InteractiveQuizAnswerElementType[]>('answerElements');
  const [answersStyle, setAnswersStyle] = useContentObjectProperty<string>('answersStyle');
  const [answersVisualization, setAnswersVisualization] = useContentObjectProperty<ChartType>('answersVisualization');

  const { hasAudio, hasImage, hasText } = useAnswerElements();

  const addNewAnswer = useAddNewAnswer(defaultAnswerConfig);

  return (
    <>
      <AnswersColumnCountPicker
        value={amountOfColumns}
        onChange={setAmountOfColumns}
        maxColumnCount={INTERACTIVE_QUIZ_MAX_COLUMN_AMOUNT}
        dataTestId="interactive-quiz-number-of-columns-radio"
      />
      <AnswersAddNew onClick={addNewAnswer} dataTestId="interactive-quiz-add-new-answer" />
      <AnswersElementPicker
        allowedElementTypesCombinations={allowedElementTypesCombinations}
        dataTestId="interactive-quiz-answer-element"
        onChange={setAnswerElements}
        options={Object.keys(ElementTypes) as InteractiveQuizAnswerElementType[]}
        value={answerElements}
      />
      <AnswersStylePicker
        hasAudio={hasAudio}
        hasImage={hasImage}
        hasText={hasText}
        onChange={setAnswersStyle}
        options={styleOptions}
        value={answersStyle}
      />
      <AnswersVisualizationPicker
        onChange={setAnswersVisualization}
        supportedChartTypes={supportedChartTypes}
        value={answersVisualization}
      />
    </>
  );
}
