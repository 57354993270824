import { ComponentType, ReactElement, createContext, useContext, useMemo } from 'react';
import * as Y from 'yjs';
import { StoreApi, createStore } from 'zustand';

import { TColumnOrder, TColumnWidths, TRowHeights, TRowOrder } from '@/widgets/Table/utils';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type TableProviderProps = {
  children: ReactElement;
  TableCellRenderer: ComponentType<{ contentId: string }>;
};

type TableStore = {
  TableCellRenderer: ComponentType<{ contentId: string }>;
};

const TableStoreContext = createContext<StoreApi<TableStore> | null>(null);

export function TableProvider(props: TableProviderProps) {
  const { children, TableCellRenderer } = props;

  const tiptapClozeOptionsStore = useMemo(
    () =>
      createStore<TableStore>(() => ({
        TableCellRenderer
      })),
    [TableCellRenderer]
  );

  return <TableStoreContext.Provider value={tiptapClozeOptionsStore}>{children}</TableStoreContext.Provider>;
}

type TableSelector = {
  columnOrder: TColumnOrder;
  setColumnOrder: (newValue: TColumnOrder | ((prevState: TColumnOrder) => TColumnOrder)) => void;
  rowOrder: TRowOrder;
  setRowOrder: (newValue: TRowOrder | ((prevState: TRowOrder) => TRowOrder)) => void;
  columnWidths: TColumnWidths;
  setColumnWidths: (newValue: TColumnWidths | ((prevState: TColumnWidths) => TColumnWidths)) => void;
  rowHeights: TRowHeights;
  setRowHeights: (newValue: TRowHeights | ((prevState: TRowHeights) => TRowHeights)) => void;
  content: Y.Map<unknown> | undefined;
  setContent: (newValue: Y.Map<unknown> | ((prevState: Y.Map<unknown>) => Y.Map<unknown>)) => void;
  TableCellRenderer: ComponentType<{ contentId: string }>;
};

export function useTable<T = TableSelector>(selector: (state: TableSelector) => T) {
  const store = useContext(TableStoreContext);
  if (store === null) throw new Error('Context setup is incorrect');

  const [rowOrder, setRowOrder] = useContentObjectProperty<TRowOrder>('rowOrder');
  const [columnOrder, setColumnOrder] = useContentObjectProperty<TColumnOrder>('columnOrder');
  const [columnWidths, setColumnWidths] = useContentObjectProperty<TColumnWidths>('columnWidths');
  const [rowHeights, setRowHeights] = useContentObjectProperty<TRowHeights>('rowHeights');
  const [content, setContent] = useContentObjectProperty<Y.Map<unknown>>(`contentById`);

  const tableStore: TableSelector = {
    rowOrder,
    setRowOrder,
    columnOrder,
    setColumnOrder,
    columnWidths,
    setColumnWidths,
    rowHeights,
    setRowHeights,
    content,
    setContent,
    TableCellRenderer: store.getState().TableCellRenderer
  };

  return selector(tableStore);
}
