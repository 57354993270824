import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type AnswerOptionButtonStylesProps = {
  disabled?: boolean;
};

export const useStyles = createStyles((theme, props: AnswerOptionButtonStylesProps) => {
  const { disabled } = props;

  return {
    group: {
      cursor: disabled ? 'auto' : 'pointer'
    },
    icon: {
      minWidth: rem(64),
      minHeight: rem(64)
    }
  };
});
