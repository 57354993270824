import { Alert, Button, Textarea, TextInput } from '@mantine/core';

import { Config } from './Config';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function Studio() {
  const [status] = useContentObjectProperty<string>('status');
  const [avatarData] = useContentObjectProperty<object>('avatarData');

  return (
    <div>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      {!status ? <Alert variant="light">Configure the widget in the sidebar to generate the avatar.</Alert> : null}
      {status === 'generating' ? (
        <Alert variant="light" color="orange">
          The video is being generated. Please wait...
        </Alert>
      ) : null}
      {status === 'ready' ? (
        <video controls style={{ maxWidth: '100%' }}>
          <source src={avatarData?.result_url} />
        </video>
      ) : null}
    </div>
  );
}
