import { ReactNode, useEffect } from 'react';
import * as Y from 'yjs';

import { useTextTools } from 'shared/richTextToolbar/useTextTools';
import { TextTypes } from 'shared/types/Text';

import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useBlockTitleWidgetStyles } from '@/utils/typographyStyles.utils';
import { Manifest } from '@/widgets/BlockTitle/Manifest';
import { Config } from '@/widgets/BlockTitle/config';
import { Base } from '@/widgets/BlockTitle/studio/Base';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function BlockTitle(props: StudioProps & { config?: ReactNode }) {
  const { initialize, config } = props;

  const { document, id } = useContentObject();
  const [placeholder] = useContentObjectProperty<string>('placeholder');
  const [fragment] = useContentObjectProperty<Y.XmlFragment>('fragment');
  const [textType] = useContentObjectProperty<TextTypes>('textType');

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const widgetStyles = useBlockTitleWidgetStyles(id);

  const [tools] = useTextTools({ textType, withParagraphOption: false, withCssTool: true });

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <>
      <SideMenuPortal>
        <Config>{config}</Config>
      </SideMenuPortal>
      <div className={customClassNames}>
        <Base
          tools={tools}
          fragment={fragment}
          placeholder={placeholder}
          widgetStyles={widgetStyles}
          overrideTypographyStylesPath="blockTitle"
          changeContrastColorToMain
        />
      </div>
    </>
  );
}
