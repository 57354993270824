import { useMantineTheme } from '@mantine/core';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { InteractivePollAnswerElement } from './InteractivePollAnswerElement';
import { AnswerOption } from '@/components/AnswerOption';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function InteractivePollAnswerOption(props: { answerIndex: number }) {
  const { answerIndex } = props;
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const theme = useMantineTheme();

  return (
    <AnswerOption
      AnswerElementComponent={InteractivePollAnswerElement}
      answerAreaThemeProps={theme.other.collabItems.interactivePoll.answerArea}
      answerIndex={answerIndex}
      answersStyle={answersStyle}
      checkboxThemeProps={theme.other.collabItems.interactivePoll.checkbox}
      dataTestId="studio-interactive-poll"
      withCheckbox={answersStyle !== 'Button'}
    />
  );
}
