import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    container: {
      background: theme.colors.accent[0],
      padding: rem(4),
      borderRadius: rem(8),
      display: 'flex',
      gap: rem(12),
      width: rem(194),
      height: rem(40),
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      fontSize: rem(20),
      fontWeight: 500
    }
  };
});
