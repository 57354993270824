import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type UploadFileStylesProps = {
  isRejected?: boolean;
  previewMaxHeight?: number;
  aspectRatio?: string;
  isThumbnail?: boolean;
  disabled?: boolean;
};

export const useStyles = createStyles((theme, props: UploadFileStylesProps) => {
  const { isRejected, previewMaxHeight = 400, aspectRatio, isThumbnail, disabled } = props;

  return {
    dropzone: {
      backgroundColor: theme.colors.accent[0],
      borderColor: isRejected ? theme.colors.red[8] : theme.colors.accent[3],
      borderWidth: rem(1),
      borderStyle: 'dashed',
      borderSpacing: '20px',
      height: '100%',
      width: '100%',
      padding: `${rem(5)} ${rem(12)}`,
      aspectRatio: aspectRatio,
      ...(disabled && { opacity: '40%', cursor: 'not-allowed' })
    },
    dropzoneInner: {
      height: '100%'
    },
    dropzoneContent: {
      color: isRejected ? theme.colors.red[8] : theme.colors.gray[7],
      alignItems: 'center',
      justifyContent: 'center',
      gap: rem(8),
      flexDirection: isThumbnail ? 'column' : 'row',
      paddingInline: isThumbnail ? 0 : rem(8),
      wordBreak: 'normal'
    },
    dropzoneIcon: {
      svg: {
        width: isThumbnail ? rem(36) : rem(24),
        height: isThumbnail ? rem(36) : rem(24)
      },
      alignItems: 'center',
      justifyContent: 'center',
      color: isRejected ? theme.colors.red[8] : theme.colors.accent[6]
    },
    dropzoneContentText: {
      flexDirection: 'column',
      alignItems: isThumbnail ? 'center' : 'flex-start',
      justifyContent: 'center',
      gap: rem(4),
      paddingInline: isThumbnail ? rem(20) : 0,
      textAlign: isThumbnail ? 'center' : 'start',
      '*': {
        lineHeight: 1.5
      }
    },
    image: {
      width: '100%',
      height: 'auto',
      maxHeight: rem(previewMaxHeight),
      objectFit: 'contain',
      aspectRatio: aspectRatio,
      ...(disabled && { opacity: '40%' })
    },
    deleteBtn: {
      backgroundColor: theme.colors.accent[6],
      padding: `${rem(8)} ${rem(8)}`,
      borderRadius: rem(10),
      boxShadow: `0 0 ${rem(6)} 0 rgba(56, 55, 69, 0.30)`,
      color: theme.white,
      height: rem(36),
      width: rem(36),
      ...(disabled && { opacity: '40%' })
    },
    replaceBtn: {
      display: 'flex',
      width: 'auto',
      gap: rem(8),
      backgroundColor: theme.colors.gray[0],
      padding: `${rem(8)} ${rem(8)}`,
      borderRadius: rem(10),
      boxShadow: `0 0 ${rem(6)} 0 rgba(56, 55, 69, 0.30)`,
      color: theme.colors.accent[6],
      height: rem(36)
    },
    disabled: {
      opacity: '40%'
    }
  };
});
