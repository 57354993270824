import * as Y from 'yjs';

import { TextTypes } from 'shared/types/Text';

import { TextWidgetStyles } from '@/utils/typographyStyles.utils';
import { RichText } from '@/widgets/_components/RichText';

export type BaseProps = {
  fragment?: Y.XmlFragment;
  placeholder?: string;
  dataTestIdPrefix?: string;
  textType?: TextTypes;
  disableTypographyInheritance?: boolean;
  widgetStyles?: TextWidgetStyles;
};

export function Base(props: BaseProps) {
  return <RichText editable={false} {...props} />;
}
