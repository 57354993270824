import { Box, Button, Dialog } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { useStyles } from './YjsDevTools.styles';
import { useCurrentDocument } from '@/components/YjsProvider';
import { Eye } from '@/icons/Eye';
const DynamicReactJson = dynamic(import('react-json-view'), { ssr: false });

function DevTools() {
  const { classes } = useStyles();
  const [opened, { close, toggle }] = useDisclosure();
  const yjsDocument = useCurrentDocument();
  const [doc, setDoc] = useState(() => yjsDocument?.toJSON() ?? {});

  useEffect(() => {
    // @ts-expect-error For development debugging
    window.__YJS_DOCUMENT__ = yjsDocument;
    if (opened) {
      const handler = () => {
        setDoc(yjsDocument.toJSON());
      };
      handler();
      yjsDocument.on('update', handler);
      return () => yjsDocument.off('update', handler);
    }
  }, [yjsDocument, opened]);

  return (
    <>
      <Button onClick={toggle} className={classes.openButton} data-testid="yjs-dev-tools-button">
        <Eye />
      </Button>
      <Dialog onClose={close} size="90vw" opened={opened} withCloseButton>
        <Box className={classes.jsonContainer}>
          <DynamicReactJson src={doc} collapsed sortKeys />
        </Box>
      </Dialog>
    </>
  );
}
export const YjsDevTools =
  process.env.NODE_ENV === 'development'
    ? DevTools
    : function () {
        return null;
      };
