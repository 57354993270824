import { getSchema } from '@tiptap/core';
import { generateJSON } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import { prosemirrorJSONToYXmlFragment } from 'y-prosemirror';

import { BaseClozeExtension } from '../tiptap/BaseClozeExtension';
import { BaseFillInTheBlanksExtension } from '../tiptap/BaseFillInTheBlanksExtension';
import { Heading } from '../tiptap/heading';

export const htmlToXmlFragment = (content: string) => {
  const extensions = [
    StarterKit.configure({
      heading: false
    }),
    Heading,
    BaseFillInTheBlanksExtension,
    BaseClozeExtension
  ];

  const schema = getSchema(extensions);
  const json = generateJSON(content, extensions);
  const fragment = prosemirrorJSONToYXmlFragment(schema, json);

  return fragment;
};
