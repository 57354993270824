import { clientModules } from '@hendrx/modules';
import { SelectProps, Text } from '@mantine/core';
import { forwardRef } from 'react';

import { Select } from '@/components/Select';

type FontFamilyPickerProps = {
  placeholder?: string;
  title?: string;
} & Omit<SelectProps, 'data'>;

export function FontFamilyPicker(props: FontFamilyPickerProps) {
  const { onChange, value, placeholder, title, ...rest } = props;

  return (
    <Select
      title={title}
      contentEditable
      placeholder={placeholder}
      renderOption={renderFontOption}
      value={value}
      onChange={onChange}
      data={clientModules.ui.fonts}
      withCheckIcon={false}
      {...rest}
    />
  );
}

type FontOptionProps = React.ComponentPropsWithoutRef<'div'> & {
  label: string;
  value: string;
};

const renderFontOption: SelectProps['renderOption'] = ({ option, checked }) => (
  <FontOption label={option.label} value={option.value} />
);

export const FontOption = forwardRef<HTMLDivElement, FontOptionProps>((props, ref) => {
  const { label, value, ...others } = props;

  return (
    <Text w="100%" ref={ref} ff={value} ta="center" truncate {...others}>
      {label}
    </Text>
  );
});
FontOption.displayName = 'FontOption';
