export type Direction = 'ltr' | 'rtl';
export type Alignment = 'right' | 'left' | 'center';

export const defaultLanguage = 'he';

export const getDirectionFromLanguage = (language: string): Direction => {
  return language === 'he' || language === 'ar' ? 'rtl' : 'ltr';
};

export const getAlignmentFromLanguage = (language: string): Alignment => {
  return language === 'he' || language === 'ar' ? 'right' : 'left';
};

export const getCssXPosition = (direction: Direction) => {
  return direction === 'rtl' ? 'right' : 'left';
};

export const getDirectionMultiplier = (direction: Direction) => {
  return direction === 'rtl' ? -1 : 1;
};

export const getFlipStyle = (direction: Direction) => {
  return { scale: `${getDirectionMultiplier(direction) * 1} 1` };
};
