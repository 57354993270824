import { Box } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { usePreview } from 'react-dnd-preview';

import { useStyles } from './DndDragLayer.styles';
import { useFullscreenScale } from '@/components/Stage/hooks/useFullscreenScale';
import { useFullscreenContext } from '@/utils/useFullscreenContext';
import { DndItem } from '@/widgets/_components/dnd/dnd';

type Props = {
  scale?: number;
  fixed?: boolean;
  width?: number;
} & PropsWithChildren;

export function DndDragLayer(props: Props) {
  const { scale = 1, children, fixed, width = 0 } = props;

  const preview = usePreview<DndItem, HTMLDivElement>();

  const { isFullscreen } = useFullscreenContext();
  const { fullscreenScale, offsetLeft, offsetTop } = useFullscreenScale();

  const { classes } = useStyles();

  if (!preview.display) {
    return null;
  }

  const { ref, monitor, style } = preview;

  const { x: elementX, y: elementY } = monitor.getSourceClientOffset() ?? { x: 0, y: 0 };

  let deltaX = elementX / scale;
  let deltaY = elementY / scale;

  if (isFullscreen) {
    deltaX = (elementX - offsetLeft) / fullscreenScale;
    deltaY = (elementY - offsetTop) / fullscreenScale;
  }

  const transform = `translate(${deltaX}px, ${deltaY}px) scale(${1 / (isFullscreen ? fullscreenScale : scale)})`;

  const previewDndProps = {
    style: {
      ...style,
      position: fixed ? ('fixed' as const) : ('absolute' as const),
      transform,
      // Without WebkitTransform the app seems to crash
      WebkitTransform: transform
    },
    ref,
    className: classes.dndPreview
  };

  return (
    <Box {...previewDndProps} sx={{ width }}>
      {children}
    </Box>
  );
}
