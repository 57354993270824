import * as Y from 'yjs';

import { FeedbackMessages, getFeedbackMessageType } from './feedback';
import { calculateSelectionQuestionPercentageScore } from './formulas';

export const evaluateInteractiveQuiz = (
  contentObjectData: Y.Map<unknown>,
  sensitiveData: Y.Map<unknown>,
  answers: string[]
) => {
  const correctAnswers = sensitiveData.get('correctAnswers') as string[];
  const allAnswers = (contentObjectData.get('answers') as Y.Array<Y.Map<unknown>>).toArray();

  let rightAnswersCount = 0;
  let wrongAnswersCount = 0;

  // show `no answer` feedback until user picks an answer
  if (answers.length === 0 && correctAnswers.length === 0) {
    return { percentageScore: 0, feedbackMessageType: FeedbackMessages.NoAnswer, statuses: {}, noCorrectAnswers: true };
  }

  const evaluatedAnswers = answers.reduce((statuses, answer) => {
    const allAnswerIds = allAnswers.map(answer => answer.get('id') as string);
    const correctAnswersIndices = correctAnswers.map(correctAnswerId =>
      allAnswerIds.indexOf(correctAnswerId).toString()
    );
    const isCorrect = correctAnswersIndices.includes(answer);

    if (isCorrect) {
      rightAnswersCount++;
    } else {
      wrongAnswersCount++;
    }

    return {
      ...statuses,
      [answer]: isCorrect ? 'correct' : 'incorrect'
    };
  }, {});

  const percentageScore = calculateSelectionQuestionPercentageScore({
    allPossibleAnswersCount: allAnswers.length,
    allPossibleRightAnswersCount: correctAnswers.length,
    rightAnswersCount,
    wrongAnswersCount
  });

  const feedbackMessageType = getFeedbackMessageType({
    allPossibleRightAnswersCount: correctAnswers.length,
    rightAnswersCount,
    wrongAnswersCount,
    feedback: null
  });

  return { percentageScore, evaluatedAnswers, feedbackMessageType };
};
