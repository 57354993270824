import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewEmbedData } from 'shared/widgetsSDK/Embed/create';

import { manifestLocales } from './manifest.locales';
import EmbedEn from './resources/en/embed.svg';
import EmbedHe from './resources/he/embed.svg';
import { EmbedObject } from '@/icons/EmbedObject';

export const Manifest: ManifestType = {
  name: WidgetType.Embed,
  previewGroups: [
    {
      id: 'embed',
      divider: {
        icon: EmbedObject,
        title: 'embed'
      },
      previews: [
        {
          name: 'embedPreview',
          previewImage: {
            paths: {
              en: EmbedEn,
              he: EmbedHe
            },
            size: {
              width: 285,
              height: 140
            },
            alt: { ...manifestLocales.embed.previewImageAlt }
          },
          dimensions: {
            width: 560,
            height: 315
          },
          initializeProps: () => createNewEmbedData()
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 200,
      minWidth: 200
    }
  }
};
