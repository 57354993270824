import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

type ViewBox = {
  x?: number;
  y?: number;
  cx?: number;
  cy?: number;
  innerRadius?: number;
  outerRadius?: number;
  startAngle?: number;
};

type PieChartLabelCenterProps = { viewBox?: ViewBox; total: number };

export const PieChartLabelCenter = (props: PieChartLabelCenterProps) => {
  const { total, viewBox: { cx = 0, cy = 0 } = {} } = props;

  const positioningProps = useMemo(
    () => ({
      x: cx,
      y: cy,
      textAnchor: 'middle',
      verticalAnchor: 'middle'
    }),
    [cx, cy]
  );

  const theme = useMantineTheme();

  return (
    <>
      <text {...positioningProps} fontSize={66} fill={theme.colors.systemDark[8]} fontWeight="bold">
        {total}
      </text>
      <text
        {...positioningProps}
        y={positioningProps.y + 50}
        fontSize={30}
        fill={theme.colors.systemDark[8]}
        fontWeight="bold"
      >
        {'answers'}
      </text>
    </>
  );
};
