import { Flex, Radio, ThemeIcon } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { QuestionListBulletTypes } from 'shared/types/QuestionGroupList';
import { RelativeScoreMode } from 'shared/types/QuestionScoreMode';

import * as classes from './BulletsAndNumberingSettings.css';
import { useQuestionNumberingSetting } from '@/contentObjects/Root/hooks/useQuestionNumberingSetting';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useQuestionListBulletType } from '@/widgets/QuestionGroupList/hooks/useQuestionListBulletType';
import { ReactComponent as TrianglePoint } from '@/widgets/QuestionGroupList/resources/triangle-point.svg';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function BulletsAndNumberingSettings() {
  const t = useTranslations('widgets');
  const [, setBulletListType] = useContentObjectProperty<QuestionListBulletTypes>('questionListBulletType');
  const [relativeScoreMode] = useContentObjectProperty<RelativeScoreMode>('relativeScoreMode');

  const bulletListType = useQuestionListBulletType();
  const [withAutomaticQuestionNumbering] = useQuestionNumberingSetting();

  const disabled = withAutomaticQuestionNumbering && relativeScoreMode === RelativeScoreMode.RelativeToActivity;

  const { contentDirection } = useContentLanguage();

  return (
    <FormControl title={t('questionGroupListSettings.bulletAndNumbering')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Radio.Group value={bulletListType} onChange={value => setBulletListType(value as QuestionListBulletTypes)}>
          <Flex direction="column" gap={12}>
            <Radio
              disabled={disabled}
              value={QuestionListBulletTypes.Number}
              label={
                <ThemeIcon className={classes.bulletList.base} radius="xl">
                  1
                </ThemeIcon>
              }
            />
            <Radio
              disabled={disabled}
              value={QuestionListBulletTypes.Letter}
              label={
                <ThemeIcon className={classes.bulletList.base} radius="xl">
                  {t('questionGroupListSettings.firstLetter')}
                </ThemeIcon>
              }
            />
            <Radio
              disabled={disabled}
              value={QuestionListBulletTypes.Triangle}
              label={
                <TrianglePoint
                  className={contentDirection === 'ltr' ? classes.bulletList.base : classes.bulletList.rotated}
                />
              }
            />
            <Radio
              disabled={disabled}
              value={QuestionListBulletTypes.None}
              label={t(`questionGroupListSettings.none`)}
            />
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
}
