import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useRef, useState } from 'react';

import { useStyles } from './Base.styles';
import { StyledActionIcon } from '@/components/StyledActionIcon';
import { AudioPause } from '@/icons/AudioPause';
import { AudioPlaceholder } from '@/icons/AudioPlaceholder';
import { AudioPlay } from '@/icons/AudioPlay';
import { Placeholder } from '@/widgets/_components/widget/Placeholder';

type AudioClassNames = {
  playButton?: string;
  placeholder?: string;
};

export type BaseProps = {
  classNames?: AudioClassNames;
  src?: string;
  title?: string;
};

export function Base(props: BaseProps) {
  const { src, title, classNames } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const t = useTranslations('widgets');
  const { classes, cx } = useStyles();

  const label = isPlaying ? t('audioWidget.pause') : t('audioWidget.play');

  const handleClick = () => {
    if (isPlaying) {
      audioRef?.current?.pause();
    } else {
      void audioRef?.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioStop = () => {
    setIsPlaying(false);
  };

  return src ? (
    <>
      <audio ref={audioRef} src={src} onEnded={handleAudioStop} title={title} />
      <Box className={classes.audioContainer}>
        <StyledActionIcon
          className={cx(classes.audio, classNames?.playButton)}
          aria-label={label}
          label={label}
          onClick={handleClick}
          icon={isPlaying ? <AudioPause /> : <AudioPlay />}
        />
      </Box>
    </>
  ) : (
    <Placeholder Icon={AudioPlaceholder} className={classNames?.placeholder} />
  );
}
