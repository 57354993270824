export const manifestLocales = {
  enclosureWithBlockTitle: {
    previewImageAlt: {
      en: 'Enclosure With Block Title',
      he: 'כרטיס עם כותרת'
    },
    initialText: {
      en: 'Enclosure With Block Title',
      he: 'כרטיס עם כותרת'
    }
  }
};
