import * as Y from 'yjs';

import { calculateGrade } from './calculateGrade';
import { Feedbacks, getFeedbackMessageType } from './feedback';
import { calculateClozeQuestionPercentageScore } from './formulas';

type WordOption = {
  id: string;
  value: string;
};

export const evaluateFillInTheBlanksQuestion = (
  contentObjectData: Y.Map<unknown>,
  sensitiveData: Y.Map<unknown>,
  widgetFormData: Y.Map<unknown>,
  answers: Record<string, WordOption>
) => {
  const correctOptions = sensitiveData.get('correctOptions') as Y.Map<string>;
  const feedback = contentObjectData.get('feedback') as Feedbacks;
  const wordBankOptions = contentObjectData.get('wordBankOptions') as Y.Array<{ id: string; value: string }>;
  const wordBankOptionsArray = wordBankOptions.toArray();
  const scoreWeight = widgetFormData.get('scoreWeight') as number;

  let rightAnswersCount = 0;
  let wrongAnswersCount = 0;

  const evaluatedAnswers = Object.entries(answers).reduce((result, [fillInTheBlanksId, item]) => {
    const userValue = item.value;
    const correctOptionId = correctOptions?.get(fillInTheBlanksId);
    const correctOptionValue = wordBankOptionsArray.find(option => option.id === correctOptionId)?.value;
    const isCorrect = correctOptionValue === userValue;

    if (isCorrect) {
      rightAnswersCount += 1;
    } else {
      wrongAnswersCount += 1;
    }

    result[fillInTheBlanksId] = isCorrect;

    return result;
  }, {} as Record<string, boolean>);

  // Use the same formula as for the ClozeQuestion
  const percentageScore = calculateClozeQuestionPercentageScore({
    allAnswersCount: correctOptions.size,
    rightAnswersCount,
    wrongAnswersCount
  });

  const feedbackMessageType = getFeedbackMessageType({
    allPossibleRightAnswersCount: correctOptions.size,
    rightAnswersCount,
    wrongAnswersCount,
    feedback
  });

  const grade = calculateGrade(percentageScore, scoreWeight, feedbackMessageType);

  return { evaluatedAnswers, feedbackMessageType, percentageScore, grade };
};
