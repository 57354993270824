import { MouseEvent, memo, useCallback, useRef } from 'react';

import { useStyles } from './Table.styles';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { RowId, calculateRowMinHeight } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

type TableRowResizerProps = {
  rowId: RowId;
};

export const TableRowResizer = memo(function TableRowResizer(props: TableRowResizerProps) {
  const { rowId } = props;
  const initialY = useRef<number | null>(null);
  const deltaY = useRef<number>(0);
  const setRowHeights = useTable(context => context.setRowHeights);
  const { document: yjsDocument } = useContentObject();

  const handleRowHeightChange = useCallback(
    (delta: number) => {
      yjsDocument.transact(() => {
        setRowHeights(rowHeights => ({ ...rowHeights, [rowId]: rowHeights[rowId] + delta }));
      }, 'untracked');
    },
    [rowId, setRowHeights, yjsDocument]
  );

  const handleRowHeightChangeEnd = useCallback(() => {
    const rowMinHeight = calculateRowMinHeight(rowId);
    yjsDocument.transact(() => {
      setRowHeights(currentHeights => ({
        ...currentHeights,
        [rowId]: currentHeights[rowId] < rowMinHeight ? rowMinHeight : currentHeights[rowId]
      }));
    }, 'untracked');
  }, [rowId, setRowHeights, yjsDocument]);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    initialY.current = e.clientY;
    deltaY.current = 0;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = useCallback(
    (e: globalThis.MouseEvent) => {
      if (initialY.current !== null) {
        deltaY.current = e.clientY - initialY.current;
        handleRowHeightChange(deltaY.current);
        initialY.current = e.clientY;
      }
    },
    [handleRowHeightChange]
  );

  const handleMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    handleRowHeightChangeEnd();
  }, [handleMouseMove, handleRowHeightChangeEnd]);

  const { classes } = useStyles();

  return <div onMouseDown={handleMouseDown} className={classes.rowResizer} />;
});
