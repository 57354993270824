import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';

import { useStyles } from './ClozeOption.styles';
import { ClozeOptionInput } from './ClozeOptionInput';
import { ClozeOptionSelect } from './ClozeOptionSelect';
import { Check } from '@/icons/Check';
import { Cross } from '@/icons/Cross';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useTiptapClozeOptionsStore } from '@/widgets/ClozeQuestion/player/hooks/useTiptapClozeOptionsStore';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';

export function ClozeOption(props: NodeViewRendererProps) {
  const {
    node: {
      attrs: { clozeId, fontFamily, fontSize, type }
    }
  } = props;

  const handleSelectAnswers = useTiptapClozeOptionsStore(state => state.handleSelectAnswers);
  const selectedAnswers = useTiptapClozeOptionsStore(state => state.selectedAnswers);
  const selectedAnswersStatuses = useTiptapClozeOptionsStore(state => state.selectedAnswersStatuses);
  const disabled = useTiptapClozeOptionsStore(state => state.disabled);

  const isCorrect = selectedAnswersStatuses[clozeId];

  const { contentDirection } = useContentLanguage();

  const { classes, cx } = useStyles({ disabled, fontFamily, fontSize, isCorrect, direction: contentDirection });

  const onChange = useCallback(
    (newValue: string) => {
      handleSelectAnswers({ ...selectedAnswers, [clozeId]: newValue });
    },
    [handleSelectAnswers, selectedAnswers, clozeId]
  );

  return (
    <NodeViewWrapper className={classes.componentWithContent}>
      {isCorrect !== undefined ? (
        <>
          {isCorrect ? (
            <Check className={cx(classes.icon, classes.checkIcon)} />
          ) : (
            <Cross className={cx(classes.icon, classes.crossIcon)} />
          )}
        </>
      ) : null}
      {type === ClozeOptionType.Input ? (
        <ClozeOptionInput
          fontSize={fontSize}
          fontFamily={fontFamily}
          className={classes.content}
          disabled={disabled}
          onInputChange={onChange}
          value={selectedAnswers[clozeId] ?? ''}
        />
      ) : (
        <ClozeOptionSelect
          fontSize={fontSize}
          fontFamily={fontFamily}
          inputClassName={classes.content}
          clozeId={clozeId}
          disabled={disabled}
          onInputChange={onChange}
          value={selectedAnswers[clozeId] ?? null}
        />
      )}
    </NodeViewWrapper>
  );
}
