import cx from 'clsx';
import ImageNext from 'next/image';
import Link from 'next/link';

import { LinkTargetType, ObjectFit } from 'shared/types/Image';

import * as classes from './Base.css';
import { ImagePlaceholder } from '@/icons/ImagePlaceholder';
import { useDeviceView } from '@/utils/deviceHooks';
import { Placeholder } from '@/widgets/_components/widget/Placeholder';

type ImageClassNames = {
  image?: string;
  placeholder?: string;
};

export type BaseProps = {
  src: string;
  alt: string;
  mobileSrc: string;
  objectFit: ObjectFit;
  mobileObjectFit: ObjectFit;
  classNames?: ImageClassNames;
  goToLink?: string;
  linkTargetType?: LinkTargetType;
};

export function Base(props: BaseProps) {
  const {
    src,
    mobileSrc,
    alt,
    classNames,
    linkTargetType,
    goToLink,
    objectFit: generalObjectFit,
    mobileObjectFit
  } = props;
  const { deviceView } = useDeviceView();

  const isMobile = deviceView === 'mobile';
  const imageSrc = mobileSrc && isMobile ? mobileSrc : src;
  const objectFit = isMobile ? mobileObjectFit : generalObjectFit;

  const BaseComponent = imageSrc ? (
    <ImageNext src={imageSrc} alt={alt} fill className={cx(classes.image, classNames?.image)} style={{ objectFit }} />
  ) : (
    <Placeholder Icon={ImagePlaceholder} className={classNames?.placeholder} />
  );

  return goToLink ? (
    <Link className={classes.link} href={goToLink} target={linkTargetType}>
      {BaseComponent}
    </Link>
  ) : (
    BaseComponent
  );
}
