import { Box, Flex, Text } from '@mantine/core';
import { ReactNode } from 'react';

import { ClearActivityStyleProperty } from '@/components/Theme/ClearActivityStyleProperty';

type ThemeControlProps = {
  children: ReactNode;
  className?: string;
  globalPath?: string;
  label?: string;
  partialGlobalPath?: string;
  path?: string;
  pathsBatch?: string[];
};

export function ThemeControl(props: ThemeControlProps) {
  const { path, children, label, globalPath, partialGlobalPath, pathsBatch, className } = props;

  return (
    <Box>
      {label && <Text size="sm">{label}</Text>}
      <Flex wrap="wrap" align="start" gap={4} className={className}>
        {children}
        {path && (
          <ClearActivityStyleProperty
            path={path}
            globalPath={globalPath}
            partialGlobalPath={partialGlobalPath}
            pathsBatch={pathsBatch}
          />
        )}
      </Flex>
    </Box>
  );
}
