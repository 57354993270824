import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { ClozeQuestionType } from 'shared/widgetsSDK/ClozeQuestion/create';

import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { ClozeTable } from '@/widgets/ClozeQuestion/player/ClozeTable';
import { ClozeText } from '@/widgets/ClozeQuestion/player/ClozeText';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';

export function ClozeQuestionAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.ClozeQuestion}>
      <ClozeQuestionAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

function ClozeQuestionAnswerPlayerImplementation() {
  const clozeQuestionType = useContentObjectStaticProperty<string>('clozeQuestionType');
  const answerFragment = useContentObjectStaticProperty<Y.XmlFragment>('answerFragment');

  return clozeQuestionType === ClozeQuestionType.Table ? <ClozeTable /> : <ClozeText fragment={answerFragment} />;
}
