export enum WidgetType {
  AvatarWidget = 'AvatarWidget',
  Audio = 'Audio',
  BlockTitle = 'BlockTitle',
  ClozeQuestion = 'ClozeQuestion',
  ClozeQuestionAnswer = 'ClozeQuestionAnswer',
  CombinedTitle = 'CombinedTitle',
  Embed = 'Embed',
  EnclosureWithBlockTitle = 'EnclosureWithBlockTitle',
  EnclosureWithCombinedTitle = 'EnclosureWithCombinedTitle',
  EvaluatablePlugin = 'EvaluatablePlugin',
  EvaluatablePluginAnswer = 'EvaluatablePluginAnswer',
  FillInTheBlanksQuestion = 'FillInTheBlanksQuestion',
  FillInTheBlanksQuestionAnswer = 'FillInTheBlanksQuestionAnswer',
  FlexSection = 'FlexSection',
  Image = 'Image',
  InteractivePoll = 'InteractivePoll',
  InteractivePollAnswer = 'InteractivePollAnswer',
  InteractiveQuiz = 'InteractiveQuiz',
  InteractiveQuizAnswer = 'InteractiveQuizAnswer',
  MicrosoftEmbed = 'MicrosoftEmbed',
  OpenDiscussion = 'OpenDiscussion',
  OpenQuestion = 'OpenQuestion',
  OpenQuestionAnswer = 'OpenQuestionAnswer',
  Page = 'Page',
  PdfDocument = 'PdfDocument',
  Plugin = 'Plugin',
  QuestionGroupList = 'QuestionGroupList',
  Section = 'Section',
  SelectionQuestion = 'SelectionQuestion',
  SelectionQuestionAnswer = 'SelectionQuestionAnswer',
  Slide = 'Slide',
  Spacer = 'Spacer',
  SubmitPage = 'SubmitPage',
  SubmitPageComment = 'SubmitPageComment',
  SubmitPageReturnToFixSwitch = 'SubmitPageReturnToFixSwitch',
  SubmitPageSatisfactionSurvey = 'SubmitPageSatisfactionSurvey',
  SubmitPageTitle = 'SubmitPageTitle',
  SubmitPageUnansweredQuestions = 'SubmitPageUnansweredQuestions',
  SummaryPage = 'SummaryPage',
  SummaryPageSubmissionHistory = 'SummaryPageSubmissionHistory',
  SummaryPageTaskSubmittedNotification = 'SummaryPageTaskSubmittedNotification',
  Table = 'Table',
  Text = 'Text',
  Video = 'Video'
}

export enum EvaluationMode {
  Auto = 'auto',
  Manual = 'manual'
}

export const evaluatableWidgets = [
  WidgetType.SelectionQuestion,
  WidgetType.ClozeQuestion,
  WidgetType.FillInTheBlanksQuestion,
  WidgetType.EvaluatablePlugin,
  WidgetType.QuestionGroupList,
  WidgetType.InteractiveQuiz
] as const;

// TODO: Make it possibe to evaluate OpenQuestion
export const scorableWidgets = [...evaluatableWidgets, WidgetType.OpenQuestion];

export const questionWidgets = [
  // Note: When extending, include the name in the QuestionGroupList -> QuestionBank -> locales
  WidgetType.ClozeQuestion,
  WidgetType.EvaluatablePlugin,
  WidgetType.FillInTheBlanksQuestion,
  WidgetType.OpenQuestion,
  WidgetType.SelectionQuestion
];

export const collaborativeWidgets = [
  WidgetType.InteractivePoll,
  WidgetType.InteractivePollAnswer,
  WidgetType.InteractiveQuiz,
  WidgetType.InteractiveQuizAnswer,
  WidgetType.OpenDiscussion
];

export const fullPageItems = [WidgetType.OpenDiscussion];

export const uniqueItemsPerPage = [WidgetType.OpenDiscussion, WidgetType.InteractivePoll, WidgetType.InteractiveQuiz];

export type EvaluatableWidgets = (typeof evaluatableWidgets)[number];

const isInArray = <T, A extends T>(item: T, array: ReadonlyArray<A>): item is A => array.includes(item as A);

export const canHaveScoreWeight = (widgetType: unknown) => {
  return isInArray(widgetType, scorableWidgets);
};

export const widgetsWithConfig = [
  WidgetType.Audio,
  WidgetType.BlockTitle,
  WidgetType.ClozeQuestion,
  WidgetType.CombinedTitle,
  WidgetType.Embed,
  WidgetType.EvaluatablePlugin,
  WidgetType.EnclosureWithBlockTitle,
  WidgetType.FillInTheBlanksQuestion,
  WidgetType.Image,
  WidgetType.InteractivePoll,
  WidgetType.InteractiveQuiz,
  WidgetType.MicrosoftEmbed,
  WidgetType.OpenQuestion,
  WidgetType.PdfDocument,
  WidgetType.Plugin,
  WidgetType.QuestionGroupList,
  WidgetType.SelectionQuestion,
  WidgetType.Table,
  WidgetType.Text,
  WidgetType.Video
];
