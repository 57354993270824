import { Box, Stack, rem, useMantineTheme } from '@mantine/core';
import { HeaderGroup } from '@tanstack/react-table';
import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useStyles } from './Table.styles';
import { TableMenu } from './TableMenu';
import { useStyles as useTooltipStyles } from './TableTooltip.styles';
import { useStyles as useDndStyles } from './dnd/Dnd.styles';
import { DndDragLayer } from './dnd/DndDragLayer';
import { DndCollect, DndItem } from './dnd/types';
import { useIsGlobalDragging } from './dnd/useIsGlobalDragging';
import { useMouseAndSize } from './hooks/useMouseAndSize';
import { useStore } from '@/store';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useHighlightColumnOnHover } from '@/widgets/Table/base/studio/hooks/useHighlightColumnOnHover';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CLASSNAMES, CSS_VARS, ColumnId, TRow, createContentId, getActualColumnWidth } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

type TableColumnMenuAreasProps = {
  headerGroups: HeaderGroup<TRow>[];
};

export const TableColumnMenuAreas = memo(function TableColumnMenuAreas(props: TableColumnMenuAreasProps) {
  const { headerGroups } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.thead}>
      {headerGroups.map(headerGroup => (
        <div className={classes.tr} key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <TableColumnMenuArea key={header.column.id} id={header.column.id} />
          ))}
        </div>
      ))}
    </div>
  );
});

type TableColumnMenuAreaProps = {
  id: ColumnId;
};

export function TableColumnMenuArea(props: TableColumnMenuAreaProps) {
  const { id } = props;

  const highlightElementRef = useHighlightColumnOnHover(id);
  const isGlobalDragging = useIsGlobalDragging();
  const { classes } = useTooltipStyles({ isGlobalDragging });

  const { id: tableId, document: yDocument } = useContentObject();
  const setColumnOrder = useTable(context => context.setColumnOrder);
  const setColumnWidths = useTable(context => context.setColumnWidths);
  const TableCellRenderer = useTable(context => context.TableCellRenderer);

  const {
    ref: mouseAndSizeRef,
    position,
    size: { width: previewWidth }
  } = useMouseAndSize();

  const stageScale = useStore(state => state.stageScale);

  const [{ isDragging }, drag, dragPreview] = useDrag<DndItem, void, DndCollect>(
    {
      type: `${tableId}-column`,
      item: { id },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    },
    [id, tableId]
  );

  const { contentDirection } = useContentLanguage();

  const [{ isOver }, drop] = useDrop<DndItem, void, DndCollect>({
    accept: `${tableId}-column`,
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    drop: item => {
      setColumnOrder(currentOrder => {
        // Find the index of the dragged column and the target column
        const draggedColumnIndex = currentOrder.indexOf(item.id);
        const targetColumnIndex = currentOrder.indexOf(id);

        const isSameColumn = item.id === id;
        if (isSameColumn) {
          return currentOrder;
        }

        // Determine the new index considering whether the item is dropped in the right or left area of the target
        const newIndex = targetColumnIndex + (isOverEnd ? 0 : 1);

        // Calculate adjustments based on the drag direction
        const adjustedIndex = draggedColumnIndex < newIndex ? newIndex - 1 : newIndex;

        // Create a new array excluding the dragged item
        const newColumnOrderWithoutDragged = currentOrder.filter(id => id !== item.id);

        // Insert the dragged item at the new position
        const newColumnOrder = [
          ...newColumnOrderWithoutDragged.slice(0, adjustedIndex),
          item.id,
          ...newColumnOrderWithoutDragged.slice(adjustedIndex)
        ];

        yDocument.transact(() => {
          setColumnWidths(columnWidths => {
            const newLastColumnId = newColumnOrder[newColumnOrder.length - 1];
            const currentLastColumnId = currentOrder[currentOrder.length - 1];
            return {
              ...columnWidths,
              [currentLastColumnId]: getActualColumnWidth(currentLastColumnId),
              [newLastColumnId]: 0
            };
          });
        }, 'untracked');

        return newColumnOrder;
      });
    }
  });

  const isOverLeft = isOver && position.x * (1 / stageScale) < previewWidth / 2;
  const isOverRight = isOver && position.x * (1 / stageScale) > previewWidth / 2;

  const isOverStart = contentDirection === 'rtl' ? isOverLeft : isOverRight;
  const isOverEnd = contentDirection === 'rtl' ? isOverRight : isOverLeft;

  const { classes: dndClasses, cx } = useDndStyles({
    isDragging,
    isOverStart,
    isOverEnd
  });
  drop(mouseAndSizeRef);

  const {
    other: {
      table: { isHeaderVisible }
    }
  } = useMantineTheme();

  const rowOrder = useTable(context => context.rowOrder);

  const contentId = isHeaderVisible ? id : createContentId(id, rowOrder[0]);

  dragPreview(highlightElementRef);

  return (
    <Box
      className={cx(dndClasses.dndContainer, CLASSNAMES.TableRowWrapper)}
      style={{
        width: `calc(var(${CSS_VARS.ColumnSize(id)}) * ${rem(1)})`
      }}
    >
      <Box ref={mouseAndSizeRef} className={dndClasses.dndWrapper}>
        <div
          className={classes.tooltipColumnAreaCell}
          key={id}
          ref={dragPreview}
          style={{
            width: `100%`
          }}
        >
          <TableMenu type="column" columnId={id} dragRef={drag} />
        </div>
      </Box>
      <DndDragLayer scale={stageScale} isDragging={isDragging}>
        <Stack
          style={{
            width: previewWidth,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            gap: 0
          }}
        >
          <TableMenu type="column" columnId={id} dragRef={null} />
          <TableCellRenderer contentId={contentId} />
        </Stack>
      </DndDragLayer>
    </Box>
  );
}
