import isEmpty from 'lodash/isEmpty';
import { memo, ReactNode } from 'react';

import { useStyles } from './WidgetErrorIndicator.styles';
import { ErrorTriangle } from '@/icons/ErrorTriangle';

type WidgetErrorIndicatorProps = {
  children: ReactNode;
  errors: Record<string, Array<string>>;
};

export const WidgetErrorIndicator = memo(function UIIndicators(props: WidgetErrorIndicatorProps) {
  const { children, errors } = props;
  const { classes } = useStyles();
  const hasErrors = !isEmpty(errors);

  return (
    <>
      {hasErrors && <ErrorTriangle data-testid="validation-error-triangle" className={classes.errorIcon} />}
      {children}
    </>
  );
});
