import { clientModules } from '@hendrx/modules';
import { MantineProvider } from '@mantine/core';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';
import cx from 'clsx';
import { ReactNode, useEffect, useMemo } from 'react';

import { useCurrentDocument } from '@/components/YjsProvider';
import { useActivityThemeStyles } from '@/contentObjects/Root/hooks/useActivityThemeStyles';
import { emotionCache } from '@/emotion/cache';
import { useThemeStyles } from '@/utils/mantineVanillaIntegration/useThemeStyles';
import { theme } from '@/utils/theme.utils';

type CSSVariablesProviderProps = {
  children: ReactNode;
};

/**
 * @description Provides CSS variable values and injects them into the component tree.
 */
export const CSSVarsValuesProvider = (props: CSSVariablesProviderProps) => {
  const { children } = props;

  const yDocument = useCurrentDocument();
  const [activityThemeStyles] = useActivityThemeStyles(yDocument);
  const mantineTheme = useMemo(
    () => ({
      ...theme,
      other: activityThemeStyles
    }),
    [activityThemeStyles]
  );

  const cssInjectionStyle = useThemeStyles(activityThemeStyles);

  useEffect(() => {
    document.documentElement.style.cssText = Object.entries(cssInjectionStyle)
      .map(([key, value]) => `${key}: ${value}`)
      .join(';');
  }, [cssInjectionStyle]);

  useEffect(() => {
    document.documentElement.className = cx(clientModules.ui.fonts.map(font => font.variable));
  }, []);

  return (
    <MantineEmotionProvider cache={emotionCache}>
      <MantineProvider theme={mantineTheme} stylesTransform={emotionTransform}>
        {children}
      </MantineProvider>
    </MantineEmotionProvider>
  );
};

/**
 * @description Custom hook that returns the CSS injection props based on the current document and activity theme styles.
 */
export const useCSSValuesInjectionProps = () => {
  const document = useCurrentDocument();
  const [activityThemeStyles] = useActivityThemeStyles(document);
  const varToValueMap = useThemeStyles(activityThemeStyles);

  return {
    className: cx(clientModules.ui.fonts.map(font => font.variable)),
    style: varToValueMap
  };
};
