import * as Y from 'yjs';

import { DraggableType, ExistingDraggableType, isExistingDraggableType } from '../types/DraggableType';
import { HorizontalLayout } from '../types/Layout';

type PartialLayout = Omit<HorizontalLayout, 'height'>;
export function canAddToSection(newChildLayout: PartialLayout, currentLayout: PartialLayout[]) {
  const fullLayout = [...currentLayout, newChildLayout];
  const totalSpan = fullLayout.reduce((total, layout) => total + layout.span, 0);

  if (totalSpan > 12) {
    return false;
  }
  const childrenAfter = fullLayout.filter(layout => layout.column > newChildLayout.column);
  const totalSpanAfter = childrenAfter.reduce((total, layout) => total + layout.span, 0);
  return totalSpanAfter <= 12 - newChildLayout.column;
}

export function isOverlapping(newChildLayout: PartialLayout, currentLayout: PartialLayout[]) {
  return currentLayout.some(layout => {
    if (newChildLayout.column === -1) {
      return false;
    }

    if (layout.column === newChildLayout.column) {
      return true;
    }

    if (newChildLayout.column < layout.column) {
      return newChildLayout.column + newChildLayout.span > layout.column;
    }

    if (layout.column === 0) {
      return layout.span >= newChildLayout.column;
    }

    return layout.column + layout.span > newChildLayout.column;
  });
}

export function alignLayoutItems(sectionLayout: Y.Map<Y.Map<unknown>>) {
  let remainingSpan = 13;

  sectionLayout.forEach(childLayout => {
    const span = childLayout.get('span');
    if (typeof span !== 'number') {
      throw new Error('Span is not a number.');
    }
    const column = remainingSpan - span;
    childLayout.set('column', column);
    remainingSpan -= span;
  });
}

export const removeItemFromLayout = (currentLayout: HorizontalLayout[], changedItem: ExistingDraggableType) => {
  const currentLayoutClone = [...currentLayout];

  const initiallyDraggedItemIndex = currentLayout.findIndex(layout =>
    changedItem ? layout.column === changedItem.column && layout.span === changedItem.span : -1
  );

  currentLayoutClone.splice(initiallyDraggedItemIndex, 1);

  return currentLayoutClone;
};

export const checkIfHasEnoughSpace = (
  currentLayout: HorizontalLayout[],
  childLayout: Omit<HorizontalLayout, 'height'>,
  id: string,
  changedItem: DraggableType | null
) => {
  let layoutToCheck = currentLayout;

  if (changedItem && isExistingDraggableType(changedItem) && id === changedItem.parentId) {
    layoutToCheck = removeItemFromLayout(currentLayout, changedItem);
  }

  return canAddToSection(childLayout, layoutToCheck) && !isOverlapping(childLayout, layoutToCheck);
};
