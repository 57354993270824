import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { memo } from 'react';
import * as Y from 'yjs';

import { ToolbarType } from 'shared/types/RichTextToolbar';

import { useStyles } from './Table.styles';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

export const TableCaption = memo(function TableCaption() {
  const caption = useContentObjectStaticProperty<Y.XmlFragment>('caption');

  const t = useTranslations('widgets.tableWidget');

  const { classes } = useStyles();

  return (
    <Box className={classes.caption}>
      <RichText fragment={caption} placeholder={`${t('caption')}`} toolbarType={ToolbarType.Fixed} />
    </Box>
  );
});
