import { useMutation } from '@tanstack/react-query';

const fetchAiModuleBackend = async (customData: Record<string, unknown>) => {
  const response = await fetch('/api/ai-module/call', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customData)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const useAIModuleBackendData = (customData: Record<string, unknown>) => {
  return useMutation<Record<string, unknown>>({
    mutationFn: () => fetchAiModuleBackend(customData)
  });
};
