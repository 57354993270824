import { Dispatch, MouseEvent, MutableRefObject, SetStateAction, useCallback } from 'react';

import { getItemWidth } from 'shared/widgetsSDK/Stage.utils';

import { Direction } from '@/components/Resizable/types';

interface HandleMouseDownProps {
  setOriginalWidth: Dispatch<SetStateAction<number>>;
  setResizingDirection: Dispatch<SetStateAction<Direction | undefined>>;
  setResizingItemId?: Dispatch<SetStateAction<string | undefined>>;
  id: string;
  span: number;
  coordsRef: MutableRefObject<{ x: number; y: number }>;
  width?: number;
}

export function useHandleMouseDown(props: HandleMouseDownProps) {
  const { setOriginalWidth, setResizingDirection, setResizingItemId, id, span, width, coordsRef } = props;

  return useCallback(
    (e: MouseEvent, direction: Direction) => {
      if (setResizingItemId) {
        setOriginalWidth(width || getItemWidth(span));
        setResizingDirection(direction);
        setResizingItemId(id);
        coordsRef.current.x = e.clientX;
        coordsRef.current.y = e.clientY;
      }
    },
    [coordsRef, id, setOriginalWidth, setResizingDirection, setResizingItemId, span, width]
  );
}
