import { useCallback } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';
import { getCollaborativeState, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

export const useCollaborativeAnswers = (openDiscussionId: string) => {
  const document = useCurrentDocument();
  const [answers = {}, setAnswers] = useObservedProperty<Record<string, OpenDiscussionAnswer[]>>(
    getCollaborativeState(document),
    'questionsAnswers'
  );

  const addAnswer = useCallback(
    (answer: OpenDiscussionAnswer) => {
      const clone = structuredClone(answers);
      if (!clone[openDiscussionId]) {
        clone[openDiscussionId] = [answer];
      } else {
        clone[openDiscussionId].push(answer);
      }
      setAnswers(clone);
    },
    [answers, openDiscussionId, setAnswers]
  );

  const updateAnswer = useCallback(
    (answer: OpenDiscussionAnswer) => {
      const clone = structuredClone(answers);
      const index = clone[openDiscussionId].findIndex(({ id }) => id === answer.id);
      clone[openDiscussionId][index] = answer;
      setAnswers(clone);
    },
    [answers, openDiscussionId, setAnswers]
  );

  const deleteAnswer = useCallback(
    (id: string) => {
      const clone = structuredClone(answers);
      clone[openDiscussionId] = clone[openDiscussionId].filter(({ id: answerId }) => answerId !== id);
      setAnswers(clone);
    },
    [answers, openDiscussionId, setAnswers]
  );

  const openDiscussionAnswers = answers[openDiscussionId] ?? [];

  return { openDiscussionAnswers, addAnswer, updateAnswer, deleteAnswer };
};
