import { Flex, Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { isChoiceSettingLocked } from 'shared/widgetsSDK/questionGroup';

import { useStyles } from './Design.styles';
import { AnswerCount } from '@/widgets/QuestionGroupList/config/design/AnswerCount';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function ChoiceQuestionsSettings() {
  const t = useTranslations('widgets');

  const [choiceQuestionsCount, setChoiceQuestionsCount] = useContentObjectProperty<number>('choiceQuestionsCount');
  const [choiceQuestionsEnabled, setChoiceQuestionsEnabled] =
    useContentObjectProperty<boolean>('choiceQuestionsEnabled');
  const [visibleQuestionsCount] = useContentObjectProperty<number>('visibleQuestionsCount');
  const [requiredQuestions] = useContentObjectProperty<string[]>('requiredSubQuestionsId');
  const requiredQuestionsCount = requiredQuestions.length;

  const { classes } = useStyles();

  return (
    <FormControl title={t('questionGroupListSettings.choiceQuestions')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Switch
          className={classes.choiceQuestionSwitch}
          checked={choiceQuestionsEnabled}
          onChange={event => setChoiceQuestionsEnabled(event.currentTarget.checked)}
          label={t('questionGroupListSettings.on')}
          disabled={isChoiceSettingLocked(visibleQuestionsCount, requiredQuestionsCount)}
        />
        {choiceQuestionsEnabled && (
          <AnswerCount
            value={choiceQuestionsCount}
            setValue={setChoiceQuestionsCount}
            min={requiredQuestions.length || 1}
            max={Math.max(visibleQuestionsCount - 1, 0)}
          />
        )}
      </Flex>
    </FormControl>
  );
}
