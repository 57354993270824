import { Box } from '@mantine/core';
import { Fragment, ReactElement } from 'react';

import { useStyles } from './DndGrid.styles';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { Dnd } from '@/widgets/_components/dnd/Dnd/Dnd';
import { DndIndicator } from '@/widgets/_components/dnd/DndIndicator/DndIndicator';

type Props = {
  amountOfColumns: number;
  onDrop: (startIndex: number, targetIndex: number) => void;
  contextId: string;
  children: ReactElement[];
  colMaxWidth: number;
};

export const DndGrid = (props: Props) => {
  const { amountOfColumns, children, onDrop, contextId, colMaxWidth } = props;

  const questionGroupSettings = useParentQuestionGroupSettings();
  const { classes } = useStyles({ amountOfColumns, colMaxWidth, dense: !!questionGroupSettings });
  const isLast = (index: number) => index % amountOfColumns === amountOfColumns - 1 || index === children.length - 1;

  const isSingleColumnLayout = amountOfColumns === 1;

  return (
    <Box className={classes.dndGrid} data-testid="drag-and-drop-grid" data-columns={amountOfColumns}>
      {children.map((child, index) => {
        return (
          <Fragment key={index}>
            <DndIndicator targetIndex={index} onDrop={onDrop} contextId={contextId} amountOfColumns={amountOfColumns} />
            <Dnd id={index.toString()} index={index} offset={36} contextId={contextId}>
              {child}
            </Dnd>
            {!isSingleColumnLayout && isLast(index) ? (
              <DndIndicator
                targetIndex={index + 1}
                onDrop={onDrop}
                contextId={contextId}
                amountOfColumns={amountOfColumns}
              />
            ) : null}
          </Fragment>
        );
      })}
      {isSingleColumnLayout ? (
        <DndIndicator
          targetIndex={children.length}
          onDrop={onDrop}
          contextId={contextId}
          amountOfColumns={amountOfColumns}
        />
      ) : null}
    </Box>
  );
};
