import type { InitializePropsOptions, ManifestInitializedProps } from './ManifestType';
import type { WidgetType } from '../utils/widgets';

export type NewDraggableType = {
  isNew: true;
  type: WidgetType;
  span: number;
  initializeProps: (options?: InitializePropsOptions) => ManifestInitializedProps;
  height: number | 'auto';
};

export type ExistingDraggableType = {
  isNew: false;
  id: string;
  parentId: string;
  span: number;
  column: number;
};

export type DraggableType = NewDraggableType | ExistingDraggableType;

export const isExistingDraggableType = (draggable: DraggableType): draggable is ExistingDraggableType => {
  return 'parentId' in draggable;
};
