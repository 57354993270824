import * as Y from 'yjs';

import { fullPageItems, WidgetType } from 'shared/utils/widgets';

import { ContentObjectType, getContentObjectsMap, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

function recursivelyContainsFullPageItem(contentObjectId: string, contentObjects: Y.Map<ContentObjectType>): boolean {
  const contentObjectData = validateYMap(contentObjects.get(contentObjectId));
  const widgetType = contentObjectData.get('type') as WidgetType;

  if (fullPageItems.includes(widgetType)) {
    return true;
  }

  const children = contentObjectData.get('children');

  if (!children) {
    return false;
  }

  const childrenIds = validateYArray<string>(children).toArray();
  return childrenIds.some(id => recursivelyContainsFullPageItem(id, contentObjects));
}

export const containsFullPageItem = (contentObjectId: string, document: Y.Doc) => {
  const contentObjects = getContentObjectsMap(document);

  return contentObjectId ? recursivelyContainsFullPageItem(contentObjectId, contentObjects) : false;
};

export const isFullPageItem = (contentObjectId: string, document: Y.Doc) => {
  const contentObjects = getContentObjectsMap(document);
  const contentObjectData = validateYMap(contentObjects.get(contentObjectId));
  const widgetType = contentObjectData.get('type') as WidgetType;
  return fullPageItems.includes(widgetType);
};
