import { Checkbox, Flex } from '@mantine/core';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { WidgetType } from 'shared/utils/widgets';

import * as classes from './InteractiveQuestionAnswerPlayer.css';
import { InteractiveQuizPlayerSingleAnswer } from './InteractiveQuizPlayerSingleAnswer';
import { AnswersGrid } from '@/components/AnswersGrid';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { useUser } from '@/queries/user';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { useEvaluatableQuestionAnswersContext } from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';
import { useColumnsForCurrentDevice } from '@/widgets/hooks/useColumnsForCurrentDevice';
import { useOrderedAnswers } from '@/widgets/hooks/useOrderedAnswers';

export function InteractiveQuizAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.InteractiveQuiz}>
      <InteractiveQuizAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

export function InteractiveQuizAnswerPlayerImplementation() {
  const { orderedAnswers } = useOrderedAnswers();

  const { handleSelectAnswers, selectedAnswers } = useEvaluatableQuestionAnswersContext<WidgetType.SelectionQuestion>();

  const columns = useColumnsForCurrentDevice();
  const { isTextOnly } = useAnswerElements();
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const { user } = useUser();
  const isStudentMobile = user?.role === 'student' || user?.role === 'plenaryStudent';

  return (
    <Flex className={isStudentMobile ? classes.wrapper.mobile : classes.wrapper.base}>
      <Checkbox.Group
        value={selectedAnswers}
        onChange={handleSelectAnswers}
        className={classes.answers}
        classNames={{ label: questionClasses.label, description: questionClasses.description }}
        size="xl"
      >
        <AnswersGrid columns={columns} isTextOnly={isTextOnly} answersStyle={answersStyle}>
          {orderedAnswers.map(answerIndex => (
            <InteractiveQuizPlayerSingleAnswer
              key={answerIndex}
              answerIndex={answerIndex}
              isChecked={selectedAnswers.includes(answerIndex.toString())}
              inputType="checkbox"
            />
          ))}
        </AnswersGrid>
      </Checkbox.Group>
    </Flex>
  );
}
