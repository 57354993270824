export enum PluginEvent {
  unloadEvent = 'unloadEvent',
  handleLoadEvent = 'handleLoadEvent',
  viewModeChangeEvent = 'viewModeChangeEvent',
  checkQuestionEvent = 'checkQuestionEvent',
  resetQuestionEvent = 'resetQuestionEvent',
  showQuestionAnswerEvent = 'showQuestionAnswerEvent',
  handleEditButtonClickEvent = 'handleEditButtonClickEvent',
  loadedEvent = 'loadedEvent',
  errorsDataEvent = 'errorsDataEvent',
  selectPluginEvent = 'selectPluginEvent',
  allowEditPluginEvent = 'allowEditPluginEvent',
  answersScoreChangeEvent = 'answersScoreChangeEvent',
  answersAnsweredChangeEvent = 'answersAnsweredChangeEvent',
  answersStateChangeEvent = 'answersStateChangeEvent',
  answersCheckedChangeEvent = 'answersCheckedChangeEvent'
}
