import * as Y from 'yjs';

import { FillInTheBlanksExtension } from './FillInTheBlanksOption/FillInTheBlanksExtension';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

const extraExtensions = [FillInTheBlanksExtension];

export function FillInTheBlanksText() {
  const answerFragment = useContentObjectStaticProperty<Y.XmlFragment>('answerFragment');

  return (
    <RichText
      fragment={answerFragment}
      extraExtensions={extraExtensions}
      contentEditable={false}
      dataTestIdPrefix="preview-fill-in-the-blanks-answer"
      disableTypographyInheritance
    />
  );
}
