import isEqual from 'lodash/isEqual';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as Y from 'yjs';

import { getQuestionNumbering } from './utils/getQuestionNumbering';
import { useQuestionGroupsContext } from '@/components/QuestionGroupsContext';
import { QuestionData, getQuestionData } from '@/components/ScoreWeightSummary/hooks/useQuestions';
import { useCurrentDocument } from '@/components/YjsProvider';
import { usePages } from '@/contentObjects/Root/hooks/usePages';
import { getActivityVisibleOrderedQuestionsIds } from '@/utils/getActivityOrderedQuestionsIds';
import { selectContentObjects } from '@/utils/selectContentObjects';
import { getFormDataMap, getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

type QuestionNumbering = Record<string, number | null>;

const QuestionNumberingContext = createContext<QuestionNumbering | null>(null);

type QuestionNumberingContextProps = { children: ReactNode };

export function QuestionNumberingContextProvider(props: QuestionNumberingContextProps) {
  const { children } = props;
  const document = useCurrentDocument();
  const rootMap = getRootMap(document);
  const widgetsFormData = getFormDataMap(document);
  const { visiblePages } = usePages(document);
  const questionGroups = useQuestionGroupsContext();

  const selectQuestionNumbering = useCallback(
    (contentObjects: Y.Map<Y.Map<unknown>>) => {
      const selectedContentObjects = selectContentObjects(contentObjects, { includeLabel: false });
      const orderedQuestionsIds = getActivityVisibleOrderedQuestionsIds(
        visiblePages.map(page => page.id),
        selectedContentObjects
      );
      const allTopLevelQuestions = orderedQuestionsIds
        .map(questionId => getQuestionData(questionId, selectedContentObjects, widgetsFormData, 'all', questionGroups))
        .filter(Boolean) as QuestionData[];

      return getQuestionNumbering(allTopLevelQuestions);
    },
    [visiblePages, widgetsFormData, questionGroups]
  );

  const [questionNumbering] = useObservedProperty<QuestionNumbering>(rootMap, 'contentObjects', {
    deepObserve: true,
    selector: selectQuestionNumbering
  });
  const [localQuestionNumbering, setLocalQuestionNumbering] = useState(questionNumbering);

  useEffect(() => {
    if (!isEqual(questionNumbering, localQuestionNumbering)) {
      setLocalQuestionNumbering(questionNumbering);
    }
  }, [questionNumbering, localQuestionNumbering]);

  return <QuestionNumberingContext.Provider value={questionNumbering}>{children}</QuestionNumberingContext.Provider>;
}

export function useQuestionNumberingContext() {
  const questionGroups = useContext(QuestionNumberingContext);
  if (questionGroups === null) throw new Error('QuestionNumberingContext not initialized properly.');
  return questionGroups;
}
