import isEmpty from 'lodash/isEmpty';
import * as Y from 'yjs';

import { evaluateQuestionByType, QuestionDataType } from './evaluateQuestionByType';

const getSubQuestionAnswers = (id: string, type: string, answers: Record<string, unknown>) => {
  const foundAnswers = answers[id];
  if (foundAnswers) return foundAnswers;

  // Found answers are undefined, provide empty objects to satisfy zod schema - it throws an error for undefined.
  return type === 'SelectionQuestion' ? [] : {};
};

const isQuestionAnswered = (questionId: string, answers: Record<string, unknown>) => {
  // TODO: Adjust after OpenQuestion evaluation is implemented
  return !isEmpty(answers[questionId]);
};

export const evaluateQuestionGroupList = ({
  subQuestionsData,
  shownQuestions,
  requiredQuestionIds,
  numberOfQuestionsToEvaluate,
  answers,
  contentObjectsMap,
  sensitiveDataMap,
  widgetsFormDataMap
}: {
  subQuestionsData: Record<string, QuestionDataType>;
  shownQuestions: string[];
  requiredQuestionIds: string[];
  numberOfQuestionsToEvaluate: number;
  answers: Record<string, unknown>;
  contentObjectsMap: Y.Map<Y.Map<unknown>>;
  sensitiveDataMap: Y.Map<unknown>;
  widgetsFormDataMap: Y.Map<unknown>;
}) => {
  // Get unrequired questions that should be evaluated. Order: from top to bottom,
  // but start from the questions that were answered.
  const optionalIds = shownQuestions.filter(questionId => !requiredQuestionIds.includes(questionId));
  const answeredOptionalQuestionIds = optionalIds.filter(id => {
    return isQuestionAnswered(id, answers);
  });
  const unansweredOptionalQuestionIds = optionalIds.filter(id => !answeredOptionalQuestionIds.includes(id));

  // Question ids in the evaluation order: required, answered, unanswered (everything from top to bottom)
  // and limit them by numberOfQuestionsToEvaluate
  const orderedQuestionIdsToEvaluate = requiredQuestionIds
    .concat(answeredOptionalQuestionIds)
    .concat(unansweredOptionalQuestionIds)
    .slice(0, numberOfQuestionsToEvaluate);

  // Evaluate subQuestions
  const evaluatedSubQuestions: Record<string, unknown> = {};
  orderedQuestionIdsToEvaluate.forEach(id => {
    const { type, contentObjectData, sensitiveData, widgetFormData } = subQuestionsData[id];

    evaluatedSubQuestions[id] = evaluateQuestionByType({
      type,
      payload: { answers: getSubQuestionAnswers(id, type, answers) },
      contentObjectData,
      sensitiveData,
      widgetFormData,
      contentObjectsMap,
      sensitiveDataMap,
      widgetsFormDataMap
    });
  });

  return {
    evaluatedSubQuestions
  };
};
