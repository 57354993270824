import { useStyles } from './Base.styles';
import { VideoPlaceholder } from '@/icons/VideoPlaceholder';
import { Placeholder } from '@/widgets/_components/widget/Placeholder';

export type BaseProps = {
  src: string;
  title: string;
};

export function Base(props: BaseProps) {
  const { src, title } = props;
  const { classes } = useStyles();

  return src ? (
    <video src={src} title={title} controls controlsList="nodownload" className={classes.video} />
  ) : (
    <Placeholder Icon={VideoPlaceholder} />
  );
}
