import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type TextHighlightPickerStylesProps = {
  color: string;
};

export const useStyles = createStyles((theme, props: TextHighlightPickerStylesProps) => {
  const { color } = props;

  return {
    colorPickerSwatch: {
      position: 'relative',
      ['&[aria-label="clear"]:after']: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: `calc(100% + ${rem(8)})`,
        height: `calc(100% + ${rem(4)})`,
        borderBottom: '3px solid rgb(255, 0, 0)',
        transform: `rotate(45deg)`,
        transformOrigin: 'right'
      },
      [`&[aria-label="${color}"] > div:nth-of-type(2)`]: {
        border: `2px solid ${theme.colors.blue[5]}`
      }
    }
  };
});
