import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { PLAYER_ASSIGNMENT_PATH } from '@/consts/paths';
import { getAssignmentStatus, StateStatus, submitAssignment } from '@/queries/assignmentApi';
import { submitFeedbackForAssignment } from '@/queries/stateApi';
import { useUser } from '@/queries/user';
import { parseQueryParam } from '@/utils/query.utils';

export function useGetAssignmentStatus({ enabled }: { enabled: boolean }) {
  const router = useRouter();
  const { user: currentUser } = useUser();

  const assignmentId = parseQueryParam(router.query.assignmentId);
  const studentId = parseQueryParam(router.query.studentId);

  const userId = studentId || currentUser?.id;

  const { data: savedStateDocument, isLoading } = useQuery<{ status: StateStatus }>({
    queryKey: ['assignmentStatus', { userId, assignmentId }],
    queryFn: () => getAssignmentStatus({ assignmentId: assignmentId as string, userId: userId as string }),
    enabled: enabled && !!userId && !!assignmentId
  });

  return {
    isLoading,
    isEvaluated: savedStateDocument?.status === StateStatus.Evaluated,
    isReturnedToFix: savedStateDocument?.status === StateStatus.ReturnedToFix,
    isSubmitted: savedStateDocument?.status === StateStatus.Submitted,
    isUnsubmitted: !savedStateDocument?.status || savedStateDocument.status === StateStatus.Unsubmitted,
    status: savedStateDocument?.status
  };
}

export function useSubmitAssignment({ onSuccess }: { onSuccess?: () => void }) {
  const router = useRouter();
  const { user } = useUser();
  const studentId = user?.id;

  const assignmentId = parseQueryParam(router.query.assignmentId) as string;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const assignmentURL = new URL(
        `${PLAYER_ASSIGNMENT_PATH + assignmentId}?studentId=${studentId}`,
        window.location.origin
      ).toString();
      if (!studentId || !assignmentId) {
        throw new Error('Cannot submit assignment without studentId or assignmentId.');
      }

      return submitAssignment({
        assignmentId,
        assignmentURL,
        studentId
      });
    },
    async onSuccess(_data) {
      await queryClient.invalidateQueries({
        queryKey: ['savedState', 'assignment', { userId: studentId, id: assignmentId }]
      });
      queryClient.setQueryData(['assignmentStatus', { userId: studentId, assignmentId }], {
        status: StateStatus.Submitted,
        draftNoteToTeacher: ''
      });
      if (onSuccess) {
        onSuccess();
      }
    }
  });
}

export const useSubmitFeedbackForAssignment = () => {
  const router = useRouter();

  const assignmentId = parseQueryParam(router.query.assignmentId) as string;
  const studentId = parseQueryParam(router.query.studentId);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { returnToFix?: boolean }) =>
      submitFeedbackForAssignment({
        assignmentId,
        studentId,
        returnToFix: variables.returnToFix
      }),
    onSuccess(_data, variables) {
      void queryClient.setQueryData(['savedState', 'assignment', { userId: studentId, id: assignmentId }], oldData => ({
        ...(oldData ?? {}),
        draftFeedbackForAssignment: '',
        status: variables.returnToFix ? StateStatus.ReturnedToFix : StateStatus.Evaluated
      }));
    }
  });
};
