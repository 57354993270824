import { useCallback } from 'react';

import { ManifestPreviews } from 'shared/types/ManifestType';
import { addNewItemToSectionFlex } from 'shared/widgetsSDK/addNewItemToSectionFlex';

import { useSelectableStore } from '@/components/Selectable';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useLearningObjectType } from '@/utils/useLearningObjectType';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useAddNewItemToSection() {
  const { data: contentObjectData, document } = useContentObject();
  const select = useSelectableStore(state => state.select);
  const { contentLanguage } = useContentLanguage();
  const { isPlenaryLesson } = useLearningObjectType();

  return useCallback(
    (
      itemHeight: number | 'auto',
      initializeProps: ManifestPreviews['initializeProps'],
      position: number,
      span: number,
      sectionWidth: number
    ) => {
      addNewItemToSectionFlex({
        document,
        contentObjectData,
        select,
        contentLanguage,
        isPlenaryLesson,
        itemHeight,
        initializeProps,
        position,
        span,
        sectionWidth
      });
    },
    [isPlenaryLesson, contentLanguage, document, select, contentObjectData]
  );
}
