import { Flex, Radio } from '@mantine/core';
import range from 'lodash/range';
import { useTranslations } from 'next-intl';

import { FormControl } from '@/widgets/_components/FormControl';

type AnswerColumnCountPickerProps = {
  dataTestId: string;
  maxColumnCount: number;
  onChange: (value: number) => void;
  value: number;
};

export function AnswersColumnCountPicker(props: AnswerColumnCountPickerProps) {
  const { onChange, value, dataTestId, maxColumnCount } = props;

  const t = useTranslations('components.answersColumnCountPicker');

  return (
    <FormControl title={t('title')}>
      <Radio.Group onChange={value => onChange(Number(value))} value={value.toString()}>
        <Flex justify={'space-around'}>
          {range(1, maxColumnCount + 1).map(option => (
            <Radio key={option} value={`${option}`} label={`${option}`} data-testid={dataTestId} />
          ))}
        </Flex>
      </Radio.Group>
    </FormControl>
  );
}
