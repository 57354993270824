import { Flex, Text } from '@mantine/core';
import { useDrag } from 'react-dnd';

import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';
import { DndLayer } from '@/widgets/FillInTheBlanksQuestion/player/DndLayer';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';

type WordBankOptionProps = {
  disabled?: boolean;
  item: WordOption;
  questionId: string;
};

export function WordBankOption(props: WordBankOptionProps) {
  const { disabled, item, questionId } = props;

  const { classes } = useStyles({ disabled });

  const [{ isDragging }, dragRef] = useDrag({
    type: `fitb-option-${questionId}`,
    item,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !disabled
  });

  return (
    <Flex ref={dragRef} className={classes.wordBankOption}>
      <Text className={classes.wordBankOptionText} data-testid="preview-word-bank-option">
        {item.value}
      </Text>
      {isDragging ? (
        <DndLayer>
          <Text className={classes.wordBankOptionText}>{item.value}</Text>
        </DndLayer>
      ) : null}
    </Flex>
  );
}
