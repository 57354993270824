import { Box } from '@mantine/core';
import * as Y from 'yjs';

import { useStyles } from './Table.styles';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

export function TableCaption() {
  const caption = useContentObjectStaticProperty<Y.XmlFragment>('caption');

  const { classes } = useStyles();

  return (
    <Box className={classes.caption}>
      <RichText fragment={caption} editable={false} />
    </Box>
  );
}
