import { StudentHelpers } from './StudentsHelpers';
import { HelpersTypes } from '@/widgets/types/helperType';

type HelpersProps = {
  disabled?: HelpersTypes[];
};

export function Helpers(props: HelpersProps) {
  const { disabled } = props;

  return <StudentHelpers disabled={disabled} />;
}
