import { Assets } from '@hendrx/modules/ui';

import { ReactComponent as CrossOutEyesFace } from './svgs/cross-out-eyes-face.svg';
import { ReactComponent as DissatisfiedFace } from './svgs/dissatisfied-face.svg';
import { ReactComponent as EyeOutlined } from './svgs/eye-outlined.svg';
import { ReactComponent as HappyFace } from './svgs/happy-face.svg';
import { ReactComponent as LightBulb } from './svgs/light-bulb.svg';
import { ReactComponent as Loader } from './svgs/loader.svg';
import { ReactComponent as NeutralFace } from './svgs/neutral-face.svg';
import { ReactComponent as PartiallyRightFace } from './svgs/partially-right-face.svg';
import { ReactComponent as ResetArrow } from './svgs/reset-arrow.svg';
import { ReactComponent as SadFace } from './svgs/sad-face.svg';
import { ReactComponent as SatisfiedFace } from './svgs/satisfied-face.svg';
import { ReactComponent as TempLogo } from './svgs/temp-logo.svg';

export const assets: Assets = {
  feedbackIcons: {
    AnswerSuccess: HappyFace,
    AnswerPartialSuccess: PartiallyRightFace,
    AnswerFailure: SadFace,
    NoAnswer: CrossOutEyesFace
  },
  interactiveQuizFeedbackIcons: {
    AnswerSuccess: HappyFace,
    AnswerPartialSuccess: PartiallyRightFace,
    AnswerFailure: SadFace,
    NoAnswer: CrossOutEyesFace
  },
  questionFooterIcons: {
    Hint: LightBulb,
    Answer: EyeOutlined,
    Reset: ResetArrow
  },
  logo: {
    en: TempLogo,
    he: TempLogo
  },
  satisfactionSurveyIcons: {
    Dissatisfied: DissatisfiedFace,
    Neutral: NeutralFace,
    Satisfied: SatisfiedFace
  },
  loader: Loader
};
