import { InteractivePollStudent } from './InteractivePollStudent';
import { InteractivePollTeacher } from './InteractivePollTeacher';
import { useUser } from '@/queries/user';

export const InteractivePollPlayer = () => {
  const { user } = useUser();

  const isStudent = user?.role === 'plenaryStudent' || user?.role === 'student';

  return isStudent ? <InteractivePollStudent /> : <InteractivePollTeacher />;
};
