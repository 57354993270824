import { useEffect } from 'react';

import { Base } from './Base';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';
export function PdfDocument(props: StudioProps) {
  const { initialize } = props;

  const { document, id } = useContentObject();
  const [src] = useContentObjectProperty<string>('src');
  const [title] = useContentObjectProperty<string>('title');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  useEffect(() => {
    initialize?.({
      isResizableVertically: true,
      shouldKeepAspectRatio: true
    });
  }, [initialize]);
  return (
    <div className={customClassNames}>
      <Base src={src} title={title} />
    </div>
  );
}
