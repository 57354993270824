import isNil from 'lodash/isNil';

import { Data } from './Chart';
import { ChartFeedbackCorrect } from '@/icons/ChartFeedbackCorrect';
import { ChartFeedbackWrong } from '@/icons/ChartFeedbackWrong';

const iconSize = 48;

type LabelListContentProps = {
  data: Data;
  direction: 'horizontal' | 'vertical';
  uiDirection: 'ltr' | 'rtl';
  fontSize?: string | number;
  height?: string | number;
  index?: number;
  value?: string | number;
  width?: string | number;
  x?: string | number;
  y?: string | number;
};

export const LabelListContent = (props: LabelListContentProps) => {
  const { x = 0, y = 0, width = 0, height = 0, value, index, data, fontSize, uiDirection, direction } = props;

  if (isNil(index)) {
    return;
  }

  const dataItem = data[index];
  const isCorrect = dataItem.isCorrect;

  const { iconCoordinates, textCoordinates } = calculateElementsCoordinates({
    direction,
    height: +height,
    uiDirection,
    width: +width,
    x: +x,
    y: +y
  });

  return (
    <g>
      <text
        x={textCoordinates.x}
        y={textCoordinates.y}
        color="#343A40"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize }}
      >
        {value || 0}
      </text>
      <foreignObject x={iconCoordinates.x} y={iconCoordinates.y} width={iconSize} height={iconSize}>
        {isCorrect ? (
          <ChartFeedbackCorrect width={iconSize} height={iconSize} />
        ) : (
          <ChartFeedbackWrong width={iconSize} height={iconSize} />
        )}
      </foreignObject>
    </g>
  );
};

const calculateElementsCoordinates = ({
  direction,
  height,
  uiDirection,
  width,
  x,
  y
}: {
  direction: 'horizontal' | 'vertical';
  height: number;
  uiDirection: 'ltr' | 'rtl';
  width: number;
  x: number;
  y: number;
}) => {
  const halfIconSize = iconSize / 2;
  const halfWidth = width / 2;

  if (direction === 'horizontal') {
    const textCoordinates = { x: x + halfWidth, y: y - halfIconSize };
    const iconCoordinates = { x: x + halfWidth - halfIconSize, y: y - iconSize * 2 };
    return { textCoordinates, iconCoordinates };
  }

  const adjustedY = y + height / 2;
  const adjustedX = x + width;
  const textCoordinates = { x: uiDirection === 'ltr' ? adjustedX + 20 : adjustedX - 20, y: adjustedY };
  const iconCoordinates = { x: uiDirection === 'ltr' ? adjustedX + 40 : adjustedX - 90, y: adjustedY - halfIconSize };

  return { textCoordinates, iconCoordinates };
};
