import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { Popover } from '@mantine/core';
import { Box } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { RichTextTools, RichTextExtraTools, ToolbarType } from 'shared/types/RichTextToolbar';

import * as classes from './RichTextEditorFixed.css';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { RichTextProps } from '@/widgets/_components/RichText';
import { RichTextToolbar } from '@/widgets/_components/RichTextToolbar';

export type RichTextEditorFixedProps = {
  tools?: (RichTextTools | RichTextExtraTools)[];
  withToolbar?: boolean;
  customControls?: ReactNode;
  disableTypographyInheritance?: boolean;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
};

export function RichTextEditorFixed(props: RichTextProps & RichTextEditorFixedProps) {
  const { tools, customControls, overrideTypographyStylesPath, changeContrastColorToMain } = props;
  const { uiDirection } = useUiLanguage();

  const { editor } = useRichTextEditorContext();
  const [editorContentRef, setEditorContentRef] = useState<HTMLDivElement | null>(null);
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);
  const [linkRef, setLinkRef] = useState<HTMLDivElement | null>(null);

  const [isToolbarOpened, { close, open }] = useDisclosure();

  const clickOutsideDepsMemoized = useMemo(
    () => [editorContentRef, toolbarRef, linkRef].filter(Boolean),
    [editorContentRef, toolbarRef, linkRef]
  );

  useClickOutside(
    () => {
      close();
    },
    null,
    clickOutsideDepsMemoized
  );

  useEffect(() => {
    editor?.on('focus', open);
    return () => {
      editor?.off('focus', open);
    };
  }, [editor, open]);

  return (
    <Popover
      withinPortal
      portalProps={{ target: '#rich-text-editor-portal' }}
      opened={isToolbarOpened}
      classNames={{ dropdown: classes.toolbarPopover[uiDirection] }}
      shadow="md"
    >
      <Popover.Dropdown id="text-editor-toolbar" data-testid="text-editor-toolbar">
        <RichTextEditor.Toolbar ref={setToolbarRef}>
          <RichTextToolbar
            editor={editor}
            editorContentRef={editorContentRef}
            setLinkRef={setLinkRef}
            type={ToolbarType.Fixed}
            tools={tools}
            overrideTypographyStylesPath={overrideTypographyStylesPath}
            changeContrastColorToMain={changeContrastColorToMain}
          >
            {customControls}
          </RichTextToolbar>
        </RichTextEditor.Toolbar>
      </Popover.Dropdown>
      <Popover.Target>
        <Box className={classes.content}>
          <RichTextEditor.Content ref={setEditorContentRef} />
        </Box>
      </Popover.Target>
    </Popover>
  );
}
