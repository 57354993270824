import { createClientAIModuleModule } from '@hendrx/ai-module/client';
import { createClientAuthenticationModule as createClientAuthenticationModuleGoogle } from '@hendrx/authentication-google/client';
import { createClientAuthenticationModule as createClientAuthenticationModuleMocked } from '@hendrx/authentication-mocked/client';
import { createClientAuthorizationModule } from '@hendrx/authorization-core/client';
import { createClientFileStorageModule } from '@hendrx/file-storage-cloudinary/client';
import { createClientLmsModule } from '@hendrx/lms-google/client';
import { createClientModules } from '@hendrx/modules';
import { createClientComponentsModule } from '@hendrx/ui-core';

createClientModules({
  aiModule: createClientAIModuleModule,
  authentication:
    process.env.NEXT_PUBLIC_MOCK_AUTHENTICATION === 'true'
      ? createClientAuthenticationModuleMocked
      : createClientAuthenticationModuleGoogle,
  authorization: createClientAuthorizationModule,
  ui: createClientComponentsModule,
  fileStorage: createClientFileStorageModule,
  lms: createClientLmsModule,
  xAPIEvents: () => ({})
});
