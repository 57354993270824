import isNil from 'lodash/isNil';
import { useTranslations } from 'next-intl';

import { ColorInputPanel } from '@/components/Theme/ColorInputPanel';
import { FontFamilySelectPanel } from '@/components/Theme/FontFamilySelectPanel';
import { FontSizeSelectPanel } from '@/components/Theme/FontSizeSelectPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { SwitchPanel } from '@/components/Theme/SwitchPanel';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function HeaderRow() {
  const t = useTranslations('components.theme.settings');

  const { document, id } = useContentObject();
  const [themeIsHeaderVisible] = useActivityThemeStylesProperty<boolean>(document, 'table.isHeaderVisible');
  const [isHeaderVisible, setIsHeaderVisible] = useWidgetStyle<boolean | null>(document, id, 'isHeaderVisible');

  const [themeHeaderBackgroundColor] = useActivityThemeStylesProperty<string | null>(
    document,
    'table.headerBackgroundColor'
  );
  const [globalHeaderBackgroundColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.primary.contrast'
  );
  const [headerBackgroundColor, setHeaderBackgroundColor] = useWidgetStyle<string | null>(
    document,
    id,
    'headerBackgroundColor'
  );

  const [themeHeaderFontFamily] = useActivityThemeStylesProperty<string | null>(document, 'table.headerFontFamily');
  const [globalHeaderFontFamily] = useActivityThemeStylesProperty<string>(document, 'global.content.fontFamily');
  const [headerFontFamily, setHeaderFontFamily] = useWidgetStyle<string | null>(document, id, 'headerFontFamily');
  const [themeHeaderColor] = useActivityThemeStylesProperty<string | null>(document, 'table.headerColor');
  const [globalHeaderColor] = useActivityThemeStylesProperty<string>(
    document,
    'global.content.colors.primary.contrast'
  );
  const [headerColor, setHeaderColor] = useWidgetStyle<string | null>(document, id, 'headerColor');
  const [themeHeaderFontSize] = useActivityThemeStylesProperty<number>(document, 'table.headerFontSize');
  const [headerFontSize, setHeaderFontSize] = useWidgetStyle<number | null>(document, id, 'headerFontSize');

  return (
    <ThemeItemSection label={t('headerRow')}>
      <SwitchPanel
        value={isHeaderVisible ?? themeIsHeaderVisible}
        onChange={setIsHeaderVisible}
        ResetButton={!isNil(isHeaderVisible) && <ResetButton onClick={() => setIsHeaderVisible(null)} />}
      />
      <ColorInputPanel
        label={t('backgroundColor')}
        color={headerBackgroundColor ?? themeHeaderBackgroundColor ?? globalHeaderBackgroundColor}
        setColor={setHeaderBackgroundColor}
        ResetButton={headerBackgroundColor && <ResetButton onClick={() => setHeaderBackgroundColor(null)} />}
      />
      <FontFamilySelectPanel
        label={t('family')}
        value={headerFontFamily ?? themeHeaderFontFamily ?? globalHeaderFontFamily}
        onChange={setHeaderFontFamily}
        ResetButton={headerFontFamily && <ResetButton onClick={() => setHeaderFontFamily(null)} />}
      />
      <FontSizeSelectPanel
        label={t('size')}
        value={headerFontSize ?? themeHeaderFontSize}
        onChange={setHeaderFontSize}
        ResetButton={headerFontSize && <ResetButton onClick={() => setHeaderFontSize(null)} />}
      />
      <ColorInputPanel
        label={t('color')}
        color={headerColor ?? themeHeaderColor ?? globalHeaderColor}
        setColor={setHeaderColor}
        ResetButton={headerColor && <ResetButton onClick={() => setHeaderColor(null)} />}
      />
    </ThemeItemSection>
  );
}
