import { Tabs, Textarea, TextInput } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  const t = useTranslations('widgets');
  const [title, setTitle] = useContentObjectProperty<string>('title');
  const [src, setSrc] = useContentObjectProperty<string>('src');
  const [errors] = useContentObjectErrors();

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <FormControl title={t('embedSettings.addTitleLabel')} withAsterisk>
          <TextInput
            value={title}
            onChange={event => setTitle(event.currentTarget.value)}
            error={errors.title && t('embedSettings.addTitleError')}
            placeholder={t('embedSettings.addTitlePlaceholder') ?? ''}
            data-testid="embed-title-field"
          />
        </FormControl>
        <FormControl title={t('embedSettings.addLinkOrCodeLabel')} withAsterisk>
          <Textarea
            autosize
            value={src}
            onChange={event => setSrc(event.currentTarget.value)}
            error={errors.src && t('embedSettings.linkOrCodeNotValidError')}
            placeholder={`${t('embedSettings.linkOrCodePlaceholder')}`}
            minRows={10}
            data-testid="iframe-src-field"
          />
        </FormControl>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
