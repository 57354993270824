import { clientModules } from '@hendrx/modules';
import sample from 'lodash/sample';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useState } from 'react';

export type RandomizedFeedback = {
  RandomizedIcon: FC;
  randomizedText: string;
  withAnimation: boolean;
};

export enum FeedbackMessages {
  NoAnswer = 'noAnswer',
  OnlyWrongAnswers = 'onlyWrongAnswers',
  NotAllCorrectAndNoWrongAnswers = 'notAllCorrectAndNoWrongAnswers',
  SomeCorrectAndSomeWrongAnswers = 'someCorrectAndSomeWrongAnswers',
  OnlyAllCorrectAnswers = 'onlyAllCorrectAnswers',
  ShowAnswer = 'showAnswer'
}

export type FeedbackMessageType = `${FeedbackMessages}`;

type FeedbackIconMap = Record<FeedbackMessageType, FC>;

function getFeedbackIconMap(): FeedbackIconMap {
  const assets = clientModules.ui.assets;
  return {
    onlyAllCorrectAnswers: assets.feedbackIcons.AnswerSuccess,
    onlyWrongAnswers: assets.feedbackIcons.AnswerFailure,
    noAnswer: assets.feedbackIcons.AnswerFailure,
    notAllCorrectAndNoWrongAnswers: assets.feedbackIcons.AnswerPartialSuccess,
    someCorrectAndSomeWrongAnswers: assets.feedbackIcons.AnswerPartialSuccess,
    showAnswer: assets.feedbackIcons.NoAnswer
  };
}

export const useRandomizedFeedback = (feedbackMessageType: FeedbackMessageType | null): RandomizedFeedback | null => {
  const t = useTranslations('widgets');

  const [randomizedFeedback, setRandomizedFeedback] = useState<RandomizedFeedback | null>(null);
  useEffect(() => {
    if (feedbackMessageType) {
      const RandomizedIcon = getFeedbackIconMap()[feedbackMessageType];
      const feedbackList = t.raw(`selectionQuestionWidget.feedbackMessages.${feedbackMessageType}`) as string[];
      const randomizedText = sample(feedbackList) || '';
      const withAnimation = feedbackMessageType === FeedbackMessages.OnlyAllCorrectAnswers;
      setRandomizedFeedback({ RandomizedIcon, randomizedText, withAnimation });
    }
  }, [feedbackMessageType, t]);

  return randomizedFeedback;
};
