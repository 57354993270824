import { Flex, Radio, Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { Fragment } from 'react';

import { OpenQuestionElementType } from 'shared/types/OpenQuestion';
import { TextEditorTypes } from 'shared/types/TextEditor';

import { useStyles } from './ElementsOptions.styles';
import { elementsOptions } from '@/widgets/OpenQuestion/OpenQuestion.utils';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

type ElementsOptionsProps = {
  elements: Record<OpenQuestionElementType, boolean>;
  onElementChange: (element: OpenQuestionElementType, state: boolean) => void;
};

export function ElementsOptions(props: ElementsOptionsProps) {
  const { elements, onElementChange } = props;
  const { classes } = useStyles();
  const t = useTranslations('widgets');
  const [textEditorType, setTextEditorType] = useContentObjectProperty<string>('textEditorType');

  const selectedElements = Object.keys(elements).filter(key => elements[key as OpenQuestionElementType]);

  return (
    <FormControl title={t('openQuestionSettings.answerType')} withSeparator>
      <Flex gap={12} direction="column">
        {Object.entries(elementsOptions).map(([element, elementData]) => (
          <Fragment key={element}>
            <Switch
              disabled={selectedElements.length === 1 && elements[element as OpenQuestionElementType]}
              checked={elements[element as OpenQuestionElementType]}
              onChange={event => onElementChange(element as OpenQuestionElementType, event.currentTarget.checked)}
              classNames={{
                body: classes.switchBody,
                label: classes.switchLabel
              }}
              label={
                <Flex gap={12} align="center">
                  <elementData.Icon />
                  {t(`openQuestionSettings.${element}`)}
                </Flex>
              }
            />
            {element === 'text' && elements.text && (
              <Radio.Group
                value={textEditorType}
                onChange={value => setTextEditorType(value)}
                classNames={{
                  root: classes.textEditors
                }}
              >
                <Flex gap={12}>
                  <Radio
                    value={TextEditorTypes.Simple}
                    label={t(`openQuestionSettings.textEditor.${TextEditorTypes.Simple}`)}
                  />
                  <Radio
                    value={TextEditorTypes.Rich}
                    label={t(`openQuestionSettings.textEditor.${TextEditorTypes.Rich}`)}
                  />
                </Flex>
              </Radio.Group>
            )}
          </Fragment>
        ))}
      </Flex>
    </FormControl>
  );
}
