import camelCase from 'lodash/camelCase';
import { useTranslations } from 'next-intl';
import { ComponentType, useCallback } from 'react';
import * as Y from 'yjs';

import { ManifestType } from 'shared/types/ManifestType';
import { ContentObjectType, getContentObjectsMap, useObservedProperty } from 'shared/widgetsSDK/yjs';
import { useConvertObservedYXmlFragment } from 'shared/widgetsSDK/yjs';

import { ConfigTitleBase } from './ConfigTitleBase';
import { useCurrentDocument } from '@/components/YjsProvider';
import { yXmlFragmentToText } from '@/utils/tiptap';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { widgetManifests, WidgetWithManifests } from '@/widgets/widgetManifests';

const configTitleSelector = (widget: ContentObjectType) => {
  const type = widget?.get('type') as WidgetWithManifests;
  const labelFragment = widget?.get('labelFragment') as Y.XmlFragment | undefined;
  const alt = widget?.get('alt') as string | undefined;
  const title = widget?.get('title') as string | undefined;
  const documentType = widget?.get('documentType') as string | undefined;
  const direction = widget?.get('direction') as string | undefined;

  return { type, labelFragment, title, alt, documentType, direction };
};
type ConfigTitleProps = {
  title?: string;
  Icon?: ComponentType<{ width: number; height: number }> | string | null;
};

export function ConfigTitle(props: ConfigTitleProps) {
  const { title: finalTitle, Icon } = props;
  const t = useTranslations('widgets');
  const [id] = useContentObjectProperty<string>('id');
  const document = useCurrentDocument();
  const [widgetData] = useObservedProperty(getContentObjectsMap(document), id, {
    deepObserve: true,
    selector: configTitleSelector
  });

  const { type, labelFragment, title, alt, documentType, direction } = widgetData;

  const labelText = useConvertObservedYXmlFragment({
    fragment: labelFragment,
    convertFunction: yXmlFragmentToText
  });

  const widgetManifest = widgetManifests[type];

  const getActivePreview = useCallback(
    (widgetManifest: ManifestType) => {
      return (
        widgetManifest.previewGroups.find(preview => {
          // Widgets like MicrosoftEmbed have multiple divider icons. To find the correct one, it's needed to pass a property to
          // find it in previewGroups array.
          //same goes for flexSection that has different directions
          if (documentType) {
            return preview.id.toLowerCase() === documentType.toLowerCase();
          } else if (direction) {
            return preview.id.toLowerCase() === direction.toLowerCase();
          }
        }) ?? widgetManifest.previewGroups[0]
      );
    },
    [documentType, direction]
  );
  let activePreviewIcon = null;
  let activePreviewTitle = '';

  if (!finalTitle || !Icon) {
    const activePreview = getActivePreview(widgetManifest);
    activePreviewIcon = typeof activePreview.divider !== 'boolean' ? activePreview.divider?.icon : null;
    const localeName = camelCase(type);
    activePreviewTitle = t(`${localeName}Manifest.${activePreview.id ?? localeName}`);
  }

  const DividerIcon = Icon ?? activePreviewIcon;
  const titleText = finalTitle ?? activePreviewTitle;

  const subtitle = labelText ?? finalTitle ?? title ?? alt;

  return <ConfigTitleBase Icon={DividerIcon} title={titleText} subtitle={subtitle} />;
}
