import { z } from 'zod';

import { GoToType } from 'shared/types/Image';

export const Schema = z
  .object({
    isDecorative: z.boolean().optional(),
    alt: z.string().optional(),
    src: z.string().url(),
    goToLink: z.string().url().optional().or(z.literal('')),
    goToPageTitle: z.string().optional(),
    goToLinkTitle: z.string().optional(),
    goToType: z.string().nullish().optional()
  })
  .superRefine((data, ctx) => {
    if (!data.isDecorative && data.alt?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'alt is required when isDecorative is true.',
        path: ['alt']
      });
    }

    if (data.goToType === GoToType.Page && data.goToPageTitle?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Page link title is required when go to is set to page.',
        path: ['goToPageTitle']
      });
    }

    if (data.goToType === GoToType.ExternalLink) {
      if (data.goToLink?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Link URL is required when when go to is set to external link.',
          path: ['goToLink']
        });
      }

      if (data.goToLinkTitle?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Link title is required when when go to is set to external link.',
          path: ['goToLinkTitle']
        });
      }
    }
  });

export type ImageSchemaType = z.infer<typeof Schema>;
