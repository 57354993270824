import { Box, Checkbox, Flex, Radio } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { ReactElement, useMemo } from 'react';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { AnswerStyle } from './AnswerStyle';
import * as classes from './AnswersStylePicker.css';
import { FormControl } from '@/widgets/_components/FormControl';

type Options = { value: AnswerStyleType; LabelComponent: ReactElement }[];

type AnswersStylePickerProps = {
  hasAudio: boolean;
  hasImage: boolean;
  hasText: boolean;
  onChange: (value: string) => void;
  options: AnswerStyleType[];
  value: string;
};

export function AnswersStylePicker(props: AnswersStylePickerProps) {
  const { onChange, value, hasAudio, hasImage, hasText } = props;
  const t = useTranslations('components.answersStylePicker');

  const options: Options = useMemo(() => {
    const options = [];
    if (props.options.includes(AnswerStyleType.Default)) {
      options.push({
        value: AnswerStyleType.Default,
        LabelComponent: <CheckboxStyleOption hasAudio={hasAudio} hasImage={hasImage} hasText={hasText} />
      });
    }
    if (props.options.includes(AnswerStyleType.Button)) {
      options.push({
        value: AnswerStyleType.Button,
        LabelComponent: <ButtonStyleOption hasAudio={hasAudio} hasImage={hasImage} hasText={hasText} />
      });
    }
    if (props.options.includes(AnswerStyleType.CheckboxWithoutBorder)) {
      options.push({
        value: AnswerStyleType.CheckboxWithoutBorder,
        LabelComponent: (
          <CheckboxStyleOption hasAudio={hasAudio} hasImage={hasImage} hasText={hasText} withBorder={false} />
        )
      });
    }

    return options;
  }, [hasAudio, hasImage, hasText, props.options]);

  return (
    <FormControl title={t('title')}>
      <Flex direction="column" justify="space-evenly">
        <Radio.Group value={value} onChange={onChange}>
          <Flex direction="column" gap={12}>
            {options.map(option => (
              <Radio
                classNames={{ label: classes.label, inner: classes.inner, labelWrapper: classes.labelWrapper }}
                label={
                  <Box className={option.value === value ? classes.optionBox.selected : classes.optionBox.base}>
                    {option.LabelComponent}
                  </Box>
                }
                value={option.value}
                key={option.value}
              />
            ))}
          </Flex>
        </Radio.Group>
      </Flex>
    </FormControl>
  );
}

type ButtonStyleOptionProps = { hasAudio: boolean; hasImage: boolean; hasText: boolean };

function ButtonStyleOption(props: ButtonStyleOptionProps) {
  const { hasAudio, hasImage, hasText } = props;
  return (
    <Box className={classes.buttonOption}>
      <AnswerStyle
        className={classes.buttonOptionAnswerStyle}
        hasAudio={hasAudio}
        hasImage={hasImage}
        hasText={hasText}
      />
    </Box>
  );
}

type CheckboxStyleOptionProps = { hasAudio: boolean; hasImage: boolean; hasText: boolean; withBorder?: boolean };

function CheckboxStyleOption(props: CheckboxStyleOptionProps) {
  const { withBorder = true, hasAudio, hasImage, hasText } = props;

  return (
    <Flex align="center" p={16}>
      <Checkbox classNames={{ input: classes.checkboxOption }} checked={false} readOnly />
      <AnswerStyle
        className={withBorder ? classes.checkboxOptionAnswerStyle.withBorder : classes.checkboxOptionAnswerStyle.base}
        hasAudio={hasAudio}
        hasImage={hasImage}
        hasText={hasText}
      />
    </Flex>
  );
}
