import debounce from 'lodash/debounce';
import { useEffect } from 'react';

import { useTable } from '@/widgets/Table/hooks/useTable';
import { CELL_RENDERER_CLASSNAME, RowId, calculateRowMinHeight } from '@/widgets/Table/utils';

/**
 * @description This hook is used to automatically resize the row height based on the content of a cell.
 * It handles scenarios where a cell is being resized (resizing a column causing text overflow)
 * or when content is being added and typed (causing text overflow from the row).
 *
 * @param rowId The ID of the row to be auto-resized.
 */
export const useAutoResizeRow = (rowId: RowId) => {
  const setRowHeights = useTable(context => context.setRowHeights);
  useEffect(() => {
    const updateRowHeight = () => {
      const rowMinHeight = calculateRowMinHeight(rowId);
      setRowHeights(currentHeights => {
        if (currentHeights[rowId] < rowMinHeight) {
          return {
            ...currentHeights,
            [rowId]: rowMinHeight
          };
        }
        return currentHeights;
      });
    };

    const debouncedUpdateRowHeight = debounce(updateRowHeight, 300);

    const mutationObserverCallback: MutationCallback = mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          debouncedUpdateRowHeight();
        }
      }
    };

    const resizeObserverCallback: ResizeObserverCallback = entries => {
      entries.forEach(() => {
        debouncedUpdateRowHeight();
      });
    };

    const observerOptions = {
      childList: true,
      subtree: true,
      characterData: true
    };

    const mutationObserver = new MutationObserver(mutationObserverCallback);
    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    const cellRenderers = document.querySelectorAll(`.${CELL_RENDERER_CLASSNAME}`);
    cellRenderers.forEach(cell => {
      mutationObserver.observe(cell, observerOptions);
      resizeObserver.observe(cell);
    });

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [rowId, setRowHeights]);
};
