import { WidgetType } from 'shared/utils/widgets';

import { StageElement } from '@/contentObjects/Plugin';
import { useDataAttributes } from '@/contentObjects/Plugin/hooks/useDataAttributes';
import { usePluginScriptContext } from '@/contentObjects/Plugin/utils/pluginScriptContext';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { ContentObjectProvider, useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function EvaluatablePluginAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();

  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.EvaluatablePlugin}
      withOrchestrationWrapper={false}
    >
      <EvaluatablePluginAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

function EvaluatablePluginAnswerPlayerImplementation() {
  const { id } = useContentObject();
  const script = usePluginScriptContext();
  const { contentLanguage, uiLanguage, isStudioEdit, viewMode, workMode, assignmentMode, role } = useDataAttributes();

  return (
    <StageElement
      id={id}
      script={script}
      isStudioEdit={isStudioEdit}
      viewMode={viewMode}
      workMode={workMode}
      assignmentMode={assignmentMode}
      role={role}
      contentLanguage={contentLanguage}
      uiLanguage={uiLanguage}
      isQuestion={true}
    />
  );
}
