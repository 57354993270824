import { ReactElement, createContext, useContext, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

type TiptapFillInTheBlanksOptionsProviderProps = {
  children: ReactElement;
};

type TiptapFillInTheBlanksOptionsStore = {
  nodesRemovalFunctions: Record<string, () => void>;
  setNodesRemovalFunctions: (id: string, nodeRemovalFunction: () => void) => void;
};

const TiptapFillInTheBlanksOptionsStoreContext = createContext<StoreApi<TiptapFillInTheBlanksOptionsStore> | null>(
  null
);

export function TiptapFillInTheBlanksOptionsProvider(props: TiptapFillInTheBlanksOptionsProviderProps) {
  const { children } = props;

  const tiptapFillInTheBlanksOptionsStore = useMemo(
    () =>
      createStore<TiptapFillInTheBlanksOptionsStore>(set => ({
        nodesRemovalFunctions: {},
        setNodesRemovalFunctions: (id, nodeRemovalFunction) =>
          set(state => ({
            nodesRemovalFunctions: {
              ...state.nodesRemovalFunctions,
              [id]: nodeRemovalFunction
            }
          }))
      })),
    []
  );

  return (
    <TiptapFillInTheBlanksOptionsStoreContext.Provider value={tiptapFillInTheBlanksOptionsStore}>
      {children}
    </TiptapFillInTheBlanksOptionsStoreContext.Provider>
  );
}

export function useTiptapFillInTheBlanksOptionsStore<T = TiptapFillInTheBlanksOptionsStore>(
  selector: (state: TiptapFillInTheBlanksOptionsStore) => T
) {
  const store = useContext(TiptapFillInTheBlanksOptionsStoreContext);
  if (store === null) throw new Error('Context setup is incorrect');
  return useStore(store, selector);
}
