import { Box, Flex, Text } from '@mantine/core';
import { useClickOutside, useDisclosure, useHover, useMergedRef } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { useDrag } from 'react-dnd';

import { useStyles } from './QuestionBank.styles';
import { QuestionCardActions } from './QuestionCardActions';
import { MenuHandle } from '@/icons/MenuHandle';
import { questionListDndType } from '@/widgets/QuestionGroupList/config/design/QuestionBank/QuestionBank';
import { DragHandle } from '@/widgets/_components/widget/DragHandle';

type QuestionCardProps = {
  questionData: { id: string; type: string };
  positionIndex: number;
  isQuestionRequired: boolean;
  onDeleteQuestion: (positionIndex: number, itemId: string) => void;
  onDuplicateQuestion: (positionIndex: number, itemId: string) => void;
};

export function QuestionCard(props: QuestionCardProps) {
  const { questionData, positionIndex, isQuestionRequired, onDeleteQuestion, onDuplicateQuestion } = props;
  const { classes } = useStyles({});
  const [showMenu, { toggle: toggleShowMenu, close: closeMenu }] = useDisclosure(false);

  const t = useTranslations('components');

  const clickOutsideRef = useClickOutside(closeMenu);
  const { ref: hoverRef, hovered: isCardHovered } = useHover();
  const containerRefs = useMergedRef(clickOutsideRef, hoverRef);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: questionListDndType,
      item: questionData,
      collect(monitor) {
        return { isDragging: monitor.isDragging() };
      }
    }),
    []
  );

  return (
    <Box
      key={questionData.id}
      className={classes.itemCard}
      ref={containerRefs}
      sx={{ opacity: isDragging ? '0%' : '100%' }}
      data-testid="question-bank-item"
    >
      <Flex className={classes.itemCardTop} align="center" direction="row">
        {isCardHovered && <DragHandle orientation="side" strength={5} offset={36} ref={drag} variant="hover" />}
        <Box className={classes.numberOrder} data-testid="question-bank-order-number">
          {positionIndex + 1}
        </Box>
        <Text className={classes.questionBankCardTitle}>{t(`questionBank.${questionData.type}`)}</Text>
        {isQuestionRequired && <Text c="accent">*</Text>}
        {isCardHovered && (
          <Box className={classes.menuHandleIcon} onClick={toggleShowMenu} data-testid="question-bank-item-menu-icon">
            <MenuHandle />
          </Box>
        )}
      </Flex>
      {showMenu && (
        <QuestionCardActions
          itemId={questionData.id}
          onDeleteQuestion={() => onDeleteQuestion(positionIndex, questionData.id)}
          onDuplicateQuestion={() => onDuplicateQuestion(positionIndex, questionData.id)}
        />
      )}
    </Box>
  );
}
