import * as Y from 'yjs';

import { evaluateQuestionByType } from './evaluateQuestionByType';
import { extractDataMaps, getQuestionData } from './questionDataUtils';

export function evaluateQuestion({
  document,
  questionId,
  payload
}: {
  document: Y.Doc;
  questionId: string;
  payload: unknown;
}) {
  const { contentObjectsMap, sensitiveDataMap, widgetsFormDataMap } = extractDataMaps(document);
  const { type, contentObjectData, sensitiveData, widgetFormData } = getQuestionData(
    questionId,
    contentObjectsMap,
    sensitiveDataMap,
    widgetsFormDataMap
  );

  return evaluateQuestionByType({
    type,
    payload,
    contentObjectData,
    sensitiveData,
    widgetFormData,
    contentObjectsMap,
    sensitiveDataMap,
    widgetsFormDataMap
  });
}
