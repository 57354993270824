import { Box } from '@mantine/core';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';

import { CardType } from '@/widgets/OpenDiscussion/player/TeacherView/Card';

type DropzoneProps = {
  columnIndex: number;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number, dragColumnIndex: number, hoverColumnIndex: number) => void;
};

export const Dropzone = (props: DropzoneProps) => {
  const { columnIndex: hoverColumnIndex, index: hoverIndex, moveCard } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(() => ({
    accept: 'AnswerCard',
    drop(item: CardType) {
      if (!ref.current) {
        return;
      }

      const { index: dragIndex, columnIndex: dragColumnIndex } = item;

      moveCard(dragIndex, hoverIndex, dragColumnIndex, hoverColumnIndex);

      item.index = hoverIndex;
      item.columnIndex = hoverColumnIndex;
    }
  }));

  drop(ref);

  return <Box ref={ref} h="100%" />;
};
