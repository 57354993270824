import { CreateThemeDto, ThemeType } from '@hendrx/modules/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';

import { createTheme, getAllThemeNames, getThemeById, updateTheme } from './themeApi';

export type ThemeNames = { _id: string; name: string }[];

export const useFetchAllThemeNames = () => {
  return useQuery<ThemeNames>({
    queryKey: ['themes', 'list'],
    queryFn: () => getAllThemeNames()
  });
};

export const useTheme = (themeId?: string | null) => {
  return useQuery<ThemeType>({
    queryKey: ['theme', { themeId }],
    queryFn: () => getThemeById(themeId as string),
    enabled: !!themeId
  });
};

type UseUpdateThemeArgs = {
  onSuccess: () => void;
  onError: () => void;
};

export function useUpdateTheme(props: UseUpdateThemeArgs) {
  const { onSuccess, onError } = props;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTheme,
    onSuccess: async (_, { themeId }) => {
      await queryClient.invalidateQueries(['theme', { themeId }]);
      onSuccess();
    },
    onError
  });
}

export function useCreateTheme(
  createThemeDto: CreateThemeDto,
  { onSuccess, onError }: { onSuccess: (themeId: string) => void; onError: () => void }
) {
  const queryClient = useQueryClient();

  return useMutation<ThemeType>({
    mutationFn: () => createTheme(createThemeDto),
    onSuccess: ({ _id, name }) => {
      void queryClient.setQueryData<ThemeNames>(['themes', 'list'], oldList =>
        sortBy([...(oldList ?? []), { _id, name }], ['name'])
      );
      onSuccess(_id);
    },
    onError
  });
}
