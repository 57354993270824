import { z } from 'zod';

import { trimUnknown } from '@/utils/trimString';

const correctOptionSchema = z.object({
  id: z.string().nonempty(),
  value: z.preprocess(trimUnknown, z.string().nonempty())
});

export const SensitiveDataSchema = z.object({
  inputCorrectOptions: z.record(z.array(correctOptionSchema)),
  selectCorrectOptions: z.record(correctOptionSchema)
});
