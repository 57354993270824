import { ReactNodeViewRenderer } from '@tiptap/react';

import { BaseClozeExtension } from 'shared/tiptap/BaseClozeExtension';

import { ClozeOption } from './ClozeOption';

export const ClozeExtension = BaseClozeExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(ClozeOption);
  }
});
