import { useQuery } from '@tanstack/react-query';

import { Plugin } from 'shared/types/dto/Plugin';

import { getPluginById, getListByCategory } from '@/queries/pluginApi';

export const useListPlugins = (category: string) => {
  return useQuery<Omit<Plugin, 'script'>[]>({
    queryKey: ['listPlugins', { category }],
    queryFn: () => getListByCategory(category)
  });
};

export const useGetPluginById = (id: string) => {
  return useQuery<Plugin>({
    queryKey: ['useGetPluginById', { id }],
    queryFn: () => getPluginById(id)
  });
};
