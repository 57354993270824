import { Box, Switch, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useStyles } from './SubmitPageReturnToFixSwitch.styles';
import { Manifest } from '@/widgets/SubmitPageSatisfactionSurvey/Manifest';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function SubmitPageReturnToFixSwitch(props: StudioProps) {
  const { initialize } = props;
  const t = useTranslations('widgets');
  const [toFix, setToFix] = useContentObjectProperty<boolean>('toFix');

  const { classes } = useStyles();

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <Box className={classes.container}>
      <Text>{t('submitPageReturnToFixSwitch.returnToFix')}</Text>
      <Switch checked={toFix} onChange={event => setToFix(event.currentTarget.checked)} />
    </Box>
  );
}
