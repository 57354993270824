import type { ActivityThemeStyles } from '@hendrx/modules/ui';
import type { MantineTheme } from '@mantine/core';

import { DEFAULT_THEME } from './_mantineClone/defaultTheme';
import { mergeMantineTheme } from './_mantineClone/mergeMantineTheme';
import { themeToVars } from './themeToVars';
// TODO: Workaround for vanilla extract plugin bundling bug: https://github.com/vanilla-extract-css/vanilla-extract/issues/1043
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries, boundaries/no-unknown, import/no-relative-parent-imports
import { defaultActivityThemeStyles } from '../../../../ui-core/src/themes/defaultActivityTheme';
import { theme } from '@/utils/theme.utils';

export const vars = themeToVars(
  mergeMantineTheme(DEFAULT_THEME, {
    ...theme,
    other: defaultActivityThemeStyles
  }) as MantineTheme & {
    other: ActivityThemeStyles;
  }
);
