import { useMantineTheme } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { memo, useEffect, useMemo } from 'react';

import { useStyles } from './Table.styles';
import { TableBody } from './TableBody';
import { TableCaption } from './TableCaption';
import { TableColumnMenuAreas } from './TableColumnMenuAreas';
import { TableHead } from './TableHead';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { Config } from '@/widgets/Table/config';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CSS_VARS, getColumns, getRows } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const TableImplementation = memo(function TableImplementation() {
  const { ref: tableRef, width: tableWidth } = useElementSize();
  const rowOrder = useTable(context => context.rowOrder);
  const columnOrder = useTable(context => context.columnOrder);
  const columnWidths = useTable(context => context.columnWidths);
  const setColumnWidths = useTable(context => context.setColumnWidths);
  const { document, id } = useContentObject();
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const data = useMemo(() => {
    return getRows(rowOrder, columnOrder);
  }, [columnOrder, rowOrder]);

  const columns = useMemo(() => {
    return getColumns(columnOrder, true);
  }, [columnOrder]);

  const { classes } = useStyles();

  const {
    other: {
      table: { isCaptionVisible: themeIsCaptionVisible, isHeaderVisible: themeIsHeaderVisible }
    }
  } = useMantineTheme();
  const [isCaptionVisible] = useWidgetStyle<boolean | null>(document, id, 'isCaptionVisible');
  const [isHeaderVisible] = useWidgetStyle<boolean | null>(document, id, 'isHeaderVisible');

  const { contentDirection } = useContentLanguage();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    columnResizeDirection: contentDirection,
    state: {
      columnSizing: columnWidths
    },
    onColumnSizingChange: setColumnWidths
  });

  useEffect(() => {
    if (tableRef.current) {
      const columnSizeVars = columnOrder.reduce((sizes, columnId) => {
        sizes[CSS_VARS.ColumnSize(columnId)] = columnWidths[columnId] * (tableWidth / 1180);
        return sizes;
      }, {} as { [key: string]: number });

      Object.entries(columnSizeVars).forEach(([key, value]) => {
        tableRef.current.style.setProperty(key, value);
      });
    }
  }, [tableWidth, columnWidths, columnOrder, tableRef]);

  return (
    <div className={customClassNames} ref={tableRef}>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      <div className={classes.divTable}>
        <TableColumnMenuAreas headerGroups={table.getHeaderGroups()} />
        {(isCaptionVisible ?? themeIsCaptionVisible) && <TableCaption />}
        {(isHeaderVisible ?? themeIsHeaderVisible) && <TableHead headerGroups={table.getHeaderGroups()} />}
        <TableBody rows={table.getRowModel().rows} />
      </div>
    </div>
  );
});
