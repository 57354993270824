import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  weightScoreInput: {
    width: rem(52),
    marginInlineEnd: rem(8)
  },
  extendedOptions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: rem(8)
  },
  activityScoreWeight: {
    color: theme.colors.gray[6]
  }
}));
