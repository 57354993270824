import { z } from 'zod';

const validatePayloadFactory =
  <T extends z.ZodTypeAny>(schema: T) =>
  (data: unknown): z.infer<T> => {
    const result = schema.safeParse(data);
    if (!result.success) {
      throw new Error('Invalid payload');
    }
    return result.data;
  };

export const validatePayload = <T extends z.ZodTypeAny>(payload: unknown, schema: T) =>
  validatePayloadFactory(schema)(payload);
