import * as Y from 'yjs';

import { getContentObjectsMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const useIsQuestionEmpty = () => {
  const { document } = useContentObject();
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');
  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<unknown>>(contentObjectsMap, verticalSectionId);
  const [verticalSectionChildren] = useObservedArray<string>(verticalSection, 'children');
  return verticalSectionChildren.length === 1;
};
