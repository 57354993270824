import { Box, Title } from '@mantine/core';
import { useTranslations } from 'next-intl';
import * as Y from 'yjs';

import { ToolbarType } from 'shared/types/RichTextToolbar';

import * as classes from './HintModal.css';
import { Modal } from '@/components/Modal';
import { RichText } from '@/widgets/_components/RichText';

type HintModalProps = {
  hintFragment?: Y.XmlFragment;
  opened: boolean;
  close: () => void;
  isHintEditable?: boolean;
};

export function HintModal(props: HintModalProps) {
  const { hintFragment, opened, close, isHintEditable } = props;
  const t = useTranslations('widgets');

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      classNames={{
        content: classes.modal,
        body: classes.modalBody,
        header: classes.modalHeader
      }}
      closeButtonProps={{ className: classes.closeButton, iconSize: 24 }}
      dataTestPrefix="hint-modal"
    >
      <Box className={classes.modalBodyInner}>
        <Title order={3} ta="center" className={classes.modalTitle}>
          {t('hintModal.title')}
        </Title>
        <RichText
          dataTestIdPrefix="hint"
          editable={isHintEditable}
          toolbarType={isHintEditable ? ToolbarType.Inline : ToolbarType.None}
          fragment={hintFragment}
          placeholder={`${t('hintModal.placeholder')}`}
          className={isHintEditable ? classes.richText : ''}
        />
      </Box>
    </Modal>
  );
}
