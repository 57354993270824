import { MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, MS_WORD_MIME_TYPE } from '@mantine/dropzone';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { MicrosoftDocumentType } from 'shared/types/MicrosoftEmbed';

import { useFileUpload } from '@/queries/fileUpload';
import { Manifest } from '@/widgets/MicrosoftEmbed/Manifest';
import { microsoftDocumentIcons } from '@/widgets/MicrosoftEmbed/icons';
import { createOfficeWebViewerUrl } from '@/widgets/MicrosoftEmbed/utils/createOfficeWebViewerUrl';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';
import { UploadDropzone } from '@/widgets/_components/widget/UploadDropzone';

type BaseProps = {
  src: string;
  title: string;
  setSrc: (src: string) => void;
  documentType: MicrosoftDocumentType;
};

export const Base = forwardRef<() => void | null, BaseProps>((props, ref) => {
  const { src, setSrc, title, documentType } = props;

  const t = useTranslations('widgets');
  const { mutateAsync: uploadFile, isLoading } = useFileUpload();

  const isDocumentReady = Boolean(src && !isLoading);

  return (
    <>
      {isDocumentReady && <EmbedIframe workMode="Edit" title={title} src={createOfficeWebViewerUrl(src)} />}

      <UploadDropzone
        Icon={microsoftDocumentIcons[documentType]}
        src={src}
        isReady={isDocumentReady}
        maxSize={Manifest.additionalData?.maxFileSizeBytes}
        maxFiles={Manifest.additionalData?.maxFiles}
        accept={[...MS_POWERPOINT_MIME_TYPE, ...MS_WORD_MIME_TYPE, ...MS_EXCEL_MIME_TYPE]}
        placeholderText={t(`documentType.${documentType}.idleText`)}
        upload={uploadFile}
        dropAction={({ src }) => setSrc(src)}
        dropError={t('microsoftEmbedWidget.unableUpload')}
        ref={ref}
      />
    </>
  );
});

Base.displayName = 'Base';
