import { ColorSwatch, Group, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

import * as classes from './ColorSwatches.css';
import { DarkCheck } from '@/icons/DarkCheck';

export const initialCardColor = '#ffffff';

type ColorSwatchesProps = {
  cardColor: string;
  editCardColor: (newColor: string) => Promise<void> | undefined;
};

export function ColorSwatches(props: ColorSwatchesProps) {
  const { cardColor, editCardColor } = props;
  const { colors } = useMantineTheme();

  const colorsList = useMemo(() => {
    return [
      { color: initialCardColor, borderColor: colors.gray[6] },
      { color: colors.violet[1], borderColor: colors.violet[8] },
      { color: colors.blue[1], borderColor: colors.blue[3] },
      { color: colors.pink[1], borderColor: colors.pink[5] },
      { color: colors.yellow[2], borderColor: colors.yellow[6] },
      { color: colors.cyan[1], borderColor: colors.cyan[3] },
      { color: colors.lime[2], borderColor: colors.lime[6] },
      { color: colors.orange[2], borderColor: colors.orange[8] },
      { color: colors.grape[1], borderColor: colors.grape[4] }
    ];
  }, [colors]);

  return (
    <Group justify="center" gap={8} my={16}>
      {colorsList.map(listElement => {
        const { color, borderColor } = listElement;
        const isChecked = color === cardColor;

        return (
          <ColorSwatch
            key={color}
            w={20}
            h={20}
            component="button"
            color={color}
            onClick={() => editCardColor(color)}
            withShadow={false}
            classNames={{ root: isChecked ? classes.root.checked : classes.root.base }}
            style={{ borderColor }}
          >
            {isChecked && <DarkCheck />}
          </ColorSwatch>
        );
      })}
    </Group>
  );
}
