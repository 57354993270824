import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    numberInput: {
      display: 'flex',
      alignItems: 'center',
      gap: rem(10)
    },
    choiceQuestionSwitch: {
      marginBottom: rem(16)
    }
  };
});
