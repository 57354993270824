import join from 'lodash/join';
import uniq from 'lodash/uniq';
import { useCallback } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

export function useWidgetCustomCSSClasses(widgetId: string, document: Y.Doc) {
  const rootMap = getRootMap(document);

  const selector = useCallback(
    (data: Y.Map<string[]>) => {
      return data.get(widgetId);
    },
    [widgetId]
  );

  const [widgetCustomCSSClasses] = useObservedProperty<string[] | undefined>(rootMap, 'widgetsCustomCSSClasses', {
    deepObserve: true,
    selector
  });

  return widgetCustomCSSClasses;
}

export function useJoinedWidgetCustomCSSClasses(widgetId: string, document: Y.Doc) {
  return join(useWidgetCustomCSSClasses(widgetId, document), ' ');
}

export const getUniqueClassesListFromJSON = (allCustomCSSClassesJSON: Record<string, string[]>) => {
  return Array.from(new Set(Object.values(allCustomCSSClassesJSON).flat()));
};

export const getNewCustomClasses = (newClasses: string, existingClasses: string[]) => {
  const trimmed = newClasses.trim();
  const splitByWhitespace = trimmed ? trimmed.split(/\s+/g) : [];
  return uniq([...existingClasses, ...splitByWhitespace]);
};

export const useAllCustomCSSClasses = (document: Y.Doc) => {
  const rootMap = getRootMap(document);
  const [allCustomCSSClasses] = useObservedProperty<Y.Map<string[]>>(rootMap, 'widgetsCustomCSSClasses', {
    deepObserve: true
  });
  return allCustomCSSClasses;
};
