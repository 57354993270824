import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import { Box } from '@mantine/core';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';

import { useStyles } from './Player.styles';
import { useGetNestLevel } from '@/contentObjects/Root/hooks/useGetNestLevel';
import { DndSection } from '@/widgets/FlexSection/DndSection';
import { useFlexSectionStyle } from '@/widgets/FlexSection/hooks/useFlexSectionStyle';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type PlayerProps = {
  alignItems?: string;
  className?: string;
  direction?: FlexSectionDirection;
  gap?: number;
  itemsOrder?: Record<string, number>;
  questionIds?: string[];
  shouldApplyNestStyles?: boolean;
};

export function Player(props: PlayerProps) {
  const {
    alignItems,
    className,
    direction: propsDirection,
    gap,
    itemsOrder,
    questionIds,
    shouldApplyNestStyles = true
  } = props;

  const { id, document } = useContentObject();

  const nestLevel = useGetNestLevel(document, id, WidgetType.FlexSection);

  const [sectionDirection] = useContentObjectProperty<FlexSectionDirection>('direction');
  const [backgroundColor] = useFlexSectionStyle(document, id, 'backgroundColor');
  const [padding] = useFlexSectionStyle<number>(document, id, 'padding');
  const [border] = useFlexSectionStyle<BorderThemeProps>(document, id, 'border');
  const [borderRadius] = useFlexSectionStyle<BorderRadiusThemeProps>(document, id, 'borderRadius');
  const questionGroupSettings = useParentQuestionGroupSettings();

  const direction = propsDirection ?? sectionDirection;

  const { classes, cx } = useStyles({
    backgroundColor,
    border,
    borderRadius,
    direction,
    nestLevel,
    padding,
    shouldApplyNestStyles,
    dense: !!questionGroupSettings
  });

  return (
    <Box className={cx(classes.dndSectionWrapperStyle, className)}>
      <DndSection
        alignItems={alignItems}
        direction={direction}
        gap={gap}
        id={id}
        itemsOrder={itemsOrder}
        questionIds={questionIds}
      />
    </Box>
  );
}
