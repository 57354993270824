import { PieLabel } from 'recharts';

export const PieChartLabel: PieLabel = props => {
  const { cx, cy, midAngle = 0, innerRadius = 0, outerRadius = 0, percent } = props;
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

  return (
    <text
      x={cx + radius * Math.cos(-midAngle * RADIAN)}
      y={cy + radius * Math.sin(-midAngle * RADIAN)}
      textAnchor="middle"
      dominantBaseline="middle"
      fill="#ffffff"
      fontSize={30}
      fontWeight="bold"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
