import { ManifestAdditionalData } from 'shared/types/ManifestType';
import { DND_CELL_WIDTH_PX, roundHeight } from 'shared/widgetsSDK/Stage.utils';

export const getRoundedMinWidth = (minWidth = 0) => {
  // Round to the next full cell
  return Math.ceil(minWidth / DND_CELL_WIDTH_PX) * DND_CELL_WIDTH_PX;
};

export const getRoundedMinHeight = (minHeight = 0) => {
  return roundHeight(minHeight);
};

export function calculateConstraints(constraints?: ManifestAdditionalData['constraints']) {
  const minWidth = getRoundedMinWidth(constraints?.minWidth);
  const minHeight = getRoundedMinHeight(constraints?.minHeight);

  return { minWidth, minHeight };
}
