import { createStyles } from '@mantine/emotion';

type ClozeOptionStyleProps = {
  fontFamily?: string;
  fontSize?: string;
};

export const useStyles = createStyles((theme, props: ClozeOptionStyleProps) => {
  const { fontFamily, fontSize } = props;

  const globalThemeContentColors = theme.other.global.content.colors;
  const fitbQuestionBlankTheme = theme.other.learningItems.fillInTheBlanks.blank;

  return {
    componentWithContent: {
      display: 'inline-block',
      width: 'fit-content',
      height: '100%',
      fontFamily,
      fontSize: fontSize,
      mark: {
        backgroundColor: fitbQuestionBlankTheme.backgroundColorHovered ?? globalThemeContentColors.primary.light,
        color: fitbQuestionBlankTheme.color ?? globalThemeContentColors.secondary.contrast
      }
    }
  };
});
