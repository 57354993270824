import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { Feedbacks } from 'shared/types/Feedbacks';
import { GroupCheckButtonMode, QuestionListBulletTypes } from 'shared/types/QuestionGroupList';

import { BaseEvaluationResult } from '@/queries/evaluation';

export type QuestionGroupListSettings = {
  choiceQuestionsEnabled: boolean;
  feedback: `${Feedbacks}`;
  groupCheckButtonMode: `${GroupCheckButtonMode}`;
  scoreWeight: number;
  showEachQuestionGrade: boolean;
  subQuestionsOrder: string[];
  questionListBulletType: QuestionListBulletTypes;
};

export const QuestionGroupListSettingsContext = createContext<QuestionGroupListSettings | null>(null);

export const useParentQuestionGroupSettings = () => {
  const settings = useContext(QuestionGroupListSettingsContext);
  return settings;
};

export type QuestionGroupListEvaluation = Record<string, BaseEvaluationResult>;

export const QuestionGroupListEvaluationContext = createContext<QuestionGroupListEvaluation | null>(null);

export const useParentQuestionGroupEvaluation = () => {
  return useContext(QuestionGroupListEvaluationContext);
};

export enum QuestionGroupListAction {
  Check = 'check',
  Reset = 'reset',
  ShowAnswers = 'showAnswers'
}

export type QuestionGroupListActionContextType = {
  action: QuestionGroupListAction | null;
  setAction: Dispatch<SetStateAction<QuestionGroupListAction | null>>;
};

export const QuestionGroupListActionContext = createContext<QuestionGroupListActionContextType | null>(null);

export const useParentQuestionGroupAction = () => {
  return useContext(QuestionGroupListActionContext);
};
