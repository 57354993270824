import { Flex, Radio, Stack, Switch, Text, Tooltip } from '@mantine/core';
import { has } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useRef, useState } from 'react';
import * as Y from 'yjs';

import { ClozeOption } from 'shared/types/ClozeQuestion';
import { generateDefaultOption } from 'shared/utils/initializeCloze';

import { useStyles } from './ClozeAccordionItem.styles';
import { InputCloze } from './InputCloze';
import { InputClozePlaceholder } from './InputClozePlaceholder';
import { Accordion } from '@/components/Accordion';
import { ErrorTriangle } from '@/icons/ErrorTriangle';
import { useOverflowText } from '@/utils/useOverflowText';
import { ClozeSchemaType } from '@/widgets/ClozeQuestion/Schema';
import { useClozeOptions } from '@/widgets/ClozeQuestion/hooks/useClozeOptions';
import { useCorrectClozeOption } from '@/widgets/ClozeQuestion/hooks/useCorrectClozeOption';
import {
  useContentObject,
  useContentObjectArray,
  useContentObjectErrors,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

type ClozeAccordionItemSelectProps = { clozeId: string; index: number };

export function ClozeAccordionItemSelect(props: ClozeAccordionItemSelectProps) {
  const { clozeId, index } = props;

  const t = useTranslations('widgets.clozeQuestionSettings');

  const { classes } = useStyles();

  const { document } = useContentObject();

  const [, observedPossibleOptions] = useContentObjectArray<Y.Map<unknown>>(`selectPossibleOptions.${clozeId}`);

  const { withRandomAnswer, possibleOptions } = useClozeOptions(clozeId);
  const correctOption = useCorrectClozeOption(clozeId);

  const [, setCorrectOption] = useContentObjectProperty<Y.Map<unknown>>(
    `selectCorrectOptions.${clozeId}`,
    'sensitiveData'
  );
  const [, setWithRandomAnswer] = useContentObjectProperty<boolean | undefined>(`withRandomAnswers.${clozeId}`);

  const handleChangeCorrectOption = (option: ClozeOption) => {
    console.log('TEST optionoptionoption', option);
    setCorrectOption(() => {
      const newCorrectOption = new Y.Map();
      newCorrectOption.set('id', option.id);
      newCorrectOption.set('value', option.value);
      return newCorrectOption;
    });
  };

  const handleRemoveOptions = (indexToRemove: number) => {
    if (possibleOptions.length < 3) return;

    document.transact(() => {
      const optionToRemove = possibleOptions[indexToRemove];
      const isOptionToRemoveCorrect = optionToRemove.id === correctOption?.id;
      if (isOptionToRemoveCorrect) {
        const previousOption = possibleOptions[indexToRemove - 1];
        const nextOption = possibleOptions[indexToRemove + 1];
        const optionToSet = previousOption ?? nextOption;

        handleChangeCorrectOption(optionToSet);
      }

      observedPossibleOptions?.delete(indexToRemove);
    });
  };

  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);
  const addNewOption = useCallback(
    (value: string) => {
      observedPossibleOptions.push([generateDefaultOption(value)]);
      setFocusedOptionIndex(observedPossibleOptions.length - 1);
    },
    [observedPossibleOptions]
  );

  const previewTextRef = useRef<HTMLDivElement>(null);
  const overflowPreviewText = useOverflowText(previewTextRef);

  const [errors] = useContentObjectErrors<ClozeSchemaType>();

  return (
    <>
      <Accordion.Control>
        {
          <Tooltip label={overflowPreviewText} disabled={!overflowPreviewText} maw={400} multiline>
            <Flex ref={previewTextRef}>
              <Text truncate className={classes.previewText}>
                {index + 1}. {correctOption?.value}
              </Text>
              {has(errors, ['selectPossibleOptions', clozeId]) && <ErrorTriangle />}
            </Flex>
          </Tooltip>
        }
      </Accordion.Control>
      <Accordion.Panel p={0} pt={4}>
        <FormControl title={t('randomizeAnswers')} withSeparator>
          <Switch
            checked={withRandomAnswer}
            onChange={event => setWithRandomAnswer(event.currentTarget.checked)}
            label={withRandomAnswer ? t('on') : t('off')}
          />
        </FormControl>
        <FormControl title={t('answersOptions')}>
          {has(errors, ['selectPossibleOptions', clozeId]) && (
            <Text size="xs" c="red" mb={8} mt={-8}>
              {t('answersOptionsUniqueError')}
            </Text>
          )}
          <Stack>
            {possibleOptions.map((option, index) => {
              const optionId = option.id;
              if (!optionId) return null;

              return (
                <Flex
                  key={optionId}
                  className={classes.wrapper}
                  data-testid="studio-cloze-question-select-option-wrapper"
                >
                  <Radio
                    checked={optionId === correctOption?.id}
                    onChange={() => handleChangeCorrectOption(option)}
                    data-testid="studio-cloze-question-select-answer-radio"
                  />
                  <InputCloze
                    autoFocus={focusedOptionIndex === index}
                    index={index}
                    isDisabled={possibleOptions.length < 3}
                    onDelete={() => handleRemoveOptions(index)}
                    propertyPath={`selectPossibleOptions.${clozeId}.${index}.value`}
                    onChange={value => {
                      if (optionId === correctOption?.id) {
                        handleChangeCorrectOption({ value, id: optionId });
                      }
                    }}
                  />
                </Flex>
              );
            })}
            <Flex className={classes.wrapper} data-testid="studio-cloze-question-select-option-wrapper">
              <Radio className={classes.nonClickable} />
              <InputClozePlaceholder onChange={addNewOption} />
            </Flex>
          </Stack>
        </FormControl>
      </Accordion.Panel>
    </>
  );
}
