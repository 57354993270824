import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    wrapper: {
      gap: rem(12),
      alignItems: 'center'
    },
    nonClickable: {
      pointerEvents: 'none'
    },
    previewText: {
      marginInlineEnd: rem(8)
    }
  };
});
