import { Editor } from '@tiptap/react';

import { TextLevelSelect } from './TextLevelSelect';

const textLevels: { value: string; label: string }[] = [
  { value: '1', label: 'H1' },
  { value: '2', label: 'H2' },
  { value: '3', label: 'H3' },
  { value: '4', label: 'H4' }
];

export type TextLevelOnlySelectProps = {
  editor: Editor | null;
};

export function TextLevelOnlySelect(props: TextLevelOnlySelectProps) {
  const { editor } = props;

  return <TextLevelSelect textLevels={textLevels} editor={editor} />;
}
