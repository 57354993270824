import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => {
  return {
    errorIcon: {
      position: 'absolute',
      width: rem(36),
      height: rem(36),
      margin: rem(8),
      zIndex: 1,
      insetInlineEnd: 0,
      top: 0
    },
    '> :first-of-type': {
      height: '100%',
      width: '100%'
    }
  };
});
