import { z } from 'zod';

import { Schema as AudioSchema } from '@/widgets/Audio/Schema';
import { Schema as ImageSchema } from '@/widgets/Image/Schema';

const AnswersSchema = z.array(
  z.object({
    text: z.object({ fragment: z.string() }).optional(),
    audio: AudioSchema.optional(),
    image: ImageSchema.optional(),
    video: z.object({}).optional() // TODO: replace z.object() with proper schema after adding a video widget,
  })
);

const BaseSchema = z.object({
  answers: AnswersSchema,
  answerElements: z.array(z.enum(['Text', 'Audio', 'Image', 'Video']))
});

export const Schema = z.preprocess(data => {
  const { answers, answerElements } = data as z.infer<typeof BaseSchema>;

  const filteredAnswers = answers.map(answer =>
    answerElements.reduce<Record<string, unknown>>((acc, element) => {
      const key = element.toLowerCase() as keyof typeof answer;
      if (answer[key] !== undefined) {
        acc[key] = answer[key];
      }
      return acc;
    }, {})
  );

  return { ...(data as z.infer<typeof BaseSchema>), answers: filteredAnswers };
}, BaseSchema);

export type SelectionQuestionSchema = z.infer<typeof Schema>;
