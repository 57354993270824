import { useCallback } from 'react';

import { genRandId } from 'shared/utils/genRandId';
import { validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const useDuplicateAnswer = () => {
  const { document, data } = useContentObject();

  const [correctAnswers, setCorrectAnswers] = useContentObjectProperty<string[]>('correctAnswers', 'sensitiveData');

  return useCallback(
    (answerIndex: number) => {
      document.transact(() => {
        if (!data) {
          throw new Error('Content object data is not present.');
        }
        const answersData = validateYArray(data.get('answers'));
        const currentAnswer = validateYMap(answersData.get(answerIndex));
        const currentAnswerClone = currentAnswer.clone();

        const currentId = currentAnswer.get('id') as string;
        const newId = genRandId();
        currentAnswerClone.set('id', newId);

        answersData.insert(answerIndex + 1, [currentAnswerClone]);
        if (correctAnswers.includes(currentId)) {
          setCorrectAnswers(correctAnswers => [...correctAnswers, newId]);
        }
      });
    },
    [document, data, correctAnswers, setCorrectAnswers]
  );
};
