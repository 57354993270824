import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import { manifestLocales } from './manifest.locales';
import VideoEn from './resources/en/video.svg';
import VideoHe from './resources/he/video.svg';
import { VideoObject } from '@/icons/VideoObject';

export const Manifest: ManifestType = {
  name: WidgetType.Video,
  previewGroups: [
    {
      id: 'video',
      divider: {
        icon: VideoObject,
        title: 'video'
      },
      previews: [
        {
          name: 'videoPreview',
          previewImage: {
            paths: {
              en: VideoEn,
              he: VideoHe
            },
            size: {
              width: 285,
              height: 140
            },
            alt: { ...manifestLocales.video.previewImageAlt }
          },
          dimensions: {
            width: 560,
            height: 315
          },
          initializeProps: widgetsCreateFunctions[WidgetType.Video] as ManifestPreviews['initializeProps']
        }
      ]
    }
  ],
  additionalData: {
    maxFileSizeBytes: 52_428_800,
    acceptFileType: ['video/mp4', 'video/webm', 'video/ogg'],
    maxFiles: 1,
    constraints: {
      minHeight: 200,
      minWidth: 200
    }
  }
};
