import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    notificationWrapper: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.colors.accent[1],
      padding: rem(16),
      color: theme.colors.dark[3],
      fontSize: rem(18)
    },
    notificationIcon: {
      color: theme.colors.accent[6],
      marginRight: rem(5)
    }
  };
});
