import { User } from '@hendrx/modules/authentication';

export const login = async (reqBody: Record<string, unknown>) => {
  const response = await fetch('/api/authentication/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  });

  if (!response.ok) {
    throw new Error(`HTTP auth error! Status: ${response.status}`, {
      cause: response
    });
  }

  return response.json();
};

export const logout = async () => {
  const response = await fetch('/api/authentication/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP auth error! Status: ${response.status}`, {
      cause: response
    });
  }

  return response.json();
};

export const getUserData = async () => {
  const response = await fetch('/api/authentication/get-user-data', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP auth error! Status: ${response.status}`, {
      cause: response
    });
  }

  return (await response.json()).user as User | null;
};
