import { useCallback } from 'react';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { getRootMap, useObservedProperty, useObservedArray } from 'shared/widgetsSDK/yjs';

export type Page = {
  isHidden: boolean;
  title: string;
  id: string;
  currentIndex: number;
};

const getCurrentIndex = (rootChildren: string[], pageId: string) => {
  return rootChildren.findIndex(element => element === pageId);
};

const sortPages = (pageA: Page, pageB: Page) => pageA.currentIndex - pageB.currentIndex;

const getPagesInOrder = (array: Page[]) => array.filter(({ currentIndex }) => currentIndex >= 0).sort(sortPages);

export function usePages(document: Y.Doc) {
  const [rootChildren] = useObservedArray<string>(getRootMap(document), 'children');

  const selector = useCallback(
    (contentObjects: Y.Map<Y.Map<unknown>>) => {
      const pages: Record<'pagesData' | 'visiblePages' | 'hiddenPages', Page[]> = {
        pagesData: [],
        visiblePages: [],
        hiddenPages: []
      };

      contentObjects.forEach(contentObject => {
        const type = contentObject.get('type') as WidgetType;
        if (type === WidgetType.Page || type === WidgetType.Slide) {
          const id = contentObject.get('id') as string;
          const title = contentObject.get('title') as string;
          const isHidden = !!contentObject.get('isHidden');
          const currentIndex = getCurrentIndex(rootChildren, id);

          const fullPage = { id, title, isHidden, currentIndex };

          pages.pagesData.push(fullPage);
          if (isHidden) {
            pages.hiddenPages.push(fullPage);
          } else {
            pages.visiblePages.push(fullPage);
          }
        }
      });

      return {
        pagesData: getPagesInOrder(pages.pagesData),
        visiblePages: getPagesInOrder(pages.visiblePages),
        hiddenPages: getPagesInOrder(pages.hiddenPages)
      };
    },
    [rootChildren]
  );

  const [pages] = useObservedProperty<Record<'pagesData' | 'visiblePages' | 'hiddenPages', Page[]>>(
    getRootMap(document),
    'contentObjects',
    {
      deepObserve: true,
      selector
    }
  );

  return pages;
}
