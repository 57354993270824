import { ElementState } from '@hendrx/modules/lms';
import { debounce } from 'lodash';
import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { createStore, StoreApi, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { useCanUpdateSavedState } from '@/components/CanProvider';
import { useSavedState, useSaveElementState } from '@/queries/state';

const UPDATE_SERVER_DEBOUNCE_TIME = 2000;

type PlayerStateStoreType = {
  elementsState: Record<string, ElementState['state']>;
  setSingleElementState: (update: ElementState) => void;
};

const PlayerStateStoreContext = createContext<StoreApi<PlayerStateStoreType> | null>(null);

const applyUpdateToElementsState = (
  update: ElementState,
  elements: PlayerStateStoreType['elementsState']
): PlayerStateStoreType['elementsState'] => {
  const { elementId, state } = update;

  return {
    ...elements,
    [elementId]: state
  };
};

type PlayerStateProviderProps = {
  children: ReactNode;
  mock?: boolean;
};

const groupState = (state: PlayerStateStoreType['elementsState']) =>
  Object.entries(state).map(([elementId, state]) => ({ elementId, state }));

export const PlayerStateProvider = memo(function PlayerStateProvider(props: PlayerStateProviderProps) {
  const { children, mock } = props;

  const contentObjectStore = useMemo(
    () =>
      createStore<PlayerStateStoreType, [['zustand/subscribeWithSelector', unknown]]>(
        subscribeWithSelector(set => ({
          elementsState: {},
          setSingleElementState: update =>
            set(({ elementsState: currentElementsState }) => {
              const { elementId, state } = update;

              const updatedState = typeof state === 'function' ? state(currentElementsState[elementId]) : state;

              return {
                elementsState: applyUpdateToElementsState({ elementId, state: updatedState }, currentElementsState)
              };
            })
        }))
      ),
    []
  );

  const { mutate } = useSaveElementState();
  const { data } = useSavedState<Record<string, unknown>>();

  const canUpdateSavedState = useCanUpdateSavedState();

  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current || mock || !data) return;
    contentObjectStore.setState({
      elementsState: data
    });
    isInitialized.current = true;
  }, [contentObjectStore, data, mock]);

  useEffect(() => {
    if (mock) return;

    const updateServer = debounce((elementsState: Record<string, unknown>) => {
      if (!canUpdateSavedState) return;
      mutate({ elements: groupState(elementsState) });
    }, UPDATE_SERVER_DEBOUNCE_TIME);

    const unsubscribe = contentObjectStore.subscribe(state => state.elementsState, updateServer);

    const handleUpdateBeforeLeave = () => {
      const currentState = contentObjectStore.getState().elementsState;
      updateServer(currentState);
    };

    window.addEventListener('visibilitychange', handleUpdateBeforeLeave);

    return () => {
      window.removeEventListener('visibilitychange', handleUpdateBeforeLeave);
      unsubscribe();
    };
  }, [canUpdateSavedState, contentObjectStore, mock, mutate]);

  return <PlayerStateStoreContext.Provider value={contentObjectStore}>{children}</PlayerStateStoreContext.Provider>;
});

export function usePlayerStateStore<T = PlayerStateStoreType>(selector: (state: PlayerStateStoreType) => T) {
  const store = useContext(PlayerStateStoreContext);
  if (store === null) throw new Error('PlayerStoreContext not initialized properly.');

  return useStore(store, selector);
}

export function usePlayerElementState<T>(elementId: string) {
  const elementState = usePlayerStateStore<T>(state => state.elementsState?.[elementId] as T);
  const setSingleElementState = usePlayerStateStore(state => state.setSingleElementState);

  const setElementState: Dispatch<SetStateAction<T>> = useCallback(
    state => {
      setSingleElementState({ elementId, state });
    },
    [elementId, setSingleElementState]
  );

  return [elementState, setElementState] as const;
}
