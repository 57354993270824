import { Group, SelectProps, useMantineTheme } from '@mantine/core';

import { rem } from 'shared/styles/rem';

export const SelectOption: SelectProps['renderOption'] = props => {
  const { option, checked } = props;

  const theme = useMantineTheme();
  const clozeQuestionSelectedFieldTheme = theme.other.learningItems.clozeQuestion.selectedField;
  const globalContentColorsTheme = theme.other.global.content.colors;

  return (
    <Group
      flex="1"
      gap="xs"
      sx={{
        padding: `${rem(6)} ${rem(12)}`,
        ...(checked
          ? {
              background: clozeQuestionSelectedFieldTheme.backgroundColor ?? globalContentColorsTheme.primary.light,
              borderRadius: rem(4)
            }
          : {})
      }}
    >
      {option.label}
    </Group>
  );
};
