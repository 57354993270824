import { createContext, ReactNode, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

type StageStoreType = {
  isStageAsThumbnail: boolean;
};
const StageContext = createContext<StoreApi<StageStoreType> | null>(null);

type StageContextProviderProps = {
  isStageAsThumbnail: boolean;
  children: ReactNode;
};
export function StageContextProvider(props: StageContextProviderProps) {
  const { isStageAsThumbnail, children } = props;
  const stageStore = useMemo(
    () =>
      createStore<StageStoreType>(() => ({
        isStageAsThumbnail
      })),
    [isStageAsThumbnail]
  );
  return <StageContext.Provider value={stageStore}>{children}</StageContext.Provider>;
}

export function useStageContext<T = StageStoreType>(selector: (state: StageStoreType) => T) {
  const store = useContext(StageContext);
  if (store === null) throw new Error('StageContextProvider not initialized properly.');
  return useStore(store, selector);
}
