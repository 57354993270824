import { Box, Button } from '@mantine/core';
import { useTranslations } from 'next-intl';

import * as classes from './PlenaryLessonSubmitButton.css';

type PlenaryLessonSubmitButtonProps = {
  onSubmit: () => void;
  isDisabled?: boolean;
};

export const PlenaryLessonSubmitButton = (props: PlenaryLessonSubmitButtonProps) => {
  const { onSubmit, isDisabled } = props;

  const t = useTranslations('components.plenaryLessonSubmitButton');

  return (
    <Box className={classes.submitButtonWrapper}>
      <Button
        w="100%"
        radius="md"
        size="compact-xl"
        onClick={onSubmit}
        disabled={isDisabled}
        classNames={{ label: classes.submitButtonLabel }}
      >
        {t('submit')}
      </Button>
    </Box>
  );
};
