import { ActionIcon, Flex, Popover, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure, useFullscreen } from '@mantine/hooks';
import * as nextIntl from 'next-intl';
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState, useRef, useMemo } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import { Modal } from '@/components/Modal';
import { RevealAnswers } from '@/components/RevealAnswers';
import { Plus } from '@/icons/Plus';
import { AnswerField } from '@/widgets/OpenDiscussion/player/AnswerField';
import { FocusModalContent } from '@/widgets/OpenDiscussion/player/FocusModalContent';
import { QuestionHeader } from '@/widgets/OpenDiscussion/player/QuestionHeader';
import { Card } from '@/widgets/OpenDiscussion/player/TeacherView/Card';
import { Dropzone } from '@/widgets/OpenDiscussion/player/TeacherView/Dropzone';
import * as classes from '@/widgets/OpenDiscussion/player/TeacherView/TeacherView.css';
import {
  addAnswersToBoard,
  convertTo2DArray,
  removeAnswersFromBoard,
  updateAnswersOnBoard
} from '@/widgets/OpenDiscussion/player/TeacherView/utils';

type TeacherViewProps = {
  answers: OpenDiscussionAnswer[];
  deleteAnswer: (id: string) => void;
  editAnswerId: string | null;
  handleCancelEditMode: () => void;
  handleEdit: () => Promise<void>;
  handleEditCardColor: (newColor: string, answer: OpenDiscussionAnswer) => Promise<void>;
  handleImageDelete: () => void;
  handleOpenEditMode: (answer: OpenDiscussionAnswer) => void;
  handleSend: () => Promise<void>;
  image: File | string | null;
  isLoading: boolean;
  resetRef: RefObject<() => void>;
  setImage: Dispatch<SetStateAction<string | File | null>>;
  setText: Dispatch<SetStateAction<string>>;
  text: string;
};

export function TeacherView(props: TeacherViewProps) {
  const {
    answers,
    deleteAnswer,
    editAnswerId,
    handleCancelEditMode,
    handleEdit,
    handleEditCardColor,
    handleImageDelete,
    handleOpenEditMode,
    handleSend,
    image,
    isLoading,
    resetRef,
    setImage,
    setText,
    text
  } = props;

  const t = nextIntl.useTranslations('widgets.openDiscussionPlayer');
  const theme = useMantineTheme();
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [focusedAnswerIndex, setFocusedAnswerIndex] = useState(0);
  const [boardIndexes, setBoardIndexes] = useState({ column: 0, row: 0 });
  const [focusCardColor, setFocusCardColor] = useState('');
  const [shouldRevealAnswers, { toggle: toggleRevealAnswers }] = useDisclosure(false);
  const focusedAnswerRef = useRef<OpenDiscussionAnswer>();
  const { fullscreen: isFullscreen } = useFullscreen();
  const ref = useRef<HTMLDivElement>(null);

  const width = ref.current?.getBoundingClientRect()?.width;
  const columns = width === undefined ? 4 : Math.floor(width / 300);

  const [cards, setCards] = useState(convertTo2DArray(answers, columns));

  const onEdit = useCallback(
    (answer: OpenDiscussionAnswer) => {
      handleOpenEditMode(answer);
      setIsPopoverOpened(true);
    },
    [handleOpenEditMode]
  );

  const onFocus = useCallback(
    (columnIndex: number, index: number) => {
      setBoardIndexes({ column: columnIndex, row: index });
      setIsModalOpened(true);
      focusedAnswerRef.current = cards[columnIndex][index];
    },
    [cards]
  );

  useEffect(() => {
    setCards(cards => {
      if (columns !== cards.length) {
        return convertTo2DArray(answers, columns);
      }

      const currentAnswerIds = cards.flat().map(({ id }) => id);
      const hasAnswersAdded = currentAnswerIds.length < answers.length;
      const hasAnswersRemoved = currentAnswerIds.length > answers.length;

      if (hasAnswersAdded) {
        return addAnswersToBoard(answers, cards, currentAnswerIds);
      } else if (hasAnswersRemoved) {
        return removeAnswersFromBoard(answers, cards, currentAnswerIds);
      }

      return updateAnswersOnBoard(answers, cards);
    });
  }, [answers, columns]);

  useEffect(() => {
    setFocusCardColor(cards[boardIndexes.column][boardIndexes.row]?.cardColor ?? '#fff');
  }, [boardIndexes.column, boardIndexes.row, cards]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, dragColumnIndex: number, hoverColumnIndex: number) => {
      setCards(cards => {
        const clonedCards = structuredClone(cards);
        clonedCards[dragColumnIndex].splice(dragIndex, 1);
        clonedCards[hoverColumnIndex].splice(hoverIndex, 0, cards[dragColumnIndex][dragIndex]);
        return clonedCards;
      });
    },
    []
  );

  const Board = useMemo(() => {
    if (!shouldRevealAnswers) {
      return null;
    }

    let cardIndex = 0;

    return cards.map((column, columnIndex) => (
      <Flex key={`column-${columnIndex}`} direction="column" gap="sm" w="100%">
        {column.map((answer, index) => (
          <Card
            answer={answer}
            columnIndex={columnIndex}
            onDelete={deleteAnswer}
            index={index}
            key={`card-${answer.id}`}
            moveCard={moveCard}
            onEdit={onEdit}
            onClick={() => onFocus(columnIndex, index)}
            onEditColor={handleEditCardColor}
            setFocusedAnswerIndex={setFocusedAnswerIndex}
            cardIndex={cardIndex++}
          />
        ))}
        <Dropzone
          key={`dropzone-${columnIndex}-${column.length}`}
          index={column.length}
          columnIndex={columnIndex}
          moveCard={moveCard}
        />
      </Flex>
    ));
  }, [cards, deleteAnswer, handleEditCardColor, moveCard, onEdit, onFocus, shouldRevealAnswers]);

  const currentCard = cards[boardIndexes.column][boardIndexes.row];
  const answer = currentCard ?? focusedAnswerRef.current;
  const isAnswerAvailable = !!currentCard;

  return (
    <Flex direction="column" className={classes.teacherViewContainer}>
      <RevealAnswers answersCount={answers.length} isOn={shouldRevealAnswers} onToggle={toggleRevealAnswers} />
      <QuestionHeader />
      <Flex className={classes.boardContainer}>
        <Flex className={classes.extendedContainer} gap="md">
          {Board}
        </Flex>

        <Modal
          opened={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          title={isAnswerAvailable ? `${focusedAnswerIndex + 1}/${answers.length}` : undefined}
          overridingClasses={{
            header: classes.modalHeader,
            content: classes.modalContent,
            title: classes.modalTitle,
            close: classes.modalCloseButton,
            inner: classes.modalInner,
            body: classes.modalBody
          }}
          styles={{
            header: { backgroundColor: `${focusCardColor} !important` },
            content: {
              backgroundColor: `${focusCardColor} !important`,
              width: answer?.text ? 'min-content' : undefined
            }
          }}
          scrollAreaComponent={undefined}
          withinPortal={!isFullscreen}
          size="auto"
        >
          <FocusModalContent
            answer={answer}
            boardIndexes={boardIndexes}
            columns={cards.map(column => column.length)}
            focusedAnswerIndex={focusedAnswerIndex}
            maxIndexes={{ column: cards.length - 1, row: cards[boardIndexes.column].length - 1 }}
            setBoardIndexes={setBoardIndexes}
            setFocusedAnswerIndex={setFocusedAnswerIndex}
            totalAnswers={answers.length}
            isAvailable={isAnswerAvailable}
          />
        </Modal>

        <Popover
          width={450}
          position="bottom"
          shadow="md"
          opened={isPopoverOpened}
          onChange={(opened: boolean) => {
            setIsPopoverOpened(opened);
            handleCancelEditMode();
          }}
          withinPortal={false}
        >
          <Popover.Target>
            <Flex gap={4} direction="column" style={{ alignSelf: 'flex-end' }} w={128}>
              <ActionIcon
                size={42}
                radius="xl"
                mx="auto"
                aria-label={t('addPost')}
                bg={theme.colors?.accent?.[4]}
                className={classes.addButton}
                onClick={() => setIsPopoverOpened(opened => !opened)}
              >
                <Plus width={20} height={20} color="white" />
              </ActionIcon>
              <Text fz={14} ta="center" style={{ textWrap: 'nowrap' }}>
                {t('addPost')}
              </Text>
            </Flex>
          </Popover.Target>
          <Popover.Dropdown p="xs">
            <AnswerField
              editAnswerId={editAnswerId}
              handleImageDelete={handleImageDelete}
              handleSend={async () => {
                await handleSend();
                setIsPopoverOpened(false);
              }}
              image={image}
              isLoading={isLoading}
              resetRef={resetRef}
              setImage={setImage}
              setText={setText}
              text={text}
              imagePosition="bottom"
              handleCancelEditMode={() => {
                setIsPopoverOpened(false);
                handleCancelEditMode();
              }}
              handleEdit={async () => {
                await handleEdit();
                setIsPopoverOpened(false);
              }}
            />
          </Popover.Dropdown>
        </Popover>
      </Flex>
    </Flex>
  );
}
