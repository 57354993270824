import * as Y from 'yjs';

import { getContentObjectsMap, validateYArray } from './yjs';
import { WidgetType } from '../utils/widgets';

const getNestedSectionData = (document: Y.Doc, contentObjectData?: Y.Map<unknown>) => {
  const childrenIds = validateYArray<string>(contentObjectData?.get('children')).toArray();
  let sectionId;

  if (contentObjectData?.get('type') === WidgetType.EnclosureWithCombinedTitle) {
    // EnclosureWithCombinedTitle is the only widget with 2 flex sections, only the second one should accept new children
    sectionId = childrenIds.length > 1 ? childrenIds[1] : undefined;
  } else {
    sectionId = childrenIds.find(childId => {
      const childData = getContentObjectsMap(document).get(childId);
      return childData?.get('type') === WidgetType.FlexSection;
    });
  }

  return sectionId ? getContentObjectsMap(document).get(sectionId) : undefined;
};

export const getTargetSectionData = (document: Y.Doc, contentObjectData: Y.Map<unknown> | undefined) => {
  const contentObjectType = contentObjectData?.get('type');

  return contentObjectType === WidgetType.Section || contentObjectType === WidgetType.FlexSection
    ? contentObjectData
    : getNestedSectionData(document, contentObjectData);
};
