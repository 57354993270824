import { Tabs, TextInput } from '@mantine/core';
import has from 'lodash/has';
import { useTranslations } from 'next-intl';

import { AudioSchemaType } from '@/widgets/Audio/Schema';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

type BaseProps = {
  title: string;
  setTitle: (title: string) => void;
  errors: Partial<AudioSchemaType>;
  withCustomCSSClasses: boolean;
};
export function Base(props: BaseProps) {
  const { title, setTitle, errors, withCustomCSSClasses } = props;
  const t = useTranslations('widgets');

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <FormControl title={t('audioSettings.addTitleLabel')} withAsterisk>
          <TextInput
            value={title}
            onChange={event => setTitle(event.currentTarget.value)}
            error={has(errors, 'title') && t('audioSettings.addTitleError')}
            placeholder={t('audioSettings.addTitlePlaceholder') ?? ''}
            data-testid="audio-title-field"
          />
        </FormControl>
        {withCustomCSSClasses && <CustomCSSClassesInput />}
      </Tabs.Panel>
    </WidgetConfig>
  );
}
