import { Tabs, TextInput } from '@mantine/core';
import has from 'lodash/has';
import { useTranslations } from 'next-intl';

import { AudioSchemaType } from '@/widgets/Audio/Schema';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

type BaseProps = {
  title: string;
  setTitle: (title: string) => void;
  errors: Partial<AudioSchemaType>;
};
export function Base(props: BaseProps) {
  const { title, setTitle, errors } = props;
  const t = useTranslations('widgets');

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <FormControl title={t('videoSettings.addTitleLabel')} withAsterisk>
          <TextInput
            value={title}
            onChange={event => setTitle(event.currentTarget.value)}
            error={has(errors, 'title') && t('videoSettings.addTitleError')}
            placeholder={t('videoSettings.addTitlePlaceholder') ?? ''}
            data-testid="video-title-field"
          />
        </FormControl>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
