import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type Props = {
  isEditMode: boolean;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { isEditMode } = props;
  const mediaStyle = theme.other.media;
  const borderStyle = getThemeBorderStyle(mediaStyle.border);
  const borderRadiusStyle = getThemeBorderRadiusStyle(mediaStyle.borderRadius);

  return {
    iframe: {
      display: 'block',
      border: 0,
      width: '100%',
      height: '100%',
      ...borderStyle,
      ...borderRadiusStyle
    },
    injectedIframe: {
      width: '100%',
      height: '100%',
      '> iframe': {
        width: '100%',
        height: '100%',
        border: 0,
        ...borderStyle,
        ...borderRadiusStyle
      }
    },
    iframeContainer: {
      height: '100%',
      overflow: 'hidden',
      transform: 'translateZ(0)',
      ...(isEditMode && { pointerEvents: 'none' }),
      ...borderRadiusStyle
    }
  };
});
