import { useRouter } from 'next/router';

import {
  PLAYER_ACTIVITY_PATH,
  PLAYER_ASSIGNMENT_PATH,
  PLAYER_PATH,
  STUDIO_EDIT_PATH,
  STUDIO_PATH,
  STUDIO_PREVIEW_PATH,
  STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH
} from '@/consts/paths';
import { parseQueryParam } from '@/utils/query.utils';
import { useContext } from 'react';
import { WorkModeContext } from '@/components/WorkModeContext';

export const useWorkMode = () => {
  const router = useRouter();
  const { pathname, query } = router;

  const workModeContext = useContext(WorkModeContext);

  const page = parseQueryParam(query.page);
  const mockTest = parseQueryParam(query.mockTest); // TODO: Change mocking test state to some other long-term solution

  const isPlayer = pathname.includes(PLAYER_PATH);
  const isPlayerActivity = pathname.includes(PLAYER_ACTIVITY_PATH) && !pathname.includes(PLAYER_ASSIGNMENT_PATH);
  const isPlayerAssignment = pathname.includes(PLAYER_ASSIGNMENT_PATH);
  const isPlayerAssignmentSubmitPage = pathname.includes(PLAYER_ASSIGNMENT_PATH) && page === 'submit';
  const isPlayerAssignmentSummaryPage = pathname.includes(PLAYER_ASSIGNMENT_PATH) && page === 'summary';
  const isPlayerAssignmentTest = pathname.includes(PLAYER_ASSIGNMENT_PATH) && mockTest === 'true';
  const isSnapshotHistory = pathname.includes(STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH);
  const isStudio = pathname.includes(STUDIO_PATH) && !pathname.includes(STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH);
  const isStudioEdit = pathname.includes(STUDIO_EDIT_PATH) && !pathname.includes(STUDIO_SNAPSHOT_HISTORY_PARTIAL_PATH);
  const isStudioPreview = pathname.includes(STUDIO_PREVIEW_PATH);

  return {
    isPlayer,
    isPlayerActivity,
    isPlayerAssignment,
    isPlayerAssignmentSubmitPage,
    isPlayerAssignmentSummaryPage,
    isPlayerAssignmentTest,
    isSnapshotHistory,
    isStudio,
    isStudioEdit,
    isStudioPreview,
    ...workModeContext
  };
};
