import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';

import { manifestLocales } from './manifest.locales';
import TableEn from './resources/en/table.svg';
import TableHe from './resources/he/table.svg';
import { DEFAULT_TABLE_WIDTH, createNewTableData } from './utils';
import { TableIcon } from '@/icons/TableIcon';

export const Manifest: ManifestType = {
  name: WidgetType.Table,
  previewGroups: [
    {
      id: 'table',
      divider: {
        icon: TableIcon,
        title: 'table'
      },
      previews: [
        {
          name: 'tablePreview',
          previewImage: {
            paths: {
              en: TableEn,
              he: TableHe
            },
            alt: { ...manifestLocales.table },
            size: {
              width: 285,
              height: 144
            }
          },
          initializeProps: options => createNewTableData(4, 2, options),
          dimensions: {
            width: DEFAULT_TABLE_WIDTH,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
