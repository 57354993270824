import { Box, Flex, Text } from '@mantine/core';
import cx from 'clsx';
import { useTranslations } from 'next-intl';

import * as classes from './AnswerStyle.css';
import { AudioPlaceholder } from '@/icons/AudioPlaceholder';
import { ImagePlaceholder } from '@/icons/ImagePlaceholder';

type AnswerStyleProps = {
  hasAudio: boolean;
  hasImage: boolean;
  hasText: boolean;
  className?: string;
};

export function AnswerStyle(props: AnswerStyleProps) {
  const { className, hasAudio, hasImage, hasText } = props;

  const t = useTranslations('components.answersStylePicker');

  return (
    <Box className={cx(hasImage ? classes.answerStyle.withImage : classes.answerStyle.base, className)}>
      {hasImage && (
        <Flex className={classes.imageWrapper}>
          <ImagePlaceholder className={classes.imageIcon} />
        </Flex>
      )}
      <Flex className={classes.caption}>
        {hasText && <Text fz="xs">{t('answer')}</Text>}
        {hasAudio && (
          <Flex className={classes.audioWrapper}>
            <AudioPlaceholder className={classes.audioIcon} />
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
