import { Accordion, Tabs } from '@mantine/core';
import * as Y from 'yjs';

import { ClozeAccordionItem } from './ClozeAccordionItem';
import { useTiptapClozeOptionsStore } from '@/widgets/ClozeQuestion/studio/hooks/useTiptapClozeOptionsStore';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { createContentId } from '@/widgets/Table/utils';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

function extractClozeIds(fragment: Y.XmlFragment | undefined) {
  if (!fragment) return [];
  const regex = /clozeId="([^"]+)"/g;
  const clozeIds = [];
  let match;

  // It's weird but toJSON() actually returns a dom string
  const dom = fragment.toJSON();

  while ((match = regex.exec(dom)) !== null) {
    clozeIds.unshift(match[1]);
  }

  return clozeIds;
}

export function TableClozeConfig() {
  const [clozeOptions] = useContentObjectProperty<Y.Map<Y.Map<unknown>>>('clozeOptions');

  const selectedClozeId = useTiptapClozeOptionsStore(state => state.selectedClozeId);
  const setSelectedClozeId = useTiptapClozeOptionsStore(state => state.setSelectedClozeId);

  const content = useTable(context => context.content);
  const rowOrder = useTable(context => context.rowOrder);
  const columnOrder = useTable(context => context.columnOrder);

  const clozeIdsSet = new Set<string>();
  rowOrder?.forEach(rowId => {
    columnOrder?.forEach(columnId => {
      const cellContentId = createContentId(columnId, rowId);
      const cellContent = content?.get(cellContentId) as Y.XmlFragment;
      const cellClozeIds = extractClozeIds(cellContent);
      cellClozeIds.forEach(clozeId => clozeIdsSet.add(clozeId));
    });
  });
  const clozeIds = Array.from(clozeIdsSet).filter(clozeId => clozeOptions.has(clozeId));

  return (
    <WidgetConfig tabs={['appearance', 'behaviour']}>
      <Tabs.Panel key="appearance" value="appearance">
        {clozeIds.length > 0 && (
          <Accordion value={selectedClozeId} onChange={clozeId => setSelectedClozeId(clozeId)}>
            {Array.from(clozeIds).map((clozeId, index) => {
              const type = clozeOptions.get(clozeId)?.get('type') as ClozeOptionType;
              return <ClozeAccordionItem clozeId={clozeId} index={index} type={type} key={clozeId} />;
            })}
          </Accordion>
        )}
        <CustomCSSClassesInput />
      </Tabs.Panel>
      <Tabs.Panel key="behaviour" value="behaviour">
        <EvaluationSettings />
        <Feedback />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
