import { Box, Switch, Text } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from './SubmitPageReturnToFixSwitch.styles';
import { useCanSeeReturnToFixSwitch } from '@/components/CanProvider';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function SubmitPageReturnToFixSwitch() {
  const t = useTranslations('widgets');
  const [toFix, setToFix] = useContentObjectProperty<boolean>('toFix');
  const canSeeReturnToFixSwitch = useCanSeeReturnToFixSwitch();

  const { classes } = useStyles();

  return canSeeReturnToFixSwitch ? (
    <Box className={classes.container}>
      <Text>{t('submitPageReturnToFixSwitch.returnToFix')}</Text>
      <Switch checked={toFix} onChange={event => setToFix(event.currentTarget.checked)} />
    </Box>
  ) : null;
}
