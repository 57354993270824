import * as Y from 'yjs';

import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';
import { WidgetType, questionWidgets } from 'shared/utils/widgets';
import { getSubQuestionsIds } from 'shared/widgetsSDK/questionGroup';
import {
  ContentObjectType,
  getContentObjectsMap,
  getFormDataMap,
  getRootMap,
  validateYArray
} from 'shared/widgetsSDK/yjs';

import { ScoreWeightDistributionInput, distributeScoreWeights } from './scoreWeightUtils';
import { getActivityHiddenQuestionsIds, getActivityOrderedQuestionsIds } from '@/utils/getActivityOrderedQuestionsIds';
import { selectContentObjects } from '@/utils/selectContentObjects';

type QuestionWidgetType =
  | WidgetType.ClozeQuestion
  | WidgetType.FillInTheBlanksQuestion
  | WidgetType.OpenQuestion
  | WidgetType.SelectionQuestion
  | WidgetType.QuestionGroupList;

export const calculateWeightScore = (document: Y.Doc) => {
  const formDataMap = getFormDataMap(document);

  const scoreWeightsDistributionInput = getScoreWeightDistributionInput(document);
  const scoreWeightsDistribution = distributeScoreWeights(scoreWeightsDistributionInput);

  scoreWeightsDistribution.forEach(item => {
    const map = formDataMap.get(item.id);
    if (map?.get('scoreWeight') !== item.scoreWeight) {
      map?.set('scoreWeight', item.scoreWeight);
    }
    item.subQuestions?.forEach(subQuestion => {
      const subQuestionMap = formDataMap.get(subQuestion.id);
      if (subQuestionMap?.get('scoreWeight') !== subQuestion.scoreWeight) {
        subQuestionMap?.set('scoreWeight', subQuestion.scoreWeight);
      }
    });
  });
};

const getScoreWeightDistributionInput = (document: Y.Doc): ScoreWeightDistributionInput => {
  const rootMap = getRootMap(document);
  const contentObjectsMap = getContentObjectsMap(document);
  const pagesIds = validateYArray<string>(rootMap.get('children')).toArray();
  const selectedContentObjects = selectContentObjects(contentObjectsMap, { includeLabel: false });
  const activityOrderedQuestionsIds = getActivityOrderedQuestionsIds(pagesIds, selectedContentObjects);
  const activityHiddenQuestionsIds = getActivityHiddenQuestionsIds(pagesIds, selectedContentObjects);

  const groupQuestionsWithSubQuestions: Record<string, string[]> = {};
  activityOrderedQuestionsIds.forEach(id => {
    const contentObject = contentObjectsMap.get(id);
    if (contentObject?.get('type') === WidgetType.QuestionGroupList) {
      groupQuestionsWithSubQuestions[id] = getSubQuestionsIds(contentObject, contentObjectsMap);
    }
  });
  const allSubQuestions = Object.values(groupQuestionsWithSubQuestions).flat();

  return activityOrderedQuestionsIds
    .filter(filterTopLevelQuestionWidgets(allSubQuestions, contentObjectsMap))
    .map(transformToObjects(contentObjectsMap))
    .map(question => ({ ...question, isHidden: activityHiddenQuestionsIds.includes(question.id) }));
};

const filterTopLevelQuestionWidgets =
  (allSubQuestions: string[], contentObjectsMap: Y.Map<ContentObjectType>) => (id: string) => {
    const type = contentObjectsMap.get(id)?.get('type') as WidgetType;
    return type === WidgetType.QuestionGroupList || (questionWidgets.includes(type) && !allSubQuestions.includes(id));
  };

const transformToObjects = (contentObjectsMap: Y.Map<ContentObjectType>) => (id: string) => {
  const map = contentObjectsMap.get(id) ?? new Y.Map();
  const type = map.get('type') as QuestionWidgetType;
  const questionScoreMode = map.get('questionScoreMode') as QuestionScoreMode;

  if (type === WidgetType.QuestionGroupList) {
    const subQuestionIds = getSubQuestionsIds(map, contentObjectsMap);
    const relativeScoreMode = map.get('relativeScoreMode') as RelativeScoreMode;
    const choiceQuestionsEnabled = map.get('choiceQuestionsEnabled') as boolean;
    const choiceQuestionsCount = map.get('choiceQuestionsCount') as number;
    const visibleQuestionsCount = map.get('visibleQuestionsCount') as number;
    return {
      id,
      type,
      subQuestions: subQuestionIds.map(item => ({ id: item })),
      relativeScoreMode,
      questionScoreMode,
      choiceQuestionsEnabled,
      choiceQuestionsCount,
      visibleQuestionsCount
    };
  }

  return { id, type, questionScoreMode };
};
