import { useEffect } from 'react';

import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';
import { StudioProps } from '@/widgets/types/studioProps';

export function Embed(props: StudioProps) {
  const { initialize } = props;
  const { document, id } = useContentObject();
  const [src] = useContentObjectProperty<string>('src');
  const [title] = useContentObjectProperty<string>('title');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  useEffect(() => {
    initialize?.({
      isResizableVertically: true,
      shouldKeepAspectRatio: true
    });
  }, [initialize]);

  return (
    <div className={customClassNames}>
      <EmbedIframe workMode="Preview" title={title} src={src} />
    </div>
  );
}
