import shuffle from 'lodash/shuffle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Y from 'yjs';

import { useContentObjectArray, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export const useOrderedAnswers = () => {
  const [withRandomAnswers] = useContentObjectProperty<boolean>('withRandomAnswers');
  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const answersOrderIndices = useMemo(() => answers.map((_, index) => index), [answers]);
  const [orderedAnswers, setOrderedAnswers] = useState<number[]>([]);

  const getOrderedAnswers = useCallback(() => {
    return withRandomAnswers ? shuffle(answersOrderIndices) : answersOrderIndices;
  }, [answersOrderIndices, withRandomAnswers]);

  const resetOrderedAnswers = useCallback(() => {
    setOrderedAnswers(getOrderedAnswers());
  }, [getOrderedAnswers]);

  useEffect(() => {
    resetOrderedAnswers();
  }, [resetOrderedAnswers]);

  return {
    orderedAnswers,
    setOrderedAnswers,
    getOrderedAnswers,
    resetOrderedAnswers
  };
};
