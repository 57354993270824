import { FC } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { ClozeQuestionAnswerStudio } from '@/contentObjects/ClozeQuestionAnswer/ClozeQuestionAnswerStudio';
import { EvaluatablePluginAnswerStudio } from '@/contentObjects/EvaluatablePluginAnswer/EvaluatablePluginAnswerStudio';
import { FillInTheBlanksQuestionAnswerStudio } from '@/contentObjects/FillInTheBlanksQuestionAnswer/FillInTheBlanksQuestionAnswerStudio';
import { InteractivePollAnswerStudio } from '@/contentObjects/InteractivePollAnswer/InteractivePollAnswerStudio';
import { InteractiveQuizAnswerStudio } from '@/contentObjects/InteractiveQuizAnswer/InteractiveQuizAnswerStudio';
import { OpenQuestionAnswerStudio } from '@/contentObjects/OpenQuestionAnswer/OpenQuestionAnswerStudio';
import { EvaluatableStudio as EvaluatablePluginStudio } from '@/contentObjects/Plugin';
import { Studio as PluginStudio } from '@/contentObjects/Plugin';
import { SelectionQuestionAnswerStudio } from '@/contentObjects/SelectionQuestionAnswer/SelectionQuestionAnswerStudio';
import { Audio as AudioStudio } from '@/widgets/Audio/studio';
import { BlockTitle as BlockTitleStudio } from '@/widgets/BlockTitle/studio';
import { ClozeQuestion as ClozeQuestionStudio } from '@/widgets/ClozeQuestion/studio';
import { CombinedTitle as CombinedTitleStudio } from '@/widgets/CombinedTitle/studio';
import { Embed as EmbedStudio } from '@/widgets/Embed/studio';
import { EnclosureWithBlockTitle as EnclosureWithBlockTitleStudio } from '@/widgets/EnclosureWithBlockTitle/studio';
import { EnclosureWithCombinedTitle as EnclosureWithCombinedTitleStudio } from '@/widgets/EnclosureWithCombinedTitle/studio';
import { FillInTheBlanksQuestion as FillInTheBlanksQuestionStudio } from '@/widgets/FillInTheBlanksQuestion/studio';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { Image as ImageStudio } from '@/widgets/Image/studio';
import { InteractivePollStudio } from '@/widgets/InteractivePoll/studio';
import { InteractiveQuizStudio } from '@/widgets/InteractiveQuiz/studio';
import { MicrosoftEmbed as MicrosoftEmbedStudio } from '@/widgets/MicrosoftEmbed/studio';
import { OpenDiscussion as OpenDiscussionStudio } from '@/widgets/OpenDiscussion/studio';
import { OpenQuestion as OpenQuestionStudio } from '@/widgets/OpenQuestion/studio';
import { PdfDocument as PdfDocumentStudio } from '@/widgets/PdfDocument/studio';
import { QuestionGroupList as QuestionGroupListStudio } from '@/widgets/QuestionGroupList/studio';
import { SelectionQuestion as SelectionQuestionStudio } from '@/widgets/SelectionQuestion/studio';
import { Spacer as SpacerStudio } from '@/widgets/Spacer/studio';
import { SubmitPageComment as SubmitPageCommentStudio } from '@/widgets/SubmitPageComment/studio';
import { SubmitPageReturnToFixSwitch as SubmitPageReturnToFixSwitchStudio } from '@/widgets/SubmitPageReturnToFixSwitch/studio';
import { SubmitPageSatisfactionSurvey as SubmitPageSatisfactionSurveyStudio } from '@/widgets/SubmitPageSatisfactionSurvey/studio';
import { SubmitPageTitle as SubmitPageTitleStudio } from '@/widgets/SubmitPageTitle/studio';
import { SubmitPageUnansweredQuestions as SubmitPageUnansweredQuestionsStudio } from '@/widgets/SubmitPageUnansweredQuestions/studio';
import { SummaryPageSubmissionHistory as SummaryPageSubmissionHistoryStudio } from '@/widgets/SummaryPageSubmissionHistory/studio';
import { SummaryPageTaskSubmittedNotification as SummaryPageTaskSubmittedNotificationStudio } from '@/widgets/SummaryPageTaskSubmittedNotification/studio';
import { Table as TableStudio } from '@/widgets/Table/studio';
import { Text as TextStudio } from '@/widgets/Text/studio';
import { Video as VideoStudio } from '@/widgets/Video/studio';
import { Studio as AvatarWidgetStudio } from '@/widgets/AvatarWidget/Studio';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const studioWidgets: Record<WidgetType, FC<any>> = {
  Audio: AudioStudio,
  BlockTitle: BlockTitleStudio,
  ClozeQuestion: ClozeQuestionStudio,
  ClozeQuestionAnswer: ClozeQuestionAnswerStudio,
  CombinedTitle: CombinedTitleStudio,
  Embed: EmbedStudio,
  EnclosureWithBlockTitle: EnclosureWithBlockTitleStudio,
  EnclosureWithCombinedTitle: EnclosureWithCombinedTitleStudio,
  EvaluatablePlugin: EvaluatablePluginStudio,
  EvaluatablePluginAnswer: EvaluatablePluginAnswerStudio,
  FillInTheBlanksQuestion: FillInTheBlanksQuestionStudio,
  FillInTheBlanksQuestionAnswer: FillInTheBlanksQuestionAnswerStudio,
  FlexSection: FlexSectionStudio,
  Image: ImageStudio,
  InteractivePoll: InteractivePollStudio,
  InteractivePollAnswer: InteractivePollAnswerStudio,
  InteractiveQuiz: InteractiveQuizStudio,
  InteractiveQuizAnswer: InteractiveQuizAnswerStudio,
  MicrosoftEmbed: MicrosoftEmbedStudio,
  OpenDiscussion: OpenDiscussionStudio,
  OpenQuestion: OpenQuestionStudio,
  OpenQuestionAnswer: OpenQuestionAnswerStudio,
  Page: () => null,
  PdfDocument: PdfDocumentStudio,
  Plugin: PluginStudio,
  QuestionGroupList: QuestionGroupListStudio,
  Section: () => null,
  SelectionQuestion: SelectionQuestionStudio,
  SelectionQuestionAnswer: SelectionQuestionAnswerStudio,
  Slide: () => null,
  Spacer: SpacerStudio,
  SubmitPage: () => null,
  SubmitPageComment: SubmitPageCommentStudio,
  SubmitPageReturnToFixSwitch: SubmitPageReturnToFixSwitchStudio,
  SubmitPageSatisfactionSurvey: SubmitPageSatisfactionSurveyStudio,
  SubmitPageTitle: SubmitPageTitleStudio,
  SubmitPageUnansweredQuestions: SubmitPageUnansweredQuestionsStudio,
  SummaryPage: () => null,
  SummaryPageSubmissionHistory: SummaryPageSubmissionHistoryStudio,
  SummaryPageTaskSubmittedNotification: SummaryPageTaskSubmittedNotificationStudio,
  Table: TableStudio,
  Text: TextStudio,
  Video: VideoStudio,
  AvatarWidget: AvatarWidgetStudio
};
