import { useEffect } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { Manifest } from '@/widgets/CombinedTitle/Manifest';
import { Config } from '@/widgets/CombinedTitle/config';
import { useCombinedTitleStyleProperties } from '@/widgets/CombinedTitle/hooks/useCombinedTitleStyleProperties';
import { Studio as FlexSection } from '@/widgets/FlexSection/studio';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as studioProps from '@/widgets/types/studioProps';

const acceptedItemTypes: ItemDragType[] = [];
export function CombinedTitle(props: studioProps.StudioProps) {
  const { initialize } = props;

  const { document, id } = useContentObject();
  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');
  const { alignItems, gap, itemsOrder } = useCombinedTitleStyleProperties(id, document);
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>

      <div className={customClassNames}>
        <ContentObjectProvider id={horizontalSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            <FlexSection
              alignItems={alignItems}
              gap={gap}
              itemsOrder={itemsOrder}
              shouldApplyNestStyles={false}
              withConfig={false}
            />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
      </div>
    </>
  );
}
