// Toolbox
export const HEADER_HEIGHT_PX = 56;

// Stage
export const NAV_ARROW_BOX_SHADOW_WIDTH_PX = 8;
export const NAV_ARROW_HEIGHT_PX = 112;
export const PRESENTATION_AREA_HEIGHT_PX = 720;
export const PRESENTATION_AREA_WIDTH_PX = 1280;
export const STAGE_SECTION_PADDING_PX = 24;
export const STAGE_COMMON_PADDING_PX = 56;
export const PLENARY_STAGE_TOP_PADDING_PX = 56;

// Section
export const SECTION_PADDING_TOP_PX = 10;
