import * as Y from 'yjs';

import { FlexSectionDirection } from '../types/FlexSectionDirection';
import { createNewFlexSectionData } from '../widgetsSDK/FlexSection/create';

// stage width without its padding
const defaultWidth = 1184;

export const createQuestionWidgetChildren = (questionAnswerId: string, width = defaultWidth) => {
  const [horizontalFlexSectionData] = createNewFlexSectionData(FlexSectionDirection.row, [
    { id: questionAnswerId, isDeletable: false, isDraggable: false, width, height: 'auto' }
  ]);
  const [verticalFlexSectionData] = createNewFlexSectionData(
    FlexSectionDirection.column,
    [{ id: horizontalFlexSectionData.id, isDeletable: false, isDraggable: false, width, height: 'auto' }],
    new Y.Map([['padding', 0]])
  );
  const { id: verticalSectionId } = verticalFlexSectionData;

  const childrenArray = new Y.Array();
  childrenArray.push([verticalSectionId]);

  return { horizontalFlexSectionData, verticalFlexSectionData, childrenArray };
};
