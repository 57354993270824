import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { LOGIN_PATH, LOGOUT_PATH } from '@/consts/paths';
import { getUserData, logout } from '@/queries/authenticationApi';

export const useUser = () => {
  const router = useRouter();

  const {
    data: user,
    isError,
    isLoading,
    remove
  } = useQuery({
    queryKey: ['userData'],
    queryFn: getUserData
  });

  const onLogout = useCallback(async () => {
    remove();
    await logout();

    void router.push({
      pathname: LOGOUT_PATH,
      query: { redirectTo: router.asPath }
    });
  }, [remove, router]);

  const onLogin = useCallback(() => {
    void router.push({
      pathname: LOGIN_PATH,
      query: { redirectTo: router.asPath }
    });
  }, [router]);

  return {
    isError,
    isLoading,
    onLogin,
    onLogout,
    user
  };
};
