import { Box, Flex, Text, Tooltip } from '@mantine/core';
import ImageNext from 'next/image';
import { ComponentType } from 'react';

import { wrapper, icon, widgetTitle, subTitle } from './ConfigTitle.css';

export type ConfigTitleBaseProps = {
  title?: string;
  subtitle?: string;
  Icon?: ComponentType<{ width: number; height: number }> | string | null;
};

export function ConfigTitleBase(props: ConfigTitleBaseProps) {
  const { Icon, title, subtitle } = props;

  return (
    <Flex className={wrapper} direction="row">
      {Icon && (
        <Box className={icon}>
          {typeof Icon === 'string' ? (
            <ImageNext src={Icon} alt={title ?? ''} width={20} height={20} />
          ) : (
            <Icon width={20} height={20} />
          )}
        </Box>
      )}
      <Flex direction="column">
        <Text className={widgetTitle} span>
          {title}
        </Text>
        <Tooltip multiline position="bottom" label={subtitle}>
          <Text className={subTitle} span>
            {subtitle}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
