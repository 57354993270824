import { getAttributes } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';

/**
 * This extension is used to override the built-in handleClickLink plugin from the Link extension.
 * With the built-in plugin there was a bug that prevented opening a link in the same tab
 * if the editor containing the link was editable.
 * It was fixed by using '_self' as a target if the original target is an empty string.
 */

export const CustomLink = Link.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
          handleClick: (view, pos, event) => {
            if (event.button !== 0) {
              return false;
            }

            const eventTarget = event.target as HTMLElement;

            if (eventTarget.nodeName !== 'A') {
              return false;
            }

            const attrs = getAttributes(view.state, 'link');
            const link = event.target as HTMLLinkElement;

            const href = link?.href ?? attrs.href;
            const target = link?.target ?? attrs.target;

            if (link && href) {
              if (view.editable) {
                window.open(href, target ? target : '_self');
              }

              return true;
            }

            return false;
          }
        }
      })
    ];
  }
});
