import * as Y from 'yjs';

import { AnswerConfig } from '../types/AnswerConfig';
import { ObjectFit } from '../types/Image';
import { genRandId } from '../utils/genRandId';

export const generateDefaultAnswer = (config: AnswerConfig, customContent?: string) => {
  const { hasAudio, hasImage, hasText, hasVideo } = config;
  const id = genRandId();
  const map = new Y.Map([['id', id]]);

  if (hasText) {
    const fragment = new Y.XmlFragment();
    const paragraph = new Y.XmlElement('paragraph');
    const text = new Y.XmlText(customContent);

    paragraph.insert(0, [text]);
    fragment.insert(0, [paragraph]);

    map.set('text', new Y.Map([['fragment', fragment]]));
  }

  if (hasImage) {
    map.set(
      'image',
      new Y.Map([
        ['src', ''],
        ['alt', ''],
        ['mobileSrc', ''],
        ['objectFit', ObjectFit.Contain],
        ['mobileObjectFit', ObjectFit.Contain]
        // isDecorative property remains undefined, since it's not used for the nested image
      ])
    );
  }

  if (hasAudio) {
    map.set(
      'audio',
      new Y.Map([
        ['src', ''],
        ['title', '']
      ])
    );
  }

  if (hasVideo) {
    map.set('video', new Y.Map());
  }

  return [map, id] as const;
};
