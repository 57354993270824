import { Flex, rem } from '@mantine/core';

import { ImageAnswerThumbnail } from './ImageAnswerThumbnail';

export type ImageThumbnail = { name: string; src: string };

type ImageThumbnailListProps = {
  isDeleteDisabled?: boolean;
  images: ImageThumbnail[];
  onDeleteImage: (index: number) => void;
};

export function ImageAnswerThumbnails(props: ImageThumbnailListProps) {
  const { images, onDeleteImage, isDeleteDisabled } = props;

  return (
    <Flex gap={rem(10)} direction="row" wrap="wrap">
      {images.map((image, index) => (
        <ImageAnswerThumbnail
          key={index}
          image={image}
          onDeleteImage={() => onDeleteImage(index)}
          isDeleteDisabled={isDeleteDisabled}
        />
      ))}
    </Flex>
  );
}
