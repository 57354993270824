import { Box, Flex, Radio } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { GroupCheckButtonMode } from 'shared/types/QuestionGroupList';

import { useStyles } from './Feedback.styles';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function GroupCheckButtonSettings() {
  const t = useTranslations('widgets');
  const [groupCheckButtonMode, setGroupCheckButtonMode] =
    useContentObjectProperty<`${GroupCheckButtonMode}`>('groupCheckButtonMode');

  const { classes } = useStyles();

  return (
    <Box className={classes.extendedOptions}>
      <Radio.Group
        value={groupCheckButtonMode}
        onChange={value => setGroupCheckButtonMode(value as `${GroupCheckButtonMode}`)}
      >
        <Flex direction="column" gap={12}>
          {Object.values(GroupCheckButtonMode).map(mode => (
            <Radio key={mode} value={mode} label={t(`feedback.checkButton.${mode}`)} />
          ))}
        </Flex>
      </Radio.Group>
    </Box>
  );
}
