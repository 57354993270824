import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderRadiusStyle } from '@/utils/theme.utils';
import { useWorkMode } from '@/utils/useWorkMode';

type PlaceholderStyleProps = {
  isContentOnly?: boolean;
  isError?: boolean;
};

export const useStyles = createStyles((theme, props: PlaceholderStyleProps) => {
  const { isContentOnly, isError } = props;
  const { isStudioEdit } = useWorkMode();

  const svgMaxSize = isStudioEdit ? rem(50) : rem(100);

  const mediaStyle = theme.other.media;
  const borderRadiusStyle = getThemeBorderRadiusStyle(mediaStyle.borderRadius);

  return {
    placeholderContainer: {
      width: '100%',
      height: '100%',
      containerType: isStudioEdit ? 'size' : 'normal',
      containerName: 'placeholder'
    },
    placeholderContent: {
      border: isContentOnly ? 'none' : `2px dashed ${theme.colors.accent[3]}`,
      backgroundColor: isError ? theme.colors.pink[0] : isContentOnly ? 'transparent' : theme.colors.accent[0],
      overflow: isContentOnly ? 'initial' : 'auto',
      maxHeight: '100%',
      width: '100%',
      height: '100%',
      ...borderRadiusStyle
    },
    placeholderIcon: {
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        maxHeight: svgMaxSize,
        maxWidth: svgMaxSize,
        width: '100%',
        height: '100%'
      }
    },
    placeholderText: {
      width: '75%',
      display: 'none',
      '@container placeholder (min-width: 300px) and (min-height: 150px)': {
        display: 'initial'
      }
    },
    errorIcon: {
      position: 'absolute',
      margin: rem(8),
      zIndex: 1,
      right: 0,
      top: 0
    }
  };
});
