import { useCallback } from 'react';

import { genRandId } from 'shared/utils/genRandId';
import { validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const useDuplicateAnswer = () => {
  const { document, data } = useContentObject();

  return useCallback(
    (answerIndex: number) => {
      document.transact(() => {
        if (!data) {
          throw new Error('Content object data is not present.');
        }
        const answersData = validateYArray(data.get('answers'));
        const currentAnswer = validateYMap(answersData.get(answerIndex));
        const currentAnswerClone = currentAnswer.clone();

        const newId = genRandId();
        currentAnswerClone.set('id', newId);

        answersData.insert(answerIndex + 1, [currentAnswerClone]);
      });
    },
    [document, data]
  );
};
