import { AnswerAreaThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { DeviceType } from 'shared/types/DeviceType';

import { AnswerStyles } from '@/components/AnswerOption/types';
import { getThemeBorderRadiusStyle, getThemeBorderStyle, PROSE_MIRROR_SELECTOR } from '@/utils/theme.utils';

type Props = {
  answerAreaThemeProps: AnswerAreaThemeProps;
  answersStyleType: AnswerStyleType;
  deviceView?: DeviceType;
  isChecked?: boolean;
  isTextOnly?: boolean;
};

export const useStyles = createStyles((theme, props: Props) => {
  const { answersStyleType, isChecked, isTextOnly, deviceView, answerAreaThemeProps } = props;
  const isMobile = deviceView === 'mobile';
  const globalContentColorsTheme = theme.other.global.content.colors;

  const borderStyle = getThemeBorderStyle(answerAreaThemeProps.border, globalContentColorsTheme.decoration.main, {
    borderColor: isChecked
      ? theme.colors.green[6]
      : answerAreaThemeProps.border.borderColor ?? globalContentColorsTheme.decoration.main
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(answerAreaThemeProps.borderRadius);
  const color = answerAreaThemeProps.color ?? globalContentColorsTheme.secondary.contrast;

  const answerStyles: Record<AnswerStyleType, AnswerStyles> = {
    Default: {
      checkboxInner: {
        marginTop: rem(10)
      }
    },
    Button: {
      checkboxLabel: {
        marginInlineStart: 0,
        padding: `0 !important`
      }
    },
    CheckboxWithoutBorder: {
      checkboxInner: {
        marginTop: rem(10)
      }
    }
  };

  return {
    checkboxLabel: {
      lineHeight: 'normal',
      backgroundColor: answerAreaThemeProps.backgroundColor ?? globalContentColorsTheme.secondary.main,
      ...borderStyle,
      ...borderRadiusStyle,
      marginInlineStart: rem(12),
      height: '100%',
      flexGrow: 1,
      maxWidth: isTextOnly || isMobile ? '100%' : rem(214),
      width: 'auto',
      [PROSE_MIRROR_SELECTOR]: { color, lineHeight: answerAreaThemeProps.lineHeight },
      ...answerStyles[answersStyleType].checkboxLabel,
      '.ProseMirror p.is-editor-empty:first-of-type::before': {
        color: answerAreaThemeProps.placeholderColor,
        lineHeight: answerAreaThemeProps.lineHeight
      }
    },
    checkboxLabelWrapper: {
      flexGrow: 1
    },
    checkboxInner: {
      ...answerStyles[answersStyleType].checkboxInner,
      ...(isMobile && { display: 'none' })
    },
    checkboxRoot: {
      ...answerStyles[answersStyleType].checkboxRoot
    },
    checkboxBody: {
      ...answerStyles[answersStyleType].checkboxBody,
      height: '100%'
    },
    checkboxInput: {
      ...answerStyles[answersStyleType].checkboxInput
    },
    contentArea: {
      ...answerStyles[answersStyleType].contentArea
    }
  };
});
