import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useStyles } from './SubmitPageComment.styles';
import { Manifest } from '@/widgets/SubmitPageComment/Manifest';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import { StudioProps } from '@/widgets/types/studioProps';

export function SubmitPageComment(props: StudioProps) {
  const { initialize } = props;

  const t = useTranslations('widgets.submitPageComment');

  const [settingsPlaceholder] = useContentObjectProperty<string>('placeholder');
  const placeholder = settingsPlaceholder || t('writeNoteToTeacher');

  const { classes } = useStyles();

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <Box className={classes.comment}>
      <RichText dataTestIdPrefix="comment" placeholder={placeholder} contentEditable={false} />
    </Box>
  );
}
