import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type TableTooltipStyles = {
  type?: 'column' | 'row';
  isDndPreview?: boolean;
  isGlobalDragging?: boolean;
};

export const useStyles = createStyles((theme, props: TableTooltipStyles) => {
  const { type = 'column', isDndPreview = false, isGlobalDragging = false } = props ?? {};

  return {
    group: {
      gap: 0,
      borderRadius: rem(16),
      overflow: 'hidden',
      width: type === 'column' ? rem(100) : rem(26),
      height: type === 'column' ? rem(26) : rem(100),
      backgroundColor: theme.colors.blue[5],
      flexDirection: type === 'column' ? 'row' : 'column'
    },
    button: {
      flex: 1,
      flexGrow: 1, //override a mantine value
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      color: 'white',
      backgroundColor: theme.colors.blue[5],
      '&:hover': {
        backgroundColor: theme.colors.blue[6]
      },
      '&[disabled]': {
        backgroundColor: theme.colors.blue[3],
        cursor: 'not-allowed'
      },
      ':first-of-type': {
        paddingInlineStart: type === 'column' ? rem(4) : 0,
        paddingBlockStart: type === 'row' ? rem(4) : 0
      },
      ':last-of-type': {
        paddingInlineEnd: type === 'column' ? rem(4) : 0,
        paddingBlockEnd: type === 'row' ? rem(4) : 0
      }
    },
    dragHandleIcon: {
      rotate: type === 'column' ? '90deg' : '0deg'
    },
    tooltipColumnAreaCell: {
      height: rem(26),
      padding: `${rem(2)} ${rem(4)}`,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:last-of-type': {
        flexGrow: 1
      },
      '*': {
        visibility: isDndPreview && isGlobalDragging ? 'visible' : 'hidden'
      },
      ':hover': {
        '*': {
          visibility: isGlobalDragging ? 'hidden' : 'visible'
        }
      }
    },
    tooltipRowAreaCell: {
      transform: 'translate(calc(-100% * var(--direction-multiplier)), -50%)',
      top: '50%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: rem(26),
      '*': {
        visibility: isDndPreview && isGlobalDragging ? 'visible' : 'hidden'
      },
      ':hover': {
        '*': {
          visibility: isGlobalDragging ? 'hidden' : 'visible'
        }
      }
    }
  };
});
