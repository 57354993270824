import { AuthenticationModuleClientType } from '@hendrx/modules/authentication';
import { Overlay } from '@mantine/core';
import { useGoogleLogin } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import GoogleButton from 'react-google-button';

import { GOOGLE_AUTH_REDIRECT_URI, GOOGLE_SCOPE } from '../../consts';
import { onShowErrorNotification } from '../notifications';

export const LoginPage: AuthenticationModuleClientType['LoginPage'] = () => {
  const {
    query: { redirectTo }
  } = useRouter();
  const t = useTranslations('external-modules.authenticationGoogle.notifications');

  const showErrorNotification = useCallback(
    () => onShowErrorNotification(t('loginFail.title'), t('loginFail.message')),
    [t]
  );

  const onLogin = useGoogleLogin({
    flow: 'auth-code',
    // TODO doesn't work. See more: https://github.com/MomenSherif/react-oauth/issues/226
    include_granted_scopes: false,
    onError: showErrorNotification,
    onNonOAuthError: showErrorNotification,
    redirect_uri: GOOGLE_AUTH_REDIRECT_URI,
    scope: GOOGLE_SCOPE,
    state: JSON.stringify({ redirectTo }),
    ux_mode: 'redirect'
  });

  return (
    <Overlay center backgroundOpacity={0}>
      <GoogleButton onClick={onLogin} />
    </Overlay>
  );
};
