import { Box } from '@mantine/core';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { Hoverable } from '@/components/Hoverable';
import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { Selectable } from '@/components/Selectable';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { PluginScriptContext } from '@/contentObjects/Plugin/utils/pluginScriptContext';
import { getQuestionBulletWrapperData, shouldShowCheckButton, shouldShowScoreWeight } from '@/utils/questions.utils';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';
import { useIsQuestionEmpty } from '@/widgets/hooks/useIsQuestionEmpty';

type StudioQuestionStageProps = {
  id: string;
  script: string;
};

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

export function StudioQuestionStage(props: StudioQuestionStageProps) {
  const { id, script } = props;

  const { document } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [feedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const [scoreWeight] = useContentObjectScoreWeight();
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();

  const [flexSectionId] = useContentObjectProperty<string>('children.0');
  const isEmpty = useIsQuestionEmpty();

  const isRequired = isQuestionRequired(id, document);

  const questionGroupSettings = useParentQuestionGroupSettings();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(feedback, questionGroupSettings);

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  return (
    <>
      {labelFragment && (
        <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
          <Selectable>
            <Hoverable>
              <Box className={`${questionClasses.label} ${ThemeClassNames.widgets.evaluatablePlugin.label}`}>
                <QuestionTitle
                  questionId={id}
                  fragment={labelFragment}
                  dataTestIdPrefix="studio-plugin-question-title"
                />
              </Box>
            </Hoverable>
          </Selectable>
        </ListBulletWrapper>
      )}
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <PluginScriptContext.Provider value={script}>
          <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
            <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
              {isEmpty && <DropzonePlaceholder type="contentItems" />}
              <FlexSectionStudio selectable />
            </DndAllowedItemsContextProvider>
          </ContentObjectProvider>
        </PluginScriptContext.Provider>
        <QuestionFooter
          questionId={id}
          hintFragment={hintFragment}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </>
  );
}
