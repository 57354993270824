import { useTranslations } from 'next-intl';
import { memo } from 'react';
import * as Y from 'yjs';

import { ToolbarType } from 'shared/types/RichTextToolbar';

import { useTable } from '@/widgets/Table/hooks/useTable';
import { getCellInfoFromContentId } from '@/widgets/Table/utils';
import { RichText } from '@/widgets/_components/RichText';

type TableCellRendererProps = {
  contentId: string;
};

export const TableCellRenderer = memo(function TableCellRenderer(props: TableCellRendererProps) {
  const { contentId } = props;

  const content = useTable(context => context.content);
  const rowOrder = useTable(context => context.rowOrder);
  const columnOrder = useTable(context => context.columnOrder);

  const [columnId, rowId] = getCellInfoFromContentId(contentId);

  const t = useTranslations('widgets.tableWidget');

  const rowIndex = rowOrder.indexOf(rowId);
  const columnIndex = columnOrder.indexOf(columnId);
  const fragment = content?.get(contentId) as Y.XmlFragment;

  const isHeader = rowIndex === -1;
  return (
    <RichText
      fragment={fragment}
      placeholder={
        isHeader
          ? t('header', { columnIndex: columnIndex + 1 })
          : t('cell', { columnIndex: columnIndex + 1, rowIndex: rowIndex + 1 })
      }
      toolbarType={ToolbarType.Fixed}
    />
  );
});
