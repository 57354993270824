import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewBlockTitleData } from 'shared/widgetsSDK/BlockTitle/create';

import { manifestLocales } from './manifest.locales';
import BlockTitleEn from './resources/en/block-title.svg';
import BlockTitleHe from './resources/he/block-title.svg';

export const Manifest: ManifestType = {
  name: WidgetType.BlockTitle,
  previewGroups: [
    {
      id: 'blockTitle',
      divider: true,
      previews: [
        {
          name: 'blockTitlePreview',
          previewImage: {
            paths: {
              en: BlockTitleEn,
              he: BlockTitleHe
            },
            alt: { ...manifestLocales.blockTitle.previewImageAlt },
            size: {
              width: 285,
              height: 60
            }
          },
          initializeProps: options =>
            createNewBlockTitleData(options, {
              content: `<h1><span>${manifestLocales.blockTitle.initialText[options?.locale ?? 'he']}</span></h1>`
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
