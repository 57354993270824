import { Tooltip } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useRef } from 'react';
import * as Y from 'yjs';

import { useStyles } from './ClozeOption.styles';
import { ClozeOptionValue } from './ClozeOptionValue';
import { useOverflowText } from '@/utils/useOverflowText';
import { useTiptapClozeOptionsStore } from '@/widgets/ClozeQuestion/studio/hooks/useTiptapClozeOptionsStore';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function ClozeOption(props: NodeViewProps) {
  const {
    node: {
      attrs: { clozeId, fontFamily, fontSize, type }
    }
  } = props;

  const selectedClozeId = useTiptapClozeOptionsStore(state => state.selectedClozeId);

  const ref = useRef<HTMLDivElement>(null);

  const { classes } = useStyles({
    fontFamily,
    fontSize,
    isSelected: selectedClozeId === clozeId
  });

  const [clozeOptions] = useContentObjectProperty<Y.Map<Y.Map<unknown>>>(`clozeOptions`);
  const overflowText = useOverflowText(ref);

  return (
    <NodeViewWrapper ref={ref} className={classes.componentWithContent}>
      {clozeId && clozeOptions.get(clozeId) ? (
        <Tooltip label={overflowText} disabled={!(type === ClozeOptionType.Select && overflowText)} multiline maw={600}>
          <ClozeOptionValue clozeId={clozeId} fontFamily={fontFamily} fontSize={fontSize} type={type} />
        </Tooltip>
      ) : null}
    </NodeViewWrapper>
  );
}
