import { Box, Button, Flex } from '@mantine/core';
import { ReactElement } from 'react';

import { useStyles } from './IconButton.styles';

type IconButtonProps = {
  dataTestId?: string;
  Icon: ReactElement;
  onClick?: () => void;
};

export function IconButton(props: IconButtonProps) {
  const { Icon, onClick, dataTestId } = props;

  const { classes } = useStyles();

  return (
    <Button onClick={onClick} className={classes.button} data-testid={`${dataTestId}-icon-button`}>
      <Flex justify="center" align="center" direction="column">
        <Box className={classes.icon}>{Icon}</Box>
      </Flex>
    </Button>
  );
}
