import { SaveElementRequest, SaveElementResponse } from 'shared/types/dto/State';

export const saveElementState = async (args: SaveElementRequest): Promise<SaveElementResponse> => {
  const encodedArgs = {
    ...args,
    elements: args.elements.map(element => ({ ...element, state: btoa(escape(JSON.stringify(element.state))) }))
  };

  const response = await fetch('/api/state/save-element', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(encodedArgs)
  });

  if (!response.ok) {
    throw new Error(`Save element state failed (${response.status}): ${response.statusText}`, {
      cause: response
    });
  }

  return;
};

type GetSavedStateOptions = {
  assignmentId?: string;
  learningObjectDraftId?: string;
  plenaryId?: string;
  userId: string;
};

export const getSavedState = async (args: GetSavedStateOptions) => {
  if (!args.learningObjectDraftId && !args.assignmentId) {
    throw new Error('assignmentId or learningObjectDraftId has to be present');
  }

  const { learningObjectDraftId, assignmentId, userId, plenaryId } = args;

  const params = new URLSearchParams({
    userId,
    ...(learningObjectDraftId && { learningObjectDraftId }),
    ...(assignmentId && { assignmentId }),
    ...(plenaryId && { plenaryId })
  });

  const response = await fetch(`/api/state?${params.toString()}`, {
    method: 'GET'
  });

  if (!response.ok) {
    throw new Error(`Fetching saved state failed (${response.status}): ${response.statusText}`, {
      cause: response
    });
  }

  const result = await response.json();
  return result;
};

type SubmitFeedbackForAssignmentOptions = {
  assignmentId: string;
  studentId?: string;
  returnToFix?: boolean;
};

export const submitFeedbackForAssignment = async ({
  assignmentId,
  studentId,
  returnToFix
}: SubmitFeedbackForAssignmentOptions) => {
  if (!assignmentId || !studentId) {
    return;
  }

  const response = await fetch('/api/assignment/submit-feedback', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ assignmentId, studentId, returnToFix })
  });

  if (!response.ok) {
    throw new Error(`Upserting feedback for the assignment failed (${response.status}): ${response.statusText}`, {
      cause: response
    });
  }
};
