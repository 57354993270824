import { Box } from '@mantine/core';
import cx from 'clsx';
import { useEffect } from 'react';
import * as Y from 'yjs';

import { OpenQuestionElementType } from 'shared/types/OpenQuestion';
import { WidgetType } from 'shared/utils/widgets';

import { Hoverable } from '@/components/Hoverable';
import { Selectable } from '@/components/Selectable';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { answerStyles, placeholderStyles, textStyles } from '@/widgets/OpenQuestion/OpenQuestion.css';
import { Base as TextBase } from '@/widgets/Text/studio/Base';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { AnswerProvider } from '@/widgets/_components/questions/AnswerProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function OpenQuestionAnswerStudio(props: StudioProps) {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.OpenQuestion}
      withOrchestrationWrapper={false}
    >
      <OpenQuestionAnswerStudioImplementation {...props} />
    </ContentObjectProvider>
  );
}

export function OpenQuestionAnswerStudioImplementation(props: StudioProps) {
  const { initialize } = props;
  const placeholderFragment = useContentObjectStaticProperty<Y.XmlFragment>('placeholderFragment');
  const [showElements] = useContentObjectProperty<Record<OpenQuestionElementType, boolean>>('showElements');

  useEffect(() => {
    initialize?.({
      actions: [],
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <div>
      {showElements.text && (
        <Box className={`${answerStyles.base} ${ThemeClassNames.widgets.openQuestion.answer}`}>
          <Selectable>
            <Hoverable>
              <Box className={cx(textStyles, placeholderStyles)}>
                <TextBase
                  expandHeight
                  fragment={placeholderFragment}
                  dataTestIdPrefix="studio-open-question-placeholder"
                  disableTypographyInheritance
                />
              </Box>
            </Hoverable>
          </Selectable>
        </Box>
      )}
      {(showElements.image || showElements.file || showElements.recording) && (
        <AnswerProvider visibleElements={showElements} />
      )}
    </div>
  );
}
