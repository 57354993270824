import { FC } from 'react';

import { WidgetType } from 'shared/utils/widgets';

import { ClozeQuestionAnswerPlayer } from '@/contentObjects/ClozeQuestionAnswer/ClozeQuestionAnswerPlayer';
import { EvaluatablePluginAnswerPlayer } from '@/contentObjects/EvaluatablePluginAnswer/EvaluatablePluginAnswerPlayer';
import { FillInTheBlanksQuestionAnswerPlayer } from '@/contentObjects/FillInTheBlanksQuestionAnswer/FillInTheBlanksQuestionAnswerPlayer';
import { InteractivePollAnswerPlayer } from '@/contentObjects/InteractivePollAnswer/InteractivePollAnswerPlayer';
import { InteractiveQuizAnswerPlayer } from '@/contentObjects/InteractiveQuizAnswer/InteractiveQuizAnswerPlayer';
import { OpenQuestionAnswerPlayer } from '@/contentObjects/OpenQuestionAnswer/OpenQuestionAnswerPlayer';
import { EvaluatablePlayer as EvaluatablePluginPlayer } from '@/contentObjects/Plugin';
import { Player as PluginPlayer } from '@/contentObjects/Plugin';
import { SelectionQuestionAnswerPlayer } from '@/contentObjects/SelectionQuestionAnswer/SelectionQuestionAnswerPlayer';
import { Audio as AudioPlayer } from '@/widgets/Audio/player';
import { BlockTitle as BlockTitlePlayer } from '@/widgets/BlockTitle/player';
import { ClozeQuestion as ClozeQuestionPlayer } from '@/widgets/ClozeQuestion/player';
import { CombinedTitle as CombinedTitlePlayer } from '@/widgets/CombinedTitle/player';
import { Embed as EmbedPlayer } from '@/widgets/Embed/player';
import { EnclosureWithBlockTitle as EnclosureWithBlockTitlePlayer } from '@/widgets/EnclosureWithBlockTitle/player';
import { EnclosureWithCombinedTitle as EnclosureWithCombinedTitlePlayer } from '@/widgets/EnclosureWithCombinedTitle/player';
import { FillInTheBlanksQuestion as FillInTheBlanksQuestionPlayer } from '@/widgets/FillInTheBlanksQuestion/player';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import { Image as ImagePlayer } from '@/widgets/Image/player';
import { InteractivePollPlayer } from '@/widgets/InteractivePoll/player';
import { InteractiveQuizPlayer } from '@/widgets/InteractiveQuiz/player';
import { MicrosoftEmbed as MicrosoftEmbedPlayer } from '@/widgets/MicrosoftEmbed/player';
import { OpenDiscussion as OpenDiscussionPlayer } from '@/widgets/OpenDiscussion/player';
import { OpenQuestion as OpenQuestionPlayer } from '@/widgets/OpenQuestion/player';
import { PdfDocument as PdfDocumentPlayer } from '@/widgets/PdfDocument/player';
import { QuestionGroupList as QuestionGroupListPlayer } from '@/widgets/QuestionGroupList/player';
import { SelectionQuestion as SelectionQuestionPlayer } from '@/widgets/SelectionQuestion/player';
import { Spacer as SpacerPlayer } from '@/widgets/Spacer/player';
import { SubmitPageComment as SubmitPageCommentPlayer } from '@/widgets/SubmitPageComment/player';
import { SubmitPageReturnToFixSwitch as SubmitPageReturnToFixSwitchPlayer } from '@/widgets/SubmitPageReturnToFixSwitch/player';
import { SubmitPageSatisfactionSurvey as SubmitPageSatisfactionSurveyPlayer } from '@/widgets/SubmitPageSatisfactionSurvey/player';
import { SubmitPageTitle as SubmitPageTitlePlayer } from '@/widgets/SubmitPageTitle/player';
import { SubmitPageUnansweredQuestions as SubmitPageUnansweredQuestionsPlayer } from '@/widgets/SubmitPageUnansweredQuestions/player';
import { SummaryPageSubmissionHistory as SummaryPageSubmissionHistoryPlayer } from '@/widgets/SummaryPageSubmissionHistory/player';
import { SummaryPageTaskSubmittedNotification as SummaryPageTaskSubmittedNotificationPlayer } from '@/widgets/SummaryPageTaskSubmittedNotification/player';
import { Table as TablePlayer } from '@/widgets/Table/player';
import { Text as TextPlayer } from '@/widgets/Text/player';
import { Video as VideoPlayer } from '@/widgets/Video/player';
import { Player as AvatarWidgetPlayer } from '@/widgets/AvatarWidget/Player';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const playerWidgets: Record<WidgetType, FC<any>> = {
  AvatarWidget: AvatarWidgetPlayer,
  Audio: AudioPlayer,
  BlockTitle: BlockTitlePlayer,
  ClozeQuestion: ClozeQuestionPlayer,
  ClozeQuestionAnswer: ClozeQuestionAnswerPlayer,
  CombinedTitle: CombinedTitlePlayer,
  Embed: EmbedPlayer,
  EnclosureWithBlockTitle: EnclosureWithBlockTitlePlayer,
  EnclosureWithCombinedTitle: EnclosureWithCombinedTitlePlayer,
  EvaluatablePlugin: EvaluatablePluginPlayer,
  EvaluatablePluginAnswer: EvaluatablePluginAnswerPlayer,
  FillInTheBlanksQuestion: FillInTheBlanksQuestionPlayer,
  FillInTheBlanksQuestionAnswer: FillInTheBlanksQuestionAnswerPlayer,
  FlexSection: FlexSectionPlayer,
  Image: ImagePlayer,
  InteractivePoll: InteractivePollPlayer,
  InteractivePollAnswer: InteractivePollAnswerPlayer,
  InteractiveQuiz: InteractiveQuizPlayer,
  InteractiveQuizAnswer: InteractiveQuizAnswerPlayer,
  MicrosoftEmbed: MicrosoftEmbedPlayer,
  OpenDiscussion: OpenDiscussionPlayer,
  OpenQuestion: OpenQuestionPlayer,
  OpenQuestionAnswer: OpenQuestionAnswerPlayer,
  Page: () => null,
  PdfDocument: PdfDocumentPlayer,
  Plugin: PluginPlayer,
  QuestionGroupList: QuestionGroupListPlayer,
  Section: () => null,
  SelectionQuestion: SelectionQuestionPlayer,
  SelectionQuestionAnswer: SelectionQuestionAnswerPlayer,
  Slide: () => null,
  Spacer: SpacerPlayer,
  SubmitPage: () => null,
  SubmitPageComment: SubmitPageCommentPlayer,
  SubmitPageReturnToFixSwitch: SubmitPageReturnToFixSwitchPlayer,
  SubmitPageSatisfactionSurvey: SubmitPageSatisfactionSurveyPlayer,
  SubmitPageTitle: SubmitPageTitlePlayer,
  SubmitPageUnansweredQuestions: SubmitPageUnansweredQuestionsPlayer,
  SummaryPage: () => null,
  SummaryPageSubmissionHistory: SummaryPageSubmissionHistoryPlayer,
  SummaryPageTaskSubmittedNotification: SummaryPageTaskSubmittedNotificationPlayer,
  Table: TablePlayer,
  Text: TextPlayer,
  Video: VideoPlayer
};
