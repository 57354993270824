import { ActionIcon, Flex, Text } from '@mantine/core';

import { Cross } from '@/icons/Cross';
import { useStyles } from '@/widgets/FillInTheBlanksQuestion/FillInTheBlanksQuestion.styles';

type WordBankOptionProps = {
  children: string;
  onRemove: () => void;
};

export function WordBankOption(props: WordBankOptionProps) {
  const { children, onRemove } = props;

  const { classes } = useStyles({});

  return (
    <Flex className={classes.wordBankOption}>
      <Text className={classes.wordBankOptionText} data-testid="studio-word-bank-option">
        {children}
      </Text>
      <ActionIcon variant="subtle" size="xs" onClick={onRemove}>
        <Cross />
      </ActionIcon>
    </Flex>
  );
}
