import { useTranslations } from 'next-intl';

import NumberInput from '@/components/NumberInput/NumberInput';
import { useStyles } from '@/widgets/QuestionGroupList/config/design/Design.styles';

type AnswerCountProps = {
  value: number;
  setValue: (newValue: number | ((prevState: number) => number)) => void;
  max?: number;
  min?: number;
};

export function AnswerCount(props: AnswerCountProps) {
  const { value, setValue, max, min } = props;

  const { classes } = useStyles();
  const t = useTranslations('widgets');

  return (
    <div className={classes.numberInput}>
      <span>{t('questionGroupListSettings.answer')}</span>
      <NumberInput setValue={setValue} value={value} min={min} max={max} />
      <span>
        {t('questionGroupListSettings.outOf')} {max}
      </span>
    </div>
  );
}
