import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewCombinedTitleData } from 'shared/widgetsSDK/CombinedTitle/create';

import { manifestLocales } from './manifest.locales';
import CombinedTitleEn from './resources/en/combined-title.svg';
import CombinedTitleHe from './resources/he/combined-title.svg';

export const Manifest: ManifestType = {
  name: WidgetType.CombinedTitle,
  previewGroups: [
    {
      id: 'combinedTitle',
      divider: true,
      previews: [
        {
          name: 'combinedTitlePreview',
          previewImage: {
            paths: {
              en: CombinedTitleEn,
              he: CombinedTitleHe
            },
            alt: { ...manifestLocales.combinedTitle.previewImageAlt },
            size: {
              width: 285,
              height: 64
            }
          },
          initializeProps: options =>
            createNewCombinedTitleData(options, {
              content: `<h1><span>${manifestLocales.combinedTitle.initialText[options?.locale ?? 'he']}</span></h1>`
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
