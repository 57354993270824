import { AudioSchemaType } from '@/widgets/Audio/Schema';
import { Base } from '@/widgets/Video/config/Base';
import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Config() {
  const [title, setTitle] = useContentObjectProperty<string>('title');
  const [errors] = useContentObjectErrors<AudioSchemaType>();

  return <Base title={title} setTitle={setTitle} errors={errors} />;
}
