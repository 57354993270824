import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  const { assignTaskButton } = theme.other.topbar.player;

  return {
    assignTaskButton: {
      ...assignTaskButton.standard,

      '&:hover': assignTaskButton.hovered
    }
  };
});
