import { WidgetType } from 'shared/utils/widgets';

import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useCombinedTitleStyleProperties } from '@/widgets/CombinedTitle/hooks/useCombinedTitleStyleProperties';
import { Player as FlexSection } from '@/widgets/FlexSection/player';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';

export function CombinedTitle() {
  const { document, id } = useContentObject();
  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');
  const { alignItems, gap, itemsOrder } = useCombinedTitleStyleProperties(id, document);
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  return (
    <div className={customClassNames}>
      <ContentObjectProvider id={horizontalSectionId} document={document} type={WidgetType.FlexSection}>
        <FlexSection alignItems={alignItems} gap={gap} itemsOrder={itemsOrder} shouldApplyNestStyles={false} />
      </ContentObjectProvider>
    </div>
  );
}
