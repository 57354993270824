import { ReactNodeViewRenderer } from '@tiptap/react';

import { BaseFillInTheBlanksExtension } from 'shared/tiptap/BaseFillInTheBlanksExtension';

import { Option } from './Option';

export const FillInTheBlanksExtension = BaseFillInTheBlanksExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(Option);
  }
});
