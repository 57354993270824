export const manifestLocales = {
  openQuestion: {
    previewOpenQuestionAlt: {
      en: 'openQuestion widget',
      he: 'שאלה פתוחה'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    },
    answerPlaceholder: {
      en: 'Answer placeholder',
      he: 'ממלא מקום לתשובה'
    }
  }
};
