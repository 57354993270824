import { clientModules } from '@hendrx/modules';
import { ActivityThemeStyles } from '@hendrx/modules/ui';
import { Button, Tooltip } from '@mantine/core';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { useTranslations } from 'next-intl';

import { useStyles } from './ClearActivityStyleProperty.styles';
import { useCurrentDocument } from '@/components/YjsProvider';
import { getThemeDefaultObjectValues, getThemeDefaultValue } from '@/contentObjects/Root/ActivityThemeStylesUtils';
import { useParentTheme } from '@/contentObjects/Root/hooks/useParentTheme';
import { Reset } from '@/icons/Reset';
import { getRootMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

type ClearActivityStylePropertyProps = {
  path: string;
  globalPath?: string;
  partialGlobalPath?: string;
  pathsBatch?: string[];
};

export function ClearActivityStyleProperty(props: ClearActivityStylePropertyProps) {
  const { path, globalPath, partialGlobalPath, pathsBatch } = props;

  const t = useTranslations('components.theme');
  const { classes } = useStyles();

  const document = useCurrentDocument();
  const rootMap = getRootMap(document);

  const [activityThemeStyles, setActivityThemeStyles] = useObservedProperty<Partial<ActivityThemeStyles>>(
    rootMap,
    'activityThemeStyles'
  );
  const activityThemeValue = get(activityThemeStyles, path);
  const { data: parentTheme } = useParentTheme();

  const themeDefaultValue = getThemeDefaultValue(
    activityThemeStyles,
    parentTheme?.styles,
    clientModules.ui.defaultActivityThemeStyles,
    path,
    globalPath
  );

  const themeDefaultObjectValues = getThemeDefaultObjectValues(
    themeDefaultValue,
    activityThemeStyles,
    parentTheme?.styles,
    clientModules.ui.defaultActivityThemeStyles,
    partialGlobalPath
  );

  const getThemeLabel = (path: string) => {
    const parentThemeLabel = path.includes('fontFamily')
      ? clientModules.ui.fonts.find(font => font.value === themeDefaultValue)?.label
      : globalPath?.includes('fontFamily')
      ? clientModules.ui.fonts.find(font => font.value === themeDefaultValue)?.label
      : themeDefaultValue;
    return parentThemeLabel;
  };

  const parentThemeLabel = typeof themeDefaultValue === 'object' ? themeDefaultObjectValues : getThemeLabel(path);

  const clearStyleProperty = () => {
    setActivityThemeStyles(prevTheme => omit(structuredClone(prevTheme), pathsBatch ? pathsBatch : path));
  };

  return isNil(activityThemeValue) || isEqual(activityThemeValue, themeDefaultValue) ? null : (
    <Tooltip
      position="bottom"
      label={t('parentThemeDefaultValue', {
        value: `${parentThemeLabel || '-'}` // Make sure this is always a string, otherwise next-intl throws an error
      })}
    >
      <Button className={classes.clearButton} size="compact-md" onClick={clearStyleProperty}>
        <Reset className={classes.clearIcon} />
      </Button>
    </Tooltip>
  );
}
