import { Flex, Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function Settings() {
  const t = useTranslations('widgets.openDiscussionSettings');

  const [enabledPictureUpload, setEnabledPictureUpload] = useContentObjectProperty<boolean>('enabledPictureUpload');
  const [enabledMultiplePosts, setEnabledMultiplePosts] = useContentObjectProperty<boolean>('enabledMultiplePosts');

  return (
    <FormControl title={t('studentsPostsOptions')} withSeparator>
      <Flex direction="column" justify="space-evenly">
        <Switch
          checked={enabledPictureUpload}
          onChange={event => setEnabledPictureUpload(event.currentTarget.checked)}
          label={t('allowPictureUpload')}
          mb={16}
        />
        <Switch
          checked={enabledMultiplePosts}
          onChange={event => setEnabledMultiplePosts(event.currentTarget.checked)}
          label={t('allowMultiplePosts')}
          mb={16}
        />
      </Flex>
    </FormControl>
  );
}
