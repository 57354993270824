import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useGetAssignmentStatus } from '@/queries/assignment';
import { Manifest } from '@/widgets/SubmitPageTitle/Manifest';
import { useStyles } from '@/widgets/SubmitPageTitle/player/SubmitPageTitle.styles';
import { StudioProps } from '@/widgets/types/studioProps';

export function SubmitPageTitle(props: StudioProps) {
  const { initialize } = props;
  const t = useTranslations('widgets');
  const { classes } = useStyles();
  const { isReturnedToFix } = useGetAssignmentStatus({ enabled: true });

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <h2 className={classes.title}>
      <div className={classes.titleInner}>
        {isReturnedToFix ? t('submitPageTitleWidget.resubmitTitle') : t('submitPageTitleWidget.submitTitle')}
      </div>
    </h2>
  );
}
