import * as Y from 'yjs';

import { InitializePropsOptions, ManifestInitializedProps } from 'shared/types/ManifestType';
import { genRandId } from 'shared/utils/genRandId';
import { ClozeQuestionType, createNewClozeQuestionDataBase } from 'shared/widgetsSDK/ClozeQuestion/create';

import { createNewTableDataBase } from '@/widgets/Table/utils';

export function createNewTableClozeQuestionData(options: InitializePropsOptions): ManifestInitializedProps {
  const {
    contentObjectData: clozeContentObjectData,
    horizontalFlexSectionData,
    questionAnswerData,
    sensitiveData: clozeSensitiveData,
    verticalFlexSectionData
  } = createNewClozeQuestionDataBase(options);
  const { contentObjectData: tableContentObjectData } = createNewTableDataBase(4, 4, options);

  const id = genRandId();

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ...tableContentObjectData,
        ...clozeContentObjectData,
        ['clozeQuestionType', ClozeQuestionType.Table],
        ['id', id]
      ]),
      sensitiveData: new Y.Map([...clozeSensitiveData])
    },
    horizontalFlexSectionData,
    verticalFlexSectionData,
    questionAnswerData
  ];
}
