import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewClozeQuestionData } from 'shared/widgetsSDK/ClozeQuestion/create';
import { manifestLocales } from 'shared/widgetsSDK/ClozeQuestion/manifest.locales';

import { createNewTableClozeQuestionData } from './createTableVersion';
import ClozeQuestionEn from './resources/en/cloze-question.svg';
import TableClozeQuestionHe from './resources/en/cloze-table-question.svg';
import ClozeQuestionHe from './resources/he/cloze-question.svg';
import TableClozeQuestionEn from './resources/he/cloze-table-question.svg';
import { QuestionsDropdown } from '@/icons/QuestionsDropdown';

export const Manifest: ManifestType = {
  name: WidgetType.ClozeQuestion,
  previewGroups: [
    {
      id: 'clozeQuestion',
      divider: {
        icon: QuestionsDropdown,
        title: 'clozeQuestion'
      },
      previews: [
        {
          name: 'clozeQuestionPreview',
          previewImage: {
            paths: {
              en: ClozeQuestionEn,
              he: ClozeQuestionHe
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.clozeQuestion.previewClozeQuestionAlt }
          },
          dimensions: {
            width: 1180,
            height: 'auto'
          },
          initializeProps: createNewClozeQuestionData
        },
        {
          name: 'tableClozeQuestionPreview',
          previewImage: {
            paths: {
              en: TableClozeQuestionEn,
              he: TableClozeQuestionHe
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.clozeQuestion.previewClozeQuestionAlt }
          },
          dimensions: {
            width: 1180,
            height: 'auto'
          },
          initializeProps: createNewTableClozeQuestionData
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
