import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect, useRef } from 'react';

import { useStyles } from './Studio.styles';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { Replace } from '@/icons/Replace';
import { WidgetAction, WidgetAdditionalAction } from '@/types/Stage';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useBasicValidation } from '@/utils/validation';
import { Manifest } from '@/widgets/PdfDocument/Manifest';
import { Schema } from '@/widgets/PdfDocument/Schema';
import { Config } from '@/widgets/PdfDocument/config';
import { Base } from '@/widgets/PdfDocument/studio/Base';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function PdfDocument(props: StudioProps) {
  const { initialize } = props;

  const { data, document, id } = useContentObject();
  const [title] = useContentObjectProperty<string>('title');
  const [src, setSrc] = useContentObjectProperty<string>('src');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const t = useTranslations('widgets');
  const { classes, cx } = useStyles();
  const uploadPdfDocumentActionRef = useRef<() => void>(null);

  useEffect(() => {
    const actions: WidgetAction[] = ['Duplicate', 'Delete'];

    const replacePowerPointAction: WidgetAdditionalAction = {
      actionName: t('pdfDocumentActions.replace'),
      dataTestId: 'widget-additional-action-replace',
      Icon: Replace,
      onClick: () => uploadPdfDocumentActionRef.current?.()
    };

    const shouldKeepAspectRatio = true;
    const isResizableVertically = true;
    const baseData = {
      actions,
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically,
      shouldKeepAspectRatio
    };

    const initData = src
      ? {
          ...baseData,
          additionalActions: [replacePowerPointAction]
        }
      : baseData;

    initialize?.(initData);
  }, [initialize, src, t]);

  useBasicValidation(id, document, data, Schema);

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>

      <Box className={cx(classes.container, customClassNames)}>
        <Base title={title} src={src} setSrc={setSrc} ref={uploadPdfDocumentActionRef} />
      </Box>
    </>
  );
}
