import { Box } from '@mantine/core';
import cx from 'clsx';
import { useEffect } from 'react';
import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';
import { ClozeQuestionType } from 'shared/widgetsSDK/ClozeQuestion/create';

import { Hoverable } from '@/components/Hoverable';
import { Selectable } from '@/components/Selectable';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import { studioAnswerStyles } from '@/widgets/ClozeQuestion/ClozeQuestion.css';
import { ClozeTable } from '@/widgets/ClozeQuestion/studio/ClozeTable';
import { ClozeText } from '@/widgets/ClozeQuestion/studio/ClozeText';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function ClozeQuestionAnswerStudio(props: StudioProps) {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider
      document={document}
      id={parentQuestionId}
      type={WidgetType.ClozeQuestion}
      withOrchestrationWrapper={false}
    >
      <ClozeQuestionAnswerStudioImplementation {...props} />
    </ContentObjectProvider>
  );
}

function ClozeQuestionAnswerStudioImplementation(props: StudioProps) {
  const { initialize } = props;

  const clozeQuestionType = useContentObjectStaticProperty<string>('clozeQuestionType');
  const answerFragment = useContentObjectStaticProperty<Y.XmlFragment>('answerFragment');
  const [answerPlaceholder] = useContentObjectProperty<string>('answerPlaceholder');

  useEffect(() => {
    initialize?.({
      actions: [],
      isResizableVertically: false
    });
  }, [initialize]);

  const isTable = clozeQuestionType === ClozeQuestionType.Table;

  return (
    <Box className={!isTable ? cx(studioAnswerStyles, ThemeClassNames.widgets.clozeQuestion.answer) : undefined}>
      <Hoverable>
        <Selectable>
          {isTable ? <ClozeTable /> : <ClozeText fragment={answerFragment} placeholder={answerPlaceholder} />}
        </Selectable>
      </Hoverable>
    </Box>
  );
}
