import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type ClozeOptionStyleProps = {
  fontFamily?: string;
  fontSize?: string;
  isSelected: boolean;
};

export const useStyles = createStyles((theme, props: ClozeOptionStyleProps) => {
  const { isSelected, fontFamily, fontSize } = props;
  const globalThemeContentColors = theme.other.global.content.colors;
  const clozeQuestionInputFieldTheme = theme.other.learningItems.clozeQuestion.inputField;
  const clozeQuestionSelectedFieldTheme = theme.other.learningItems.clozeQuestion.selectedField;

  const currentTheme = isSelected ? clozeQuestionSelectedFieldTheme : clozeQuestionInputFieldTheme;
  const borderStyle = getThemeBorderStyle(currentTheme.border, globalThemeContentColors.primary.dark);
  const borderRadiusStyle = getThemeBorderRadiusStyle(clozeQuestionInputFieldTheme.borderRadius);
  const color = currentTheme.color ?? globalThemeContentColors.secondary.contrast;

  return {
    componentWithContent: {
      display: 'inline-block',
      padding: `0 ${rem(2)}`,
      fontSize: fontSize,
      maxWidth: '100%'
    },
    content: {
      ...borderStyle,
      ...borderRadiusStyle,
      background:
        currentTheme.backgroundColor ??
        (isSelected ? globalThemeContentColors.primary.light : globalThemeContentColors.secondary.main),
      padding: `0 ${rem(6)}`,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontFamily,
      gap: rem(4)
    },
    option: {
      color: `${color} !important`
    }
  };
});
