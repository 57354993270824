import { MouseEventHandler, CSSProperties, useMemo } from 'react';

import { rem } from 'shared/styles/rem';

import { NavArrowBase } from '@/components/NavArrowBase';
import { NAV_ARROW_HEIGHT_PX } from '@/consts/styleConsts';
import { useUiLanguage } from '@/utils/useUiLanguage';

type ModalNavArrowProps = {
  isArrowNext?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export function ModalNavArrow(props: ModalNavArrowProps) {
  const { isArrowNext = false, onClick } = props;
  const { uiDirection } = useUiLanguage();

  const arrowPositionStyle: CSSProperties = useMemo(() => {
    const isLTR = uiDirection === 'ltr';

    return {
      ...(isLTR === isArrowNext ? { right: 0 } : { left: 0 }),
      top: `calc(50% - ${rem(NAV_ARROW_HEIGHT_PX / 2)})`,
      position: 'absolute'
    };
  }, [isArrowNext, uiDirection]);

  return <NavArrowBase isArrowNext={isArrowNext} onClick={onClick} arrowPositionStyle={arrowPositionStyle} />;
}
