export const manifestLocales = {
  combinedTitle: {
    previewImageAlt: {
      en: 'Combined Title',
      he: 'כותרת משולבת'
    },
    initialText: {
      en: 'Combined Title',
      he: 'כותרת משולבת'
    }
  }
};
