import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewEnclosureWithBlockTitleData } from 'shared/widgetsSDK/EnclosureWithBlockTitle/create';

import { manifestLocales } from './manifest.locales';
import EnclosureWithBlockTitleEn from './resources/en/enclosure-with-block-title.svg';
import EnclosureWithBlockTitleHe from './resources/he/enclosure-with-block-title.svg';

export const Manifest: ManifestType = {
  name: WidgetType.EnclosureWithBlockTitle,
  previewGroups: [
    {
      id: 'enclosureWithBlockTitle',
      divider: true,
      previews: [
        {
          name: 'enclosureWithBlockTitlePreview',
          previewImage: {
            paths: {
              en: EnclosureWithBlockTitleEn,
              he: EnclosureWithBlockTitleHe
            },
            alt: { ...manifestLocales.enclosureWithBlockTitle.previewImageAlt },
            size: {
              width: 285,
              height: 200
            }
          },
          initializeProps: options =>
            createNewEnclosureWithBlockTitleData(options, {
              content: `<h1><span>${
                manifestLocales.enclosureWithBlockTitle.initialText[options?.locale ?? 'he']
              }</span></h1>`
            }),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
