import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type DndItemIndicatorStylesProps = { height?: number; isHorizontal?: boolean };

export const useStyles = createStyles((theme, props: DndItemIndicatorStylesProps) => {
  const { isHorizontal, height } = props;
  return {
    dndItemIndicator: {
      width: rem(8),
      height: height ?? 'auto',
      ...(isHorizontal && {
        width: '100%',
        height: rem(8)
      }),
      backgroundColor: theme.colors.blue[5],
      borderRadius: '99px'
    },
    dndItemIndicatorBar: {
      width: rem(8),
      height: '80%',
      borderRadius: '99px'
    }
  };
});
