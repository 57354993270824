import { BulletsAndNumberingSettings } from './BulletsAndNumberingSettings';
import { ParentHeadlineSettings } from './ParentHeadlineSettings';
import { QuestionBank } from './QuestionBank';
import { ChoiceQuestionsSettings } from '@/widgets/QuestionGroupList/config/design/ChoiceQuestionsSettings';
import { DisplayPreferenceSettings } from '@/widgets/QuestionGroupList/config/design/DisplayPreferenceSettings';

export function Design() {
  return (
    <>
      <QuestionBank />
      <DisplayPreferenceSettings />
      <ChoiceQuestionsSettings />
      <ParentHeadlineSettings />
      <BulletsAndNumberingSettings />
    </>
  );
}
