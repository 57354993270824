import { Accordion } from '@mantine/core';

import { ClozeAccordionItemInput } from './ClozeAccordionItemInput';
import { ClozeAccordionItemSelect } from './ClozeAccordionItemSelect';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';

type ClozeAccordionItemProps = {
  clozeId: string;
  index: number;
  type: ClozeOptionType;
};

export function ClozeAccordionItem(props: ClozeAccordionItemProps) {
  const { clozeId, index, type } = props;

  return (
    <Accordion.Item value={clozeId} data-testid="studio-cloze-question-appearance-accordion-item">
      {type === ClozeOptionType.Input && <ClozeAccordionItemInput clozeId={clozeId} index={index} />}
      {type === ClozeOptionType.Select && <ClozeAccordionItemSelect clozeId={clozeId} index={index} />}
    </Accordion.Item>
  );
}
