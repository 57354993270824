import { Stack, Tabs } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useStyles } from './Config.styles';
import { Borders } from './partials/Borders';
import { CaptionRow } from './partials/CaptionRow';
import { Cells } from './partials/Cells';
import { Corners } from './partials/Corners';
import { HeaderRow } from './partials/HeaderRow';
import { TableStyles } from './partials/TableStyles';
import { TextSettings } from './partials/TextSettings';
import { ConfigTitleBase } from '@/widgets/_components/ConfigTitle/ConfigTitleBase';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  const t = useTranslations('components.theme.themeables');
  const { classes } = useStyles();

  return (
    <WidgetConfig tabs={['appearance']} customTitle={<ConfigTitleBase title={t('table')} />}>
      <Tabs.Panel key="appearance" value="appearance">
        <Stack className={classes.contentStack}>
          <TableStyles />
          <TextSettings />
          <Cells />
          <Corners />
          <Borders />
          <CaptionRow />
          <HeaderRow />
        </Stack>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
