import * as Y from 'yjs';

import { manifestLocales } from './manifest.locales';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { TextEditorTypes } from '../../types/TextEditor';
import { createQuestionWidgetChildren } from '../../utils/createQuestionWidgetChildren';
import { genRandId } from '../../utils/genRandId';
import { getEvaluationProps } from '../../utils/getEvaluationProps';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { EvaluationMode, WidgetType } from '../../utils/widgets';
import { createNewOpenQuestionAnswerData } from '../OpenQuestionAnswer/create';
import { OpenQuestionConfig } from '../questionConfigs';

export function createNewOpenQuestionData(
  options: InitializePropsOptions,
  initializationConfig?: OpenQuestionConfig
): ManifestInitializedProps {
  const { isPlenaryLesson = false, locale } = options;
  const { initialHeadlineText, answerPlaceholder } = manifestLocales.openQuestion;

  const {
    hint = '',
    label = initialHeadlineText[locale],
    placeholder = answerPlaceholder[locale],
    showElements = {
      text: true,
      recording: false,
      image: false,
      file: false,
      embed: false
    },
    textEditorType = TextEditorTypes.Simple
  } = initializationConfig || {};

  const id = genRandId();
  const labelFragment = htmlToXmlFragment(`<h2>${label}</h2>`);
  const placeholderFragment = htmlToXmlFragment(placeholder);
  const hintFragment = htmlToXmlFragment(hint);
  const widgetEvaluationProps = Object.entries(getEvaluationProps(isPlenaryLesson));

  const [questionAnswerData] = createNewOpenQuestionAnswerData();
  const { childrenArray, horizontalFlexSectionData, verticalFlexSectionData } = createQuestionWidgetChildren(
    questionAnswerData.id,
    options?.width
  );

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.OpenQuestion],
        ['evaluationMode', EvaluationMode.Manual],
        ['children', childrenArray],
        ['labelFragment', labelFragment],
        ['placeholderFragment', placeholderFragment],
        ['hintFragment', hintFragment],
        ['saveAnswers', true],
        ['textEditorType', textEditorType],
        ['showElements', showElements],
        ...widgetEvaluationProps,
        ['showAnswerButton', false], //override default value
        ['questionAnswerId', questionAnswerData.id]
      ])
    },
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  ];
}
