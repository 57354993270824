import { useTranslations } from 'next-intl';
import { useCallback, useMemo } from 'react';

import { SwitchGroup } from '@/components/SwitchGroup';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

enum Options {
  MultiSelect = 'multiselect',
  LiveAnswers = 'liveAnswers'
}

const options = [
  { label: 'Multiselect', value: Options.MultiSelect },
  { label: 'Appear live', value: Options.LiveAnswers }
];

export function Settings() {
  const t = useTranslations('widgets.interactivePollSettings');
  const [isMultiselect, setIsMultiselect] = useContentObjectProperty<boolean>('isMultiselect');
  const [hasLiveAnswers, setHasLiveAnswers] = useContentObjectProperty<boolean>('hasLiveAnswers');

  const handleAnswersOptionsChange = useCallback(
    (value: string[]) => {
      setIsMultiselect(value.includes(Options.MultiSelect));
      setHasLiveAnswers(value.includes(Options.LiveAnswers));
    },
    [setIsMultiselect, setHasLiveAnswers]
  );

  const answersOptionsValue = useMemo(() => {
    const value = [];
    if (isMultiselect) {
      value.push(Options.MultiSelect);
    }
    if (hasLiveAnswers) {
      value.push(Options.LiveAnswers);
    }
    return value;
  }, [isMultiselect, hasLiveAnswers]);

  return (
    <SwitchGroup
      dataTestId="interactive-poll-answers-options"
      onChange={handleAnswersOptionsChange}
      options={options}
      title={t('answersOptions')}
      value={answersOptionsValue}
    />
  );
}
