import { Text, Tooltip, UnstyledButton } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { MouseEvent, useCallback } from 'react';

import { genRandId } from 'shared/utils/genRandId';

import { TableMenuProps } from './TableMenu';
import { useStyles } from './TableTooltip.styles';
import { Plus } from '@/icons/Plus';
import { useTable } from '@/widgets/Table/hooks/useTable';
import {
  ColumnId,
  createContentId,
  DEFAULT_COLUMN_WIDTH,
  getActualColumnWidth,
  getDefaultContentValue,
  RowId,
  TColumnWidths
} from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

type AddActionButtonProps = TableMenuProps;

const shouldModifyOrder = (e: MouseEvent<HTMLButtonElement>) => e.altKey;

export function AddActionButton(props: AddActionButtonProps) {
  const { type, rowId, columnId } = props;
  const columnOrder = useTable(context => context.columnOrder);
  const columnWidths = useTable(context => context.columnWidths);
  const rowOrder = useTable(context => context.rowOrder);
  const setColumnOrder = useTable(context => context.setColumnOrder);
  const setColumnWidths = useTable(context => context.setColumnWidths);
  const setRowHeights = useTable(context => context.setRowHeights);
  const setRowOrder = useTable(context => context.setRowOrder);
  const content = useTable(context => context.content);
  const { classes } = useStyles({ type });
  const { document } = useContentObject();

  const handleAddColumn = useCallback(
    (columnId: ColumnId, shouldAddLeft = false) => {
      const newColumnId = genRandId();
      const modifier = shouldAddLeft ? 0 : 1;
      const newColumnIndex = columnOrder.indexOf(columnId) + modifier;
      const newColumnOrder = [
        ...columnOrder.slice(0, newColumnIndex),
        newColumnId,
        ...columnOrder.slice(newColumnIndex)
      ];

      const newColumnWidth = DEFAULT_COLUMN_WIDTH;

      const lastColumnId = columnOrder[columnOrder.length - 1];
      columnWidths[lastColumnId] = getActualColumnWidth(columnId);

      const isGraterThanNewColumn = (columnId: string) => columnWidths[columnId] > newColumnWidth;

      const totalSumGraterThanNewColumn = columnOrder.reduce((acc, columnId) => {
        return isGraterThanNewColumn(columnId) ? acc + columnWidths[columnId] : acc;
      }, 0);

      const widthsToSubstract = columnOrder.map(columnId => {
        return isGraterThanNewColumn(columnId)
          ? newColumnWidth * (columnWidths[columnId] / totalSumGraterThanNewColumn)
          : 0;
      });

      const newColumnWidths: TColumnWidths = columnOrder.reduce(
        (acc, columnId, index) => ({
          ...acc,
          [columnId]: columnWidths[columnId] - widthsToSubstract[index]
        }),
        {}
      );
      newColumnWidths[newColumnId] = newColumnWidth;
      const lastNewColumnId = newColumnOrder[newColumnOrder.length - 1];
      newColumnWidths[lastNewColumnId] = 0;

      document.transact(() => {
        rowOrder.forEach(rowId => {
          const cellId = createContentId(newColumnId, rowId);
          content?.set(cellId, getDefaultContentValue());
        });
        content?.set(newColumnId, getDefaultContentValue());
        setColumnWidths(newColumnWidths);
        setColumnOrder(newColumnOrder);
      });
    },
    [columnOrder, columnWidths, content, document, rowOrder, setColumnOrder, setColumnWidths]
  );

  const handleAddRow = useCallback(
    (rowId: RowId, shouldAddAbove = false) => {
      const newRowId = genRandId();
      const modifier = shouldAddAbove ? 0 : 1;
      const newRowIndex = rowOrder.indexOf(rowId) + modifier;
      const newRowOrder = [...rowOrder.slice(0, newRowIndex), newRowId, ...rowOrder.slice(newRowIndex)];

      document.transact(() => {
        columnOrder.forEach(columnId => {
          const cellId = createContentId(columnId, newRowId);
          content?.set(cellId, getDefaultContentValue());
        });
        setRowHeights(currentRowHeights => {
          const newRowHeights = { ...currentRowHeights, [newRowId]: 50 };
          return newRowHeights;
        });
        setRowOrder(newRowOrder);
      });
    },
    [rowOrder, content, columnOrder, document, setRowHeights, setRowOrder]
  );

  const handleAdd = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (type === 'column') {
        handleAddColumn(columnId, shouldModifyOrder(e));
      } else {
        handleAddRow(rowId, shouldModifyOrder(e));
      }
    },
    [handleAddColumn, handleAddRow, columnId, rowId, type]
  );

  const isGraterThanNewColumn = (columnId: string) => columnWidths[columnId] > DEFAULT_COLUMN_WIDTH;
  const totalSumGraterThanNewColumn = columnOrder.reduce((acc, columnId) => {
    return isGraterThanNewColumn(columnId) ? acc + columnWidths[columnId] : acc;
  }, 0);
  const isDisabled = type === 'column' && totalSumGraterThanNewColumn < DEFAULT_COLUMN_WIDTH * 1.2;

  return (
    <Tooltip
      label={isDisabled ? <DisabledTooltipLabel /> : <TooltipLabel type={type} />}
      position="bottom"
      withinPortal
    >
      <UnstyledButton className={classes.button} onClick={handleAdd} disabled={isDisabled}>
        <Plus color="white" width={14} height={14} />
      </UnstyledButton>
    </Tooltip>
  );
}

type TooltipLabelProps = { type: AddActionButtonProps['type'] };

const TooltipLabel = (props: TooltipLabelProps) => {
  const { type } = props;
  const t = useTranslations('widgets.tableWidget.tooltip');

  const clickInsertAction = type === 'column' ? t('toInsertInlineStart') : t('toAddBelow');
  const altClickInsertAction = type === 'column' ? t('toInsertInlineEnd') : t('toAddAbove');

  return (
    <Text>
      <Text>
        <Text fw={700} span>
          {t('click')}{' '}
        </Text>
        {clickInsertAction}
      </Text>
      <Text>
        <Text fw={700}>{t('altOptionClick')}</Text>
        {altClickInsertAction}
      </Text>
    </Text>
  );
};

const DisabledTooltipLabel = () => {
  const t = useTranslations('widgets.tableWidget.tooltip');

  return <Text>{t('notEnoughSpace')}</Text>;
};
