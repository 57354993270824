export const manifestLocales = {
  h1: {
    previewImageAlt: {
      en: 'Main Headline H1',
      he: 'כותרת ראשית H1'
    },
    initialText: {
      en: 'Main Headline H1',
      he: 'כותרת ראשית H1'
    }
  },
  h2: {
    previewImageAlt: {
      en: 'Second Headline H2',
      he: 'כותרת משנית H2'
    },
    initialText: {
      en: 'Second Headline H2',
      he: 'כותרת משנית H2'
    }
  },
  h3: {
    previewImageAlt: {
      en: 'Medium Headline H3',
      he: 'כותרת משנית H3'
    },
    initialText: {
      en: 'Medium Headline H3',
      he: 'כותרת משנית H3'
    }
  },
  h4: {
    previewImageAlt: {
      en: 'Medium Headline H4',
      he: 'כותרת משנית H4'
    },
    initialText: {
      en: 'Medium Headline H4',
      he: 'כותרת משנית H4'
    }
  },
  paragraph: {
    previewImageAlt: {
      en: 'paragraph',
      he: 'פסקה'
    },
    initialText: {
      en: 'This paragraph is ready to hold stunning creativity, experiences and stories.',
      he: 'פסקה זו, מחכה לשפע סיפורים, חוויות ופעילויות נפלאות, מלאות ביצירתיות ובדמיון מעשיר'
    }
  },
  bulletedList: {
    previewImageAlt: {
      en: 'bullet list',
      he: 'רשימה עם בולטים'
    },
    initialText: {
      en: 'Bulleted list.',
      he: 'רשימה עם בולטים'
    }
  },
  numberedList: {
    previewImageAlt: {
      en: 'numbered list',
      he: 'רשימה ממוספרת'
    },
    initialText: {
      en: 'Numbered list.',
      he: 'רשימה ממוספרת'
    }
  }
};
