import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../consts/breakpoints';
import { DeviceType } from '../types/DeviceType';

export const DEVICE_FRAME = {
  MOBILE: {
    width: 434,
    height: 868,
    url: '/deviceFrames/mobile.svg'
  },
  TABLET: {
    width: 663,
    height: 949,
    url: '/deviceFrames/tablet.svg'
  }
};

export type stageViewInfoProps = {
  width: number;
  numOfColumns: number;
};

export function getStageViewInfo(deviceView: DeviceType): stageViewInfoProps {
  switch (deviceView) {
    case 'mobile':
      return { numOfColumns: 4, width: MOBILE_BREAKPOINT - 28 };
    case 'tablet':
      return { numOfColumns: 6, width: TABLET_BREAKPOINT - 40 };
    case 'pc':
      return { numOfColumns: 12, width: DND_STAGE_WIDTH_PX };
  }
}

export enum ItemDragType {
  Item = 'item',
  Section = 'section',
  FlexSection = 'flexSection',
  Page = 'page',
  Slide = 'slide',
  Question = 'question',
  UniqueItem = 'uniqueItem',
  FullPageItem = 'fullPageItem'
}

export const DND_COLS_AMOUNT = 12;
export const DND_GAP_WIDTH_PX = 24;
export const DND_PADDING_PX = 48;
export const DND_STAGE_WIDTH_PX = 1280;
export const DND_GRID_WIDTH_PX = DND_STAGE_WIDTH_PX - DND_PADDING_PX * 2;
export const DND_CELL_WIDTH_PX = (DND_GRID_WIDTH_PX - DND_GAP_WIDTH_PX * (DND_COLS_AMOUNT - 1)) / DND_COLS_AMOUNT;

export const SECTION_OPTIONS_WIDTH_PX = 48;
export const SECTION_OPTIONS_OFFSET_PX = 8;

export const getItemWidth = (span: number) => {
  return DND_CELL_WIDTH_PX * span + DND_GAP_WIDTH_PX * (span - 1);
};

export const getItemSpan = (width: number) => {
  // width = X * cell + (x-1) * gap
  // width = X * cell + X * gap - gap
  // width + gap = X (cell + gap)
  // (width + gap) / (cell + gap) = X
  return Math.round((width + DND_GAP_WIDTH_PX) / (DND_CELL_WIDTH_PX + DND_GAP_WIDTH_PX));
};

export const roundHeight = (height: number) => {
  return Math.ceil(height / 20) * 20;
};
