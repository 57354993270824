import { Box } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { usePreview } from 'react-dnd-preview';

import { useStyles } from './Dnd.styles';

type Props = {
  scale?: number;
  isDragging: boolean;
} & PropsWithChildren;

export function DndDragLayer(props: Props) {
  const { scale = 1, children, isDragging } = props;

  const preview = usePreview<unknown, HTMLDivElement>();

  const { classes } = useStyles({ isDragging });

  if (!preview.display) {
    return null;
  }

  const { ref, monitor, style } = preview;

  const { x: clientX, y: clientY } = monitor.getClientOffset() ?? { x: 0, y: 0 };
  const { x: elementX, y: elementY } = monitor.getInitialSourceClientOffset() ?? { x: 0, y: 0 };

  const deltaX = (clientX - elementX) / scale;
  const deltaY = (clientY - elementY) / scale;

  const transform = `translate(${deltaX}px, ${deltaY}px)`;

  const previewDndProps = {
    style: {
      ...style,
      position: 'absolute' as const,
      transform,
      // Without WebkitTransform the app seems to crash
      WebkitTransform: transform
    },
    ref,
    className: classes.dndPreview
  };

  return <Box {...previewDndProps}>{children}</Box>;
}
