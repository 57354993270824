import { Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function RandomizeAnswers() {
  const t = useTranslations('widgets');
  const [withRandomAnswers, setWithRandomAnswers] = useContentObjectProperty<boolean>('withRandomAnswers');

  return (
    <FormControl title={t('selectionQuestionSettings.randomizeAnswers')} withSeparator>
      <Switch
        checked={withRandomAnswers}
        onChange={event => setWithRandomAnswers(event.currentTarget.checked)}
        label={t('selectionQuestionSettings.random')}
      />
    </FormControl>
  );
}
