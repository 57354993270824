import { useLayoutEffect, useRef } from 'react';

import { PluginEvent } from './pluginEvent';

export function useInjectScript(
  script: string | undefined,
  attributes: { name: string; value: string }[],
  widgetId: string
) {
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && script) {
      const headElements = document.getElementsByTagName('head');
      const headElement = headElements[0];
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML = script;
      attributes.forEach(attribute => {
        scriptElement.setAttribute(attribute.name, attribute.value);
      });

      const dataAttribute = attributes.find(attribute => attribute.name === 'data-element');
      const dataElement = dataAttribute?.value;

      scriptRef.current = scriptElement;

      headElement.appendChild(scriptRef.current);
      return () => {
        if (scriptRef.current) {
          if (dataElement === 'stage')
            document.dispatchEvent(new CustomEvent(`${PluginEvent.unloadEvent}-${widgetId}`));
          headElement.removeChild(scriptRef.current);
        }
      };
    }
    // We don't want to reload the script when attributes change but we need to use them when first creating it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [script, widgetId]);

  useLayoutEffect(() => {
    attributes.forEach(attribute => {
      if (scriptRef.current) {
        scriptRef.current.setAttribute(attribute.name, attribute.value);
      }
    });
  }, [attributes]);
}
