import { InteractiveButtonThemeProps } from '@hendrx/modules/ui';
import { useId } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { FC, forwardRef, useMemo, useRef } from 'react';
import * as Y from 'yjs';

import { AnswerElementType } from 'shared/types/AnswerElement';
import { ObjectFit } from 'shared/types/Image';

import { useStyles } from './AnswerElement.styles';
import { Hoverable } from '@/components/Hoverable';
import { Selectable, useSelectableStore } from '@/components/Selectable';
import { SideMenuPortalBase } from '@/components/SideMenuPortal';
import { Replace } from '@/icons/Replace';
import { WidgetAdditionalAction } from '@/types/Stage';
import { AudioSchemaType } from '@/widgets/Audio/Schema';
import { Base as AudioConfig } from '@/widgets/Audio/config/Base';
import { Base as AudioStudio } from '@/widgets/Audio/studio/Base';
import { ImageSchemaType } from '@/widgets/Image/Schema';
import { Base as ImageConfig } from '@/widgets/Image/config/Base';
import { Base as ImageStudio } from '@/widgets/Image/studio/Base';
import { Base as TextStudio } from '@/widgets/Text/studio/Base';
import { WidgetActionsBar } from '@/widgets/_components/widget/WidgetActionsBar';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnswerConfigs: Record<AnswerElementType, FC<any>> = {
  Text: () => null,
  Image: ImageConfig,
  // Video: () => null,
  Audio: AudioConfig
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnswerComponents: Record<AnswerElementType, FC<any>> = {
  Text: forwardRef(TextStudio),
  Image: ImageStudio,
  // Video: forwardRef(function Video() {
  //   return null;
  // }),
  Audio: AudioStudio
};

export type AnswerElementProps = {
  answerElementType: AnswerElementType;
  imageAlt: string;
  imageMobileObjectFit: ObjectFit;
  imageMobileSrc: string;
  imageObjectFit: ObjectFit;
  imageSrc: string;
  interactiveButtonThemeProps: InteractiveButtonThemeProps;
  setAudioTitle?: (value: string) => void;
  setImageAlt: (value: string) => void;
  setImageMobileObjectFit: (value: ObjectFit) => void;
  setImageMobileSrc: (value: string) => void;
  setImageObjectFit: (value: ObjectFit) => void;
  setImageSrc: (value: string) => void;
  audioError?: Partial<AudioSchemaType>;
  audioSrc?: string;
  audioTitle?: string;
  dataTestIdPrefix?: string;
  fragment?: Y.XmlFragment;
  hasImage?: boolean;
  imageError?: Partial<ImageSchemaType>;
  setAudioSrc?: (value: string) => void;
};

export function AnswerElement(props: AnswerElementProps) {
  const {
    answerElementType,
    audioError,
    audioSrc,
    audioTitle,
    dataTestIdPrefix,
    fragment,
    hasImage,
    imageAlt,
    imageError,
    imageMobileObjectFit,
    imageMobileSrc,
    imageObjectFit,
    imageSrc,
    interactiveButtonThemeProps,
    setAudioSrc,
    setAudioTitle,
    setImageAlt,
    setImageMobileObjectFit,
    setImageMobileSrc,
    setImageObjectFit,
    setImageSrc
  } = props;
  const id = useId();
  const selectedId = useSelectableStore(state => state.selectedId);

  const t = useTranslations();

  const Component = AnswerComponents[answerElementType];
  const ConfigComponent = AnswerConfigs[answerElementType];

  const ref = useRef<() => void | null>(null);

  const { classes } = useStyles({ hasImage, interactiveButtonThemeProps });

  const additionalActionsMap: Record<string, WidgetAdditionalAction[]> = useMemo(() => {
    return {
      Image: imageSrc
        ? [
            {
              onClick: () => ref.current?.(),
              actionName: t('widgets.imageActions.replace'),
              dataTestId: 'widget-additional-action-replace',
              Icon: Replace
            }
          ]
        : [],
      Audio: audioSrc
        ? [
            {
              onClick: () => ref.current?.(),
              actionName: t('widgets.audioActions.replace'),
              dataTestId: 'widget-additional-action-replace',
              Icon: Replace
            }
          ]
        : []
    };
  }, [audioSrc, imageSrc, t]);

  const componentPropsMap: Record<AnswerElementType, Record<string, unknown>> = {
    Image: {
      alt: imageAlt,
      src: imageSrc,
      setSrc: setImageSrc,
      width: 600,
      height: 600,
      fill: false,
      objectFit: imageObjectFit,
      mobileObjectFit: imageMobileObjectFit,
      classNames: { image: classes.image, dropzone: classes.imageDropZone }
    },
    Text: {
      fragment,
      placeholder: t('components.answerOption.answerPlaceholder'),
      disableTypographyInheritance: true
    },
    Audio: {
      src: audioSrc,
      setSrc: setAudioSrc,
      classNames: { playButton: classes.audioPlayButton, dropzone: classes.audioDropZone }
    } //,
    //Video: {}
  };

  const configPropsMap: Record<AnswerElementType, Record<string, unknown>> = {
    Image: {
      alt: imageAlt,
      setAlt: setImageAlt,
      mobileSrc: imageMobileSrc,
      setMobileSrc: setImageMobileSrc,
      errors: imageError,
      setObjectFit: setImageObjectFit,
      objectFit: imageObjectFit,
      setMobileObjectFit: setImageMobileObjectFit,
      mobileObjectFit: imageMobileObjectFit
    },
    Text: {},
    Audio: {
      title: audioTitle,
      setTitle: setAudioTitle,
      errors: audioError
    } //,
    //Video: {}
  };

  const itemConfigId = answerElementType !== 'Text' ? id : null;

  return (
    <Hoverable>
      <Selectable itemConfigId={itemConfigId}>
        <div data-testid={dataTestIdPrefix}>
          {selectedId === id && (
            <SideMenuPortalBase>
              <div>
                <Selectable unstyled itemConfigId={itemConfigId}>
                  <ConfigComponent {...configPropsMap[answerElementType]} />
                </Selectable>
              </div>
            </SideMenuPortalBase>
          )}
          <WidgetActionsBar isOpened={selectedId === id} additionalActions={additionalActionsMap[answerElementType]}>
            <Component ref={ref} {...componentPropsMap[answerElementType]} dataTestIdPrefix={dataTestIdPrefix} />
          </WidgetActionsBar>
        </div>
      </Selectable>
    </Hoverable>
  );
}
