import { ActionIcon } from '@mantine/core';

import { Copy } from '@/icons/Copy';

type QuestionDuplicateProps = {
  onDuplicate: () => void;
};

export function QuestionDuplicate(props: QuestionDuplicateProps) {
  const { onDuplicate } = props;

  return (
    <ActionIcon variant="transparent" data-testid="copy-question-bank-item-button" onClick={onDuplicate}>
      <Copy />
    </ActionIcon>
  );
}
