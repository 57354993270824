import { Checkbox, Radio } from '@mantine/core';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { WidgetType } from 'shared/utils/widgets';

import { SelectionQuestionPlayerSingleAnswer } from './SelectionQuestionPlayerSingleAnswer';
import { AnswersGrid } from '@/components/AnswersGrid';
import { useParentQuestionId } from '@/contentObjects/hooks/useParentQuestionId';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { useEvaluatableQuestionAnswersContext } from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';
import { useColumnsForCurrentDevice } from '@/widgets/hooks/useColumnsForCurrentDevice';
import { useOrderedAnswers } from '@/widgets/hooks/useOrderedAnswers';

export function SelectionQuestionAnswerPlayer() {
  const { document } = useContentObject();
  const parentQuestionId = useParentQuestionId();
  if (!parentQuestionId) {
    return null;
  }

  return (
    <ContentObjectProvider document={document} id={parentQuestionId} type={WidgetType.SelectionQuestion}>
      <SelectionQuestionAnswerPlayerImplementation />
    </ContentObjectProvider>
  );
}

function SelectionQuestionAnswerPlayerImplementation() {
  const [isSingleAnswerQuestion] = useContentObjectProperty<boolean>('isSingleAnswerQuestion');

  const { id } = useContentObject();

  const { orderedAnswers } = useOrderedAnswers();

  const { disabled, handleSelectAnswers, selectedAnswers, selectedAnswersStatuses } =
    useEvaluatableQuestionAnswersContext<WidgetType.SelectionQuestion>();

  const columns = useColumnsForCurrentDevice();
  const { isTextOnly } = useAnswerElements();
  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  return (
    <>
      {isSingleAnswerQuestion ? (
        <Radio.Group
          value={selectedAnswers[0] ?? ''}
          onChange={answer => handleSelectAnswers([answer])}
          classNames={{ label: questionClasses.label, description: questionClasses.description }}
          size="xl"
        >
          <AnswersGrid columns={columns} isTextOnly={isTextOnly} answersStyle={answersStyle}>
            {orderedAnswers.map(answerIndex => (
              <SelectionQuestionPlayerSingleAnswer
                disabled={disabled}
                key={answerIndex}
                answerIndex={answerIndex}
                status={selectedAnswersStatuses[answerIndex]}
                isChecked={selectedAnswers.includes(answerIndex.toString())}
                inputType="radio"
                name={`RadioQuestion-${id}`}
              />
            ))}
          </AnswersGrid>
        </Radio.Group>
      ) : (
        <Checkbox.Group
          value={selectedAnswers}
          onChange={handleSelectAnswers}
          classNames={{ label: questionClasses.label, description: questionClasses.description }}
          size="xl"
        >
          <AnswersGrid columns={columns} isTextOnly={isTextOnly} answersStyle={answersStyle}>
            {orderedAnswers.map(answerIndex => (
              <SelectionQuestionPlayerSingleAnswer
                disabled={disabled}
                key={answerIndex}
                answerIndex={answerIndex}
                status={selectedAnswersStatuses[answerIndex]}
                isChecked={selectedAnswers.includes(answerIndex.toString())}
                inputType="checkbox"
              />
            ))}
          </AnswersGrid>
        </Checkbox.Group>
      )}
    </>
  );
}
