import { ActivityThemeStyles, BorderThemeProps } from '@hendrx/modules/ui';
import { Box, Switch } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { themeControlStyles } from './Config.css';
import { BorderPanel } from '@/components/Theme/BorderPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import * as classes from '@/components/Theme/Theme.css';
import { SetTextWidgetStyleFunction, TypographyIdType, WidgetTypographyStyle } from '@/utils/typographyStyles.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';

type BorderKeyType = 'borderTop' | 'borderBottom' | 'borderRight' | 'borderLeft';
const borderList: BorderKeyType[] = ['borderTop', 'borderBottom', 'borderRight', 'borderLeft'];

const getUnifiedBorder = (
  widgetBorder: BorderThemeProps | null | undefined,
  activityBorder: BorderThemeProps,
  globalBorderColor: string
) => {
  return {
    borderColor: widgetBorder?.borderColor ?? activityBorder.borderColor ?? globalBorderColor,
    borderStyle: widgetBorder?.borderStyle ?? activityBorder.borderStyle,
    borderWidth: widgetBorder?.borderWidth ?? activityBorder.borderWidth
  };
};

type ConfigSplitBorderProps = {
  activityThemeStyles: ActivityThemeStyles;
  typographyId: TypographyIdType;
  widgetTypographyStyle: Partial<WidgetTypographyStyle> | undefined;
  setWidgetTypographyStyle: SetTextWidgetStyleFunction<WidgetTypographyStyle>;
};

export function ConfigSplitBorder(props: ConfigSplitBorderProps) {
  const { activityThemeStyles, typographyId, widgetTypographyStyle, setWidgetTypographyStyle } = props;
  const t = useTranslations('components.theme.settings');
  const { contentDirection } = useContentLanguage();

  const borderLabels = {
    borderTop: t('top'),
    borderBottom: t('bottom'),
    borderRight: contentDirection === 'ltr' ? t('right') : t('left'),
    borderLeft: contentDirection === 'ltr' ? t('left') : t('right')
  };

  const { borderTop, borderRight, borderBottom, borderLeft } = widgetTypographyStyle || {};
  const activityBordersStyle = activityThemeStyles.typography[typographyId];
  const activityBorderTop = activityBordersStyle.borderTop;
  const globalBorderColor = activityThemeStyles.global.content.colors.decoration.main;

  const resultBorders: Record<BorderKeyType, BorderThemeProps> = {
    borderTop: getUnifiedBorder(borderTop, activityBordersStyle.borderTop, globalBorderColor),
    borderBottom: getUnifiedBorder(borderBottom, activityBordersStyle.borderBottom, globalBorderColor),
    borderLeft: getUnifiedBorder(borderLeft, activityBordersStyle.borderLeft, globalBorderColor),
    borderRight: getUnifiedBorder(borderRight, activityBordersStyle.borderRight, globalBorderColor)
  };

  const isBorderEqual =
    uniqWith(
      [resultBorders.borderTop, resultBorders.borderRight, resultBorders.borderBottom, resultBorders.borderLeft],
      isEqual
    ).length === 1;
  const [isBorderSplit, setIsBorderSplit] = useState(!isBorderEqual);

  const setAllFourBorders = (borderData: BorderThemeProps | null) => {
    setWidgetTypographyStyle({
      ...widgetTypographyStyle,
      borderTop: borderData,
      borderBottom: borderData,
      borderLeft: borderData,
      borderRight: borderData
    });
  };

  const setBorder = (borderKey: BorderKeyType, borderData: BorderThemeProps | null) =>
    setWidgetTypographyStyle({
      ...widgetTypographyStyle,
      [borderKey]: borderData
    });

  const onSwitchSplitBorder = (isSplit: boolean) => {
    if (!isSplit) {
      // Unify all borders: keep the data from the borderTop (first on top)
      setAllFourBorders(borderTop || null);
    }
    setIsBorderSplit(isSplit);
  };

  return (
    <Box className={classes.fullWidthContainer}>
      <Box className={classes.withMarginBottom}>
        <Switch
          checked={isBorderSplit}
          label={t('splitBorder')}
          onChange={event => onSwitchSplitBorder(event.currentTarget.checked)}
        />
      </Box>
      {isBorderSplit ? (
        <>
          {borderList.map(borderType => {
            const widgetBorder = widgetTypographyStyle?.[borderType];

            return (
              <BorderPanel
                key={borderType}
                label={borderLabels[borderType]}
                border={resultBorders[borderType]}
                setBorder={(newBorderData: BorderThemeProps) => setBorder(borderType, newBorderData)}
                themeControlClassName={themeControlStyles}
                ResetButton={
                  (widgetBorder?.borderColor || widgetBorder?.borderStyle || widgetBorder?.borderWidth) && (
                    <ResetButton
                      onClick={() => {
                        setBorder(borderType, null);
                      }}
                    />
                  )
                }
              />
            );
          })}
        </>
      ) : (
        <BorderPanel
          label={t('border')}
          border={getUnifiedBorder(borderTop, activityBorderTop, globalBorderColor)}
          setBorder={(newBorderData: BorderThemeProps) => setAllFourBorders(newBorderData)}
          themeControlClassName={themeControlStyles}
          ResetButton={
            (borderTop?.borderColor || borderTop?.borderStyle || borderTop?.borderWidth) && (
              <ResetButton
                onClick={() => {
                  setAllFourBorders(null);
                }}
              />
            )
          }
        />
      )}
    </Box>
  );
}
