import { Box, Flex, SelectProps } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { Select } from '@/components/Select';
import { BulletList } from '@/icons/BulletList';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { NumberedList } from '@/icons/NumberedList';
import { getFlipStyle } from '@/utils/locale.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { BUTTONS_GAP_PX } from '@/widgets/_components/RichTextToolbar/RichTextToolbar.css';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

const listTypeIcon = {
  bulletList: BulletList,
  orderedList: NumberedList
} as const;

const listTypeComponent = {
  bulletList: RichTextEditor.BulletList,
  orderedList: RichTextEditor.OrderedList
} as const;

const listTypeOptions = [
  { value: 'bulletList', label: '' },
  { value: 'orderedList', label: '' }
] as const;

export type ListTypeSelectProps = {
  editor: Editor | null;
  isSelectView?: boolean;
};

export function ListTypeSelect(props: ListTypeSelectProps) {
  const { isSelectView } = props;
  const { classes } = useStyles({ maxWidth: 60 });
  const t = useTranslations('widgets.listTypeSelect');
  const { editor } = props;
  const { contentDirection } = useContentLanguage();

  const currentListType = listTypeOptions.find(listType => editor?.isActive(listType.value)) ?? null;

  const Icon = listTypeIcon[currentListType?.value ?? 'bulletList'];

  return isSelectView ? (
    <Select
      title={t('title') ?? ''}
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        input: classes.selectInput,
        section: classes.selectIcon,
        label: classes.selectLabel
      }}
      data={listTypeOptions}
      renderOption={renderListOption}
      label={
        <Box data-active={currentListType !== null}>
          <Icon style={getFlipStyle(contentDirection)} />
        </Box>
      }
      variant="unstyled"
      leftSection={<DropdownArrow />}
      leftSectionWidth={16}
      rightSection={<></>}
      rightSectionWidth={'0px'}
    />
  ) : (
    <Flex direction="row" dir="ltr" gap={BUTTONS_GAP_PX}>
      {listTypeOptions.map(listTypeData => (
        <ListTypeOption key={listTypeData.value} value={listTypeData.value} />
      ))}
    </Flex>
  );
}

type ListTypeOptionProps = {
  value: (typeof listTypeOptions)[number]['value'];
};

const renderListOption: SelectProps['renderOption'] = ({ option }) => (
  <ListTypeOption value={option.value as ListTypeOptionProps['value']} />
);

const ListTypeOption = forwardRef<HTMLButtonElement, ListTypeOptionProps>(function ListTypeOption(props, ref) {
  const { value } = props;
  const { contentDirection } = useContentLanguage();

  const t = useTranslations('widgets.listTypeSelect');

  const Component = listTypeComponent[value];
  const Icon = listTypeIcon[value];

  return (
    <Component
      icon={() => <Icon />}
      title={t(value) ?? ''}
      ref={ref}
      m={0}
      w="100%"
      sx={getFlipStyle(contentDirection)}
      bg="transparent"
    />
  );
});
