import { useMutation } from '@tanstack/react-query';

import { evaluateQuestion as clientEvaluateQuestion } from 'shared/evaluation/evaluateQuestion';
import { getCorrectAnswers } from 'shared/evaluation/getCorrectAnswers';
import { EvaluateQuestionRequest } from 'shared/types/dto/Evaluation';
import { EvaluatableWidgets, WidgetType } from 'shared/utils/widgets';

import { useCurrentDocument } from '@/components/YjsProvider';
import { FeedbackMessageType } from '@/utils/feedback.utils';
import { useLearningObjectIds } from '@/utils/useLearningObjectIds';
import { WordOption } from '@/widgets/FillInTheBlanksQuestion/types';

export type EvaluationPayloadByWidget = {
  [WidgetType.SelectionQuestion]: { answers: string[] };
  [WidgetType.ClozeQuestion]: { answers: Record<string, string> };
  [WidgetType.FillInTheBlanksQuestion]: { answers: Record<string, WordOption> };
  [WidgetType.QuestionGroupList]: {
    shownQuestions: string[];
    requiredQuestionIds: string[];
    numberOfQuestionsToEvaluate: number;
    answers: Record<string, unknown>;
  };
  [WidgetType.EvaluatablePlugin]: {
    state: unknown;
    score: number;
    answered: boolean;
  };
  [WidgetType.InteractiveQuiz]: { answers: string[] };
};

export type BaseEvaluationResult = {
  feedbackMessageType: FeedbackMessageType;
  percentageScore: number;
  grade?: number;
  noCorrectAnswers?: boolean;
  evaluatedAnswers: Record<string, unknown>;
};

export type EvaluationResultByWidget = {
  [WidgetType.SelectionQuestion]: BaseEvaluationResult & { evaluatedAnswers: Record<string, 'correct' | 'incorrect'> };
  [WidgetType.ClozeQuestion]: BaseEvaluationResult & { evaluatedAnswers: Record<string, boolean> };
  [WidgetType.FillInTheBlanksQuestion]: BaseEvaluationResult & { evaluatedAnswers: Record<string, boolean> };
  [WidgetType.QuestionGroupList]: BaseEvaluationResult & { evaluatedSubQuestions: Record<string, unknown> };
  [WidgetType.EvaluatablePlugin]: never;
  [WidgetType.InteractiveQuiz]: BaseEvaluationResult & { evaluatedAnswers: Record<string, 'correct' | 'incorrect'> };
};

type EvaluateQuestionVariables<T extends EvaluatableWidgets> = {
  payload: EvaluationPayloadByWidget[T];
  questionId: string;
};

type EvaluationQuestionClient = Parameters<typeof clientEvaluateQuestion>[0];

export const evaluateQuestion = async (evaluateQuestionDto: EvaluateQuestionRequest & EvaluationQuestionClient) => {
  // Always call the evaluate for server side but don't await it so that the client can also calculate and show the feedback immediately
  // TODO: once test mode is implemented - evaluate only on server side when test mode is on
  const { document, payload, questionId, assignmentId, learningObjectDraftId, learningObjectSnapshotId } =
    evaluateQuestionDto;

  try {
    void fetch('/api/evaluation/evaluate-question', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        payload,
        questionId,
        learningObjectDraftId,
        learningObjectSnapshotId,
        assignmentId
      })
    });
  } catch (error) {
    console.warn('Failed to evaluate question on server side');
  }
  try {
    return JSON.parse(
      JSON.stringify(
        clientEvaluateQuestion({
          payload: payload,
          questionId: questionId,
          document: document
        })
      )
    );
  } catch (error) {
    console.warn('Failed to evaluate question on client side');
  }
};

export const useEvaluateQuestion = <T extends EvaluatableWidgets = never>() => {
  const document = useCurrentDocument();
  const { learningObjectDraftId, learningObjectSnapshotId, assignmentId } = useLearningObjectIds();

  // TODO: Fetch from server when assignment with grade is implemented
  return useMutation<EvaluationResultByWidget[T], unknown, EvaluateQuestionVariables<T>, unknown>({
    mutationFn: variables =>
      evaluateQuestion({
        learningObjectDraftId,
        learningObjectSnapshotId,
        assignmentId,
        document,
        ...variables
      })
  });
};

type CorrectOptionType = {
  id: string;
  value: string;
};
type InputCorrectOptionsType = Record<string, CorrectOptionType[]>;
type SelectCorrectOptionsType = Record<string, CorrectOptionType>;
export type CorrectAnswersResultByWidget = {
  [WidgetType.SelectionQuestion]: { correctAnswers: string[] };
  [WidgetType.ClozeQuestion]: {
    inputCorrectOptions: InputCorrectOptionsType;
    selectCorrectOptions: SelectCorrectOptionsType;
  };
  [WidgetType.FillInTheBlanksQuestion]: { correctOptions: Record<string, string> };
  [WidgetType.QuestionGroupList]: unknown;
  [WidgetType.EvaluatablePlugin]: never;
  [WidgetType.InteractiveQuiz]: { correctAnswers: string[] };
};

export type CorrectAnswersResult<T extends EvaluatableWidgets> = { result: CorrectAnswersResultByWidget[T] };

export const useFetchCorrectAnswers = <T extends EvaluatableWidgets>() => {
  const document = useCurrentDocument();

  // TODO: Fetch from server when assignment with grade is implemented
  return useMutation<CorrectAnswersResult<T>, unknown, { questionId: string }, unknown>({
    mutationFn: variables =>
      ({
        result: JSON.parse(
          JSON.stringify(
            getCorrectAnswers({
              questionId: variables.questionId,
              document
            })
          )
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
  });
};
