import { UnstyledButton } from '@mantine/core';

import { useStyles } from './NumberInput.styles';
import { Minus } from '@/icons/Minus';
import { Plus } from '@/icons/Plus';

type NumberInputProps = {
  value: number;
  setValue: (newValue: number | ((prevState: number) => number)) => void;
  min?: number;
  max?: number;
};

export default function NumberInput(props: NumberInputProps) {
  const { value, setValue, min, max } = props;

  const handleSetValue = (amount: number) => {
    setValue(prevValue => {
      const newValue = prevValue + amount;
      if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
        return newValue;
      }
      return prevValue;
    });
  };

  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <UnstyledButton className={classes.button}>
        <Minus onClick={() => handleSetValue(-1)} />
      </UnstyledButton>
      <div>{value}</div>
      <UnstyledButton className={classes.button}>
        <Plus onClick={() => handleSetValue(+1)} />
      </UnstyledButton>
    </div>
  );
}
