import { RefObject, useMemo } from 'react';

import { BASE_FONT_SIZE, useDeviceView } from '@/utils/deviceHooks';

export const useDynamicInputWidth = (
  words: string[],
  extraSpace: number,
  ref?: RefObject<HTMLTextAreaElement | HTMLInputElement>
) => {
  const { htmlFontSize } = useDeviceView();

  const getWordWithSpaces = (word: string) => word.replace(/ /g, '&nbsp');

  const fontSizeMultipier = BASE_FONT_SIZE / htmlFontSize;

  const longestWord = useMemo(() => {
    let maxWidth = 0;
    for (const word of words) {
      const ghostElement = document.createElement('div');

      ghostElement.innerHTML = getWordWithSpaces(word);
      ghostElement.style.opacity = '0';
      ghostElement.style.zIndex = '-100';
      ghostElement.style.position = 'fixed';
      ghostElement.style.whiteSpace = 'no-wrap';
      ghostElement.style.overflow = 'visible';

      const el = ref?.current?.closest('p') ?? document.body;
      el.appendChild(ghostElement);
      const width = ghostElement.offsetWidth;
      el.removeChild(ghostElement);
      maxWidth = Math.max(maxWidth, width);
    }
    return maxWidth;
  }, [ref, words]);

  return (longestWord + extraSpace) * fontSizeMultipier;
};
