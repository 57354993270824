import { ColumnDef } from '@tanstack/react-table';
import { last } from 'lodash';
import drop from 'lodash/drop';
import * as Y from 'yjs';

import { InitializePropsOptions, ManifestInitializedProps } from 'shared/types/ManifestType';
import { genRandId } from 'shared/utils/genRandId';
import { htmlToXmlFragment } from 'shared/utils/htmlToXmlFragment';
import { WidgetType } from 'shared/utils/widgets';

import { vars } from '@/utils/mantineVanillaIntegration/vars';
import { manifestLocales } from '@/widgets/Table/manifest.locales';

export type Caption = Y.XmlFragment;
export type ContentId = string;
export type ColumnId = string;
export type RowId = string;
export type TCells = Record<ColumnId, ContentId>;
export type TRow = {
  id: RowId;
  cells: TCells;
};
export type TColumnWidths = Record<ColumnId, number>;
export type TRowHeights = Record<RowId, number>;
export type TColumnOrder = ColumnId[];
export type TRowOrder = RowId[];

/**
 * @description The class name for the div who wraps the cell renderer. This div is used to track the height of the cell contents in an agnostic manner to ensure compatibility for different implementations of cell renderers.
 */
export const CELL_RENDERER_CLASSNAME = 'Cell-Renderer';

export const getActualColumnWidth = (columnId: ColumnId): number => {
  return document.querySelector(`.${CLASSNAMES.CellColumn(columnId)}`)?.clientWidth ?? DEFAULT_COLUMN_WIDTH;
};

export const CSS_VARS = {
  ColumnSize: (columnId: ColumnId) => `--col-${columnId}-size`,
  SelectionColor: vars.colors.blue[5]
};

export const CLASSNAMES = {
  CellColumn: (columnId: ColumnId) => `cell-col-${columnId}`,
  CellRow: (rowId: RowId) => `cell-row-${rowId}`,
  TableRowWrapper: 'table-row-wrapper'
};

export const getDefaultContentValue = () => {
  return new Y.XmlFragment();
};

const DEFAULT_ROW_HEIGHT = 140;
export const DEFAULT_COLUMN_WIDTH = 160;
export const DEFAULT_TABLE_WIDTH = 1180;

export const calculateRowMinHeight = (rowId: RowId) => {
  const MIN_ROW_HEIGHT = 70;
  const rowCells = document.querySelectorAll(`.${CLASSNAMES.CellRow(rowId)}`);
  return Array.from(rowCells).reduce((minHeight, cell) => {
    const renderer = cell.querySelector(`.${CELL_RENDERER_CLASSNAME}`);
    if (renderer && renderer.clientHeight > minHeight) {
      return renderer.clientHeight;
    }
    return minHeight;
  }, MIN_ROW_HEIGHT);
};

export function createNewTableDataBase(columnsAmount: number, rowsAmount: number, options: InitializePropsOptions) {
  const id = genRandId();
  const columnOrder = createColumnOrder(columnsAmount);
  const rowOrder = createRowOrder(rowsAmount);
  const defaultColumnWidths = createDefaultColumnWidths(columnOrder);
  const defaultRowHeights = createDefaultRowHeights(rowOrder);
  const initialContent = creteInitialContent(columnOrder, rowOrder);
  const initialCaption = createDefaultCaption(options?.locale);

  return {
    id,
    contentObjectData: [
      ['id', id],
      ['type', WidgetType.Table],
      ['children', new Y.Array()],
      ['caption', initialCaption],
      ['rowOrder', rowOrder],
      ['columnOrder', columnOrder],
      ['columnWidths', defaultColumnWidths],
      ['rowHeights', defaultRowHeights],
      ['contentById', new Y.Map(initialContent)],
      ['style', new Y.Map()]
    ]
  } as const;
}

export function createNewTableData(
  columnsAmount: number,
  rowsAmount: number,
  options: InitializePropsOptions
): ManifestInitializedProps {
  const { contentObjectData, id } = createNewTableDataBase(columnsAmount, rowsAmount, options);
  return [
    {
      id,
      contentObjectData: new Y.Map(contentObjectData)
    }
  ];
}

export const createContentId = (columnId: ColumnId, rowId: RowId): ContentId => {
  return `${columnId}-${rowId}`;
};

/**
 *
 * @param contentId
 * @returns [columnId, rowId]
 *
 */
export const getCellInfoFromContentId = (contentId: ContentId): [ColumnId, RowId] => {
  return contentId.split('-') as [ColumnId, RowId];
};

export const getRows = (rowOrder: TRowOrder, columnOrder: TColumnOrder): TRow[] => {
  return rowOrder.map(rowId => ({
    id: rowId,
    cells: {
      ...columnOrder.reduce((acc, columnId) => ({ ...acc, [columnId]: createContentId(columnId, rowId) }), {} as TCells)
    }
  }));
};
export const getColumns = (columnOrder: TColumnOrder, hasLeadingColumn: boolean): ColumnDef<TRow>[] => {
  const amountToSkip = hasLeadingColumn ? 0 : 1;
  return drop(columnOrder, amountToSkip).map(id => ({
    accessorFn: row => row.cells[id],
    id
  }));
};

const createDefaultCaption = (locale: InitializePropsOptions['locale']): Caption => {
  return htmlToXmlFragment(`<h1>${manifestLocales.defaultCaption[locale] ?? ''}</h1>`);
};

const creteInitialContent = (columnOrder: TColumnOrder, rowOrder: TRowOrder): [string, unknown][] => {
  const content: [string, unknown][] = [];

  // Headers
  for (const columnId of columnOrder) {
    content.push([columnId, getDefaultContentValue()]);
  }

  // Table Cells
  for (const rowId of rowOrder) {
    for (const columnId of columnOrder) {
      const cellId = createContentId(columnId, rowId);
      content.push([cellId, getDefaultContentValue()]);
    }
  }

  return content;
};

const createDefaultColumnWidths = (columnOrder: TColumnOrder): TColumnWidths => {
  const columnWidths = columnOrder.reduce(
    (acc, columnId) => ({ ...acc, [columnId]: DEFAULT_TABLE_WIDTH / columnOrder.length - 1 }),
    {} as TColumnWidths
  );
  columnWidths[last(columnOrder) as string] = 0; //allow the last row to fill the remaining space
  return columnWidths;
};

const createDefaultRowHeights = (rowOrder: TRowOrder): TRowHeights => {
  return rowOrder.reduce((acc, rowId) => ({ ...acc, [rowId]: DEFAULT_ROW_HEIGHT }), {});
};

const createColumnOrder = (amountOfColumns: number): TColumnOrder => {
  return Array.from({ length: amountOfColumns }, () => genRandId());
};

const createRowOrder = (amountOfRows: number): TRowOrder => {
  return Array.from({ length: amountOfRows }, () => genRandId());
};
