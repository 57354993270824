import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';
import { customNodes } from '@/utils/tiptap/customNodes';

export type FontFamilyOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontFamily: {
      /**
       * Set the font family
       */
      setFontFamily: (fontFamily: string) => ReturnType;
      /**
       * Unset the font family
       */
      unsetFontFamily: () => ReturnType;
    };
  }
}

export const FontFamily = Extension.create<FontFamilyOptions>({
  name: 'fontFamily',

  addOptions() {
    return {
      types: ['textStyle']
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: element => element.style.fontFamily?.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontFamily) {
                return {};
              }

              return {
                style: `font-family: ${attributes.fontFamily}`
              };
            }
          }
        }
      }
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        fontFamily =>
        ({ chain, tr }) => {
          const from = tr.selection.ranges[0].$from.pos;
          const to = tr.selection.ranges[0].$to.pos;
          tr.doc.descendants((node, pos) => {
            if (customNodes.includes(node.type.name) && pos >= from && pos <= to) {
              tr.setNodeAttribute(pos, 'fontFamily', fontFamily);
            }
          });
          return chain().setMark('textStyle', { fontFamily }).run();
        },
      unsetFontFamily:
        () =>
        ({ chain, tr }) => {
          const from = tr.selection.ranges[0].$from.pos;
          const to = tr.selection.ranges[0].$to.pos;
          tr.doc.descendants((node, pos) => {
            if (customNodes.includes(node.type.name) && pos >= from && pos <= to) {
              tr.setNodeAttribute(pos, 'fontFamily', null);
            }
          });
          return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run();
        }
    };
  }
});
