import { WidgetType } from 'shared/utils/widgets';

import { ImageSchemaType } from '@/widgets/Image/Schema';
import { Base } from '@/widgets/Image/config/Base';
import { GoToType, ObjectFit } from 'shared/types/Image';
import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Config() {
  const [isDecorative, setIsDecorative] = useContentObjectProperty<boolean>('isDecorative');
  const [alt, setAlt] = useContentObjectProperty<string>('alt');
  const [mobileSrc, setMobileSrc] = useContentObjectProperty<string>('mobileSrc');
  const [objectFit, setObjectFit] = useContentObjectProperty<ObjectFit>('objectFit');
  const [mobileObjectFit, setMobileObjectFit] = useContentObjectProperty<ObjectFit>('mobileObjectFit');
  const [errors] = useContentObjectErrors<ImageSchemaType>();
  const [widgetType] = useContentObjectProperty<WidgetType>('type');
  const [goToType, setGoToType] = useContentObjectProperty<GoToType | null>('goToType');

  const isStandaloneImageWidget = widgetType === WidgetType.Image;

  return (
    <Base
      alt={alt}
      errors={errors}
      goToType={goToType}
      isDecorative={isDecorative}
      mobileObjectFit={mobileObjectFit}
      mobileSrc={mobileSrc}
      objectFit={objectFit}
      setAlt={setAlt}
      setGoToType={setGoToType}
      setIsDecorative={setIsDecorative}
      setMobileObjectFit={setMobileObjectFit}
      setMobileSrc={setMobileSrc}
      setObjectFit={setObjectFit}
      withCustomCSSClasses={isStandaloneImageWidget}
      withGoToSettings={isStandaloneImageWidget}
    />
  );
}
