import { Stack } from '@mantine/core';
import { ReactElement, useCallback } from 'react';

import { StyledActionIcon } from '@/components/StyledActionIcon';

type Props = {
  label: string;
  icon: ReactElement;
  onClick: () => void;
  disabled?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  dataTestId?: string;
};

export function QuestionActionButton(props: Props) {
  const { icon, label, onClick, disabled, containerClassName, dataTestId } = props;

  const handleOnClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <Stack className={containerClassName} align="center" gap={0} onClick={handleOnClick}>
      <StyledActionIcon
        aria-label={label}
        label={label}
        disabled={disabled}
        icon={icon}
        data-testid={dataTestId}
        size={40}
      />
    </Stack>
  );
}
