import { Image, Text } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import { ModalNavArrow } from '@/widgets/OpenDiscussion/player/ModalNavArrow';

type FocusModalContentProps = {
  answer: OpenDiscussionAnswer;
  maxIndexes: { column: number; row: number };
  boardIndexes: {
    column: number;
    row: number;
  };
  setBoardIndexes: Dispatch<
    SetStateAction<{
      column: number;
      row: number;
    }>
  >;
  columns: number[];
  focusedAnswerIndex: number;
  totalAnswers: number;
  setFocusedAnswerIndex: Dispatch<SetStateAction<number>>;
  isAvailable: boolean;
};

export const FocusModalContent = (props: FocusModalContentProps) => {
  const {
    answer,
    maxIndexes,
    boardIndexes,
    setBoardIndexes,
    columns,
    focusedAnswerIndex,
    totalAnswers,
    setFocusedAnswerIndex,
    isAvailable
  } = props;
  const { text, image } = answer;

  const previousCard = () => {
    if (boardIndexes.column > 0 && boardIndexes.row === 0) {
      let currentColumnIndex = boardIndexes.column - 1;
      while (currentColumnIndex >= 0 && columns[currentColumnIndex] === 0) {
        currentColumnIndex--;
      }

      setBoardIndexes({ column: currentColumnIndex, row: columns[currentColumnIndex] - 1 });
    } else {
      setBoardIndexes(boardIndexes => ({ column: boardIndexes.column, row: boardIndexes.row - 1 }));
    }

    setFocusedAnswerIndex(index => index - 1);
  };

  const nextCard = () => {
    if (boardIndexes.column < maxIndexes.column && boardIndexes.row === maxIndexes.row) {
      let currentColumnIndex = boardIndexes.column + 1;
      while (currentColumnIndex < columns.length - 1 && columns[currentColumnIndex] === 0) {
        currentColumnIndex++;
      }

      setBoardIndexes({ column: currentColumnIndex, row: 0 });
    } else {
      setBoardIndexes(boardIndexes => ({ column: boardIndexes.column, row: boardIndexes.row + 1 }));
    }

    setFocusedAnswerIndex(index => index + 1);
  };

  const hasPrevious = focusedAnswerIndex > 0 && (boardIndexes.column > 0 || boardIndexes.row > 0);

  const hasNext =
    focusedAnswerIndex < totalAnswers - 1 &&
    (boardIndexes.column < maxIndexes.column || boardIndexes.row < maxIndexes.row);

  return (
    <>
      {hasPrevious && isAvailable && <ModalNavArrow onClick={previousCard} />}
      {image && (
        <Image
          alt=""
          src={image}
          fit="contain"
          mb={text ? 'sm' : undefined}
          radius="sm"
          style={{
            objectPosition: 'center'
          }}
          h="60vh"
          w="fit-content"
          maw="100%"
          mah={550}
          mx="auto"
        />
      )}
      {text && (
        <Text miw={600} maw="90%" mah="90%" fz={44} fw={400} lh="130%" c="systemDark.8" mx="auto">
          {text}
        </Text>
      )}
      {hasNext && isAvailable && <ModalNavArrow isArrowNext onClick={nextCard} />}
    </>
  );
};
