import { useEffect } from 'react';

import { SupportedLocale } from 'shared/types/SupportedLocale';

import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ConfigElement, StageElement } from '@/contentObjects/Plugin';
import { useStudioEvents } from '@/contentObjects/Plugin/hooks/useStudioEvents';
import { useGetPluginById } from '@/queries/plugin';
import { WidgetAction } from '@/types/Stage';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { useWorkMode } from '@/utils/useWorkMode';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function Studio(props: StudioProps) {
  const { initialize } = props;
  const { contentLanguage } = useContentLanguage();
  const { uiLanguage } = useUiLanguage();

  const [id] = useContentObjectProperty<string>('id');
  const [pluginId] = useContentObjectProperty<string>('pluginId');
  const { data } = useGetPluginById(pluginId);
  const { isStudioEdit } = useWorkMode();

  useEffect(() => {
    const actions: WidgetAction[] = ['Duplicate', 'Delete'];
    const initData = {
      actions,
      constraints: { minHeight: 200, minWidth: 200 },
      shouldKeepAspectRatio: true,
      isResizableVertically: true
    };
    initialize?.(initData);
  }, [initialize]);

  useStudioEvents(id);

  if (!data) {
    return null;
  }

  return (
    <>
      <StageElement
        id={id}
        script={data.script}
        isStudioEdit={isStudioEdit}
        contentLanguage={contentLanguage}
        uiLanguage={uiLanguage}
        isQuestion={false}
      />
      <SideMenuPortal>
        <ConfigElement
          id={id}
          script={data.script}
          isStudioEdit={isStudioEdit}
          contentLanguage={contentLanguage}
          uiLanguage={uiLanguage}
          title={data.title[uiLanguage as SupportedLocale]}
          tabs={data.configTabs}
          iconUrl={data.iconUrl}
        />
      </SideMenuPortal>
    </>
  );
}
