import * as Y from 'yjs';

import { AnswerConfig } from '../types/AnswerConfig';
import { generateDefaultAnswer } from '../utils/generateDefaultAnswer';

export const initializeAnswers = (config: AnswerConfig, answersAmount: number, customContents?: string[]) => {
  const answersArray = new Y.Array();
  const idsArray: string[] = [];

  answersArray.push(
    Array.from({ length: answersAmount }).map((_, index) => {
      const [map, id] = generateDefaultAnswer(config, customContents?.[index]);

      idsArray.push(id);
      return map;
    })
  );

  return [answersArray, idsArray] as const;
};
