import type { ActivityThemeStyles, BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import type { MantineTheme, MantineThemeOverride } from '@mantine/core';
import { fallbackVar } from '@vanilla-extract/css';

import { rem } from 'shared/styles/rem';

import type { Direction } from './locale.utils';
import type { ThemeVars } from '@/utils/mantineVanillaIntegration/themeToVars';

export const sidebarWidth = 80;
export const configWidth = 316;
export const stageSpaceX = 24;

export const theme: MantineThemeOverride = {
  primaryShade: 6,
  primaryColor: 'accent',
  colors: {
    accent: [
      '#F5F5FF',
      '#ECEBFF',
      '#BAC8FF',
      '#ADA1FC',
      '#8D83FB',
      '#7771FA',
      '#5750FA',
      '#4245FA',
      '#1737F6',
      '#122FD6'
    ],
    systemDark: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343A40',
      '#212529'
    ],
    success: [
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971',
      '#02C971'
    ],
    warning: [
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C',
      '#FF922C'
    ],
    failure: [
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980',
      '#E64980'
    ]
  },

  cursorType: 'pointer',
  defaultRadius: 'sm',

  // Breakpoints needs to be defined and confirmed
  breakpoints: {
    xs: '22rem',
    sm: '48rem',
    md: '64rem',
    lg: '74rem',
    xl: '90rem'
  }
};

type Overrides = {
  borderColor?: string | null;
  borderWidth?: number;
  borderStyle?: string | null;
};

const emptyVar = 'var(--emptyVar)';

export const isVar = (text: unknown): text is `var(--${string})` =>
  typeof text === 'string' && text.startsWith('var(--');

const applyUnit = (value: string | number, unit = '1px') => {
  // Applies unit to either value or CSS variable
  return `calc(${value} * ${unit})`;
};

export const getThemeBorderStyleAsOneString = (
  border: BorderThemeProps | ThemeVars<BorderThemeProps>,
  globalBorderColor = theme.other?.global?.content?.colors?.decoration?.main,
  overrides: Overrides | ThemeVars<Overrides>
) => {
  const borderStyle = getThemeBorderStyle(border, globalBorderColor, overrides);
  return `${rem(borderStyle.borderWidth)} ${borderStyle.borderStyle} ${borderStyle.borderColor}`;
};

export const getThemeBorderStyle = (
  border: BorderThemeProps | ThemeVars<BorderThemeProps>,
  globalBorderColor?: string,
  overrides?: Overrides | ThemeVars<Overrides>
) => {
  const { borderColor, borderWidth, borderStyle } = border;

  if (isVar(borderColor) || isVar(borderWidth) || isVar(borderStyle)) {
    return getThemeBorderStyleVars(
      border as ThemeVars<BorderThemeProps>,
      globalBorderColor,
      overrides as ThemeVars<Overrides>
    );
  } else {
    return getThemeBorderStyleTheme(border as BorderThemeProps, globalBorderColor, overrides as Overrides);
  }
};

const getThemeBorderStyleTheme = (border: BorderThemeProps, globalBorderColor?: string, overrides?: Overrides) => {
  const { borderColor, borderWidth, borderStyle } = border;

  return {
    borderColor: overrides?.borderColor ?? borderColor ?? globalBorderColor,
    borderStyle: overrides?.borderStyle ?? borderStyle,
    borderWidth: overrides?.borderWidth ?? borderWidth
  };
};

const getThemeBorderStyleVars = (
  border: ThemeVars<BorderThemeProps>,
  globalBorderColor?: string,
  overrides?: ThemeVars<Overrides>
) => {
  const { borderColor, borderWidth, borderStyle } = border;

  return {
    borderColor: fallbackVar(
      overrides?.borderColor ?? emptyVar,
      borderColor ?? emptyVar,
      globalBorderColor ?? emptyVar
    ),
    borderStyle: fallbackVar(overrides?.borderStyle ?? emptyVar, borderStyle),
    borderWidth: applyUnit(fallbackVar(overrides?.borderWidth ?? emptyVar, borderWidth))
  };
};

export const getThemeBorderRadiusStyle = (
  borderRadius: BorderRadiusThemeProps | ThemeVars<BorderRadiusThemeProps>,
  overrides?: Partial<BorderRadiusThemeProps> | Partial<ThemeVars<BorderRadiusThemeProps>>,
  direction: Direction = 'ltr'
) => {
  const { topLeft, topRight, bottomLeft, bottomRight, measureUnit } = borderRadius;

  if (isVar(topLeft) || isVar(topRight) || isVar(bottomLeft) || isVar(bottomRight) || isVar(measureUnit)) {
    return getThemeBorderRadiusStyleVars(
      borderRadius as ThemeVars<BorderRadiusThemeProps>,
      overrides as Partial<ThemeVars<BorderRadiusThemeProps>>,
      direction
    );
  } else {
    return getThemeBorderRadiusStyleTheme(
      borderRadius as BorderRadiusThemeProps,
      overrides as Partial<BorderRadiusThemeProps>,
      direction
    );
  }
};

const getThemeBorderRadiusStyleTheme = (
  borderRadius: BorderRadiusThemeProps,
  overrides?: Partial<BorderRadiusThemeProps>,
  direction: Direction = 'ltr'
) => {
  const { topLeft, topRight, bottomLeft, bottomRight, measureUnit } = borderRadius;

  const effectiveTopLeft = overrides?.topLeft ?? topLeft;
  const effectiveTopRight = overrides?.topRight ?? topRight;
  const effectiveBottomLeft = overrides?.bottomLeft ?? bottomLeft;
  const effectiveBottomRight = overrides?.bottomRight ?? bottomRight;
  const effectiveMeasureUnit = overrides?.measureUnit ?? measureUnit;

  const resultTopLeft = direction === 'rtl' ? effectiveTopRight : effectiveTopLeft;
  const resultTopRight = direction === 'rtl' ? effectiveTopLeft : effectiveTopRight;
  const resultBottomLeft = direction === 'rtl' ? effectiveBottomRight : effectiveBottomLeft;
  const resultBottomRight = direction === 'rtl' ? effectiveBottomLeft : effectiveBottomRight;

  return {
    borderRadius: `${applyUnit(resultTopLeft, effectiveMeasureUnit)} ${applyUnit(
      resultTopRight,
      effectiveMeasureUnit
    )} ${applyUnit(resultBottomRight, effectiveMeasureUnit)} ${applyUnit(resultBottomLeft, effectiveMeasureUnit)}`
  };
};

const getThemeBorderRadiusStyleVars = (
  borderRadius: ThemeVars<BorderRadiusThemeProps>,
  overrides?: Partial<ThemeVars<BorderRadiusThemeProps>>,
  direction: Direction = 'ltr'
) => {
  const { topLeft, topRight, bottomLeft, bottomRight, measureUnit } = borderRadius;

  const resultTopLeft = fallbackVar(overrides?.topLeft ?? emptyVar, topLeft);
  const resultTopRight = fallbackVar(overrides?.topRight ?? emptyVar, topRight);
  const resultBottomLeft = fallbackVar(overrides?.bottomLeft ?? emptyVar, bottomLeft);
  const resultBottomRight = fallbackVar(overrides?.bottomRight ?? emptyVar, bottomRight);
  const resultMeasureUnit = fallbackVar(overrides?.measureUnit ?? emptyVar, measureUnit);

  const effectiveTopLeft = direction === 'rtl' ? resultTopRight : resultTopLeft;
  const effectiveTopRight = direction === 'rtl' ? resultTopLeft : resultTopRight;
  const effectiveBottomLeft = direction === 'rtl' ? resultBottomRight : resultBottomLeft;
  const effectiveBottomRight = direction === 'rtl' ? resultBottomLeft : resultBottomRight;

  return {
    borderRadius: `${applyUnit(effectiveTopLeft, resultMeasureUnit)} ${applyUnit(
      effectiveTopRight,
      resultMeasureUnit
    )} ${applyUnit(effectiveBottomRight, resultMeasureUnit)} ${applyUnit(effectiveBottomLeft, resultMeasureUnit)}`
  };
};

export const PROSE_MIRROR_SELECTOR_CHILDREN = 'h1,h2,h3,h4,p,label,div,span';

/**
 * @description This selector is used to style the content of the ProseMirror editor
 */
export const PROSE_MIRROR_SELECTOR = PROSE_MIRROR_SELECTOR_CHILDREN.split(',')
  .map(child => `.ProseMirror ${child}`)
  .join(',');

/**
 * @description This selector is used to style the content of the ProseMirror preview
 */
export const PREVIEW_CONTENT_SELECTOR = PROSE_MIRROR_SELECTOR_CHILDREN.split(',')
  .map(child => `.previewContent ${child}`)
  .join(',');

type LayoutSections = keyof ActivityThemeStyles['sections'];

export const getLayoutSectionWrapperThemeStyles = (
  theme: MantineTheme | ThemeVars<MantineTheme>,
  type: 'vertical' | 'horizontal',
  nestLevel: number
) => {
  const secureNestLevel = Math.min(nestLevel, 3);
  const key = `${type}${secureNestLevel}` as LayoutSections;
  const sectionStyle = theme.other.sections[key];
  const borderStyle = getThemeBorderStyle(sectionStyle.border);
  const borderRadiusStyle = getThemeBorderRadiusStyle(sectionStyle.borderRadius);

  return {
    backgroundColor: sectionStyle.backgroundColor,
    padding: applyUnit(sectionStyle.padding),
    ...borderStyle,
    ...borderRadiusStyle
  };
};

export const getLayoutSectionThemeStyles = (
  theme: MantineTheme | ThemeVars<MantineTheme>,
  type: 'vertical' | 'horizontal',
  nestLevel: number
) => {
  const secureNestLevel = Math.min(nestLevel, 3);
  const key = `${type}${secureNestLevel}` as LayoutSections;
  const sectionStyle = theme.other.sections[key];

  return {
    gap: sectionStyle.gap,
    ...(type === 'vertical' && { alignItems: sectionStyle.alignTo }),
    ...(type === 'horizontal' && { justifyContent: sectionStyle.alignTo })
  };
};

export const getVerticalSectionWrapperThemeStyles = (
  theme: MantineTheme | ThemeVars<MantineTheme>,
  nestLevel: number
) => {
  return getLayoutSectionWrapperThemeStyles(theme, 'vertical', nestLevel);
};

export const getHorizontalSectionWrapperThemeStyles = (
  theme: MantineTheme | ThemeVars<MantineTheme>,
  nestLevel: number
) => {
  return getLayoutSectionWrapperThemeStyles(theme, 'horizontal', nestLevel);
};

export const getVerticalSectionThemeStyles = (theme: MantineTheme | ThemeVars<MantineTheme>, nestLevel: number) => {
  return getLayoutSectionThemeStyles(theme, 'vertical', nestLevel);
};

export const getHorizontalSectionThemeStyles = (theme: MantineTheme | ThemeVars<MantineTheme>, nestLevel: number) => {
  return getLayoutSectionThemeStyles(theme, 'horizontal', nestLevel);
};
