import { useLearningObjectDraft, useLearningObjectSnapshot } from '@/queries/learningObject';
import { useTheme } from '@/queries/theme';
import { useLearningObjectIds } from '@/utils/useLearningObjectIds';
import { useWorkMode } from '@/utils/useWorkMode';

export function useParentTheme() {
  const { learningObjectDraftId, learningObjectSnapshotId } = useLearningObjectIds();
  const { isStudio } = useWorkMode();
  const { data: draft } = useLearningObjectDraft(isStudio ? learningObjectDraftId : undefined);
  const { data: snapshot } = useLearningObjectSnapshot(!isStudio ? learningObjectSnapshotId : undefined);
  return useTheme(isStudio ? draft?.themeId : snapshot?.themeId);
}
