export const manifestLocales = {
  fillInTheBlanksQuestion: {
    previewFillInTheBlanksQuestionAlt: {
      en: 'FillInTheBlanksQuestion widget',
      he: 'השלמת משפטים'
    },
    initialHeadlineText: {
      en: 'Headline / Question',
      he: 'כותרת/שאלה'
    },
    answerPlaceholder: {
      en: 'Answer placeholder',
      he: 'ממלא מקום לתשובה'
    }
  }
};
