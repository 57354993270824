import { Box, Center, Flex } from '@mantine/core';
import cx from 'clsx';
import { useCallback, useEffect } from 'react';
import * as Y from 'yjs';

import { FeedbackMessageType } from 'shared/evaluation/feedback';
import { WidgetType } from 'shared/utils/widgets';

import * as classes from './InteractiveQuizStudent.css';
import { PlenaryLessonAnswerFeedback, PlenaryAnswerStatus } from '@/components/PlenaryLessonAnswerFeedback';
import { PlenaryLessonSubmitButton } from '@/components/PlenaryLessonSubmit';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { InteractiveQuizAnswerPlayerImplementation } from '@/contentObjects/InteractiveQuizAnswer/InteractiveQuizAnswerPlayer';
import { useUser } from '@/queries/user';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { isQuestionAnswered } from '@/utils/questions.utils';
import { useCollaborativeQuestionAnswer } from '@/utils/useCollaborativeQuestionAnswer';
import { useCollaborativeQuestionState } from '@/utils/useCollaborativeQuestionState';
import { useContentObject, useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { useEvaluatableQuestionAnswersContext } from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';

const feedbackToPlenaryAnswerStatus: Record<FeedbackMessageType, PlenaryAnswerStatus> = {
  noAnswer: 'noAnswer',
  notAllCorrectAndNoWrongAnswers: 'partialSuccess',
  onlyAllCorrectAnswers: 'success',
  onlyWrongAnswers: 'failure',
  showAnswer: 'noAnswer',
  someCorrectAndSomeWrongAnswers: 'partialSuccess'
};

export const InteractiveQuizStudent = () => {
  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const { user } = useUser();

  const {
    selectedAnswers,
    handleEvaluate,
    feedbackMessageType: feedback
  } = useEvaluatableQuestionAnswersContext<WidgetType.InteractiveQuiz>();

  const [collaborativeAnswer, setCollaborativeAnswer] = useCollaborativeQuestionAnswer(
    id,
    `${user?.id ?? user?.firstName}`
  );
  const isSubmitted = collaborativeAnswer && isQuestionAnswered({ answers: collaborativeAnswer });

  const { state } = useCollaborativeQuestionState(id);
  const isRevealed = state.isRevealed;

  const onSubmit = useCallback(() => {
    setCollaborativeAnswer(selectedAnswers);
  }, [setCollaborativeAnswer, selectedAnswers]);

  useEffect(() => {
    if (isRevealed) {
      handleEvaluate(collaborativeAnswer, false);
    }
  }, [isRevealed, handleEvaluate, collaborativeAnswer]);

  return isSubmitted || isRevealed ? (
    <Center>
      <PlenaryLessonAnswerFeedback
        status={isRevealed && feedback ? feedbackToPlenaryAnswerStatus[feedback] : 'waiting'}
      />
    </Center>
  ) : (
    <Flex direction="column" className={cx(classes.widgetWrapper, customClassNames)}>
      {labelFragment && (
        <Box className={cx(questionClasses.label, classes.labelWrapper, ThemeClassNames.widgets.interactiveQuiz.label)}>
          <RichText fragment={labelFragment} dataTestIdPrefix="preview-interactive-quiz-title" editable={false} />
        </Box>
      )}
      <Box className={classes.answersWrapper}>
        <InteractiveQuizAnswerPlayerImplementation />
      </Box>
      <PlenaryLessonSubmitButton onSubmit={onSubmit} isDisabled={selectedAnswers.length === 0} />
    </Flex>
  );
};
