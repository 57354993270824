import * as Y from 'yjs';

import { getContentObjectsMap, useObservedArray, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { VerticalAlignment } from '@/widgets/CombinedTitle/types';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

const verticalAlignmentToCss: Record<VerticalAlignment, string> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

export const useCombinedTitleStyleProperties = (id: string, document: Y.Doc) => {
  const contentObjectsMap = getContentObjectsMap(document);

  const [_verticalAlignment] = useWidgetStyle<VerticalAlignment>(document, id, 'verticalAlignment');
  const [_imagePosition] = useWidgetStyle<string>(document, id, 'imagePosition');
  const [_gap] = useWidgetStyle<number>(document, id, 'gap');

  const [themeVerticalAlignment] = useActivityThemeStylesProperty<VerticalAlignment>(
    document,
    'combinedTitle.verticalAlignment'
  );
  const [themeImagePosition] = useActivityThemeStylesProperty<string>(document, 'combinedTitle.imagePosition');
  const [themeGap] = useActivityThemeStylesProperty<number>(document, 'combinedTitle.gap');

  const verticalAlignment = _verticalAlignment ?? themeVerticalAlignment;
  const imagePosition = _imagePosition ?? themeImagePosition;
  const gap = _gap ?? themeGap;

  const [horizontalSectionId] = useContentObjectProperty<string>('children.0');
  const [horizontalSection] = useObservedProperty<Y.Map<unknown>>(contentObjectsMap, horizontalSectionId);
  const [horizontalSectionChildren] = useObservedArray<string>(horizontalSection, 'children');

  const [imageId, textId] = horizontalSectionChildren;
  const defaultItemsOrder = { [imageId]: 1, [textId]: 2 };
  const reversedItemsOrder = { [imageId]: 2, [textId]: 1 };

  return {
    alignItems: verticalAlignment ? verticalAlignmentToCss[verticalAlignment] : undefined,
    gap,
    itemsOrder: imagePosition === 'start' ? defaultItemsOrder : reversedItemsOrder
  };
};
