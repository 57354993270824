import { CSSProperties, MouseEvent, ReactNode, useCallback, useId } from 'react';

import { useStyles } from './Hoverable.styles';
import { useHoverableStore } from './hooks/useHoverableStore';

type HoverableProps = {
  children: ReactNode;
  style?: CSSProperties;
};

export function Hoverable(props: HoverableProps) {
  const { children, style } = props;

  const id = useId();
  const hoveredId = useHoverableStore(state => state.hoveredId);
  const hoverOver = useHoverableStore(state => state.hoverOver);
  const { classes } = useStyles({ isHovered: hoveredId === id });

  const handleMouseMove = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const isPrimaryMouseButtonPressed = event.buttons === 1;
      if (isPrimaryMouseButtonPressed) {
        return; //disable hover while dragging
      }

      const target = event.target as HTMLElement;
      const closestHoverable = target.closest(`[data-hoverable-id]`);
      const hoverableId = closestHoverable?.getAttribute('data-hoverable-id');

      if (hoverableId !== hoveredId) {
        hoverOver(hoverableId);
      }
    },
    [hoverOver, hoveredId]
  );

  const handleMouseLeave = useCallback(() => hoverOver(null), [hoverOver]);

  return (
    <div
      className={classes.hoverable}
      data-hoverable-id={id}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={style}
    >
      {children}
    </div>
  );
}
