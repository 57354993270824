import * as Y from 'yjs';

import { genRandId } from 'shared/utils/genRandId';

import { ClozeOptionType } from 'shared/types/ClozeQuestion';

export function generateDefaultOption(initialContent: string | null = '', id?: string) {
  return new Y.Map([
    ['id', id ?? genRandId()],
    ['value', initialContent]
  ]);
}

export function initializeClozeOption(clozeId: string, clozeType: ClozeOptionType) {
  return new Y.Map([
    ['id', clozeId],
    ['type', clozeType]
  ]);
}

export function initializeClozeOptionInputData(initialContent: string) {
  const inputCorrectOptions = new Y.Array();
  const optionId = genRandId();
  inputCorrectOptions.push([generateDefaultOption(initialContent, optionId)]);

  return inputCorrectOptions;
}

export function initializeClozeOptionSelectData(initialContent: string, secondOptionInitialContent: string) {
  const optionId = genRandId();
  const correctOption = generateDefaultOption(initialContent, optionId);
  const secondOption = generateDefaultOption(secondOptionInitialContent);

  const possibleOptions = new Y.Array();
  possibleOptions.push([correctOption, secondOption]);

  return { possibleOptions, correctOption, optionId };
}
