import { useRef, useState } from 'react';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import { initialCardColor } from './ColorSwatches';
import { useFileUpload } from '@/queries/fileUpload';
import { useUser } from '@/queries/user';
import { StudentView } from '@/widgets/OpenDiscussion/player/StudentView';
import { TeacherView } from '@/widgets/OpenDiscussion/player/TeacherView';
import { useCollaborativeAnswers } from '@/widgets/OpenDiscussion/player/hooks/useCollaborativeAnswers';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const OpenDiscussion = () => {
  const { user } = useUser();
  const { id } = useContentObject();
  const { openDiscussionAnswers, addAnswer, updateAnswer, deleteAnswer } = useCollaborativeAnswers(id);
  const { mutateAsync: uploadFile, isLoading } = useFileUpload();

  const [text, setText] = useState('');
  const [image, setImage] = useState<File | string | null>(null);
  const [editAnswerId, setEditAnswerId] = useState<string | null>(null);
  const [cardColor, setCardColor] = useState<string>(initialCardColor);
  const resetRef = useRef<() => void>(null);

  const clearAnswer = () => {
    setText('');
    setImage(null);
    setEditAnswerId(null);
    resetRef.current?.();
  };

  const getImageUrl = async () => {
    return image instanceof File ? await uploadFile(image) : image ?? undefined;
  };

  const handleOpenEditMode = (answer: OpenDiscussionAnswer) => {
    setText(answer.text);
    setImage(answer.image ?? null);
    setEditAnswerId(answer.id);
    setCardColor(answer.cardColor);
  };

  const handleCancelEditMode = () => {
    setEditAnswerId(null);
    clearAnswer();
  };

  const handleEdit = async () => {
    if ((text || image) && user?.id && editAnswerId) {
      updateAnswer({
        id: editAnswerId,
        text: text.trim(),
        image: await getImageUrl(),
        userId: user.id,
        cardColor
      });
    }

    setEditAnswerId(null);
    clearAnswer();
  };

  const handleSend = async () => {
    if ((text || image) && user?.id) {
      addAnswer({
        id: Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36),
        text: text.trim(),
        image: await getImageUrl(),
        userId: user.id,
        cardColor
      });
      clearAnswer();
    }
  };

  const handleEditCardColor = async (newColor: string, answer: OpenDiscussionAnswer) => {
    updateAnswer({ ...answer, cardColor: newColor });
  };

  const handleImageDelete = () => {
    setImage(null);
    resetRef.current?.();
  };

  if (user?.role === 'student' || user?.role === 'plenaryStudent') {
    return (
      <StudentView
        deleteAnswer={deleteAnswer}
        editAnswerId={editAnswerId}
        handleCancelEditMode={handleCancelEditMode}
        handleEdit={handleEdit}
        handleImageDelete={handleImageDelete}
        handleOpenEditMode={handleOpenEditMode}
        handleSend={handleSend}
        image={image}
        answers={openDiscussionAnswers.filter(({ userId }) => userId === user.id)}
        isLoading={isLoading}
        resetRef={resetRef}
        setImage={setImage}
        setText={setText}
        text={text}
      />
    );
  }

  return (
    <TeacherView
      deleteAnswer={deleteAnswer}
      editAnswerId={editAnswerId}
      handleCancelEditMode={handleCancelEditMode}
      handleEdit={handleEdit}
      handleEditCardColor={handleEditCardColor}
      handleImageDelete={handleImageDelete}
      handleOpenEditMode={handleOpenEditMode}
      handleSend={handleSend}
      image={image}
      answers={openDiscussionAnswers}
      isLoading={isLoading}
      resetRef={resetRef}
      setImage={setImage}
      setText={setText}
      text={text}
    />
  );
};
