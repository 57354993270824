import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  const secondaryButtonStyle = theme.other.button.secondary;
  const standardStyle = secondaryButtonStyle.standard;
  const hoveredStyle = secondaryButtonStyle.hovered;
  const globalSystemColorsStyle = theme.other.global.system.colors;

  const borderStyle = (buttonState: 'standard' | 'hovered') => {
    const borderStyle = theme.other.button.secondary[buttonState].border;
    return getThemeBorderStyle(borderStyle, globalSystemColorsStyle.decoration.main);
  };
  const borderRadiusStyle = getThemeBorderRadiusStyle(standardStyle.borderRadius);

  return {
    actionIcon: {
      color: standardStyle.color ?? globalSystemColorsStyle.secondary.contrast,
      backgroundColor: standardStyle.backgroundColor ?? globalSystemColorsStyle.secondary.main,
      ...borderStyle('standard'),
      ...borderRadiusStyle,

      '&:hover': {
        color: hoveredStyle.color ?? globalSystemColorsStyle.secondary.main,
        backgroundColor: hoveredStyle.backgroundColor ?? globalSystemColorsStyle.secondary.contrast,
        ...borderStyle('hovered')
      }
    }
  };
});
