import { LearningObjectTypes } from 'shared/types/LearningObjectType';
import { LearningObjectDraft } from 'shared/types/LearningObjects';

export const listLearningObjectDrafts = async (deleted?: boolean) => {
  const params = new URLSearchParams({
    ...(deleted && { deleted: 'true' })
  });

  const response = await fetch(`/api/learning-object-draft/list?${params.toString()}`, { method: 'GET' });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}. Could not fetch recent learning object drafts.`, {
      cause: response
    });
  }

  return await response.json();
};

export const restoreLearningObjectDraft = async (learningObjectDraftId: string) => {
  const response = await fetch(`/api/learning-object-draft/restore`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const softDeleteLearningObjectDraft = async (learningObjectDraftId: string) => {
  const response = await fetch(`/api/learning-object-draft/soft-delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const updateLearningObjectDraft = async (
  learningObjectDraftId: string,
  update: Partial<Omit<LearningObjectDraft, '_id' | 'data' | 'themeId' | 'updatedAt' | 'documentVersion'>>
) => {
  const response = await fetch(`/api/learning-object-draft/update`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId, update })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const createLearningObjectDraft = async ({
  language,
  type
}: {
  language: string;
  type: LearningObjectTypes;
}) => {
  const response = await fetch('/api/learning-object-draft', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ language, type })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const getLearningObjectDraftById = async (learningObjectDraftId: string, includeDeleted?: boolean) => {
  const params = new URLSearchParams({ ...(includeDeleted && { includeDeleted: 'true' }) });

  const response = await fetch(
    `/api/learning-object-draft/get-one-by-id/${learningObjectDraftId}?${params.toString()}`,
    { method: 'GET' }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const duplicateLearningObjectDraftById = async (learningObjectDraftId: string) => {
  const response = await fetch('/api/learning-object-draft/duplicate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

export const setLearningObjectDraftTheme = async (learningObjectDraftId: string, themeId: string) => {
  const response = await fetch(`/api/learning-object-draft/set-theme`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ learningObjectDraftId, themeId })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};
