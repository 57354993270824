import { Box } from '@mantine/core';
import cx from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import * as Y from 'yjs';

import { AnswerElement } from 'shared/types/AnswerElement';
import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';
import { getContentObjectsMap, getSensitiveDataMap, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { Hoverable } from '@/components/Hoverable';
import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import {
  getQuestionBulletWrapperData,
  shouldShowCheckButton,
  shouldShowResetButton,
  shouldShowScoreWeight
} from '@/utils/questions.utils';
import { ValidationHandler, useValidation } from '@/utils/validation';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useAlignFeedbackWithParent } from '@/widgets/QuestionGroupList/hooks/useAlignFeedbackWithParent';
import { Manifest } from '@/widgets/SelectionQuestion/Manifest';
import { Schema } from '@/widgets/SelectionQuestion/Schema';
import { SensitiveDataSchema } from '@/widgets/SelectionQuestion/SensitiveDataSchema';
import { Config } from '@/widgets/SelectionQuestion/config';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectArray,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';
import { useIsQuestionEmpty } from '@/widgets/hooks/useIsQuestionEmpty';
import { StudioProps } from '@/widgets/types/studioProps';

const getValidator: (selectionQuestionId: string) => ValidationHandler = selectionQuestionId => document => {
  const contentObjectsMap = getContentObjectsMap(document);

  const sensitiveDataMap = getSensitiveDataMap(document);

  const question = contentObjectsMap.get(selectionQuestionId);

  if (!question) {
    throw new Error(`Selection Question ${selectionQuestionId} not found in contentObjects`);
  }

  const feedbackType = question.get('feedback') as Feedbacks;
  const answers = validateYArray(question.get('answers')).toJSON();
  const answerElements = question.get('answerElements') as AnswerElement[];

  const dataValidationResult = Schema.safeParse({ answers, answerElements });
  const dataErrors = dataValidationResult.success ? {} : dataValidationResult.error.format();

  const questionSensitiveData = validateYMap(sensitiveDataMap.get(selectionQuestionId));
  const correctAnswers = questionSensitiveData.get('correctAnswers') as string[];

  const sensitiveDataValidationResult = SensitiveDataSchema.safeParse({ feedbackType, correctAnswers });
  const sensitiveDataErrors = sensitiveDataValidationResult.success ? {} : sensitiveDataValidationResult.error.format();

  return { ...dataErrors, ...sensitiveDataErrors };
};

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

export function SelectionQuestion(props: StudioProps) {
  const { initialize } = props;

  const { document, id } = useContentObject();
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [answers] = useContentObjectArray<Y.Map<unknown>>('answers');
  const [correctAnswers] = useContentObjectProperty<string[]>('correctAnswers', 'sensitiveData');
  const [feedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const [scoreWeight] = useContentObjectScoreWeight();
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();

  const [flexSectionId] = useContentObjectProperty<string>('children.0');
  const isEmpty = useIsQuestionEmpty();

  const isRequired = isQuestionRequired(id, document);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const questionGroupSettings = useParentQuestionGroupSettings();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(feedback, questionGroupSettings);
  const showResetButton = shouldShowResetButton(questionGroupSettings);

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  const validator = useMemo(() => getValidator(id), [id]);
  useValidation(id, document, validator, [id]);

  useAlignFeedbackWithParent();

  if (!answers || !correctAnswers) {
    return null;
  }

  return (
    <div className={customClassNames} ref={containerRef}>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      {labelFragment && (
        <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
          <Selectable>
            <Hoverable>
              <Box className={cx(questionClasses.label, ThemeClassNames.widgets.selectionQuestion.label)}>
                <QuestionTitle
                  questionId={id}
                  fragment={labelFragment}
                  dataTestIdPrefix="studio-selection-question-title"
                />
              </Box>
            </Hoverable>
          </Selectable>
        </ListBulletWrapper>
      )}
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            {isEmpty && <DropzonePlaceholder type="contentItems" />}
            <FlexSectionStudio selectable />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
        <QuestionFooter
          questionId={id}
          hintFragment={hintFragment}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton}
          showResetButton={showResetButton}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
