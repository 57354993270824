import { Flex } from '@mantine/core';
import Lottie from 'lottie-react';
import { useTranslations } from 'next-intl';

import { useStyles } from './TaskSubmittedNotification.styles';
import { useCanReadStudentAssignedMode } from '@/components/CanProvider';
import confetti from '@/icons/lotties/confetti-task-submission.json';
import { useGetAssignmentStatus } from '@/queries/assignment';

type TaskSubmittedNotificationProps = { withAnimation?: boolean };

export const TaskSubmittedNotification = (props: TaskSubmittedNotificationProps) => {
  const { withAnimation = true } = props;

  const t = useTranslations('components');
  const { classes, cx } = useStyles({ withAnimation });

  const canReadStudentAssignedMode = useCanReadStudentAssignedMode();
  const { isReturnedToFix, isEvaluated } = useGetAssignmentStatus({ enabled: true });

  return (
    <Flex justify="center" align="center" className={classes.container}>
      <Lottie animationData={confetti} className={cx(classes.confetti, classes.left)} />
      <h2 className={classes.content}>
        {t(
          `taskSubmittedNotification.${
            canReadStudentAssignedMode
              ? isReturnedToFix
                ? 'teacherReturnedToFix'
                : isEvaluated
                ? 'teacherEvaluated'
                : 'teacherSubmitted'
              : isReturnedToFix
              ? 'returnedToFix'
              : isEvaluated
              ? 'evaluated'
              : 'submitted'
          }`
        )}
      </h2>
      <Lottie animationData={confetti} className={cx(classes.confetti, classes.right)} />
    </Flex>
  );
};
