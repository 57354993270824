import * as Y from 'yjs';

import { getSubQuestionsIds } from './questionGroup';
import {
  getContentObjectsMap,
  getFormDataMap,
  getSensitiveDataMap,
  getWidgetCustomCSSClassesMap,
  validateYArray,
  validateYMap
} from './yjs';
import { genRandId } from '../utils/genRandId';
import { WidgetType, canHaveScoreWeight } from '../utils/widgets';

export function duplicateContentObject(document: Y.Doc, id: string, parentId: string | undefined) {
  const contentObjectsMap = getContentObjectsMap(document);
  const formDataMap = getFormDataMap(document);
  const sensitiveDataMap = getSensitiveDataMap(document);
  const widgetsCustomCSSClassesMap = getWidgetCustomCSSClassesMap(document);

  const contentObject = validateYMap(contentObjectsMap.get(id));
  const formData = validateYMap(formDataMap.get(id));
  const sensitiveData = validateYMap(sensitiveDataMap.get(id));
  const customCSSClassesData = widgetsCustomCSSClassesMap.get(id);

  const currentChildren = contentObject.get('children')
    ? validateYArray<string>(contentObject.get('children'))
    : new Y.Array<string>();
  const newId = genRandId();

  const contentObjectClone = contentObject.clone();
  const formDataClone = formData.clone();
  const sensitiveDataClone = sensitiveData.clone();

  contentObjectClone.set('id', newId);
  // TODO (435786): This fixes a bug but highlights an important possible issue we have which is not having a unified base interface for a general content object
  // for example we should decide that all content object should have "children", "parentId" etc, so we can add types and make sure we are not missing important edge cases
  contentObjectClone.set('parentId', parentId);

  contentObjectsMap.set(newId, contentObjectClone);
  formDataMap.set(newId, formDataClone);
  sensitiveDataMap.set(newId, sensitiveDataClone);
  if (customCSSClassesData) {
    widgetsCustomCSSClassesMap.set(newId, customCSSClassesData);
  }

  const cloneType = contentObjectClone.get('type') as WidgetType;

  const duplicateChildren = () => {
    const newChildrenIds = new Y.Array<string>();
    newChildrenIds.push(
      currentChildren.map(childId => {
        const duplicateId = duplicateContentObject(document, childId, newId)?.get('id') as string;
        // TODO: Generalize for each widget as a helper function?
        if ([WidgetType.Section, WidgetType.FlexSection].includes(cloneType)) {
          const cloneSectionLayout = validateYMap(contentObjectClone.get('layout'));
          cloneSectionLayout.set(duplicateId, validateYMap(cloneSectionLayout.get(childId)).clone());
          cloneSectionLayout.delete(childId);
        }

        return duplicateId;
      })
    );
    contentObjectClone.set('children', newChildrenIds);
  };

  if (cloneType === WidgetType.QuestionGroupList) {
    // since the duplicated subQuestions have the newly generated ids, we have to adjust the `requiredSubQuestionsId` array accordingly

    const subQuestionIds = getSubQuestionsIds(contentObject, contentObjectsMap);
    const requiredSubQuestionsId = contentObject.get('requiredSubQuestionsId') as string[];

    duplicateChildren();

    const newSubQuestionIds = getSubQuestionsIds(contentObjectClone, contentObjectsMap);
    const newRequiredSubQuestionsId = getDuplicatedRequiredSubQuestionIds(
      subQuestionIds,
      newSubQuestionIds,
      requiredSubQuestionsId
    );

    contentObjectClone.set('requiredSubQuestionsId', newRequiredSubQuestionsId);
  } else if (
    [
      WidgetType.ClozeQuestion,
      WidgetType.FillInTheBlanksQuestion,
      WidgetType.OpenQuestion,
      WidgetType.SelectionQuestion,
      WidgetType.EvaluatablePlugin,
      WidgetType.InteractivePoll,
      WidgetType.InteractiveQuiz
    ].includes(cloneType)
  ) {
    duplicateChildren();
    // we have to update the reference for the nested question answer widget
    const questionAnswerId = getQuestionAnswerId(contentObjectClone, contentObjectsMap);
    contentObjectClone.set('questionAnswerId', questionAnswerId);
  } else {
    duplicateChildren();
  }

  return contentObjectClone;
}

const getQuestionAnswerId = (questionMap: Y.Map<unknown>, contentObjectsMap: Y.Map<Y.Map<unknown>>) => {
  const children = validateYArray<string>(questionMap.get('children')).toArray();
  const verticalSectionId = children[0];
  const verticalSection = validateYMap(contentObjectsMap.get(verticalSectionId));
  const verticalSectionChildren = validateYArray<string>(verticalSection.get('children')).toArray();
  let questionAnswerId;
  // this looks for the deeply nested QuestionAnswer widget
  verticalSectionChildren.forEach(childId => {
    const child = contentObjectsMap.get(childId);
    if (child?.get('type') === WidgetType.FlexSection) {
      const nestedChildren = validateYArray<string>(child.get('children')).toArray();
      nestedChildren.find(nestedChildId => {
        const nestedChild = contentObjectsMap.get(nestedChildId);
        if (
          [
            WidgetType.ClozeQuestionAnswer,
            WidgetType.FillInTheBlanksQuestionAnswer,
            WidgetType.OpenQuestionAnswer,
            WidgetType.SelectionQuestionAnswer,
            WidgetType.EvaluatablePluginAnswer,
            WidgetType.InteractivePollAnswer,
            WidgetType.InteractiveQuizAnswer
          ].includes(nestedChild?.get('type') as WidgetType)
        ) {
          questionAnswerId = nestedChildId;
        }
      });
    }
  });

  return questionAnswerId;
};

export function removeContentObject(document: Y.Doc, id: string) {
  const contentObjectsMap = getContentObjectsMap(document);
  const formDataMap = getFormDataMap(document);
  const sensitiveDataMap = getSensitiveDataMap(document);
  const widgetsCustomCSSClassesMap = getWidgetCustomCSSClassesMap(document);

  const contentObject = validateYMap(contentObjectsMap.get(id));
  const currentChildren = contentObject.get('children') as Y.Array<string> | undefined;
  if (currentChildren) {
    currentChildren.forEach(childId => removeContentObject(document, childId));
  }

  contentObjectsMap.delete(id);
  formDataMap.delete(id);
  sensitiveDataMap.delete(id);
  widgetsCustomCSSClassesMap.delete(id);
}

export function createContentObject(document: Y.Doc, id: string, data: Y.Map<unknown>, sensitiveData?: Y.Map<unknown>) {
  const contentObjectsMap = getContentObjectsMap(document);
  const formDataMap = getFormDataMap(document);
  const sensitiveDataMap = getSensitiveDataMap(document);

  contentObjectsMap.set(id, data);
  const newFormDataItemMap = new Y.Map([['errors', {}]]);

  const widgetType = data.get('type');
  // TODO: we should find a way to generalize this:
  if (canHaveScoreWeight(widgetType)) {
    newFormDataItemMap.set('scoreWeight', 0);
  }

  formDataMap.set(id, newFormDataItemMap);

  sensitiveDataMap.set(id, sensitiveData ?? new Y.Map());
}

const getDuplicatedRequiredSubQuestionIds = (
  subQuestionIds: string[],
  newSubQuestionIds: string[],
  requiredSubQuestionsId: string[]
) => {
  const oldIdsToNewIdsMap: Record<string, string> = {};
  for (let i = 0; i < subQuestionIds.length; i++) {
    const oldId = subQuestionIds[i];
    const newId = newSubQuestionIds[i];
    oldIdsToNewIdsMap[oldId] = newId;
  }

  return requiredSubQuestionsId.map(id => oldIdsToNewIdsMap[id]);
};
