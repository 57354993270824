import * as Y from 'yjs';

import { AnswerStyleType } from '../../types/AnswerStyleType';
import { Feedbacks } from '../../types/Feedbacks';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { createQuestionWidgetChildren } from '../../utils/createQuestionWidgetChildren';
import { genRandId } from '../../utils/genRandId';
import { getEvaluationProps } from '../../utils/getEvaluationProps';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { initializeAnswers } from '../../utils/initializeAnswers';
import { DEFAULT_ANSWERS_AMOUNT, defaultAnswerConfig } from '../../utils/selectionQuestionConfigs';
import { WidgetType } from '../../utils/widgets';
import { manifestLocales } from '../SelectionQuestion/manifest.locales';
import { createNewSelectionQuestionAnswerData } from '../SelectionQuestionAnswer/create';
import { SelectionQuestionConfig } from '../questionConfigs';

export function createNewSelectionQuestionData(
  options: InitializePropsOptions,
  initializationConfig?: SelectionQuestionConfig
): ManifestInitializedProps {
  const { isPlenaryLesson = false, locale } = options;
  const { initialHeadlineText } = manifestLocales.multiChoice;

  const {
    amountOfColumns = 1,
    answerElements = ['Text'],
    answers: customAnswers,
    answersStyle = AnswerStyleType.Default,
    correctAnswers = [],
    feedback = Feedbacks.Check,
    hint = '',
    isSingleAnswerQuestion = false,
    label = initialHeadlineText[locale],
    saveAnswers = true,
    withRandomAnswers = false
  } = initializationConfig || {};

  const id = genRandId();

  const labelFragment = htmlToXmlFragment(`<h2>${label}</h2>`);
  const hintFragment = htmlToXmlFragment(hint);

  const [initializedAnswers, initializedAnswersIds] = initializeAnswers(
    defaultAnswerConfig,
    customAnswers?.length ?? DEFAULT_ANSWERS_AMOUNT,
    customAnswers
  );

  const correctAnswersIds = correctAnswers.map(answer => initializedAnswersIds[answer]);
  const widgetEvaluationProps = Object.entries(getEvaluationProps(isPlenaryLesson));

  const [questionAnswerData] = createNewSelectionQuestionAnswerData();
  const { childrenArray, horizontalFlexSectionData, verticalFlexSectionData } = createQuestionWidgetChildren(
    questionAnswerData.id,
    options?.width
  );

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.SelectionQuestion],
        ['children', childrenArray],
        ['labelFragment', labelFragment],
        ['hintFragment', hintFragment],
        ['answers', initializedAnswers],
        ['amountOfColumns', amountOfColumns],
        ['answerElements', answerElements],
        ['withRandomAnswers', withRandomAnswers],
        ['isSingleAnswerQuestion', isSingleAnswerQuestion],
        ['feedback', feedback],
        ['answersStyle', answersStyle],
        ['saveAnswers', saveAnswers],
        ...widgetEvaluationProps,
        ['questionAnswerId', questionAnswerData.id]
      ]),
      sensitiveData: new Y.Map([['correctAnswers', correctAnswersIds]])
    },
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  ];
}
