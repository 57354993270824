import { useDidUpdate } from '@mantine/hooks';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { EvaluatableWidgets, WidgetType } from 'shared/utils/widgets';

import {
  useCanGiveAnswers,
  useCanPrefillCorrectAnswers,
  useCanPrefillEvaluation,
  useCanSeeQuestionEvaluation
} from '@/components/CanProvider';
import { usePlayerElementState } from '@/components/PlayerStateProvider';
import {
  CorrectAnswersResultByWidget,
  EvaluationPayloadByWidget,
  EvaluationResultByWidget,
  useEvaluateQuestion,
  useFetchCorrectAnswers
} from '@/queries/evaluation';
import { QuestionState } from '@/queries/state';
import { Feedbacks } from 'shared/types/Feedbacks';
import { FeedbackMessages } from '@/utils/feedback.utils';
import { useViewMode } from '@/utils/useViewMode';
import {
  QuestionGroupListAction,
  QuestionGroupListActionContextType,
  QuestionGroupListEvaluation
} from '@/widgets/QuestionGroupList/context';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type AnswerType<T extends Exclude<EvaluatableWidgets, WidgetType.EvaluatablePlugin>> =
  EvaluationPayloadByWidget[T]['answers'];

export type UseEvaluatableQuestionAnswersProps<T extends Exclude<EvaluatableWidgets, WidgetType.EvaluatablePlugin>> = {
  feedback?: `${Feedbacks}`;
  onReset?: () => void;
  getCorrectAnswers: (result?: CorrectAnswersResultByWidget[T]) => AnswerType<T>;
  defaultState: AnswerType<T>;
  getCorrectStatuses: (correctAnswersOptions: AnswerType<T>) => EvaluationResultByWidget[T]['evaluatedAnswers'];
  questionGroupAction: QuestionGroupListActionContextType | null;
  questionGroupEvaluation: QuestionGroupListEvaluation | null;
  isRequired?: boolean;
};

export const useEvaluatableQuestionAnswers = <T extends Exclude<EvaluatableWidgets, WidgetType.EvaluatablePlugin>>(
  props: UseEvaluatableQuestionAnswersProps<T>
) => {
  const {
    feedback,
    getCorrectAnswers,
    onReset,
    defaultState,
    getCorrectStatuses,
    questionGroupAction,
    questionGroupEvaluation,
    isRequired
  } = props;
  const [questionId] = useContentObjectProperty<string>('id');
  const [viewMode] = useViewMode();
  const { action, setAction } = questionGroupAction || {};

  const { mutate: evaluate, data: evaluatedQuestion, reset: resetEvaluatedQuestions } = useEvaluateQuestion<T>();

  const {
    mutate: fetchCorrectAnswers,
    data: correctAnswersData,
    mutateAsync: asyncFetchCorrectAnswers
  } = useFetchCorrectAnswers<T>();

  const canPrefillCorrectAnswers = useCanPrefillCorrectAnswers();
  const [elementState, setElementState] = usePlayerElementState<Partial<QuestionState<AnswerType<T>>> | undefined>(
    questionId
  );

  const isCorrectAnswerShown = elementState?.showCorrectAnswers;

  const correctAnswersOptions = useMemo(
    () => getCorrectAnswers(correctAnswersData?.result),
    [correctAnswersData?.result, getCorrectAnswers]
  );

  const correctElementState = useMemo(
    () => ({
      answers: correctAnswersOptions,
      checked: false,
      showCorrectAnswers: true
    }),
    [correctAnswersOptions]
  );

  const correctStatuses = useMemo(
    () => getCorrectStatuses(correctAnswersOptions),
    [correctAnswersOptions, getCorrectStatuses]
  );

  const grade: EvaluationResultByWidget[T]['grade'] = evaluatedQuestion?.grade;
  const feedbackMessageType: EvaluationResultByWidget[T]['feedbackMessageType'] | null = isCorrectAnswerShown
    ? canPrefillCorrectAnswers
      ? null
      : FeedbackMessages.ShowAnswer
    : evaluatedQuestion?.feedbackMessageType ?? null;
  const evaluatedAnswers: EvaluationResultByWidget[T]['evaluatedAnswers'] = evaluatedQuestion?.evaluatedAnswers ?? {};

  const canGiveAnswers = useCanGiveAnswers();
  const canPrefillEvaluation = useCanPrefillEvaluation();
  const canSeeQuestionEvaluation = useCanSeeQuestionEvaluation();

  const disabled = isCorrectAnswerShown || !canGiveAnswers;

  const evaluateAnswers = useCallback(
    (newAnswers: AnswerType<T>) => {
      evaluate({ questionId, payload: { answers: newAnswers } as EvaluationPayloadByWidget[T] });
    },
    [evaluate, questionId]
  );

  const handleSelectAnswers = useCallback(
    (newAnswers: AnswerType<T>) => {
      if (feedback === Feedbacks.Automatic && canSeeQuestionEvaluation) {
        evaluateAnswers(newAnswers);
      } else {
        resetEvaluatedQuestions();
      }
      setElementState(currentState => ({
        ...currentState,
        answers: newAnswers,
        checked: false,
        showCorrectAnswers: false
      }));
      setAction?.(null);
    },
    [canSeeQuestionEvaluation, evaluateAnswers, feedback, resetEvaluatedQuestions, setAction, setElementState]
  );

  const shouldEvaluateAnswers = useMemo(
    () =>
      (feedback === Feedbacks.Automatic && canSeeQuestionEvaluation) ||
      (elementState?.checked && canSeeQuestionEvaluation),
    [canSeeQuestionEvaluation, elementState?.checked, feedback]
  );

  const isInitialized = useRef(false);
  useEffect(() => {
    // When the question is initialized, evaluate the answers if appropriate
    if (!elementState || isInitialized.current) {
      return;
    }
    if (canPrefillEvaluation || shouldEvaluateAnswers) {
      evaluateAnswers(elementState?.answers ?? defaultState);
      isInitialized.current = true;
    }
  }, [
    canPrefillEvaluation,
    canSeeQuestionEvaluation,
    defaultState,
    elementState,
    elementState?.answers,
    evaluateAnswers,
    feedback,
    handleSelectAnswers,
    shouldEvaluateAnswers
  ]);

  useEffect(() => {
    if (canPrefillCorrectAnswers) {
      fetchCorrectAnswers({ questionId });
    }
  }, [canPrefillCorrectAnswers, setElementState, resetEvaluatedQuestions, fetchCorrectAnswers, questionId]);

  useDidUpdate(() => {
    // When moving between view modes, update the evaluated state
    if (!canSeeQuestionEvaluation) {
      resetEvaluatedQuestions();
    } else if (shouldEvaluateAnswers) {
      evaluateAnswers(elementState?.answers ?? defaultState);
    }
  }, [viewMode]);

  const handleResetAnswers = useCallback(() => {
    onReset?.();
    resetEvaluatedQuestions();
    setElementState(currentState => ({
      ...currentState,
      answers: defaultState,
      checked: false,
      showCorrectAnswers: false
    }));
    setAction?.(null);
  }, [defaultState, onReset, resetEvaluatedQuestions, setAction, setElementState]);

  const handleEvaluate = useCallback(
    (answers: AnswerType<T>, evaluateAndCheck = true) => {
      evaluateAnswers(answers);
      setElementState(currentState => ({
        ...currentState,
        checked: evaluateAndCheck
      }));
    },
    [evaluateAnswers, setElementState]
  );

  const handleShowAnswer = useCallback(async () => {
    const { result } = await asyncFetchCorrectAnswers({ questionId });
    const correctAnswers = getCorrectAnswers(result);
    evaluateAnswers(correctAnswers);
    setElementState(currentState => ({
      ...currentState,
      answers: correctAnswers,
      checked: false,
      showCorrectAnswers: true
    }));
  }, [getCorrectAnswers, asyncFetchCorrectAnswers, evaluateAnswers, questionId, setElementState]);

  const isShowAnswer = canPrefillCorrectAnswers
    ? correctElementState.showCorrectAnswers
    : isCorrectAnswerShown ?? false;
  const selectedAnswers = canPrefillCorrectAnswers
    ? correctElementState.answers
    : elementState?.answers ?? defaultState;
  const selectedAnswersStatuses = canPrefillCorrectAnswers ? correctStatuses : evaluatedAnswers ?? {};

  useEffect(() => {
    if (action === QuestionGroupListAction.Reset) {
      handleResetAnswers();
    }
    if (action === QuestionGroupListAction.ShowAnswers) {
      void handleShowAnswer();
    }
  }, [action, handleResetAnswers, handleShowAnswer]);

  useEffect(() => {
    if (action === QuestionGroupListAction.Check) {
      const subQuestionEvaluation = questionGroupEvaluation?.[questionId];

      if (!subQuestionEvaluation) {
        return;
      }

      if (isRequired || (!isRequired && subQuestionEvaluation?.feedbackMessageType !== FeedbackMessages.NoAnswer)) {
        handleEvaluate(selectedAnswers);
      }
    }
  }, [action, handleEvaluate, isRequired, selectedAnswers, questionGroupEvaluation, questionId]);

  return {
    disabled,
    feedbackMessageType,
    grade,
    handleEvaluate,
    handleResetAnswers,
    handleSelectAnswers,
    handleShowAnswer,
    isShowAnswer,
    selectedAnswers,
    selectedAnswersStatuses
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EvaluatableQuestionAnswersContext = createContext<any>(null);

export const useEvaluatableQuestionAnswersContext = <
  T extends Exclude<EvaluatableWidgets, WidgetType.EvaluatablePlugin>
>() => {
  const context = useContext<null | ReturnType<typeof useEvaluatableQuestionAnswers<T>>>(
    EvaluatableQuestionAnswersContext
  );
  if (context === null) throw new Error('PlayerStoreContext not initialized properly.');
  return context;
};
