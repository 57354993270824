export const manifestLocales = {
  blockTitle: {
    previewImageAlt: {
      en: 'Block Title',
      he: 'כותרת מעוצבת'
    },
    initialText: {
      en: 'Block Title',
      he: 'כותרת מעוצבת'
    }
  }
};
