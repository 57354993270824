import { AuthenticatedServerUser } from '@hendrx/modules/authentication';

export const GOOGLE_AUTH_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID ?? '';
export const GOOGLE_AUTH_REDIRECT_URI = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI ?? '';
export const JWT_TOKEN_COOKIE = 'jwtToken';

export type JwtData = {
  exp?: number;
  iat?: number;
  nbf?: number;
  user?: AuthenticatedServerUser;
};

export const GOOGLE_SCOPE = [
  // Needed to fetch email address of the logged in student
  'https://www.googleapis.com/auth/classroom.profile.emails',
  // Needed to differentiate teachers from students
  'https://www.googleapis.com/auth/classroom.rosters.readonly',
  // Needed to list classes (courses)
  'https://www.googleapis.com/auth/classroom.courses.readonly',
  // Needed to list coursework (assignments) and edit submissions
  'https://www.googleapis.com/auth/classroom.coursework.me'
].join(' ');
