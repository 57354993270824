import { Text } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

import { AnswerElementType } from 'shared/types/AnswerElement';

import { SwitchGroup } from '@/components/SwitchGroup';
import { AudioElement } from '@/icons/AudioElement';
import { ImageElement } from '@/icons/ImageElement';
import { TextElement } from '@/icons/TextElement';

const elementIcons: Record<AnswerElementType, JSX.Element> = {
  Text: <TextElement />,
  Image: <ImageElement />,
  // Video: <VideoElement />,
  Audio: <AudioElement />
};

type EnabledState = Record<AnswerElementType, boolean>;

const getEnabledState = (
  values: AnswerElementType[],
  options: AnswerElementType[],
  allowedElementTypesCombinations: AnswerElementType[][]
) => {
  const enabledCombination = allowedElementTypesCombinations.filter(combination =>
    values.every(value => combination.includes(value))
  );

  const isElementTypeEnabled = (elementType: AnswerElementType) =>
    enabledCombination?.some(combination => combination.includes(elementType)) ?? false;

  return options.reduce<EnabledState>(
    (enabled, elementType) => ({
      ...enabled,
      [elementType]: isElementTypeEnabled(elementType)
    }),
    {} as EnabledState
  );
};

type AnswersElementPickerProps<T extends AnswerElementType> = {
  allowedElementTypesCombinations: AnswerElementType[][];
  dataTestId: string;
  onChange: (value: T[]) => void;
  options: T[];
  value: T[];
};

export function AnswersElementPicker<T extends AnswerElementType>(props: AnswersElementPickerProps<T>) {
  const { allowedElementTypesCombinations, onChange, options, value, dataTestId } = props;
  const t = useTranslations('components.answersElementPicker');

  const enabledState = getEnabledState(value, options, allowedElementTypesCombinations);

  const isSingleEnabledOption = useCallback(
    (elementType: AnswerElementType) => enabledState[elementType] && value.length === 1 && value[0] === elementType,
    [value, enabledState]
  );

  return (
    <SwitchGroup
      dataTestId={dataTestId}
      onChange={onChange as (value: string[]) => void}
      value={value}
      options={options.map(elementType => ({
        value: elementType,
        label: (
          <>
            {elementIcons[elementType]}
            <Text>{t(`elementTypeNames.${elementType}`)}</Text>
          </>
        ),
        disabled: !enabledState[elementType] || isSingleEnabledOption(elementType)
      }))}
      title={t('title')}
    />
  );
}
