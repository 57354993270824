import { Box, NumberInput, NumberInputProps } from '@mantine/core';
import { ReactNode } from 'react';

import * as classes from '@/components/Theme/Theme.css';
import { ThemeControl } from '@/components/Theme/ThemeControl';

type NumberInputPanelProps = {
  label?: string;
  path?: string;
  value: number;
  onChange: (newValue: number) => void;
  ResetButton?: ReactNode;
};

export const NumberInputPanel = (props: NumberInputPanelProps) => {
  const { label, path, onChange, value, ResetButton } = props;

  return (
    <ThemeControl label={label} path={path}>
      <Box className={classes.numberInput}>
        <NumberInput value={value} onChange={onChange as NumberInputProps['onChange']} min={0} />
      </Box>
      {ResetButton}
    </ThemeControl>
  );
};
