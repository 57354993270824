import { Tabs, TextInput } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { CustomCSSClassesInput } from '@/widgets/_components/CustomCSSClassesInput';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

export function Config() {
  const t = useTranslations('widgets');
  const [title, setTitle] = useContentObjectProperty<string>('title');
  const [errors] = useContentObjectErrors();

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        <FormControl title={t('pdfDocumentSettings.addTitleLabel')} withAsterisk>
          <TextInput
            value={title}
            onChange={event => setTitle(event.currentTarget.value)}
            error={errors.title && t('pdfDocumentSettings.addTitleError')}
            placeholder={t('pdfDocumentSettings.addTitlePlaceholder') ?? ''}
            data-testid="pdfDocumentSettings-title-field"
          />
        </FormControl>
        <CustomCSSClassesInput />
      </Tabs.Panel>
    </WidgetConfig>
  );
}
