import { Box } from '@mantine/core';

import { useStyles } from './DndItemIndicator.styles';

type DndItemIndicatorProps = { isHorizontal?: boolean; height: number };

export function DndItemIndicator(props: DndItemIndicatorProps) {
  const { isHorizontal, height } = props;

  const { classes } = useStyles({ isHorizontal, height });

  return (
    <Box className={classes.dndItemIndicator} data-testid="indicator">
      <Box className={classes.dndItemIndicatorBar} />
    </Box>
  );
}
