import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

import { useStyles } from './Base.styles';
import { StyledActionIcon } from '@/components/StyledActionIcon';
import { AudioPlaceholder } from '@/icons/AudioPlaceholder';
import { AudioPlay } from '@/icons/AudioPlay';
import { useFileUpload } from '@/queries/fileUpload';
import { Manifest } from '@/widgets/Audio/Manifest';
import { UploadDropzone } from '@/widgets/_components/widget/UploadDropzone';

type AudioClassNames = {
  playButton?: string;
  dropzone?: string;
};
type BaseProps = {
  src: string;
  setSrc: (src: string) => void;
  classNames?: AudioClassNames;
};
export const Base = forwardRef<() => void | null, BaseProps>((props, ref) => {
  const { src, setSrc, classNames } = props;

  const t = useTranslations('widgets');

  const { mutateAsync: uploadFile, isLoading } = useFileUpload();

  const isAudioReady = Boolean(src && !isLoading);

  const { classes, cx } = useStyles();
  return (
    <>
      {isAudioReady && (
        <Box className={classes.audioContainer}>
          <StyledActionIcon
            aria-label={t('audioWidget.play')}
            label={`${t('audioWidget.play')}`}
            icon={<AudioPlay />}
            className={cx(classes.audio, classNames?.playButton)}
          />
        </Box>
      )}

      <UploadDropzone
        Icon={AudioPlaceholder}
        src={src}
        isReady={isAudioReady}
        maxSize={Manifest.additionalData?.maxFileSizeBytes}
        maxFiles={Manifest.additionalData?.maxFiles}
        accept={Manifest.additionalData?.acceptFileType}
        placeholderText={t('audioWidget.idleText')}
        upload={uploadFile}
        dropAction={({ src }) => {
          setSrc(src);
        }}
        dropError={t('audioWidget.unableUpload')}
        ref={ref}
        className={classNames?.dropzone}
      />
    </>
  );
});

Base.displayName = 'Base';
