import { Grade } from '@/components/Grade';
import { useSavedGrade } from '@/queries/state';

type ManualGradeProps = { questionId: string; scoreWeight: number };

export const ManualGrade = (props: ManualGradeProps) => {
  const { questionId, scoreWeight } = props;

  const { savedGrade, isLoading, saveGrade } = useSavedGrade(questionId);

  if (isLoading) {
    return null;
  }

  return <Grade defaultScore={savedGrade} maxScore={scoreWeight} onScoreChange={saveGrade} />;
};
