import { Resource, useCan } from './utils';

export const useCanGiveAnswers = () => {
  const canUpdateLocalState = useCanUpdateLocalState();
  const canUpdateSavedState = useCanUpdateSavedState();

  return canUpdateLocalState || canUpdateSavedState;
};

export const useCanCreateAssignment = () => useCan('create', Resource.Assignment);
export const useCanSubmitAssignment = () => useCan('submit', Resource.Assignment);
export const useCanReadAssignmentNotes = () => useCan('read', Resource.AssignmentNotes);
export const useCanSubmitFeedbackForAssignment = () => useCan('submitFeedbackFor', Resource.Assignment);
export const useCanAccessAssignmentSubmitPage = () => useCan('access', Resource.AssignmentSubmitPage);
export const useCanAccessAssignmentSummaryPage = () => useCan('access', Resource.AssignmentSummaryPage);
export const useCanReadDraftNoteToTeacher = () => useCan('read', Resource.AssignmentDraftNoteToTeacher);
export const useCanUpsertDraftNoteToTeacher = () => useCan('upsert', Resource.AssignmentDraftNoteToTeacher);
export const useCanReadSatisfactionScore = () => useCan('read', Resource.AssignmentSatisfactionScore);
export const useCanReadSubmissionHistory = () => useCan('read', Resource.AssignmentSubmissionHistory);
export const useCanUpsertSatisfactionScore = () => useCan('upsert', Resource.AssignmentSatisfactionScore);
export const useCanReadDraftFeedbackForAssignment = () => useCan('read', Resource.AssignmentDraftFeedbackForAssignment);
export const useCanUpsertDraftFeedbackForAssignment = () =>
  useCan('upsert', Resource.AssignmentDraftFeedbackForAssignment);
export const useCanManageViewMode = () => useCan('manage', Resource.ViewMode);
export const useCanReadStudentAssignedMode = () => useCan('read', Resource.StudentAssignedMode);
export const useCanReadStudentLearningMode = () => useCan('read', Resource.StudentLearningMode);
export const useCanReadTeacherSolvedMode = () => useCan('read', Resource.TeacherSolvedMode);
export const useCanReadStudentTestMode = () => useCan('read', Resource.StudentTestMode);
export const useCanPrefillCorrectAnswers = () => useCan('prefill', Resource.CorrectAnswers);
export const useCanReadQuestionStatus = () => useCan('read', Resource.QuestionStatus);
export const useCanPrefillEvaluation = () => useCan('prefill', Resource.QuestionEvaluation);
export const useCanSeeFeedback = () => useCan('see', Resource.Feedback);
export const useCanSeeQuestionCheckButton = () => useCan('see', Resource.QuestionCheckButton);
export const useCanSeeQuestionEvaluation = () => useCan('see', Resource.QuestionEvaluation);
export const useCanSeeQuestionHintButton = () => useCan('see', Resource.QuestionHintButton);
export const useCanSeeQuestionResetButton = () => useCan('see', Resource.QuestionResetButton);
export const useCanSeeQuestionScoreWeight = () => useCan('see', Resource.QuestionScoreWeight);
export const useCanSeeReturnToFixSwitch = () => useCan('see', Resource.ReturnToFixSwitch);
export const useCanSeeSatisfactionSurvey = () => useCan('see', Resource.SatisfactionSurvey);
export const useCanSeeUnansweredQuestions = () => useCan('see', Resource.UnansweredQuestions);
export const useCanReadQuestionGrade = () => useCan('read', Resource.QuestionGrade);
export const useCanUpsertQuestionGrade = () => useCan('upsert', Resource.QuestionGrade);
export const useCanSeeQuestionShowAnswerButton = () => useCan('see', Resource.QuestionShowAnswerButton);
export const useCanReadSavedState = () => useCan('read', Resource.SavedState);
export const useCanUpdateLocalState = () => useCan('update', Resource.LocalState);
export const useCanUpdateSavedState = () => useCan('update', Resource.SavedState);
export const useCanSeePresenterActionBar = () => useCan('see', Resource.PresenterActionBar);
export const useCanSeeSlidesNavigation = () => useCan('see', Resource.SlidesNavigation);
export const useCanSeeCanvas = () => useCan('see', Resource.Canvas);
export const useCanSaveCanvas = () => useCan('save', Resource.Canvas);
export const useCanRevealPlenaryLessonAnswers = () => useCan('reveal', Resource.PlenaryLessonAnswers);
export const useCanSeePlenaryLessonCode = () => useCan('see', Resource.PlenaryLessonCode);
export const useCanEnlargeOpenDiscussionAnswer = () => useCan('see', Resource.EnlargeOpenDiscussionAnswer);
export const useCanSyncSlideNumber = () => useCan('sync', Resource.SlideNumber);
