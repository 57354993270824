import * as Y from 'yjs';

import { initializeYjsDocumentStructure } from './initializeYjsDocumentStructure';
import {
  createHistoryFeedbackForSubmitPage,
  createSubmitPage,
  getExistingSubmitPage,
  historyFeedbackInSubmitPageExists
} from './submitPage';
import { createSummaryPage } from './summaryPage';
import { WidgetType } from './widgets';
import { LearningObjectTypes } from '../types/LearningObjectType';
import { SupportedLocale } from '../types/SupportedLocale';
import { createNewLearningObjectCommonPageData } from '../widgetsSDK/LearningObjectCommonPage/create';
import { createContentObject } from '../widgetsSDK/contentObjectOperations';

export function initializeYjsDocumentStageData(
  document: Y.Doc,
  learningObjectType: LearningObjectTypes,
  locale: SupportedLocale
) {
  document.transact(() => {
    initializeYjsDocumentStructure(document);

    const rootMap = document.getMap('root');
    const rootContentObjects = rootMap.get('contentObjects') as Y.Map<unknown>;
    const rootChildren = rootMap.get('children');

    if (learningObjectType === LearningObjectTypes.LearningActivity) {
      const summaryPageExists = Array.from(rootContentObjects.values()).some(contentObject => {
        return contentObject.get('type') === WidgetType.SummaryPage;
      });

      const submitPageExists = Array.from(rootContentObjects.values()).some(contentObject => {
        return contentObject.get('type') === WidgetType.SubmitPage;
      });

      if (!summaryPageExists) {
        createSummaryPage(document);
      }

      if (!submitPageExists) {
        createSubmitPage(document, locale);
      }

      if (!historyFeedbackInSubmitPageExists(document)) {
        const existingSubmitPage = getExistingSubmitPage(document);
        createHistoryFeedbackForSubmitPage(document, existingSubmitPage);
      }
    }

    if (!rootChildren || !rootContentObjects) {
      const [defaultPage, defaultPageId] = createNewLearningObjectCommonPageData(learningObjectType);
      createContentObject(document, defaultPageId, defaultPage);
      const defaultChildren = new Y.Array();
      defaultChildren.push([defaultPageId]);

      rootMap.set('children', defaultChildren);
    }
  }, 'untracked');
}
