import { Box, Flex, Text } from '@mantine/core';
import { Editor } from '@tiptap/react';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Submission } from './SubmissionHistory';
import { useStyles } from './SubmissionHistory.styles';
import { getOptions as getIconOptions } from '@/components/SatisfactionSurvey';
import { UserAvatar } from '@/components/UserAvatar';
import { RichText } from '@/widgets/_components/RichText';

type SubmissionEntryProps = {
  submission: Submission;
  withScore?: boolean;
};

export const SubmissionEntry = (props: SubmissionEntryProps) => {
  const { submission, withScore = false } = props;
  const { date, status, comment, submissionEntryOwner: entryOwner, satisfactionScore } = submission;

  const Icon = satisfactionScore && getIconOptions().filter(option => option.name === satisfactionScore)[0]?.Icon;

  const { classes } = useStyles({});
  const t = useTranslations('components.submissionHistory');

  const [commentEditor, setCommentEditor] = useState<Editor | null>(null);

  useEffect(() => {
    commentEditor?.commands.setContent(comment ?? '');
  }, [commentEditor, comment]);

  // TODO: Get the real score
  const evaluatedScorePlaceholder = 100;
  const stateText = t(status);
  const scoreText = withScore ? `${t('score')} ${evaluatedScorePlaceholder}` : '';
  const userName = `${entryOwner.firstName} ${entryOwner.lastName}`;

  return (
    <Flex className={classes.entryWrapper} direction="row" align="flex-start">
      <UserAvatar user={entryOwner} />
      <Flex className={classes.contentWrapper} direction="column" align="flex-start">
        <Text className={classes.username}>{userName}</Text>
        <Text className={classes.state}>{`${dayjs(date).format('MM/DD, HH:mm')} - ${stateText} ${scoreText}`}</Text>
        {comment && (
          <Box className={classes.comment}>
            <RichText dataTestIdPrefix="comment" contentEditable={false} setEditor={setCommentEditor} />
          </Box>
        )}
        {Icon && <Icon />}
      </Flex>
    </Flex>
  );
};
