import { ReactNodeViewRenderer } from '@tiptap/react';

import { BaseFillInTheBlanksExtension } from 'shared/tiptap/BaseFillInTheBlanksExtension';

import { FillInTheBlanksOption } from './FillInTheBlanksOption';

export const FillInTheBlanksExtension = BaseFillInTheBlanksExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(FillInTheBlanksOption);
  }
});
