import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type TaskSubmittedNotificationStylesProps = { withAnimation?: boolean };

export const useStyles = createStyles((theme, props: TaskSubmittedNotificationStylesProps) => {
  const { withAnimation } = props;

  return {
    container: {
      position: 'relative',
      height: rem(260)
    },
    content: {
      whiteSpace: 'nowrap'
    },
    confetti: {
      position: 'absolute',
      top: rem(-200),
      visibility: withAnimation ? 'visible' : 'hidden'
    },
    left: {
      left: rem(-120)
    },
    right: {
      right: rem(-120)
    }
  };
});
