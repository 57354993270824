import { Editor } from '@tiptap/react';
import { Node } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { useCallback, useMemo, useState } from 'react';
import * as Y from 'yjs';

import { ToolbarType } from 'shared/types/RichTextToolbar';
import { extractYjsMaps } from 'shared/widgetsSDK/yjs';

import { AddClozeInput, AddClozeSelect } from './AddCloze';
import { NodeHistoryData, handlePasteLogic, useCustomNodeHistoryIntegration } from '@/utils/tiptap';
import { ClozeExtension } from '@/widgets/ClozeQuestion/studio/ClozeOption/ClozeExtension';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

type ClozeTextProps = {
  fragment: Y.XmlFragment | undefined;
  placeholder: string;
};

export function ClozeText(props: ClozeTextProps) {
  const { fragment: answerFragment, placeholder: answerPlaceholder } = props;

  const [editor, setEditor] = useState<Editor | null>(null);

  const [contentObjectOptions, setContentObjectOptions] =
    useContentObjectProperty<Y.Map<Y.Map<unknown>>>('clozeOptions');
  const [withRandomAnswers, setWithRandomAnswers] = useContentObjectProperty<Y.Map<unknown>>(`withRandomAnswers`);

  const { document, id } = useContentObject();

  const onRemove = (nodeIds: string[]): NodeHistoryData<Y.Map<unknown>>[] => {
    const deletedNodeMaps = nodeIds.map(nodeId => {
      return {
        key: nodeId,
        value: contentObjectOptions.get(nodeId)?.clone() as Y.Map<unknown>
      };
    });

    const clonedOptions = contentObjectOptions.clone();
    nodeIds.forEach(nodeId => {
      const newWithRandomAnswers = withRandomAnswers.clone();
      newWithRandomAnswers.delete(nodeId);
      setWithRandomAnswers(newWithRandomAnswers);
      clonedOptions.delete(nodeId);
    });
    document.transact(() => {
      setContentObjectOptions(clonedOptions);
    }, 'untracked');

    return deletedNodeMaps;
  };

  const onAdd = (historyData: NodeHistoryData<Y.Map<unknown>>[]) => {
    const clonedOptions = contentObjectOptions.clone();
    historyData.forEach(({ key, value }) => {
      if (!value) return;
      clonedOptions.set(key, value);
    });
    document.transact(() => {
      setContentObjectOptions(clonedOptions);
    }, 'untracked');
  };

  useCustomNodeHistoryIntegration<Y.Map<unknown>>({
    editor,
    nodeName: 'clozeNode',
    attributeKey: 'clozeId',
    onAdd,
    onRemove
  });

  const processClozeNode = useCallback(
    (node: Node) => {
      if (node.type.name !== 'clozeNode') return null;

      type Option = { id: string; value: string };
      type InputCorrectOptions = Record<string, Option[]>;
      type SelectPossibleOptions = Record<string, Option[]>;
      type SelectCorrectOptions = Record<string, Option>;

      const { sensitiveDataMap, contentObjectsMap } = extractYjsMaps(document, id);

      const inputCorrectOptions = sensitiveDataMap.get('inputCorrectOptions')?.toJSON() as InputCorrectOptions;
      const selectPossibleOptions = contentObjectsMap.get('selectPossibleOptions')?.toJSON() as SelectPossibleOptions;
      const selectCorrectOptions = sensitiveDataMap.get('selectCorrectOptions')?.toJSON() as SelectCorrectOptions;

      const correctOption = selectCorrectOptions?.[node.attrs.clozeId];
      const correctOptionIndex = selectPossibleOptions?.[node.attrs.clozeId]?.findIndex(
        option => option.id === correctOption.id
      );

      const textToShowSelect = selectPossibleOptions?.[node.attrs.clozeId]?.[correctOptionIndex]?.value;
      const textToShowInput = inputCorrectOptions?.[node.attrs.clozeId]?.[0]?.value;

      if (node.attrs.type === ClozeOptionType.Select) {
        return textToShowSelect;
      } else {
        return textToShowInput;
      }
    },
    [document, id]
  );

  const extraExtensions = useMemo(
    () => [
      ClozeExtension.extend({
        addProseMirrorPlugins() {
          return [
            new Plugin({
              key: new PluginKey('clozePastePlugin'),
              props: {
                handlePaste(view, _, slice) {
                  return handlePasteLogic(view, slice, node => processClozeNode(node));
                }
              }
            })
          ];
        }
      })
    ],
    [processClozeNode]
  );

  const customControls = useMemo(
    () => (
      <>
        <AddClozeSelect />
        <AddClozeInput />
      </>
    ),
    []
  );

  return (
    <RichText
      dataTestIdPrefix="studio-cloze-question-answer"
      fragment={answerFragment}
      placeholder={answerPlaceholder}
      extraExtensions={extraExtensions}
      customControls={customControls}
      setEditor={setEditor}
      expandHeight
      disableTypographyInheritance
      toolbarType={ToolbarType.Fixed}
    />
  );
}
