import { AppContext } from '@hendrx/modules/authorization';

const isAdmin = (user: AppContext['user']) => {
  return user?.role === 'admin';
};
const isTeacher = (user: AppContext['user']) => {
  return user?.role === 'teacher';
};
const isStudent = (user: AppContext['user']) => {
  return user?.role === 'student';
};

export const canCreateAssignment = (appContext: AppContext) => {
  const { user } = appContext;

  const teacherAuthorized = isTeacher(user);

  return teacherAuthorized;
};

export const canSubmitAssignment = (appContext: AppContext) => {
  const { user, isAssignmentUnsubmitted, isAssignmentReturnedToFix } = appContext;

  const studentAuthorized = isStudent(user) && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return studentAuthorized;
};

export const canSubmitFeedbackForAssignment = (appContext: AppContext) => {
  const { user, isPlayerAssignment } = appContext;

  const adminAuthorized = isAdmin(user) && isPlayerAssignment;
  const teacherAuthorized = isTeacher(user) && isPlayerAssignment;

  return adminAuthorized || teacherAuthorized;
};

export const canReadDraftNoteToTeacher = (appContext: AppContext) => {
  const { user, isPlayerAssignment } = appContext;

  const studentAuthorized = isStudent(user) && isPlayerAssignment;

  return studentAuthorized;
};

export const canUpsertDraftNoteToTeacher = (appContext: AppContext) => {
  const { user, isAssignmentUnsubmitted, isAssignmentReturnedToFix, isPlayerAssignment } = appContext;

  const studentAuthorized =
    isStudent(user) && isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return studentAuthorized;
};

export const canReadSatisfactionScore = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isAssignmentSubmitted, isAssignmentReturnedToFix, isAssignmentEvaluated } =
    appContext;

  const teacherAuthorized =
    isTeacher(user) &&
    isPlayerAssignment &&
    (isAssignmentSubmitted || isAssignmentReturnedToFix || isAssignmentEvaluated);
  const studentAuthorized = isStudent(user) && isPlayerAssignment;

  return teacherAuthorized || studentAuthorized;
};

export const canReadSubmissionHistory = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isAssignmentSubmitted, isAssignmentReturnedToFix, isAssignmentEvaluated } =
    appContext;

  const teacherAuthorized =
    isTeacher(user) &&
    isPlayerAssignment &&
    (isAssignmentSubmitted || isAssignmentReturnedToFix || isAssignmentEvaluated);
  const studentAuthorized =
    isStudent(user) &&
    isPlayerAssignment &&
    (isAssignmentSubmitted || isAssignmentReturnedToFix || isAssignmentEvaluated);

  return teacherAuthorized || studentAuthorized;
};

export const canUpsertSatisfactionScore = (appContext: AppContext) => {
  const { user, isAssignmentUnsubmitted, isAssignmentReturnedToFix, isPlayerAssignment } = appContext;

  const studentAuthorized =
    isStudent(user) && isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return studentAuthorized;
};

export const canReadDraftFeedbackForAssignment = (appContext: AppContext) => {
  const { user } = appContext;

  const adminAuthorized = isAdmin(user);
  const teacherAuthorized = isTeacher(user);

  return adminAuthorized || teacherAuthorized;
};

export const canUpsertDraftFeedbackForAssignment = (appContext: AppContext) => {
  const { user, isAssignmentUnsubmitted } = appContext;

  const adminAuthorized = isAdmin(user) && !isAssignmentUnsubmitted;
  const teacherAuthorized = isTeacher(user) && !isAssignmentUnsubmitted;

  return adminAuthorized || teacherAuthorized;
};

export const canReadAssignmentNotes = (appContext: AppContext) => {
  const { user, isAssignmentSubmitted, isAssignmentReturnedToFix, isPlayerAssignment, isAssignmentEvaluated } =
    appContext;

  const sharedCondition =
    isPlayerAssignment && (isAssignmentSubmitted || isAssignmentReturnedToFix || isAssignmentEvaluated);

  const adminAuthorized = isAdmin(user) && sharedCondition;
  const teacherAuthorized = isTeacher(user) && sharedCondition;
  const studentAuthorized = isStudent(user) && sharedCondition;

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canManageViewMode = (appContext: AppContext) => {
  const { isStudioPreview, isPlayerActivity, user, isPlayerAssignment, isLearningActivity } = appContext;

  const adminAuthorized =
    isAdmin(user) && (isStudioPreview || isPlayerActivity || isPlayerAssignment) && isLearningActivity;
  const teacherAuthorized =
    isTeacher(user) && (isStudioPreview || isPlayerActivity || isPlayerAssignment) && isLearningActivity;

  return adminAuthorized || teacherAuthorized;
};

export const canReadStudentLearningViewMode = (appContext: AppContext) => {
  const { isStudioPreview, isPlayerActivity, user, hasStudentId, isPlayerAssignment, isTest } = appContext;

  const adminAuthorized =
    isAdmin(user) && (isStudioPreview || isPlayerActivity || (isPlayerAssignment && !isTest && !hasStudentId));
  const teacherAuthorized =
    isTeacher(user) && (isStudioPreview || isPlayerActivity || (isPlayerAssignment && !isTest && !hasStudentId));

  return adminAuthorized || teacherAuthorized;
};

export const canReadStudentTestViewMode = (appContext: AppContext) => {
  const { isPlenaryLesson, isStudioPreview, isPlayerActivity, user, isPlayerAssignment, isTest } = appContext;

  const adminAuthorized =
    isAdmin(user) && !isPlenaryLesson && (isStudioPreview || isPlayerActivity || (isPlayerAssignment && isTest));
  const teacherAuthorized =
    isTeacher(user) && !isPlenaryLesson && (isStudioPreview || isPlayerActivity || (isPlayerAssignment && isTest));

  return adminAuthorized || teacherAuthorized;
};

export const canReadTeacherSolvedMode = (appContext: AppContext) => {
  const { isPlenaryLesson, user } = appContext;

  const adminAuthorized = isAdmin(user) && !isPlenaryLesson;
  const teacherAuthorized = isTeacher(user) && !isPlenaryLesson;

  return adminAuthorized || teacherAuthorized;
};

export const canUpdateLocalState = (appContext: AppContext) => {
  const { user, viewMode } = appContext;

  const adminAuthorized = isAdmin(user) && (viewMode === 'studentLearning' || viewMode === 'studentTest');

  return adminAuthorized;
};

export const canReadSavedState = (appContext: AppContext) => {
  const { isPlayerActivity, isPlayerAssignment, user } = appContext;

  const adminAuthorized = isAdmin(user) && (isPlayerActivity || isPlayerAssignment);
  const teacherAuthorized = isTeacher(user) && (isPlayerActivity || isPlayerAssignment);
  const studentAuthorized = isStudent(user) && (isPlayerActivity || isPlayerAssignment);

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canReadStudentAssignedMode = (appContext: AppContext) => {
  const { hasStudentId, isPlayerAssignment, user } = appContext;
  const sharedCondition = isPlayerAssignment && hasStudentId;

  const adminAuthorized = isAdmin(user) && sharedCondition;
  const teacherAuthorized = isTeacher(user) && sharedCondition;

  return adminAuthorized || teacherAuthorized;
};

export const canUpdateSavedState = (appContext: AppContext) => {
  const {
    isPlayerActivity,
    user,
    viewMode,
    isAssignmentUnsubmitted,
    isAssignmentReturnedToFix,
    hasStudentId,
    isPlayerAssignment
  } = appContext;

  const isStudentViewMode =
    viewMode === 'studentAssigned' || viewMode === 'studentLearning' || viewMode === 'studentTest';

  const adminAuthorized =
    isAdmin(user) && (isPlayerActivity || (isPlayerAssignment && !hasStudentId)) && isStudentViewMode;
  const teacherAuthorized =
    isTeacher(user) && (isPlayerActivity || (isPlayerAssignment && !hasStudentId)) && isStudentViewMode;
  const studentAuthorized = isStudent(user) && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canReadQuestionStatus = (appContext: AppContext) => {
  const { user, isPlayerActivity, isPlayerAssignment, hasStudentId } = appContext;

  const adminAuthorized = isAdmin(user) && (isPlayerActivity || (isPlayerAssignment && !hasStudentId));
  const teacherAuthorized = isTeacher(user) && (isPlayerActivity || (isPlayerAssignment && !hasStudentId));
  const studentAuthorized = isStudent(user);

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canPrefillCorrectAnswers = (appContext: AppContext) => {
  const { user, viewMode } = appContext;

  const adminAuthorized = isAdmin(user) && viewMode === 'teacher';
  const teacherAuthorized = isTeacher(user) && viewMode === 'teacher';

  return adminAuthorized || teacherAuthorized;
};

export const canPrefillEvaluation = (appContext: AppContext) => {
  const { isPlayerAssignment, isTest, isAssignmentSubmitted, isAssignmentEvaluated, user, hasStudentId } = appContext;

  const adminAuthorized =
    isAdmin(user) && isPlayerAssignment && hasStudentId && (!isTest || isAssignmentSubmitted || isAssignmentEvaluated);
  const teacherAuthorized =
    isTeacher(user) &&
    isPlayerAssignment &&
    hasStudentId &&
    (!isTest || isAssignmentSubmitted || isAssignmentEvaluated);
  const studentAuthorized = isStudent(user) && isPlayerAssignment && (isAssignmentSubmitted || isAssignmentEvaluated);

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeFeedback = (appContext: AppContext) => {
  const {
    user,
    isPlayerActivity,
    isPlayerAssignment,
    isTest,
    isStudioPreview,
    viewMode,
    isAssignmentSubmitted,
    isAssignmentEvaluated
  } = appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioPreview ||
      isPlayerActivity ||
      (isPlayerAssignment && (!isTest || isAssignmentSubmitted || isAssignmentEvaluated))) &&
    (viewMode === 'studentLearning' || viewMode === 'studentAssigned');
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioPreview ||
      isPlayerActivity ||
      (isPlayerAssignment && (!isTest || isAssignmentSubmitted || isAssignmentEvaluated))) &&
    (viewMode === 'studentLearning' || viewMode === 'studentAssigned');
  const studentAuthorized = isStudent(user) && (isPlayerActivity || (isPlayerAssignment && !isTest));

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionEvaluation = (appContext: AppContext) => {
  const {
    user,
    viewMode,
    isAssignmentSubmitted,
    isAssignmentEvaluated,
    isAssignmentReturnedToFix,
    isTest,
    isPlayerAssignment,
    isStudioPreview,
    isPlayerActivity
  } = appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (((isStudioPreview || isPlayerActivity) && (viewMode === 'studentLearning' || viewMode === 'teacher')) ||
      (isPlayerAssignment && !isTest));
  const teacherAuthorized =
    isTeacher(user) &&
    (((isStudioPreview || isPlayerActivity) && (viewMode === 'studentLearning' || viewMode === 'teacher')) ||
      (isPlayerAssignment && !isTest));
  const studentAuthorized =
    isStudent(user) &&
    (isPlayerActivity ||
      (isPlayerAssignment &&
        !isTest &&
        ((!isAssignmentSubmitted && !isAssignmentEvaluated) || isAssignmentReturnedToFix)));

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionResetButton = (appContext: AppContext) => {
  const {
    user,
    isAssignmentUnsubmitted,
    isAssignmentReturnedToFix,
    isPlayerActivity,
    isStudioEdit,
    isStudioPreview,
    viewMode,
    isPlayerAssignment,
    hasStudentId
  } = appContext;

  const isStudentViewMode =
    viewMode === 'studentAssigned' || viewMode === 'studentLearning' || viewMode === 'studentTest';

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) && isStudentViewMode));
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) && isStudentViewMode));
  const studentAuthorized = isStudent(user) && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionHintButton = (appContext: AppContext) => {
  const {
    isAssignmentReturnedToFix,
    isAssignmentUnsubmitted,
    isPlayerActivity,
    isPlayerAssignment,
    isStudioEdit,
    isStudioPreview,
    isTest,
    user,
    viewMode,
    hasStudentId
  } = appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const studentAuthorized =
    isStudent(user) &&
    (isPlayerActivity || (isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix) && !isTest));

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionShowAnswerButton = (appContext: AppContext) => {
  const {
    isAssignmentReturnedToFix,
    isAssignmentUnsubmitted,
    isPlayerActivity,
    isPlayerAssignment,
    isStudioEdit,
    isStudioPreview,
    isTest,
    user,
    viewMode,
    hasStudentId
  } = appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const studentAuthorized =
    isStudent(user) &&
    (isPlayerActivity || (isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix) && !isTest));

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionCheckButton = (appContext: AppContext) => {
  const {
    isAssignmentReturnedToFix,
    isAssignmentUnsubmitted,
    isPlayerActivity,
    isPlayerAssignment,
    isStudioEdit,
    isStudioPreview,
    isTest,
    user,
    viewMode,
    hasStudentId
  } = appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity || (isPlayerAssignment && !hasStudentId)) &&
        viewMode === 'studentLearning'));
  const studentAuthorized =
    isStudent(user) &&
    (isPlayerActivity || (isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix) && !isTest));

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeQuestionScoreWeight = (appContext: AppContext) => {
  const { user, isStudioEdit, isStudioPreview, isPlayerActivity, isPlayerAssignment, isTest, viewMode, hasStudentId } =
    appContext;

  const adminAuthorized =
    isAdmin(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity) && viewMode === 'studentLearning') ||
      (isPlayerAssignment && ((hasStudentId && !isTest) || (!hasStudentId && viewMode === 'studentLearning'))));
  const teacherAuthorized =
    isTeacher(user) &&
    (isStudioEdit ||
      ((isStudioPreview || isPlayerActivity) && viewMode === 'studentLearning') ||
      (isPlayerAssignment && ((hasStudentId && !isTest) || (!hasStudentId && viewMode === 'studentLearning'))));
  const studentAuthorized = isStudent(user) && isPlayerAssignment && !isTest;

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSeeReturnToFixSwitch = (appContext: AppContext) => {
  const { user } = appContext;

  const teacherAuthorized = isTeacher(user);

  return teacherAuthorized;
};

export const canSeeSatisfactionSurvey = (appContext: AppContext) => {
  const { user, isAssignmentUnsubmitted, isAssignmentReturnedToFix, isPlayerAssignment } = appContext;

  const studentAuthorized =
    isStudent(user) && isPlayerAssignment && (isAssignmentUnsubmitted || isAssignmentReturnedToFix);

  return studentAuthorized;
};

export const canSeeUnansweredQuestions = (appContext: AppContext) => {
  const { user } = appContext;

  const studentAuthorized = isStudent(user);

  return studentAuthorized;
};

export const canAccessAssignmentSubmitPage = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isAssignmentSubmitted, isAssignmentEvaluated, isAssignmentUnsubmitted } =
    appContext;

  const teacherAuthorized = isTeacher(user) && isPlayerAssignment && !isAssignmentUnsubmitted;
  const studentAuthorized = isStudent(user) && isPlayerAssignment && !isAssignmentSubmitted && !isAssignmentEvaluated;

  return teacherAuthorized || studentAuthorized;
};

export const canAccessAssignmentSummaryPage = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isAssignmentUnsubmitted } = appContext;

  const adminAuthorized = isAdmin(user) && isPlayerAssignment && !isAssignmentUnsubmitted;
  const teacherAuthorized = isTeacher(user) && isPlayerAssignment && !isAssignmentUnsubmitted;
  const studentAuthorized = isStudent(user) && isPlayerAssignment && !isAssignmentUnsubmitted;

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canReadQuestionGrade = (appContext: AppContext) => {
  const { user, isAssignmentSubmitted, isAssignmentEvaluated, isPlayerAssignment, isTest, viewMode } = appContext;

  const teacherAuthorized =
    isTeacher(user) &&
    isPlayerAssignment &&
    isTest &&
    (isAssignmentSubmitted || isAssignmentEvaluated) &&
    viewMode === 'studentAssigned';
  const studentAuthorized =
    isStudent(user) && isPlayerAssignment && isTest && (isAssignmentSubmitted || isAssignmentEvaluated);

  return teacherAuthorized || studentAuthorized;
};

export const canUpsertQuestionGrade = (appContext: AppContext) => {
  const { user, isAssignmentSubmitted, isPlayerAssignment, isTest, viewMode } = appContext;

  const teacherAuthorized =
    isTeacher(user) && isPlayerAssignment && isTest && isAssignmentSubmitted && viewMode === 'studentAssigned';

  return teacherAuthorized;
};

export const canSeePresenterActionBar = (appContext: AppContext) => {
  const { user, isStudioPreview, isPlayer, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson && (isStudioPreview || isPlayer);
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson && (isStudioPreview || isPlayer);

  return adminAuthorized || teacherAuthorized;
};

export const canSeeSlidesNavigation = (appContext: AppContext) => {
  const { user, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson;
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson;
  const studentAuthorized = isStudent(user) && isPlenaryLesson;

  return adminAuthorized || teacherAuthorized || studentAuthorized;
};

export const canSyncSlideNumber = (appContext: AppContext) => {
  const { user, isPlenaryLesson } = appContext;

  const studentAuthorized = isStudent(user) && isPlenaryLesson;

  return studentAuthorized;
};

export const canSeeCanvas = (appContext: AppContext) => {
  const { user, isStudioPreview, isPlayer, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson && (isStudioPreview || isPlayer);
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson && (isStudioPreview || isPlayer);

  return adminAuthorized || teacherAuthorized;
};

export const canSaveCanvas = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson && isPlayerAssignment;
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson && isPlayerAssignment;

  return adminAuthorized || teacherAuthorized;
};

export const canRevealAnswers = (appContext: AppContext) => {
  const { user, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson;
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson;

  return adminAuthorized || teacherAuthorized;
};

export const canSeePlenaryLessonCode = (appContext: AppContext) => {
  const { user, isPlayerAssignment, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson && isPlayerAssignment;
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson && isPlayerAssignment;

  return adminAuthorized || teacherAuthorized;
};

export const canEnlargeOpenDiscussionAnswer = (appContext: AppContext) => {
  const { user, isPlenaryLesson } = appContext;

  const adminAuthorized = isAdmin(user) && isPlenaryLesson;
  const teacherAuthorized = isTeacher(user) && isPlenaryLesson;

  return adminAuthorized || teacherAuthorized;
};
