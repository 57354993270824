import { createNewAudioData } from './Audio/create';
import { createNewAvatarWidgetData } from './Avatar/create';
import { createNewBlockTitleData } from './BlockTitle/create';
import { createNewClozeQuestionData } from './ClozeQuestion/create';
import { createNewCombinedTitleData } from './CombinedTitle/create';
import { createNewEmbedData } from './Embed/create';
import { createNewEnclosureWithBlockTitleData } from './EnclosureWithBlockTitle/create';
import { createNewEnclosureWithCombinedTitleData } from './EnclosureWithCombinedTitle/create';
import { createNewFillInTheBlanksQuestionData } from './FillInTheBlanksQuestion/create';
import { createNewImageData } from './Image/create';
import { createNewOpenQuestionData } from './OpenQuestion/create';
import { createNewSelectionQuestionData } from './SelectionQuestion/create';
import { createNewTextData } from './Text/create';
import { createNewVideoData } from './Video/create';
import { WidgetType } from '../utils/widgets';

export const widgetsCreateFunctions = {
  // Questions
  [WidgetType.OpenQuestion]: createNewOpenQuestionData,
  [WidgetType.SelectionQuestion]: createNewSelectionQuestionData,
  [WidgetType.FillInTheBlanksQuestion]: createNewFillInTheBlanksQuestionData,
  [WidgetType.ClozeQuestion]: createNewClozeQuestionData,
  // Static
  [WidgetType.BlockTitle]: createNewBlockTitleData,
  [WidgetType.CombinedTitle]: createNewCombinedTitleData,
  [WidgetType.EnclosureWithBlockTitle]: createNewEnclosureWithBlockTitleData,
  [WidgetType.EnclosureWithCombinedTitle]: createNewEnclosureWithCombinedTitleData,
  [WidgetType.Audio]: createNewAudioData,
  [WidgetType.Image]: createNewImageData,
  [WidgetType.Text]: createNewTextData,
  [WidgetType.Video]: createNewVideoData,
  [WidgetType.Embed]: createNewEmbedData,
  [WidgetType.AvatarWidget]: createNewAvatarWidgetData
};
