import { InteractiveButtonThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type AnswerBodyStylesProps = {
  interactiveButtonThemeProps: InteractiveButtonThemeProps;
  hasImage?: boolean;
  hasText?: boolean;
  isMobile?: boolean;
  isSelectionQuestionAnswer?: boolean;
};

export const useStyles = createStyles((theme, props: AnswerBodyStylesProps) => {
  const { hasImage, hasText, interactiveButtonThemeProps, isMobile, isSelectionQuestionAnswer } = props;
  const globalThemeContentColors = theme.other.global.content.colors;

  const audioStyles = {
    width: hasImage ? rem(24) : rem(36),
    height: hasImage ? rem(24) : rem(36),
    padding: 0
  };

  const audioButtonStyles = {
    borderRadius: 0,
    minWidth: hasImage ? rem(24) : rem(36),
    minHeight: hasImage ? rem(24) : rem(36),
    background: 'none',
    color: interactiveButtonThemeProps.standard.color ?? globalThemeContentColors.secondary.contrast,
    '&:hover': {
      color: interactiveButtonThemeProps.hovered.color ?? globalThemeContentColors.primary.contrast
    }
  };

  const imageStyles = {
    position: 'unset !important' as 'unset',
    overflow: 'hidden',
    ...(!isSelectionQuestionAnswer && isMobile
      ? {
          height: `${hasImage && hasText ? rem(100) : rem(150)} !important`,
          width: `${hasImage && hasText ? rem(150) : '100%'} !important`
        }
      : {
          height: `${rem(150)} !important`,
          width: `100% !important`
        })
  };

  return {
    answerStyle: {
      borderRadius: rem(4),
      padding: rem(8),
      width: '100%'
    },
    caption: {
      marginTop: rem(hasImage ? 4 : 0),
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    audioWrapper: {
      paddingTop: rem(hasImage ? 4 : 0)
    },
    textWrapper: {
      flex: 1
    },

    audioPlayButton: {
      ...audioStyles,
      ...audioButtonStyles,
      '&:hover': {
        ...audioStyles,
        ...audioButtonStyles
      }
    },
    audioPlaceholder: {
      ...audioStyles
    },
    image: {
      ...imageStyles
    },
    imagePlaceholder: {
      ...imageStyles
    }
  };
});
