import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';

import { getItemWidth } from 'shared/widgetsSDK/Stage.utils';

import { ResizableDimensions } from '@/components/Resizable/types';

type UseInitialDimensionsProps = {
  setDimensions: Dispatch<SetStateAction<ResizableDimensions>>;
  span: number;
  height: ResizableDimensions['height'];
  isResizingCurrentItem: boolean;
  isResizableVertically: boolean;
  dimensionsRef: MutableRefObject<{ width: number; height: number | 'auto' }>;
  width?: number;
};

export function useInitialDimensions(props: UseInitialDimensionsProps) {
  const { dimensionsRef, setDimensions, span, height, isResizingCurrentItem, isResizableVertically, width } = props;

  useEffect(() => {
    if (!isResizingCurrentItem) {
      const widthRounded = Math.round(width || getItemWidth(span));
      dimensionsRef.current.width = widthRounded;
      dimensionsRef.current.height = isResizableVertically ? height : ('auto' as const);
      setDimensions({
        width: widthRounded,
        height: isResizableVertically ? height : ('auto' as const)
      });
    }
  }, [span, height, isResizingCurrentItem, isResizableVertically, setDimensions, dimensionsRef, width]);
}
