import * as Y from 'yjs';

import { LinkTargetType, ObjectFit } from '../../types/Image';
import { ManifestInitializedProps, InitializePropsOptions } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';
import { ImageConfig } from '../questionConfigs';

export function createNewImageData(
  options: InitializePropsOptions,
  initializationConfig?: ImageConfig
): ManifestInitializedProps {
  const id = genRandId();

  const {
    alt = '',
    goToLink = '',
    goToLinkTitle = '',
    goToPage = '',
    goToPageTitle = '',
    goToType = null,
    isDecorative = false,
    linkTargetType = LinkTargetType.SameWindow,
    mobileObjectFit = ObjectFit.Contain,
    mobileSrc = '',
    objectFit = ObjectFit.Contain,
    src = ''
  } = initializationConfig ?? {};

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.Image],
        ['children', new Y.Array()],
        ['src', src],
        ['alt', alt],
        ['mobileSrc', mobileSrc],
        ['objectFit', objectFit],
        ['mobileObjectFit', mobileObjectFit],
        ['goToLink', goToLink],
        ['goToLinkTitle', goToLinkTitle],
        ['goToPage', goToPage],
        ['goToPageTitle', goToPageTitle],
        ['linkTargetType', linkTargetType],
        ['goToType', goToType],
        ['isDecorative', isDecorative]
      ])
    }
  ];
}
