import { useMantineTheme } from '@mantine/core';
import React from 'react';
import * as Y from 'yjs';

import { ObjectFit } from 'shared/types/Image';

import { Answer, AnswerProps } from '@/components/Answer';
import { AnswerBody } from '@/components/Answer/AnswerBody';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useContentObjectProperty, useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';

export const SelectionQuestionPlayerSingleAnswer = (
  props: Omit<AnswerProps, 'answerAreaThemeProps' | 'checkboxThemeProps' | 'selectedCheckboxThemeProps'>
) => {
  const { answerIndex, disabled, isChecked, status, inputType, name } = props;

  const fragment = useContentObjectStaticProperty<Y.XmlFragment>(`answers.${answerIndex}.text.fragment`);
  const [audioSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.audio.src`);
  const [audioTitle] = useContentObjectProperty<string>(`answers.${answerIndex}.audio.title`);
  const [imageAlt] = useContentObjectProperty<string>(`answers.${answerIndex}.image.alt`);
  const [imageMobileObjectFit] = useContentObjectProperty<ObjectFit>(`answers.${answerIndex}.image.mobileObjectFit`);
  const [imageMobileSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.image.mobileSrc`);
  const [imageObjectFit] = useContentObjectProperty<ObjectFit>(`answers.${answerIndex}.image.objectFit`);
  const [imageSrc] = useContentObjectProperty<string>(`answers.${answerIndex}.image.src`);

  const theme = useMantineTheme();

  return (
    <Answer
      AnswerBodyComponent={
        <AnswerBody
          audioSrc={audioSrc}
          audioTitle={audioTitle}
          dataTestIdPrefix="preview-selection-question"
          fragment={fragment}
          imageAlt={imageAlt}
          imageMobileObjectFit={imageMobileObjectFit}
          imageMobileSrc={imageMobileSrc}
          imageObjectFit={imageObjectFit}
          imageSrc={imageSrc}
          interactiveButtonThemeProps={theme.other.learningItems.selectionQuestion.interactiveButton}
          isSelectionQuestionAnswer
        />
      }
      answerAreaThemeProps={theme.other.learningItems.selectionQuestion.answerArea}
      answerIndex={answerIndex}
      checkboxThemeProps={theme.other.learningItems.selectionQuestion.checkbox}
      className={ThemeClassNames.widgets.selectionQuestion.answer}
      dataTestIdPrefix="preview-selection-question"
      disabled={disabled}
      inputType={inputType}
      isChecked={isChecked}
      name={name}
      selectedCheckboxThemeProps={theme.other.learningItems.selectionQuestion.selectedCheckbox}
      status={status}
    />
  );
};
