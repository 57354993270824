import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewQuestionGroupList } from 'shared/widgetsSDK/QuestionGroupList/create';
import { manifestLocales } from 'shared/widgetsSDK/QuestionGroupList/manifest.locales';

import QuestionGroupListPreviewImage from './resources/question-group-list.svg';
import { QuestionGroupList } from '@/icons/QuestionGroupList';

export const Manifest: ManifestType = {
  name: WidgetType.QuestionGroupList,
  previewGroups: [
    {
      id: 'questionGroupList',
      divider: {
        icon: QuestionGroupList,
        title: 'questionGroupList'
      },
      previews: [
        {
          name: 'questionGroupList',
          previewImage: {
            paths: {
              en: QuestionGroupListPreviewImage,
              he: QuestionGroupListPreviewImage
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.questionGroupList.previewImageAlt }
          },
          initializeProps: createNewQuestionGroupList,
          dimensions: {
            width: 1180,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
