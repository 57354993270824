import { createContext } from 'react';

export type WorkModeContextType = {
  isPlayer?: boolean;
  isPlayerActivity?: boolean;
  isPlayerAssignment?: boolean;
  isPlayerAssignmentSubmitPage?: boolean;
  isPlayerAssignmentSummaryPage?: boolean;
  isPlayerAssignmentTest?: boolean;
  isSnapshotHistory?: boolean;
  isStudio?: boolean;
  isStudioEdit?: boolean;
  isStudioPreview?: boolean;
};

export const WorkModeContext = createContext<WorkModeContextType>({});
