import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { memo, useEffect, useState } from 'react';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';
import { WidgetType } from 'shared/utils/widgets';

import { useStyles } from './Studio.styles';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useGetNestLevel } from '@/contentObjects/Root/hooks/useGetNestLevel';
import { ToVerticalFlexbox } from '@/icons/ToVerticalFlexbox';
import { ToHorizontalFlexbox } from '@/icons/toHorizontalFlexbox';
import { WidgetAdditionalAction, WidgetData } from '@/types/Stage';
import { DndSection } from '@/widgets/FlexSection/DndSection';
import { Config } from '@/widgets/FlexSection/config';
import { useFlexSectionStyle } from '@/widgets/FlexSection/hooks/useFlexSectionStyle';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type StudioProps = {
  alignItems?: string;
  className?: string;
  direction?: FlexSectionDirection;
  gap?: number;
  itemsOrder?: Record<string, number>;
  selectable?: boolean;
  shouldApplyNestStyles?: boolean;
  withConfig?: boolean;
  initialize?: (data: WidgetData) => void;
};

export const Studio = memo(function Studio(props: StudioProps) {
  const {
    alignItems,
    className,
    direction: propsDirection,
    gap,
    itemsOrder,
    selectable = false,
    shouldApplyNestStyles = true,
    withConfig = true,
    initialize
  } = props;

  const { id, document } = useContentObject();

  const [resizingItemId, setResizingItemId] = useState<string | undefined>();

  const nestLevel = useGetNestLevel(document, id, WidgetType.FlexSection);

  const [sectionDirection, setSectionDirection] = useContentObjectProperty<FlexSectionDirection>('direction');
  const [backgroundColor] = useFlexSectionStyle(document, id, 'backgroundColor');
  const [padding] = useFlexSectionStyle<number>(document, id, 'padding');
  const [border] = useFlexSectionStyle<BorderThemeProps>(document, id, 'border');
  const [borderRadius] = useFlexSectionStyle<BorderRadiusThemeProps>(document, id, 'borderRadius');
  const t = useTranslations('widgets');
  const questionGroupSettings = useParentQuestionGroupSettings();

  const direction = propsDirection ?? sectionDirection;

  const { classes, cx } = useStyles({
    backgroundColor,
    border,
    borderRadius,
    direction,
    nestLevel,
    padding,
    shouldApplyNestStyles,
    dense: !!questionGroupSettings
  });

  useEffect(() => {
    const changeDirection: WidgetAdditionalAction = {
      actionName: t('direction'),
      dataTestId: 'widget-additional-action-direction',
      Icon: direction === FlexSectionDirection.row ? ToVerticalFlexbox : ToHorizontalFlexbox,
      onClick: () =>
        setSectionDirection(
          direction === FlexSectionDirection.row ? FlexSectionDirection.column : FlexSectionDirection.row
        )
    };

    initialize?.({
      additionalActions: [changeDirection]
    });
  }, [direction, initialize, setSectionDirection, t]);

  return (
    <>
      {withConfig && (
        <SideMenuPortal>
          <Config />
        </SideMenuPortal>
      )}

      <Box className={cx(classes.dndSectionWrapperStyle, className)}>
        {selectable ? (
          <Selectable id={id}>
            <DndSection
              alignItems={alignItems}
              direction={direction}
              gap={gap}
              id={id}
              itemsOrder={itemsOrder}
              resizingItemId={resizingItemId}
              setResizingItemId={setResizingItemId}
            />
          </Selectable>
        ) : (
          <DndSection
            alignItems={alignItems}
            direction={direction}
            gap={gap}
            id={id}
            itemsOrder={itemsOrder}
            resizingItemId={resizingItemId}
            setResizingItemId={setResizingItemId}
          />
        )}
      </Box>
    </>
  );
});
