import { ActionIcon, Box, Flex } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { useStyles } from '@/components/UploadFile/UploadFile.styles';
import { Replace } from '@/icons/Replace';
import { Trashcan } from '@/icons/Trashcan';

type FileActionsProps = {
  onDelete?: () => void;
  onReplace: () => void;
  CustomResetButton?: ReactNode;
  disabled?: boolean;
};

export function FileActions(props: FileActionsProps) {
  const { onDelete, onReplace, CustomResetButton, disabled } = props;
  const t = useTranslations('components');
  const { cx, classes } = useStyles({ disabled });

  return (
    <Flex align="center" justify="center" gap={8}>
      {CustomResetButton}
      {onDelete && (
        <ActionIcon
          variant="transparent"
          className={classes.deleteBtn}
          onClick={() => {
            onDelete();
          }}
          title={t('uploadFileActions.delete')}
          disabled={disabled}
        >
          <Trashcan />
        </ActionIcon>
      )}
      <ActionIcon
        variant="transparent"
        className={cx(classes.replaceBtn, { [classes.disabled]: disabled })}
        onClick={() => {
          onReplace();
        }}
        disabled={disabled}
      >
        <Replace />
        <Box>{t('uploadFileActions.replace')}</Box>
      </ActionIcon>
    </Flex>
  );
}
