import { ActionIcon, Box, Flex, rem, Text } from '@mantine/core';

import { PlayerFile } from 'shared/types/PlayerFile';

import { useStyles } from './Thumbnails.styles';
import { ExcelLogo } from '@/icons/ExcelLogo';
import { GeneralDocument } from '@/icons/GeneralDocument';
import { PdfLogo } from '@/icons/PdfLogo';
import { PowerPointLogo } from '@/icons/PowerPointLogo';
import { Trashcan } from '@/icons/Trashcan';

function renderFileExtensionIcon(extension: string) {
  switch (extension) {
    case 'pdf':
      return <PdfLogo />;

    case 'ppt':
    case 'pptx':
      return <PowerPointLogo />;

    case 'xls':
    case 'xlsx':
      return <ExcelLogo />;

    default:
      return <GeneralDocument />;
  }
}

type FileAnswerThumbnailsProps = {
  isDeleteDisabled?: boolean;
  files: PlayerFile[];
  onDeleteFile: (index: number) => void;
};

export function FileAnswerThumbnails(props: FileAnswerThumbnailsProps) {
  const { files, onDeleteFile, isDeleteDisabled } = props;
  const { classes } = useStyles();

  return (
    <Flex gap={rem(10)} direction="row" wrap="wrap">
      {files.map(({ name }, index) => {
        const fileExtension = name.split('.')[1];

        return (
          <Flex className={classes.answerThumbnailContainer} title={name} key={index}>
            <Box className={classes.answerThumbnail}>
              {renderFileExtensionIcon(fileExtension)}
              <ActionIcon
                color="dark"
                variant="light"
                className={classes.deleteIcon}
                onClick={() => onDeleteFile(index)}
                disabled={isDeleteDisabled}
              >
                <Trashcan />
              </ActionIcon>
            </Box>
            <Text size="xs" className={classes.fileName} lineClamp={2}>
              {name}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
