import { Box, OptionalPortal } from '@mantine/core';
import cx from 'clsx';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { OpenDiscussionAnswer } from 'shared/types/OpenDiscussion';

import * as classes from './TeacherView.css';
import { useFullscreenContext } from '@/utils/useFullscreenContext';
import { AnswerCard } from '@/widgets/OpenDiscussion/player/AnswerCard';
import { DndDragLayer } from '@/widgets/_components/dnd/DndDragLayer/DndDragLayer';

export type CardType = {
  id: string;
  index: number;
  columnIndex: number;
};

type CardProps = {
  answer: OpenDiscussionAnswer;
  columnIndex: number;
  onDelete: (id: string) => void;
  onEdit: (answer: OpenDiscussionAnswer) => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number, dragColumnIndex: number, hoverColumnIndex: number) => void;
  onEditColor?: (newColor: string, answer: OpenDiscussionAnswer) => Promise<void>;
  onClick: () => void;
  setFocusedAnswerIndex: Dispatch<SetStateAction<number>>;
  cardIndex: number;
};

export const Card = (props: CardProps) => {
  const {
    answer,
    columnIndex,
    onDelete,
    onEdit,
    index,
    moveCard,
    onClick,
    onEditColor,
    setFocusedAnswerIndex,
    cardIndex
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const { isFullscreen } = useFullscreenContext();

  const [, drop] = useDrop<CardType, void>({
    accept: 'AnswerCard',
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }

      const { index: dragIndex, columnIndex: dragColumnIndex } = item;
      const hoverIndex = index;
      const hoverColumnIndex = columnIndex;

      if (dragIndex === hoverIndex && dragColumnIndex === hoverColumnIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      let dropIndex = hoverIndex;

      const shouldDropLower =
        dragColumnIndex === hoverColumnIndex
          ? dragIndex < hoverIndex && hoverClientY < hoverMiddleY
          : hoverClientY < hoverMiddleY;

      const shouldDropHigher =
        dragColumnIndex === hoverColumnIndex
          ? dragIndex > hoverIndex && hoverClientY > hoverMiddleY
          : hoverClientY > hoverMiddleY;

      if (shouldDropLower) {
        dropIndex = dragColumnIndex === hoverColumnIndex ? hoverIndex - 1 : hoverIndex;
      } else if (shouldDropHigher) {
        dropIndex = hoverIndex + 1;
      }

      moveCard(dragIndex, dropIndex, dragColumnIndex, hoverColumnIndex);

      item.index = dropIndex;
      item.columnIndex = hoverColumnIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'AnswerCard',
    item: () => {
      return { id: answer.id, index, columnIndex };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  return (
    <>
      <Box ref={ref} className={cx(classes.move, { [classes.hidden]: isDragging })}>
        <AnswerCard
          answer={answer}
          onDelete={onDelete}
          onEdit={() => onEdit(answer)}
          onClick={() => {
            setFocusedAnswerIndex(cardIndex);
            onClick();
          }}
          onEditColor={onEditColor}
        />
      </Box>
      {isDragging && (
        <OptionalPortal withinPortal={!isFullscreen}>
          <DndDragLayer fixed width={ref.current?.getBoundingClientRect()?.width}>
            <AnswerCard answer={answer} onDelete={onDelete} onEdit={() => onEdit(answer)} />
          </DndDragLayer>
        </OptionalPortal>
      )}
    </>
  );
};
