import { getDefaultWhiteList } from 'xss';
import { escapeAttrValue } from 'xss';
import { FilterXSS } from 'xss';

export const sanitizeAndValidateIframe = (input: string): string => {
  const doc = new DOMParser().parseFromString(input, 'text/html');

  const iframe = doc.querySelector('iframe');
  if (!iframe) throw new Error('Input does not contain an iframe');

  const iframeHtml = iframe.outerHTML;

  return iframeXSS.process(iframeHtml);
};

const INVALID_ATTRS = ['width', 'height', 'title'];

const iframeXSS = new FilterXSS({
  whiteList: {
    ...getDefaultWhiteList(),
    iframe: []
  },

  onIgnoreTagAttr(tag, attributeName, value) {
    if (tag === 'iframe' && !INVALID_ATTRS.includes(attributeName)) {
      return `${attributeName}="${escapeAttrValue(value)}"`;
    }
  }
});
