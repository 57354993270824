import * as Y from 'yjs';

import { WidgetType } from 'shared/utils/widgets';

import { genRandId } from './genRandId';
import { SupportedLocale } from '../types/SupportedLocale';
import { createNewSectionData } from '../widgetsSDK/Section/create';
import { createNewSubmitPageComment } from '../widgetsSDK/SubmitPageComment/create';
import { createNewSubmitPageReturnToFixSwitch } from '../widgetsSDK/SubmitPageReturnToFixSwitch/create';
import { createNewSubmitPageSatisfactionSurvey } from '../widgetsSDK/SubmitPageSatisfactionSurvey/create';
import { createNewSubmitPageTitle } from '../widgetsSDK/SubmitPageTitle/create';
import { createNewSubmitPageUnansweredQuestions } from '../widgetsSDK/SubmitPageUnansweredQuestions/create';
import { createNewSummaryPageSubmissionHistory } from '../widgetsSDK/SummaryPageSubmissionHistory/create';
import { createContentObject } from '../widgetsSDK/contentObjectOperations';
import { validateYArray } from '../widgetsSDK/yjs';

export function createSubmitPage(document: Y.Doc, locale: SupportedLocale) {
  const { id, pageData } = createNewSubmitPageData();

  document.transact(() => {
    const [{ id: titleId, contentObjectData: titleData }] = createNewSubmitPageTitle();
    const [{ id: toFixId, contentObjectData: toFixData }] = createNewSubmitPageReturnToFixSwitch();
    const [{ id: unansweredQuestionsId, contentObjectData: unansweredQuestionsData }] =
      createNewSubmitPageUnansweredQuestions();
    const [{ id: submissionHistoryId, contentObjectData: submissionHistoryData }] = createNewSubmitPageComment();
    const [{ id: surveyId, contentObjectData: surveyData }] = createNewSubmitPageSatisfactionSurvey({
      locale
    });
    const [{ id: historyId, contentObjectData: historyData }] = createNewSummaryPageSubmissionHistory();

    createContentObject(document, id, pageData);

    createContentObject(document, titleId, titleData);
    createContentObject(document, toFixId, toFixData);
    createContentObject(document, unansweredQuestionsId, unansweredQuestionsData);
    createContentObject(document, submissionHistoryId, submissionHistoryData);
    createContentObject(document, surveyId, surveyData);
    createContentObject(document, historyId, historyData);

    const [titleSectionData, titleSectionId] = createNewSectionData(id, [
      { id: titleId, span: 12, column: 1, height: 'auto' }
    ]);
    const [toFixSectionData, toFixSectionId] = createNewSectionData(id, [
      { id: toFixId, span: 12, column: 1, height: 'auto' }
    ]);
    const [unansweredQuestionsSectionData, unansweredQuestionsSectionId] = createNewSectionData(id, [
      { id: unansweredQuestionsId, span: 12, column: 1, height: 'auto' }
    ]);
    const [submissionSectionData, submissionSectionId] = createNewSectionData(id, [
      { id: submissionHistoryId, span: 12, column: 1, height: 112 }
    ]);
    const [surveySectionData, surveySectionId] = createNewSectionData(id, [
      { id: surveyId, span: 12, column: 1, height: 'auto' }
    ]);
    const [historySectionData, historySectionId] = createNewSectionData(id, [
      { id: historyId, span: 12, column: 1, height: 'auto' }
    ]);

    createContentObject(document, titleSectionId, titleSectionData);
    createContentObject(document, toFixSectionId, toFixSectionData);
    createContentObject(document, unansweredQuestionsSectionId, unansweredQuestionsSectionData);
    createContentObject(document, submissionSectionId, submissionSectionData);
    createContentObject(document, surveySectionId, surveySectionData);
    createContentObject(document, historySectionId, historySectionData);

    validateYArray(pageData.get('children')).push([
      titleSectionId,
      toFixSectionId,
      unansweredQuestionsSectionId,
      submissionSectionId,
      surveySectionId,
      historySectionId
    ]);
  });
}

export function historyFeedbackInSubmitPageExists(document: Y.Doc) {
  const rootMap = document.getMap('root');
  const rootContentObjects = rootMap.get('contentObjects') as Y.Map<unknown>;
  const contentObjectsMap = rootContentObjects.toJSON();
  const existingSubmitPage = getExistingSubmitPage(document);
  const existingSubmitPageChildren = validateYArray<string>(existingSubmitPage.get('children'));
  const historyFeedbackInSubmitPageExists = existingSubmitPageChildren.toJSON().some(child => {
    const sectionChildren = contentObjectsMap[child].children as string[];
    return sectionChildren.some(
      sectionChildren => contentObjectsMap[sectionChildren].type === WidgetType.SummaryPageSubmissionHistory
    );
  });
  return historyFeedbackInSubmitPageExists;
}

export function getExistingSubmitPage(document: Y.Doc) {
  const rootMap = document.getMap('root');
  const rootContentObjects = rootMap.get('contentObjects') as Y.Map<unknown>;
  const existingSubmitPage = Array.from(rootContentObjects.values()).find(contentObject => {
    return contentObject.get('type') === WidgetType.SubmitPage;
  }) as Y.Map<WidgetType.SubmitPage>;
  return existingSubmitPage;
}

export function createHistoryFeedbackForSubmitPage(document: Y.Doc, existingSubmitPage: Y.Map<WidgetType.SubmitPage>) {
  const [{ id: historyId, contentObjectData: historyData }] = createNewSummaryPageSubmissionHistory();
  const [historySectionData, historySectionId] = createNewSectionData(existingSubmitPage.get('id') as string, [
    { id: historyId, span: 12, column: 1, height: 'auto' }
  ]);
  createContentObject(document, historyId, historyData);
  createContentObject(document, historySectionId, historySectionData);
  validateYArray(existingSubmitPage.get('children')).push([historySectionId]);
}

function createNewSubmitPageData() {
  const id = genRandId();
  return {
    id,
    pageData: new Y.Map([
      ['id', id],
      ['type', WidgetType.SubmitPage],
      ['style', new Y.Map()],
      ['children', new Y.Array()]
    ])
  };
}
