import { useContext } from 'react';
import { useStore } from 'zustand';

import { YjsStoreContext, YjsStoreType } from '@/components/YjsProvider';

export function useYjsStore<T = YjsStoreType>(selector: (state: YjsStoreType) => T) {
  const store = useContext(YjsStoreContext);
  if (store === null) throw new Error('YjsStoreContext not initialized properly.');

  return useStore(store, selector);
}
