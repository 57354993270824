import { InteractiveButtonThemeProps } from '@hendrx/modules/ui';
import { Box, Flex } from '@mantine/core';
import * as Y from 'yjs';

import { ObjectFit } from 'shared/types/Image';

import { useStyles } from './AnswerBody.styles';
import { useDeviceView } from '@/utils/deviceHooks';
import { Base as Audio } from '@/widgets/Audio/player/Base';
import { Base as Image } from '@/widgets/Image/player/Base';
import { Base as Text } from '@/widgets/Text/player/Base';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

type AnswerBodyProps = {
  imageAlt: string;
  imageMobileObjectFit: ObjectFit;
  imageMobileSrc: string;
  imageObjectFit: ObjectFit;
  imageSrc: string;
  interactiveButtonThemeProps: InteractiveButtonThemeProps;
  audioSrc?: string;
  audioTitle?: string;
  dataTestIdPrefix?: string;
  fragment?: Y.XmlFragment;
  isSelectionQuestionAnswer?: boolean;
};

export function AnswerBody(props: AnswerBodyProps) {
  const {
    audioSrc,
    audioTitle,
    dataTestIdPrefix,
    fragment,
    imageAlt,
    imageMobileObjectFit,
    imageMobileSrc,
    imageObjectFit,
    imageSrc,
    interactiveButtonThemeProps,
    isSelectionQuestionAnswer
  } = props;

  const { hasAudio, hasImage, hasText } = useAnswerElements();

  const { deviceView } = useDeviceView();
  const isMobile = deviceView === 'mobile';

  const { classes } = useStyles({
    hasImage,
    hasText,
    interactiveButtonThemeProps,
    isMobile,
    isSelectionQuestionAnswer
  });

  const onlyImageWithText = hasImage && hasText && !hasAudio;

  return (
    <Flex>
      <Box className={classes.answerStyle} data-testid={`${dataTestIdPrefix}-answer-box`}>
        {!isSelectionQuestionAnswer && onlyImageWithText && isMobile ? (
          <Flex gap={12} align="center">
            <Image
              alt={imageAlt}
              src={imageSrc}
              mobileSrc={imageMobileSrc}
              objectFit={imageObjectFit}
              mobileObjectFit={imageMobileObjectFit}
              classNames={{ image: classes.image, placeholder: classes.imagePlaceholder }}
            />
            <Box id="text-wrapper" className={classes.textWrapper}>
              <Text
                fragment={fragment}
                dataTestIdPrefix={`${dataTestIdPrefix}-answer-text`}
                disableTypographyInheritance
              />
            </Box>
          </Flex>
        ) : (
          <>
            {hasImage && (
              <Flex>
                <Image
                  alt={imageAlt}
                  src={imageSrc}
                  mobileSrc={imageMobileSrc}
                  objectFit={imageObjectFit}
                  mobileObjectFit={imageMobileObjectFit}
                  classNames={{ image: classes.image, placeholder: classes.imagePlaceholder }}
                />
              </Flex>
            )}
            <Flex className={classes.caption}>
              {hasText && (
                <Box id="text-wrapper" className={classes.textWrapper}>
                  <Text
                    fragment={fragment}
                    dataTestIdPrefix={`${dataTestIdPrefix}-answer-text`}
                    disableTypographyInheritance
                  />
                </Box>
              )}
              {hasAudio && (
                <Flex className={classes.audioWrapper}>
                  <Audio
                    src={audioSrc}
                    title={audioTitle}
                    classNames={{ playButton: classes.audioPlayButton, placeholder: classes.audioPlaceholder }}
                  />
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Box>
    </Flex>
  );
}
