import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  icon: {
    color: theme.colors.accent[6],
    width: rem(30),
    height: rem(30),
    '& svg': {
      width: rem(28),
      height: rem(28)
    }
  },
  button: {
    backgroundColor: theme.colors.accent[0],
    borderColor: theme.colors.accent[3],
    height: '100%',
    padding: rem(16),
    borderStyle: 'solid',
    borderWidth: rem(2),
    userSelect: 'none',
    transition: 'background-color 150ms ease',
    position: 'relative',
    borderRadius: rem(4),
    width: '100%',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors.gray[0]
    }
  }
}));
