import { BorderRadiusThemeProps } from '@hendrx/modules/ui';
import { useTranslations } from 'next-intl';

import { BorderRadiusPanel } from '@/components/Theme/BorderRadiusPanel';
import { ResetButton } from '@/components/Theme/ResetButton';
import { ThemeItemSection } from '@/components/Theme/ThemeItemSection';
import { useActivityThemeStylesProperty } from '@/contentObjects/Root/hooks/useActivityThemeStylesProperty';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function Corners() {
  const t = useTranslations('components.theme.settings');
  const { document, id } = useContentObject();
  const [themeCornerRadius] = useActivityThemeStylesProperty<BorderRadiusThemeProps>(document, 'table.cornerRadius');
  const [cornerRadius, setCornerRadius] = useWidgetStyle<Partial<BorderRadiusThemeProps> | null>(
    document,
    id,
    'cornerRadius'
  );

  return (
    <ThemeItemSection label={t('cornerRadius')}>
      <BorderRadiusPanel
        setBorderRadius={setCornerRadius}
        borderRadius={cornerRadius ?? themeCornerRadius}
        ResetButton={cornerRadius && <ResetButton onClick={() => setCornerRadius(null)} />}
      />
    </ThemeItemSection>
  );
}
