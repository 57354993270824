import * as Y from 'yjs';

import { Base } from './Base';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useBlockTitleWidgetStyles } from '@/utils/typographyStyles.utils';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function BlockTitle() {
  const { document, id } = useContentObject();
  const [fragment] = useContentObjectProperty<Y.XmlFragment>('fragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const widgetStyles = useBlockTitleWidgetStyles(id);

  return (
    <div className={customClassNames}>
      <Base
        fragment={fragment}
        overrideTypographyStylesPath="blockTitle"
        widgetStyles={widgetStyles}
        changeContrastColorToMain
      />
    </div>
  );
}
