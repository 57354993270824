import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  const globalTheme = theme.other.global;
  const typographyTheme = theme.other.typography;

  return {
    scoreContainer: {
      justifyContent: 'flex-end',
      flexDirection: 'row',
      width: '100%',
      marginTop: rem(10)
    },
    scoreWeight: {
      color: typographyTheme.p.color ?? globalTheme.content.colors.secondary.contrast
    }
  };
});
