import { AnswerElementType } from 'shared/types/AnswerElement';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useAnswerElements() {
  const [answerElements] = useContentObjectProperty<AnswerElementType[]>('answerElements');

  const hasAudio = answerElements.includes('Audio');
  const hasImage = answerElements.includes('Image');
  const hasText = answerElements.includes('Text');
  const isTextOnly = answerElements.length === 1 && answerElements[0] === 'Text';

  return {
    hasAudio,
    hasImage,
    hasText,
    isTextOnly
  };
}
