import { BasicThemeProps, BorderThemeProps, GlobalColorThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { AnswerStyles } from './types';
import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type AnswersCheckboxStylesProps = {
  answersStyleType: AnswerStyleType;
  checkboxThemeProps: BasicThemeProps;
  selectedCheckboxThemeProps?: GlobalColorThemeProps & { border: BorderThemeProps };
  isChecked?: boolean;
  isTextOnly?: boolean;
};

export const useStyles = createStyles((theme, props: AnswersCheckboxStylesProps) => {
  const { isChecked, answersStyleType, isTextOnly, checkboxThemeProps, selectedCheckboxThemeProps } = props;
  const globalThemeContentColors = theme.other.global.content.colors;

  const borderStyle = getThemeBorderStyle(
    isChecked && selectedCheckboxThemeProps ? selectedCheckboxThemeProps.border : checkboxThemeProps.border,
    globalThemeContentColors.decoration.main
  );

  const borderRadiusStyle = getThemeBorderRadiusStyle(checkboxThemeProps.borderRadius);

  const selectedCheckboxMainColor =
    selectedCheckboxThemeProps?.backgroundColor ?? globalThemeContentColors.primary.contrast;

  const checkboxBackgroundColor = isChecked
    ? selectedCheckboxThemeProps?.backgroundColor ?? globalThemeContentColors.primary.contrast
    : checkboxThemeProps?.backgroundColor ?? globalThemeContentColors.primary.main;

  const overrideAnswerStyle: Record<AnswerStyleType, AnswerStyles> = {
    Default: {
      checkboxLabel: {
        ...(isChecked && { borderColor: selectedCheckboxMainColor })
      }
    },
    Button: {
      checkboxBody: {
        insetInlineEnd: 0,
        position: 'absolute',
        transform: 'translateY(-50%)',
        top: '50%'
      },
      checkboxRoot: {
        ...(isTextOnly
          ? { insetInlineEnd: 0, top: `calc(50% - ${rem(20)})` }
          : { insetInlineEnd: rem(-26), bottom: rem(18) }),
        position: 'absolute',
        marginInline: rem(10),
        height: '100%',
        zIndex: 1
      },

      checkboxLabel: {
        ...(isChecked && { borderColor: selectedCheckboxMainColor }),
        ...(isTextOnly && { paddingInlineEnd: `${rem(48)} !important` })
      }
    },
    CheckboxWithoutBorder: {
      checkboxLabel: {
        border: 'none'
      }
    }
  };

  return {
    checkboxLabel: {
      width: rem(214),
      ...overrideAnswerStyle[answersStyleType].checkboxLabel
    },
    checkboxInput: {
      backgroundColor: checkboxBackgroundColor,
      ...borderStyle,
      ...borderRadiusStyle,
      '&:checked': {
        backgroundColor: checkboxBackgroundColor,
        ...borderStyle,
        ...borderRadiusStyle
      },
      ...overrideAnswerStyle[answersStyleType].checkboxInput
    },
    checkboxInner: {
      ...overrideAnswerStyle[answersStyleType].checkboxInner
    },
    checkboxBody: {
      ...overrideAnswerStyle[answersStyleType].checkboxBody
    },
    checkboxRoot: {
      ...overrideAnswerStyle[answersStyleType].checkboxRoot
    },
    contentArea: {
      ...overrideAnswerStyle[answersStyleType].contentArea
    }
  };
});
