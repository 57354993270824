import { useEffect } from 'react';

import { Base } from '@/widgets/SubmitPageUnansweredQuestions/studio/Base';
import { Manifest } from '@/widgets/Text/Manifest';
import { StudioProps } from '@/widgets/types/studioProps';

export function SubmitPageUnansweredQuestions(props: StudioProps) {
  const { initialize } = props;

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return <Base />;
}
