import { useDidUpdate } from '@mantine/hooks';
import { ViewMode } from '@storybook/types';
import { useEffect } from 'react';

import { usePlayerElementState } from '@/components/PlayerStateProvider';
import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';

export function usePlayerEvents(id: string, viewMode: ViewMode) {
  const [elementState, setElementState] = usePlayerElementState<Record<string, unknown> | undefined>(id);

  useEffect(() => {
    const handleLoad = (e: Event) => {
      const handleLoadEvent = new CustomEvent(`${PluginEvent.handleLoadEvent}-${id}`, {
        detail: { answersData: elementState?.answers }
      });
      document.dispatchEvent(handleLoadEvent);
    };
    document.addEventListener(`${PluginEvent.loadedEvent}-${id}`, handleLoad);
    return () => {
      document.removeEventListener(`${PluginEvent.loadedEvent}-${id}`, handleLoad);
    };
  }, [elementState, id]);

  useDidUpdate(() => {
    const viewModeChangeEvent = new CustomEvent(`${PluginEvent.viewModeChangeEvent}-${id}`, {
      detail: { answersData: elementState?.answers }
    });
    document.dispatchEvent(viewModeChangeEvent);
  }, [viewMode]);

  useEffect(() => {
    const handleAnswersChange = (e: Event) => {
      setElementState(currentState => ({
        ...currentState,
        answers: { ...(currentState?.answers ?? {}), ...(e as CustomEvent).detail }
      }));
    };
    document.addEventListener(`${PluginEvent.answersScoreChangeEvent}-${id}`, handleAnswersChange);
    document.addEventListener(`${PluginEvent.answersAnsweredChangeEvent}-${id}`, handleAnswersChange);
    document.addEventListener(`${PluginEvent.answersStateChangeEvent}-${id}`, handleAnswersChange);
    return () => {
      document.addEventListener(`${PluginEvent.answersScoreChangeEvent}-${id}`, handleAnswersChange);
      document.addEventListener(`${PluginEvent.answersAnsweredChangeEvent}-${id}`, handleAnswersChange);
      document.removeEventListener(`${PluginEvent.answersStateChangeEvent}-${id}`, handleAnswersChange);
    };
  }, [id, setElementState]);
}
