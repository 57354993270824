import { useEffect } from 'react';

import { GoToType, LinkTargetType, ObjectFit } from 'shared/types/Image';

import { Base } from './Base';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useNavigation } from '@/utils/useNavigation';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function Image(props: StudioProps) {
  const { initialize } = props;
  const { document, id } = useContentObject();
  const [src] = useContentObjectProperty<string>('src');
  const [mobileSrc] = useContentObjectProperty<string>('mobileSrc');
  const [alt] = useContentObjectProperty<string>('alt');
  const [objectFit] = useContentObjectProperty<ObjectFit>('objectFit');
  const [mobileObjectFit] = useContentObjectProperty<ObjectFit>('mobileObjectFit');
  const [goToLink] = useContentObjectProperty<string>('goToLink');
  const [goToLinkTitle] = useContentObjectProperty<string>('goToLinkTitle');
  const [goToPage] = useContentObjectProperty<string>('goToPage');
  const [goToPageTitle] = useContentObjectProperty<string>('goToPageTitle');
  const [goToType] = useContentObjectProperty<GoToType | null>('goToType');
  const [linkTargetType] = useContentObjectProperty<LinkTargetType>('linkTargetType');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const { getUpdatedRoute } = useNavigation();

  useEffect(() => {
    initialize?.({
      isResizableVertically: true,
      shouldKeepAspectRatio: true
    });
  }, [initialize]);

  const isPageGoToType = goToType === GoToType.Page;
  const isExternalLinkGoToType = goToType === GoToType.ExternalLink;
  const title = isPageGoToType ? goToPageTitle : goToLinkTitle;

  const goToPageHref = goToPage ? getUpdatedRoute({ page: goToPage }) : '';

  const baseProps = {
    src,
    alt,
    mobileSrc,
    objectFit,
    mobileObjectFit,
    isExternalLinkGoToType,
    goToLink: isExternalLinkGoToType ? goToLink : goToPageHref,
    linkTargetType
  };

  return (
    <div className={customClassNames} title={title}>
      <Base {...baseProps} />
    </div>
  );
}
