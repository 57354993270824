import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { ActionIcon, ColorPicker, ColorSwatch, Flex, Popover } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useStyles } from './TextColorPicker.styles';
import { Cross } from '@/icons/Cross';
import { useTextStyles } from '@/utils/tiptap/useTextStyles';

type TextColorPickerProps = {
  setColorPickerRef?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  portalTarget: HTMLDivElement | null;
  editor: Editor | null;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
};

const colors = [
  '#25262b',
  '#868e96',
  '#fa5252',
  '#e64980',
  '#be4bdb',
  '#7950f2',
  '#4c6ef5',
  '#228be6',
  '#15aabf',
  '#12b886',
  '#40c057',
  '#82c91e',
  '#fab005',
  '#fd7e14'
];

export function TextColorPicker(props: TextColorPickerProps) {
  const { portalTarget, editor, overrideTypographyStylesPath, changeContrastColorToMain } = props;

  const t = useTranslations('widgets.colorPicker');
  const { getTextStyles } = useTextStyles(editor);

  const [activeColor, setActiveColor] = useState(
    getTextStyles('color', {
      overrideTypographyStylesPath,
      changeContrastColorToMain
    })
  );
  const [isOpenSwatchColors, setIsOpenSwatchColors] = useState(false);
  const { classes } = useStyles();

  const toggleSwatchColors = useCallback(() => {
    setIsOpenSwatchColors(prevState => !prevState);
  }, []);

  useEffect(() => {
    const updateActiveColor = () => {
      const currentActiveColor = getTextStyles('color', {
        overrideTypographyStylesPath,
        changeContrastColorToMain
      });
      setActiveColor(currentActiveColor);
    };

    editor?.on('selectionUpdate', updateActiveColor);
    editor?.on('update', updateActiveColor);

    return () => {
      editor?.off('selectionUpdate', updateActiveColor);
      editor?.off('update', updateActiveColor);
    };
  }, [editor, getTextStyles, overrideTypographyStylesPath, changeContrastColorToMain]);

  const handleActiveColorChange = (color: string) => {
    editor?.commands.setColor(color);
  };

  return (
    <Popover
      label={`${t('title')}`}
      opened={isOpenSwatchColors}
      onChange={setIsOpenSwatchColors}
      onClick={toggleSwatchColors}
      portalProps={{
        // @ts-expect-error target type is different from React's ref
        target: portalTarget
      }}
      closeOnClickOutside
    >
      <Popover.Target>
        <ColorSwatch size={16} className={classes.popoverTarget} color={activeColor} />
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" align="flex-end">
          <ActionIcon
            variant="subtle"
            onClick={() => {
              setIsOpenSwatchColors(false);
            }}
            size="sm"
            color="gray"
          >
            <Cross />
          </ActionIcon>
          {isOpenSwatchColors && (
            <ColorPicker
              swatchesPerRow={7}
              swatches={colors}
              color={activeColor}
              onChange={color => {
                handleActiveColorChange(color);
                setIsOpenSwatchColors(false);
              }}
              withPicker={false}
              mb="lg"
              mt="md"
            />
          )}

          <ColorPicker value={activeColor} onChange={handleActiveColorChange} />
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
}
