import { useEffect } from 'react';

import { SubmissionHistory } from '@/components/SubmissionHistory';
import { Manifest } from '@/widgets/SummaryPageSubmissionHistory/Manifest';
import { StudioProps } from '@/widgets/types/studioProps';

export function SummaryPageSubmissionHistory(props: StudioProps) {
  const { initialize } = props;

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return <SubmissionHistory />;
}
