import { OpenQuestionElementType } from 'shared/types/OpenQuestion';

import { ElementsOptions } from './ElementsOptions';

type DesignProps = {
  elements: Record<OpenQuestionElementType, boolean>;
  onElementChange: (element: OpenQuestionElementType, state: boolean) => void;
};

export function Design(props: DesignProps) {
  const { elements, onElementChange } = props;

  return <ElementsOptions elements={elements} onElementChange={onElementChange} />;
}
