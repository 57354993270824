import { Box, Flex, Radio, rem, TextInput } from '@mantine/core';
import has from 'lodash/has';
import { useTranslations } from 'next-intl';

import { GoToType, LinkTargetType } from 'shared/types/Image';

import { Select } from '@/components/Select';
import { useCurrentDocument } from '@/components/YjsProvider';
import { usePages } from '@/contentObjects/Root/hooks/usePages';
import { ImageSchemaType } from '@/widgets/Image/Schema';
import { useContentObjectErrors, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

type GoToOptionProps = {
  goToType: GoToType;
  setGoToType: (goToType: GoToType) => void;
};

export function GoToOption(props: GoToOptionProps) {
  const { goToType, setGoToType } = props;

  const [errors] = useContentObjectErrors<ImageSchemaType>();
  const [goToLink, setGoToLink] = useContentObjectProperty<string>('goToLink');
  const [goToLinkTitle, setGoToLinkTitle] = useContentObjectProperty<string>('goToLinkTitle');

  const [goToPage, setGoToPage] = useContentObjectProperty<string>('goToPage');
  const [goToPageTitle, setGoToPageTitle] = useContentObjectProperty<string>('goToPageTitle');
  const [linkTargetType, setLinkTargetType] = useContentObjectProperty<LinkTargetType>('linkTargetType');

  const document = useCurrentDocument();
  const { visiblePages } = usePages(document);
  const t = useTranslations('widgets');

  const linkTargetOptions = [
    { value: LinkTargetType.SameWindow, label: t(`imageSettings.linkTargetType.sameWindow`) },
    { value: LinkTargetType.NewTab, label: t(`imageSettings.linkTargetType.inNewTab`) }
  ];

  const pagesDataArray = visiblePages.map((_, index) => {
    const pageIndex = `${index + 1}`;
    return { value: pageIndex, label: pageIndex };
  });

  return (
    <FormControl>
      <Radio.Group value={goToType} onChange={setGoToType as (value: string) => void}>
        <Box my={rem(20)}>
          <Flex my={rem(10)} direction="row" align="center">
            <Radio value={GoToType.Page} label={t('imageSettings.pageNumber')} />
            {goToType === GoToType.Page && (
              <Select
                w={rem(80)}
                ms={rem(10)}
                data={pagesDataArray}
                value={goToPage}
                onChange={pageIndex => setGoToPage(pageIndex as string)}
              />
            )}
          </Flex>

          {goToType === GoToType.Page && (
            <FormControl title={t('imageSettings.title')} withAsterisk={true}>
              <TextInput
                value={goToPageTitle}
                onChange={event => setGoToPageTitle(event.currentTarget.value)}
                placeholder={t('imageSettings.linkTitle') ?? ''}
                error={has(errors, 'goToPageTitle') && t('imageSettings.provideLinkTitleError')}
                data-testid="alternative-text-field"
              />
            </FormControl>
          )}
        </Box>

        <Box>
          <Flex direction="column">
            <Radio value={GoToType.ExternalLink} label={t('imageSettings.link')} />

            {goToType === GoToType.ExternalLink && (
              <Box mt={rem(10)}>
                <FormControl title={t('imageSettings.URL')} withAsterisk>
                  <TextInput
                    value={goToLink}
                    onChange={event => setGoToLink(event.currentTarget.value)}
                    placeholder={t('imageSettings.URL') ?? ''}
                    error={has(errors, 'goToLink') && t('imageSettings.provideUrlAddress')}
                    data-testid="goto-link-text-field"
                  />
                </FormControl>

                <FormControl title={t('imageSettings.title') ?? ''} withAsterisk>
                  <TextInput
                    value={goToLinkTitle}
                    onChange={event => setGoToLinkTitle(event.currentTarget.value)}
                    placeholder={t('imageSettings.linkTitle') ?? ''}
                    error={has(errors, 'goToLinkTitle') && t('imageSettings.provideLinkTitleError')}
                    data-testid="goto-link-text-field"
                  />
                </FormControl>

                <FormControl title={t('imageSettings.linkTarget')}>
                  <Select
                    data={linkTargetOptions}
                    value={linkTargetType}
                    onChange={newLinkType => setLinkTargetType(newLinkType as LinkTargetType)}
                  />
                </FormControl>
              </Box>
            )}
          </Flex>
        </Box>
      </Radio.Group>
    </FormControl>
  );
}
