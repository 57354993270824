import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles((theme, props: { isMultipleButtonOpen: boolean }) => {
  const { isMultipleButtonOpen } = props;
  const secondaryButtonStyle = theme.other.button.secondary;
  const globalSystemStyle = theme.other.global.system;
  const mode = isMultipleButtonOpen ? 'hovered' : 'standard';
  const bgColor = mode === 'standard' ? 'main' : 'contrast';
  const color = mode === 'standard' ? 'contrast' : 'main';
  const borderRadiusStyle = getThemeBorderRadiusStyle(secondaryButtonStyle.standard.borderRadius);

  return {
    multipleButtonContainer: {
      backgroundColor: secondaryButtonStyle[mode].backgroundColor ?? globalSystemStyle.colors.secondary.contrast,
      maxWidth: isMultipleButtonOpen ? rem(250) : rem(64),
      paddingInlineEnd: rem(32),
      ...borderRadiusStyle
    },
    openMultipleFilesButton: {
      backgroundColor: secondaryButtonStyle[mode].backgroundColor ?? globalSystemStyle.colors.secondary[bgColor],
      color: secondaryButtonStyle[mode].color ?? globalSystemStyle.colors.secondary[color],
      minWidth: rem(64),
      minHeight: rem(64),

      svg: {
        transform: `rotate(${isMultipleButtonOpen ? 45 : 0}deg)`
      }
    },
    multipleFilesButtons: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    }
  };
});
