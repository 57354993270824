import { Box } from '@mantine/core';
import cx from 'clsx';
import * as Y from 'yjs';

import { Feedbacks } from 'shared/types/Feedbacks';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { TiptapClozeOptionsProvider, useTiptapClozeOptionsStore } from './hooks/useTiptapClozeOptionsStore';
import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import {
  getQuestionBulletWrapperData,
  shouldShowCheckButton,
  shouldShowResetButton,
  shouldShowScoreWeight
} from '@/utils/questions.utils';
import { Player as FlexSectionPlayer } from '@/widgets/FlexSection/player';
import { useParentQuestionGroupEvaluation, useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';

export function ClozeQuestion() {
  return (
    <TiptapClozeOptionsProvider>
      <ClozeQuestionImplementation />
    </TiptapClozeOptionsProvider>
  );
}

function ClozeQuestionImplementation() {
  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [ownFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const { showHintButton, showAnswerButton, questionScoreMode } = useContentObjectEvaluationProps();
  const [scoreWeight] = useContentObjectScoreWeight();

  const [flexSectionId] = useContentObjectProperty<string>('children.0');

  const { document, id } = useContentObject();
  const isRequired = isQuestionRequired(id, document);

  const questionGroupSettings = useParentQuestionGroupSettings();
  const questionGroupEvaluation = useParentQuestionGroupEvaluation();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showCheckButton = shouldShowCheckButton(ownFeedback, questionGroupSettings);
  const showResetButton = shouldShowResetButton(questionGroupSettings);

  const handleShowAnswer = useTiptapClozeOptionsStore(state => state.handleShowAnswer);
  const handleResetAnswers = useTiptapClozeOptionsStore(state => state.handleResetAnswers);
  const handleEvaluate = useTiptapClozeOptionsStore(state => state.handleEvaluate);
  const selectedAnswers = useTiptapClozeOptionsStore(state => state.selectedAnswers);
  const feedbackMessageType = useTiptapClozeOptionsStore(state => state.feedbackMessageType);
  const grade = useTiptapClozeOptionsStore(state => state.grade);

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  return (
    <div className={customClassNames}>
      <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
        <Box className={cx(questionClasses.label, ThemeClassNames.widgets.clozeQuestion.label)}>
          {labelFragment && (
            <QuestionTitle
              questionId={id}
              fragment={labelFragment}
              editable={false}
              dataTestIdPrefix="preview-cloze-question-title"
            />
          )}
        </Box>
      </ListBulletWrapper>
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <FlexSectionPlayer />
        </ContentObjectProvider>
        <QuestionFooter
          questionId={id}
          feedbackMessageType={feedbackMessageType}
          hintFragment={hintFragment}
          isHintEditable={false}
          onCheck={() => handleEvaluate(selectedAnswers)}
          onReset={handleResetAnswers}
          onShowAnswer={handleShowAnswer}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={showAnswerButton && !feedbackMessageType}
          showCheckButton={showCheckButton}
          showHintButton={showHintButton && !feedbackMessageType}
          showResetButton={showResetButton}
          grade={grade || questionGroupEvaluation?.[id]?.grade}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
