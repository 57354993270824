import { Box } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

import { RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';

import { useCanUpsertDraftNoteToTeacher } from '@/components/CanProvider';
import { useSavedDraftNoteToTeacher, useSaveElementState } from '@/queries/state';
import { useStyles } from '@/widgets/SubmitPageComment/player/SubmitPageComment.styles';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { RichText } from '@/widgets/_components/RichText';

const tools = [
  RichTextTools.TextColor,
  RichTextTools.Underline,
  RichTextTools.Italic,
  RichTextTools.Bold,
  RichTextTools.TextHighlight
];

export function StudentComment() {
  const t = useTranslations('widgets.submitPageComment');

  const [settingsPlaceholder] = useContentObjectProperty<string>('placeholder');
  const placeholder = settingsPlaceholder || t('writeNoteToTeacher');

  const { classes } = useStyles();

  const [currentNoteToTeacherEditor, setCurrentNoteToTeacherEditor] = useState<Editor | null>(null);

  const canUpsertDraftNoteToTeacher = useCanUpsertDraftNoteToTeacher();

  const { data: draftNoteToTeacher } = useSavedDraftNoteToTeacher();
  const currentNoteToTeacher = draftNoteToTeacher;

  const { mutate: saveElementState } = useSaveElementState();

  useEffect(() => {
    if (currentNoteToTeacherEditor?.isEmpty && currentNoteToTeacher) {
      currentNoteToTeacherEditor?.commands.setContent(currentNoteToTeacher);
    }
  }, [currentNoteToTeacher, currentNoteToTeacherEditor]);

  const onDraftNoteToTeacherChange = useCallback(
    (newDraftNoteToTeacher: string) => {
      saveElementState({
        elements: [{ elementId: 'draftNoteToTeacher', state: newDraftNoteToTeacher }]
      });
    },
    [saveElementState]
  );

  return (
    <Box className={classes.comment}>
      <RichText
        dataTestIdPrefix="student-comment"
        placeholder={placeholder}
        editable={canUpsertDraftNoteToTeacher}
        onChange={onDraftNoteToTeacherChange}
        setEditor={setCurrentNoteToTeacherEditor}
        withBorder={true}
        tools={tools}
        toolbarType={ToolbarType.Inline}
      />
    </Box>
  );
}
