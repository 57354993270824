import { ClientModulesCreators } from '@hendrx/modules';

import { assets } from './assets';
import { components } from './components';
import { fonts } from './fonts';
import { metadata } from './metadata';
import { defaultActivityThemeStyles } from './themes';

export const createClientComponentsModule: ClientModulesCreators['ui'] = () => ({
  defaultActivityThemeStyles,
  metadata,
  assets,
  fonts,
  components
});
