import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type SelectableStylesProps = {
  isSelected?: boolean;
};

export const useStyles = createStyles((theme, props: SelectableStylesProps) => {
  const { isSelected } = props;

  return {
    selectable: {
      ...(isSelected && {
        outline: `${rem(2)} solid ${theme.colors.blue[5]}`
      })
    }
  };
});
