import { HeaderGroup } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { TableHeaderRow } from './TableHeaderRow';
import { TRow } from '@/widgets/Table/utils';

type TableHeaderProps = {
  headerGroups: HeaderGroup<TRow>[];
};

export const TableHead = memo(function TableHead(props: TableHeaderProps) {
  const { headerGroups } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.thead}>
      {headerGroups.map(headerGroup => (
        <TableHeaderRow key={headerGroup.id} headerGroup={headerGroup} />
      ))}
    </div>
  );
});
