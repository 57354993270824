import { WidgetType } from '../utils/widgets';

export enum LearningObjectTypes {
  LearningActivity = 'learningActivity',
  PlenaryLesson = 'plenaryLesson'
}

export const LearningObjectPages: Record<LearningObjectTypes, WidgetType.Page | WidgetType.Slide> = {
  [LearningObjectTypes.LearningActivity]: WidgetType.Page,
  [LearningObjectTypes.PlenaryLesson]: WidgetType.Slide
};
