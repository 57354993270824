import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Player() {
  const [status] = useContentObjectProperty<string>('status');
  const [avatarData] = useContentObjectProperty<object>('avatarData');
  return (
    <div>
      {status === 'ready' ? (
        <video controls style={{ maxWidth: '100%' }}>
          <source src={avatarData?.result_url} />
        </video>
      ) : null}
    </div>
  );
}
