import { ClientModulesCreators } from '@hendrx/modules';

import {
  canAccessAssignmentSubmitPage,
  canAccessAssignmentSummaryPage,
  canCreateAssignment,
  canEnlargeOpenDiscussionAnswer,
  canManageViewMode,
  canPrefillCorrectAnswers,
  canPrefillEvaluation,
  canReadAssignmentNotes,
  canReadDraftFeedbackForAssignment,
  canReadDraftNoteToTeacher,
  canReadQuestionGrade,
  canReadQuestionStatus,
  canReadSatisfactionScore,
  canReadSavedState,
  canReadStudentAssignedMode,
  canReadStudentLearningViewMode,
  canReadStudentTestViewMode,
  canReadSubmissionHistory,
  canReadTeacherSolvedMode,
  canRevealAnswers,
  canSaveCanvas,
  canSeeCanvas,
  canSeeFeedback,
  canSeePlenaryLessonCode,
  canSeePresenterActionBar,
  canSeeQuestionCheckButton,
  canSeeQuestionEvaluation,
  canSeeQuestionHintButton,
  canSeeQuestionResetButton,
  canSeeQuestionScoreWeight,
  canSeeQuestionShowAnswerButton,
  canSeeReturnToFixSwitch,
  canSeeSatisfactionSurvey,
  canSeeSlidesNavigation,
  canSeeUnansweredQuestions,
  canSubmitAssignment,
  canSubmitFeedbackForAssignment,
  canSyncSlideNumber,
  canUpdateLocalState,
  canUpdateSavedState,
  canUpsertDraftFeedbackForAssignment,
  canUpsertDraftNoteToTeacher,
  canUpsertQuestionGrade,
  canUpsertSatisfactionScore
} from './lib/rules';

export const createClientAuthorizationModule: ClientModulesCreators['authorization'] = () => ({
  canAccessAssignmentSubmitPage,
  canAccessAssignmentSummaryPage,
  canCreateAssignment,
  canManageViewMode,
  canPrefillCorrectAnswers,
  canPrefillEvaluation,
  canReadAssignmentNotes,
  canReadDraftFeedbackForAssignment,
  canReadDraftNoteToTeacher,
  canReadQuestionGrade,
  canReadQuestionStatus,
  canReadSatisfactionScore,
  canReadSavedState,
  canReadStudentAssignedMode,
  canReadStudentLearningViewMode,
  canReadStudentTestViewMode,
  canReadSubmissionHistory,
  canSeeFeedback,
  canSeeQuestionCheckButton,
  canSeeQuestionEvaluation,
  canSeeQuestionHintButton,
  canSeeQuestionResetButton,
  canSeeQuestionScoreWeight,
  canSeeQuestionShowAnswerButton,
  canSeeReturnToFixSwitch,
  canSeeSatisfactionSurvey,
  canSeeUnansweredQuestions,
  canSubmitAssignment,
  canSubmitFeedbackForAssignment,
  canUpdateLocalState,
  canUpdateSavedState,
  canUpsertDraftFeedbackForAssignment,
  canUpsertDraftNoteToTeacher,
  canUpsertQuestionGrade,
  canUpsertSatisfactionScore,
  canEnlargeOpenDiscussionAnswer,
  canReadTeacherSolvedMode,
  canRevealAnswers,
  canSaveCanvas,
  canSeeCanvas,
  canSeePlenaryLessonCode,
  canSeePresenterActionBar,
  canSeeSlidesNavigation,
  canSyncSlideNumber
});
