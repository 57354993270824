import "../../../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fwidgets%2FOpenDiscussion%2Fplayer%2FTeacherView%2FTeacherView.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3VTzY7aMBC%2B8xRTVUjswZEDGxKMVKlS1WforTKJE0Y4NrKHAFv13Ss7SSHA3pyMv7%2BZcfI7zWu8Vo7DnxnAXmGzJwGl1OXiPXOqBQarJBze4Au2R%2BtIGtrO%2Fs6SEZlGZK3VRUC6nQHYTrla27OAPVaVMuHfUVYVmobtLJFtBaROtROWZWRppWvQMDQajWKepCMBxXz7VFKmErCc31M%2FV85Y0V5AwedTW%2FJEdiK%2BEvtQjRZ2sjw0zp5MxUqrrRPwNcsz%2FvP7Z%2FnfI6w8OR8ut7ZTk3IWyx163KFGuk66YtGQckx1ypAXYKyZgtcRPCYkexTAnzMLWCXpMostDY26sHGOmxD9ji%2BPfFJjYxiSar2AUgULATjE7aRbsD67Z%2F7qSbU%2FpDuw4i1cqq0h5vFDCUiTqDnpy3DhPOivOX8oa0UhsT%2FKEk0jgCecp0We9d7jBEfzafKCntSFWAxwb32YdMof8haT%2Fu20LQ9BMls%2FO79DbSJq5EyK%2FIWPm8mX5aP1SGiNAKe0JAxLAeB6DLuNa6L6DXzXTLR5v7r%2FxR7zyft8w258MDRVfIyc83EhBsJNz6dVTQKy%2FoOcNL62rhX9UUtSvxYs4%2FO3idauf6FobtuVrPIhxj%2FVr45qSAQAAA%3D%3D%22%7D"
export var addButton = '_17fiydr3';
export var boardContainer = '_17fiydr1';
export var extendedContainer = '_17fiydr2';
export var hidden = '_17fiydr5';
export var modalBody = '_17fiydrb';
export var modalCloseButton = '_17fiydr9';
export var modalContent = '_17fiydr6';
export var modalHeader = '_17fiydr8';
export var modalInner = '_17fiydra';
export var modalTitle = '_17fiydr7';
export var move = '_17fiydr4';
export var teacherViewContainer = '_17fiydr0';