import { Flex } from '@mantine/core';
import { useCallback } from 'react';
import * as Y from 'yjs';

import { useCanSeeSatisfactionSurvey, useCanUpsertSatisfactionScore } from '@/components/CanProvider';
import { SatisfactionScore } from '@/components/SatisfactionSurvey';
import { SatisfactionSurvey } from '@/components/SatisfactionSurvey';
import { useGetAssignmentStatus } from '@/queries/assignment';
import { useSaveElementState, useSavedSatisfactionScore } from '@/queries/state';
import { Base as TextPlayerBase } from '@/widgets/Text/player/Base';
import { useContentObjectStaticProperty } from '@/widgets/_components/ContentObjectProvider';

export function SubmitPageSatisfactionSurvey() {
  const titleFragment = useContentObjectStaticProperty<Y.XmlFragment>('fragment');

  const canUpsertSatisfactionScore = useCanUpsertSatisfactionScore();

  const { data: satisfactionScore } = useSavedSatisfactionScore();
  const { mutate: saveElementState } = useSaveElementState();
  const { isReturnedToFix } = useGetAssignmentStatus({ enabled: true });

  const handleSatisfactionScoreChange = useCallback(
    (satisfactionScore: SatisfactionScore | null) => {
      saveElementState({ elements: [{ elementId: 'satisfactionScore', state: satisfactionScore }] });
    },
    [saveElementState]
  );

  const canSeeSatisfactionSurvey = useCanSeeSatisfactionSurvey();

  if (isReturnedToFix || !canSeeSatisfactionSurvey) {
    return null;
  }

  return (
    <SatisfactionSurvey
      disabled={!canUpsertSatisfactionScore}
      onChange={handleSatisfactionScoreChange}
      title={
        <Flex justify="center">
          <TextPlayerBase fragment={titleFragment} />
        </Flex>
      }
      value={satisfactionScore}
    />
  );
}
