import { QuestionScoreMode, RelativeScoreMode } from 'shared/types/QuestionScoreMode';
import { WidgetType } from 'shared/utils/widgets';

import { QuestionData } from '@/components/ScoreWeightSummary/hooks/useQuestions';

export const getQuestionNumbering = (allTopLevelQuestions: QuestionData[]): Record<string, number | null> => {
  const numberedQuestionIds: string[] = [];
  const unnumberedQuestionIds: string[] = [];

  allTopLevelQuestions.forEach(question => {
    if (question.type === WidgetType.QuestionGroupList) {
      const subQuestions = question.children ?? [];
      if (question.scoreMode === QuestionScoreMode.WithScore) {
        if (question.relativeScoreMode === RelativeScoreMode.RelativeToActivity) {
          unnumberedQuestionIds.push(question.id);
          subQuestions.forEach(subQuestion => {
            if (subQuestion.scoreMode === QuestionScoreMode.WithScore) {
              numberedQuestionIds.push(subQuestion.id);
            } else {
              unnumberedQuestionIds.push(subQuestion.id);
            }
          });
        } else {
          numberedQuestionIds.push(question.id);
          unnumberedQuestionIds.push(...subQuestions.map(subQuestion => subQuestion.id));
        }
      } else {
        unnumberedQuestionIds.push(question.id, ...subQuestions.map(subQuestion => subQuestion.id));
      }
    } else {
      if (question.scoreMode === QuestionScoreMode.WithScore) {
        numberedQuestionIds.push(question.id);
      } else {
        unnumberedQuestionIds.push(question.id);
      }
    }
  });

  const questionNumbering: Record<string, number | null> = {};
  numberedQuestionIds.forEach((questionId, index) => {
    const questionNumber = index + 1;
    questionNumbering[questionId] = questionNumber;
  });
  unnumberedQuestionIds.forEach(questionId => {
    questionNumbering[questionId] = null;
  });

  return questionNumbering;
};
