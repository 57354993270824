import { useEffect } from 'react';
import * as Y from 'yjs';

import { useTextTools } from 'shared/richTextToolbar/useTextTools';
import { TextTypes } from 'shared/types/Text';
import { WidgetType } from 'shared/utils/widgets';

import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useTextWidgetStyles } from '@/utils/typographyStyles.utils';
import { Manifest } from '@/widgets/Text/Manifest';
import { Config } from '@/widgets/Text/config';
import { Base } from '@/widgets/Text/studio/Base';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { StudioProps } from '@/widgets/types/studioProps';

export function Text(props: StudioProps) {
  const { initialize } = props;

  const { document, id } = useContentObject();
  const [placeholder] = useContentObjectProperty<string>('placeholder');
  const [fragment] = useContentObjectProperty<Y.XmlFragment>('fragment');
  const [textType] = useContentObjectProperty<TextTypes>('textType');
  const [widgetType] = useContentObjectProperty<WidgetType>('type');
  const [withParagraphOption] = useContentObjectProperty<boolean>('withParagraphOption');

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);
  const widgetStyles = useTextWidgetStyles(id);

  const isStandaloneTextWidget = widgetType === WidgetType.Text;

  const [tools] = useTextTools({ textType, withParagraphOption, withCssTool: isStandaloneTextWidget });

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      <div className={customClassNames}>
        <Base tools={tools} fragment={fragment} placeholder={placeholder} widgetStyles={widgetStyles} />
      </div>
    </>
  );
}
