import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewInteractiveQuizData } from 'shared/widgetsSDK/InteractiveQuiz/create';
import { manifestLocales } from 'shared/widgetsSDK/InteractiveQuiz/manifest.locales';

import IconEn from './resources/en/icon.svg';
import IconHe from './resources/he/icon.svg';
import { Quiz } from '@/icons/Quiz';

export const Manifest: ManifestType = {
  name: WidgetType.InteractiveQuiz,
  previewGroups: [
    {
      id: 'interactiveQuiz',
      divider: {
        title: 'interactiveQuiz',
        icon: Quiz
      },
      previews: [
        {
          name: 'imagePreview',
          previewImage: {
            paths: {
              en: IconEn,
              he: IconHe
            },
            size: {
              width: 140,
              height: 108
            },
            alt: { ...manifestLocales.interactiveQuiz.previewImageAlt }
          },
          initializeProps: createNewInteractiveQuizData,
          dimensions: {
            width: 800,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minWidth: 760,
      minHeight: 260
    }
  }
};
