import * as Y from 'yjs';

import { genRandId } from './genRandId';
import { WidgetType } from './widgets';
import { createNewSectionData } from '../widgetsSDK/Section/create';
import { createNewSummaryPageSubmissionHistory } from '../widgetsSDK/SummaryPageSubmissionHistory/create';
import { createNewSummaryPageTaskSubmittedNotification } from '../widgetsSDK/SummaryPageTaskSubmittedNotification/create';
import { createContentObject } from '../widgetsSDK/contentObjectOperations';
import { validateYArray } from '../widgetsSDK/yjs';

export function createSummaryPage(document: Y.Doc) {
  const { id, pageData } = createNewSummaryPageData();

  document.transact(() => {
    const [{ id: notificationId, contentObjectData: notificationData }] =
      createNewSummaryPageTaskSubmittedNotification();
    const [{ id: historyId, contentObjectData: historyData }] = createNewSummaryPageSubmissionHistory();

    createContentObject(document, id, pageData);

    createContentObject(document, notificationId, notificationData);
    createContentObject(document, historyId, historyData);

    const [notificationSectionData, notificationSectionId] = createNewSectionData(id, [
      { id: notificationId, span: 12, column: 1, height: 'auto' }
    ]);
    const [historySectionData, historySectionId] = createNewSectionData(id, [
      { id: historyId, span: 12, column: 1, height: 'auto' }
    ]);

    createContentObject(document, notificationSectionId, notificationSectionData);
    createContentObject(document, historySectionId, historySectionData);

    validateYArray(pageData.get('children')).push([notificationSectionId, historySectionId]);
  });
}

function createNewSummaryPageData() {
  const id = genRandId();
  return {
    id,
    pageData: new Y.Map([
      ['id', id],
      ['type', WidgetType.SummaryPage],
      ['style', new Y.Map()],
      ['children', new Y.Array()]
    ])
  };
}
