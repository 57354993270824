import { Box } from '@mantine/core';
import { useEffect } from 'react';

import { useStyles } from '@/widgets/Spacer/player/Player.styles';
import { StudioProps } from '@/widgets/types/studioProps';
export function Spacer(props: StudioProps) {
  const { initialize } = props;
  const { classes } = useStyles();

  useEffect(() => {
    initialize?.({
      isResizableVertically: true
    });
  }, [initialize]);

  return <Box className={classes.spacer} />;
}
