import { createContext, ReactElement, useCallback, useContext, useMemo } from 'react';
import * as Y from 'yjs';
import { createStore, StoreApi, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { Feedbacks } from 'shared/evaluation/feedback';
import { WidgetType } from 'shared/utils/widgets';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { getProperFeeback } from '@/utils/questions.utils';
import { ClozeOptionType } from 'shared/types/ClozeQuestion';
import {
  useParentQuestionGroupAction,
  useParentQuestionGroupEvaluation,
  useParentQuestionGroupSettings
} from '@/widgets/QuestionGroupList/context';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import {
  useEvaluatableQuestionAnswers,
  UseEvaluatableQuestionAnswersProps
} from '@/widgets/_components/questions/hooks/useEvaluatableQuestionAnswers';

type TiptapClozeOptionsProviderProps = {
  children: ReactElement;
};

type TiptapClozeOptionsStore = ReturnType<typeof useEvaluatableQuestionAnswers<WidgetType.ClozeQuestion>>;

const TiptapClozeOptionsStoreContext = createContext<StoreApi<TiptapClozeOptionsStore> | null>(null);

export function TiptapClozeOptionsProvider(props: TiptapClozeOptionsProviderProps) {
  const { children } = props;
  const [ownFeedback] = useContentObjectProperty<`${Feedbacks}`>('feedback');
  const questionGroupSettings = useParentQuestionGroupSettings();
  const feedback = getProperFeeback(ownFeedback, questionGroupSettings?.feedback);
  const [clozeOptions] = useContentObjectProperty<Y.Map<Y.Map<unknown>>>('clozeOptions');
  const allAnswerOptions: Y.Map<unknown>[] = useMemo(() => Array.from(clozeOptions.values()), [clozeOptions]);
  const questionGroupAction = useParentQuestionGroupAction();
  const { document, id } = useContentObject();
  const isRequired = isQuestionRequired(id, document);
  const questionGroupEvaluation = useParentQuestionGroupEvaluation();

  const getCorrectAnswers: UseEvaluatableQuestionAnswersProps<WidgetType.ClozeQuestion>['getCorrectAnswers'] =
    useCallback(
      result => {
        const { inputCorrectOptions, selectCorrectOptions } = result ?? {};
        return Array.from(allAnswerOptions.values()).reduce<Record<string, string>>((correctAnswers, optionYmap) => {
          const clozeId = optionYmap.get('id') as string;
          const type = optionYmap.get('type') as string;
          let correctOptionValue = null;

          if (inputCorrectOptions && type === ClozeOptionType.Input) {
            const correctOptions = inputCorrectOptions[clozeId];
            correctOptionValue = correctOptions[0].value;
          }
          if (selectCorrectOptions && type === ClozeOptionType.Select) {
            correctOptionValue = selectCorrectOptions[clozeId].value;
          }

          if (!correctOptionValue) {
            return correctAnswers;
          }

          return { ...correctAnswers, [clozeId]: correctOptionValue };
        }, {});
      },
      [allAnswerOptions]
    );

  const getCorrectStatuses: UseEvaluatableQuestionAnswersProps<WidgetType.ClozeQuestion>['getCorrectStatuses'] =
    useCallback((correctAnswersOptions: ReturnType<typeof getCorrectAnswers> = {}) => {
      return Object.keys(correctAnswersOptions).reduce((statuses, answer) => {
        statuses[answer] = true;
        return statuses;
      }, {} as Record<string, boolean>);
    }, []);

  const defaultState = useMemo(() => ({}), []);

  const { choiceQuestionsEnabled } = questionGroupSettings ?? {};
  const initialValues = useEvaluatableQuestionAnswers<WidgetType.ClozeQuestion>({
    feedback,
    getCorrectAnswers,
    defaultState,
    getCorrectStatuses,
    questionGroupAction,
    questionGroupEvaluation,
    isRequired: isRequired || !choiceQuestionsEnabled
  });

  const tiptapClozeOptionsStore = useMemo(
    () => createStore(subscribeWithSelector<TiptapClozeOptionsStore>(set => initialValues)),
    [initialValues]
  );

  return (
    <TiptapClozeOptionsStoreContext.Provider value={tiptapClozeOptionsStore}>
      {children}
    </TiptapClozeOptionsStoreContext.Provider>
  );
}

export function useTiptapClozeOptionsStore<T = TiptapClozeOptionsStore>(
  selector: (state: TiptapClozeOptionsStore) => T
) {
  const store = useContext(TiptapClozeOptionsStoreContext);
  if (store === null) {
    throw new Error('Context setup is incorrect');
  }

  return useStore(store, selector);
}
