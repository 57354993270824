import * as Y from 'yjs';

import { DEFAULT_ANSWERS_AMOUNT, defaultAnswerConfig } from './consts';
import { manifestLocales } from './manifest.locales';
import { AnswerStyleType } from '../../types/AnswerStyleType';
import { ChartType } from '../../types/ChartType';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { createQuestionWidgetChildren } from '../../utils/createQuestionWidgetChildren';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { initializeAnswers } from '../../utils/initializeAnswers';
import { WidgetType } from '../../utils/widgets';
import { createNewInteractiveQuizAnswerData } from '../InteractiveQuizAnswer/create';

export function createNewInteractiveQuizData(options?: InitializePropsOptions): ManifestInitializedProps {
  const id = genRandId();
  const locale = options?.locale ?? 'he';
  const { initialHeadlineText } = manifestLocales.interactiveQuiz;
  const labelFragment = htmlToXmlFragment(`<h2>${initialHeadlineText[locale]}</h2>`);
  const [answers] = initializeAnswers(defaultAnswerConfig, DEFAULT_ANSWERS_AMOUNT);

  const [questionAnswerData] = createNewInteractiveQuizAnswerData();
  const { childrenArray, horizontalFlexSectionData, verticalFlexSectionData } = createQuestionWidgetChildren(
    questionAnswerData.id,
    options?.width
  );

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.InteractiveQuiz],
        ['children', childrenArray],
        ['labelFragment', labelFragment],
        ['answers', answers],
        ['amountOfColumns', 1],
        ['answerElements', ['Text']],
        ['answersStyle', AnswerStyleType.Default],
        ['answersVisualization', ChartType.HorizontalBarChart],
        ['questionAnswerId', questionAnswerData.id]
      ]),
      sensitiveData: new Y.Map([['correctAnswers', []]])
    },
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  ];
}
