import { useMemo } from 'react';

import { DeviceType } from 'shared/types/DeviceType';

import { useDeviceView } from '@/utils/deviceHooks';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useColumnsForCurrentDevice() {
  const { deviceView } = useDeviceView();
  const [amountOfColumns] = useContentObjectProperty<number>('amountOfColumns') ?? 1;
  const numberOfColumns: Record<DeviceType, number> = useMemo(
    () => ({ mobile: 1, tablet: Math.min(amountOfColumns, 2), pc: amountOfColumns }),
    [amountOfColumns]
  );

  return numberOfColumns[deviceView];
}
