export enum QuestionListBulletTypes {
  Number = 'Number',
  Letter = 'Letter',
  Triangle = 'Triangle',
  None = 'None'
}

export enum ParentHeadline {
  Manual = 'manual',
  Automatic = 'automatic',
  None = 'none'
}

export enum GroupCheckButtonMode {
  GeneralButton = 'generalButton',
  OnEachQuestion = 'onEachQuestion'
}
