import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useStyles } from './Studio.styles';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useBasicValidation } from '@/utils/validation';
import { Manifest } from '@/widgets/Embed/Manifest';
import { Schema } from '@/widgets/Embed/Schema';
import { Config } from '@/widgets/Embed/config';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';
import { StudioProps } from '@/widgets/types/studioProps';

export function Embed(props: StudioProps) {
  const { initialize } = props;
  const { classes, cx } = useStyles();

  const { data, document, id } = useContentObject();
  const [title] = useContentObjectProperty<string>('title');
  const [src] = useContentObjectProperty<string>('src');
  const t = useTranslations('widgets');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      shouldKeepAspectRatio: true,
      isResizableVertically: true
    });
  }, [initialize]);

  useBasicValidation(id, document, data, Schema);

  return (
    <>
      <SideMenuPortal>
        <Config />
      </SideMenuPortal>
      <Box className={cx(classes.container, customClassNames)}>
        <EmbedIframe placeholderText={`${t('embedWidget.defaultText')}`} workMode="Edit" title={title} src={src} />
      </Box>
    </>
  );
}
