import uniq from 'lodash/uniq';
import { z } from 'zod';

import { ClozeOptionType } from 'shared/types/ClozeQuestion';

import { trimUnknown } from '@/utils/trimString';

const selectPossibleOptionSchema = z.object({
  id: z.string().nonempty(),
  value: z.preprocess(trimUnknown, z.string().nonempty())
});

const clozeOptionSchema = z.object({
  id: z.string().nonempty(),
  type: z.nativeEnum(ClozeOptionType)
});

export const Schema = z.object({
  clozeOptions: z.record(clozeOptionSchema),
  selectPossibleOptions: z.record(z.array(selectPossibleOptionSchema)).superRefine((obj, ctx) => {
    for (const key in obj) {
      const values = obj[key].map(item => item.value);
      if (values.length !== uniq(values).length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Select cloze options must be unique values.',
          path: [key]
        });
      }
    }
  })
});

export type ClozeSchemaType = z.infer<typeof Schema>;
