import { IMAGE_MIME_TYPE } from '@mantine/dropzone';

import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import { manifestLocales } from './manifest.locales';
import ImageEn from './resources/en/image.svg';
import ImageHe from './resources/he/image.svg';
import { ImageObject } from '@/icons/ImageObject';

export const Manifest: ManifestType = {
  name: WidgetType.Image,
  previewGroups: [
    {
      id: 'image',
      divider: {
        icon: ImageObject,
        title: 'image'
      },
      previews: [
        {
          name: 'imagePreview',
          previewImage: {
            paths: {
              en: ImageEn,
              he: ImageHe
            },
            size: {
              width: 285,
              height: 140
            },
            alt: { ...manifestLocales.upload.previewImageAlt }
          },
          initializeProps: widgetsCreateFunctions[WidgetType.Image] as ManifestPreviews['initializeProps'],
          dimensions: {
            width: 400,
            height: 260
          }
        }
      ]
    }
  ],
  additionalData: {
    maxFileSizeBytes: 1_024_000,
    acceptFileType: IMAGE_MIME_TYPE,
    maxFiles: 1,
    constraints: {
      minWidth: 76,
      minHeight: 76
    }
  }
};
