import { AnswerAreaThemeProps, BasicThemeProps, BorderThemeProps, GlobalColorThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { CSSObject, createStyles } from '@mantine/emotion';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';
import { DeviceType } from 'shared/types/DeviceType';

import { SelectedAnswerStatus } from './Answer';
import { AnswerStyles } from '@/components/AnswerOption/types';
import { getThemeBorderRadiusStyle, getThemeBorderStyle, PROSE_MIRROR_SELECTOR } from '@/utils/theme.utils';

type AnswerStylesProps = {
  answerAreaThemeProps: AnswerAreaThemeProps;
  answersStyleType: AnswerStyleType;
  checkboxThemeProps: BasicThemeProps;
  selectedCheckboxThemeProps: GlobalColorThemeProps & { border: BorderThemeProps };
  isChecked?: boolean;
  disabled?: boolean;
  isTextOnly?: boolean;
  status?: SelectedAnswerStatus;
  deviceView?: DeviceType;
};

export const useStyles = createStyles((theme, props: AnswerStylesProps) => {
  const {
    answerAreaThemeProps,
    answersStyleType,
    checkboxThemeProps,
    deviceView,
    disabled,
    isChecked,
    isTextOnly,
    selectedCheckboxThemeProps,
    status
  } = props;

  const isCorrect = status === 'correct';
  const isIncorrect = status === 'incorrect';
  const isMobile = deviceView === 'mobile';

  const globalContentColorsTheme = theme.other.global.content.colors;

  const selectedCheckboxMainColor =
    selectedCheckboxThemeProps.backgroundColor ?? globalContentColorsTheme.primary.contrast;

  const getLeadingColor = (prop: string, type?: string) => {
    if (isCorrect) return theme.colors.green[6];
    if (isIncorrect) return theme.colors.pink[6];
    if (isChecked)
      return prop === 'background'
        ? selectedCheckboxMainColor
        : type === 'label'
        ? selectedCheckboxThemeProps.border.borderColor ?? globalContentColorsTheme.decoration.main
        : selectedCheckboxThemeProps.border.borderColor ?? globalContentColorsTheme.decoration.main;
    return prop === 'background'
      ? checkboxThemeProps.backgroundColor ?? globalContentColorsTheme.secondary.main
      : type === 'label'
      ? answerAreaThemeProps.border.borderColor ?? globalContentColorsTheme.decoration.main
      : checkboxThemeProps.border.borderColor ?? globalContentColorsTheme.decoration.main;
  };

  const checkboxBorderStyle = getThemeBorderStyle(
    isChecked ? selectedCheckboxThemeProps.border : checkboxThemeProps.border,
    globalContentColorsTheme.decoration.main,
    {
      borderColor: getLeadingColor('border')
    }
  );
  const checkboxBorderRadiusStyle = getThemeBorderRadiusStyle(checkboxThemeProps.borderRadius);

  const borderStyle = getThemeBorderStyle(answerAreaThemeProps.border, globalContentColorsTheme.decoration.main, {
    borderColor: getLeadingColor('border', 'label')
  });
  const borderRadiusStyle = getThemeBorderRadiusStyle(answerAreaThemeProps.borderRadius);
  const color = answerAreaThemeProps.color ?? globalContentColorsTheme.secondary.contrast;
  const lineHeight = answerAreaThemeProps.lineHeight;

  const inputStyles: CSSObject = {
    ...checkboxBorderStyle,
    ...checkboxBorderRadiusStyle,
    backgroundColor: checkboxThemeProps.backgroundColor ?? globalContentColorsTheme.secondary.main,
    '&:checked': {
      ...checkboxBorderStyle,
      ...checkboxBorderRadiusStyle,
      backgroundColor: getLeadingColor('background')
    }
  };

  const overrideAnswerStyle: Record<AnswerStyleType, AnswerStyles> = {
    Default: {
      checkboxLabel: {
        ...(isChecked && !isCorrect && !isIncorrect && { borderColor: selectedCheckboxMainColor })
      }
    },
    Button: {
      checkboxBody: {
        position: 'relative'
      },
      checkboxInner: {
        position: 'absolute',
        insetInlineEnd: 0,
        top: isTextOnly ? '50%' : 0,
        transform: 'translateY(-50%)',
        marginInline: rem(10),
        zIndex: 1,
        opacity: status ? 1 : 0
      },
      checkboxInput: {
        borderRadius: 9999
      },
      checkboxLabel: {
        ...(isTextOnly && { paddingInlineEnd: `${rem(40)} !important` }),
        ...(isChecked && {
          backgroundColor: theme.colors.accent[0],
          outlineWidth: rem(2),
          outlineColor: theme.colors.accent[6],
          outlineStyle: 'solid'
        }),
        ...(isCorrect && { backgroundColor: theme.colors.green[isMobile ? 0 : 1] }),
        ...(isIncorrect && { backgroundColor: isMobile ? theme.colors.pink[0] : theme.colors.red[1] }),
        ...(isMobile && isCorrect && { borderColor: theme.colors.success[0] })
      }
    },
    CheckboxWithoutBorder: {
      checkboxLabel: { border: 'none' }
    }
  };

  return {
    checkboxLabelWrapper: {
      flexGrow: 1
    },
    checkboxBody: {
      ...overrideAnswerStyle[answersStyleType].checkboxBody
    },
    checkboxLabel: {
      ...borderStyle,
      ...borderRadiusStyle,
      [PROSE_MIRROR_SELECTOR]: { color, lineHeight },
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: disabled ? '.6' : '1',
      padding: 0,
      ...overrideAnswerStyle[answersStyleType].checkboxLabel
    },
    checkboxInput: {
      ...inputStyles,
      cursor: disabled ? 'not-allowed' : 'pointer',
      ...overrideAnswerStyle[answersStyleType].checkboxInput
    },
    radioInner: {
      input: {
        ...inputStyles,
        borderRadius: '50%',
        ...overrideAnswerStyle[answersStyleType].checkboxInput,

        '&:checked': {
          borderRadius: '50%'
        }
      }
    },
    checkboxInner: {
      ...overrideAnswerStyle[answersStyleType].checkboxInner
    },
    checkboxRoot: {
      ...overrideAnswerStyle[answersStyleType].checkboxRoot
    },
    contentArea: {
      ...overrideAnswerStyle[answersStyleType].contentArea
    }
  };
});
