import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { Box } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import Collaboration from '@tiptap/extension-collaboration';
import { Document } from '@tiptap/extension-document';
import { Placeholder } from '@tiptap/extension-placeholder';
import { AnyExtension, Editor, useEditor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import xss from 'xss';
import * as Y from 'yjs';

import { RichTextExtraTools, RichTextTools, ToolbarType } from 'shared/types/RichTextToolbar';

import { useStyles } from './RichText.styles';
import { getDefaultExtensions } from '@/utils/tiptap';
import { CustomTextAlign } from '@/utils/tiptap/extensions/customTextAlign';
import { BlockTitleWidgetStyles, TextWidgetStyles } from '@/utils/typographyStyles.utils';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { RichTextEditorFixed } from '@/widgets/_components/RichTextEditorFixed';
import { RichTextEditorInline } from '@/widgets/_components/RichTextEditorInline';

const defaultExtraExtensions: AnyExtension[] = [];

const HeadingDocument = Document.extend({
  content: 'heading+'
});

export type RichTextProps = {
  dataTestIdPrefix?: string;
  fragment?: Y.XmlFragment;
  placeholder?: string;
  htmlContent?: string;
  contentEditable?: boolean;
  editable?: boolean;
  extraExtensions?: AnyExtension[];
  toolbarType?: ToolbarType;
  tools?: (RichTextTools | RichTextExtraTools)[];
  customControls?: ReactNode;
  className?: string;
  toolbarClassName?: string;
  withBorder?: boolean;
  expandHeight?: true;
  preserveWhitespace?: true | 'full';
  widgetStyles?: TextWidgetStyles | BlockTitleWidgetStyles;
  disableTypographyInheritance?: boolean;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
  changeContrastColorToMain?: boolean;
  onChange?: (newValue: string) => void;
  setEditor?: (editor: Editor) => void;
};
export function RichText(props: RichTextProps) {
  const {
    preserveWhitespace,
    dataTestIdPrefix = '',
    contentEditable = true,
    editable = true,
    extraExtensions = defaultExtraExtensions,
    tools = Object.values(RichTextTools),
    toolbarType = ToolbarType.None,
    fragment,
    htmlContent,
    placeholder = '',
    expandHeight,
    widgetStyles,
    disableTypographyInheritance,
    overrideTypographyStylesPath,
    changeContrastColorToMain,
    withBorder = false,
    className,
    onChange,
    setEditor
  } = props;

  const { contentDirection } = useContentLanguage();
  const defaultAlignment = contentDirection === 'ltr' ? 'left' : 'right';
  const { classes, cx } = useStyles({
    expandHeight,
    widgetStyles,
    disableTypographyInheritance,
    overrideTypographyStylesPath,
    changeContrastColorToMain
  });

  const extensions = useMemo(() => {
    return [
      ...getDefaultExtensions({ useNativeHistory: !fragment }),
      ...extraExtensions,
      CustomTextAlign.configure({ types: ['heading', 'paragraph'], defaultAlignment }),
      Placeholder.configure({
        placeholder,
        showOnlyWhenEditable: false
      }),
      ...(fragment
        ? [
            Collaboration.configure({
              fragment
            })
          ]
        : [])
    ];
  }, [defaultAlignment, extraExtensions, placeholder, fragment]);

  const editor = useEditor(
    {
      parseOptions: {
        preserveWhitespace
      },
      editable: editable ? contentEditable : false,
      extensions: tools.includes(RichTextExtraTools.TextLevelOnly) ? [...extensions, HeadingDocument] : extensions,
      onUpdate: ({ editor }) => {
        onChange?.(editor.getHTML());
      },
      content: htmlContent
    },
    [extensions, htmlContent]
  );

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (editable && setEditor && editor) {
      setEditor(editor);
    }
  }, [editable, editor, setEditor]);

  const t = useTranslations('widgets.richTextToolbar');

  const labels = useMemo(
    () => ({
      alignCenterControlLabel: t('alignCenter'),
      alignLeftControlLabel: t('alignLeft'),
      alignRightControlLabel: t('alignRight'),
      blockquoteControlLabel: t('blockquote'),
      boldControlLabel: t('bold'),
      clearFormattingControlLabel: t('clearFormatting'),
      codeControlLabel: t('code'),
      hrControlLabel: t('hr'),
      italicControlLabel: t('italic'),
      linkControlLabel: t('link'),
      strikeControlLabel: t('strikethrough'),
      underlineControlLabel: t('underline'),
      unlinkControlLabel: t('unlink')
    }),
    [t]
  );

  return (
    <Box
      sx={{
        'p[dir=ltr], h1[dir=ltr], h2[dir=ltr], h3[dir=ltr], h4[dir=ltr], h5[dir=ltr], h6[dir=ltr]': {
          direction: ('ltr' + ' !important') as 'ltr' | 'rtl'
        },
        'p[dir=rtl], h1[dir=rtl], h2[dir=rtl], h3[dir=rtl], h4[dir=rtl], h5[dir=rtl], h6[dir=rtl]': {
          direction: ('rtl' + ' !important') as 'ltr' | 'rtl'
        }
      }}
      className={cx(classes.wrapper, className, withBorder ? classes.richTextInline : '')}
    >
      <RichTextEditor
        data-testid={`${dataTestIdPrefix}-rich-text-editor`}
        classNames={{
          control: classes.control,
          toolbar: classes.toolbar,
          content: classes.content,
          root: classes.root,
          typographyStylesProvider: classes.typographyStylesProvider
        }}
        editor={editor}
        labels={labels}
      >
        {toolbarType === ToolbarType.Fixed ? (
          <RichTextEditorFixed {...props} />
        ) : toolbarType === ToolbarType.Inline ? (
          <RichTextEditorInline {...props} />
        ) : (
          <RichTextEditor.Content
            dangerouslySetInnerHTML={htmlContent && isInitialRender ? { __html: xss(htmlContent) } : undefined}
          />
        )}
      </RichTextEditor>
    </Box>
  );
}
