import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  eyeDropper: {
    height: rem(36),
    width: rem(36)
  },
  input: {
    minWidth: rem(120),
    maxWidth: rem(160)
  }
}));
