import { TableCellRenderer } from './TableCellRenderer';
import { TableImplementation } from '@/widgets/Table/base/player';
import { TableProvider } from '@/widgets/Table/hooks/useTable';

export function Table() {
  return (
    <TableProvider TableCellRenderer={TableCellRenderer}>
      <TableImplementation />
    </TableProvider>
  );
}
