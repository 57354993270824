import { BorderRadiusThemeProps } from '@hendrx/modules/ui';
import { NumberInput, Box, ActionIcon, Flex } from '@mantine/core';
import uniq from 'lodash/uniq';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { Select } from '@/components/Select';
import * as classes from '@/components/Theme/Theme.css';
import { ThemeControl } from '@/components/Theme/ThemeControl';
import { UnlinkedRadiusInput } from '@/components/Theme/UnlinkedRadiusInput';
import { Unlink } from '@/icons/Unlink';
import { useContentLanguage } from '@/utils/useContentLanguage';

type ThemeBorderRadiusProps = {
  label?: string;
  path?: string;
  setBorderRadius: (newValue: Partial<BorderRadiusThemeProps>) => void;
  borderRadius?: Partial<BorderRadiusThemeProps>;
  ResetButton?: ReactNode;
};

const borderRadiusUnits: { label: string; value: BorderRadiusThemeProps['measureUnit'] }[] = [
  {
    label: 'px',
    value: '1px'
  },
  {
    label: '%',
    value: '1%'
  }
];

export function BorderRadiusPanel(props: ThemeBorderRadiusProps) {
  const { label, path, setBorderRadius, borderRadius, ResetButton } = props;
  const [isLinked, setIsLinked] = useState(true);
  const measureUnitSelectRef = useRef<HTMLInputElement>(null);
  const currentMeasureUnitValue =
    borderRadius?.measureUnit ??
    (measureUnitSelectRef?.current?.value as BorderRadiusThemeProps['measureUnit'] | undefined);

  const { contentDirection } = useContentLanguage();
  const isLTR = contentDirection === 'ltr';

  useEffect(() => {
    const isBorderRadiusEqual =
      uniq([borderRadius?.topLeft, borderRadius?.topRight, borderRadius?.bottomRight, borderRadius?.bottomLeft])
        .length === 1;

    setIsLinked(isBorderRadiusEqual);
  }, [borderRadius?.bottomLeft, borderRadius?.bottomRight, borderRadius?.topLeft, borderRadius?.topRight]);

  const setSingleBorderRadius = (newBorderRadius: number | string) => {
    setBorderRadius({
      measureUnit: currentMeasureUnitValue,
      topLeft: +newBorderRadius,
      topRight: +newBorderRadius,
      bottomRight: +newBorderRadius,
      bottomLeft: +newBorderRadius
    });
  };

  const setBorderRadiusProperty = (property: keyof BorderRadiusThemeProps, newBorderRadius: number | string | null) => {
    setBorderRadius({
      ...borderRadius,
      measureUnit: currentMeasureUnitValue,
      [property]: newBorderRadius
    });
  };

  const handleUnLink = () => {
    setIsLinked(false);
  };

  return (
    <Box className={classes.fullWidthContainer}>
      <ThemeControl label={label} path={path}>
        {!isLinked ? (
          <Flex className={classes.radiusInputs} wrap="wrap" gap={4}>
            <UnlinkedRadiusInput
              type={isLTR ? 'topLeft' : 'topRight'}
              borderRadius={borderRadius}
              setBorderRadiusProperty={setBorderRadiusProperty}
            />
            <UnlinkedRadiusInput
              type={isLTR ? 'topRight' : 'topLeft'}
              borderRadius={borderRadius}
              setBorderRadiusProperty={setBorderRadiusProperty}
            />
            <UnlinkedRadiusInput
              type={isLTR ? 'bottomLeft' : 'bottomRight'}
              borderRadius={borderRadius}
              setBorderRadiusProperty={setBorderRadiusProperty}
            />
            <UnlinkedRadiusInput
              type={isLTR ? 'bottomRight' : 'bottomLeft'}
              borderRadius={borderRadius}
              setBorderRadiusProperty={setBorderRadiusProperty}
            />
          </Flex>
        ) : (
          <Box className={classes.numberInput}>
            <NumberInput value={borderRadius?.topLeft ?? 0} onChange={setSingleBorderRadius} min={0} />
          </Box>
        )}
        <Box className={classes.measureUnitSelect}>
          <Select
            ref={measureUnitSelectRef}
            data={borderRadiusUnits}
            value={currentMeasureUnitValue ?? '1px'}
            onChange={newValue => setBorderRadiusProperty('measureUnit', newValue)}
          />
        </Box>
        {isLinked && (
          <ActionIcon variant="subtle" className={classes.unLink} onClick={handleUnLink}>
            <Unlink />
          </ActionIcon>
        )}
        {ResetButton}
      </ThemeControl>
    </Box>
  );
}
