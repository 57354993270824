import { useCallback } from 'react';
import * as Y from 'yjs';

import { getRootMap, useObservedProperty, validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';
import { ClozeOption } from 'shared/types/ClozeQuestion';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function useClozeOptions(clozeId: string) {
  const document = useCurrentDocument();
  const [questionId] = useContentObjectProperty<string>('id');

  const selector = useCallback(
    (contentObjects?: Y.Map<Y.Map<unknown>>) => {
      const question = contentObjects?.get(questionId);
      if (!question) {
        return { withRandomAnswer: false, possibleOptions: [] };
      }
      const possibleOptionsMap = validateYMap<Y.Map<{ value: string; id: string }>>(
        question.get(`selectPossibleOptions`)
      );
      const possibleOptions = validateYArray(possibleOptionsMap.get(clozeId)).toJSON() as ClozeOption[];
      const withRandomAnswerMap = validateYMap<boolean>(question.get(`withRandomAnswers`));
      const withRandomAnswer = !!withRandomAnswerMap.get(clozeId);
      return { withRandomAnswer, possibleOptions };
    },
    [questionId, clozeId]
  );

  const [{ withRandomAnswer, possibleOptions }] = useObservedProperty<{
    withRandomAnswer: boolean;
    possibleOptions: ClozeOption[];
  }>(getRootMap(document), 'contentObjects', {
    deepObserve: true,
    selector
  });

  return { withRandomAnswer, possibleOptions };
}
