import { createStyles } from '@mantine/emotion';

import { ObjectFit } from 'shared/types/Image';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

type ImageStylesProps = {
  objectFit: ObjectFit;
};

export const useStyles = createStyles((theme, props: ImageStylesProps) => {
  const { objectFit } = props;
  const mediaStyle = theme.other.media;
  const borderStyle = getThemeBorderStyle(mediaStyle.border);
  const borderRadiusStyle = getThemeBorderRadiusStyle(mediaStyle.borderRadius);

  return {
    imageContainer: {
      position: 'relative',
      height: '100%'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit,
      ...borderStyle,
      ...borderRadiusStyle
    }
  };
});
