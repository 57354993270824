import { Box } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { Dispatch, RefObject, SetStateAction, memo, useCallback, useState } from 'react';

import { WidgetType } from 'shared/utils/widgets';
import { getItemWidth } from 'shared/widgetsSDK/Stage.utils';
import { getContentObjectsMap, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useStyles } from './DndItem.styles';
import { Draggable } from '@/components/Draggable';
import { Resizable } from '@/components/Resizable';
import { allResizeDots, horizontalResizeDots } from '@/components/Resizable/hooks/useResizeDotsList';
import { widgetTypeToClassName } from '@/components/Section/widgetTypeToClassName';
import { useSelectableStore } from '@/components/Selectable';
import { useWidgetComponent } from '@/components/WidgetComponentProvider/useWidgetComponent';
import { WidgetProvider } from '@/components/WidgetProvider';
import { isFullPageItem } from '@/contentObjects/Page/utils/containsFullPageItem';
import { useChangeChildLayout } from '@/contentObjects/Section/hooks/useChangeChildLayout';
import { useDeleteAction } from '@/contentObjects/Section/hooks/useDeleteAction';
import { WidgetAction, WidgetData } from '@/types/Stage';
import { getDragTypeForWidget } from '@/utils/getDragTypeForWidget';
import { useWorkMode } from '@/utils/useWorkMode';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectProperty
} from '@/widgets/_components/ContentObjectProvider';
import { WidgetActionsBar } from '@/widgets/_components/widget/WidgetActionsBar';

type DndItemProps = {
  id: string;
  sectionRef: RefObject<HTMLDivElement>;
  resizingItemId?: string;
  setResizingItemId?: Dispatch<SetStateAction<string | undefined>>;
  parentId: string;
  isFlexItem?: boolean;
  flexDirection?: 'row' | 'column';
  containerWidth?: number;
  flexOrder?: number;
};

export const DndItem = memo(function DndItem(props: DndItemProps) {
  const {
    id,
    sectionRef,
    resizingItemId,
    setResizingItemId,
    parentId,
    isFlexItem,
    flexDirection,
    containerWidth,
    flexOrder
  } = props;
  const { document, id: sectionId } = useContentObject();
  const [height] = useContentObjectProperty<number>(`layout.${id}.height`);
  const [isDeletable] = useContentObjectProperty<boolean>(`layout.${id}.isDeletable`);
  const [isDraggable] = useContentObjectProperty<boolean>(`layout.${id}.isDraggable`);
  const [span] = useContentObjectProperty<number>(`layout.${id}.span`);
  const [width] = useContentObjectProperty<number>(`layout.${id}.width`);
  const [column] = useContentObjectProperty<number>(`layout.${id}.column`);

  // TODO: Create a helper for this or include the child type alongside the ID?
  const childMap = getContentObjectsMap(document).get(id);
  if (!childMap) {
    throw new Error(`Child data ${id} not found in contentObjects.`);
  }

  const { isStudioEdit } = useWorkMode();

  const isSelected = useSelectableStore(state => state.selectedId === id);
  const select = useSelectableStore(state => state.select);

  const [childType] = useObservedProperty<WidgetType>(childMap, 'type');
  const changeChildLayout = useChangeChildLayout();
  const deleteItem = useDeleteAction();

  // TODO: this needs to be discussed anyways since it can be bad UX if inner
  // widget is selected and the container gets deleted...
  // pbi: https://cet-tech.visualstudio.com/TOC/_sprints/taskboard/HendrX/TOC/HendrX/S71?workitem=401786
  const handleDelete = useCallback(() => {
    if (isSelected && isDeletable) {
      deleteItem(id);
    }
  }, [isSelected, deleteItem, id, isDeletable]);

  useHotkeys([['Delete', handleDelete]]);

  const [widgetData, setWidgetData] = useState<WidgetData>();

  const initializeWidget = useCallback((data: WidgetData) => {
    setWidgetData(data);
  }, []);

  const isEmpty = !id;

  const shouldKeepAspectRatio = !!widgetData?.shouldKeepAspectRatio;
  const { classes, cx } = useStyles({
    isFlexItem,
    flexDirection,
    containerWidth,
    isEmpty,
    column,
    span,
    height,
    width: width || getItemWidth(span),
    shouldKeepAspectRatio,
    widgetType: childType,
    isResizableVertically: widgetData?.isResizableVertically ?? false,
    flexOrder
  });

  const handleResizeEnd = useCallback(
    (newSpan: number, newHeight: number | 'auto', shouldResizeItemInStartDirection: boolean, newWidth: number) => {
      let newCol = undefined;
      if (shouldResizeItemInStartDirection) {
        const diff = span - newSpan;
        newCol = column + diff;
      }

      changeChildLayout(id, parentId, {
        height: newHeight ?? height,
        span: newSpan ?? span,
        column: newCol ?? column,
        width: newWidth ?? width
      });
    },
    [changeChildLayout, id, parentId, height, span, column, width]
  );

  const handleSelect = useCallback(() => {
    select(id);
  }, [select, id]);

  const Widget = useWidgetComponent(childType);

  if (isEmpty || !childType) {
    return <Box className={classes.dndWidgetWrapperStyle} />;
  }

  const contentObjectsMap = getContentObjectsMap(document);
  const sectionType = contentObjectsMap.get(sectionId)?.get('type') as WidgetType;

  if (!isStudioEdit) {
    return (
      <Box
        className={cx(classes.dndWidgetWrapperStyle, widgetTypeToClassName[childType])}
        data-testid={`stage-preview-item-${childType}-${id}`}
      >
        <ContentObjectProvider id={id} document={document} type={childType} columnSpan={span}>
          <WidgetProvider initializeWidget={initializeWidget}>
            <Widget />
          </WidgetProvider>
        </ContentObjectProvider>
      </Box>
    );
  }

  const actions = [...(isDeletable ? [{ name: 'Delete' as WidgetAction, handler: deleteItem }] : [])];

  return (
    <Box
      className={cx(classes.dndWidgetWrapperStyle, widgetTypeToClassName[childType])}
      data-testid={`stage-item-${childType}-${id}`}
    >
      <Box>
        <Draggable
          itemType={getDragTypeForWidget(childType)}
          resizingItemId={resizingItemId}
          id={id}
          parentSectionId={sectionId}
          span={span}
          column={column}
          disabled={!isDraggable}
        >
          <Resizable
            id={id}
            sectionType={sectionType}
            type={childType}
            span={span}
            containerWidth={containerWidth}
            width={width}
            height={height}
            sectionId={sectionId}
            constraints={widgetData?.constraints}
            onResizeEnd={handleResizeEnd}
            isResizableVertically={widgetData?.isResizableVertically}
            onSelect={handleSelect}
            sectionRef={sectionRef}
            setResizingItemId={setResizingItemId}
            resizingItemId={resizingItemId}
            resizeDirections={widgetData?.isResizableVertically ? allResizeDots : horizontalResizeDots}
            disabled={isFullPageItem(id, document)}
          >
            <WidgetActionsBar
              actions={actions}
              additionalActions={widgetData?.additionalActions}
              id={id}
              isOpened={isSelected}
            >
              <ContentObjectProvider id={id} document={document} type={childType} columnSpan={span}>
                <WidgetProvider initializeWidget={initializeWidget}>
                  <Widget />
                </WidgetProvider>
              </ContentObjectProvider>
            </WidgetActionsBar>
          </Resizable>
        </Draggable>
      </Box>
    </Box>
  );
});
