import { ActivityThemeStyles, BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';

import { useWidgetStyle } from './useWidgetStyle';
import { useCurrentDocument } from '@/components/YjsProvider';

export type TypographyIdType = keyof ActivityThemeStyles['typography'];
export const typographyIds: TypographyIdType[] = ['h1', 'h2', 'h3', 'h4', 'p'];

export type WidgetTypographyStyle = {
  fontFamily?: string | null;
  color?: string | null;
  backgroundColor: string | null;
  borderTop: BorderThemeProps | null;
  borderRight: BorderThemeProps | null;
  borderBottom: BorderThemeProps | null;
  borderLeft: BorderThemeProps | null;
  borderRadius: BorderRadiusThemeProps | null;
  padding: number | null;
};

export type BlockTitleTypographyStyle = Omit<WidgetTypographyStyle, 'padding'> & {
  fontFamily: string | null;
  color: string | null;
  paddingTop: number | null;
  paddingBottom: number | null;
  paddingLeft: number | null;
  paddingRight: number | null;
};

export type SetTextWidgetStyleFunction<T> = (
  newValue: Partial<T> | undefined | ((prevState: Partial<T> | undefined) => Partial<T> | undefined)
) => void;

export type TypedWidgetStyleAndSet<T> = {
  style: Partial<T> | undefined;
  setStyle: SetTextWidgetStyleFunction<T>;
};

export type TextWidgetStyles = Record<TypographyIdType, TypedWidgetStyleAndSet<WidgetTypographyStyle>>;
export type BlockTitleWidgetStyles = Record<TypographyIdType, TypedWidgetStyleAndSet<BlockTitleTypographyStyle>>;

export function useTypedWidgetStyles<T>(widgetId: string): Record<TypographyIdType, TypedWidgetStyleAndSet<T>> {
  const document = useCurrentDocument();

  const [h1style, setH1Style] = useWidgetStyle<Partial<T>>(document, widgetId, 'h1');
  const [h2style, setH2Style] = useWidgetStyle<Partial<T>>(document, widgetId, 'h2');
  const [h3style, setH3Style] = useWidgetStyle<Partial<T>>(document, widgetId, 'h3');
  const [h4style, setH4Style] = useWidgetStyle<Partial<T>>(document, widgetId, 'h4');
  const [paragraphStyle, setParagraphStyle] = useWidgetStyle<Partial<T>>(document, widgetId, 'p');

  return {
    h1: { style: h1style, setStyle: setH1Style },
    h2: { style: h2style, setStyle: setH2Style },
    h3: { style: h3style, setStyle: setH3Style },
    h4: { style: h4style, setStyle: setH4Style },
    p: { style: paragraphStyle, setStyle: setParagraphStyle }
  };
}

export const useBlockTitleWidgetStyles = useTypedWidgetStyles<BlockTitleTypographyStyle>;
export const useTextWidgetStyles = useTypedWidgetStyles<WidgetTypographyStyle>;
