// This file is copied over from mantine v6.0.7 and slightly adjusted
// Don't change this by hand

import type { MantineTheme, MantineThemeOverride } from '@mantine/core';

function isObject(item: unknown) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this code was copied over
export function deepMerge<T extends object>(target: T, source: any): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this code was copied over
  const result: Record<string, any> = { ...target };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this code was copied over
  const _source: Record<string, any> = source;

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(_source[key])) {
        if (!(key in target)) {
          result[key] = _source[key];
        } else {
          result[key] = deepMerge(result[key], _source[key]);
        }
      } else {
        result[key] = _source[key];
      }
    });
  }

  return result as T;
}

export const INVALID_PRIMARY_COLOR_ERROR =
  '[@mantine/core] MantineProvider: Invalid theme.primaryColor, it accepts only key of theme.colors, learn more – https://mantine.dev/theming/colors/#primary-color';

export const INVALID_PRIMARY_SHADE_ERROR =
  '[@mantine/core] MantineProvider: Invalid theme.primaryShade, it accepts only 0-9 integers or an object { light: 0-9, dark: 0-9 }';

function isValidPrimaryShade(shade: number) {
  if (shade < 0 || shade > 9) {
    return false;
  }

  return parseInt(shade.toString(), 10) === shade;
}

export function validateMantineTheme(theme: MantineTheme): asserts theme is MantineTheme {
  if (!(theme.primaryColor in theme.colors)) {
    throw new Error(INVALID_PRIMARY_COLOR_ERROR);
  }

  if (typeof theme.primaryShade === 'object') {
    if (!isValidPrimaryShade(theme.primaryShade.dark) || !isValidPrimaryShade(theme.primaryShade.light)) {
      throw new Error(INVALID_PRIMARY_SHADE_ERROR);
    }
  }

  if (typeof theme.primaryShade === 'number' && !isValidPrimaryShade(theme.primaryShade)) {
    throw new Error(INVALID_PRIMARY_SHADE_ERROR);
  }
}

export function mergeMantineTheme(currentTheme: MantineTheme, themeOverride?: MantineThemeOverride) {
  if (!themeOverride) {
    validateMantineTheme(currentTheme);
    return currentTheme;
  }

  const result = deepMerge(currentTheme, themeOverride);

  if (themeOverride.fontFamily && !themeOverride.headings?.fontFamily) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- this code was copied over
    result.headings.fontFamily = themeOverride.fontFamily;
  }

  validateMantineTheme(result);
  return result;
}
