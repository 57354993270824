import { Box, rem } from '@mantine/core';
import { Row } from '@tanstack/react-table';
import { forwardRef, memo } from 'react';
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd';

import { useStyles } from './Table.styles';
import { TableCell } from './TableCell';
import { TableRowMenuArea } from './TableRowMenuArea';
import { useStyles as useDndStyles } from './dnd/Dnd.styles';
import { DndDragLayer } from './dnd/DndDragLayer';
import { DndCollect, DndItem } from './dnd/types';
import { useIsGlobalDragging } from './dnd/useIsGlobalDragging';
import { useMouseAndSize } from './hooks/useMouseAndSize';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useStore } from '@/store';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CLASSNAMES, TRow } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

type TableRowProps = {
  row: Row<TRow>;
};

export const TableRow = memo(function TableRow(props: TableRowProps) {
  const { row } = props;
  const rowId = row.original.id;
  const { id: tableId } = useContentObject();
  const setRowOrder = useTable(context => context.setRowOrder);

  const {
    ref: mouseAndSizeRef,
    position,
    size: { width: previewWidth, height: previewHeight }
  } = useMouseAndSize();

  const stageScale = useStore(state => state.stageScale);

  const [{ isDragging }, drag, dragPreview] = useDrag<DndItem, void, DndCollect>(
    {
      type: `${tableId}-row`,
      item: { id: rowId },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    },
    [rowId, tableId]
  );

  const [{ isOver }, drop] = useDrop<DndItem, void, DndCollect>({
    accept: `${tableId}-row`,
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    drop: item => {
      setRowOrder(currentOrder => {
        // Find the index of the dragged row and the target row
        const draggedRowIndex = currentOrder.indexOf(item.id);
        const targetRowIndex = currentOrder.indexOf(rowId);

        // Determine the new index considering whether the item is dropped in the top or bottom area of the target
        const newIndex = targetRowIndex + (isOverTop ? 0 : 1);

        // Calculate adjustments based on the drag direction
        const adjustedIndex = draggedRowIndex < newIndex ? newIndex - 1 : newIndex;

        // Create a new array excluding the dragged item
        const newRowOrderWithoutDragged = currentOrder.filter(id => id !== item.id);

        // Insert the dragged item at the new position
        const newRowOrder = [
          ...newRowOrderWithoutDragged.slice(0, adjustedIndex),
          item.id,
          ...newRowOrderWithoutDragged.slice(adjustedIndex)
        ];

        return newRowOrder;
      });
    }
  });

  const isOverTop = isOver && position.y * (1 / stageScale) < previewHeight / 2;
  const isOverBottom = isOver && position.y * (1 / stageScale) > previewHeight / 2;

  const { classes, cx } = useDndStyles({ isDragging, isOverBottom, isOverTop });
  drop(mouseAndSizeRef);

  return (
    <Box className={cx(classes.dndContainer, CLASSNAMES.TableRowWrapper)}>
      <Box className={classes.dndWrapper} ref={mouseAndSizeRef}>
        <TableRowImplementation ref={dragPreview} row={row} dragRef={drag} />
      </Box>
      <DndDragLayer scale={stageScale} isDragging={isDragging}>
        <Box style={{ width: previewWidth, height: previewHeight }}>
          <TableRowImplementation row={row} dragRef={null} />
        </Box>
      </DndDragLayer>
    </Box>
  );
});

type TableRowImplementationProps = {
  row: Row<TRow>;
  dragRef: ConnectDragSource | null;
};

export const TableRowImplementation = memo(
  forwardRef<HTMLDivElement, TableRowImplementationProps>(function TableRowImplementation(props, ref) {
    const { row, dragRef } = props;
    const rowId = row.original.id;
    const rowHeights = useTable(context => context.rowHeights);

    const { classes, cx } = useStyles();

    const isGlobalDragging = useIsGlobalDragging();

    return (
      <div
        className={cx(classes.tr, ThemeClassNames.widgets.table.row)}
        ref={ref}
        style={{
          minHeight: `${rem(rowHeights[rowId])}`
        }}
      >
        <TableRowMenuArea
          rowHeight={rowHeights[rowId]}
          rowId={rowId}
          dragRef={dragRef}
          isGlobalDragging={isGlobalDragging}
        />
        {row.getVisibleCells().map(cell => (
          <TableCell key={cell.id} cell={cell} />
        ))}
      </div>
    );
  })
);
