import get from 'lodash/get';
import set from 'lodash/set';
import { useCallback } from 'react';

import { CollaborativeState, getCollaborativeState, useObservedProperty } from 'shared/widgetsSDK/yjs';

import { useCurrentDocument } from '@/components/YjsProvider';

export const useCollaborativeQuestionState = (questionId: string) => {
  const document = useCurrentDocument();
  const [allQuestionsStates = {}, setAllQuestionsStates] = useObservedProperty<CollaborativeState['questionsStates']>(
    getCollaborativeState(document),
    'questionsStates'
  );

  const state = allQuestionsStates[questionId] ?? {};

  const toggleRevealAnswers = useCallback(() => {
    const path = `${questionId}.isRevealed`;
    const isRevealed = get(allQuestionsStates, path);

    const clone = structuredClone(allQuestionsStates);
    set(clone, path, !isRevealed);
    setAllQuestionsStates(clone);
  }, [allQuestionsStates, setAllQuestionsStates, questionId]);

  return { state, toggleRevealAnswers };
};
