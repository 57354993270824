import { useEffect } from 'react';

import { getFormDataMap } from 'shared/widgetsSDK/yjs';

import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useStudioEvents(id: string) {
  const { document: contentObjectDocument } = useContentObject();
  const formData = getFormDataMap(contentObjectDocument);

  useEffect(() => {
    const handleErrors = (e: Event) => {
      const existingErrorsMap = formData.get(id);
      existingErrorsMap?.set('errors', (e as CustomEvent).detail.errors);
    };

    document.addEventListener(`${PluginEvent.errorsDataEvent}-${id}`, handleErrors);
    return () => {
      document.removeEventListener(`${PluginEvent.errorsDataEvent}-${id}`, handleErrors);
    };
  }, [formData, id]);
}
