import isEqual from 'lodash/isEqual';
import shuffle from 'lodash/shuffle';
import * as Y from 'yjs';

import { ContentObjectType, getContentObjectsMap, validateYArray, validateYMap } from './yjs';
import { QuestionGroupsType } from '../types/QuestionGroups';
import { EvaluatableWidgets, evaluatableWidgets, WidgetType } from '../utils/widgets';

export const isQuestionGroup = (map: Y.Map<unknown>) => map.has('relativeScoreMode');

export const getSubQuestionsIds = (questionGroupMap: Y.Map<unknown>, contentObjectsMap: Y.Map<ContentObjectType>) => {
  const children = validateYArray<string>(questionGroupMap.get('children')).toArray();
  const verticalSectionId = children[0];
  const verticalSection = validateYMap(contentObjectsMap.get(verticalSectionId));

  return validateYArray<string>(verticalSection.get('children')).toArray();
};

export const getParentQuestionIdForSubQuestion = (questionId: string, document: Y.Doc) => {
  const contentObjectsMap = getContentObjectsMap(document);

  let parentQuestionId: string | null = null;

  for (const contentElement of Array.from(contentObjectsMap.entries())) {
    const [questionGroupId, map] = contentElement; // contentElement = [parentId, map]

    if (isQuestionGroup(map)) {
      const subQuestionIds = getSubQuestionsIds(map, contentObjectsMap);
      if (subQuestionIds.includes(questionId)) {
        parentQuestionId = questionGroupId;
        break;
      }
    }
  }

  return parentQuestionId;
};

export const isQuestionRequired = (questionId: string | undefined, document: Y.Doc) => {
  if (!questionId) return;

  const contentObjectsMap = getContentObjectsMap(document);
  let isRequired = false;

  for (const contentElement of Array.from(contentObjectsMap.entries())) {
    const map = contentElement[1]; // contentElement = [parentId, map]

    if (isQuestionGroup(map)) {
      const requiredSubQuestionsId = map.get('requiredSubQuestionsId') as string[];
      if (requiredSubQuestionsId.includes(questionId)) {
        isRequired = true;
        break;
      }
    }
  }

  return isRequired;
};

type GetValidQuestionOrderArgs = {
  questionIds: string[];
  requiredQuestionIds: string[];
  visibleQuestionsCount: number;
};

// returns requiredQuestions and remaining questions in most valid possible order, so it is possible that what user sees will be similar to what LXD intended
export function getValidQuestionOrder(args: GetValidQuestionOrderArgs) {
  const { questionIds, requiredQuestionIds, visibleQuestionsCount } = args;

  const requiredQuestionsCount = requiredQuestionIds.length;
  const questionsToShowCount = Math.max(requiredQuestionsCount, visibleQuestionsCount);
  const optionalQuestionsToShowCount = questionsToShowCount - requiredQuestionsCount;

  const result = [];
  let addedOptionalQuestions = 0;
  let currentIndex = 0;

  const allQuestions = questionIds.map(id => ({ id, required: requiredQuestionIds.includes(id) }));

  while (result.length < questionsToShowCount) {
    const question = allQuestions[currentIndex];
    // This is a workaround for preventing app crash on preview, when studio and preview are both opened in the same time
    // The issue is related to desync problems, where required property of question object doesn't exist, when group list's question
    // TODO More info about problem and PBI - #437621
    if (!question) return [];
    if (question.required) {
      result.push(question);
    }

    if (!question.required && addedOptionalQuestions < optionalQuestionsToShowCount) {
      result.push(question);
      addedOptionalQuestions = addedOptionalQuestions + 1;
    }

    currentIndex = currentIndex + 1;
  }

  return result.map(({ id }) => id);
}

export const generateSubQuestionsForGroup = (id: string, contentObjectsMap: Y.Map<ContentObjectType>) => {
  const item = contentObjectsMap.get(id);
  if (!item) {
    return [];
  }

  const questionGroupChildren = item.get('children') as Y.Array<string>;
  const verticalSectionId = questionGroupChildren.map(id => id)?.[0];
  const verticalSection = contentObjectsMap.get(verticalSectionId);
  if (!verticalSection) {
    return [];
  }

  const verticalSectionChildren = verticalSection.get('children') as Y.Array<string>;
  const verticalSectionChildrenIds = verticalSectionChildren.map(id => id);
  const shouldRandomize = item.get('withRandomSubQuestions');
  const requiredQuestionIds = item.get('requiredSubQuestionsId') as string[];

  const visibleQuestionsCount = item.get('visibleQuestionsCount') as number;

  return getValidQuestionOrder({
    visibleQuestionsCount,
    requiredQuestionIds,
    questionIds: shouldRandomize ? shuffle(verticalSectionChildrenIds) : verticalSectionChildrenIds
  });
};

export const getQuestionGroups = (document: Y.Doc, existingQuestionGroups?: QuestionGroupsType): QuestionGroupsType => {
  if (!existingQuestionGroups) {
    return {};
  }

  const contentObjectsMap = getContentObjectsMap(document);

  const newQuestionGroups = Array.from(contentObjectsMap.values())
    .filter(item => item.get('type') === WidgetType.QuestionGroupList)
    .reduce((acc, item) => {
      const id = item.get('id') as string;
      const questionGroupMap = validateYMap(contentObjectsMap.get(id));

      const savedSubQuestionIds = existingQuestionGroups[id] ?? [];
      const hasNoConfigStored = !savedSubQuestionIds;

      const hasRandomizedSubQuestions = item.get('withRandomSubQuestions');
      const visibleQuestionsCount = questionGroupMap.get('visibleQuestionsCount') as number;
      const visibleQuestionsCountChanged = savedSubQuestionIds.length !== visibleQuestionsCount;

      const subQuestionsIds = getSubQuestionsIds(questionGroupMap, contentObjectsMap);
      const questionsChanged = hasRandomizedSubQuestions
        ? savedSubQuestionIds.some(id => !subQuestionsIds.includes(id))
        : !isEqual(subQuestionsIds, savedSubQuestionIds);

      let subQuestions = savedSubQuestionIds;
      if (hasNoConfigStored || visibleQuestionsCountChanged || questionsChanged) {
        subQuestions = generateSubQuestionsForGroup(id, contentObjectsMap);
      }

      return { ...acc, [id]: subQuestions };
    }, {});

  return newQuestionGroups;
};

const canChoiceSettingBeEnabled = (visibleQuestionsCount: number, requiredQuestionsCount: number) => {
  return (
    (requiredQuestionsCount >= 1 && visibleQuestionsCount > 2 && visibleQuestionsCount - requiredQuestionsCount > 1) ||
    (requiredQuestionsCount === 0 && visibleQuestionsCount > 1)
  );
};

export const isChoiceSettingLocked = (visibleQuestionsCount: number, requiredQuestionsCount: number) => {
  return !canChoiceSettingBeEnabled(visibleQuestionsCount, requiredQuestionsCount);
};

export const isSingleEvaluatableQuestion = (type: WidgetType) =>
  [...evaluatableWidgets, WidgetType.OpenQuestion].includes(type as EvaluatableWidgets | WidgetType.OpenQuestion);

export const getEvaluatableSubQuestionsFromSavedState = (
  questionGroupsInState: QuestionGroupsType,
  document: Y.Doc
): Record<string, string[]> => {
  const contentObjectsMap = getContentObjectsMap(document);
  const contentObjectValues = contentObjectsMap.values();

  const evaluatableQuestionsIds = Array.from(contentObjectValues)
    .filter(contentObject => isSingleEvaluatableQuestion(contentObject.get('type')))
    .map(evaluatableContentObject => evaluatableContentObject.get('id') as string);

  const evaluatableContentObjects = Object.values(questionGroupsInState)
    .flat()
    .filter(subQuestionId => evaluatableQuestionsIds.includes(subQuestionId));

  return Object.entries(questionGroupsInState).reduce((acc, [questionGroupId, subQuestions]) => {
    const evalutableQuestionsOfElementStates = subQuestions.filter(subQuestionId =>
      evaluatableContentObjects.includes(subQuestionId)
    );
    return {
      ...acc,
      [questionGroupId]: evalutableQuestionsOfElementStates
    };
  }, {});
};
