import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getHorizontalSectionThemeStyles, getVerticalSectionThemeStyles } from '@/utils/theme.utils';
import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

type DndSectionStylesProps = {
  alignTo?: string;
  direction: FlexSectionDirection;
  gap?: number;
  isEmpty: boolean;
  nestLevel: number;
  justifyContent?: string;
  alignItems?: string;
};

export const useStyles = createStyles((theme, props: DndSectionStylesProps) => {
  const { direction, gap, isEmpty, nestLevel = 1, justifyContent, alignItems } = props;

  const sectionStyle =
    direction === FlexSectionDirection.row
      ? getHorizontalSectionThemeStyles(theme, nestLevel)
      : getVerticalSectionThemeStyles(theme, nestLevel);

  return {
    dndSection: {
      flexDirection: direction,
      display: 'flex',
      minHeight: rem(50),
      flexWrap: 'wrap',
      gap: gap ?? sectionStyle.gap,
      ...(isEmpty && { alignItems: 'center' }),
      justifyContent: justifyContent ?? sectionStyle.justifyContent,
      alignItems: alignItems ?? sectionStyle.alignItems
    }
  };
});
