import { MantineSize, Tooltip } from '@mantine/core';
import shuffle from 'lodash/shuffle';
import uniq from 'lodash/uniq';
import { useTranslations } from 'next-intl';
import { useMemo, useRef } from 'react';
import { Map } from 'yjs';

import { useStyles } from './ClozeOption.styles';
import { SelectOption } from './SelectOption';
import { Select } from '@/components/Select';
import { Chevron } from '@/icons/Chevron';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useDynamicInputWidth } from '@/utils/useDynamicInputWidth';
import { useOverflowText } from '@/utils/useOverflowText';
import { useContentObjectArray, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

type ClozeOptionSelectProps = {
  clozeId: string;
  disabled?: boolean;
  fontSize?: MantineSize;
  fontFamily?: string;
  inputClassName: string;
  onInputChange: (value: string) => void;
  value: string | null;
};

export function ClozeOptionSelect(props: ClozeOptionSelectProps) {
  const { clozeId, inputClassName, onInputChange, value, disabled, fontSize = 'lg', fontFamily } = props;

  const t = useTranslations('widgets');

  const [possibleOptions] = useContentObjectArray<Map<unknown>>(`selectPossibleOptions.${clozeId}`);
  const [withRandomAnswer] = useContentObjectProperty<boolean | undefined>(`withRandomAnswers.${clozeId}`);

  const placeholder = t('clozeQuestionWidget.selectPlaceholder') ?? '';

  const ref = useRef<HTMLInputElement>(null);

  const placeholderWords = useMemo(() => [placeholder], [placeholder]);
  const placeholderWidth = useDynamicInputWidth(placeholderWords, 60, ref);

  const words = useMemo(() => possibleOptions.map(option => option.get('value') as string), [possibleOptions]);
  const width = useDynamicInputWidth(words, 60, ref);
  const finalWidth = Math.max(width, placeholderWidth);

  const { contentDirection } = useContentLanguage();

  const { classes, cx } = useStyles({
    width: finalWidth,
    fontFamily,
    fontSize,
    direction: contentDirection
  });

  const options = (withRandomAnswer ? shuffle(possibleOptions) : possibleOptions)
    .map(option => {
      return option.get('value') as string;
    })
    .filter(Boolean);

  const overflowText = useOverflowText(ref);

  const rightSectionIcon = <Chevron style={{ transform: 'scaleY(-100%)', minWidth: 24 }} />;

  return (
    <Tooltip label={overflowText} disabled={!overflowText} multiline maw={600}>
      <Select
        ref={ref}
        data-testid="preview-cloze-question-answer-select"
        value={value}
        size={fontSize}
        onChange={selectedValue => onInputChange(selectedValue ?? options[0])}
        placeholder={placeholder}
        // The user is not allowed to publish with duplicated options anyways, this patches the preview
        data={uniq(options)}
        rightSection={rightSectionIcon}
        rightSectionPointerEvents="none"
        withScrollArea={false}
        withCheckIcon={false}
        classNames={{
          wrapper: cx(classes.selectWrapper, inputClassName),
          dropdown: classes.dropdown,
          input: cx(classes.input, classes.selectInput),
          option: classes.dropdownItem,
          section: classes.rightSection
        }}
        disabled={disabled}
        renderOption={SelectOption}
      />
    </Tooltip>
  );
}
