import * as Y from 'yjs';

import { DEFAULT_DIRECTION, DEFAULT_GAPS, DEFAULT_MARGIN_END, DEFAULT_MARGIN_START } from './config';
import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { ManifestInitializedProps, InitializePropsOptions } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';
import { createNewFlexSectionData } from '../FlexSection/create';
import { EnclosureWithBlockTitleConfig } from '../questionConfigs';

export function createNewEnclosureWithBlockTitleData(
  options: InitializePropsOptions,
  initializationConfig?: EnclosureWithBlockTitleConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { content = '', style } = initializationConfig ?? {};

  const fragment = htmlToXmlFragment(content);

  const [verticalSectionData] = createNewFlexSectionData(FlexSectionDirection.column);
  const { id: verticalSectionId } = verticalSectionData;
  const childrenArray = new Y.Array();
  childrenArray.push([verticalSectionId]);

  const finalStyle = {
    ...style,
    dropzoneDirection: style?.dropzoneDirection ?? DEFAULT_DIRECTION,
    dropzoneMarginStart: style?.dropzoneMarginStart ?? DEFAULT_MARGIN_START,
    dropzoneMarginEnd: style?.dropzoneMarginEnd ?? DEFAULT_MARGIN_END,
    dropzoneGap: style?.dropzoneGap ?? DEFAULT_GAPS
  };

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.EnclosureWithBlockTitle],
        ['children', childrenArray],
        ['textType', 'header'],
        ['fragment', fragment],
        ['placeholder', ''],
        ['style', new Y.Map(Object.entries(finalStyle))]
      ])
    },
    verticalSectionData
  ];
}
