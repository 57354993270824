import { BorderRadiusThemeProps, BorderThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

import {
  getHorizontalSectionWrapperThemeStyles,
  getThemeBorderRadiusStyle,
  getVerticalSectionWrapperThemeStyles
} from '@/utils/theme.utils';

type FlexSectionPlayerStylesProps = {
  backgroundColor?: string;
  border?: BorderThemeProps;
  borderRadius?: BorderRadiusThemeProps;
  direction: FlexSectionDirection;
  nestLevel: number;
  padding?: number;
  shouldApplyNestStyles?: boolean;
  dense?: boolean;
};

export const useStyles = createStyles((theme, props: FlexSectionPlayerStylesProps) => {
  const {
    backgroundColor,
    borderRadius,
    border,
    direction,
    nestLevel = 1,
    padding,
    shouldApplyNestStyles,
    dense
  } = props;

  const wrapperStyle =
    direction === FlexSectionDirection.row
      ? getHorizontalSectionWrapperThemeStyles(theme, nestLevel)
      : getVerticalSectionWrapperThemeStyles(theme, nestLevel);

  return {
    dndSectionWrapperStyle: {
      height: '100%',
      minHeight: rem(60),
      position: 'relative',
      ...(shouldApplyNestStyles
        ? {
            borderRadius: borderRadius
              ? getThemeBorderRadiusStyle(borderRadius).borderRadius
              : wrapperStyle.borderRadius,
            borderStyle: border?.borderStyle ?? wrapperStyle.borderStyle,
            borderWidth: border?.borderWidth ?? wrapperStyle.borderWidth,
            borderColor: border?.borderColor ?? wrapperStyle.borderColor,
            backgroundColor: backgroundColor ?? wrapperStyle.backgroundColor,
            padding: padding ?? (dense ? undefined : wrapperStyle.padding)
          }
        : {})
    }
  };
});
