export type GlobalThemeProps = {
  fontFamily: string;
  colors: {
    primary: { main: string; contrast: string; dark: string; light: string };
    secondary: { main: string; contrast: string };
    decoration: { main: string };
  };
};

export type BackgroundRepeat = 'no-repeat' | 'repeat' | 'repeat-x' | 'repeat-y';
export type BackgroundSize = 'unset' | 'cover' | 'contain';
export type BackgroundThemeProps = {
  backgroundColor: string;
  backgroundImage: string;
  backgroundRepeat: BackgroundRepeat;
  backgroundSize: BackgroundSize;
};

export type TopImageFit = 'left' | 'right' | 'center' | 'fill';
export type TopImageThemeProps = {
  topImageEnabled: boolean;
  topImageSrc: string;
  topImageMobileSrc: string;
  topImageFit: TopImageFit;
  topImageOverlap: boolean;
  topImageSticky: boolean;
};

export type GlobalColorThemeProps = {
  color: string | null;
  backgroundColor: string | null;
};

export type ColorThemeProps = {
  color: string;
  backgroundColor: string;
};

export type FontWeight = 'normal' | 500 | 600 | 'bold';
export type FontThemeProps = {
  fontSize: number;
  fontWeight: FontWeight;
  fontFamily: string | null;
};

export type BorderRadiusThemeProps = {
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
  measureUnit: '1px' | '1%';
};

export type BorderThemeProps = {
  borderColor: string | null;
  borderWidth: number;
  borderStyle: string;
};

export type FullBorderThemeProps = {
  border: BorderThemeProps;
  borderRadius: BorderRadiusThemeProps;
};

export type BasicThemeProps = GlobalColorThemeProps & FullBorderThemeProps;

export type AnswerAreaThemeProps = BasicThemeProps & { placeholderColor: string; lineHeight: string };

export type InteractiveButtonThemeProps = { standard: { color: string | null }; hovered: { color: string | null } };

//todo: to be removed after top bar will be handled
export type OldBasicThemeProps = ColorThemeProps &
  Omit<BorderThemeProps, 'borderColor'> & { borderColor: string; borderRadius: number };

export type LayoutSectionStyles = FullBorderThemeProps & {
  backgroundColor: string;
  padding: number;
  gap: number;
  alignTo: string;
};

type TextCommonThemeProps = {
  fontSize: number;
  fontWeight: FontWeight;
  lineHeight: string;
  fontFamily: string | null;
  color: string | null;
  borderTop: BorderThemeProps;
  borderRight: BorderThemeProps;
  borderBottom: BorderThemeProps;
  borderLeft: BorderThemeProps;
  borderRadius: BorderRadiusThemeProps;
};

export type TypographyThemeProps = TextCommonThemeProps & {
  backgroundColor: string;
  padding: number;
};

export type BlockTitleThemeProps = TextCommonThemeProps & {
  backgroundColor: string | null;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
};

export type CombinedTitleThemeProps = {
  verticalAlignment: 'top' | 'center' | 'bottom';
  imagePosition: 'start' | 'end';
  gap: number;
};

export enum QuestionNumberingStyle {
  Standalone = 'standalone',
  WithDots = 'withDots'
}

export enum QuestionNumberingPosition {
  AboveHeadline = 'aboveHeadline',
  AfterHeadline = 'afterHeadline',
  BeforeHeadline = 'beforeHeadline'
}

export type ActivityThemeStyles = {
  global: {
    content: GlobalThemeProps;
    system: GlobalThemeProps;
  };
  page: BackgroundThemeProps & TopImageThemeProps;
  stage: FullBorderThemeProps &
    Omit<BackgroundThemeProps, 'backgroundColor'> & {
      backgroundColor: string | null;
      minQuestionInputHeight: string;
      questionInputWrapperPadding: string;
      questionInputWrapperPaddingSum: string;
    };
  sections: {
    vertical1: LayoutSectionStyles;
    horizontal1: LayoutSectionStyles;
    vertical2: LayoutSectionStyles;
    horizontal2: LayoutSectionStyles;
    vertical3: LayoutSectionStyles;
    horizontal3: LayoutSectionStyles;
  };
  typography: {
    h1: TypographyThemeProps;
    h2: TypographyThemeProps;
    h3: TypographyThemeProps;
    h4: TypographyThemeProps;
    p: TypographyThemeProps;
  };
  blockTitle: {
    h1: BlockTitleThemeProps;
    h2: BlockTitleThemeProps;
    h3: BlockTitleThemeProps;
    h4: BlockTitleThemeProps;
    p: BlockTitleThemeProps;
  };
  combinedTitle: CombinedTitleThemeProps;
  navigationBar: {
    backgroundColor: string;
    fontFamily: string | null;
    paddingInline: number;
    paddingBlock: number;
    shadow: boolean;
    borderRadius: BorderRadiusThemeProps;
    pageNumber: FullBorderThemeProps & {
      color: string | null;
      backgroundColor: string;
      fontSize: number;
      size: number;
      fontWeight: FontWeight;
    };
    pageNumberHovered: GlobalColorThemeProps & {
      border: BorderThemeProps;
      fontWeight: FontWeight;
    };
    pageNumberSelected: {
      color: string;
      backgroundColor: string | null;
      border: BorderThemeProps;
      fontWeight: FontWeight;
    };
    navigationButton: {
      color: string | null;
      fontSize: number;
      fontWeight: FontWeight;
    };
  };
  table: {
    tableStyle: 'simple' | 'alternate-rows' | 'alternate-columns';
    fontFamily: string | null;
    fontSize: number;
    color: string | null;
    cellPrimaryBackgroundColor: string;
    cellSecondaryBackgroundColor: string;
    cellPadding: number;
    rowSpacing: number;
    columnSpacing: number;
    cornerRadius: BorderRadiusThemeProps;
    isBorderVisible: boolean;
    border: BorderThemeProps;
    isCaptionVisible: boolean;
    captionBackgroundColor: string;
    captionColor: string | null;
    captionFontSize: number;
    captionFontFamily: string | null;
    isHeaderVisible: boolean;
    headerBackgroundColor: string;
    headerColor: string | null;
    headerFontSize: number;
    headerFontFamily: string | null;
  };
  tooltip: {
    backgroundColor: string;
    cornerRadius: BorderRadiusThemeProps;
    border: BorderThemeProps;
    isBorderVisible: boolean;
    shadow: string;
    isShadowVisible: boolean;
    padding: number;
    offset: number;
    position: 'top' | 'bottom' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end';
  };
  media: FullBorderThemeProps;
  button: {
    primary: {
      standard: GlobalColorThemeProps & FontThemeProps & FullBorderThemeProps;
      hovered: GlobalColorThemeProps & Omit<FullBorderThemeProps, 'borderRadius'> & { fontWeight: FontWeight };
    };
    secondary: {
      standard: GlobalColorThemeProps & FontThemeProps & FullBorderThemeProps;
      hovered: GlobalColorThemeProps & Omit<FullBorderThemeProps, 'borderRadius'> & { fontWeight: FontWeight };
    };
  };
  learningItems: {
    openQuestion: {
      answerArea: AnswerAreaThemeProps;
    };
    selectionQuestion: {
      answerArea: AnswerAreaThemeProps;
      checkbox: BasicThemeProps;
      selectedCheckbox: GlobalColorThemeProps & { border: BorderThemeProps };
      interactiveButton: InteractiveButtonThemeProps;
    };
    clozeQuestion: {
      answerArea: AnswerAreaThemeProps;
      inputField: BasicThemeProps;
      selectedField: BasicThemeProps;
    };
    fillInTheBlanks: {
      answerArea: AnswerAreaThemeProps;
      blank: BasicThemeProps & { backgroundColorHovered: string | null };
      bank: BasicThemeProps;
    };
  };
  collabItems: {
    interactivePoll: {
      answerArea: AnswerAreaThemeProps;
      checkbox: BasicThemeProps;
      selectedCheckbox: GlobalColorThemeProps & { border: BorderThemeProps };
      interactiveButton: InteractiveButtonThemeProps;
    };
    openDiscussion: {
      headlineSection: LayoutSectionStyles;
    };
    interactiveQuiz: {
      answerArea: AnswerAreaThemeProps;
      checkbox: BasicThemeProps;
      selectedCheckbox: GlobalColorThemeProps & { border: BorderThemeProps };
      interactiveButton: InteractiveButtonThemeProps;
    };
  };
  feedback: Omit<FontThemeProps, 'fontFamily'> & {
    color: {
      showAnswer: string;
      wrong: string;
      correct: string;
      partiallyCorrect: string;
    };
  };
  topbar: ColorThemeProps & {
    burger: {
      backgroundColor: string;
    };
    avatar: ColorThemeProps;
    viewModeDropdown: {
      color: string;
    };
    actionIcon: {
      standard: ColorThemeProps;
      disabled: ColorThemeProps;
    };
    saveButton: {
      standard: ColorThemeProps;
      hovered: ColorThemeProps;
      disabled: ColorThemeProps;
    };
    publishButton: {
      standard: ColorThemeProps;
      hovered: ColorThemeProps;
      disabled: ColorThemeProps;
    };
    player: ColorThemeProps & {
      activeMenu: {
        backgroundColor: string;
      };
      loginButton: {
        standard: OldBasicThemeProps;
        hovered: OldBasicThemeProps;
      };
      assignTaskButton: {
        standard: OldBasicThemeProps;
        hovered: OldBasicThemeProps;
      };
      actionIcon: {
        standard: OldBasicThemeProps;
        hovered: OldBasicThemeProps;
      };
    };
  };
  questionNumbering: {
    numberingStyle: QuestionNumberingStyle;
    numberingPosition: QuestionNumberingPosition;
    h1: TypographyThemeProps;
    h2: TypographyThemeProps;
    h3: TypographyThemeProps;
    h4: TypographyThemeProps;
    p: TypographyThemeProps;
  };
};

// It'll hold overwriting types for different titles
export type OverrideTypographyStylesKeys = 'blockTitle' | 'questionNumbering';

export type ThemeType = {
  _id: string;
  name: string;
  styles: ActivityThemeStyles;
  css?: string;
};

export type CreateThemeDto = Omit<ThemeType, '_id'>;

export type UpdateThemeDto = {
  themeId: string;
  newTheme: Partial<Omit<ThemeType, '_id'>>;
};
