import { Box, Title } from '@mantine/core';
import cx from 'clsx';
import { ReactNode } from 'react';

import * as classes from '@/components/Theme/Theme.css';

type ThemeItemSectionProps = {
  label?: string;
  children: ReactNode;
  sectionClassName?: string;
};

export function ThemeItemSection(props: ThemeItemSectionProps) {
  const { children, label, sectionClassName } = props;

  return (
    <>
      {label && (
        <Title order={6} className={classes.itemSectionTitle}>
          {label}
        </Title>
      )}
      <Box className={cx(classes.itemSection, sectionClassName)}>{children}</Box>
    </>
  );
}
