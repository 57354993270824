import { UnstyledButton } from '@mantine/core';

import { TableMenuProps } from './TableMenu';
import { useStyles } from './TableTooltip.styles';
import { DragHandle } from '@/icons/DragHandle';
type DragActionButtonProps = TableMenuProps;

export function DragActionButton(props: DragActionButtonProps) {
  const { type, dragRef } = props;
  const { classes } = useStyles({ type });

  return (
    <UnstyledButton className={classes.button} ref={dragRef}>
      <DragHandle color="white" width={24} height={24} className={classes.dragHandleIcon} />
    </UnstyledButton>
  );
}
