import { useCallback } from 'react';

import { AnswerConfig } from 'shared/types/AnswerConfig';
import { validateYArray } from 'shared/widgetsSDK/yjs';

import { useContentObject } from '@/widgets/_components/ContentObjectProvider';
import { generateDefaultAnswer } from 'shared/utils/generateDefaultAnswer';

export function useAddNewAnswer(config: AnswerConfig) {
  const { document, data } = useContentObject();
  return useCallback(() => {
    document.transact(() => {
      const newAnswer = generateDefaultAnswer(config);
      const answersArray = validateYArray(data?.get('answers'));
      answersArray.push([newAnswer]);
    });
  }, [document, data, config]);
}
