import * as Y from 'yjs';

import { FlexSectionDirection } from '../../types/FlexSectionDirection';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';
import {
  DEFAULT_DIRECTION,
  DEFAULT_GAPS,
  DEFAULT_MARGIN_END,
  DEFAULT_MARGIN_START
} from '../EnclosureWithBlockTitle/config';
import { createNewFlexSectionData } from '../FlexSection/create';
import { createNewImageData } from '../Image/create';
import { createNewTextData } from '../Text/create';
import { EnclosureWithCombinedTitleConfig } from '../questionConfigs';

export function createNewEnclosureWithCombinedTitleData(
  options: InitializePropsOptions,
  initializationConfig?: EnclosureWithCombinedTitleConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { locale, isPlenaryLesson } = options;
  const { content = '', imageConfig, style } = initializationConfig ?? {};

  const [imageData] = createNewImageData({ locale, isPlenaryLesson }, imageConfig);
  const [textData] = createNewTextData(
    { locale, isPlenaryLesson },
    { content, textType: 'header', withParagraphOption: false }
  );

  const [horizontalSectionData] = createNewFlexSectionData(FlexSectionDirection.row, [
    { id: imageData.id, width: 60, height: 60, span: 1, column: 0, isDeletable: false, isDraggable: false },
    { id: textData.id, width: 300, height: 'auto', isDeletable: false, isDraggable: false }
  ]);
  const { id: horizontalSectionId } = horizontalSectionData;

  const childrenArray = new Y.Array();
  childrenArray.push([horizontalSectionId]);

  const [verticalSectionData] = createNewFlexSectionData(FlexSectionDirection.column);
  const { id: verticalSectionId } = verticalSectionData;
  childrenArray.push([verticalSectionId]);

  const finalStyle = {
    ...style,
    dropzoneDirection: style?.dropzoneDirection ?? DEFAULT_DIRECTION,
    dropzoneMarginStart: style?.dropzoneMarginStart ?? DEFAULT_MARGIN_START,
    dropzoneMarginEnd: style?.dropzoneMarginEnd ?? DEFAULT_MARGIN_END,
    dropzoneGap: style?.dropzoneGap ?? DEFAULT_GAPS
  };

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.EnclosureWithCombinedTitle],
        ['children', childrenArray],
        ['style', new Y.Map(Object.entries(finalStyle))]
      ])
    },
    horizontalSectionData,
    imageData,
    textData,
    verticalSectionData
  ];
}
