import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSubmitPageUnansweredQuestions } from 'shared/widgetsSDK/SubmitPageUnansweredQuestions/create';

import { manifestLocales } from './manifest.locales';
import UnansweredQuestionsIconEn from './resources/en/submitPageUnansweredQuestions.svg';
import UnansweredQuestionsIconHe from './resources/he/submitPageUnansweredQuestions.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SubmitPageUnansweredQuestions,
  previewGroups: [
    {
      id: 'SubmitPageUnansweredQuestions',
      divider: false,
      previews: [
        {
          name: 'SubmitPageUnansweredQuestionsPreview',
          previewImage: {
            paths: {
              en: UnansweredQuestionsIconEn,
              he: UnansweredQuestionsIconHe
            },
            alt: { ...manifestLocales.SubmitPageUnansweredQuestions.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: () => createNewSubmitPageUnansweredQuestions(),
          dimensions: {
            width: 590,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
