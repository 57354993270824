import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles((theme, props: { isRejected: boolean }) => {
  const { isRejected } = props;
  const mediaStyle = theme.other.media;
  const borderRadiusStyle = getThemeBorderRadiusStyle(mediaStyle.borderRadius);

  return {
    dropzone: {
      backgroundColor: theme.colors.accent[0],
      height: '100%',
      border: `${rem(2)} dashed ${isRejected ? theme.colors.pink[6] : theme.colors.accent[3]}`,
      padding: 0,
      ...borderRadiusStyle
    },
    dropzoneInner: {
      height: '100%',
      display: 'grid',
      pointerEvents: 'initial'
    },
    dropzoneContent: {
      height: '100%',
      maxHeight: '100%',
      overflow: 'auto'
    }
  };
});
