import { InteractiveButtonThemeProps } from '@hendrx/modules/ui';
import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type AnswerBodyStylesProps = {
  interactiveButtonThemeProps: InteractiveButtonThemeProps;
  hasImage?: boolean;
};

export const useStyles = createStyles((theme, props: AnswerBodyStylesProps) => {
  const { hasImage, interactiveButtonThemeProps } = props;
  const globalThemeContentColors = theme.other.global.content.colors;

  const audioStyles = {
    width: hasImage ? rem(24) : rem(36),
    height: `${hasImage ? rem(24) : rem(36)} !important`,
    padding: 0
  };

  const audioButtonStyles = {
    borderRadius: 0,
    minWidth: hasImage ? rem(24) : rem(36),
    minHeight: hasImage ? rem(24) : rem(36),
    background: 'none',
    color: interactiveButtonThemeProps.standard.color ?? globalThemeContentColors.secondary.contrast,
    '&:hover': {
      color: interactiveButtonThemeProps.hovered.color ?? globalThemeContentColors.primary.contrast
    }
  };

  const imageStyles = {
    height: `${rem(150)} !important`,
    width: '100%'
  };

  return {
    audioPlayButton: {
      ...audioStyles,
      ...audioButtonStyles,
      '&:hover': {
        ...audioStyles,
        ...audioButtonStyles
      }
    },
    audioDropZone: {
      ...audioStyles
    },
    image: {
      ...imageStyles
    },
    imageDropZone: {
      ...imageStyles
    }
  };
});
