import ImageNext from 'next/image';

import { FileTypes } from '@/components/UploadFile/FileTypes';
import { useStyles } from '@/components/UploadFile/UploadFile.styles';

type FilePreviewProps = {
  type: FileTypes;
  alt: string;
  src: string;
  previewMaxHeight?: number;
  aspectRatio?: string;
  disabled?: boolean;
};

export function FilePreview(props: FilePreviewProps) {
  const { type, alt, src, previewMaxHeight, aspectRatio, disabled } = props;
  const { classes } = useStyles({ previewMaxHeight, aspectRatio, disabled });

  return <>{type === 'image' && <ImageNext alt={alt} src={src} width="400" height="1" className={classes.image} />}</>;
}
