import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { WidgetType } from 'shared/utils/widgets';

import { useDeviceView } from '@/utils/deviceHooks';
import { FlexSectionDirection } from 'shared/types/FlexSectionDirection';

type DndItemStylesProps = {
  isEmpty: boolean;
  span: number;
  column: number;
  height: number;
  shouldKeepAspectRatio?: boolean;
  widgetType: WidgetType;
  isResizableVertically: boolean;
  width: number;
  isFlexItem?: boolean;
  flexDirection?: 'row' | 'column';
  containerWidth?: number;
  flexOrder?: number;
};

export const useStyles = createStyles((theme, props: DndItemStylesProps) => {
  const {
    isEmpty,
    column,
    span,
    height,
    shouldKeepAspectRatio,
    widgetType,
    width,
    isFlexItem,
    flexDirection,
    containerWidth,
    isResizableVertically,
    flexOrder
  } = props;
  const { deviceView } = useDeviceView();

  if (isFlexItem) {
    return {
      dndWidgetWrapperStyle: {
        pointerEvents: isEmpty ? 'none' : 'auto',
        '> *': {
          width: containerWidth ? `min(${width}px, ${containerWidth}px)` : width,
          aspectRatio: shouldKeepAspectRatio ? `${width} / ${height}` : undefined,
          position: 'relative'
        },
        ...(flexDirection === FlexSectionDirection.row && { flexBasis: width }),
        minHeight: shouldKeepAspectRatio || widgetType === WidgetType.Text ? undefined : rem(height),
        aspectRatio: shouldKeepAspectRatio ? `${width} / ${height}` : undefined,
        order: flexOrder
      }
    };
  }

  return {
    dndWidgetWrapperStyle: {
      position: 'relative',
      ...(deviceView === 'pc' && { gridColumnStart: column }),
      gridColumnEnd: isEmpty ? '' : `span ${span}`,
      pointerEvents: isEmpty ? 'none' : 'auto',
      '> *': {
        height: isResizableVertically ? '100%' : 'auto'
      },
      minHeight: shouldKeepAspectRatio || widgetType === WidgetType.Text ? undefined : rem(height),
      aspectRatio: shouldKeepAspectRatio ? `${width} / ${height}` : undefined
    }
  };
});
