import { Row } from '@tanstack/react-table';
import { memo } from 'react';

import { useStyles } from './Table.styles';
import { TableRow } from './TableRow';
import { TRow } from '@/widgets/Table/utils';

type TableBodyProps = {
  rows: Row<TRow>[];
};

export const TableBody = memo(function TableBody(props: TableBodyProps) {
  const { rows } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.tbody}>
      {rows.map(row => (
        <TableRow key={row.original.id} row={row} />
      ))}
    </div>
  );
});
