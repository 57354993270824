import { RefObject, useEffect, useState } from 'react';

/**
 * Custom hook to determine if the text content of an element overflows its container.
 * Returns the overflow text if it does overflow, otherwise returns null.
 *
 * @param {RefObject<HTMLElement>} ref - The ref object pointing to the element to be monitored.
 * @returns {string | null} The overflow text if it overflows, otherwise null.
 *
 * @example
 * const ref = useRef<HTMLDivElement>(null);
 * const overflowText = useOverflowText(ref);
 */
export function useOverflowText(ref: RefObject<HTMLElement>) {
  const [overflowText, setOverflowText] = useState<string | null>(null);

  useEffect(() => {
    const handleMouseOver = (e: MouseEvent) => {
      setOverflowText(currentOverflowText => {
        const overflowText = getOverflowText(e.target as HTMLElement);
        if (overflowText !== currentOverflowText) {
          return overflowText;
        }
        return currentOverflowText;
      });
    };

    const element = ref.current;

    if (element) {
      element.addEventListener('mouseover', handleMouseOver);
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseover', handleMouseOver);
      }
    };
  }, [ref]);

  return overflowText;
}

export function getOverflowText(e: HTMLElement | HTMLInputElement) {
  if (e instanceof HTMLInputElement) {
    const input = e;
    const scrollWidth = input.scrollWidth;
    const clientWidth = input.clientWidth;

    return scrollWidth > clientWidth ? input.value : null;
  } else {
    const temp = e.cloneNode(true) as HTMLElement;

    temp.style.position = 'fixed';
    temp.style.overflow = 'visible';
    temp.style.whiteSpace = 'nowrap';
    temp.style.visibility = 'hidden';

    e.parentElement?.appendChild(temp);

    try {
      const fullWidth = temp.getBoundingClientRect().width;
      const displayWidth = e.getBoundingClientRect().width;

      return fullWidth > displayWidth ? e.innerText : null;
    } finally {
      temp.remove();
    }
  }
}
