import { QuestionGroupsType } from 'shared/types/QuestionGroups';
import { getEvaluatableSubQuestionsFromSavedState, getQuestionGroups } from 'shared/widgetsSDK/questionGroup';
import { getContentObjectsMap } from 'shared/widgetsSDK/yjs';

import { usePlayerStateStore } from '@/components/PlayerStateProvider';
import { useCurrentDocument } from '@/components/YjsProvider';
import { QuestionStates } from '@/queries/state';
import { isQuestionAnswered } from '@/utils/questions.utils';

export const useUnansweredQuestionsInGroupCount = (groupQuestionId: string) => {
  const document = useCurrentDocument();
  const groupListQuestionSettings = getContentObjectsMap(document).get(groupQuestionId);
  if (!groupListQuestionSettings) throw new Error('Cannot find relevant group question.');
  const choiceQuestionsAmount = groupListQuestionSettings.get('choiceQuestionsCount') as number;

  const elementsState = usePlayerStateStore(state => state.elementsState);
  if (!elementsState) return { subQuestionsCount: 0, unansweredSubQuestionsCount: 0 };

  const questionGroupsInState = getQuestionGroups(
    document,
    (elementsState['questionGroups'] ?? {}) as QuestionGroupsType
  );

  const evaluatableQuestionGroupsInState = getEvaluatableSubQuestionsFromSavedState(questionGroupsInState, document);

  const questionGroupQuestionsId = evaluatableQuestionGroupsInState[groupQuestionId] ?? [];
  const answeredSubQuestionsCount = questionGroupQuestionsId.reduce((acc, contentObjectQuestion) => {
    const savedStateQuestionAnswers = elementsState[contentObjectQuestion] as QuestionStates;
    return isQuestionAnswered(savedStateQuestionAnswers) ? acc + 1 : acc;
  }, 0);

  const questionsInGroupCount = questionGroupQuestionsId.length;
  const unansweredSubQuestionsCountInGroup =
    (choiceQuestionsAmount > 0 ? choiceQuestionsAmount : questionsInGroupCount) - answeredSubQuestionsCount;

  return {
    subQuestionsCount: choiceQuestionsAmount > 0 ? choiceQuestionsAmount : questionsInGroupCount,
    unansweredSubQuestionsCount: unansweredSubQuestionsCountInGroup
  };
};
