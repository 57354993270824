import { Tabs } from '@mantine/core';
import { useMemo } from 'react';

import { SupportedLocale } from 'shared/types/SupportedLocale';
import { WidgetType } from 'shared/utils/widgets';

import { useInjectScript } from './useInjectScript';
import { EvaluationSettings } from '@/widgets/_components/EvaluationSettings';
import { Feedback } from '@/widgets/_components/Feedback';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';
import { ConfigTypes } from '@/widgets/_components/WidgetConfig/ConfigTypes';

type ConfigElementProps = {
  id: string;
  script: string;
  isStudioEdit: boolean;
  contentLanguage: SupportedLocale;
  uiLanguage: string;
  tabs: ConfigTypes[];
  title?: string;
  iconUrl: string;
};

export const ConfigElement = (props: ConfigElementProps) => {
  const { id, script, isStudioEdit, contentLanguage, uiLanguage, title, tabs, iconUrl } = props;

  const attributes = useMemo(
    () => [
      { name: 'data-widgetid', value: id },
      { name: 'data-isstudioedit', value: `${isStudioEdit}` },
      { name: 'data-element', value: 'config' },
      { name: 'data-uilanguage', value: uiLanguage },
      { name: 'data-contentlanguage', value: contentLanguage }
    ],
    [contentLanguage, id, isStudioEdit, uiLanguage]
  );

  useInjectScript(script, attributes, id);

  return (
    <WidgetConfig tabs={tabs} type={WidgetType.Plugin} title={title} Icon={iconUrl}>
      {tabs.includes('appearance') && (
        <Tabs.Panel key="appearance" value="appearance">
          <div data-pluginconfigelement={id} />
        </Tabs.Panel>
      )}
      {tabs.includes('behaviour') && (
        <Tabs.Panel key="behaviour" value="behaviour">
          <EvaluationSettings />
          <Feedback />
        </Tabs.Panel>
      )}
    </WidgetConfig>
  );
};
