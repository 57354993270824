import { useEffect } from 'react';

interface UsePreventUserSelectWhileResizeProps {
  isResizingCurrentItem: boolean;
}

export function usePreventUserSelectWhileResize(props: UsePreventUserSelectWhileResizeProps) {
  const { isResizingCurrentItem } = props;

  useEffect(() => {
    document.body.style.userSelect = isResizingCurrentItem ? 'none' : '';
    return () => {
      document.body.style.userSelect = '';
    };
  }, [isResizingCurrentItem]);
}
