import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  const globalContentColorsTheme = theme.other.global.content.colors;
  const fitbQuestionBlankTheme = theme.other.learningItems.fillInTheBlanks.blank;
  const blankBorderStyle = getThemeBorderStyle(fitbQuestionBlankTheme.border, globalContentColorsTheme.primary.dark);
  const blankBorderRadiusStyle = getThemeBorderRadiusStyle(fitbQuestionBlankTheme.borderRadius);

  return {
    wordBankOptionText: {
      fontSize: theme.fontSizes.lg
    },
    wordBankOptionDragged: {
      color: fitbQuestionBlankTheme.color ?? globalContentColorsTheme.secondary.contrast,
      backgroundColor: fitbQuestionBlankTheme.backgroundColor ?? globalContentColorsTheme.secondary.main,
      ...blankBorderStyle,
      ...blankBorderRadiusStyle,
      transform: 'translate(-50%, -50%)',
      padding: `0 ${rem(4)}`,
      cursor: 'grab'
    }
  };
});
