import { Alert, Button, Tabs, Textarea, TextInput } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';
import { WidgetConfig } from '@/widgets/_components/WidgetConfig';

const fetchAiModuleBackend = async (customData: Record<string, unknown>) => {
  const response = await fetch('/api/ai-module/call', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customData)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`, {
      cause: response
    });
  }

  return await response.json();
};

const useAIModuleBackendData = (customData: Record<string, unknown>) => {
  return useMutation<Record<string, unknown>>({
    mutationFn: () => fetchAiModuleBackend(customData)
  });
};

export function Config() {
  const [status, setStatus] = useContentObjectProperty<string>('status');
  const [script, setScript] = useContentObjectProperty<string>('script');
  const [imgUrl, setImgUrl] = useContentObjectProperty<string>('imgUrl');
  const [avatarData, setAvatarData] = useContentObjectProperty<object>('avatarData');

  const { mutate: generateAvatar, data: generateResponse } = useAIModuleBackendData({
    feature: 'generateAvatar',
    job: 'generateAvatar',
    script,
    imgUrl
  });

  return (
    <WidgetConfig tabs={['appearance']}>
      <Tabs.Panel key="appearance" value="appearance">
        {!status ? (
          <>
            <FormControl title="URL to a photo of a face" withAsterisk>
              <TextInput
                value={imgUrl}
                onChange={event => setImgUrl(event.currentTarget.value)}
                placeholder={'https://path.to.image.jpg'}
              />
              <img src={imgUrl} style={{ maxWidth: '100%' }} />
            </FormControl>
            <FormControl title="Script for the avatar" withAsterisk>
              <Textarea
                rows={5}
                value={script}
                onChange={event => setScript(event.currentTarget.value)}
                placeholder={'AI Avatar script for the video'}
              />
            </FormControl>
            <FormControl>
              <Alert variant="light">
                Avatar generation is a an expensive operation that can be done only once! Make it count!
              </Alert>
              <Button
                onClick={async () => {
                  setStatus('generating');
                  generateAvatar();
                }}
                variant="filled"
                fullWidth
              >
                Generate Avatar!
              </Button>
            </FormControl>
          </>
        ) : null}
        {status === 'generating' ? (
          <FormControl>
            <Alert variant="light" color="orange">
              The video is being generated. Please wait...
            </Alert>
            <Button
              onClick={async () => {
                const data = await fetchAiModuleBackend({
                  feature: 'generateAvatar',
                  job: 'getVideo',
                  videoId: generateResponse?.id
                });
                console.log('data', data);
                setAvatarData(data);
                if (data.status === 'done') {
                  setStatus('ready');
                }
              }}
              variant="outline"
              fullWidth
            >
              Refresh
            </Button>
            <Button
              onClick={async () => {
                setStatus('');
              }}
              variant="outline"
            >
              Reset
            </Button>
          </FormControl>
        ) : null}
        {status === 'ready' ? (
          <FormControl>
            <Alert variant="light" color="green">
              The video is ready!
            </Alert>
          </FormControl>
        ) : null}
        <pre>{JSON.stringify(status || 'none', null, 2)}</pre>
        <pre>{JSON.stringify(generateResponse || {}, null, 2)}</pre>
        <pre>{JSON.stringify(avatarData || {}, null, 2)}</pre>
      </Tabs.Panel>
    </WidgetConfig>
  );
}
