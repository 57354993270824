import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewMicrosoftEmbedData } from 'shared/widgetsSDK/MicrosoftEmbed/create';

import { manifestLocales } from './manifest.locales';
import ExcelEn from './resources/en/excel.svg';
import PowerPointEn from './resources/en/power-point.svg';
import WordEn from './resources/en/word.svg';
import ExcelHe from './resources/he/excel.svg';
import PowerPointHe from './resources/he/power-point.svg';
import WordHe from './resources/he/word.svg';
import { ExcelObject } from '@/icons/ExcelObject';
import { PowerPointObject } from '@/icons/PowerPointObject';
import { WordObject } from '@/icons/WordObject';

export const Manifest: ManifestType = {
  name: WidgetType.MicrosoftEmbed,
  previewGroups: [
    {
      id: 'powerpoint',
      divider: {
        icon: PowerPointObject,
        title: 'powerpoint'
      },
      previews: [
        {
          name: 'powerPointPreview',
          previewImage: {
            paths: {
              en: PowerPointEn,
              he: PowerPointHe
            },
            size: {
              width: 280,
              height: 140
            },
            alt: { ...manifestLocales.powerpoint.previewImageAlt }
          },
          dimensions: {
            width: 1180,
            height: 500
          },
          initializeProps: () => createNewMicrosoftEmbedData('PowerPoint')
        }
      ]
    },
    {
      id: 'word',
      divider: {
        icon: WordObject,
        title: 'word'
      },
      previews: [
        {
          name: 'wordPreview',
          previewImage: {
            paths: {
              en: WordEn,
              he: WordHe
            },
            size: {
              width: 280,
              height: 140
            },
            alt: { ...manifestLocales.word.previewImageAlt }
          },
          dimensions: {
            width: 1180,
            height: 500
          },
          initializeProps: () => createNewMicrosoftEmbedData('Word')
        }
      ]
    },
    {
      id: 'excel',
      divider: {
        icon: ExcelObject,
        title: 'excel'
      },
      previews: [
        {
          name: 'excelPreview',
          previewImage: {
            paths: {
              en: ExcelEn,
              he: ExcelHe
            },
            size: {
              width: 280,
              height: 140
            },
            alt: { ...manifestLocales.excel.previewImageAlt }
          },
          dimensions: {
            width: 1180,
            height: 500
          },
          initializeProps: () => createNewMicrosoftEmbedData('Excel')
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 450,
      minWidth: 800
    },
    maxFiles: 1,
    maxFileSizeBytes: 10_000_000
  }
};
