import * as Y from 'yjs';

import { HorizontalLayout } from '../../types/Layout';
import { genRandId } from '../../utils/genRandId';
import { WidgetType } from '../../utils/widgets';

type SectionChild = {
  id: string;
} & Partial<HorizontalLayout>;

export function createNewSectionData(parentPageId: string, children: SectionChild[] = []) {
  const id = genRandId();
  const sectionChildren = new Y.Array();
  sectionChildren.push(children.map(child => child.id));

  const layout = new Y.Map();
  children.forEach(child => {
    layout.set(
      child.id,
      new Y.Map([
        ['span', child.span ?? 4],
        ['column', child.column ?? 1],
        ['height', child.height ?? 200],
        ['isDraggable', child.isDraggable ?? true],
        ['isDeletable', child.isDeletable ?? true]
      ])
    );
  });

  return [
    new Y.Map([
      ['id', id],
      ['type', WidgetType.Section],
      ['children', sectionChildren],
      ['layout', layout],
      ['parentId', parentPageId]
    ]),
    id
  ] as const;
}
