import { OverrideTypographyStylesKeys } from '@hendrx/modules/ui';
import { SelectProps } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { FontFamilyPicker } from '@/components/FontFamilyPicker';
import { DropdownArrow } from '@/icons/DropdownArrow';
import { useTextStyles } from '@/utils/tiptap/useTextStyles';
import { useStyles } from '@/widgets/_components/RichTextToolbar/ToolbarSelect.styles';

export type TextFontSizeSelectProps = {
  editor: Editor | null;
  overrideTypographyStylesPath?: OverrideTypographyStylesKeys;
};

export function FontFamilySelect(props: TextFontSizeSelectProps) {
  const { editor, overrideTypographyStylesPath } = props;

  const { classes } = useStyles({ maxWidth: 100 });
  const t = useTranslations('widgets.fontFamilySelect');
  const { getTextStyles } = useTextStyles(editor);

  const [activeFontFamily, setActiveFontFamily] = useState(
    getTextStyles('fontFamily', { overrideTypographyStylesPath })
  );

  useEffect(() => {
    const setFontFamily = () => {
      const activeFontFamily = getTextStyles('fontFamily', { overrideTypographyStylesPath });
      setActiveFontFamily(activeFontFamily);
    };

    editor?.on('selectionUpdate', setFontFamily);
    editor?.on('update', setFontFamily);

    return () => {
      editor?.off('selectionUpdate', setFontFamily);
      editor?.off('update', setFontFamily);
    };
  }, [editor, getTextStyles, overrideTypographyStylesPath]);

  const handleFontFamilyChange = (fontFamily: string) => {
    editor?.commands.setFontFamily(fontFamily);
  };

  return (
    <FontFamilyPicker
      value={activeFontFamily}
      onChange={handleFontFamilyChange as SelectProps['onChange']}
      title={t('title') ?? ''}
      placeholder={t('placeholder') ?? ''}
      leftSection={<DropdownArrow />}
      leftSectionWidth={24}
      leftSectionPointerEvents="auto"
      rightSection={<></>}
      rightSectionWidth={'0px'}
      variant="unstyled"
      classNames={{
        root: classes.selectRoot,
        option: classes.selectItem,
        dropdown: classes.selectDropdown,
        input: classes.selectInput,
        section: classes.selectIcon
      }}
    />
  );
}
