export function getImageSizeBySrc(src: string) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    if (!document) {
      reject(new Error('No document'));
    }

    const img = document.createElement('img');
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };

    img.crossOrigin = 'anonymous';
    img.src = src;
  });
}
