import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

type HoverableStylesProps = {
  isHovered?: boolean;
};

export const useStyles = createStyles((theme, props: HoverableStylesProps) => {
  const { isHovered } = props;

  return {
    hoverable: {
      ...(isHovered && {
        outline: `${rem(2)} solid ${theme.colors.blue[5]}`
      })
    }
  };
});
