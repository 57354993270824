import { useEffect, useRef } from 'react';

import { useIsGlobalDragging } from '@/widgets/Table/base/studio/dnd/useIsGlobalDragging';
import { CLASSNAMES, ColumnId, CSS_VARS } from '@/widgets/Table/utils';

export const useHighlightColumnOnHover = (id: ColumnId) => {
  const highlightElementRef = useRef<HTMLDivElement>(null);
  const isGlobalDragging = useIsGlobalDragging();

  useEffect(() => {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(`.${CLASSNAMES.CellColumn(id)}`);

    const applyStyles = () => {
      elements.forEach((element, index) => {
        const selectionColor = CSS_VARS.SelectionColor;
        if (index === 0) {
          element.style.borderInlineColor = selectionColor;
          element.style.borderTopColor = selectionColor;
        } else if (index === elements.length - 1) {
          element.style.borderInlineColor = selectionColor;
          element.style.borderBottomColor = selectionColor;
        } else {
          element.style.borderInlineColor = selectionColor;
        }
        element.style.zIndex = '1';
      });
    };

    const clearStyles = () => {
      elements.forEach(element => {
        element.style.borderInlineColor = '';
        element.style.borderTopColor = '';
        element.style.borderBottomColor = '';
        element.style.zIndex = '';
      });
    };

    if (isGlobalDragging) {
      return clearStyles;
    }

    const currenthighlightElementRef = highlightElementRef.current;

    currenthighlightElementRef?.addEventListener('onMouseEnter', applyStyles);
    currenthighlightElementRef?.addEventListener('onMouseLeave', clearStyles);

    return () => {
      currenthighlightElementRef?.removeEventListener('onMouseEnter', applyStyles);
      currenthighlightElementRef?.removeEventListener('onMouseLeave', clearStyles);
    };
  }, [id, isGlobalDragging]);

  return highlightElementRef;
};
