import { Box } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useCallback, useEffect } from 'react';
import * as Y from 'yjs';

import { OpenQuestionElementType } from 'shared/types/OpenQuestion';
import { WidgetType } from 'shared/utils/widgets';
import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';
import { isQuestionRequired } from 'shared/widgetsSDK/questionGroup';

import { DndAllowedItemsContextProvider } from '@/components/DndAllowContext';
import { DropzonePlaceholder } from '@/components/DropzonePlaceholder';
import { Hoverable } from '@/components/Hoverable';
import { ListBulletWrapper } from '@/components/ListBulletWrapper';
import { QuestionTitle } from '@/components/QuestionTitle';
import { RequiredQuestionBadge } from '@/components/RequiredQuestionBadge';
import { Selectable } from '@/components/Selectable';
import { SideMenuPortal } from '@/components/SideMenuPortal';
import { ThemeClassNames } from '@/consts/ThemeClassNames';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { getQuestionBulletWrapperData, shouldShowResetButton, shouldShowScoreWeight } from '@/utils/questions.utils';
import { Studio as FlexSectionStudio } from '@/widgets/FlexSection/studio';
import { Manifest } from '@/widgets/OpenQuestion/Manifest';
import { Config } from '@/widgets/OpenQuestion/config';
import { useParentQuestionGroupSettings } from '@/widgets/QuestionGroupList/context';
import {
  ContentObjectProvider,
  useContentObject,
  useContentObjectEvaluationProps,
  useContentObjectProperty,
  useContentObjectScoreWeight,
  useContentObjectStaticProperty
} from '@/widgets/_components/ContentObjectProvider';
import * as questionClasses from '@/widgets/_components/questions/Question.css';
import { QuestionFooter } from '@/widgets/_components/questions/QuestionFooter';
import { useIsQuestionEmpty } from '@/widgets/hooks/useIsQuestionEmpty';
import { StudioProps } from '@/widgets/types/studioProps';

const acceptedItemTypes: ItemDragType[] = [ItemDragType.Item, ItemDragType.FlexSection];

export function OpenQuestion(props: StudioProps) {
  const { initialize } = props;
  const [flexSectionId] = useContentObjectProperty<string>('children.0');
  const isEmpty = useIsQuestionEmpty();
  const { ref: sectionRef } = useElementSize();

  const labelFragment = useContentObjectStaticProperty<Y.XmlFragment>('labelFragment');
  const hintFragment = useContentObjectStaticProperty<Y.XmlFragment>('hintFragment');
  const [showElements, setShowElements] =
    useContentObjectProperty<Record<OpenQuestionElementType, boolean>>('showElements');
  const [scoreWeight] = useContentObjectScoreWeight();
  const { showHintButton, questionScoreMode } = useContentObjectEvaluationProps();
  const { document, id } = useContentObject();
  const isRequired = isQuestionRequired(id, document);
  const questionGroupSettings = useParentQuestionGroupSettings();
  const score = shouldShowScoreWeight(questionGroupSettings) ? scoreWeight : 0;
  const showResetButton = shouldShowResetButton(questionGroupSettings);

  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  useEffect(() => {
    initialize?.({
      actions: ['Delete', 'Duplicate'],
      constraints: Manifest.additionalData?.constraints,
      isResizableVertically: false
    });
  }, [initialize]);

  const handleElementChange = useCallback(
    (element: OpenQuestionElementType, state: boolean) => {
      setShowElements(prevShowElements => ({ ...prevShowElements, [element]: state }));
    },
    [setShowElements]
  );

  const { questionListBulletType, withQuestionListBullet } = getQuestionBulletWrapperData(questionGroupSettings, id);

  return (
    <div className={customClassNames} ref={sectionRef}>
      <SideMenuPortal>
        <Config elements={showElements} onElementChange={handleElementChange} />
      </SideMenuPortal>
      {labelFragment && (
        <ListBulletWrapper itemId={id} questionListBulletType={questionListBulletType}>
          <Selectable>
            <Hoverable>
              <Box className={`${questionClasses.label} ${ThemeClassNames.widgets.openQuestion.label}`}>
                <QuestionTitle questionId={id} fragment={labelFragment} dataTestIdPrefix="studio-open-question-title" />
              </Box>
            </Hoverable>
          </Selectable>
        </ListBulletWrapper>
      )}
      <Box
        className={
          withQuestionListBullet
            ? questionClasses.sideSpaceWrapper.withQuestionListBullet
            : questionClasses.sideSpaceWrapper.base
        }
      >
        <RequiredQuestionBadge isRequired={isRequired} />
        <ContentObjectProvider id={flexSectionId} document={document} type={WidgetType.FlexSection}>
          <DndAllowedItemsContextProvider allowedItems={acceptedItemTypes}>
            {isEmpty && <DropzonePlaceholder type="contentItems" />}
            <FlexSectionStudio selectable />
          </DndAllowedItemsContextProvider>
        </ContentObjectProvider>
        <QuestionFooter
          questionId={id}
          hintFragment={hintFragment}
          parentScoreWeight={questionGroupSettings?.scoreWeight}
          questionScoreMode={questionScoreMode}
          scoreWeight={score}
          showAnswerButton={false}
          showCheckButton={false}
          showHintButton={showHintButton}
          showResetButton={showResetButton}
          showSeparator={!!questionGroupSettings}
        />
      </Box>
    </div>
  );
}
