// Used to open an uploaded Microsoft file in office web viewer in an iframe.
export function createOfficeWebViewerUrl(microsoftFile: string) {
  if (!microsoftFile) {
    return '';
  }

  // For full Microsoft File UI (with download button etc.) use: http://view.officeapps.live.com/op/view.aspx?src=
  const OFFICE_WEB_VIEWER_URL = `https://view.officeapps.live.com/op/embed.aspx?src=`;
  return `${OFFICE_WEB_VIEWER_URL}${microsoftFile}`;
}
