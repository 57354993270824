import { AuthenticationModuleClientType } from '@hendrx/modules/authentication';
import { Button, Overlay, Stack } from '@mantine/core';
import { useRouter } from 'next/router';

const login = async (role: string) => {
  const response = await fetch('/api/authentication/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ role })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return await response.json();
};

const roles = ['admin', 'teacher', 'student'];

export const LoginPage: AuthenticationModuleClientType['LoginPage'] = () => {
  const {
    query: { redirectTo },
    push
  } = useRouter();

  const handleLogin = async (role: string) => {
    await login(role);
    void push(redirectTo as string);
  };

  return (
    <Overlay center backgroundOpacity={0}>
      <Stack>
        {roles.map(role => (
          <Button key={role} data-testid={`login-${role}`} onClick={() => handleLogin(role)}>
            Log in as {role}
          </Button>
        ))}
      </Stack>
    </Overlay>
  );
};
