import { useMemo } from 'react';

import { SupportedLocale } from 'shared/types/SupportedLocale';

import { stageWrapper } from './Plugin.css';
import { useInjectScript } from './useInjectScript';
import { ViewMode } from '@/utils/useViewMode';

type StageElementProps = {
  id: string;
  script: string;
  isStudioEdit: boolean;
  contentLanguage: SupportedLocale;
  uiLanguage: string;
  viewMode?: ViewMode;
  workMode?: string;
  assignmentMode?: string;
  role?: string;
  isQuestion: boolean;
};

export const StageElement = (props: StageElementProps) => {
  const {
    id,
    script,
    isStudioEdit,
    contentLanguage,
    uiLanguage,
    viewMode,
    workMode,
    role,
    assignmentMode,
    isQuestion
  } = props;

  const attributes = useMemo(
    () => [
      { name: 'data-widgetid', value: id },
      { name: 'data-isstudioedit', value: `${isStudioEdit}` },
      { name: 'data-element', value: 'stage' },
      { name: 'data-uilanguage', value: uiLanguage },
      { name: 'data-contentlanguage', value: contentLanguage },
      { name: 'data-viewmode', value: viewMode ?? '' },
      { name: 'data-workmode', value: workMode ?? '' },
      { name: 'data-role', value: role ?? '' },
      { name: 'data-assignmentmode', value: assignmentMode ?? '' }
    ],
    [contentLanguage, id, isStudioEdit, uiLanguage, viewMode, workMode, role, assignmentMode]
  );

  useInjectScript(script, attributes, id);

  const stageWrapperStyleKey = isQuestion ? 'question' : 'content';

  return <div data-pluginstageelement={id} className={stageWrapper[stageWrapperStyleKey]} />;
};
