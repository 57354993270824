import { ActionIcon, Box } from '@mantine/core';

import { actionIcon, contentWrap } from './AdditionalAction.css';
import { WidgetAdditionalAction } from '@/types/Stage';

export const AdditionalAction = (props: WidgetAdditionalAction) => {
  const { actionName, Icon, onClick, dataTestId } = props;

  return (
    <ActionIcon variant="transparent" className={actionIcon} onClick={onClick} data-testid={dataTestId}>
      <Box className={contentWrap}>
        <Icon />
        <Box>{actionName}</Box>
      </Box>
    </ActionIcon>
  );
};
