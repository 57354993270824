import { rem } from '@mantine/core';
import { memo, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';

import { TableMenu } from './TableMenu';
import { useStyles as useTooltipStyles } from './TableTooltip.styles';
import { useHighlightRowOnHover } from './hooks/useHighlightRowOnHover';
import { RowId } from '@/widgets/Table/utils';

type TableRowMenuAreaProps = {
  rowId: RowId;
  dragRef: ConnectDragSource | null;
  isGlobalDragging: boolean;
  rowHeight: number;
};

const handleOverlappingTooltips = (isHovered: boolean) => ({
  zIndex: isHovered ? 2 : 1
});

export const TableRowMenuArea = memo(function TableRowMenuArea(props: TableRowMenuAreaProps) {
  const { rowId, dragRef, isGlobalDragging, rowHeight } = props;
  const highlightElementRef = useHighlightRowOnHover(rowId, isGlobalDragging);
  const [isHovered, setIsHovered] = useState(false);

  const { classes } = useTooltipStyles({ isDndPreview: dragRef === null, isGlobalDragging });

  return (
    <div
      className={classes.tooltipRowAreaCell}
      ref={highlightElementRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...handleOverlappingTooltips(isHovered),
        height: `${rem(rowHeight)}`
      }}
    >
      <TableMenu type="row" rowId={rowId} dragRef={dragRef} />
    </div>
  );
});
