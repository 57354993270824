import { Box, Flex, rem, Text } from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import dynamic from 'next/dynamic';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import { OpenQuestionElementType, OpenQuestionValue } from 'shared/types/OpenQuestion';
import { PlayerFile } from 'shared/types/PlayerFile';

import { MultipleFilesButton } from './MultipleFilesButton/MultipleFilesButton';
import { FileAnswerThumbnails } from './Thumbnails/FileAnswerThumbnails';
import { ImageAnswerThumbnails } from './Thumbnails/ImageAnswerThumbnails';
import { UploadButton } from './UploadButton/UploadButton';
import { GeneralDocument } from '@/icons/GeneralDocument';
import { Picture } from '@/icons/Picture';
import { useFileUpload } from '@/queries/fileUpload';
import { useWorkMode } from '@/utils/useWorkMode';
import { recordingStyles } from '@/widgets/OpenQuestion/OpenQuestion.css';
import { AnswerOptionButton } from '@/widgets/OpenQuestion/common/AnswerOptionButton';

// SSR must be disabled because it's using web API
const RecordingAnswer = dynamic(
  () => import('@/widgets/OpenQuestion/common/RecordingAnswer').then(_ => _.RecordingAnswer),
  { ssr: false }
);

type AnswerProviderProps = {
  value?: OpenQuestionValue;
  disabled?: boolean;
  onChange?: (element: OpenQuestionElementType, answer: unknown[]) => void;
  visibleElements: Record<OpenQuestionElementType, boolean>;
};

const maxUploadedFiles = 5;
const maxFileSize = 5242880; // 5mb

export function AnswerProvider(props: AnswerProviderProps) {
  const { value, disabled, visibleElements, onChange } = props;
  const { file: isFileVisible, image: isImageVisible } = visibleElements;
  const t = useTranslations('widgets');

  const { isPlayer, isStudioEdit } = useWorkMode();

  const images = useMemo(() => value?.image ?? [], [value]);
  const files = useMemo(() => value?.file ?? [], [value]);
  const [errorMessage, setErrorMessage] = useState('');
  const { mutateAsync: onUploadFile } = useFileUpload({
    errorMessage: `${t('openQuestion.unableUpload')}`
  });

  const handleAddFile = useCallback(
    (newFileName: PlayerFile) => {
      const newFiles = files.concat(newFileName);
      onChange?.('file', newFiles);
    },
    [files, onChange]
  );

  const handleDeleteFile = useCallback(
    (index: number) => {
      const newFiles = files.filter((_, i) => i !== index);
      onChange?.('file', newFiles);
    },
    [files, onChange]
  );

  const handleAddImage = useCallback(
    (newImage: PlayerFile) => {
      const newImages = images.concat(newImage);
      onChange?.('image', newImages);
    },
    [images, onChange]
  );

  const handleDeleteImage = useCallback(
    (index: number) => {
      const newImages = images.filter((_, i) => i !== index);
      onChange?.('image', newImages);
    },
    [images, onChange]
  );

  const handleRecordingChange = useCallback(
    (newRecordingUrls: string[]) => {
      onChange?.('recording', newRecordingUrls);
    },
    [onChange]
  );

  const isMultiple = isFileVisible && isImageVisible;
  const isUploadImageButtonDisabled = disabled || images.length >= 5;
  const isUploadFileButtonDisabled = disabled || files.length >= maxUploadedFiles;

  const handleUploadMedia = useCallback(
    (type: 'image' | 'file') => async (file: File) => {
      if (!file) {
        return;
      }

      const { name, size } = file;
      if (size > maxFileSize) {
        setErrorMessage(t('fileAnswer.fileIsTooHeavyError') ?? '');
        return;
      }

      setErrorMessage('');

      const src = isPlayer ? await onUploadFile(file) : URL.createObjectURL(file);
      if (!src) {
        return;
      }

      return { src, name };
    },
    [isPlayer, onUploadFile, t]
  );

  const handleUploadImage = useCallback(
    async (file: File) => {
      const newItem = await handleUploadMedia('image')(file);
      if (newItem) {
        handleAddImage(newItem);
      }
    },
    [handleAddImage, handleUploadMedia]
  );

  const handleUploadFile = useCallback(
    async (file: File) => {
      const newItem = await handleUploadMedia('file')(file);
      if (newItem) {
        handleAddFile(newItem);
      }
    },
    [handleAddFile, handleUploadMedia]
  );

  return (
    <>
      {visibleElements.recording && (
        <Box mt={rem(16)} className={recordingStyles}>
          <RecordingAnswer defaultValue={value?.recording} disabled={disabled} onChange={handleRecordingChange} />
        </Box>
      )}
      {isMultiple ? (
        <Box mt={rem(16)}>
          <MultipleFilesButton
            handleUploadFile={handleUploadFile}
            handleUploadImage={handleUploadImage}
            isFileButtonDisabled={isUploadFileButtonDisabled}
            isImageButtonDisabled={isUploadImageButtonDisabled}
            showFileButton={isFileVisible}
            showImageButton={isImageVisible}
          />
        </Box>
      ) : isFileVisible || isImageVisible ? (
        <Flex mt={rem(16)} gap={rem(16)}>
          {isFileVisible && (
            <UploadButton
              addButton={({ onClick }) => (
                <AnswerOptionButton
                  disabled={isUploadFileButtonDisabled}
                  icon={<GeneralDocument width={30} height={30} />}
                  label={t('fileAnswer.addFile')}
                  onClick={isStudioEdit ? () => console.log('studio edit') : onClick}
                />
              )}
              disabled={isUploadFileButtonDisabled}
              handleUpload={handleUploadFile}
            />
          )}
          {isImageVisible && (
            <UploadButton
              accept={IMAGE_MIME_TYPE.join(',')}
              addButton={({ onClick }) => (
                <AnswerOptionButton
                  disabled={isUploadImageButtonDisabled}
                  label={t('imageAnswer.addImage')}
                  icon={<Picture />}
                  onClick={isStudioEdit ? () => console.log('studio edit') : onClick}
                />
              )}
              disabled={isUploadImageButtonDisabled}
              handleUpload={handleUploadImage}
            />
          )}
        </Flex>
      ) : null}

      {(images.length > 0 || files.length > 0 || errorMessage) && (
        <Flex mt={rem(10)} wrap="wrap" direction="row">
          <ImageAnswerThumbnails images={images} onDeleteImage={handleDeleteImage} isDeleteDisabled={disabled} />
          <FileAnswerThumbnails files={files} onDeleteFile={handleDeleteFile} isDeleteDisabled={disabled} />
          {errorMessage && <Text c="red">{errorMessage}</Text>}
        </Flex>
      )}
    </>
  );
}
