import * as Y from 'yjs';

import { Base } from './Base';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useTextWidgetStyles } from '@/utils/typographyStyles.utils';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';

export function Text() {
  const { document, id } = useContentObject();
  const [placeholder] = useContentObjectProperty<string>('placeholder');
  const [fragment] = useContentObjectProperty<Y.XmlFragment>('fragment');
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const widgetStyles = useTextWidgetStyles(id);

  return (
    <div className={customClassNames}>
      <Base placeholder={placeholder} fragment={fragment} widgetStyles={widgetStyles} />
    </div>
  );
}
