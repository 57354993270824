import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { prosemirrorJSONToYXmlFragment, yXmlFragmentToProsemirrorJSON } from 'y-prosemirror';
import * as Y from 'yjs';

import { Heading } from 'shared/tiptap/heading';

import { BaseTooltipMark } from './extensions/Tooltip/Tooltip';
import { CustomLink } from '@/utils/tiptap/extensions/customLink';
import { FontFamily } from '@/utils/tiptap/extensions/fontFamily';
import { FontSize } from '@/utils/tiptap/extensions/fontSize';
import { Image } from '@/utils/tiptap/extensions/image';
import { LineHeight } from '@/utils/tiptap/extensions/lineHeight';
import { TextDirection } from '@/utils/tiptap/extensions/textDirection';

type GetDefaultExtensionsOptions = {
  useNativeHistory?: boolean;
};
export const getDefaultExtensions = (options?: GetDefaultExtensionsOptions) => {
  const { useNativeHistory = true } = options ?? {};
  return [
    Color,
    CustomLink,
    FontFamily,
    FontSize,
    LineHeight,
    Highlight.configure({
      multicolor: true
    }),
    Image,
    useNativeHistory
      ? StarterKit.configure({ heading: false })
      : StarterKit.configure({ heading: false, history: false }),
    TextDirection.configure({
      types: ['heading', 'paragraph']
    }),
    TextStyle,
    Underline,
    BaseTooltipMark,
    Heading
  ];
};

export const htmlToXmlFragment = (content: string) => {
  const editor = new Editor({
    extensions: getDefaultExtensions(),
    content
  });

  const schema = editor.schema;
  const jsonContent = editor.getJSON();

  return prosemirrorJSONToYXmlFragment(schema, jsonContent);
};

export const yXmlFragmentToText = (fragment: Y.XmlFragment) => {
  const content = yXmlFragmentToProsemirrorJSON(fragment);
  const editor = new Editor({
    extensions: getDefaultExtensions(),
    content
  });

  return editor.getText();
};

export const checkIfYXmlFragmentHasContent = (fragment: Y.XmlFragment | undefined) => {
  if (!fragment || !fragment.length) {
    return false;
  }

  if (fragment.length !== 1) return true;

  const node = fragment.get(0);
  const nodeContent = node.toString();
  const nodeInnerContent = nodeContent.replace(/^<[^>]+>|<\/[^>]+>$/g, '').trim();

  return nodeInnerContent.length > 0;
};
