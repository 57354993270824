import { Flex, Text } from '@mantine/core';

import { Chevron } from '@/icons/Chevron';
import { useCorrectClozeOption } from '@/widgets/ClozeQuestion/hooks/useCorrectClozeOption';

type ClozeOptionValueSelectProps = { clozeId: string; className: string };

export function ClozeOptionValueSelect(props: ClozeOptionValueSelectProps) {
  const { clozeId, className } = props;

  const correctOption = useCorrectClozeOption(clozeId);

  return (
    <Flex direction="row" align="center">
      <Text truncate mb={0} className={className}>
        {correctOption?.value}
      </Text>
      <Chevron className={className} style={{ transform: 'scaleY(-100%)', minWidth: 24 }} />
    </Flex>
  );
}
