import { Flex, Text } from '@mantine/core';
import { ComponentType } from 'react';

import { useStyles } from '@/components/UploadFile/UploadFile.styles';

type DropzoneContentProps = {
  isRejected: boolean;
  Icon: ComponentType;
  text?: string;
  subText?: string;
  isThumbnail?: boolean;
};

export function DropzoneContent(props: DropzoneContentProps) {
  const { Icon, text, isRejected, subText, isThumbnail } = props;
  const { classes } = useStyles({ isRejected, isThumbnail });

  return (
    <Flex className={classes.dropzoneContent}>
      <Flex className={classes.dropzoneIcon}>
        <Icon />
      </Flex>
      <Flex className={classes.dropzoneContentText}>
        {text && <Text>{text}</Text>}
        {isThumbnail && subText && <Text fw={700}>{subText}</Text>}
      </Flex>
    </Flex>
  );
}
