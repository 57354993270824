import { useMantineTheme } from '@mantine/core';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { useStyles } from './Table.styles';
import { TableBody } from './TableBody';
import { TableCaption } from './TableCaption';
import { TableHead } from './TableHead';
import { useJoinedWidgetCustomCSSClasses } from '@/utils/customCSSClasses';
import { useWidgetStyle } from '@/utils/useWidgetStyle';
import { useTable } from '@/widgets/Table/hooks/useTable';
import { CSS_VARS, getColumns, getRows } from '@/widgets/Table/utils';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const TableImplementation = memo(function TableImplementation() {
  const rowOrder = useTable(context => context.rowOrder);
  const columnOrder = useTable(context => context.columnOrder);
  const columnWidths = useTable(context => context.columnWidths);

  const { document, id } = useContentObject();
  const customClassNames = useJoinedWidgetCustomCSSClasses(id, document);

  const data = useMemo(() => {
    return getRows(rowOrder, columnOrder);
  }, [columnOrder, rowOrder]);

  const columns = useMemo(() => {
    return getColumns(columnOrder, true);
  }, [columnOrder]);

  const { classes } = useStyles();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const columnSizeVars = useMemo(() => {
    return table.getAllFlatColumns().reduce((sizes, column) => {
      sizes[CSS_VARS.ColumnSize(column.id)] = columnWidths[column.id];
      return sizes;
    }, {} as { [key: string]: number });
  }, [table, columnWidths]);

  const {
    other: {
      table: { isCaptionVisible: themeIsCaptionVisible, isHeaderVisible: themeIsHeaderVisible }
    }
  } = useMantineTheme();
  const [isCaptionVisible] = useWidgetStyle<boolean | null>(document, id, 'isCaptionVisible');
  const [isHeaderVisible] = useWidgetStyle<boolean | null>(document, id, 'isHeaderVisible');

  return (
    <div className={customClassNames}>
      <div className={classes.divTable} style={{ ...columnSizeVars }}>
        {(isCaptionVisible ?? themeIsCaptionVisible) && <TableCaption />}
        {(isHeaderVisible ?? themeIsHeaderVisible) && <TableHead headerGroups={table.getHeaderGroups()} />}
        <TableBody rows={table.getRowModel().rows} />
      </div>
    </div>
  );
});
