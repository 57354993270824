// Every 'answerCount' value should be >= 0
const areValuesPositive = (values: number[]) => {
  return values.every(val => val >= 0);
};

/**
 * Version B. Less penalty points
 */
export const calculateSelectionQuestionPercentageScore = ({
  allPossibleAnswersCount,
  allPossibleRightAnswersCount,
  rightAnswersCount,
  wrongAnswersCount
}: {
  allPossibleAnswersCount: number;
  allPossibleRightAnswersCount: number;
  rightAnswersCount: number;
  wrongAnswersCount: number;
}) => {
  if (
    !areValuesPositive([allPossibleAnswersCount, allPossibleRightAnswersCount, rightAnswersCount, wrongAnswersCount]) ||
    allPossibleRightAnswersCount > allPossibleAnswersCount ||
    rightAnswersCount > allPossibleRightAnswersCount ||
    wrongAnswersCount > allPossibleAnswersCount ||
    rightAnswersCount + wrongAnswersCount > allPossibleAnswersCount
  ) {
    // Answers were incorrectly counted - return early
    return 0;
  }

  const noAnswersSelected = rightAnswersCount === 0 && wrongAnswersCount === 0;
  if (noAnswersSelected) {
    return 0;
  }

  const allAnswersSelected = rightAnswersCount + wrongAnswersCount === allPossibleAnswersCount;
  if (allAnswersSelected) {
    const allPossibleAnswersAreCorrect = allPossibleAnswersCount === allPossibleRightAnswersCount;
    return allPossibleAnswersAreCorrect ? 100 : 0;
  }

  const score = rightAnswersCount / (allPossibleRightAnswersCount + wrongAnswersCount);
  return Math.round(score * 100);
};

export const calculateClozeQuestionPercentageScore = ({
  allAnswersCount,
  rightAnswersCount,
  wrongAnswersCount
}: {
  allAnswersCount: number;
  rightAnswersCount: number;
  wrongAnswersCount: number;
}) => {
  if (
    !areValuesPositive([allAnswersCount, rightAnswersCount, wrongAnswersCount]) ||
    rightAnswersCount > allAnswersCount ||
    wrongAnswersCount > allAnswersCount ||
    rightAnswersCount + wrongAnswersCount > allAnswersCount
  ) {
    // Answers were incorrectly counted - return early
    return 0;
  }

  const noAnswersSelected = rightAnswersCount === 0 && wrongAnswersCount === 0;
  if (noAnswersSelected) {
    return 0;
  }

  const score = rightAnswersCount / allAnswersCount;
  return Math.round(score * 100);
};
