import * as Y from 'yjs';

import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { genRandId } from '../../utils/genRandId';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { WidgetType } from '../../utils/widgets';
import { TextConfig } from '../questionConfigs';

export function createNewTextData(
  options: InitializePropsOptions,
  initializationConfig: TextConfig
): ManifestInitializedProps {
  const id = genRandId();

  const { content, textType, withParagraphOption } = initializationConfig ?? {};
  const fragment = htmlToXmlFragment(content);

  return [
    {
      id,
      contentObjectData: new Y.Map([
        ['id', id],
        ['type', WidgetType.Text],
        ['textType', textType],
        ['withParagraphOption', withParagraphOption],
        ['children', new Y.Array()],
        ['fragment', fragment],
        ['placeholder', ''],
        ['style', new Y.Map()]
      ])
    }
  ];
}
