import { ManifestPreviews, ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { manifestLocales } from 'shared/widgetsSDK/SelectionQuestion/manifest.locales';
import { widgetsCreateFunctions } from 'shared/widgetsSDK/widgetsCreateFunctions';

import MultiChoiceEn from './resources/en/multi-choice.svg';
import MultiChoiceHe from './resources/he/multi-choice.svg';
import { QuestionsCheckbox } from '@/icons/QuestionsCheckbox';

export const Manifest: ManifestType = {
  name: WidgetType.SelectionQuestion,
  previewGroups: [
    {
      id: 'selectionQuestion',
      divider: {
        icon: QuestionsCheckbox,
        title: 'selectionQuestion'
      },
      previews: [
        {
          name: 'multiChoicePreview',
          previewImage: {
            paths: {
              en: MultiChoiceEn,
              he: MultiChoiceHe
            },
            size: {
              width: 140,
              height: 122
            },
            alt: { ...manifestLocales.multiChoice.previewMultiChoiceAlt }
          },
          dimensions: {
            width: 1180,
            height: 'auto'
          },
          initializeProps: widgetsCreateFunctions[WidgetType.SelectionQuestion] as ManifestPreviews['initializeProps']
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 100
    }
  }
};
