import { createStyles } from '@mantine/emotion';

import { getThemeBorderStyle, getThemeBorderRadiusStyle } from '@/utils/theme.utils';

export const useStyles = createStyles(theme => {
  const mediaStyle = theme.other.media;
  const borderStyle = getThemeBorderStyle(mediaStyle.border);
  const borderRadiusStyle = getThemeBorderRadiusStyle(mediaStyle.borderRadius);

  return {
    video: {
      pointerEvents: 'none',
      height: '100%',
      width: '100%',
      ...borderStyle,
      ...borderRadiusStyle
    }
  };
});
