import { SimpleGrid } from '@mantine/core';
import { ReactNode } from 'react';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { useStyles } from './AnswersGrid.styles';
import { useDeviceView } from '@/utils/deviceHooks';

type AnswersGridProps = { children: ReactNode; isTextOnly?: boolean; columns: number; answersStyle: AnswerStyleType };

export const AnswersGrid = (props: AnswersGridProps) => {
  const { answersStyle, children, columns, isTextOnly } = props;
  const { deviceView } = useDeviceView();

  const { classes } = useStyles({ columns, isTextOnly, answersStyle, deviceView });

  return <SimpleGrid className={classes.grid}>{children}</SimpleGrid>;
};
