import { Dispatch, SetStateAction, useEffect } from 'react';

import { useSelectableStore } from '@/components/Selectable';
import { PluginEvent } from '@/contentObjects/Plugin/pluginEvent';

export function useEvaluatableStudioEvents(id: string, setAllowEdit: Dispatch<SetStateAction<boolean>>) {
  const select = useSelectableStore(state => state.select);

  useEffect(() => {
    const handleSelectPlugin = (e: Event) => {
      select(id);
    };

    document.addEventListener(`${PluginEvent.selectPluginEvent}-${id}`, handleSelectPlugin);
    return () => {
      document.removeEventListener(`${PluginEvent.selectPluginEvent}-${id}`, handleSelectPlugin);
    };
  }, [id, select]);

  useEffect(() => {
    const handleAllowEditPlugin = (e: Event) => {
      setAllowEdit(true);
    };

    document.addEventListener(`${PluginEvent.allowEditPluginEvent}-${id}`, handleAllowEditPlugin);
    return () => {
      document.removeEventListener(`${PluginEvent.allowEditPluginEvent}-${id}`, handleAllowEditPlugin);
    };
  }, [id, setAllowEdit]);
}
