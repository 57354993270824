import { useGetAssignmentStatus } from '@/queries/assignment';
import { useUser } from '@/queries/user';
import { useContentLanguage } from '@/utils/useContentLanguage';
import { useUiLanguage } from '@/utils/useUiLanguage';
import { useViewMode } from '@/utils/useViewMode';
import { useWorkMode } from '@/utils/useWorkMode';

export function useDataAttributes() {
  const { contentLanguage } = useContentLanguage();
  const { uiLanguage } = useUiLanguage();

  const [viewMode] = useViewMode();

  const { isPlayer, isStudioPreview, isPlayerActivity, isPlayerAssignment, isPlayerAssignmentTest, isStudioEdit } =
    useWorkMode();
  const workModeData: Record<string, boolean> = {
    isStudioPreview,
    isPlayerActivity,
    isPlayerAssignmentTest,
    isPlayerAssignment
  };
  const workMode = Object.keys(workModeData).find(key => workModeData[key]);

  const { user } = useUser();
  const role = user?.role;

  const { isEvaluated, isReturnedToFix, isSubmitted, isUnsubmitted } = useGetAssignmentStatus({ enabled: isPlayer });
  const assignmentModeData: Record<string, boolean> = { isEvaluated, isReturnedToFix, isSubmitted, isUnsubmitted };
  const assignmentMode = Object.keys(assignmentModeData).find(key => assignmentModeData[key]);

  return {
    contentLanguage,
    uiLanguage,
    isStudioEdit,
    viewMode,
    workMode,
    role,
    assignmentMode
  };
}
