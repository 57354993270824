import { FontType } from '@hendrx/modules/ui';
import localFont from 'next/font/local';

const Alef = localFont({
  variable: '--font-alef',
  src: [
    {
      path: './Alef/Alef-Bold.woff2',
      weight: '700',
      style: 'normal'
    },
    {
      path: './Alef/Alef-Regular.woff2',
      weight: '400',
      style: 'normal'
    }
  ],
  preload: true
});

const Arimo = localFont({
  variable: '--font-arimo',
  src: [
    {
      path: './Arimo/Arimo-VariableFont_wght.woff2',
      style: 'normal'
    },
    {
      path: './Arimo/Arimo-Italic-VariableFont_wght.woff2',
      style: 'italic'
    }
  ],
  preload: true
});

const Assistant = localFont({
  variable: '--font-assistant',
  src: [
    {
      path: './Assistant/Assistant-VariableFont_wght.woff2',
      style: 'normal'
    }
  ],
  preload: false
});

const Heebo = localFont({
  variable: '--font-heebo',
  src: [
    {
      path: './Heebo/Heebo-VariableFont_wght.woff2',
      style: 'normal'
    }
  ],
  preload: false
});

const Lateef = localFont({
  variable: '--font-lateef',
  src: [
    {
      path: './Lateef/Lateef-Regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: './Lateef/Lateef-Bold.woff2',
      weight: '700',
      style: 'normal'
    }
  ],
  preload: false
});

const NotoSans = localFont({
  variable: '--font-notoSans',
  src: [
    {
      path: './NotoSans/NotoSans-Regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: './NotoSans/NotoSans-Bold.woff2',
      weight: '700',
      style: 'normal'
    }
  ],
  preload: false
});

const OpenSans = localFont({
  variable: '--font-openSans',
  src: [
    {
      path: './OpenSans/OpenSans-VariableFont_wdth,wght.woff2',
      style: 'normal'
    },
    {
      path: './OpenSans/OpenSans-Italic-VariableFont_wdth,wght.woff2',
      style: 'italic'
    }
  ],
  preload: false
});

const fonts: FontType[] = [
  { value: 'var(--font-alef)', label: 'Alef', variable: Alef.variable },
  { value: 'var(--font-arimo)', label: 'Arimo', variable: Arimo.variable },
  { value: 'var(--font-assistant)', label: 'Assistant', variable: Assistant.variable },
  { value: 'var(--font-heebo)', label: 'Heebo', variable: Heebo.variable },
  { value: 'var(--font-lateef)', label: 'Lateef', variable: Lateef.variable },
  { value: 'var(--font-notoSans)', label: 'NotoSans', variable: NotoSans.variable },
  { value: 'var(--font-openSans)', label: 'OpenSans', variable: OpenSans.variable }
];

export { fonts };
