import { Box } from '@mantine/core';
import { forwardRef } from 'react';

import { useStyles } from './DragHandle.styles';
import { DragHandle as DragHandleIcon } from '@/icons/DragHandle';

export type DragHandleProps = {
  orientation: 'side' | 'center';
  strength: 2 | 3 | 4 | 5 | 6;
  offset?: number;
  variant?: 'default' | 'hover';
  isResizing?: boolean;
};

export const DragHandle = forwardRef<HTMLDivElement, DragHandleProps>(function DragHandle(props, ref) {
  const { classes } = useStyles(props);

  return (
    <Box className={classes.dragHandleIcon} ref={ref}>
      <Box className={classes.dragHandleWrapper} data-testid="drag-handle-button">
        <DragHandleIcon />
      </Box>
    </Box>
  );
});
