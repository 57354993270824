import { MutableRefObject, RefObject } from 'react';

import { WidgetType } from 'shared/utils/widgets';

// eslint-disable-next-line boundaries/element-types
import { DND_PADDING_PX } from 'shared/widgetsSDK/Stage.utils';

import { Direction, ResizableDimensions, isHeightControlled } from '@/components/Resizable/types';
import { Direction as LocaleDirection } from '@/utils/locale.utils';

type ResizeAction = {
  dimensionsRef: MutableRefObject<{ width: number; height: number | 'auto' }>;
  scaledMouseX: number;
  scaledMouseY: number;
  originalWidth: number;
  isResizableVertically: boolean;
  setMoveStartDir: (value: number) => void;
  stageScale: number;
  minWidth: number | undefined;
  resizableRef: RefObject<HTMLDivElement>;
  sectionRef: RefObject<HTMLDivElement>;
  contentDirection: LocaleDirection;
  sectionType: WidgetType;
  coordsRef: MutableRefObject<{ x: number; y: number }>;
};

type Fn = (action: ResizeAction) => Partial<ResizableDimensions>;

const shouldStopResize = (action: ResizeAction, resizeDirection: 'start' | 'end') => {
  const {
    coordsRef,
    contentDirection,
    stageScale,
    dimensionsRef,
    scaledMouseX,
    resizableRef,
    sectionRef,
    minWidth = 0
  } = action;

  if (!resizableRef.current || !sectionRef.current) {
    return true;
  }

  const { left: resizableLeft, right: resizableRight } = resizableRef.current.getBoundingClientRect();
  const { left: sectionLeft, right: sectionRight } = sectionRef.current.getBoundingClientRect();

  const isMovingLeft = resizeDirection === 'start' ? scaledMouseX < 0 : scaledMouseX > 0;
  const isMovingToStart = contentDirection === 'ltr' ? resizeDirection === 'start' : resizeDirection === 'end';

  const isInFlexSection = action.sectionType === WidgetType.FlexSection;
  const additionalPadding = isInFlexSection ? 0 : DND_PADDING_PX * stageScale;

  const limitToResizable = isMovingToStart
    ? coordsRef.current.x >= resizableLeft
    : coordsRef.current.x <= resizableRight;

  const limitResizableToSection = isMovingToStart
    ? resizableLeft <= sectionLeft + additionalPadding
    : resizableRight >= sectionRight - additionalPadding;

  const limitToSection = isMovingToStart
    ? coordsRef.current.x <= sectionLeft + additionalPadding
    : coordsRef.current.x >= sectionRight - additionalPadding;

  const limitToConstraints = isMovingLeft ? limitToResizable : dimensionsRef.current.width <= minWidth;
  const limitToSectionBorder = isMovingLeft ? limitResizableToSection : limitToSection;

  return limitToConstraints || limitToSectionBorder;
};

export const handleUpStart: Fn = action => {
  const { dimensionsRef, scaledMouseX, isResizableVertically, setMoveStartDir, originalWidth } = action;
  const newWidth = dimensionsRef.current.width - scaledMouseX;
  const newHeight = isHeightControlled(isResizableVertically, dimensionsRef.current)
    ? (dimensionsRef.current.height / dimensionsRef.current.width) * newWidth
    : ('auto' as const);
  if (shouldStopResize(action, 'start')) {
    setMoveStartDir(originalWidth - dimensionsRef.current.width);
    return { width: dimensionsRef.current.width, height: newHeight };
  } else {
    setMoveStartDir(originalWidth - newWidth);
    return { width: newWidth, height: newHeight };
  }
};

export const handleStart: Fn = action => {
  const { dimensionsRef, scaledMouseX, originalWidth, setMoveStartDir } = action;
  const newWidth = dimensionsRef.current.width - scaledMouseX;
  if (shouldStopResize(action, 'start')) {
    setMoveStartDir(originalWidth - dimensionsRef.current.width);
    return {
      width: dimensionsRef.current.width
    };
  }
  setMoveStartDir(originalWidth - newWidth);
  return { width: newWidth };
};

export const handleDownStart = handleUpStart;

export const handleDown: Fn = action => {
  const { dimensionsRef, scaledMouseY, isResizableVertically } = action;
  const newHeight = isHeightControlled(isResizableVertically, dimensionsRef.current)
    ? dimensionsRef.current.height + scaledMouseY
    : ('auto' as const);
  return { height: newHeight };
};

export const handleDownEnd: Fn = action => {
  const { dimensionsRef, scaledMouseX, isResizableVertically } = action;
  const newWidth = dimensionsRef.current.width + scaledMouseX;
  const newHeight = isHeightControlled(isResizableVertically, dimensionsRef.current)
    ? (dimensionsRef.current.height / dimensionsRef.current.width) * newWidth
    : ('auto' as const);
  return { width: shouldStopResize(action, 'end') ? dimensionsRef.current.width : newWidth, height: newHeight };
};

export const handleEnd: Fn = action => {
  const { dimensionsRef, scaledMouseX } = action;
  const newWidth = dimensionsRef.current.width + scaledMouseX;
  return { width: shouldStopResize(action, 'end') ? dimensionsRef.current.width : newWidth };
};

export const handleUpEnd: Fn = action => {
  const { dimensionsRef, scaledMouseX, scaledMouseY, isResizableVertically } = action;
  const newWidth = dimensionsRef.current.width + scaledMouseX;
  const newHeight = isHeightControlled(isResizableVertically, dimensionsRef.current)
    ? dimensionsRef.current.height - scaledMouseY
    : ('auto' as const);
  return { width: shouldStopResize(action, 'end') ? dimensionsRef.current.width : newWidth, height: newHeight };
};

export const resizeHandler = (resizingDirection?: Direction) => {
  switch (resizingDirection) {
    case 'up-start':
      return handleUpStart;
    case 'start':
      return handleStart;
    case 'down-start':
      return handleDownStart;
    case 'down':
      return handleDown;
    case 'down-end':
      return handleDownEnd;
    case 'end':
      return handleEnd;
    case 'up-end':
      return handleUpEnd;
    default:
      return null;
  }
};
