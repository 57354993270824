import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';

import { BarChartAxisLabel } from './BarChartAxisLabel';
import { ASPECT_RATIO, ChartVariant, Data, FONT_SIZE, HORIZONTAL_MARGIN, IMG_WIDTH, TEXT_WIDTH } from './Chart';
import { LabelListContent } from './LabelListContent';
import { Direction } from '@/utils/locale.utils';
import { useUiLanguage } from '@/utils/useUiLanguage';

const barRadius: [number, number, number, number] = [0, 6, 6, 0];

type VerticalBarChartProps = {
  data: Data;
  directionStyle: { direction: Direction };
  getBarColor: (index: number, isCorrect?: boolean) => string;
  variant: ChartVariant;
  dataHasImage?: boolean;
  dataHasLabel?: boolean;
};

export const VerticalBarChart = (props: VerticalBarChartProps) => {
  const { data, dataHasImage, dataHasLabel, directionStyle, getBarColor, variant } = props;

  const theme = useMantineTheme();
  const { uiDirection } = useUiLanguage();

  const dataWithImageAndLabel = dataHasImage && dataHasLabel;

  const imageWithTextWidth = TEXT_WIDTH + IMG_WIDTH + HORIZONTAL_MARGIN;
  const yAxisWidth =
    (dataWithImageAndLabel ? imageWithTextWidth : dataHasLabel ? TEXT_WIDTH : IMG_WIDTH) + HORIZONTAL_MARGIN;

  const margin = useMemo(
    () =>
      uiDirection === 'ltr'
        ? {
            top: 0,
            bottom: 0,
            right: variant === 'validated' ? HORIZONTAL_MARGIN * 5 : HORIZONTAL_MARGIN * 2,
            left: 0
          }
        : {
            top: 0,
            bottom: 0,
            right: HORIZONTAL_MARGIN * 2,
            left: variant === 'validated' ? HORIZONTAL_MARGIN * 5 : HORIZONTAL_MARGIN * 2
          },
    [uiDirection, variant]
  );

  return (
    <ResponsiveContainer aspect={ASPECT_RATIO} style={directionStyle}>
      <BarChart data={data} layout="vertical" margin={margin}>
        <XAxis type="number" hide reversed={uiDirection === 'rtl'} />
        <YAxis
          type="category"
          dataKey="key"
          tickLine={false}
          tick={props => <BarChartAxisLabel {...props} data={data} direction="vertical" uiDirection={uiDirection} />}
          stroke="#495057"
          orientation={uiDirection === 'ltr' ? 'left' : 'right'}
          width={yAxisWidth}
        />
        <Bar dataKey="value" radius={barRadius}>
          <LabelList
            orientation={uiDirection === 'ltr' ? 'left' : 'right'}
            dataKey="value"
            position="right"
            fill={theme.colors.systemDark[8]}
            fontSize={FONT_SIZE}
            dx={uiDirection === 'ltr' ? HORIZONTAL_MARGIN / 2 : -HORIZONTAL_MARGIN}
            content={
              variant === 'validated' ? (
                <LabelListContent data={data} uiDirection={uiDirection} direction="vertical" />
              ) : undefined
            }
          />
          {data.map((dataItem, index) => (
            <Cell key={index} fill={getBarColor(index, dataItem.isCorrect)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
