import { Box, Title } from '@mantine/core';

import { useStyles } from './FormLabel.styles';

type FormLabelProps = {
  title?: string | null;
  subTitle?: string | null;
  withAsterisk?: boolean;
};

export function FormLabel(props: FormLabelProps) {
  const { title, subTitle, withAsterisk = false } = props;

  const { classes } = useStyles();

  if (!title) {
    return null;
  }
  return (
    <Title order={3} className={classes.propTitle}>
      {title}
      {subTitle && (
        <Box component="span" className={classes.propSubTitle}>
          {subTitle}
        </Box>
      )}
      {withAsterisk && (
        <Box component="span" className={classes.asterisk}>
          *
        </Box>
      )}
    </Title>
  );
}
