import { MicrosoftDocumentType } from 'shared/types/MicrosoftEmbed';

import { microsoftDocumentPlaceholderIcons } from '@/widgets/MicrosoftEmbed/icons';
import { createOfficeWebViewerUrl } from '@/widgets/MicrosoftEmbed/utils/createOfficeWebViewerUrl';
import { EmbedIframe } from '@/widgets/_components/EmbedIframe';

type BaseProps = {
  src: string;
  title: string;
  documentType: MicrosoftDocumentType;
};

export function Base(props: BaseProps) {
  const { src, title, documentType } = props;

  return (
    <EmbedIframe
      tabIndex={documentType === 'Excel' ? -1 : undefined}
      placeholderIcon={microsoftDocumentPlaceholderIcons[documentType]}
      workMode="Preview"
      title={title}
      src={createOfficeWebViewerUrl(src)}
    />
  );
}
