export const manifestLocales = {
  table: {
    en: 'Table',
    he: 'טבלה'
  },
  defaultCaption: {
    en: 'My Table',
    he: 'הטבלה שלי'
  }
};
