import { Flex, Loader } from '@mantine/core';
import { Dropzone, FileRejection } from '@mantine/dropzone';
import { useTranslations } from 'next-intl';
import { ComponentType, ReactNode, useRef, useState } from 'react';

import { DropzoneContent } from './DropzoneContent';
import { FileActions } from './FileActions';
import { FilePreview } from './FilePreview';
import { FileTypes } from './FileTypes';
import { useStyles } from './UploadFile.styles';
import { useFileUpload } from '@/queries/fileUpload';
import { humanFileSize } from '@/utils/core.utils';
import { showErrorNotification } from '@/utils/notifications';

export type UploadFileProps = {
  src?: string;
  alt?: string;
  Icon: ComponentType;
  text: string;
  maxSize?: number;
  maxFiles?: number;
  accept?: string[];
  onDrop: (src: string) => void;
  onDelete?: () => void;
  dropError: string;
  fileType?: FileTypes;
  previewMaxHeight?: number;
  aspectRatio?: string;
  isThumbnail?: boolean;
  CustomResetButton?: ReactNode;
  disabled?: boolean;
};

export function UploadFile(props: UploadFileProps) {
  const {
    fileType = 'image',
    src,
    alt = '',
    maxSize = 1_000_000,
    maxFiles = 1,
    accept,
    text,
    Icon,
    onDrop,
    dropError,
    onDelete,
    previewMaxHeight,
    aspectRatio = 'auto',
    isThumbnail,
    CustomResetButton,
    disabled
  } = props;

  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const { mutateAsync: uploadFile, isLoading } = useFileUpload();
  const uploadFileActionRef = useRef<() => void>(null);
  const [isDropped, setIsDropped] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const t = useTranslations('components.uploadFileActions');
  const utilsT = useTranslations('utils');

  const isFileReady = Boolean(src && !isLoading);

  const { classes } = useStyles({
    isRejected: rejectedFiles.length !== 0,
    aspectRatio,
    isThumbnail,
    disabled
  });

  const handleUpload = async (uploadedFiles: File[]) => {
    try {
      setRejectedFiles([]);
      setErrorMessage('');
      setIsDropped(true);
      const src = await uploadFile(uploadedFiles[0]);
      if (!src) return;
      onDrop(src);
    } catch (e) {
      setErrorMessage(dropError);
    } finally {
      setIsDropped(false);
    }
  };

  const handleReject = (files: FileRejection[]) => {
    setRejectedFiles(files);
    let message = '';
    files.forEach(({ errors }) => {
      if (errors[0].code === 'file-too-large') {
        message = t('fileTooLargeMessage', { maxSize: humanFileSize(maxSize) });
      } else if (errors[0].code === 'file-invalid-type') {
        message = t('fileInvalidTypeMessage');
      } else if (errors[0].code === 'too-many-files') {
        message = t('tooManyFilesMessage', { count: maxFiles });
      } else {
        message = dropError;
      }
    });
    setErrorMessage(message);
    if (isFileReady) showErrorNotification(message, utilsT('notification.error'));
  };

  const handleReplace = () => {
    uploadFileActionRef?.current?.();
  };

  const handleDelete = () => {
    setRejectedFiles([]);
    setErrorMessage('');
    setIsDropped(false);
    onDelete?.();
  };

  return (
    <>
      {src && isFileReady && (
        <Flex gap={16} direction="column">
          <FilePreview
            previewMaxHeight={previewMaxHeight}
            aspectRatio={aspectRatio}
            type={fileType}
            alt={alt}
            src={src}
            disabled={disabled}
          />
          <FileActions
            onDelete={onDelete && handleDelete}
            onReplace={handleReplace}
            CustomResetButton={CustomResetButton}
            disabled={disabled}
          />
        </Flex>
      )}
      <Dropzone
        sx={{ display: isFileReady ? 'none' : 'auto' }}
        openRef={uploadFileActionRef}
        onDrop={handleUpload}
        onReject={handleReject}
        maxSize={maxSize}
        maxFiles={maxFiles}
        accept={accept}
        classNames={{ root: classes.dropzone, inner: classes.dropzoneInner }}
        disabled={disabled}
      >
        <Flex justify="center" align="center" direction="column" h="100%">
          {rejectedFiles.length > 0 || errorMessage ? (
            <DropzoneContent
              Icon={Icon}
              isRejected={rejectedFiles.length !== 0}
              text={errorMessage}
              isThumbnail={isThumbnail}
            />
          ) : (
            <DropzoneContent
              Icon={isDropped ? Loader : Icon}
              text={text}
              isRejected={rejectedFiles.length !== 0}
              subText={`${t('maxSize', { maxSize: humanFileSize(maxSize) })}`}
              isThumbnail={isThumbnail}
            />
          )}
        </Flex>
      </Dropzone>
    </>
  );
}
