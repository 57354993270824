import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { QuestionGroupsType } from 'shared/types/QuestionGroups';
import { getQuestionGroups } from 'shared/widgetsSDK/questionGroup';

import { useCurrentDocument } from '@/components/YjsProvider';
import { useContentObjectsChanged } from '@/contentObjects/hooks/useContentObjectsChanged';
import { useSavedQuestionGroups } from '@/queries/state';
import { useWorkMode } from '@/utils/useWorkMode';

const QuestionGroupsContext = createContext<QuestionGroupsType | null>(null);

type QuestionGroupsContextProps = { children: ReactNode };

const emptyObject = {};

const useGroups = () => {
  const document = useCurrentDocument();

  const [questionGroups, setQuestionGroups] = useState<QuestionGroupsType>(getQuestionGroups(document, {}));

  const { isPlayer, isStudioPreview } = useWorkMode();

  const onChange = useCallback(() => {
    if (!isStudioPreview) return;
    setQuestionGroups(getQuestionGroups(document, {}));
  }, [document, isStudioPreview]);

  useContentObjectsChanged(onChange);

  const { data: savedQuestionGroups = emptyObject } = useSavedQuestionGroups();

  if (isPlayer) {
    return savedQuestionGroups;
  }

  if (isStudioPreview) {
    return questionGroups;
  }

  return emptyObject;
};

export function QuestionGroupsContextProvider(props: QuestionGroupsContextProps) {
  const { children } = props;

  const groups = useGroups();

  return <QuestionGroupsContext.Provider value={groups}>{children}</QuestionGroupsContext.Provider>;
}

export function useQuestionGroupsContext() {
  const questionGroups = useContext(QuestionGroupsContext);
  if (questionGroups === null) throw new Error('QuestionGroupsContext not initialized properly.');
  return questionGroups;
}
