import { memo } from 'react';
import * as Y from 'yjs';

import { useTable } from '@/widgets/Table/hooks/useTable';
import { RichText } from '@/widgets/_components/RichText';

type TableCellRendererProps = {
  contentId: string;
};

export const TableCellRenderer = memo(function TableCellRenderer(props: TableCellRendererProps) {
  const { contentId } = props;

  const content = useTable(context => context.content);

  const fragment = content?.get(contentId) as Y.XmlFragment;

  return <RichText fragment={fragment} editable={false} />;
});
