import { Box, Portal } from '@mantine/core';
import { ReactNode } from 'react';

import { useStyles } from './SideMenuPortal.styles';
import { useSelectableStore } from '@/components/Selectable';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useContentObjectIsSelected() {
  const { id } = useContentObject();
  return useSelectableStore(state => state.itemConfigId === id || state.selectedId === id);
}

type SideMenuPortalProps = {
  children: ReactNode;
};

export function SideMenuPortalBase(props: SideMenuPortalProps) {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <Portal target="#studio-settings-portal">
      <Box className={classes.wrapper} data-testid="widget-side-menu">
        {children}
      </Box>
    </Portal>
  );
}

export function SideMenuPortal(props: SideMenuPortalProps) {
  const { children } = props;
  const isContentObjectSelected = useContentObjectIsSelected();

  return <>{isContentObjectSelected && <SideMenuPortalBase>{children}</SideMenuPortalBase>}</>;
}
