import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => ({
  mobileIconPlus: {
    color: theme.colors.accent[6],
    width: rem(30),
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  subtitle: {
    padding: `${rem(16)} ${rem(12)}`,
    margin: 0
  }
}));
