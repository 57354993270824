import { ActionIcon, ColorPicker, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import { useTranslations } from 'next-intl';

import { useStyles } from './TextHighlightPicker.styles';
import { TextHighlight } from '@/icons/TextHighlight';

export type TextHighlightPickerProps = {
  editor: Editor | null;
};

const highlightColors = ['clear', '#eff29b', '#76ede3', '#98ed76', '#f0c3b5', '#ececec'];

export function TextHighlightPicker(props: TextHighlightPickerProps) {
  const { editor } = props;
  const { classes } = useStyles({ color: editor?.getAttributes('highlight')?.color });
  const t = useTranslations('widgets.textHighlightPicker');

  const [opened, { toggle, close }] = useDisclosure(false);

  const handleHighlightChange = (color: string) => {
    if (color === 'clear') {
      editor?.commands.unsetHighlight();
    } else {
      editor?.commands.setHighlight({ color });
    }

    close();
    editor?.commands.focus();
  };

  return (
    <Popover opened={opened} width={224} position="bottom" withArrow shadow="md" withinPortal={false}>
      <Popover.Target>
        <ActionIcon variant="subtle" component="div" onClick={toggle} title={t('title')} size="lg">
          <RichTextEditor.Control>
            <TextHighlight />
          </RichTextEditor.Control>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown h={66}>
        <ColorPicker
          size="sm"
          h={'100%'}
          swatches={highlightColors}
          withPicker={false}
          onChange={handleHighlightChange}
          classNames={{
            swatch: classes.colorPickerSwatch
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
