import { clientModules } from '@hendrx/modules';
import { Box, Flex } from '@mantine/core';
import { ReactNode, useState } from 'react';

import { SatisfactionSurveyOption } from './Option';
import { useStyles } from './SatisfactionSurvey.styles';

enum SatisfactionScores {
  Dissatisfied = 'dissatisfied',
  Neutral = 'neutral',
  Satisfied = 'satisfied'
}

export type SatisfactionScore = `${SatisfactionScores}`;

export function getOptions() {
  return [
    { name: SatisfactionScores.Satisfied, Icon: clientModules.ui.assets.satisfactionSurveyIcons.Satisfied },
    { name: SatisfactionScores.Neutral, Icon: clientModules.ui.assets.satisfactionSurveyIcons.Neutral },
    { name: SatisfactionScores.Dissatisfied, Icon: clientModules.ui.assets.satisfactionSurveyIcons.Dissatisfied }
  ];
}

type SatisfactionSurveyProps = {
  onChange: (value: SatisfactionScore | null) => void;
  title: ReactNode;
  value: SatisfactionScore | null | undefined;
  disabled?: boolean;
};

export const SatisfactionSurvey = (props: SatisfactionSurveyProps) => {
  const { onChange, value, title, disabled } = props;
  const [selectedOption, setSelectedOption] = useState(value ?? null);
  const { classes } = useStyles();

  const handleOnChange = (satisfactionScore: SatisfactionScore | null) => {
    if (selectedOption === satisfactionScore) {
      setSelectedOption(null);
      onChange(null);
    } else {
      setSelectedOption(satisfactionScore);
      onChange(satisfactionScore);
    }
  };

  return (
    <Box className={classes.satisfactionSurvey}>
      {title}
      <Flex justify="center" wrap="wrap">
        {getOptions().map(option => (
          <SatisfactionSurveyOption
            Icon={option.Icon}
            disabled={disabled}
            isSelected={option.name === selectedOption}
            key={option.name}
            onChange={() => handleOnChange(option.name)}
            optionName={option.name}
          />
        ))}
      </Flex>
    </Box>
  );
};
