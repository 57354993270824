import { useDragLayer } from 'react-dnd';

import { ItemDragType } from 'shared/widgetsSDK/Stage.utils';

export const useDragInfo = () => {
  const { isDragging, previewSpan, draggedItemId, draggedItemHeight } = useDragLayer(monitor => {
    const itemType = monitor.getItemType();
    const stageTypes = Object.values<unknown>(ItemDragType);
    const isStageType = stageTypes.includes(itemType);

    if (!isStageType) {
      return {
        isDragging: false,
        previewSpan: 1,
        draggedItemId: null
      };
    }

    return {
      isDragging: monitor.isDragging(),
      previewSpan: monitor.getItem()?.span ?? 1,
      draggedItemId: monitor.getItem()?.id,
      draggedItemHeight: monitor.getItem()?.height || monitor.getItem()?.constraints?.minHeight
    };
  });

  return { isDragging, previewSpan, draggedItemId, draggedItemHeight };
};
