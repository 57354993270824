import { useCallback } from 'react';

import { validateYArray, validateYMap } from 'shared/widgetsSDK/yjs';

import { calculateNewListPosition } from '@/utils/calculateNewListPosition';
import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export function useReorderAnswer() {
  const { document, data } = useContentObject();
  return useCallback(
    (currentIndex: number, newIndex: number) => {
      document.transact(() => {
        const calculatedNewIndex = calculateNewListPosition(currentIndex, newIndex);
        if (calculatedNewIndex === -1) {
          return;
        }
        const answersArray = validateYArray(data?.get('answers'));
        const currentAnswerClone = validateYMap(answersArray.get(currentIndex)).clone();
        answersArray.delete(currentIndex);
        answersArray.insert(calculatedNewIndex, [currentAnswerClone]);
      });
    },
    [document, data]
  );
}
