import { ActionIcon, Box, Collapse, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { Fragment } from 'react';
import * as Y from 'yjs';

import {
  ContentObjectType,
  getContentObjectsMap,
  useObservedArray,
  useObservedProperty,
  validateYMap
} from 'shared/widgetsSDK/yjs';

import { useStyles } from './QuestionBank.styles';
import { QuestionCard } from './QuestionCard';
import { QuestionDropIndicator } from './QuestionDropIndicator';
import { duplicateContentObject, removeContentObject } from 'shared/widgetsSDK/contentObjectOperations';
import { Chevron } from '@/icons/Chevron';
import { useContentObject, useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export const questionListDndType = 'questionBankItem';

type QuestionInSection = { id: string; type: string };

const getQuestionsInSection = (verticalChildren: string[], contentObjectsMap: Y.Map<ContentObjectType>) => {
  const questionsInSection: QuestionInSection[] = [];

  verticalChildren.forEach(question => {
    const contentObject = validateYMap(contentObjectsMap.get(question));
    if (contentObject) {
      const id = contentObject.get('id') as string;
      const type = contentObject.get('type') as string;
      questionsInSection.push({ id, type });
    }
  });

  return questionsInSection;
};

export function QuestionBank() {
  const { classes } = useStyles({});
  const { document, id } = useContentObject();
  const [verticalSectionId] = useContentObjectProperty<string>('children.0');
  const contentObjectsMap = getContentObjectsMap(document);
  const [verticalSection] = useObservedProperty<Y.Map<ContentObjectType>>(contentObjectsMap, verticalSectionId);
  const [verticalChildren, verticalSectionChildren] = useObservedArray(verticalSection, 'children') as [
    string[],
    Y.Array<string>
  ];
  const [requiredSubQuestionsId] = useContentObjectProperty<string[]>('requiredSubQuestionsId');

  const [isListOpened, { toggle: toggleOpenList }] = useDisclosure(true);
  const t = useTranslations('widgets');

  const questionsInSection = getQuestionsInSection(verticalChildren, contentObjectsMap);

  const onDeleteQuestion = (positionIndex: number, itemId: string) => {
    verticalSectionChildren.delete(positionIndex, 1);
    removeContentObject(document, itemId);
  };

  const onDuplicateQuestion = (positionIndex: number, itemId: string) => {
    const currentLayout = verticalSection.get('layout');
    if (!currentLayout) return;
    const duplicatedQuestionMap = duplicateContentObject(document, itemId, verticalSectionId);
    const duplicatedQuestionId = duplicatedQuestionMap.get('id') as string;

    const sourceItemLayoutEntry = currentLayout.get(itemId) as Y.Map<unknown>;
    currentLayout.set(duplicatedQuestionId, sourceItemLayoutEntry.clone());
    verticalSectionChildren.insert(positionIndex + 1, [duplicatedQuestionId]);

    // If duplicated question was required, update also the requiredSubQuestionsId
    if (requiredSubQuestionsId.includes(itemId)) {
      const parentQuestionMap = contentObjectsMap.get(id);
      parentQuestionMap?.set('requiredSubQuestionsId', [...requiredSubQuestionsId, duplicatedQuestionId]);
    }
  };

  return (
    <FormControl withSeparator>
      <Flex justify="space-between" onClick={toggleOpenList} align="center" direction="row">
        <Flex direction="row">
          <Text>({verticalChildren.length})</Text>
        </Flex>

        <ActionIcon variant="subtle" sx={{ transform: `rotate(${isListOpened ? '0deg' : '180deg'})` }}>
          <Chevron />
        </ActionIcon>
      </Flex>

      <Collapse in={isListOpened}>
        {questionsInSection.length > 0 ? (
          <>
            <QuestionDropIndicator verticalSectionChildren={verticalSectionChildren} positionIndex={0} />
            {questionsInSection.map((question, index) => (
              <Fragment key={question.id}>
                <QuestionCard
                  questionData={question}
                  positionIndex={index}
                  onDeleteQuestion={onDeleteQuestion}
                  onDuplicateQuestion={onDuplicateQuestion}
                  isQuestionRequired={requiredSubQuestionsId.includes(question.id)}
                />
                <QuestionDropIndicator verticalSectionChildren={verticalSectionChildren} positionIndex={index + 1} />
              </Fragment>
            ))}
          </>
        ) : (
          <Box color="dimmed" className={classes.emptyListWrapper}>
            <Text c="dimmed">{t('questionGroupListSettings.noQuestion')}</Text>
          </Box>
        )}
      </Collapse>
    </FormControl>
  );
}
