import { User } from '@hendrx/modules/authentication';
import { Avatar, AvatarProps, useMantineTheme } from '@mantine/core';
import { useTranslations } from 'next-intl';

type UserAvatarProps = { user: User | Partial<User> | null | undefined } & AvatarProps;

export function UserAvatar(props: UserAvatarProps) {
  const mantineTheme = useMantineTheme();
  const { color, user, ...otherProps } = props;
  const t = useTranslations('components');
  const { avatarUrl, firstName = '', lastName = '' } = user ?? {};
  const userInitials = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  const innerText = avatarUrl ? '' : userInitials || t('topbar.guestAcronym.title');

  return (
    <Avatar
      alt={t('topbar.avatarAlt.title') ?? ''}
      data-testid="topbar-user-avatar"
      radius="100%"
      src={avatarUrl}
      color={color ?? mantineTheme.other.topbar.avatar.backgroundColor}
      variant="filled"
      {...otherProps}
    >
      {innerText}
    </Avatar>
  );
}
