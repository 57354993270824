export enum RichTextTools {
  Alignment = 'alignment',
  Blockquote = 'blockquote',
  Bold = 'bold',
  ClearFormatting = 'clearFormatting',
  Code = 'code',
  Direction = 'direction',
  FontFamily = 'fontFamily',
  FontSize = 'fontSize',
  HorizontalLine = 'horizontalLine',
  InsertImage = 'insertImage',
  Italic = 'italic',
  LineHeight = 'lineHeight',
  Link = 'link',
  Lists = 'lists',
  StrikeThrough = 'strikeThrough',
  TextColor = 'textColor',
  TextHighlight = 'textHighlight',
  Tooltip = 'tooltip',
  Underline = 'underline'
}

export enum RichTextExtraTools {
  Css = 'css',
  TextLevelOnly = 'textLevelOnly',
  TextLevelWithParagraph = 'TextLevelWithParagraph'
}

export enum ToolbarType {
  Inline = 'inline',
  Fixed = 'fixed',
  None = 'none'
}

export type TextTypes = 'header' | 'paragraph';
