import { Flex, Switch } from '@mantine/core';
import { useTranslations } from 'next-intl';

import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { FormControl } from '@/widgets/_components/FormControl';

export function ShowGradeSettings() {
  const t = useTranslations('widgets');
  const [showGeneralGrade, setShowGeneralGrade] = useContentObjectProperty<boolean>('showGeneralGrade');
  const [showEachQuestionGrade, setShowEachQuestionGrade] = useContentObjectProperty<boolean>('showEachQuestionGrade');

  return (
    <FormControl title={t('evaluationSettings.showGradeSettings.showGrade')} withSeparator>
      <Flex gap={12} direction="column">
        <Switch
          checked={showGeneralGrade}
          onChange={event => setShowGeneralGrade(event.currentTarget.checked)}
          label={t('evaluationSettings.showGradeSettings.generalGrade')}
        />
        <Switch
          checked={showEachQuestionGrade}
          onChange={event => setShowEachQuestionGrade(event.currentTarget.checked)}
          label={t('evaluationSettings.showGradeSettings.onEachQuestion')}
        />
      </Flex>
    </FormControl>
  );
}
