import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    themeControl: {
      flexWrap: 'wrap'
    },
    contentStack: {
      paddingInline: rem(12),
      paddingBlock: 0,
      gap: 0
    }
  };
});
