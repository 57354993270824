import * as Y from 'yjs';

import { generateDefaultOption, initializeClozeOptionInputData, initializeClozeOptionSelectData } from 'shared/utils/initializeCloze';
import { ClozeOptionType } from '../../types/ClozeQuestion';
import { Feedbacks } from '../../types/Feedbacks';
import { InitializePropsOptions, ManifestInitializedProps } from '../../types/ManifestType';
import { createQuestionWidgetChildren } from '../../utils/createQuestionWidgetChildren';
import { genRandId } from '../../utils/genRandId';
import { getEvaluationProps } from '../../utils/getEvaluationProps';
import { htmlToXmlFragment } from '../../utils/htmlToXmlFragment';
import { initializeClozeOption } from '../../utils/initializeCloze';
import { WidgetType } from '../../utils/widgets';
import { createNewClozeQuestionAnswerData } from '../ClozeQuestionAnswer/create';
import { ClozeQuestionConfig } from '../questionConfigs';
import { manifestLocales } from './manifest.locales';

export enum ClozeQuestionType {
  Default = 'default',
  Table = 'table'
}

export function createNewClozeQuestionDataBase(
  options: InitializePropsOptions,
  initializationConfig?: ClozeQuestionConfig
) {
  const { isPlenaryLesson = false, locale } = options;
  const { answerPlaceholder, initialHeadlineText } = manifestLocales.clozeQuestion;
  const { answer = '', hint = '', label = initialHeadlineText[locale] } = initializationConfig || {};

  // answer example:
  // const answer = 'one two <clozeInput>three four</clozeInput> five six <clozeSelect>seven eight</clozeSelect> nine ten';

  const clozeOptions = new Y.Map(); 
  const selectPossibleOptions = new Y.Map();
  const inputCorrectOptions = new Y.Map();
  const selectCorrectOptions = new Y.Map();

  const content = answer
    .split(/(<clozeInput>.*?<\/clozeInput>|<clozeSelect>.*?<\/clozeSelect>)/)
    .map(chunk => {
      if (chunk.startsWith('<clozeInput>') || chunk.startsWith('<clozeSelect>')) {

        const newClozeId = genRandId();
        const type = chunk.startsWith('<clozeInput>') ? ClozeOptionType.Input : ClozeOptionType.Select;
        const newCloze = initializeClozeOption(newClozeId, type);

        clozeOptions.set(newClozeId, newCloze);

        if (chunk.startsWith('<clozeInput>')) {
          const input = initializeClozeOptionInputData(chunk.replace('<clozeInput>', '').replace('</clozeInput>', ''));
          inputCorrectOptions.set(newClozeId, input);
        }

        if (chunk.startsWith('<clozeSelect>')) {
          const newText = chunk.replace('<clozeSelect>', '').replace('</clozeSelect>', '')
          const { possibleOptions, optionId } = initializeClozeOptionSelectData(newText, "Option 2"); // use translation: t('secondOptionText')

          selectPossibleOptions.set(newClozeId, possibleOptions);
          selectCorrectOptions.set(newClozeId, generateDefaultOption(newText, optionId));
        }        

        return `<cloze-option data-cloze-id="${newClozeId}" data-cloze-type="${type}" style="font-size: null; font-family: null"></cloze-option>`
      }

      return chunk;
    })
    .join('');

  const id = genRandId();
  const labelFragment = htmlToXmlFragment(`<h2>${label}</h2>`);
  const answerFragment = htmlToXmlFragment(content);
  const hintFragment = htmlToXmlFragment(hint);
  const widgetEvaluationProps = Object.entries(getEvaluationProps(isPlenaryLesson));

  const [questionAnswerData] = createNewClozeQuestionAnswerData();
  const { childrenArray, horizontalFlexSectionData, verticalFlexSectionData } = createQuestionWidgetChildren(
    questionAnswerData.id,
    options?.width
  );

  return {
    id,
    contentObjectData: [
      ['id', id],
      ['type', WidgetType.ClozeQuestion],
      ['children', childrenArray],
      ['labelFragment', labelFragment],
      ['answerFragment', answerFragment],
      ['hintFragment', hintFragment],
      ['answerPlaceholder', answerPlaceholder[locale]],
      ['clozeOptions', clozeOptions],
      ['selectPossibleOptions', selectPossibleOptions],
      ['withRandomAnswers', new Y.Map()],
      ['feedback', Feedbacks.Check],
      ['saveAnswers', true],
      ...widgetEvaluationProps,
      ['questionAnswerId', questionAnswerData.id],
      ['clozeQuestionType', ClozeQuestionType.Default]
    ],
    sensitiveData: [
      ['inputCorrectOptions', inputCorrectOptions],
      ['selectCorrectOptions', selectCorrectOptions]
    ],
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  } as const;
}

export function createNewClozeQuestionData(options: InitializePropsOptions, initializationConfig?: ClozeQuestionConfig): ManifestInitializedProps {
  const {
    contentObjectData,
    horizontalFlexSectionData,
    id,
    questionAnswerData,
    sensitiveData,
    verticalFlexSectionData
  } = createNewClozeQuestionDataBase(options, initializationConfig);

  return [
    {
      id,
      contentObjectData: new Y.Map(contentObjectData),
      sensitiveData: new Y.Map(sensitiveData)
    },
    verticalFlexSectionData,
    horizontalFlexSectionData,
    questionAnswerData
  ];
}
