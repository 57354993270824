import { ManifestType } from 'shared/types/ManifestType';
import { WidgetType } from 'shared/utils/widgets';
import { createNewSummaryPageSubmissionHistory } from 'shared/widgetsSDK/SummaryPageSubmissionHistory/create';

import { manifestLocales } from './manifest.locales';
import SummaryPageSubmissionHistoryEn from './resources/en/summaryPageSubmissionHistory.svg';
import SummaryPageSubmissionHistoryHe from './resources/he/summaryPageSubmissionHistory.svg';

export const Manifest: ManifestType = {
  name: WidgetType.SummaryPageSubmissionHistory,
  previewGroups: [
    {
      id: 'SummaryPageSubmissionHistory',
      divider: false,
      previews: [
        {
          name: 'SummaryPageSubmissionHistory',
          previewImage: {
            paths: {
              en: SummaryPageSubmissionHistoryEn,
              he: SummaryPageSubmissionHistoryHe
            },
            alt: { ...manifestLocales.summaryPageSubmissionHistory.previewImageAlt },
            size: {
              width: 285,
              height: 44
            }
          },
          initializeProps: () => createNewSummaryPageSubmissionHistory(),
          dimensions: {
            width: 1180,
            height: 'auto'
          }
        }
      ]
    }
  ],
  additionalData: {
    constraints: {
      minHeight: 48,
      minWidth: 1180
    }
  }
};
