import { rem } from '@mantine/core';
import { CSSObject, createStyles } from '@mantine/emotion';

import { DragHandleProps } from './DragHandle';

export const useStyles = createStyles((theme, props: DragHandleProps) => {
  const { orientation, strength, offset = 0, variant = 'default', isResizing } = props;

  const palette = variant === 'default' ? theme.colors.gray : theme.colors.accent;

  const stylesByOrientation: CSSObject =
    orientation === 'center'
      ? {
          insetInline: rem(20),
          insetBlockStart: 0,
          width: `calc(100% - ${rem(40)})`,
          marginTop: rem(-offset),
          '> *': {
            rotate: '90deg'
          }
        }
      : {
          insetInlineStart: 0,
          insetBlock: 0,
          width: rem(48),
          marginInlineStart: rem(-offset)
        };

  return {
    dragHandleIcon: {
      position: 'absolute',
      display: 'grid',
      placeItems: 'center',
      pointerEvents: 'none',
      color: palette[strength],
      opacity: isResizing ? '0%' : '100%',
      ...stylesByOrientation
    },
    dragHandleWrapper: {
      zIndex: 1,
      pointerEvents: 'initial',
      ':hover': {
        color: palette[strength + 2],
        cursor: 'grab'
      }
    }
  };
});
