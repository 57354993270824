import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(theme => {
  return {
    deleteText: {
      color: theme.colors.pink[6],
      fontWeight: 'bold'
    }
  };
});
