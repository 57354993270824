import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import * as Y from 'yjs';
import { z } from 'zod';

import { getContentObjectsMap, getFormDataMap, getSensitiveDataMap } from 'shared/widgetsSDK/yjs';

type Errors = object;
export type ValidationHandler = (document: Y.Doc) => Errors;

export const getValidator = (data: Y.Map<unknown> | undefined, schema: z.Schema) => () => {
  const dataValidationResult = schema.safeParse(data?.toJSON());
  const dataErrors = dataValidationResult.success ? {} : dataValidationResult.error.format();
  return dataErrors;
};

export function useValidation(id: string, document: Y.Doc, validate: ValidationHandler, dependsOn: string[]) {
  const contentObjectsMap = getContentObjectsMap(document);
  const sensitiveDataMap = getSensitiveDataMap(document);
  const formData = getFormDataMap(document);

  useEffect(() => {
    const handleValidate = () => {
      const newErrors = validate(document);
      const existingErrorsMap = formData.get(id);
      document.transact(() => {
        const currentErrors = existingErrorsMap?.get('errors');
        if (!isEqual(currentErrors, newErrors)) {
          existingErrorsMap?.set('errors', newErrors);
        }
      }, 'untracked');
    };

    // Validate on mount, this is not perfect because it may result in unnecessary validations
    // But unless it poses any problems, this has the advantage of having the full logic completely
    // encapsulated here
    handleValidate();

    dependsOn.forEach(contentObjectId => {
      const contentObject = contentObjectsMap.get(contentObjectId);
      const sensitiveData = sensitiveDataMap.get(contentObjectId);

      contentObject?.observeDeep(handleValidate);
      sensitiveData?.observeDeep(handleValidate);
    });

    return () =>
      dependsOn.forEach(contentObjectId => {
        const contentObject = contentObjectsMap.get(contentObjectId);
        const sensitiveData = sensitiveDataMap.get(contentObjectId);

        contentObject?.unobserveDeep(handleValidate);
        sensitiveData?.unobserveDeep(handleValidate);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependsOn, validate, document, formData, contentObjectsMap]);
}

export function useBasicValidation(
  contentObjectId: string,
  document: Y.Doc,
  data: Y.Map<unknown> | undefined,
  schema: z.Schema
) {
  const validator = useMemo(() => getValidator(data, schema), [data, schema]);
  useValidation(contentObjectId, document, validator, [contentObjectId]);
}
