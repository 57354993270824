import { useCanReadQuestionGrade } from '@/components/CanProvider';
import { Grade } from '@/components/Grade';

type ReadOnlyGradeProps = { scoreWeight: number; grade?: number };

export const ReadOnlyGrade = (props: ReadOnlyGradeProps) => {
  const { grade, scoreWeight } = props;

  const canReadQuestionGrade = useCanReadQuestionGrade();

  return canReadQuestionGrade ? <Grade key={grade} defaultScore={grade} maxScore={scoreWeight} submitted /> : null;
};
