import { AnswerAreaThemeProps, BasicThemeProps, BorderThemeProps, GlobalColorThemeProps } from '@hendrx/modules/ui';
import { Box, Checkbox, CheckboxProps, Radio, RadioProps } from '@mantine/core';
import React, { ReactElement, useMemo } from 'react';

import { AnswerStyleType } from 'shared/types/AnswerStyleType';

import { useStyles } from './Answer.styles';
import { Check } from '@/icons/Check';
import { Cross } from '@/icons/Cross';
import { useDeviceView } from '@/utils/deviceHooks';
import { useContentObjectProperty } from '@/widgets/_components/ContentObjectProvider';
import { useStyles as useAnswerAreaStyles } from '@/widgets/hooks/AnswerArea.styles';
import { useAnswerElements } from '@/widgets/hooks/useAnswerElements';

export type SelectedAnswerStatus = 'correct' | 'incorrect';

export type AnswerProps = {
  answerAreaThemeProps: AnswerAreaThemeProps;
  answerIndex: number;
  checkboxThemeProps: BasicThemeProps;
  inputType: 'radio' | 'checkbox';
  selectedCheckboxThemeProps: GlobalColorThemeProps & { border: BorderThemeProps };
  className?: string;
  dataTestIdPrefix?: string;
  disabled?: boolean;
  isChecked?: boolean;
  name?: string;
  status?: SelectedAnswerStatus;
};

const getIcon = (status?: SelectedAnswerStatus) => {
  const Icon: RadioProps['icon'] & CheckboxProps['icon'] = ({ className }) =>
    status === 'incorrect' ? <Cross className={className} /> : <Check className={className} />;

  return Icon;
};

export function Answer(props: AnswerProps & { AnswerBodyComponent: ReactElement }) {
  const {
    AnswerBodyComponent,
    answerAreaThemeProps,
    answerIndex,
    checkboxThemeProps,
    className,
    dataTestIdPrefix,
    disabled,
    inputType,
    isChecked,
    name,
    selectedCheckboxThemeProps,
    status
  } = props;

  const [answersStyle] = useContentObjectProperty<AnswerStyleType>('answersStyle');

  const { deviceView } = useDeviceView();
  const answersStyleType = deviceView === 'mobile' ? AnswerStyleType.Button : answersStyle;

  const { isTextOnly } = useAnswerElements();

  const { classes: commonClasses, cx } = useAnswerAreaStyles({
    answerAreaThemeProps,
    answersStyleType,
    deviceView,
    isChecked,
    isTextOnly
  });
  const { classes } = useStyles({
    answerAreaThemeProps,
    answersStyleType,
    checkboxThemeProps,
    deviceView,
    disabled,
    isChecked,
    isTextOnly,
    selectedCheckboxThemeProps,
    status
  });

  const Label = useMemo(
    () => <Box className={commonClasses.contentArea}>{AnswerBodyComponent}</Box>,
    [commonClasses.contentArea, AnswerBodyComponent]
  );

  return inputType === 'radio' ? (
    <Radio
      {...(status && { icon: getIcon(status) })}
      className={className}
      data-testid={`${dataTestIdPrefix}-answer-radio`}
      disabled={disabled}
      label={Label}
      value={answerIndex.toString()}
      classNames={{
        radio: cx(commonClasses.checkboxInput, classes.checkboxInput),
        inner: cx(commonClasses.checkboxInner, classes.checkboxInner, classes.radioInner),
        label: cx(commonClasses.checkboxLabel, classes.checkboxLabel),
        labelWrapper: cx(commonClasses.checkboxLabelWrapper, classes.checkboxLabelWrapper),
        body: cx(commonClasses.checkboxBody, classes.checkboxBody),
        root: cx(commonClasses.checkboxRoot, classes.checkboxRoot)
      }}
      name={name}
      size="xl"
    />
  ) : (
    <Checkbox
      className={className}
      data-testid={`${dataTestIdPrefix}-answer-checkbox`}
      disabled={disabled}
      icon={getIcon(status)}
      value={answerIndex.toString()}
      label={Label}
      classNames={{
        input: cx(commonClasses.checkboxInput, classes.checkboxInput),
        inner: cx(commonClasses.checkboxInner, classes.checkboxInner),
        label: cx(commonClasses.checkboxLabel, classes.checkboxLabel),
        labelWrapper: cx(commonClasses.checkboxLabelWrapper, classes.checkboxLabelWrapper),
        body: cx(commonClasses.checkboxBody, classes.checkboxBody),
        root: cx(commonClasses.checkboxRoot, classes.checkboxRoot)
      }}
      size="xl"
    />
  );
}
