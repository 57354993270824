import { useCallback } from 'react';

import { validateYArray } from 'shared/widgetsSDK/yjs';

import { useContentObject } from '@/widgets/_components/ContentObjectProvider';

export const useDeleteAnswer = () => {
  const { document, data } = useContentObject();

  return useCallback(
    (answerIndex: number) => {
      document.transact(() => {
        if (!data) {
          throw new Error('Content object data is not present.');
        }
        const answersData = validateYArray(data.get('answers'));
        answersData.delete(answerIndex);
      });
    },
    [document, data]
  );
};
